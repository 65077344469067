//5、2矩形缺
import makerjs from "makerjs";
// inputParam,model,myHolesNum,outParam,fixedParam
// (D,X,Y,area,plate,markers,myHolesNum,glassW,glassH,markShift,markShiftTwo,markShiftThree,markShiftFour,markHolesOneD)
function RectAngleGap(inputParam,model,myHolesNum,outParam,fixedParam,zoom){
var H=inputParam.H,R=inputParam.R,W=inputParam.W,X=inputParam.X,Y=inputParam.Y,area=inputParam.area,myZoom=zoom*1,
plate=model.plate,markers=model.markers,
glassW=outParam.glassW,glassH=outParam.glassH,
markShift=fixedParam.markShift,markShiftF=fixedParam.markShiftF,markShiftTwo=fixedParam.markShiftTwo,
   markShiftThree=fixedParam.markShiftThree,markShiftFour=fixedParam.markShiftFour,markHolesOneD=fixedParam.markHolesOneD,
 
   w= W*1*myZoom,
   h= H*1*myZoom,
   r=R*1*myZoom,
   rd=r/Math.sqrt(2), 
     x0 = X*1*myZoom,
     y0 = Y*1*myZoom;
     /*
     w= W*1,
     h= H*1,
     r=R*1,
     rd=r/Math.sqrt(2), 
       x0 = X*1,
       y0 = Y*1;
       */
     if(r!==0){
      var TxtR="R="+R;
     }
     if(h!==0){
      var TxtH=H;
     }
     if(w!==0){
      var TxtW=W;
     }
     if(x0!==0){          
     var TxtX=X;
     }
     if(y0!==0){          
      var TxtY=Y;
     }
  //在A区域 计算孔的位置       
  var xa=x0,ya=glassH * 1-y0,
  //B区域//只有Y标注没有X标注 
  xb=glassW * 1/2,yb=glassH * 1-y0,
  //C区域
  xc=glassW * 1-x0,yc=glassH * 1-y0,
  //D区域
  //只有X标注没有Y标注 
  xd=x0,yd=glassH * 1/2,
  //E区域
  //只有X标注没有Y标注 
  xe=glassW * 1/2,ye=glassH * 1/2,
  //F区域
  //只有X标注没有Y标注 
  xf=glassW * 1-x0,yf=glassH * 1/2,
  //在G区域 计算孔的位置
  xg=x0,yg=y0,
  //在H区域 计算孔的位置
  //只标注Y
  xh=glassW*1/2,yh=y0,
  //在I区域 计算孔的位置
  xi=glassW*1-x0,yi=y0;
  if(area==1){
    //画缺 A位置
        //5、圆角矩形缺 A位置 
        var myRagGapARoundRectangle=new makerjs.models.RoundRectangle(w, h, r); 
        myRagGapARoundRectangle.origin=[xa,ya-h]; 
     //画5、圆角矩形缺标记 A位置    
  var myRagGapAMakerW = new makerjs.models.Square(0); //W的起始点       
  myRagGapAMakerW.origin = [xa-x0,ya+y0];
  var myRagGapAMakerH = new makerjs.models.Square(0); //H的起始点        
  myRagGapAMakerH.origin = [xa-x0,ya+y0];
  var myRagGapAMakerY = new makerjs.models.Square(0); //Y的起始点        
  myRagGapAMakerY.origin = [xa-x0,ya+y0];
  var myRagGapAMakerX = new makerjs.models.Square(0); //Y的起始点        
  myRagGapAMakerX.origin = [xa-x0,ya+y0];
  var myRagGapAMakerR = new makerjs.models.Square(0); //Y的起始点        
  myRagGapAMakerR.origin = [xa-x0,ya+y0];
  /*****************************************************************************/
  makerjs.model.addPath(myRagGapAMakerW, new makerjs.paths.Line([0,0], [0, markShiftFour]));//Y轴加长标线
  makerjs.model.addPath(myRagGapAMakerW, new makerjs.paths.Line([x0,0], [x0, markShiftFour]));//Y轴短标线
  makerjs.model.addPath(myRagGapAMakerW, new makerjs.paths.Line([x0+w,0], [x0+w, markShiftFour]));//Y轴短标线
  makerjs.model.addPath(myRagGapAMakerW, new makerjs.paths.Line([0,0], [0-markShiftFour, 0]));//X轴加长标线
  makerjs.model.addPath(myRagGapAMakerW, new makerjs.paths.Line([0,0-y0], [0-markShiftFour, 0-y0]));//X轴方向短线
  makerjs.model.addPath(myRagGapAMakerW, new makerjs.paths.Line([0,0-y0-h], [0-markShiftFour, 0-y0-h]));//X轴方向短线
  
  makerjs.model.addPath(myRagGapAMakerH, new makerjs.paths.Line([0-markShiftTwo,0-y0], [0-markShiftTwo, 0-y0-h]));//H相关 Y轴方向短线
  makerjs.model.addCaption(myRagGapAMakerH,TxtH, [0-markShiftFour, 0-y0-h],[0-markShiftFour,0-y0] );//H文字标注
  
  makerjs.model.addPath(myRagGapAMakerY, new makerjs.paths.Line([0-markShiftTwo,0], [0-markShiftTwo, 0-y0]));//Y轴方向标注短线 
  makerjs.model.addCaption(myRagGapAMakerY,TxtY, [0-markShiftFour, 0-y0],[0-markShiftFour,0] );//Y文字标注
  
  makerjs.model.addPath(myRagGapAMakerX, new makerjs.paths.Line([0,markShiftTwo], [x0, markShiftTwo]));//X轴方向标注短线 
  makerjs.model.addCaption(myRagGapAMakerX,TxtX, [0,markShiftFour],[x0, markShiftFour] );//X文字标注
  // /*****************************************************************************/
  // /*****************************************************************************/
  makerjs.model.addPath(myRagGapAMakerW, new makerjs.paths.Line([x0,markShiftTwo], [x0+w, markShiftTwo]));//W短线标注
  makerjs.model.addCaption(myRagGapAMakerW, TxtW, [x0,markShiftFour], [x0+w, markShiftFour]);//W文字标注
  // /*****************************************************************************/ 
  // /*****************************************************************************/
  makerjs.model.addPath(myRagGapAMakerR, new makerjs.paths.Line([x0+w-r,-y0-h+r], [x0+w-r+rd,-y0-h+r-rd]));////R短线标注直径标线
  makerjs.model.addCaption(myRagGapAMakerR, TxtR, [x0+w-r+markHolesOneD,-y0-h+r+r-markHolesOneD], [x0-r+w+rd+r+markHolesOneD, -y0-h+r-rd-r-markHolesOneD]);///R文字标注直径文字标注
  /*****************************************************************************/    
  plate.models["myRagGapARoundRectangle"+myHolesNum]=myRagGapARoundRectangle;
  markers.models["myRagGapARoundRectangle"+myHolesNum]=myRagGapARoundRectangle;
  markers.models["myRagGapAMakerW"+myHolesNum]=myRagGapAMakerW;
  markers.models["myRagGapAMakerH"+myHolesNum]=myRagGapAMakerH;
  markers.models["myRagGapAMakerX"+myHolesNum]=myRagGapAMakerX;
  markers.models["myRagGapAMakerY"+myHolesNum]=myRagGapAMakerY;
  markers.models["myRagGapAMakerR"+myHolesNum]=myRagGapAMakerR;
  /*****************************************************************************/
  makerjs.model.combineSubtraction(markers.models.myRect, markers.models["myRagGapARoundRectangle"+myHolesNum]);
  console.log("markers==",markers);
  /*****************************************************************************/ 
  /*****************************************************************************/ 
    
  }else if(area==2){
    //画缺 B位置
        //5、圆角矩形缺 B位置 
        var myRagGapBRoundRectangle=new makerjs.models.RoundRectangle(w, h, r); 
        myRagGapBRoundRectangle.origin=[xb,yb-h]; 
     //画5、圆角矩形缺标记 B位置    
  var myRagGapBMakerW = new makerjs.models.Square(0); //W的起始点       
  myRagGapBMakerW.origin = [xb-x0,yb+y0];
  var myRagGapBMakerH = new makerjs.models.Square(0); //H的起始点        
  myRagGapBMakerH.origin = [xb-x0,yb+y0];
  var myRagGapBMakerY = new makerjs.models.Square(0); //Y的起始点        
  myRagGapBMakerY.origin = [xb-x0,yb+y0];
  var myRagGapBMakerX = new makerjs.models.Square(0); //Y的起始点        
  myRagGapBMakerX.origin = [xb-x0,yb+y0];
  var myRagGapBMakerR = new makerjs.models.Square(0); //Y的起始点        
  myRagGapBMakerR.origin = [xb-x0,yb+y0];
  /*****************************************************************************/
  makerjs.model.addPath(myRagGapBMakerW, new makerjs.paths.Line([0,0], [0, markShiftFour]));//Y轴加长标线
  // makerjs.model.addPath(myRagGapBMakerW, new makerjs.paths.Line([x0,0], [x0, markShiftFour]));//Y轴短标线
  makerjs.model.addPath(myRagGapBMakerW, new makerjs.paths.Line([x0+w,0], [x0+w, markShiftFour]));//Y轴短标线
  makerjs.model.addPath(myRagGapBMakerW, new makerjs.paths.Line([0,0], [0-markShiftFour, 0]));//X轴加长标线
  makerjs.model.addPath(myRagGapBMakerW, new makerjs.paths.Line([0,0-y0], [0-markShiftFour, 0-y0]));//X轴方向短线
  makerjs.model.addPath(myRagGapBMakerW, new makerjs.paths.Line([0,0-y0-h], [0-markShiftFour, 0-y0-h]));//X轴方向短线
  
  makerjs.model.addPath(myRagGapBMakerH, new makerjs.paths.Line([0-markShiftTwo,0-y0], [0-markShiftTwo, 0-y0-h]));//H相关 Y轴方向短线
  makerjs.model.addCaption(myRagGapBMakerH,TxtH, [0-markShiftFour, 0-y0-h],[0-markShiftFour,0-y0] );//H文字标注
  
  makerjs.model.addPath(myRagGapBMakerY, new makerjs.paths.Line([0-markShiftTwo,0], [0-markShiftTwo, 0-y0]));//Y轴方向标注短线 
  makerjs.model.addCaption(myRagGapBMakerY,TxtY, [0-markShiftFour, 0-y0],[0-markShiftFour,0] );//Y文字标注
  
  makerjs.model.addPath(myRagGapBMakerX, new makerjs.paths.Line([0,markShiftTwo], [x0, markShiftTwo]));//X轴方向标注短线 
  makerjs.model.addCaption(myRagGapBMakerX,TxtX, [0,markShiftFour],[x0, markShiftFour] );//X文字标注
  // /*****************************************************************************/
  // /*****************************************************************************/
  makerjs.model.addPath(myRagGapBMakerW, new makerjs.paths.Line([x0,markShiftTwo], [x0+w, markShiftTwo]));//W短线标注
  makerjs.model.addCaption(myRagGapBMakerW, TxtW, [x0,markShiftFour], [x0+w, markShiftFour]);//W文字标注
  // /*****************************************************************************/ 
  // /*****************************************************************************/
  makerjs.model.addPath(myRagGapBMakerR, new makerjs.paths.Line([x0+w-r,-y0-h+r], [x0+w-r+rd,-y0-h+r-rd]));////R短线标注直径标线
  makerjs.model.addCaption(myRagGapBMakerR, TxtR, [x0+w-r+markHolesOneD,-y0-h+r+r-markHolesOneD], [x0-r+w+rd+r+markHolesOneD, -y0-h+r-rd-r-markHolesOneD]);///R文字标注直径文字标注
  /*****************************************************************************/    
  plate.models["myRagGapBRoundRectangle"+myHolesNum]=myRagGapBRoundRectangle;
  markers.models["myRagGapBRoundRectangle"+myHolesNum]=myRagGapBRoundRectangle;
  markers.models["myRagGapBMakerW"+myHolesNum]=myRagGapBMakerW;
  markers.models["myRagGapBMakerH"+myHolesNum]=myRagGapBMakerH;
  markers.models["myRagGapBMakerX"+myHolesNum]=myRagGapBMakerX;
  markers.models["myRagGapBMakerY"+myHolesNum]=myRagGapBMakerY;
  markers.models["myRagGapBMakerR"+myHolesNum]=myRagGapBMakerR;
  /*****************************************************************************/
  makerjs.model.combineSubtraction(markers.models.myRect, markers.models["myRagGapBRoundRectangle"+myHolesNum]);
  console.log("markers==",markers);
  /*****************************************************************************/ 
  /*****************************************************************************/ 
           
  }else if(area==3){
   //画缺 C位置
        //5、圆角矩形缺 C位置 
        var myRagGapCRoundRectangle=new makerjs.models.RoundRectangle(w, h, r); 
        myRagGapCRoundRectangle.origin=[xc-w,yc-h]; 
     //画5、圆角矩形缺标记C位置    
  var myRagGapCMakerW = new makerjs.models.Square(0); //W的起始点       
  myRagGapCMakerW.origin = [xc+x0,yc+y0];
  var myRagGapCMakerH = new makerjs.models.Square(0); //H的起始点        
  myRagGapCMakerH.origin = [xc+x0,yc+y0];
  var myRagGapCMakerY = new makerjs.models.Square(0); //Y的起始点        
  myRagGapCMakerY.origin = [xc+x0,yc+y0];
  var myRagGapCMakerX = new makerjs.models.Square(0); //Y的起始点        
  myRagGapCMakerX.origin = [xc+x0,yc+y0];
  var myRagGapCMakerR = new makerjs.models.Square(0); //Y的起始点        
  myRagGapCMakerR.origin = [xc+x0,yc+y0];
  /*****************************************************************************/
  makerjs.model.addPath(myRagGapCMakerW, new makerjs.paths.Line([0,0], [0, markShiftFour]));//Y轴加长标线
  makerjs.model.addPath(myRagGapCMakerW, new makerjs.paths.Line([-x0,0], [-x0, markShiftFour]));//Y轴短标线
  makerjs.model.addPath(myRagGapCMakerW, new makerjs.paths.Line([0-x0-w,0], [0-x0-w, markShiftFour]));//Y轴短标线
  makerjs.model.addPath(myRagGapCMakerW, new makerjs.paths.Line([0,0], [0+markShiftFour, 0]));//X轴加长标线
  makerjs.model.addPath(myRagGapCMakerW, new makerjs.paths.Line([0,0-y0], [0+markShiftFour, 0-y0]));//X轴方向短线
  makerjs.model.addPath(myRagGapCMakerW, new makerjs.paths.Line([0,0-y0-h], [0+markShiftFour, 0-y0-h]));//X轴方向短线
  
  makerjs.model.addPath(myRagGapCMakerH, new makerjs.paths.Line([0+markShiftTwo,0-y0], [0+markShiftTwo, 0-y0-h]));//H相关 Y轴方向短线
  makerjs.model.addCaption(myRagGapCMakerH,TxtH, [0+markShiftFour, 0-y0-h],[0+markShiftFour,0-y0] );//H文字标注
  
  makerjs.model.addPath(myRagGapCMakerY, new makerjs.paths.Line([0+markShiftTwo,0], [0+markShiftTwo, 0-y0]));//Y轴方向标注短线 
  makerjs.model.addCaption(myRagGapCMakerY,TxtY, [0+markShiftFour, 0-y0],[0+markShiftFour,0] );//Y文字标注
  
  makerjs.model.addPath(myRagGapCMakerX, new makerjs.paths.Line([0,markShiftTwo], [0-x0, markShiftTwo]));//X轴方向标注短线 
  makerjs.model.addCaption(myRagGapCMakerX,TxtX, [0-x0,markShiftFour],[0, markShiftFour] );//X文字标注
  // // /*****************************************************************************/
  // // /*****************************************************************************/
  makerjs.model.addPath(myRagGapCMakerW, new makerjs.paths.Line([0-x0-w,markShiftTwo], [0-x0, markShiftTwo]));//W短线标注
  makerjs.model.addCaption(myRagGapCMakerW, TxtW, [0-x0-w,markShiftFour], [0-x0, markShiftFour]);//W文字标注
  // // /*****************************************************************************/ 
  // // /*****************************************************************************/
  makerjs.model.addPath(myRagGapCMakerR, new makerjs.paths.Line([-x0-r,-y0-h+r], [-x0-r+rd,-y0-h+r-rd]));////R短线标注直径标线
  makerjs.model.addCaption(myRagGapCMakerR, TxtR, [-x0-r+markHolesOneD,-y0-h+r+r-markHolesOneD], [-x0+rd+markHolesOneD, -y0-h+r-rd-r-markHolesOneD]);///R文字标注直径文字标注
  /*****************************************************************************/    
  plate.models["myRagGapCRoundRectangle"+myHolesNum]=myRagGapCRoundRectangle;
  markers.models["myRagGapCRoundRectangle"+myHolesNum]=myRagGapCRoundRectangle;
  markers.models["myRagGapCMakerW"+myHolesNum]=myRagGapCMakerW;
  markers.models["myRagGapCMakerH"+myHolesNum]=myRagGapCMakerH;
  markers.models["myRagGapCMakerX"+myHolesNum]=myRagGapCMakerX;
  markers.models["myRagGapCMakerY"+myHolesNum]=myRagGapCMakerY;
  markers.models["myRagGapCMakerR"+myHolesNum]=myRagGapCMakerR;
  /*****************************************************************************/
  makerjs.model.combineSubtraction(markers.models.myRect, markers.models["myRagGapCRoundRectangle"+myHolesNum]);
  console.log("markers==",markers);
  /*****************************************************************************/ 
  /*****************************************************************************/ 
      
  }else if(area==4){
   //画缺 D位置
        //5、方缺 D位置 
        var myRagGapDRoundRectangle=new makerjs.models.RoundRectangle(w, h, r); 
        myRagGapDRoundRectangle.origin=[xd,yd-h/2]; 
     //画5、方缺标记 D位置    
  var myRagGapDMakerW = new makerjs.models.Square(0); //W的起始点       
  myRagGapDMakerW.origin = [xd,yd];   
  var myRagGapDMakerX = new makerjs.models.Square(0); //W的起始点       
  myRagGapDMakerX.origin = [xd,yd];
  var myRagGapDMakerH = new makerjs.models.Square(0); //H的起始点        
  myRagGapDMakerH.origin = [xd,yd];
  var myRagGapDMakerR = new makerjs.models.Square(0); //Y的起始点        
  myRagGapDMakerR.origin = [xd,yd];
  // /*****************************************************************************/
  makerjs.model.addPath(myRagGapDMakerW, new makerjs.paths.Line([-x0,yd], [-x0, yd+markShiftFour]));//Y轴加长标线
  makerjs.model.addPath(myRagGapDMakerW, new makerjs.paths.Line([0,yd], [0, yd+markShiftFour]));//Y轴加长标线
  makerjs.model.addPath(myRagGapDMakerW, new makerjs.paths.Line([w,yd], [w, yd+markShiftFour]));//Y轴短标线
  
  makerjs.model.addPath(myRagGapDMakerH, new makerjs.paths.Line([0+markShiftTwo,h/2], [0+markShiftTwo, -h/2]));//H相关 Y轴方向短线
  makerjs.model.addCaption(myRagGapDMakerH,TxtH,[0+markShiftFour,-h/2], [0+markShiftFour, h/2] );//H文字标注
  // /*****************************************************************************/
  // /*****************************************************************************/
  makerjs.model.addPath(myRagGapDMakerX, new makerjs.paths.Line([-x0,yd+markShiftTwo], [0, yd+markShiftTwo]));//W短线标注
  makerjs.model.addCaption(myRagGapDMakerX, TxtX, [-x0,yd+markShiftFour], [0, yd+markShiftFour]);//W文字标注
  // /*****************************************************************************/
  // /*****************************************************************************/
  makerjs.model.addPath(myRagGapDMakerW, new makerjs.paths.Line([0,yd+markShiftTwo], [0+w, yd+markShiftTwo]));//W短线标注
  makerjs.model.addCaption(myRagGapDMakerW, TxtW, [0,yd+markShiftFour], [0+w, yd+markShiftFour]);//W文字标注
  // /*****************************************************************************/ 
  // /*****************************************************************************/
  makerjs.model.addPath(myRagGapDMakerR, new makerjs.paths.Line([w-r,h/2-r], [w-r+rd,h/2-r+rd]));////R短线标注直径标线
  makerjs.model.addCaption(myRagGapDMakerR, TxtR, [w-r+markHolesOneD,h/2-r-r+markHolesOneD], [w-r+rd+r+markHolesOneD, h/2-r+rd+r+markHolesOneD]);///R文字标注直径文字标注
  /*****************************************************************************/    
  plate.models["myRagGapDRoundRectangle"+myHolesNum]=myRagGapDRoundRectangle;
  markers.models["myRagGapDRoundRectangle"+myHolesNum]=myRagGapDRoundRectangle;
  markers.models["myRagGapDMakerX"+myHolesNum]=myRagGapDMakerX;
  markers.models["myRagGapDMakerW"+myHolesNum]=myRagGapDMakerW;
  markers.models["myRagGapDMakerH"+myHolesNum]=myRagGapDMakerH;
  markers.models["myRagGapDMakerR"+myHolesNum]=myRagGapDMakerR;
  // /*****************************************************************************/
  makerjs.model.combineSubtraction(markers.models.myRect, markers.models["myRagGapDRoundRectangle"+myHolesNum]);
  console.log("markers==",markers);
  /*****************************************************************************/ 
  /*****************************************************************************/ 
           
  }else if(area==5){ 
    //画缺 E位置
        //5、圆角矩形缺 E位置 
        var myRagGapERoundRectangle=new makerjs.models.RoundRectangle(w, h, r); 
        myRagGapERoundRectangle.origin=[xe-w/2,ye-h/2]; 
     //画5、圆角矩形缺标记 E位置    
  var myRagGapEMakerW = new makerjs.models.Square(0); //W的起始点       
  myRagGapEMakerW.origin = [xe-x0,ye+y0];
  var myRagGapEMakerH = new makerjs.models.Square(0); //H的起始点        
  myRagGapEMakerH.origin = [xe-x0,ye+y0];
  var myRagGapEMakerY = new makerjs.models.Square(0); //Y的起始点        
  myRagGapEMakerY.origin = [xe-x0,ye+y0];
  var myRagGapEMakerX = new makerjs.models.Square(0); //Y的起始点        
  myRagGapEMakerX.origin = [xe-x0,ye+y0];
  var myRagGapEMakerR = new makerjs.models.Square(0); //Y的起始点        
  myRagGapEMakerR.origin = [xe-x0,ye+y0];
  /*****************************************************************************/
  makerjs.model.addPath(myRagGapEMakerW, new makerjs.paths.Line([0-w/2,0+h/2], [0-w/2, h/2+markShiftFour]));//Y轴加长标线
  // makerjs.model.addPath(myRagGapBMakerW, new makerjs.paths.Line([x0,0], [x0, markShiftFour]));//Y轴短标线
  makerjs.model.addPath(myRagGapEMakerW, new makerjs.paths.Line([x0+w/2,0+h/2], [x0+w/2, h/2+markShiftFour]));//Y轴短标线
  makerjs.model.addPath(myRagGapEMakerW, new makerjs.paths.Line([0-w/2,0+h/2], [0-w/2-markShiftFour, h/2]));//X轴加长标线
  // makerjs.model.addPath(myRagGapEMakerW, new makerjs.paths.Line([0-w/2,0+h/2-y0], [0-w/2-markShiftFour, h/2-y0]));//X轴方向短线
  makerjs.model.addPath(myRagGapEMakerW, new makerjs.paths.Line([0-w/2,0-h/2-y0], [0-w/2-markShiftFour, 0-y0-h/2]));//X轴方向短线
  
  makerjs.model.addPath(myRagGapEMakerH, new makerjs.paths.Line([0-w/2-markShiftTwo,0+h/2-y0], [0-w/2-markShiftTwo, 0-y0-h/2]));//H相关 Y轴方向短线
  makerjs.model.addCaption(myRagGapEMakerH,TxtH, [0-w/2-markShiftFour, 0-y0-h/2],[0-w/2-markShiftFour,0+h/2-y0] );//H文字标注
  
  makerjs.model.addPath(myRagGapEMakerY, new makerjs.paths.Line([0-w/2-markShiftTwo,h/2], [0-w/2-markShiftTwo, 0+h/2-y0]));//Y轴方向标注短线 
  makerjs.model.addCaption(myRagGapEMakerY,TxtY, [0-w/2-markShiftFour, 0+h/2-y0],[0-w/2-markShiftFour,0+h/2] );//Y文字标注
  
  makerjs.model.addPath(myRagGapEMakerX, new makerjs.paths.Line([0-w/2,markShiftTwo+h/2], [x0-w/2, markShiftTwo+h/2]));//X轴方向标注短线 
  makerjs.model.addCaption(myRagGapEMakerX,TxtX, [0-w/2,markShiftFour+h/2],[x0-w/2, markShiftFour+h/2] );//X文字标注
  // /*****************************************************************************/
  // /*****************************************************************************/
  makerjs.model.addPath(myRagGapEMakerW, new makerjs.paths.Line([x0-w/2,markShiftTwo+h/2], [x0+w/2, markShiftTwo+h/2]));//W短线标注
  makerjs.model.addCaption(myRagGapEMakerW, TxtW, [x0-w/2,markShiftFour+h/2], [x0+w/2, markShiftFour+h/2]);//W文字标注
  // /*****************************************************************************/ 
  // /*****************************************************************************/
  makerjs.model.addPath(myRagGapEMakerR, new makerjs.paths.Line([x0+w/2-r,-y0-h/2+r], [x0+w/2-r+rd,-y0-h/2+r-rd]));////R短线标注直径标线
  makerjs.model.addCaption(myRagGapEMakerR, TxtR, [x0+w/2-r+markHolesOneD,-y0-h/2+r+r-markHolesOneD], [x0-r+w/2+rd+r+markHolesOneD, -y0-h/2+r-rd-r-markHolesOneD]);///R文字标注直径文字标注
  /*****************************************************************************/    
  plate.models["myRagGapERoundRectangle"+myHolesNum]=myRagGapERoundRectangle;
  markers.models["myRagGapERoundRectangle"+myHolesNum]=myRagGapERoundRectangle;
  markers.models["myRagGapEMakerW"+myHolesNum]=myRagGapEMakerW;
  markers.models["myRagGapEMakerH"+myHolesNum]=myRagGapEMakerH;
  markers.models["myRagGapEMakerX"+myHolesNum]=myRagGapEMakerX;
  markers.models["myRagGapEMakerY"+myHolesNum]=myRagGapEMakerY;
  markers.models["myRagGapEMakerR"+myHolesNum]=myRagGapEMakerR;
  /*****************************************************************************/
  makerjs.model.combineSubtraction(markers.models.myRect, markers.models["myRagGapERoundRectangle"+myHolesNum]);
  console.log("markers==",markers);
  /*****************************************************************************/ 
  /*****************************************************************************/ 
          
  }else if(area==6){
   //画缺 F位置
        //5、圆角矩形缺 F位置 
        var myRagGapFRoundRectangle=new makerjs.models.RoundRectangle(w, h, r); 
        myRagGapFRoundRectangle.origin=[xf-w,yf-h/2]; 
     //画5、圆角矩形缺标记 F位置    
  var myRagGapFMakerX = new makerjs.models.Square(0); //W的起始点       
  myRagGapFMakerX.origin = [xf,yf];   
  var myRagGapFMakerW = new makerjs.models.Square(0); //W的起始点       
  myRagGapFMakerW.origin = [xf,yf];
  var myRagGapFMakerH = new makerjs.models.Square(0); //H的起始点        
  myRagGapFMakerH.origin = [xf,yf];
  var myRagGapFMakerR = new makerjs.models.Square(0); //Y的起始点        
  myRagGapFMakerR.origin = [xf,yf]; 
  // /*****************************************************************************/
  makerjs.model.addPath(myRagGapFMakerW, new makerjs.paths.Line([x0,yd], [x0, yd+markShiftFour]));//Y轴加长标线
  makerjs.model.addPath(myRagGapFMakerW, new makerjs.paths.Line([0,yd], [0, yd+markShiftFour]));//Y轴加长标线
  makerjs.model.addPath(myRagGapFMakerW, new makerjs.paths.Line([-w,yd], [-w, yd+markShiftFour]));//Y轴短标线
  makerjs.model.addPath(myRagGapFMakerW, new makerjs.paths.Line([x0,h/2], [x0+markShiftFour, h/2]));//X轴加长标线
  makerjs.model.addPath(myRagGapFMakerW, new makerjs.paths.Line([x0,0-h/2], [x0+markShiftFour, 0-h/2]));//X轴方向短线
  
  makerjs.model.addPath(myRagGapFMakerH, new makerjs.paths.Line([x0+markShiftTwo,h/2], [x0+markShiftTwo, -h/2]));//H相关 Y轴方向短线
  makerjs.model.addCaption(myRagGapFMakerH,TxtH, [x0+markShiftFour, -h/2],[x0+markShiftFour,h/2] );//H文字标注
  /*****************************************************************************/
  /*****************************************************************************/
  makerjs.model.addPath(myRagGapFMakerX, new makerjs.paths.Line([0,yd+markShiftTwo], [x0, yd+markShiftTwo]));//X短线标注
  makerjs.model.addCaption(myRagGapFMakerX, TxtX, [0, yd+markShiftFour], [x0,yd+markShiftFour]);//X文字标注
  /*****************************************************************************/
  /*****************************************************************************/
  makerjs.model.addPath(myRagGapFMakerW, new makerjs.paths.Line([0,yd+markShiftTwo], [0-w, yd+markShiftTwo]));//W短线标注
  makerjs.model.addCaption(myRagGapFMakerW, TxtW, [0-w, yd+markShiftFour], [0,yd+markShiftFour]);//W文字标注
  /*****************************************************************************/ 
  /*****************************************************************************/
  makerjs.model.addPath(myRagGapFMakerR, new makerjs.paths.Line([-w+r,h/2-r], [-w+r-rd,h/2-r+rd]));////R短线标注直径标线
  makerjs.model.addCaption(myRagGapFMakerR, TxtR, [-w+r-rd-r-markHolesOneD, h/2-r+rd+r+markHolesOneD], [-w+r-markHolesOneD,h/2-r-r+markHolesOneD]);///R文字标注直径文字标注
  /*****************************************************************************/    
  plate.models["myRagGapFRoundRectangle"+myHolesNum]=myRagGapFRoundRectangle;
  markers.models["myRagGapFRoundRectangle"+myHolesNum]=myRagGapFRoundRectangle;
  markers.models["myRagGapFMakerX"+myHolesNum]=myRagGapFMakerX;
  markers.models["myRagGapFMakerW"+myHolesNum]=myRagGapFMakerW;
  markers.models["myRagGapFMakerH"+myHolesNum]=myRagGapFMakerH;
  markers.models["myRagGapFMakerR"+myHolesNum]=myRagGapFMakerR;
  /*****************************************************************************/
  makerjs.model.combineSubtraction(markers.models.myRect, markers.models["myRagGapFRoundRectangle"+myHolesNum]);
  console.log("markers==",markers);
  /*****************************************************************************/ 
  /*****************************************************************************/ 
           
  }else if(area==7){
  //画缺 G位置
        //5、圆角矩形缺G位置 
        var myRagGapGRoundRectangle=new makerjs.models.RoundRectangle(w, h, r); 
        myRagGapGRoundRectangle.origin=[xg,yg]; 
     //画5、圆角矩形缺标记 G位置    
  var myRagGapGMakerW = new makerjs.models.Square(0); //W的起始点       
  myRagGapGMakerW.origin = [xg,yg-y0];   
  var myRagGapGMakerX = new makerjs.models.Square(0); //W的起始点       
  myRagGapGMakerX.origin = [xg,yg-y0];
  var myRagGapGMakerH = new makerjs.models.Square(0); //H的起始点        
  myRagGapGMakerH.origin = [xg,yg-y0];
  var myRagGapGMakerY = new makerjs.models.Square(0); //Y的起始点        
  myRagGapGMakerY.origin = [xg,yg-y0];
  var myRagGapGMakerR = new makerjs.models.Square(0); //Y的起始点        
  myRagGapGMakerR.origin = [xg,yg-y0];
  /*****************************************************************************/
  makerjs.model.addPath(myRagGapGMakerW, new makerjs.paths.Line([-x0,0], [-x0, -markShiftFour]));//Y轴加长标线
  makerjs.model.addPath(myRagGapGMakerW, new makerjs.paths.Line([0,0], [0, -markShiftFour]));//Y轴加长标线
  makerjs.model.addPath(myRagGapGMakerW, new makerjs.paths.Line([w,0], [w, -markShiftFour]));//Y轴短标线
  makerjs.model.addPath(myRagGapGMakerW, new makerjs.paths.Line([-x0,0], [-x0-markShiftFour, 0]));//X轴加长标线
  makerjs.model.addPath(myRagGapGMakerW, new makerjs.paths.Line([-x0,0+y0], [-x0-markShiftFour, 0+y0]));//X轴方向短线
  makerjs.model.addPath(myRagGapGMakerW, new makerjs.paths.Line([-x0,0+y0+h], [-x0-markShiftFour, 0+y0+h]));//X轴方向短线
  
  makerjs.model.addPath(myRagGapGMakerH, new makerjs.paths.Line([-x0-markShiftTwo,0+y0], [-x0-markShiftTwo, 0+y0+h]));//H相关 Y轴方向短线
  makerjs.model.addCaption(myRagGapGMakerH,TxtH,[-x0-markShiftFour,0+y0], [-x0-markShiftFour, 0+y0+h] );//H文字标注
  
  makerjs.model.addPath(myRagGapGMakerY, new makerjs.paths.Line([-x0-markShiftTwo,0], [-x0-markShiftTwo, 0+y0]));//Y轴方向标注短线 
  makerjs.model.addCaption(myRagGapGMakerY,TxtY,[-x0-markShiftFour,0], [-x0-markShiftFour, 0+y0] );//Y文字标注
  /*****************************************************************************/
  /*****************************************************************************/
  makerjs.model.addPath(myRagGapGMakerX, new makerjs.paths.Line([-x0,-markShiftTwo], [0, -markShiftTwo]));//W短线标注
  makerjs.model.addCaption(myRagGapGMakerX, TxtX, [-x0,-markShiftFour], [0, -markShiftFour]);//W文字标注
  /*****************************************************************************/
  /*****************************************************************************/
  makerjs.model.addPath(myRagGapGMakerW, new makerjs.paths.Line([0,-markShiftTwo], [0+w, -markShiftTwo]));//W短线标注
  makerjs.model.addCaption(myRagGapGMakerW, TxtW, [0,-markShiftFour], [0+w, -markShiftFour]);//W文字标注
  /*****************************************************************************/ 
  /*****************************************************************************/
  makerjs.model.addPath(myRagGapGMakerR, new makerjs.paths.Line([w-r,y0+h-r], [w-r+rd,y0+h-r+rd]));////R短线标注直径标线
  makerjs.model.addCaption(myRagGapGMakerR, TxtR, [w-r+markHolesOneD,y0+h-r-r+markHolesOneD], [w-r+rd+r+markHolesOneD, y0+h-r+rd+r+markHolesOneD]);///R文字标注直径文字标注
  /*****************************************************************************/    
  plate.models["myRagGapGRoundRectangle"+myHolesNum]=myRagGapGRoundRectangle;
  markers.models["myRagGapGRoundRectangle"+myHolesNum]=myRagGapGRoundRectangle;
  markers.models["myRagGapGMakerX"+myHolesNum]=myRagGapGMakerX;
  markers.models["myRagGapGMakerW"+myHolesNum]=myRagGapGMakerW;
  markers.models["myRagGapGMakerH"+myHolesNum]=myRagGapGMakerH;
  markers.models["myRagGapGMakerY"+myHolesNum]=myRagGapGMakerY;
  markers.models["myRagGapGMakerR"+myHolesNum]=myRagGapGMakerR;
  /*****************************************************************************/
  makerjs.model.combineSubtraction(markers.models.myRect, markers.models["myRagGapGRoundRectangle"+myHolesNum]);
  console.log("markers==",markers);
  /*****************************************************************************/ 
  /*****************************************************************************/ 
            
  }else if(area==8){
    //画缺 H位置
        //5、圆角矩形缺 H位置 
        var myRagGapHRoundRectangle=new makerjs.models.RoundRectangle(w, h, r); 
        myRagGapHRoundRectangle.origin=[xh-w/2,yh]; 
     //画5、圆角矩形缺标记 H位置    
  var myRagGapHMakerW = new makerjs.models.Square(0); //W的起始点       
  myRagGapHMakerW.origin = [xh-x0,yh-y0];
  var myRagGapHMakerH = new makerjs.models.Square(0); //H的起始点        
  myRagGapHMakerH.origin = [xh-x0,yh-y0];
  var myRagGapHMakerY = new makerjs.models.Square(0); //Y的起始点        
  myRagGapHMakerY.origin = [xh-x0,yh-y0];
  var myRagGapHMakerX = new makerjs.models.Square(0); //Y的起始点        
  myRagGapHMakerX.origin = [xh-x0,yh-y0];
  var myRagGapHMakerR = new makerjs.models.Square(0); //Y的起始点        
  myRagGapHMakerR.origin = [xh-x0,yh-y0];
  /*****************************************************************************/
  makerjs.model.addPath(myRagGapHMakerW, new makerjs.paths.Line([0-w/2,0], [0-w/2, markShiftFour+y0+h]));//Y轴加长标线
  // makerjs.model.addPath(myRagGapBMakerW, new makerjs.paths.Line([x0,0], [x0, markShiftFour]));//Y轴短标线
  makerjs.model.addPath(myRagGapHMakerW, new makerjs.paths.Line([x0+w/2,0], [x0+w/2, markShiftFour+y0+h]));//Y轴短标线
  makerjs.model.addPath(myRagGapHMakerW, new makerjs.paths.Line([0-w/2,0], [0-w/2-markShiftFour, 0]));//X轴加长标线
  makerjs.model.addPath(myRagGapHMakerW, new makerjs.paths.Line([0-w/2,0+y0], [0-w/2-markShiftFour, 0+y0]));//X轴方向短线
  makerjs.model.addPath(myRagGapHMakerW, new makerjs.paths.Line([0-w/2,0+y0+h], [0-w/2-markShiftFour, 0+y0+h]));//X轴方向短线
  
  makerjs.model.addPath(myRagGapHMakerH, new makerjs.paths.Line([0-w/2-markShiftTwo,0+y0], [0-w/2-markShiftTwo, 0+y0+h]));//H相关 Y轴方向短线
  makerjs.model.addCaption(myRagGapHMakerH,TxtH,[0-w/2-markShiftFour,0+y0], [0-w/2-markShiftFour, 0+y0+h] );//H文字标注
  
  makerjs.model.addPath(myRagGapHMakerY, new makerjs.paths.Line([0-w/2-markShiftTwo,0], [0-w/2-markShiftTwo, 0+y0]));//Y轴方向标注短线 
  makerjs.model.addCaption(myRagGapHMakerY,TxtY,[0-w/2-markShiftFour,0], [0-w/2-markShiftFour, 0+y0] );//Y文字标注
  
  makerjs.model.addPath(myRagGapHMakerX, new makerjs.paths.Line([0-w/2,markShiftTwo], [x0-w/2, markShiftTwo]));//X轴方向标注短线 
  makerjs.model.addCaption(myRagGapHMakerX,TxtX, [0-w/2,markShiftFour],[x0-w/2, markShiftFour] );//X文字标注
  // /*****************************************************************************/
  // /*****************************************************************************/
  makerjs.model.addPath(myRagGapHMakerW, new makerjs.paths.Line([x0-w/2,markShiftTwo+y0+h], [x0+w/2, markShiftTwo+y0+h]));//W短线标注
  makerjs.model.addCaption(myRagGapHMakerW, TxtW, [x0-w/2,markShiftFour+y0+h], [x0+w/2, markShiftFour+y0+h]);//W文字标注
  // /*****************************************************************************/ 
  // /*****************************************************************************/
  makerjs.model.addPath(myRagGapHMakerR, new makerjs.paths.Line([x0+w/2-r,y0+r], [x0+w/2-r+rd,y0+r-rd]));////R短线标注直径标线
  makerjs.model.addCaption(myRagGapHMakerR, TxtR, [x0+w/2-r+markHolesOneD,y0+r+r-markHolesOneD], [x0-r+w/2+rd+r+markHolesOneD, y0-rd-markHolesOneD]);///R文字标注直径文字标注
  /*****************************************************************************/    
  plate.models["myRagGapHRoundRectangle"+myHolesNum]=myRagGapHRoundRectangle;
  markers.models["myRagGapHRoundRectangle"+myHolesNum]=myRagGapHRoundRectangle;
  markers.models["myRagGapHMakerW"+myHolesNum]=myRagGapHMakerW;
  markers.models["myRagGapHMakerH"+myHolesNum]=myRagGapHMakerH;
  markers.models["myRagGapHMakerX"+myHolesNum]=myRagGapHMakerX;
  markers.models["myRagGapHMakerY"+myHolesNum]=myRagGapHMakerY;
  markers.models["myRagGapHMakerR"+myHolesNum]=myRagGapHMakerR;
  /*****************************************************************************/
  makerjs.model.combineSubtraction(markers.models.myRect, markers.models["myRagGapHRoundRectangle"+myHolesNum]);
  console.log("markers==",markers);
  /*****************************************************************************/ 
  /*****************************************************************************/ 
           
  }else if(area==9){
  //画缺 I位置
        //5、圆角矩形缺 I位置 
        var myRagGapIRoundRectangle=new makerjs.models.RoundRectangle(w, h, r); 
        myRagGapIRoundRectangle.origin=[xi-w,yi]; 
     //画5、圆角矩形缺标记 I位置    
  var myRagGapIMakerW = new makerjs.models.Square(0); //W的起始点       
  myRagGapIMakerW.origin = [xi,yi-y0];    
  var myRagGapIMakerX = new makerjs.models.Square(0); //W的起始点       
  myRagGapIMakerX.origin = [xi,yi-y0];
  var myRagGapIMakerH = new makerjs.models.Square(0); //H的起始点        
  myRagGapIMakerH.origin = [xi,yi-y0];
  var myRagGapIMakerY = new makerjs.models.Square(0); //Y的起始点        
  myRagGapIMakerY.origin = [xi,yi-y0];
  var myRagGapIMakerR = new makerjs.models.Square(0); //Y的起始点        
  myRagGapIMakerR.origin = [xi,yi-y0];
  // /*****************************************************************************/
  makerjs.model.addPath(myRagGapIMakerW, new makerjs.paths.Line([x0,0], [x0, -markShiftFour]));//Y轴加长标线
  makerjs.model.addPath(myRagGapIMakerW, new makerjs.paths.Line([0,0], [0, -markShiftFour]));//Y轴加长标线
  makerjs.model.addPath(myRagGapIMakerW, new makerjs.paths.Line([-w,0], [-w, -markShiftFour]));//Y轴短标线
  makerjs.model.addPath(myRagGapIMakerW, new makerjs.paths.Line([x0,0], [x0+markShiftFour, 0]));//X轴加长标线
  makerjs.model.addPath(myRagGapIMakerW, new makerjs.paths.Line([x0,0+y0], [x0+markShiftFour, 0+y0]));//X轴方向短线
  makerjs.model.addPath(myRagGapIMakerW, new makerjs.paths.Line([x0,0+y0+h], [x0+markShiftFour, 0+y0+h]));//X轴方向短线
  
  makerjs.model.addPath(myRagGapIMakerH, new makerjs.paths.Line([x0+markShiftTwo,0+y0], [x0+markShiftTwo, 0+y0+h]));//H相关 Y轴方向短线
  makerjs.model.addCaption(myRagGapIMakerH,TxtH,[x0+markShiftFour,0+y0], [x0+markShiftFour, 0+y0+h] );//H文字标注
  
  makerjs.model.addPath(myRagGapIMakerY, new makerjs.paths.Line([x0+markShiftTwo,0], [x0+markShiftTwo, 0+y0]));//Y轴方向标注短线 
  makerjs.model.addCaption(myRagGapIMakerY,TxtY,[x0+markShiftFour,0], [x0+markShiftFour, 0+y0] );//Y文字标注
  /*****************************************************************************/
  /*****************************************************************************/
  makerjs.model.addPath(myRagGapIMakerX, new makerjs.paths.Line([0,-markShiftTwo], [x0, -markShiftTwo]));//X短线标注
  makerjs.model.addCaption(myRagGapIMakerX, TxtX, [0, -markShiftFour], [x0,-markShiftFour]);//X文字标注
  /*****************************************************************************/
  /*****************************************************************************/
  makerjs.model.addPath(myRagGapIMakerW, new makerjs.paths.Line([0,-markShiftTwo], [0-w, -markShiftTwo]));//W短线标注
  makerjs.model.addCaption(myRagGapIMakerW, TxtW, [0-w, -markShiftFour], [0,-markShiftFour]);//W文字标注
  /*****************************************************************************/ 
  /*****************************************************************************/
  makerjs.model.addPath(myRagGapIMakerR, new makerjs.paths.Line([-w+r,y0+h-r], [-w+r-rd,y0+h-r+rd]));////R短线标注直径标线
  makerjs.model.addCaption(myRagGapIMakerR, TxtR, [-w+r-rd-r-markHolesOneD, y0+h-r+rd+r+markHolesOneD], [-w+r-markHolesOneD,y0+h-r-r+markHolesOneD]);///R文字标注直径文字标注
  /*****************************************************************************/    
  plate.models["myRagGapIRoundRectangle"+myHolesNum]=myRagGapIRoundRectangle;
  markers.models["myRagGapIRoundRectangle"+myHolesNum]=myRagGapIRoundRectangle;
  markers.models["myRagGapIMakerX"+myHolesNum]=myRagGapIMakerX;
  markers.models["myRagGapIMakerW"+myHolesNum]=myRagGapIMakerW;
  markers.models["myRagGapIMakerH"+myHolesNum]=myRagGapIMakerH;
  markers.models["myRagGapIMakerY"+myHolesNum]=myRagGapIMakerY;
  markers.models["myRagGapIMakerR"+myHolesNum]=myRagGapIMakerR;
  /*****************************************************************************/
  makerjs.model.combineSubtraction(markers.models.myRect, markers.models["mySgGapIRoundRectangle"+myHolesNum]);
  console.log("markers==",markers);
  /*****************************************************************************/ 
  /*****************************************************************************/ 
         
  }

myHolesNum++;
return{plate, markers,myHolesNum}
};


export default RectAngleGap;