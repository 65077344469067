//缺4、跑道缺
import makerjs from "makerjs";
// inputParam,model,myHolesNum,outParam,fixedParam
// (D,X,Y,area,plate,markers,myHolesNum,glassW,glassH,markShift,markShiftTwo,markShiftThree,markShiftFour,markHolesOneD)
function RunwayGap(inputParam,model,myHolesNum,outParam,fixedParam,zoom){
var H=inputParam.H,H1=inputParam.H1,W=inputParam.W,W1=inputParam.W1,X=inputParam.X,Y=inputParam.Y,area=inputParam.area,myZoom=zoom*1,
plate=model.plate,markers=model.markers,
glassW=outParam.glassW,glassH=outParam.glassH,
markShift=fixedParam.markShift,markShiftF=fixedParam.markShiftF,markShiftTwo=fixedParam.markShiftTwo,
   markShiftThree=fixedParam.markShiftThree,markShiftFour=fixedParam.markShiftFour,markHolesOneD=fixedParam.markHolesOneD,
 
   d = H*1*myZoom,
      w= W*1*myZoom,
      w1= W1*1*myZoom,
      h1= H1*1*myZoom,
      h= H*1*myZoom,
      r=h/2,
      dd=Math.sqrt(r*r-h/2*h/2),
      ww=w1-w-dd,
        x0 = X*1*myZoom,
        y0 = Y*1*myZoom;
        /*
        d = H*1,
           w= W*1,
           w1= W1*1,
           h1= H1*1,
           h= H*1,
           r=h/2,
           dd=Math.sqrt(r*r-h/2*h/2),
           ww=w1-w-dd,
             x0 = X*1,
             y0 = Y*1;
             */
        if(d!==0){
         var TxtD="∮"+D;
        }
        if(h!==0){
         var TxtH=H;
        }
        if(h1!==0){
         var TxtH1=H1;
        }
        if(w!==0){
         var TxtW=W;
        }
        if(w1!==0){
         var TxtW1=W1;
        }
        if(x0!==0){          
        var TxtX=X;
        }
        if(y0!==0){          
         var TxtY=Y;
        }
  //在A区域 计算孔的位置       
  var xa=x0,ya=glassH * 1-y0,
  //B区域//只有Y标注没有X标注 
  xb=glassW * 1/2,yb=glassH * 1-y0,
  //C区域
  xc=glassW * 1-x0,yc=glassH * 1-y0,
  //D区域
  //只有X标注没有Y标注 
  xd=x0,yd=glassH * 1/2,
  //E区域
  //只有X标注没有Y标注 
  xe=glassW * 1/2,ye=glassH * 1/2,
  //F区域
  //只有X标注没有Y标注 
  xf=glassW * 1-x0,yf=glassH * 1/2,
  //在G区域 计算孔的位置
  xg=x0,yg=y0,
  //在H区域 计算孔的位置
  //只标注Y
  xh=glassW*1/2,yh=y0,
  //在I区域 计算孔的位置
  xi=glassW*1-x0,yi=y0;
  if(area==1){
    //画缺 A位置
        //4、跑道缺 A位置   H W D  width=w-d/2      
        var myRwgGapARect=new makerjs.models.Rectangle(ww+5, h1); 
            myRwgGapARect.origin=[xa-5,ya-h1];     
        var myRwgGapAOval=new makerjs.models.Oval(w+h, h);
            myRwgGapAOval.origin=[xa+ww-r,ya-h1/2-h/2];
        var myRwgGapA=makerjs.model.combineUnion(myRwgGapARect, myRwgGapAOval);  
     //画4、跑道缺标记 A位置 
  var myRwgGapAMakerW = new makerjs.models.Square(0); //D的起始点       
  myRwgGapAMakerW.origin = [xa,ya+y0];
  var myRwgGapAMakerW1 = new makerjs.models.Square(0); //W的起始点        
  myRwgGapAMakerW1.origin = [xa,ya+y0];
  var myRwgGapAMakerH = new makerjs.models.Square(0); //H的起始点        
  myRwgGapAMakerH.origin = [xa,ya+y0];
  var myRwgGapAMakerH1 = new makerjs.models.Square(0); //H的起始点        
  myRwgGapAMakerH1.origin = [xa,ya+y0];
  var myRwgGapAMakerY = new makerjs.models.Square(0); //Y的起始点        
  myRwgGapAMakerY.origin = [xa,ya+y0];
  // /*****************************************************************************/
  makerjs.model.addPath(myRwgGapAMakerW, new makerjs.paths.Line([0,0], [0, markShiftFour]));//Y轴加长标线
  makerjs.model.addPath(myRwgGapAMakerW, new makerjs.paths.Line([w1,0], [w1,0+markShiftFour]));//Y轴方向短线
  makerjs.model.addPath(myRwgGapAMakerW, new makerjs.paths.Line([w1-w,0+markShift], [w1-w,0+markShiftFour]));//Y轴方向短线
  makerjs.model.addPath(myRwgGapAMakerW, new makerjs.paths.Line([0,0], [0-markShiftFour, 0]));//X轴加长标线
  makerjs.model.addPath(myRwgGapAMakerW, new makerjs.paths.Line([0,0-y0], [0-markShiftFour, 0-y0]));//X轴方向短线
  makerjs.model.addPath(myRwgGapAMakerW, new makerjs.paths.Line([0,0-y0-h1], [0-markShiftFour, 0-y0-h1]));//X轴方向短线
  
  makerjs.model.addPath(myRwgGapAMakerW, new makerjs.paths.Line([w1,0-y0-h1/2+r], [w1+markShiftFour, 0-y0-h1/2+r]));//D相关 X轴方向短线上
  makerjs.model.addPath(myRwgGapAMakerW, new makerjs.paths.Line([w1,0-y0-h1/2-r], [w1+markShiftFour, 0-y0-h1/2-r]));//D相关 X轴方向短线下
  
  
  makerjs.model.addPath(myRwgGapAMakerH, new makerjs.paths.Line([w1,0-y0-h1/2+r], [w1, 0-y0-h1/2-r]));//H相关 Y轴方向短线
  makerjs.model.addCaption(myRwgGapAMakerH,TxtD, [w1+markShiftTwo,0-y0-h1/2], [w1+markShiftTwo, 0-y0-h1/2]);//H文字标注
  
  
  makerjs.model.addPath(myRwgGapAMakerY, new makerjs.paths.Line([0-markShiftTwo,0], [0-markShiftTwo, 0-y0]));//Y轴方向标注短线 
  makerjs.model.addCaption(myRwgGapAMakerY,TxtY, [0-markShiftFour,0], [0-markShiftFour, 0-y0]);//Y文字标注
  // /*****************************************************************************/
  makerjs.model.addPath(myRwgGapAMakerH1, new makerjs.paths.Line([0-markShiftTwo,0-y0], [0-markShiftTwo, 0-y0-h1]));//H1轴方向标注短线 
  makerjs.model.addCaption(myRwgGapAMakerH1,TxtH1, [0-markShiftFour, 0-y0-h1], [0-markShiftFour,0-y0]);//H1文字标注
  // /*****************************************************************************/
  makerjs.model.addPath(myRwgGapAMakerW1, new makerjs.paths.Line([0,markShift], [0+w1, markShift]));//W1短线标注
  makerjs.model.addCaption(myRwgGapAMakerW1, TxtW1, [0,markShiftTwo], [0+w1, markShiftTwo]);//W1文字标注
  // /*****************************************************************************/ 
  // /*****************************************************************************/
  makerjs.model.addPath(myRwgGapAMakerW, new makerjs.paths.Line([0+w1-w,markShiftThree], [0+w1, markShiftThree]));//W短线标注
  makerjs.model.addCaption(myRwgGapAMakerW, TxtW, [0+w1-w,markShiftFour], [0+w1, markShiftFour]);//W文字标注
  /*****************************************************************************/
  plate.models["myRwgGapA"+myHolesNum]=myRwgGapA;
  markers.models["myRwgGapA"+myHolesNum]=myRwgGapA;
  markers.models["myRwgGapAMakerW"+myHolesNum]=myRwgGapAMakerW;
  markers.models["myRwgGapAMakerW1"+myHolesNum]=myRwgGapAMakerW1;
  markers.models["myRwgGapAMakerH"+myHolesNum]=myRwgGapAMakerH;
  markers.models["myRwgGapAMakerH1"+myHolesNum]=myRwgGapAMakerH1;
  markers.models["myRwgGapAMakerY"+myHolesNum]=myRwgGapAMakerY;
  /*****************************************************************************/
  makerjs.model.combineSubtraction(markers.models.myRect, markers.models["myRwgGapA"+myHolesNum]);
  /*****************************************************************************/ 
     
  }else if(area==2){
          
  }else if(area==3){
   //画缺 C位置
        //4、跑道缺 C位置   H W D  width=w-d/2 
        var myRwgGapCRect=new makerjs.models.Rectangle(ww+5, h1); 
            myRwgGapCRect.origin=[xc-ww,yc-h1];     
        var myRwgGapCOval=new makerjs.models.Oval(w+h, h);
            myRwgGapCOval.origin=[xc-ww+r-w-h,yc-h1/2-h/2];
        var myRwgGapC=makerjs.model.combineUnion(myRwgGapCRect, myRwgGapCOval);  
     //画4、跑道缺标记 C位置 
  var myRwgGapCMakerW = new makerjs.models.Square(0); //D的起始点       
  myRwgGapCMakerW.origin = [xc,yc+y0];
  var myRwgGapCMakerW1 = new makerjs.models.Square(0); //W的起始点        
  myRwgGapCMakerW1.origin = [xc,yc+y0];
  var myRwgGapCMakerH = new makerjs.models.Square(0); //H的起始点        
  myRwgGapCMakerH.origin = [xc,yc+y0];
  var myRwgGapCMakerH1 = new makerjs.models.Square(0); //H的起始点        
  myRwgGapCMakerH1.origin = [xc,yc+y0];
  var myRwgGapCMakerY = new makerjs.models.Square(0); //Y的起始点        
  myRwgGapCMakerY.origin = [xc,yc+y0];
  // /*****************************************************************************/
  makerjs.model.addPath(myRwgGapCMakerW, new makerjs.paths.Line([0,0], [0, markShiftFour]));//Y轴加长标线
  makerjs.model.addPath(myRwgGapCMakerW, new makerjs.paths.Line([-w1,0], [-w1,0+markShiftFour]));//Y轴方向短线
  makerjs.model.addPath(myRwgGapCMakerW, new makerjs.paths.Line([-w1+w,0+markShift], [-w1+w,0+markShiftFour]));//Y轴方向短线
  makerjs.model.addPath(myRwgGapCMakerW, new makerjs.paths.Line([0,0], [0+markShiftFour, 0]));//X轴加长标线
  makerjs.model.addPath(myRwgGapCMakerW, new makerjs.paths.Line([0,0-y0], [0+markShiftFour, 0-y0]));//X轴方向短线
  makerjs.model.addPath(myRwgGapCMakerW, new makerjs.paths.Line([0,0-y0-h1], [0+markShiftFour, 0-y0-h1]));//X轴方向短线
  
  makerjs.model.addPath(myRwgGapCMakerW, new makerjs.paths.Line([-w1,0-y0-h1/2+r], [-w1-markShiftFour, 0-y0-h1/2+r]));//D相关 X轴方向短线上
  makerjs.model.addPath(myRwgGapCMakerW, new makerjs.paths.Line([-w1,0-y0-h1/2-r], [-w1-markShiftFour, 0-y0-h1/2-r]));//D相关 X轴方向短线下
  
  
  makerjs.model.addPath(myRwgGapCMakerH, new makerjs.paths.Line([-w1,0-y0-h1/2+r], [-w1, 0-y0-h1/2-r]));//H相关 Y轴方向短线
  makerjs.model.addCaption(myRwgGapCMakerH,TxtD, [-w1-markShiftTwo,0-y0-h1/2], [-w1-markShiftTwo, 0-y0-h1/2]);//H文字标注
  
  
  makerjs.model.addPath(myRwgGapCMakerY, new makerjs.paths.Line([0+markShiftTwo,0], [0+markShiftTwo, 0-y0]));//Y轴方向标注短线 
  makerjs.model.addCaption(myRwgGapCMakerY,TxtY, [0+markShiftFour,0], [0+markShiftFour, 0-y0]);//Y文字标注
  // // /*****************************************************************************/
  makerjs.model.addPath(myRwgGapCMakerH1, new makerjs.paths.Line([0+markShiftTwo,0-y0], [0+markShiftTwo, 0-y0-h1]));//H1轴方向标注短线 
  makerjs.model.addCaption(myRwgGapCMakerH1,TxtH1, [0+markShiftFour, 0-y0-h1], [0+markShiftFour,0-y0]);//H1文字标注
  // // /*****************************************************************************/
  makerjs.model.addPath(myRwgGapCMakerW1, new makerjs.paths.Line([0,markShift], [0-w1, markShift]));//W1短线标注
  makerjs.model.addCaption(myRwgGapCMakerW1, TxtW1, [0-w1, markShiftTwo], [0,markShiftTwo]);//W1文字标注
  // // /*****************************************************************************/ 
  // // /*****************************************************************************/
  makerjs.model.addPath(myRwgGapCMakerW, new makerjs.paths.Line([0-w1+w,markShiftThree], [0-w1, markShiftThree]));//W短线标注
  makerjs.model.addCaption(myRwgGapCMakerW, TxtW, [0-w1, markShiftFour], [0-w1+w,markShiftFour]);//W文字标注
  /*****************************************************************************/
  plate.models["myRwgGapC"+myHolesNum]=myRwgGapC;
  markers.models["myRwgGapC"+myHolesNum]=myRwgGapC;
  markers.models["myRwgGapCMakerW"+myHolesNum]=myRwgGapCMakerW;
  markers.models["myRwgGapCMakerW1"+myHolesNum]=myRwgGapCMakerW1;
  markers.models["myRwgGapCMakerH"+myHolesNum]=myRwgGapCMakerH;
  markers.models["myRwgGapCMakerH1"+myHolesNum]=myRwgGapCMakerH1;
  markers.models["myRwgGapCMakerY"+myHolesNum]=myRwgGapCMakerY;
  /*****************************************************************************/
  makerjs.model.combineSubtraction(markers.models.myRect, markers.models["myRwgGapC"+myHolesNum]);
  /*****************************************************************************/ 
            
  }else if(area==4){
   //画缺 D位置
        //4、跑道缺D位置   H W D  width=w-d/2      
        var myRwgGapDRect=new makerjs.models.Rectangle(ww+5, h1); 
            myRwgGapDRect.origin=[xd-5,yd-h1/2];     
        var myRwgGapDOval=new makerjs.models.Oval(w+h, h);
            myRwgGapDOval.origin=[xd+ww-r,yd-h/2];
        var myRwgGapD=makerjs.model.combineUnion(myRwgGapDRect, myRwgGapDOval);  
     //画4、跑道缺标记 D位置 
  var myRwgGapDMakerW = new makerjs.models.Square(0); //D的起始点       
  myRwgGapDMakerW.origin = [xd,yd];
  var myRwgGapDMakerW1 = new makerjs.models.Square(0); //W的起始点        
  myRwgGapDMakerW1.origin = [xd,yd];
  var myRwgGapDMakerH = new makerjs.models.Square(0); //H的起始点        
  myRwgGapDMakerH.origin = [xd,yd];
  var myRwgGapDMakerH1 = new makerjs.models.Square(0); //H的起始点        
  myRwgGapDMakerH1.origin = [xd,yd];
  var myRwgGapDMakerY = new makerjs.models.Square(0); //Y的起始点        
  myRwgGapDMakerY.origin = [xd,yd];
  // /*****************************************************************************/
  makerjs.model.addPath(myRwgGapDMakerW, new makerjs.paths.Line([0,yd], [0, yd+markShiftFour]));//Y轴加长标线
  makerjs.model.addPath(myRwgGapDMakerW, new makerjs.paths.Line([w1,yd], [w1,yd+markShiftFour]));//Y轴方向短线
  makerjs.model.addPath(myRwgGapDMakerW, new makerjs.paths.Line([w1-w,yd+markShift], [w1-w,yd+markShiftFour]));//Y轴方向短线
  
  makerjs.model.addPath(myRwgGapDMakerW, new makerjs.paths.Line([w1,0+h/2], [w1+markShiftFour, 0+h/2]));//D相关 X轴方向短线上
  makerjs.model.addPath(myRwgGapDMakerW, new makerjs.paths.Line([w1,0-h/2], [w1+markShiftFour, 0-h/2]));//D相关 X轴方向短线下
  
  
  makerjs.model.addPath(myRwgGapDMakerH, new makerjs.paths.Line([w1,0+h/2], [w1, 0-h/2]));//H相关 Y轴方向短线
  makerjs.model.addCaption(myRwgGapDMakerH,TxtD, [w1+markShiftTwo, 0-h/2], [w1+markShiftTwo,0+h/2]);//H文字标注
  
  
  makerjs.model.addPath(myRwgGapDMakerY, new makerjs.paths.Line([0-markShiftTwo,0], [0-markShiftTwo, 0-y0]));//Y轴方向标注短线 
  makerjs.model.addCaption(myRwgGapDMakerY,TxtY, [0-markShiftFour,0], [0-markShiftFour, 0-y0]);//Y文字标注
  // /*****************************************************************************/
  makerjs.model.addPath(myRwgGapDMakerH1, new makerjs.paths.Line([0+markShiftTwo,h1/2], [0+markShiftTwo, -h1/2]));//H1轴方向标注短线 
  makerjs.model.addCaption(myRwgGapDMakerH1,TxtH1, [0+markShiftFour,-h1/2], [0+markShiftFour, h1/2]);//H1文字标注
  // /*****************************************************************************/
  makerjs.model.addPath(myRwgGapDMakerW1, new makerjs.paths.Line([0,yd+markShift], [0+w1, yd+markShift]));//W1短线标注
  makerjs.model.addCaption(myRwgGapDMakerW1, TxtW1, [0,yd+markShiftTwo], [0+w1, yd+markShiftTwo]);//W1文字标注
  // /*****************************************************************************/ 
  // /*****************************************************************************/
  makerjs.model.addPath(myRwgGapDMakerW, new makerjs.paths.Line([0+w1-w,yd+markShiftThree], [0+w1, yd+markShiftThree]));//W短线标注
  makerjs.model.addCaption(myRwgGapDMakerW, TxtW, [0+w1-w,yd+markShiftFour], [0+w1, yd+markShiftFour]);//W文字标注
  /*****************************************************************************/
  plate.models["myRwgGapD"+myHolesNum]=myRwgGapD;
  markers.models["myRwgGapD"+myHolesNum]=myRwgGapD;
  markers.models["myRwgGapDMakerW"+myHolesNum]=myRwgGapDMakerW;
  markers.models["myRwgGapDMakerW1"+myHolesNum]=myRwgGapDMakerW1;
  markers.models["myRwgGapDMakerH"+myHolesNum]=myRwgGapDMakerH;
  markers.models["myRwgGapDMakerH1"+myHolesNum]=myRwgGapDMakerH1;
  markers.models["myRwgGapDMakerY"+myHolesNum]=myRwgGapDMakerY;
  /*****************************************************************************/
  makerjs.model.combineSubtraction(markers.models.myRect, markers.models["myRwgGapD"+myHolesNum]);
  /*****************************************************************************/ 
             
  }else if(area==5){
          
  }else if(area==6){
  //画缺 F位置
        //4、跑道缺 F位置   H W D  width=w-d/2 
        var myRwgGapFRect=new makerjs.models.Rectangle(ww+5, h1); 
            myRwgGapFRect.origin=[xf-ww,yf-h1/2];     
        var myRwgGapFOval=new makerjs.models.Oval(w+h, h);
            myRwgGapFOval.origin=[xf-ww+r-w-h,yf-h/2];
        var myRwgGapF=makerjs.model.combineUnion(myRwgGapFRect, myRwgGapFOval);  
     //画4、跑道缺标记 F位置 
  var myRwgGapFMakerW = new makerjs.models.Square(0); //D的起始点       
  myRwgGapFMakerW.origin = [xf,yf];
  var myRwgGapFMakerW1 = new makerjs.models.Square(0); //W的起始点        
  myRwgGapFMakerW1.origin = [xf,yf];
  var myRwgGapFMakerH = new makerjs.models.Square(0); //H的起始点        
  myRwgGapFMakerH.origin = [xf,yf];
  var myRwgGapFMakerH1 = new makerjs.models.Square(0); //H的起始点        
  myRwgGapFMakerH1.origin = [xf,yf];
  var myRwgGapFMakerY = new makerjs.models.Square(0); //Y的起始点        
  myRwgGapFMakerY.origin = [xf,yf];
  // /*****************************************************************************/
  makerjs.model.addPath(myRwgGapFMakerW, new makerjs.paths.Line([0,yf], [0, yf+markShiftFour]));//Y轴加长标线
  makerjs.model.addPath(myRwgGapFMakerW, new makerjs.paths.Line([-w1,yf], [-w1,yf+markShiftFour]));//Y轴方向短线
  makerjs.model.addPath(myRwgGapFMakerW, new makerjs.paths.Line([-w1+w,yf+markShift], [-w1+w,yf+markShiftFour]));//Y轴方向短线
  makerjs.model.addPath(myRwgGapFMakerW, new makerjs.paths.Line([0,h1/2], [0+markShiftFour, h1/2]));//X轴方向短线
  makerjs.model.addPath(myRwgGapFMakerW, new makerjs.paths.Line([0,0-h1/2], [0+markShiftFour, 0-h1/2]));//X轴方向短线
  
  makerjs.model.addPath(myRwgGapFMakerW, new makerjs.paths.Line([-w1,0+h/2], [-w1-markShiftFour, 0+h/2]));//D相关 X轴方向短线上
  makerjs.model.addPath(myRwgGapFMakerW, new makerjs.paths.Line([-w1,0-h/2], [-w1-markShiftFour, 0-h/2]));//D相关 X轴方向短线下
  
  
  makerjs.model.addPath(myRwgGapFMakerH, new makerjs.paths.Line([-w1,0+h/2], [-w1, 0-h/2]));//H相关 Y轴方向短线
  makerjs.model.addCaption(myRwgGapFMakerH,TxtD, [-w1-markShiftTwo, 0-h/2], [-w1-markShiftTwo,0+h/2]);//H文字标注
  
  
  makerjs.model.addPath(myRwgGapFMakerY, new makerjs.paths.Line([0+markShiftTwo,0], [0+markShiftTwo, 0-y0]));//Y轴方向标注短线 
  makerjs.model.addCaption(myRwgGapFMakerY,TxtY, [0+markShiftFour,0], [0+markShiftFour, 0-y0]);//Y文字标注
  // // /*****************************************************************************/
  makerjs.model.addPath(myRwgGapFMakerH1, new makerjs.paths.Line([0+markShiftTwo,h1/2], [0+markShiftTwo, -h1/2]));//H1轴方向标注短线 
  makerjs.model.addCaption(myRwgGapFMakerH1,TxtH1, [0+markShiftFour,-h1/2], [0+markShiftFour, h1/2]);//H1文字标注
  // // /*****************************************************************************/
  makerjs.model.addPath(myRwgGapFMakerW1, new makerjs.paths.Line([0,yf+markShift], [0-w1, yf+markShift]));//W1短线标注
  makerjs.model.addCaption(myRwgGapFMakerW1, TxtW1, [0-w1, yf+markShiftTwo], [0,yf+markShiftTwo]);//W1文字标注
  // // /*****************************************************************************/ 
  // // /*****************************************************************************/
  makerjs.model.addPath(myRwgGapFMakerW, new makerjs.paths.Line([0-w1+w,yf+markShiftThree], [0-w1, yf+markShiftThree]));//W短线标注
  makerjs.model.addCaption(myRwgGapFMakerW, TxtW, [0-w1, yf+markShiftFour], [0-w1+w,yf+markShiftFour]);//W文字标注
  /*****************************************************************************/
  plate.models["myRwgGapF"+myHolesNum]=myRwgGapF;
  markers.models["myRwgGapF"+myHolesNum]=myRwgGapF;
  markers.models["myRwgGapFMakerW"+myHolesNum]=myRwgGapFMakerW;
  markers.models["myRwgGapFMakerW1"+myHolesNum]=myRwgGapFMakerW1;
  markers.models["myRwgGapFMakerH"+myHolesNum]=myRwgGapFMakerH;
  markers.models["myRwgGapFMakerH1"+myHolesNum]=myRwgGapFMakerH1;
  markers.models["myRwgGapFMakerY"+myHolesNum]=myRwgGapFMakerY;
  /*****************************************************************************/
  makerjs.model.combineSubtraction(markers.models.myRect, markers.models["myRwgGapF"+myHolesNum]);
  /*****************************************************************************/ 
       
  }else if(area==7){
  //画缺 G位置
        //4、跑道缺 G位置   H W D  width=w-d/2      
        var myRwgGapGRect=new makerjs.models.Rectangle(ww+5, h1); 
            myRwgGapGRect.origin=[xg-5,yg];     
        var myRwgGapGOval=new makerjs.models.Oval(w+h, h);
            myRwgGapGOval.origin=[xg+ww-r,yg-h/2+h1/2];
        var myRwgGapG=makerjs.model.combineUnion(myRwgGapGRect, myRwgGapGOval);  
     //画4、跑道缺标记 G位置 
  var myRwgGapGMakerW = new makerjs.models.Square(0); //D的起始点       
  myRwgGapGMakerW.origin = [xg,yg-y0];
  var myRwgGapGMakerW1 = new makerjs.models.Square(0); //W的起始点        
  myRwgGapGMakerW1.origin = [xg,yg-y0];
  var myRwgGapGMakerH = new makerjs.models.Square(0); //H的起始点        
  myRwgGapGMakerH.origin = [xg,yg-y0];
  var myRwgGapGMakerH1 = new makerjs.models.Square(0); //H的起始点        
  myRwgGapGMakerH1.origin = [xg,yg-y0];
  var myRwgGapGMakerY = new makerjs.models.Square(0); //Y的起始点        
  myRwgGapGMakerY.origin = [xg,yg-y0];
  // /*****************************************************************************/
  makerjs.model.addPath(myRwgGapGMakerW, new makerjs.paths.Line([0,0], [0, -markShiftFour]));//Y轴加长标线
  makerjs.model.addPath(myRwgGapGMakerW, new makerjs.paths.Line([w1,0], [w1,0-markShiftFour]));//Y轴方向短线
  makerjs.model.addPath(myRwgGapGMakerW, new makerjs.paths.Line([w1-w,0-markShift], [w1-w,0-markShiftFour]));//Y轴方向短线
  makerjs.model.addPath(myRwgGapGMakerW, new makerjs.paths.Line([0,0], [0-markShiftFour, 0]));//X轴加长标线
  makerjs.model.addPath(myRwgGapGMakerW, new makerjs.paths.Line([0,0+y0], [0-markShiftFour, 0+y0]));//X轴方向短线
  makerjs.model.addPath(myRwgGapGMakerW, new makerjs.paths.Line([0,0+y0+h1], [0-markShiftFour, 0+y0+h1]));//X轴方向短线
  
  makerjs.model.addPath(myRwgGapGMakerW, new makerjs.paths.Line([w1,0+y0+h1/2-r], [w1+markShiftFour, 0+y0+h1/2-r]));//D相关 X轴方向短线上
  makerjs.model.addPath(myRwgGapGMakerW, new makerjs.paths.Line([w1,0+y0+h1/2+r], [w1+markShiftFour, 0+y0+h1/2+r]));//D相关 X轴方向短线下
  
  
  makerjs.model.addPath(myRwgGapGMakerH, new makerjs.paths.Line([w1,0+y0+h1/2-r], [w1, 0+y0+h1/2+r]));//H相关 Y轴方向短线
  makerjs.model.addCaption(myRwgGapGMakerH,TxtD, [w1+markShiftTwo,0+y0+h1/2], [w1+markShiftTwo, 0+y0+h1/2]);//H文字标注
  
  
  makerjs.model.addPath(myRwgGapGMakerY, new makerjs.paths.Line([0-markShiftTwo,0], [0-markShiftTwo, 0+y0]));//Y轴方向标注短线 
  makerjs.model.addCaption(myRwgGapGMakerY,TxtY, [0-markShiftFour,0], [0-markShiftFour, 0+y0]);//Y文字标注
  // /*****************************************************************************/
  makerjs.model.addPath(myRwgGapGMakerH1, new makerjs.paths.Line([0-markShiftTwo,0+y0], [0-markShiftTwo, 0+y0+h1]));//H1轴方向标注短线 
  makerjs.model.addCaption(myRwgGapGMakerH1,TxtH1, [0-markShiftFour,0+y0], [0-markShiftFour, 0+y0+h1]);//H1文字标注
  // /*****************************************************************************/
  makerjs.model.addPath(myRwgGapGMakerW1, new makerjs.paths.Line([0,-markShiftTwo], [0+w1, -markShiftTwo]));//W1短线标注
  makerjs.model.addCaption(myRwgGapGMakerW1, TxtW1, [0,-markShiftFour], [0+w1, -markShiftFour]);//W1文字标注
  // /*****************************************************************************/ 
  // /*****************************************************************************/
  makerjs.model.addPath(myRwgGapGMakerW, new makerjs.paths.Line([0+w1-w,-markShiftThree], [0+w1, -markShiftThree]));//W短线标注
  makerjs.model.addCaption(myRwgGapGMakerW, TxtW, [0+w1-w,-markShiftFour], [0+w1, -markShiftFour]);//W文字标注
  /*****************************************************************************/
  plate.models["myRwgGapG"+myHolesNum]=myRwgGapG;
  markers.models["myRwgGapG"+myHolesNum]=myRwgGapG;
  markers.models["myRwgGapGMakerW"+myHolesNum]=myRwgGapGMakerW;
  markers.models["myRwgGapGMakerW1"+myHolesNum]=myRwgGapGMakerW1;
  markers.models["myRwgGapGMakerH"+myHolesNum]=myRwgGapGMakerH;
  markers.models["myRwgGapGMakerH1"+myHolesNum]=myRwgGapGMakerH1;
  markers.models["myRwgGapGMakerY"+myHolesNum]=myRwgGapGMakerY;
  /*****************************************************************************/
  makerjs.model.combineSubtraction(markers.models.myRect, markers.models["myRwgGapG"+myHolesNum]);
  /*****************************************************************************/ 
             
  }else if(area==8){
          
  }else if(area==9){
  //画缺 I位置
        //4、跑道缺 I位置   H W D  width=w-d/2 
        var myRwgGapIRect=new makerjs.models.Rectangle(ww+5, h1); 
            myRwgGapIRect.origin=[xi-ww,yi];     
        var myRwgGapIOval=new makerjs.models.Oval(w+h, h);
            myRwgGapIOval.origin=[xi-ww+r-w-h,yi-h/2+h1/2];
        var myRwgGapI=makerjs.model.combineUnion(myRwgGapIRect, myRwgGapIOval);  
  //    //画4、跑道缺标记 C位置 
  var myRwgGapIMakerW = new makerjs.models.Square(0); //D的起始点       
  myRwgGapIMakerW.origin = [xi,yi-y0];
  var myRwgGapIMakerW1 = new makerjs.models.Square(0); //W的起始点        
  myRwgGapIMakerW1.origin = [xi,yi-y0];
  var myRwgGapIMakerH = new makerjs.models.Square(0); //H的起始点        
  myRwgGapIMakerH.origin = [xi,yi-y0];
  var myRwgGapIMakerH1 = new makerjs.models.Square(0); //H的起始点        
  myRwgGapIMakerH1.origin = [xi,yi-y0];
  var myRwgGapIMakerY = new makerjs.models.Square(0); //Y的起始点        
  myRwgGapIMakerY.origin = [xi,yi-y0];
  // // /*****************************************************************************/
  makerjs.model.addPath(myRwgGapIMakerW, new makerjs.paths.Line([0,0], [0, -markShiftFour]));//Y轴加长标线
  makerjs.model.addPath(myRwgGapIMakerW, new makerjs.paths.Line([-w1,0], [-w1,0-markShiftFour]));//Y轴方向短线
  makerjs.model.addPath(myRwgGapIMakerW, new makerjs.paths.Line([-w1+w,0-markShift], [-w1+w,0-markShiftFour]));//Y轴方向短线
  makerjs.model.addPath(myRwgGapIMakerW, new makerjs.paths.Line([0,0], [0+markShiftFour, 0]));//X轴加长标线
  makerjs.model.addPath(myRwgGapIMakerW, new makerjs.paths.Line([0,0+y0], [0+markShiftFour, 0+y0]));//X轴方向短线
  makerjs.model.addPath(myRwgGapIMakerW, new makerjs.paths.Line([0,0+y0+h1], [0+markShiftFour, 0+y0+h1]));//X轴方向短线
  
  makerjs.model.addPath(myRwgGapIMakerW, new makerjs.paths.Line([-w1,0+y0+h1/2-r], [-w1-markShiftFour, 0+y0+h1/2-r]));//D相关 X轴方向短线上
  makerjs.model.addPath(myRwgGapIMakerW, new makerjs.paths.Line([-w1,0+y0+h1/2+r], [-w1-markShiftFour, 0+y0+h1/2+r]));//D相关 X轴方向短线下
  
  makerjs.model.addPath(myRwgGapIMakerH, new makerjs.paths.Line([-w1,0+y0+h1/2-r], [-w1, 0+y0+h1/2+r]));//H相关 Y轴方向短线
  makerjs.model.addCaption(myRwgGapIMakerH,TxtD, [-w1-markShiftTwo,0+y0+h1/2], [-w1-markShiftTwo, 0+y0+h1/2]);//H文字标注
  
  makerjs.model.addPath(myRwgGapIMakerY, new makerjs.paths.Line([0+markShiftTwo,0], [0+markShiftTwo, 0+y0]));//Y轴方向标注短线 
  makerjs.model.addCaption(myRwgGapIMakerY,TxtY, [0+markShiftFour,0], [0+markShiftFour, 0+y0]);//Y文字标注
  // // // /*****************************************************************************/
  makerjs.model.addPath(myRwgGapIMakerH1, new makerjs.paths.Line([0+markShiftTwo,0+y0], [0+markShiftTwo, 0+y0+h1]));//H1轴方向标注短线 
  makerjs.model.addCaption(myRwgGapIMakerH1,TxtH1, [0+markShiftFour,0+y0], [0+markShiftFour, 0+y0+h1]);//H1文字标注
  // // // /*****************************************************************************/
  makerjs.model.addPath(myRwgGapIMakerW1, new makerjs.paths.Line([0,-markShiftTwo], [0-w1, -markShiftTwo]));//W1短线标注
  makerjs.model.addCaption(myRwgGapIMakerW1, TxtW1, [0-w1, -markShiftFour], [0,-markShiftFour]);//W1文字标注
  /*****************************************************************************/ 
   /*****************************************************************************/
  makerjs.model.addPath(myRwgGapIMakerW, new makerjs.paths.Line([0-w1+w,-markShiftThree], [0-w1, -markShiftThree]));//W短线标注
  makerjs.model.addCaption(myRwgGapIMakerW, TxtW, [0-w1, -markShiftFour], [0-w1+w,-markShiftFour]);//W文字标注
  /*****************************************************************************/
  plate.models["myRwgGapI"+myHolesNum]=myRwgGapI;
  markers.models["myRwgGapI"+myHolesNum]=myRwgGapI;
  markers.models["myRwgGapIMakerW"+myHolesNum]=myRwgGapIMakerW;
  markers.models["myRwgGapIMakerW1"+myHolesNum]=myRwgGapIMakerW1;
  markers.models["myRwgGapIMakerH"+myHolesNum]=myRwgGapIMakerH;
  markers.models["myRwgGapIMakerH1"+myHolesNum]=myRwgGapIMakerH1;
  markers.models["myRwgGapIMakerY"+myHolesNum]=myRwgGapIMakerY;
  /*****************************************************************************/
  makerjs.model.combineSubtraction(markers.models.myRect, markers.models["myRwgGapI"+myHolesNum]);
  /*****************************************************************************/       
  }

myHolesNum++;
return{plate, markers,myHolesNum}
};


export default RunwayGap;