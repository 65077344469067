//缺1.3、蝴蝶
import makerjs from "makerjs";
// inputParam,model,myHolesNum,outParam,fixedParam
// (D,X,Y,area,plate,markers,myHolesNum,glassW,glassH,markShift,markShiftTwo,markShiftThree,markShiftFour,markHolesOneD)
function ButterflyGap3(inputParam,model,myHolesNum,outParam,fixedParam,zoom){
var R1=inputParam.R1,R2=inputParam.R2,W=inputParam.W,W1=inputParam.W1,W2=inputParam.W2,H=inputParam.H,H1=inputParam.H1,X=inputParam.X,Y=inputParam.Y,area=inputParam.area,myZoom=zoom*1,
plate=model.plate,markers=model.markers,
glassW=outParam.glassW,glassH=outParam.glassH,
markShift=fixedParam.markShift,markShiftF=fixedParam.markShiftF,markShiftTwo=fixedParam.markShiftTwo,
   markShiftThree=fixedParam.markShiftThree,markShiftFour=fixedParam.markShiftFour,markHolesOneD=fixedParam.markHolesOneD,
 
   r1 = R1*1*myZoom,
      r2= R2*1*myZoom,
      w= W*1*myZoom,
      w1= W1*1*myZoom,
      w2= W2*1*myZoom,
      h = H*1*myZoom,
      h1 = H1*1*myZoom,
      dd=r2/Math.sqrt(2),
        x0 = X*1*myZoom,
        y0 = Y*1*myZoom;
        /*
        r1 = R1*1,
           r2= R2*1,
           w= W*1,
           w1= W1*1,
           w2= W2*1,
           h = H*1,
           h1 = H1*1,
           dd=r2/Math.sqrt(2),
             x0 = X*1,
             y0 = Y*1;
             */
        if(r1!==0){
         var TxtR1="R1="+R1;
        }
        if(r2!==0){
         var TxtR2="R2="+R2;
        }
        if(h!==0){
         var TxtH=H;
        }
        if(h1!==0){
         var TxtH1=H1;
        }
        if(w!==0){
         var TxtW=W;
        }
        if(w1!==0){
         var TxtW1=W1;
        }
        if(w2!==0){
         var TxtW2=W2;
        }
        if(h1!==0){
         var TxtH1=H1;
        }
        if(y0!==0){          
         var TxtY=Y;
        }
  //在A区域 计算孔的位置       
  var xa=x0,ya=glassH * 1-y0,
  //B区域//只有Y标注没有X标注 
  xb=glassW * 1/2,yb=glassH * 1-y0,
  //C区域
  xc=glassW * 1-x0,yc=glassH * 1-y0,
  //D区域
  //只有X标注没有Y标注 
  xd=x0,yd=glassH * 1/2,
  //E区域
  //只有X标注没有Y标注 
  xe=glassW * 1/2,ye=glassH * 1/2,
  //F区域
  //只有X标注没有Y标注 
  xf=glassW * 1-x0,yf=glassH * 1/2,
  //在G区域 计算孔的位置
  xg=x0,yg=y0,
  //在H区域 计算孔的位置
  //只标注Y
  xh=glassW*1/2,yh=y0,
  //在I区域 计算孔的位置
  xi=glassW*1-x0,yi=y0;
  if(area==1){
    //画缺 A位置
        //1、3蝴蝶3 A位置   H W D  width=w-d/2  
        var myBfg3GapARect=new makerjs.models.Rectangle(w1+r2, h1); 
        myBfg3GapARect.origin=[xa-r2,ya-h1];     
        var myBfg3GapABoltRectangle=new makerjs.models.BoltRectangle(w2+r2,h,r2);
        myBfg3GapABoltRectangle.origin=[xa-r2,ya-h]; 
     var myBfg3GapA=new makerjs.model.combineUnion(myBfg3GapARect, myBfg3GapABoltRectangle);
     
     //画1、3蝴蝶3标记 A位置 
  var myBfg3GapAMakerW = new makerjs.models.Square(0); //D的起始点       
  myBfg3GapAMakerW.origin = [xa,ya+y0];
  var myBfg3GapAMakerW1 = new makerjs.models.Square(0); //D的起始点       
  myBfg3GapAMakerW1.origin = [xa,ya+y0];
  var myBfg3GapAMakerW2 = new makerjs.models.Square(0); //D的起始点       
  myBfg3GapAMakerW2.origin = [xa,ya+y0];
  var myBfg3GapAMakerH = new makerjs.models.Square(0); //W的起始点        
  myBfg3GapAMakerH.origin = [xa,ya+y0];
  var myBfg3GapAMakerH1 = new makerjs.models.Square(0); //W的起始点        
  myBfg3GapAMakerH1.origin = [xa,ya+y0];
  var myBfg3GapAMakerR1 = new makerjs.models.Square(0); //H的起始点        
  myBfg3GapAMakerR1.origin = [xa,ya+y0];
  var myBfg3GapAMakerR2 = new makerjs.models.Square(0); //H的起始点        
  myBfg3GapAMakerR2.origin = [xa,ya+y0];
  var myBfg3GapAMakerY = new makerjs.models.Square(0); //H的起始点        
  myBfg3GapAMakerY.origin = [xa,ya+y0];
  
  /*****************************************************************************/
  
  makerjs.model.addPath(myBfg3GapAMakerW, new makerjs.paths.Line([0,0], [0, markShiftThree]));//Y轴加长标线
  
  makerjs.model.addPath(myBfg3GapAMakerW, new makerjs.paths.Line([w2,-y0], [w2,0+markShiftFour]));//Y轴方向短线  W2
  
  makerjs.model.addPath(myBfg3GapAMakerW, new makerjs.paths.Line([0,-y0], [0,-y0+markShift]));//Y轴方向短线  W1
  makerjs.model.addPath(myBfg3GapAMakerW, new makerjs.paths.Line([w1,-y0], [w1,-y0+markShift]));//Y轴方向短线  W1
  
  makerjs.model.addPath(myBfg3GapAMakerW, new makerjs.paths.Line([0,-y0-h1], [0,-y0-h1+markShift]));//Y轴方向短线  W
  makerjs.model.addPath(myBfg3GapAMakerW, new makerjs.paths.Line([w,-y0-h1], [w,-y0-h1+markShift]));//Y轴方向短线  W
  
  makerjs.model.addPath(myBfg3GapAMakerW, new makerjs.paths.Line([0,0], [0-markShiftThree, 0]));//X轴加长标线
  makerjs.model.addPath(myBfg3GapAMakerW, new makerjs.paths.Line([0-markShift,0-y0], [0-markShiftThree, 0-y0]));//X轴方向短线
  makerjs.model.addPath(myBfg3GapAMakerW, new makerjs.paths.Line([w2,0-y0], [w2+markShiftThree, 0-y0]));//X轴方向短线 h  up
  makerjs.model.addPath(myBfg3GapAMakerW, new makerjs.paths.Line([w2,0-y0-h], [w2+markShiftThree, 0-y0-h]));//X轴方向短线 h  dn
  
  makerjs.model.addPath(myBfg3GapAMakerR1, new makerjs.paths.Line([w,0-y0-h], [w-dd-markHolesOneD,0-y0-h-dd-markHolesOneD]));//R1相关 X轴方向短线上
  makerjs.model.addPath(myBfg3GapAMakerR1, new makerjs.paths.Line([w-dd-markHolesOneD,0-y0-h-dd-markHolesOneD], [w-dd-markHolesOneD,0-y0-h-dd-markHolesOneD-markShiftFour]));//R1相关 X轴方向短线上
  makerjs.model.addCaption(myBfg3GapAMakerR1,TxtR1,[w-markShift-dd-markHolesOneD,0-y0-h-dd-markHolesOneD], [w-markShift-dd-markHolesOneD,0-y0-h-dd-markHolesOneD-markShiftFour]);//R1相关 X轴方向短线下
  
  makerjs.model.addPath(myBfg3GapAMakerR2, new makerjs.paths.Line([w2,0-y0-h], [w2+dd+markHolesOneD,0-y0-h-dd-markHolesOneD]));//R2相关 X轴方向短线上
  makerjs.model.addPath(myBfg3GapAMakerR2, new makerjs.paths.Line([w2+dd+markHolesOneD,0-y0-h-dd-markHolesOneD], [w2+dd+markHolesOneD,0-y0-h-dd-markHolesOneD-markShiftFour]));//R2相关 X轴方向短线上
  makerjs.model.addCaption(myBfg3GapAMakerR2,TxtR2,[w2+markShift+dd+markHolesOneD,0-y0-h-dd-markHolesOneD], [w2+markShift+dd+markHolesOneD,0-y0-h-dd-markHolesOneD-markShiftFour]);//R2相关 X轴方向短线下
  
  
  makerjs.model.addPath(myBfg3GapAMakerH, new makerjs.paths.Line([w2+markShift,0-y0], [w2+markShift, 0-y0-h]));//H相关 Y轴方向短线
  makerjs.model.addCaption(myBfg3GapAMakerH,TxtH, [w2+markShiftThree, 0-y0-h], [w2+markShiftThree,0-y0]);//H文字标注
  
  
  makerjs.model.addPath(myBfg3GapAMakerH1, new makerjs.paths.Line([0+markShift,0-y0], [0+markShift, 0-y0-h1]));//H1相关 Y轴方向短线
  makerjs.model.addCaption(myBfg3GapAMakerH1,TxtH1, [0+markShiftThree, 0-y0-h1], [0+markShiftThree,0-y0]);//H1文字标注
  
  
  makerjs.model.addPath(myBfg3GapAMakerY, new makerjs.paths.Line([0-markShiftTwo,0], [0-markShiftTwo, 0-y0]));//Y轴方向标注短线 
  makerjs.model.addCaption(myBfg3GapAMakerY,TxtY,  [0-markShiftFour, 0-y0],[0-markShiftFour,0]);//Y文字标注
  
  // /*****************************************************************************/
  
  
  makerjs.model.addPath(myBfg3GapAMakerW, new makerjs.paths.Line([0,-y0-h1+markShiftF], [w, -y0-h1+markShiftF]));//W轴方向标注短线 
  makerjs.model.addCaption(myBfg3GapAMakerW,TxtW, [0,-y0-h1+markShift], [w, -y0-h1+markShift]);//W文字标注
  
  makerjs.model.addPath(myBfg3GapAMakerW1, new makerjs.paths.Line([0,-y0+markShiftF], [w1, -y0+markShiftF]));//W1轴方向标注短线 
  makerjs.model.addCaption(myBfg3GapAMakerW1,TxtW1, [0,-y0+markShiftTwo], [w1, -y0+markShiftTwo]);//W1文字标注
  
  makerjs.model.addPath(myBfg3GapAMakerW2, new makerjs.paths.Line([0,0+markShiftF], [w2, 0+markShiftF]));//W2轴方向标注短线 
  makerjs.model.addCaption(myBfg3GapAMakerW2,TxtW2, [0,0+markShiftTwo], [w2, 0+markShiftTwo]);//W2文字标注
  
  // // /*****************************************************************************/
  // /*****************************************************************************/    
  
  plate.models["myBfg3GapA"+myHolesNum]=myBfg3GapA;
  markers.models["myBfg3GapA"+myHolesNum]=myBfg3GapA;
  markers.models["myBfg3GapAMakerW"+myHolesNum]=myBfg3GapAMakerW;
  markers.models["myBfg3GapAMakerW1"+myHolesNum]=myBfg3GapAMakerW1;
  markers.models["myBfg3GapAMakerW2"+myHolesNum]=myBfg3GapAMakerW2;
  markers.models["myBfg3GapAMakerH1"+myHolesNum]=myBfg3GapAMakerH1;
  markers.models["myBfg3GapAMakerH"+myHolesNum]=myBfg3GapAMakerH;
  markers.models["myBfg3GapAMakerR1"+myHolesNum]=myBfg3GapAMakerR1;
  markers.models["myBfg3GapAMakerR2"+myHolesNum]=myBfg3GapAMakerR2;
  markers.models["myBfg3GapAMakerY"+myHolesNum]=myBfg3GapAMakerY;
  /*****************************************************************************/
  makerjs.model.combineSubtraction(markers.models["myRect"], markers.models["myBfg3GapA"+myHolesNum]); 
  
  var c = makerjs.model.findSingleChain(markers.models["myBfg3GapA"+myHolesNum]);
     var fillets = makerjs.chain.fillet(c, r1);
    markers.models["filletsA"+myHolesNum] = fillets;
  
  console.log("markers==",markers);
  /*****************************************************************************/ 
  
  /*****************************************************************************/ 
  
   }else if(area==2){
          
  }else if(area==3){
          //画缺 C位置
        //1、3蝴蝶3 C位置   H W D  width=w-d/2
        var myBfg3GapCRect=new makerjs.models.Rectangle(w1+r2, h1); 
        myBfg3GapCRect.origin=[xc-r2-w,yc-h1];     
        var myBfg3GapCBoltRectangle=new makerjs.models.BoltRectangle(w2+r2,h,r2);
        myBfg3GapCBoltRectangle.origin=[xc-r2-w,yc-h];  
     var myBfg3GapC=new makerjs.model.combineUnion(myBfg3GapCRect, myBfg3GapCBoltRectangle);
     //画1、3蝴蝶3标记 C位置 
  var myBfg3GapCMakerW = new makerjs.models.Square(0); //D的起始点       
  myBfg3GapCMakerW.origin = [xc,yc+y0];
  var myBfg3GapCMakerW1 = new makerjs.models.Square(0); //D的起始点       
  myBfg3GapCMakerW1.origin = [xc,yc+y0];
  var myBfg3GapCMakerW2 = new makerjs.models.Square(0); //D的起始点       
  myBfg3GapCMakerW2.origin = [xc,yc+y0];
  var myBfg3GapCMakerH = new makerjs.models.Square(0); //W的起始点        
  myBfg3GapCMakerH.origin = [xc,yc+y0];
  var myBfg3GapCMakerH1 = new makerjs.models.Square(0); //W的起始点        
  myBfg3GapCMakerH1.origin = [xc,yc+y0];
  var myBfg3GapCMakerR1 = new makerjs.models.Square(0); //H的起始点        
  myBfg3GapCMakerR1.origin = [xc,yc+y0];
  var myBfg3GapCMakerR2 = new makerjs.models.Square(0); //H的起始点        
  myBfg3GapCMakerR2.origin = [xc,yc+y0];
  var myBfg3GapCMakerY = new makerjs.models.Square(0); //H的起始点        
  myBfg3GapCMakerY.origin = [xc,yc+y0];
  /*****************************************************************************/
  makerjs.model.addPath(myBfg3GapCMakerW, new makerjs.paths.Line([0,0], [0, markShiftThree]));//Y轴加长标线
  
  makerjs.model.addPath(myBfg3GapCMakerW, new makerjs.paths.Line([-w2,-y0], [-w2,0+markShiftFour]));//Y轴方向短线  W2
  
  makerjs.model.addPath(myBfg3GapCMakerW, new makerjs.paths.Line([0,-y0], [0,-y0+markShift]));//Y轴方向短线  W1
  makerjs.model.addPath(myBfg3GapCMakerW, new makerjs.paths.Line([-w1,-y0], [-w1,-y0+markShift]));//Y轴方向短线  W1
  
  makerjs.model.addPath(myBfg3GapCMakerW, new makerjs.paths.Line([0,-y0-h1], [0,-y0-h1+markShift]));//Y轴方向短线  W
  makerjs.model.addPath(myBfg3GapCMakerW, new makerjs.paths.Line([-w,-y0-h1], [-w,-y0-h1+markShift]));//Y轴方向短线  W
  
  makerjs.model.addPath(myBfg3GapCMakerW, new makerjs.paths.Line([0,0], [0+markShiftThree, 0]));//X轴加长标线
  makerjs.model.addPath(myBfg3GapCMakerW, new makerjs.paths.Line([0+markShift,0-y0], [0+markShiftThree, 0-y0]));//X轴方向短线
  makerjs.model.addPath(myBfg3GapCMakerW, new makerjs.paths.Line([-w2,0-y0], [-w2-markShiftThree, 0-y0]));//X轴方向短线 h  up
  makerjs.model.addPath(myBfg3GapCMakerW, new makerjs.paths.Line([-w2,0-y0-h], [-w2-markShiftThree, 0-y0-h]));//X轴方向短线 h  dn
  
  makerjs.model.addPath(myBfg3GapCMakerR1, new makerjs.paths.Line([-w,0-y0-h], [-w+dd+markHolesOneD,0-y0-h-dd-markHolesOneD]));//R1相关 X轴方向短线上
  makerjs.model.addPath(myBfg3GapCMakerR1, new makerjs.paths.Line([-w+dd+markHolesOneD,0-y0-h-dd-markHolesOneD], [-w+dd+markHolesOneD,0-y0-h-dd-markHolesOneD-markShiftFour]));//R1相关 X轴方向短线上
  makerjs.model.addCaption(myBfg3GapCMakerR1,TxtR1,[-w+markShift+dd+markHolesOneD,0-y0-h-dd-markHolesOneD], [-w+markShift+dd+markHolesOneD,0-y0-h-dd-markHolesOneD-markShiftFour]);//R1相关 X轴方向短线下
  
  makerjs.model.addPath(myBfg3GapCMakerR2, new makerjs.paths.Line([-w2,0-y0-h], [-w2-dd-markHolesOneD,0-y0-h-dd-markHolesOneD]));//R2相关 X轴方向短线上
  makerjs.model.addPath(myBfg3GapCMakerR2, new makerjs.paths.Line([-w2-dd-markHolesOneD,0-y0-h-dd-markHolesOneD], [-w2-dd-markHolesOneD,0-y0-h-dd-markHolesOneD-markShiftFour]));//R2相关 X轴方向短线上
  makerjs.model.addCaption(myBfg3GapCMakerR2,TxtR2,[-w2-markShift-dd-markHolesOneD,0-y0-h-dd-markHolesOneD], [-w2-markShift-dd-markHolesOneD,0-y0-h-dd-markHolesOneD-markShiftFour]);//R2相关 X轴方向短线下
  
  
  makerjs.model.addPath(myBfg3GapCMakerH, new makerjs.paths.Line([-w2-markShift,0-y0], [-w2-markShift, 0-y0-h]));//H相关 Y轴方向短线
  makerjs.model.addCaption(myBfg3GapCMakerH,TxtH, [-w2-markShiftThree, 0-y0-h], [-w2-markShiftThree,0-y0]);//H文字标注
  
  
  makerjs.model.addPath(myBfg3GapCMakerH1, new makerjs.paths.Line([0-markShift,0-y0], [0-markShift, 0-y0-h1]));//H1相关 Y轴方向短线
  makerjs.model.addCaption(myBfg3GapCMakerH1,TxtH1, [0-markShiftThree, 0-y0-h1], [0-markShiftThree,0-y0]);//H1文字标注
  
  
  makerjs.model.addPath(myBfg3GapCMakerY, new makerjs.paths.Line([0+markShiftTwo,0], [0+markShiftTwo, 0-y0]));//Y轴方向标注短线 
  makerjs.model.addCaption(myBfg3GapCMakerY,TxtY,[0+markShiftFour,0],[0+markShiftFour, 0-y0]);//Y文字标注
  
  // /*****************************************************************************/
  makerjs.model.addPath(myBfg3GapCMakerW, new makerjs.paths.Line([0,-y0-h1+markShiftF], [-w, -y0-h1+markShiftF]));//W轴方向标注短线 
  makerjs.model.addCaption(myBfg3GapCMakerW,TxtW, [-w, -y0-h1+markShift], [0,-y0-h1+markShift]);//W文字标注
  
  makerjs.model.addPath(myBfg3GapCMakerW1, new makerjs.paths.Line([0,-y0+markShiftF], [-w1, -y0+markShiftF]));//W1轴方向标注短线 
  makerjs.model.addCaption(myBfg3GapCMakerW1,TxtW1, [-w1, -y0+markShiftTwo], [0,-y0+markShiftTwo]);//W1文字标注
  
  makerjs.model.addPath(myBfg3GapCMakerW2, new makerjs.paths.Line([0,0+markShiftF], [-w2, 0+markShiftF]));//W2轴方向标注短线 
  makerjs.model.addCaption(myBfg3GapCMakerW2,TxtW2, [-w2, 0+markShiftTwo], [0,0+markShiftTwo]);//W2文字标注
  
  // // /*****************************************************************************/
  // /*****************************************************************************/  
  plate.models["myBfg3GapC"+myHolesNum]=myBfg3GapC;
  markers.models["myBfg3GapC"+myHolesNum]=myBfg3GapC;
  markers.models["myBfg3GapCMakerW"+myHolesNum]=myBfg3GapCMakerW;
  markers.models["myBfg3GapCMakerW1"+myHolesNum]=myBfg3GapCMakerW1;
  markers.models["myBfg3GapCMakerW2"+myHolesNum]=myBfg3GapCMakerW2;
  markers.models["myBfg3GapCMakerH1"+myHolesNum]=myBfg3GapCMakerH1;
  markers.models["myBfg3GapCMakerH"+myHolesNum]=myBfg3GapCMakerH;
  markers.models["myBfg3GapCMakerR1"+myHolesNum]=myBfg3GapCMakerR1;
  markers.models["myBfg3GapCMakerR2"+myHolesNum]=myBfg3GapCMakerR2;
  markers.models["myBfg3GapCMakerY"+myHolesNum]=myBfg3GapCMakerY;
  /*****************************************************************************/
  makerjs.model.combineSubtraction(markers.models["myRect"], markers.models["myBfg3GapC"+myHolesNum]); 
  var c = makerjs.model.findSingleChain(markers.models["myBfg3GapC"+myHolesNum]);
     var fillets = makerjs.chain.fillet(c, r1);
    markers.models["filletsC"+myHolesNum] = fillets;
  console.log("markers==",markers);
  /*****************************************************************************/ 
  
  /*****************************************************************************/ 
    
          
  }else if(area==4){
     //画缺 D位置
        //1、3蝴蝶3 D位置   H W D  width=w-d/2  
        var myBfg3GapDRect=new makerjs.models.Rectangle(w1+r2, h1); 
        myBfg3GapDRect.origin=[xd-r2,yd-h1/2];     
        var myBfg3GapDBoltRectangle=new makerjs.models.BoltRectangle(w2+r2,h,r2);
        myBfg3GapDBoltRectangle.origin=[xd-r2,yd-h/2]; 
     var myBfg3GapD=new makerjs.model.combineUnion(myBfg3GapDRect, myBfg3GapDBoltRectangle);
     //画1、3蝴蝶3标记 D位置 
  var myBfg3GapDMakerW = new makerjs.models.Square(0); //D的起始点       
  myBfg3GapDMakerW.origin = [xd,yd];
  var myBfg3GapDMakerW1 = new makerjs.models.Square(0); //D的起始点       
  myBfg3GapDMakerW1.origin = [xd,yd];
  var myBfg3GapDMakerW2 = new makerjs.models.Square(0); //D的起始点       
  myBfg3GapDMakerW2.origin = [xd,yd];
  var myBfg3GapDMakerH = new makerjs.models.Square(0); //W的起始点        
  myBfg3GapDMakerH.origin = [xd,yd];
  var myBfg3GapDMakerH1 = new makerjs.models.Square(0); //W的起始点        
  myBfg3GapDMakerH1.origin = [xd,yd];
  var myBfg3GapDMakerR1 = new makerjs.models.Square(0); //H的起始点        
  myBfg3GapDMakerR1.origin = [xd,yd];
  var myBfg3GapDMakerR2 = new makerjs.models.Square(0); //H的起始点        
  myBfg3GapDMakerR2.origin = [xd,yd];
  var myBfg3GapDMakerY = new makerjs.models.Square(0); //H的起始点        
  myBfg3GapDMakerY.origin = [xd,yd];
  /*****************************************************************************/
  makerjs.model.addPath(myBfg3GapDMakerW, new makerjs.paths.Line([w2,h/2], [w2,h/2+markShiftFour]));//Y轴方向短线  W2
  
  makerjs.model.addPath(myBfg3GapDMakerW, new makerjs.paths.Line([w1,h/2], [w1,h/2+markShiftTwo]));//Y轴方向短线  W1
  
  makerjs.model.addPath(myBfg3GapDMakerW, new makerjs.paths.Line([w,-h1/2], [w,-h1/2-markShiftFour]));//Y轴方向短线  W
  
  makerjs.model.addPath(myBfg3GapDMakerW, new makerjs.paths.Line([w2,h/2], [w2+markShiftThree, h/2]));//X轴方向短线 h  up
  makerjs.model.addPath(myBfg3GapDMakerW, new makerjs.paths.Line([w2,0-y0-h/2], [w2+markShiftThree, 0-y0-h/2]));//X轴方向短线 h  dn
  
  makerjs.model.addPath(myBfg3GapDMakerR1, new makerjs.paths.Line([w,0-y0-h/2], [w-dd-markHolesOneD,0-y0-h/2-dd-markHolesOneD]));//R1相关 X轴方向短线上
  makerjs.model.addPath(myBfg3GapDMakerR1, new makerjs.paths.Line([w-dd-markHolesOneD,0-y0-h/2-dd-markHolesOneD], [w-dd-markHolesOneD,0-y0-h/2-dd-markHolesOneD-markShiftFour]));//R1相关 X轴方向短线上
  makerjs.model.addCaption(myBfg3GapDMakerR1,TxtR1,[w-markShift-dd-markHolesOneD,0-y0-h/2-dd-markHolesOneD], [w-markShift-dd-markHolesOneD,0-y0-h/2-dd-markHolesOneD-markShiftFour]);//R1相关 X轴方向短线下
  
  makerjs.model.addPath(myBfg3GapDMakerR2, new makerjs.paths.Line([w2,0-y0-h/2], [w2+dd+markHolesOneD,0-y0-h/2-dd-markHolesOneD]));//R2相关 X轴方向短线上
  makerjs.model.addPath(myBfg3GapDMakerR2, new makerjs.paths.Line([w2+dd+markHolesOneD,0-y0-h/2-dd-markHolesOneD], [w2+dd+markHolesOneD,0-y0-h/2-dd-markHolesOneD-markShiftFour]));//R2相关 X轴方向短线上
  makerjs.model.addCaption(myBfg3GapDMakerR2,TxtR2,[w2+markShift+dd+markHolesOneD,0-y0-h/2-dd-markHolesOneD], [w2+markShift+dd+markHolesOneD,0-y0-h/2-dd-markHolesOneD-markShiftFour]);//R2相关 X轴方向短线下
  
  
  makerjs.model.addPath(myBfg3GapDMakerH, new makerjs.paths.Line([w2+markShift,h/2], [w2+markShift, -h/2]));//H相关 Y轴方向短线
  makerjs.model.addCaption(myBfg3GapDMakerH,TxtH, [w2+markShiftThree,h/2], [w2+markShiftThree, -h/2]);//H文字标注
  
  
  makerjs.model.addPath(myBfg3GapDMakerH1, new makerjs.paths.Line([0+markShift,h1/2], [0+markShift, -h1/2]));//H1相关 Y轴方向短线
  makerjs.model.addCaption(myBfg3GapDMakerH1,TxtH1, [0+markShiftThree,h1/2], [0+markShiftThree, -h1/2]);//H1文字标注
  
  // // /*****************************************************************************/
  makerjs.model.addPath(myBfg3GapDMakerW, new makerjs.paths.Line([0,-y0-h1+markShift], [w, -y0-h1+markShift]));//W轴方向标注短线 
  makerjs.model.addCaption(myBfg3GapDMakerW,TxtW, [0,-y0-h1+markShiftTwo], [w, -y0-h1+markShiftTwo]);//W文字标注
  
  makerjs.model.addPath(myBfg3GapDMakerW1, new makerjs.paths.Line([0,h1/2+markShiftF], [w1, h1/2+markShiftF]));//W1轴方向标注短线 
  makerjs.model.addCaption(myBfg3GapDMakerW1,TxtW1, [0,h1/2+markShift], [w1, h1/2+markShift]);//W1文字标注
  
  makerjs.model.addPath(myBfg3GapDMakerW2, new makerjs.paths.Line([0,h1/2+markShiftThree], [w2, h1/2+markShiftThree]));//W2轴方向标注短线 
  makerjs.model.addCaption(myBfg3GapDMakerW2,TxtW2, [0,h1/2+markShiftFour], [w2, h1/2+markShiftFour]);//W2文字标注
  // // /*****************************************************************************/
  // /*****************************************************************************/   
  plate.models["myBfg3GapD"+myHolesNum]=myBfg3GapD;
  markers.models["myBfg3GapD"+myHolesNum]=myBfg3GapD;
  markers.models["myBfg3GapDMakerW"+myHolesNum]=myBfg3GapDMakerW;
  markers.models["myBfg3GapDMakerW1"+myHolesNum]=myBfg3GapDMakerW1;
  markers.models["myBfg3GapDMakerW2"+myHolesNum]=myBfg3GapDMakerW2;
  markers.models["myBfg3GapDMakerH1"+myHolesNum]=myBfg3GapDMakerH1;
  markers.models["myBfg3GapDMakerH"+myHolesNum]=myBfg3GapDMakerH;
  markers.models["myBfg3GapDMakerR1"+myHolesNum]=myBfg3GapDMakerR1;
  markers.models["myBfg3GapDMakerR2"+myHolesNum]=myBfg3GapDMakerR2;
  /*****************************************************************************/
  makerjs.model.combineSubtraction(markers.models["myRect"], markers.models["myBfg3GapD"+myHolesNum]);
  var c = makerjs.model.findSingleChain(markers.models["myBfg3GapD"+myHolesNum]);
     var fillets = makerjs.chain.fillet(c, r1);
    markers.models["filletsD"+myHolesNum] = fillets;
  console.log("markers==",markers);
  /*****************************************************************************/ 
            
  }else if(area==5){
          
  }else if(area==6){
       //画缺 F位置
        //1、3蝴蝶3 F位置   H W D  width=w-d/2
        var myBfg3GapFRect=new makerjs.models.Rectangle(w1+r2, h1); 
        myBfg3GapFRect.origin=[xf-r2-w,yf-h1/2];     
        var myBfg3GapFBoltRectangle=new makerjs.models.BoltRectangle(w2+r2,h,r2);
        myBfg3GapFBoltRectangle.origin=[xf-r2-w,yf-h/2];  
     var myBfg3GapF=new makerjs.model.combineUnion(myBfg3GapFRect, myBfg3GapFBoltRectangle);
     //画1、3蝴蝶3标记 F位置 
  var myBfg3GapFMakerW = new makerjs.models.Square(0); //D的起始点       
  myBfg3GapFMakerW.origin = [xf,yf];
  var myBfg3GapFMakerW1 = new makerjs.models.Square(0); //D的起始点       
  myBfg3GapFMakerW1.origin = [xf,yf];
  var myBfg3GapFMakerW2 = new makerjs.models.Square(0); //D的起始点       
  myBfg3GapFMakerW2.origin = [xf,yf];
  var myBfg3GapFMakerH = new makerjs.models.Square(0); //W的起始点        
  myBfg3GapFMakerH.origin = [xf,yf];
  var myBfg3GapFMakerH1 = new makerjs.models.Square(0); //W的起始点        
  myBfg3GapFMakerH1.origin = [xf,yf];
  var myBfg3GapFMakerR1 = new makerjs.models.Square(0); //H的起始点        
  myBfg3GapFMakerR1.origin = [xf,yf];
  var myBfg3GapFMakerR2 = new makerjs.models.Square(0); //H的起始点        
  myBfg3GapFMakerR2.origin = [xf,yf];
  /*****************************************************************************/
  makerjs.model.addPath(myBfg3GapFMakerW, new makerjs.paths.Line([-w2,h/2], [-w2,h/2+markShiftFour]));//Y轴方向短线  W2
  
  makerjs.model.addPath(myBfg3GapFMakerW, new makerjs.paths.Line([-w1,h/2], [-w1,h/2+markShiftTwo]));//Y轴方向短线  W1
   
  makerjs.model.addPath(myBfg3GapFMakerW, new makerjs.paths.Line([-w,-y0-h1/2], [-w,-y0-h1/2-markShiftFour]));//Y轴方向短线  W
  
  makerjs.model.addPath(myBfg3GapFMakerW, new makerjs.paths.Line([-w2,h/2], [-w2-markShiftThree, h/2]));//X轴方向短线 h  up
  makerjs.model.addPath(myBfg3GapFMakerW, new makerjs.paths.Line([-w2,0-y0-h/2], [-w2-markShiftThree, 0-y0-h/2]));//X轴方向短线 h  dn
  
  makerjs.model.addPath(myBfg3GapFMakerR1, new makerjs.paths.Line([-w,0-y0-h/2], [-w+dd+markHolesOneD,0-y0-h/2-dd-markHolesOneD]));//R1相关 X轴方向短线上
  makerjs.model.addPath(myBfg3GapFMakerR1, new makerjs.paths.Line([-w+dd+markHolesOneD,0-y0-h/2-dd-markHolesOneD], [-w+dd+markHolesOneD,0-y0-h/2-dd-markHolesOneD-markShiftFour]));//R1相关 X轴方向短线上
  makerjs.model.addCaption(myBfg3GapFMakerR1,TxtR1, [-w+markShift+dd+markHolesOneD,0-y0-h/2-dd-markHolesOneD-markShiftFour],[-w+markShift+dd+markHolesOneD,0-y0-h/2-dd-markHolesOneD]);//R1相关 X轴方向短线下
  
  makerjs.model.addPath(myBfg3GapFMakerR2, new makerjs.paths.Line([-w2,0-y0-h/2], [-w2-dd-markHolesOneD,0-y0-h/2-dd-markHolesOneD]));//R2相关 X轴方向短线上
  makerjs.model.addPath(myBfg3GapFMakerR2, new makerjs.paths.Line([-w2-dd-markHolesOneD,0-y0-h/2-dd-markHolesOneD], [-w2-dd-markHolesOneD,0-y0-h/2-dd-markHolesOneD-markShiftFour]));//R2相关 X轴方向短线上
  makerjs.model.addCaption(myBfg3GapFMakerR2,TxtR2, [-w2-markShift-dd-markHolesOneD,0-y0-h/2-dd-markHolesOneD-markShiftFour],[-w2-markShift-dd-markHolesOneD,0-y0-h/2-dd-markHolesOneD]);//R2相关 X轴方向短线下
  
  
  makerjs.model.addPath(myBfg3GapFMakerH, new makerjs.paths.Line([-w2-markShift,0+h/2], [-w2-markShift, 0-y0-h/2]));//H相关 Y轴方向短线
  makerjs.model.addCaption(myBfg3GapFMakerH,TxtH, [-w2-markShiftThree, 0-y0-h/2], [-w2-markShiftThree,0+h/2]);//H文字标注
  
  
  makerjs.model.addPath(myBfg3GapFMakerH1, new makerjs.paths.Line([0-markShift,h1/2], [0-markShift, 0-y0-h1/2]));//H1相关 Y轴方向短线
  makerjs.model.addCaption(myBfg3GapFMakerH1,TxtH1, [0-markShiftThree, 0-y0-h1/2], [0-markShiftThree,h1/2]);//H1文字标注
  
   /*****************************************************************************/
  makerjs.model.addPath(myBfg3GapFMakerW, new makerjs.paths.Line([0,-y0-h1+markShift], [-w, -y0-h+markShift]));//W轴方向标注短线 
  makerjs.model.addCaption(myBfg3GapFMakerW,TxtW, [-w, -y0-h1+markShiftTwo], [0,-y0-h1+markShiftTwo]);//W文字标注
  
  makerjs.model.addPath(myBfg3GapFMakerW1, new makerjs.paths.Line([0,h1/2+markShiftF], [-w1, h1/2+markShiftF]));//W1轴方向标注短线 
  makerjs.model.addCaption(myBfg3GapFMakerW1,TxtW1, [-w1, h1/2+markShift], [0,h1/2+markShift]);//W1文字标注
  
  makerjs.model.addPath(myBfg3GapFMakerW2, new makerjs.paths.Line([0,h1/2+markShiftThree], [-w2, h1/2+markShiftThree]));//W2轴方向标注短线 
  makerjs.model.addCaption(myBfg3GapFMakerW2,TxtW2, [-w2, h1/2+markShiftFour], [0,h1/2+markShiftFour]);//W2文字标注
  
  /*****************************************************************************/
  /*****************************************************************************/  
  plate.models["myBfg3GapF"+myHolesNum]=myBfg3GapF;
  markers.models["myBfg3GapF"+myHolesNum]=myBfg3GapF;
  markers.models["myBfg3GapFMakerW"+myHolesNum]=myBfg3GapFMakerW;
  markers.models["myBfg3GapFMakerW1"+myHolesNum]=myBfg3GapFMakerW1;
  markers.models["myBfg3GapFMakerW2"+myHolesNum]=myBfg3GapFMakerW2;
  markers.models["myBfg3GapFMakerH1"+myHolesNum]=myBfg3GapFMakerH1;
  markers.models["myBfg3GapFMakerH"+myHolesNum]=myBfg3GapFMakerH;
  markers.models["myBfg3GapFMakerR1"+myHolesNum]=myBfg3GapFMakerR1;
  markers.models["myBfg3GapFMakerR2"+myHolesNum]=myBfg3GapFMakerR2;
  /*****************************************************************************/
  makerjs.model.combineSubtraction(markers.models.myRect, markers.models["myBfg3GapF"+myHolesNum]);
  var c = makerjs.model.findSingleChain(markers.models["myBfg3GapF"+myHolesNum]);
     var fillets = makerjs.chain.fillet(c, r1);
    markers.models["filletsF"+myHolesNum] = fillets;
  console.log("markers==",markers);
  /*****************************************************************************/ 
  
  /*****************************************************************************/ 
         
  }else if(area==7){
           //画缺 G位置
        //1、3蝴蝶3 G位置   H W D  width=w-d/2
        var myBfg3GapGRect=new makerjs.models.Rectangle(w1+r2, h1); 
        myBfg3GapGRect.origin=[xg-r2,yg];     
        var myBfg3GapGBoltRectangle=new makerjs.models.BoltRectangle(w2+r2,h,r2);
        myBfg3GapGBoltRectangle.origin=[xg-r2,yg]; 
     var myBfg3GapG=new makerjs.model.combineUnion(myBfg3GapGRect, myBfg3GapGBoltRectangle);
     //画1、3蝴蝶3标记 G位置    
  var myBfg3GapGMakerW = new makerjs.models.Square(0); //D的起始点       
  myBfg3GapGMakerW.origin = [xg,yg-y0];
  var myBfg3GapGMakerW1 = new makerjs.models.Square(0); //D的起始点       
  myBfg3GapGMakerW1.origin = [xg,yg-y0];
  var myBfg3GapGMakerW2 = new makerjs.models.Square(0); //D的起始点       
  myBfg3GapGMakerW2.origin = [xg,yg-y0];
  var myBfg3GapGMakerH = new makerjs.models.Square(0); //W的起始点        
  myBfg3GapGMakerH.origin = [xg,yg-y0];
  var myBfg3GapGMakerH1 = new makerjs.models.Square(0); //W的起始点        
  myBfg3GapGMakerH1.origin = [xg,yg-y0];
  var myBfg3GapGMakerR1 = new makerjs.models.Square(0); //H的起始点        
  myBfg3GapGMakerR1.origin = [xg,yg-y0];
  var myBfg3GapGMakerR2 = new makerjs.models.Square(0); //H的起始点        
  myBfg3GapGMakerR2.origin = [xg,yg-y0];
  var myBfg3GapGMakerY = new makerjs.models.Square(0); //H的起始点        
  myBfg3GapGMakerY.origin = [xg,yg-y0];
  
  /*****************************************************************************/
  
  makerjs.model.addPath(myBfg3GapGMakerW, new makerjs.paths.Line([0,0], [0, -markShiftThree]));//Y轴加长标线
  
  makerjs.model.addPath(myBfg3GapGMakerW, new makerjs.paths.Line([w2,y0], [w2,-markShiftFour]));//Y轴方向短线  W2
  
  makerjs.model.addPath(myBfg3GapGMakerW, new makerjs.paths.Line([0,y0], [0,y0-markShift]));//Y轴方向短线  W1
  makerjs.model.addPath(myBfg3GapGMakerW, new makerjs.paths.Line([w1,y0], [w1,y0-markShift]));//Y轴方向短线  W1
  
  makerjs.model.addPath(myBfg3GapGMakerW, new makerjs.paths.Line([0,y0+h1], [0,y0+h1-markShift]));//Y轴方向短线  W
  makerjs.model.addPath(myBfg3GapGMakerW, new makerjs.paths.Line([w,y0+h1], [w,y0+h1-markShift]));//Y轴方向短线  W
  
  makerjs.model.addPath(myBfg3GapGMakerW, new makerjs.paths.Line([0,0], [0-markShiftThree, 0]));//X轴加长标线
  makerjs.model.addPath(myBfg3GapGMakerW, new makerjs.paths.Line([0-markShift,0+y0], [0-markShiftThree, 0+y0]));//X轴方向短线
  makerjs.model.addPath(myBfg3GapGMakerW, new makerjs.paths.Line([w2,0+y0], [w2+markShiftThree, 0+y0]));//X轴方向短线 h  up
  makerjs.model.addPath(myBfg3GapGMakerW, new makerjs.paths.Line([w2,0+y0+h], [w2+markShiftThree, 0+y0+h]));//X轴方向短线 h  dn
  
  makerjs.model.addPath(myBfg3GapGMakerR1, new makerjs.paths.Line([w,0+y0+h], [w-dd-markHolesOneD,0+y0+h+dd+markHolesOneD]));//R1相关 X轴方向短线上
  makerjs.model.addPath(myBfg3GapGMakerR1, new makerjs.paths.Line([w-dd-markHolesOneD,0+y0+h+dd+markHolesOneD], [w-dd-markHolesOneD,0+y0+h+dd+markHolesOneD+markShiftFour]));//R1相关 X轴方向短线上
  makerjs.model.addCaption(myBfg3GapGMakerR1,TxtR1,[w-markShift-dd-markHolesOneD,0+y0+h+dd+markHolesOneD], [w-markShift-dd-markHolesOneD,0+y0+h+dd+markHolesOneD+markShiftFour]);//R1相关 X轴方向短线下
  
  makerjs.model.addPath(myBfg3GapGMakerR2, new makerjs.paths.Line([w2,0+y0+h], [w2+dd+markHolesOneD,0+y0+h+dd+markHolesOneD]));//R2相关 X轴方向短线上
  makerjs.model.addPath(myBfg3GapGMakerR2, new makerjs.paths.Line([w2+dd+markHolesOneD,0+y0+h+dd+markHolesOneD], [w2+dd+markHolesOneD,0+y0+h+dd+markHolesOneD+markShiftFour]));//R2相关 X轴方向短线上
  makerjs.model.addCaption(myBfg3GapGMakerR2,TxtR2,[w2+markShift+dd+markHolesOneD,0+y0+h+dd+markHolesOneD], [w2+markShift+dd+markHolesOneD,0+y0+h+dd+markHolesOneD+markShiftFour]);//R2相关 X轴方向短线下
  
  
  makerjs.model.addPath(myBfg3GapGMakerH, new makerjs.paths.Line([w2+markShift,0+y0], [w2+markShift, 0+y0+h]));//H相关 Y轴方向短线
  makerjs.model.addCaption(myBfg3GapGMakerH,TxtH, [w2+markShiftThree,0+y0], [w2+markShiftThree, 0+y0+h]);//H文字标注
  
  
  makerjs.model.addPath(myBfg3GapGMakerH1, new makerjs.paths.Line([0+markShift,0+y0], [0+markShift, 0+y0+h1]));//H1相关 Y轴方向短线
  makerjs.model.addCaption(myBfg3GapGMakerH1,TxtH1, [0+markShiftThree,0+y0], [0+markShiftThree, 0+y0+h1]);//H1文字标注
  
  
  makerjs.model.addPath(myBfg3GapGMakerY, new makerjs.paths.Line([0-markShiftTwo,0], [0-markShiftTwo, 0+y0]));//Y轴方向标注短线 
  makerjs.model.addCaption(myBfg3GapGMakerY,TxtY,  [0-markShiftFour, 0+y0],[0-markShiftFour,0]);//Y文字标注
  
  // /*****************************************************************************/
  makerjs.model.addPath(myBfg3GapGMakerW, new makerjs.paths.Line([0,y0+h1-markShiftF], [w, y0+h1-markShiftF]));//W轴方向标注短线 
  makerjs.model.addCaption(myBfg3GapGMakerW,TxtW, [0,y0+h1-markShift], [w, y0+h1-markShift]);//W文字标注
  
  makerjs.model.addPath(myBfg3GapGMakerW1, new makerjs.paths.Line([0,y0-markShiftF], [w1, y0-markShiftF]));//W1轴方向标注短线 
  makerjs.model.addCaption(myBfg3GapGMakerW1,TxtW1, [0,y0-markShiftTwo], [w1, y0-markShiftTwo]);//W1文字标注
  
  makerjs.model.addPath(myBfg3GapGMakerW2, new makerjs.paths.Line([0,0-markShiftF], [w2, 0-markShiftF]));//W2轴方向标注短线 
  makerjs.model.addCaption(myBfg3GapGMakerW2,TxtW2, [0,0-markShiftTwo], [w2, 0-markShiftTwo]);//W2文字标注
  
  // /*****************************************************************************/    
  
  plate.models["myBfg3GapG"+myHolesNum]=myBfg3GapG;
  markers.models["myBfg3GapG"+myHolesNum]=myBfg3GapG;
  markers.models["myBfg3GapGMakerW"+myHolesNum]=myBfg3GapGMakerW;
  markers.models["myBfg3GapGMakerW1"+myHolesNum]=myBfg3GapGMakerW1;
  markers.models["myBfg3GapGMakerW2"+myHolesNum]=myBfg3GapGMakerW2;
  markers.models["myBfg3GapGMakerH1"+myHolesNum]=myBfg3GapGMakerH1;
  markers.models["myBfg3GapGMakerH"+myHolesNum]=myBfg3GapGMakerH;
  markers.models["myBfg3GapGMakerR1"+myHolesNum]=myBfg3GapGMakerR1;
  markers.models["myBfg3GapGMakerR2"+myHolesNum]=myBfg3GapGMakerR2;
  markers.models["myBfg3GapGMakerY"+myHolesNum]=myBfg3GapGMakerY;
  /*****************************************************************************/
  makerjs.model.combineSubtraction(markers.models["myRect"], markers.models["myBfg3GapG"+myHolesNum]); 
  
  var c = makerjs.model.findSingleChain(markers.models["myBfg3GapG"+myHolesNum]);
    var fillets = makerjs.chain.fillet(c, r1);
   markers.models["filletsG"+myHolesNum] = fillets;
  console.log("markers==",markers);
  /*****************************************************************************/ 
  
  /*****************************************************************************/ 
      
          
  }else if(area==8){
          
  }else if(area==9){
        //1、3蝴蝶3 I位置   H W D  width=w-d/2
        var myBfg3GapIRect=new makerjs.models.Rectangle(w1+r2, h1); 
        myBfg3GapIRect.origin=[xi-r2-w,yi];     
        var myBfg3GapIBoltRectangle=new makerjs.models.BoltRectangle(w2+r2,h,r2);
        myBfg3GapIBoltRectangle.origin=[xi-r2-w,yi]; 
     var myBfg3GapI=new makerjs.model.combineUnion(myBfg3GapIRect, myBfg3GapIBoltRectangle);
     //画1、3蝴蝶3标记 I位置    
  var myBfg3GapIMakerW = new makerjs.models.Square(0); //D的起始点       
  myBfg3GapIMakerW.origin = [xi,yi-y0];
  var myBfg3GapIMakerW1 = new makerjs.models.Square(0); //D的起始点       
  myBfg3GapIMakerW1.origin = [xi,yi-y0];
  var myBfg3GapIMakerW2 = new makerjs.models.Square(0); //D的起始点       
  myBfg3GapIMakerW2.origin = [xi,yi-y0];
  var myBfg3GapIMakerH = new makerjs.models.Square(0); //W的起始点        
  myBfg3GapIMakerH.origin = [xi,yi-y0];
  var myBfg3GapIMakerH1 = new makerjs.models.Square(0); //W的起始点        
  myBfg3GapIMakerH1.origin = [xi,yi-y0];
  var myBfg3GapIMakerR1 = new makerjs.models.Square(0); //H的起始点        
  myBfg3GapIMakerR1.origin = [xi,yi-y0];
  var myBfg3GapIMakerR2 = new makerjs.models.Square(0); //H的起始点        
  myBfg3GapIMakerR2.origin = [xi,yi-y0];
  var myBfg3GapIMakerY = new makerjs.models.Square(0); //H的起始点        
  myBfg3GapIMakerY.origin = [xi,yi-y0];
  /*****************************************************************************/
  makerjs.model.addPath(myBfg3GapIMakerW, new makerjs.paths.Line([0,0], [0, -markShiftThree]));//Y轴加长标线
  
  makerjs.model.addPath(myBfg3GapIMakerW, new makerjs.paths.Line([-w2,y0], [-w2,0-markShiftFour]));//Y轴方向短线  W2
  
  makerjs.model.addPath(myBfg3GapIMakerW, new makerjs.paths.Line([0,y0], [0,y0-markShift]));//Y轴方向短线  W1
  makerjs.model.addPath(myBfg3GapIMakerW, new makerjs.paths.Line([-w1,y0], [-w1,y0-markShift]));//Y轴方向短线  W1
  
  makerjs.model.addPath(myBfg3GapIMakerW, new makerjs.paths.Line([0,y0+h1], [0,y0+h1-markShift]));//Y轴方向短线  W
  makerjs.model.addPath(myBfg3GapIMakerW, new makerjs.paths.Line([-w,y0+h1], [-w,y0+h1-markShift]));//Y轴方向短线  W
  
  makerjs.model.addPath(myBfg3GapIMakerW, new makerjs.paths.Line([0,0], [0+markShiftThree, 0]));//X轴加长标线
  makerjs.model.addPath(myBfg3GapIMakerW, new makerjs.paths.Line([0+markShift,0+y0], [0+markShiftThree, 0+y0]));//X轴方向短线
  makerjs.model.addPath(myBfg3GapIMakerW, new makerjs.paths.Line([-w2,0+y0], [-w2-markShiftThree, 0+y0]));//X轴方向短线 h  up
  makerjs.model.addPath(myBfg3GapIMakerW, new makerjs.paths.Line([-w2,0+y0+h], [-w2-markShiftThree, 0+y0+h]));//X轴方向短线 h  dn
  
  makerjs.model.addPath(myBfg3GapIMakerR1, new makerjs.paths.Line([-w,0+y0+h], [-w+dd+markHolesOneD,0+y0+h+dd+markHolesOneD]));//R1相关 X轴方向短线上
  makerjs.model.addPath(myBfg3GapIMakerR1, new makerjs.paths.Line([-w+dd+markHolesOneD,0+y0+h+dd+markHolesOneD], [-w+dd+markHolesOneD,0+y0+h+dd+markHolesOneD+markShiftFour]));//R1相关 X轴方向短线上
  makerjs.model.addCaption(myBfg3GapIMakerR1,TxtR1,[-w+markShift+dd+markHolesOneD,0+y0+h+dd+markHolesOneD], [-w+markShift+dd+markHolesOneD,0+y0+h+dd+markHolesOneD+markShiftFour]);//R1相关 X轴方向短线下
  
  makerjs.model.addPath(myBfg3GapIMakerR2, new makerjs.paths.Line([-w2,0+y0+h], [-w2-dd-markHolesOneD,0+y0+h+dd+markHolesOneD]));//R2相关 X轴方向短线上
  makerjs.model.addPath(myBfg3GapIMakerR2, new makerjs.paths.Line([-w2-dd-markHolesOneD,0+y0+h+dd+markHolesOneD], [-w2-dd-markHolesOneD,0+y0+h+dd+markHolesOneD+markShiftFour]));//R2相关 X轴方向短线上
  makerjs.model.addCaption(myBfg3GapIMakerR2,TxtR2,[-w2-markShift-dd-markHolesOneD,0+y0+h+dd+markHolesOneD], [-w2-markShift-dd-markHolesOneD,0+y0+h+dd+markHolesOneD+markShiftFour]);//R2相关 X轴方向短线下
  
  
  makerjs.model.addPath(myBfg3GapIMakerH, new makerjs.paths.Line([-w2-markShift,0+y0], [-w2-markShift, 0+y0+h]));//H相关 Y轴方向短线
  makerjs.model.addCaption(myBfg3GapIMakerH,TxtH, [-w2-markShiftThree,0+y0], [-w2-markShiftThree, 0+y0+h]);//H文字标注
  
  makerjs.model.addPath(myBfg3GapIMakerH1, new makerjs.paths.Line([0-markShift,0+y0], [0-markShift, 0+y0+h1]));//H1相关 Y轴方向短线
  makerjs.model.addCaption(myBfg3GapIMakerH1,TxtH1, [0-markShiftThree,0+y0], [0-markShiftThree, 0+y0+h1]);//H1文字标注
  
  makerjs.model.addPath(myBfg3GapIMakerY, new makerjs.paths.Line([0+markShiftTwo,0], [0+markShiftTwo, 0+y0]));//Y轴方向标注短线 
  makerjs.model.addCaption(myBfg3GapIMakerY,TxtY,  [0+markShiftFour, 0+y0],[0+markShiftFour,0]);//Y文字标注
  
  // /*****************************************************************************/
  makerjs.model.addPath(myBfg3GapIMakerW, new makerjs.paths.Line([0,y0+h1-markShiftF], [-w, y0+h1-markShiftF]));//W轴方向标注短线 
  makerjs.model.addCaption(myBfg3GapIMakerW,TxtW, [-w, y0+h1-markShift], [0,y0+h1-markShift]);//W文字标注
  
  makerjs.model.addPath(myBfg3GapIMakerW1, new makerjs.paths.Line([0,y0-markShiftF], [-w1, y0-markShiftF]));//W1轴方向标注短线 
  makerjs.model.addCaption(myBfg3GapIMakerW1,TxtW1, [-w1, y0-markShiftTwo], [0,y0-markShiftTwo]);//W1文字标注
  
  makerjs.model.addPath(myBfg3GapIMakerW2, new makerjs.paths.Line([0,0-markShiftF], [-w2, 0-markShiftF]));//W2轴方向标注短线 
  makerjs.model.addCaption(myBfg3GapIMakerW2,TxtW2, [-w2, 0-markShiftTwo], [0,0-markShiftTwo]);//W2文字标注
  // /*****************************************************************************/  
  plate.models["myBfg3GapI"+myHolesNum]=myBfg3GapI;
  markers.models["myBfg3GapI"+myHolesNum]=myBfg3GapI;
  markers.models["myBfg3GapIMakerW"+myHolesNum]=myBfg3GapIMakerW;
  markers.models["myBfg3GapIMakerW1"+myHolesNum]=myBfg3GapIMakerW1;
  markers.models["myBfg3GapIMakerW2"+myHolesNum]=myBfg3GapIMakerW2;
  markers.models["myBfg3GapIMakerH1"+myHolesNum]=myBfg3GapIMakerH1;
  markers.models["myBfg3GapIMakerH"+myHolesNum]=myBfg3GapIMakerH;
  markers.models["myBfg3GapIMakerR1"+myHolesNum]=myBfg3GapIMakerR1;
  markers.models["myBfg3GapIMakerR2"+myHolesNum]=myBfg3GapIMakerR2;
  markers.models["myBfg3GapIMakerY"+myHolesNum]=myBfg3GapIMakerY;
  /*****************************************************************************/
  makerjs.model.combineSubtraction(markers.models["myRect"], markers.models["myBfg3GapI"+myHolesNum]); 
  var c = makerjs.model.findSingleChain(markers.models["myBfg3GapI"+myHolesNum]);
     var fillets = makerjs.chain.fillet(c, r1);
    markers.models["filletsI"+myHolesNum] = fillets;
  console.log("markers==",markers);
  /*****************************************************************************/ 
  
  /*****************************************************************************/ 
         
  }

myHolesNum++;
return{plate, markers,myHolesNum}
};


export default ButterflyGap3;