//13、吊轮孔列 
import makerjs from "makerjs";
// inputParam,model,myHolesNum,outParam,fixedParam
// (D,X,Y,area,plate,markers,myHolesNum,glassW,glassH,markShift,markShiftTwo,markShiftThree,markShiftFour,markHolesOneD)
function HangWheelHoleCol(inputParam,model,myHolesNum,outParam,fixedParam,zoom){
var D=inputParam.D,H=inputParam.H,X=inputParam.X,Y=inputParam.Y,area=inputParam.area,myZoom=zoom*1,
plate=model.plate,markers=model.markers,
glassW=outParam.glassW,glassH=outParam.glassH,
   markShift=fixedParam.markShift,markShiftTwo=fixedParam.markShiftTwo,
   markShiftThree=fixedParam.markShiftThree,markShiftFour=fixedParam.markShiftFour,markHolesOneD=fixedParam.markHolesOneD,
 
   d = D*1*myZoom,
   h= H*1*myZoom,
     x0 = X*1*myZoom,
     y0 = Y*1*myZoom;
     /*
     d = D*1,
     h= H*1,
       x0 = X*1,
       y0 = Y*1;
       */
     if(d!==0){
      var TxtD="∮"+D;
     }
     if(h!==0){
      var TxtH=H;
     }
     if(x0!==0){          
     var TxtX=X;
     }
     if(y0!==0){          
      var TxtY=Y;
     }
  //在A区域 计算孔的位置       
  var xa=x0,ya=glassH * 1-y0,
  //B区域//只有Y标注没有X标注 
  xb=glassW * 1/2,yb=glassH * 1-y0,
  //C区域
  xc=glassW * 1-x0,yc=glassH * 1-y0,
  //D区域
  //只有X标注没有Y标注 
  xd=x0,yd=glassH * 1/2,
  //E区域
  //只有X标注没有Y标注 
  xe=glassW * 1/2,ye=glassH * 1/2,
  //F区域
  //只有X标注没有Y标注 
  xf=glassW * 1-x0,yf=glassH * 1/2,
  //在G区域 计算孔的位置
  xg=x0,yg=y0,
  //在H区域 计算孔的位置
  //只标注Y
  xh=glassW*1/2,yh=y0,
  //在I区域 计算孔的位置
  xi=glassW*1-x0,yi=y0;
  if(area==1){
    //画孔 A位置孔
          //标记吊轮孔列 A位置孔上
          var myHwhcHolesAup= new makerjs.models.Holes(d/2, [[xa, ya]]);
          var myHwhcHolesMarkAup = new makerjs.models.Square(0); //孔的原点       
          myHwhcHolesMarkAup.origin = [xa, ya];      
          var myHwhcHolesXMarkAup = new makerjs.models.Square(0);      
          myHwhcHolesXMarkAup.origin =[xa, ya];      
          var myHwhcHolesYMarkAup = new makerjs.models.Square(0);      
          myHwhcHolesYMarkAup.origin = [xa, ya];  
    /*****************************************************************************/ 
    makerjs.model.addPath(myHwhcHolesMarkAup, new makerjs.paths.Line([-x0,y0], [-x0, y0+markShiftTwo]));//Y轴加长标线
    makerjs.model.addPath(myHwhcHolesMarkAup, new makerjs.paths.Line([0,0], [-x0-markShiftThree, 0]));//从孔圆心出发X方向横向标线
    makerjs.model.addPath(myHwhcHolesMarkAup, new makerjs.paths.Line([-x0,y0], [-x0-markShiftThree, y0]));//X轴加长标线
    makerjs.model.addPath(myHwhcHolesMarkAup, new makerjs.paths.Line([0,0], [0, y0+markShiftTwo]));//从孔圆心出发Y方向竖向标线
    /*****************************************************************************/ 
    makerjs.model.addPath(myHwhcHolesMarkAup, new makerjs.paths.Line([0,0], [d, -d]));//直径标线
    makerjs.model.addCaption(myHwhcHolesMarkAup, TxtD, [0,0], [d+markHolesOneD, -d-markHolesOneD]);//直径文字标注
    /*****************************************************************************/   
    makerjs.model.addPath(myHwhcHolesXMarkAup, new makerjs.paths.Line([0,y0+markShift], [-x0, y0+markShift]));//X短标线
    makerjs.model.addCaption(myHwhcHolesXMarkAup, TxtX, [-x0,y0+markShiftTwo], [0, y0+markShiftTwo]);//X文字标注
    /*****************************************************************************/     
    makerjs.model.addPath(myHwhcHolesYMarkAup, new makerjs.paths.Line([-x0-markShiftTwo,0], [-x0-markShiftTwo, y0]));//Y短标线
    makerjs.model.addCaption(myHwhcHolesYMarkAup, TxtY, [-x0-markShiftThree,0], [-x0-markShiftThree, y0]);//Y文字标注
    /*****************************************************************************/ 
     //标记吊轮孔列 A位置孔下
     var myHwhcHolesAdn= new makerjs.models.Holes(d/2, [[xa, ya-h]]);
          var myHwhcHolesMarkAdn = new makerjs.models.Square(0); //孔的原点       
          myHwhcHolesMarkAdn.origin = [xa, ya-h];       
          var myHwhcHolesYMarkAdn = new makerjs.models.Square(0);      
          myHwhcHolesYMarkAdn.origin = [xa, ya-h];  
    /*****************************************************************************/ 
    makerjs.model.addPath(myHwhcHolesMarkAdn, new makerjs.paths.Line([0,0], [-x0-markShiftThree, 0]));//从孔圆心出发X方向横向标线
    makerjs.model.addPath(myHwhcHolesMarkAdn, new makerjs.paths.Line([0,0], [0, h+markShiftTwo]));//从孔圆心出发Y方向竖向标线
    /*****************************************************************************/ 
    makerjs.model.addPath(myHwhcHolesMarkAdn, new makerjs.paths.Line([0,0], [d, -d]));//直径标线
    makerjs.model.addCaption(myHwhcHolesMarkAdn, TxtD, [0,0], [d+markHolesOneD, -d-markHolesOneD]);//直径文字标注
    /*****************************************************************************/   
    makerjs.model.addPath(myHwhcHolesYMarkAdn, new makerjs.paths.Line([-x0-markShiftTwo,0], [-x0-markShiftTwo, h]));//Y短标线
    makerjs.model.addCaption(myHwhcHolesYMarkAdn, TxtH, [-x0-markShiftThree,0], [-x0-markShiftThree, h]);//Y文字标注
    /*****************************************************************************/ 
    plate.models["myHwhcHolesAup"+myHolesNum]=myHwhcHolesAup;
    markers.models["myHwhcHolesAup"+myHolesNum]=myHwhcHolesAup;
    markers.models["myHwhcHolesMarkAup"+myHolesNum]=myHwhcHolesMarkAup;
    markers.models["myHwhcHolesXMarkAup"+myHolesNum]=myHwhcHolesXMarkAup;
    markers.models["myHwhcHolesYMarkAup"+myHolesNum]=myHwhcHolesYMarkAup;
    /*****************************************************************************/
    plate.models["myHwhcHolesAdn"+myHolesNum]=myHwhcHolesAdn;
    markers.models["myHwhcHolesAdn"+myHolesNum]=myHwhcHolesAdn;
    markers.models["myHwhcHolesMarkAdn"+myHolesNum]=myHwhcHolesMarkAdn;
    markers.models["myHwhcHolesYMarkAdn"+myHolesNum]=myHwhcHolesYMarkAdn;
    /*****************************************************************************/ 
       
    }else if(area==2){
    //画孔 B位置孔
          //标记吊轮孔列B位置孔上
          var myHwhcHolesBup= new makerjs.models.Holes(d/2, [[xb, yb]]);
          var myHwhcHolesMarkBup = new makerjs.models.Square(0); //孔的原点       
          myHwhcHolesMarkBup.origin = [xb, yb];      
          var myHwhcHolesXMarkBup = new makerjs.models.Square(0);      
          myHwhcHolesXMarkBup.origin =[xb, yb];      
          var myHwhcHolesYMarkBup = new makerjs.models.Square(0);      
          myHwhcHolesYMarkBup.origin = [xb, yb];  
    /*****************************************************************************/ 
    makerjs.model.addPath(myHwhcHolesMarkBup, new makerjs.paths.Line([0,0], [-d-markShiftFour, 0]));//从孔圆心出发X方向横向标线
    makerjs.model.addPath(myHwhcHolesMarkBup, new makerjs.paths.Line([0,0], [0, y0]));//从孔圆心出发Y方向竖向标线
    /*****************************************************************************/ 
    makerjs.model.addPath(myHwhcHolesMarkBup, new makerjs.paths.Line([0,0], [d, -d]));//直径标线
    makerjs.model.addCaption(myHwhcHolesMarkBup, TxtD, [0,0], [d+markHolesOneD, -d-markHolesOneD]);//直径文字标注
    /*****************************************************************************/    
    makerjs.model.addPath(myHwhcHolesYMarkBup, new makerjs.paths.Line([-d-markShiftTwo,0], [-d-markShiftTwo, y0]));//Y短标线
    makerjs.model.addCaption(myHwhcHolesYMarkBup, TxtY, [-d-markShiftThree,0], [-d-markShiftThree, y0]);//Y文字标注
    /*****************************************************************************/ 
     //标记吊轮孔列 B位置孔下
     var myHwhcHolesBdn= new makerjs.models.Holes(d/2, [[xb, yb-h]]);
          var myHwhcHolesMarkBdn = new makerjs.models.Square(0); //孔的原点       
          myHwhcHolesMarkBdn.origin = [xb, yb-h];       
          var myHwhcHolesYMarkBdn = new makerjs.models.Square(0);      
          myHwhcHolesYMarkBdn.origin = [xb, yb-h];  
    /*****************************************************************************/ 
    makerjs.model.addPath(myHwhcHolesMarkBdn, new makerjs.paths.Line([0,0], [-d-markShiftFour, 0]));//从孔圆心出发X方向横向标线
    makerjs.model.addPath(myHwhcHolesMarkBdn, new makerjs.paths.Line([0,0], [0, h+markShiftTwo]));//从孔圆心出发Y方向竖向标线
    /*****************************************************************************/ 
    makerjs.model.addPath(myHwhcHolesMarkBdn, new makerjs.paths.Line([0,0], [d, -d]));//直径标线
    makerjs.model.addCaption(myHwhcHolesMarkBdn, TxtD, [0,0], [d+markHolesOneD, -d-markHolesOneD]);//直径文字标注
    /*****************************************************************************/   
    makerjs.model.addPath(myHwhcHolesYMarkBdn, new makerjs.paths.Line([-d-markShiftTwo,0], [-d-markShiftTwo, h]));//Y短标线
    makerjs.model.addCaption(myHwhcHolesYMarkBdn, TxtH, [-d-markShiftThree,0], [-d-markShiftThree, h]);//Y文字标注
    /*****************************************************************************/ 
    plate.models["myHwhcHolesBup"+myHolesNum]=myHwhcHolesBup;
    markers.models["myHwhcHolesBup"+myHolesNum]=myHwhcHolesBup;
    markers.models["myHwhcHolesMarkBup"+myHolesNum]=myHwhcHolesMarkBup;
    markers.models["myHwhcHolesXMarkBup"+myHolesNum]=myHwhcHolesXMarkBup;
    markers.models["myHwhcHolesYMarkBup"+myHolesNum]=myHwhcHolesYMarkBup;
    /*****************************************************************************/
    plate.models["myHwhcHolesBdn"+myHolesNum]=myHwhcHolesBdn;
    markers.models["myHwhcHolesBdn"+myHolesNum]=myHwhcHolesBdn;
    markers.models["myHwhcHolesMarkBdn"+myHolesNum]=myHwhcHolesMarkBdn;
    markers.models["myHwhcHolesYMarkBdn"+myHolesNum]=myHwhcHolesYMarkBdn;
    /*****************************************************************************/ 
      
    }else if(area==3){
    //画孔 C位置孔
          //标记吊轮孔列C位置孔上
          var myHwhcHolesCup= new makerjs.models.Holes(d/2, [[xc, yc]]);
          var myHwhcHolesMarkCup = new makerjs.models.Square(0); //孔的原点       
          myHwhcHolesMarkCup.origin = [xc, yc];      
          var myHwhcHolesXMarkCup = new makerjs.models.Square(0);      
          myHwhcHolesXMarkCup.origin =[xc, yc];      
          var myHwhcHolesYMarkCup = new makerjs.models.Square(0);      
          myHwhcHolesYMarkCup.origin = [xc, yc];  
    /*****************************************************************************/ 
    makerjs.model.addPath(myHwhcHolesMarkCup, new makerjs.paths.Line([x0,y0], [x0, y0+markShiftTwo]));//Y轴加长标线
    makerjs.model.addPath(myHwhcHolesMarkCup, new makerjs.paths.Line([0,0], [x0+markShiftThree, 0]));//从孔圆心出发X方向横向标线
    makerjs.model.addPath(myHwhcHolesMarkCup, new makerjs.paths.Line([x0,y0], [x0+markShiftThree, y0]));//X轴加长标线
    makerjs.model.addPath(myHwhcHolesMarkCup, new makerjs.paths.Line([0,0], [0, y0+markShiftTwo]));//从孔圆心出发Y方向竖向标线
    /*****************************************************************************/ 
    makerjs.model.addPath(myHwhcHolesMarkCup, new makerjs.paths.Line([0,0], [d, -d]));//直径标线
    makerjs.model.addCaption(myHwhcHolesMarkCup, TxtD, [0,0], [d+markHolesOneD, -d-markHolesOneD]);//直径文字标注
    /*****************************************************************************/   
    makerjs.model.addPath(myHwhcHolesXMarkCup, new makerjs.paths.Line([0,y0+markShift], [x0, y0+markShift]));//X短标线
    makerjs.model.addCaption(myHwhcHolesXMarkCup, TxtX, [0, y0+markShiftTwo], [x0,y0+markShiftTwo]);//X文字标注
    /*****************************************************************************/     
    makerjs.model.addPath(myHwhcHolesYMarkCup, new makerjs.paths.Line([x0+markShift,0], [x0+markShift, y0]));//Y短标线
    makerjs.model.addCaption(myHwhcHolesYMarkCup, TxtY, [x0+markShiftThree,0], [x0+markShiftThree, y0]);//Y文字标注
    /*****************************************************************************/ 
     //标记吊轮孔列 C位置孔下
     var myHwhcHolesCdn= new makerjs.models.Holes(d/2, [[xc, yc-h]]);
          var myHwhcHolesMarkCdn = new makerjs.models.Square(0); //孔的原点       
          myHwhcHolesMarkCdn.origin = [xc, yc-h];       
          var myHwhcHolesYMarkCdn = new makerjs.models.Square(0);      
          myHwhcHolesYMarkCdn.origin = [xc, yc-h];  
    /*****************************************************************************/ 
    makerjs.model.addPath(myHwhcHolesMarkCdn, new makerjs.paths.Line([0,0], [x0+markShiftThree, 0]));//从孔圆心出发X方向横向标线
    makerjs.model.addPath(myHwhcHolesMarkCdn, new makerjs.paths.Line([0,0], [0, h+markShiftTwo]));//从孔圆心出发Y方向竖向标线
    /*****************************************************************************/ 
    makerjs.model.addPath(myHwhcHolesMarkCdn, new makerjs.paths.Line([0,0], [d, -d]));//直径标线
    makerjs.model.addCaption(myHwhcHolesMarkCdn, TxtD, [0,0], [d+markHolesOneD, -d-markHolesOneD]);//直径文字标注
    /*****************************************************************************/   
    makerjs.model.addPath(myHwhcHolesYMarkCdn, new makerjs.paths.Line([x0+markShift,0], [x0+markShift, h]));//Y短标线
    makerjs.model.addCaption(myHwhcHolesYMarkCdn, TxtH, [x0+markShiftThree,0], [x0+markShiftThree, h]);//Y文字标注
    /*****************************************************************************/ 
    plate.models["myHwhcHolesCup"+myHolesNum]=myHwhcHolesCup;
    markers.models["myHwhcHolesCup"+myHolesNum]=myHwhcHolesCup;
    markers.models["myHwhcHolesMarkCup"+myHolesNum]=myHwhcHolesMarkCup;
    markers.models["myHwhcHolesXMarkCup"+myHolesNum]=myHwhcHolesXMarkCup;
    markers.models["myHwhcHolesYMarkCup"+myHolesNum]=myHwhcHolesYMarkCup;
    /*****************************************************************************/
    plate.models["myHwhcHolesCdn"+myHolesNum]=myHwhcHolesCdn;
    markers.models["myHwhcHolesCdn"+myHolesNum]=myHwhcHolesCdn;
    markers.models["myHwhcHolesMarkCdn"+myHolesNum]=myHwhcHolesMarkCdn;
    markers.models["myHwhcHolesYMarkCdn"+myHolesNum]=myHwhcHolesYMarkCdn;
    /*****************************************************************************/ 
      
    }

myHolesNum++;
return{plate, markers,myHolesNum}
};


export default HangWheelHoleCol;