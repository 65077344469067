//缺3、应力孔
import makerjs from "makerjs";
// inputParam,model,myHolesNum,outParam,fixedParam
// (D,X,Y,area,plate,markers,myHolesNum,glassW,glassH,markShift,markShiftTwo,markShiftThree,markShiftFour,markHolesOneD)
function StressHoleGap(inputParam,model,myHolesNum,outParam,fixedParam,zoom){
var H=inputParam.H,D=inputParam.D,W=inputParam.W,X=inputParam.X,Y=inputParam.Y,area=inputParam.area,myZoom=zoom*1,
plate=model.plate,markers=model.markers,
glassW=outParam.glassW,glassH=outParam.glassH,
markShift=fixedParam.markShift,markShiftF=fixedParam.markShiftF,markShiftTwo=fixedParam.markShiftTwo,
   markShiftThree=fixedParam.markShiftThree,markShiftFour=fixedParam.markShiftFour,markHolesOneD=fixedParam.markHolesOneD,
 
   d = D*1*myZoom,
   w= W*1*myZoom,
   h= H*1*myZoom,
   r=d/2,
   dd=Math.sqrt(r*r-h/2*h/2).toFixed(),
     x0 = X*1*myZoom,
     y0 = Y*1*myZoom;
     /*
     d = D*1,
     w= W*1,
     h= H*1,
     r=d/2,
     dd=Math.sqrt(r*r-h/2*h/2).toFixed(),
       x0 = X*1,
       y0 = Y*1;
       */
     if(d!==0){
      var TxtD="∮"+D;
     }
     if(h!==0){
      var TxtH=H;
     }
     if(w!==0){
      var TxtW=W;
     }
     if(x0!==0){          
     var TxtX=X;
     }
     if(y0!==0){          
      var TxtY=Y;
     }
  //在A区域 计算孔的位置       
  var xa=x0,ya=glassH * 1-y0,
  //B区域//只有Y标注没有X标注 
  xb=glassW * 1/2,yb=glassH * 1-y0,
  //C区域
  xc=glassW * 1-x0,yc=glassH * 1-y0,
  //D区域
  //只有X标注没有Y标注 
  xd=x0,yd=glassH * 1/2,
  //E区域
  //只有X标注没有Y标注 
  xe=glassW * 1/2,ye=glassH * 1/2,
  //F区域
  //只有X标注没有Y标注 
  xf=glassW * 1-x0,yf=glassH * 1/2,
  //在G区域 计算孔的位置
  xg=x0,yg=y0,
  //在H区域 计算孔的位置
  //只标注Y
  xh=glassW*1/2,yh=y0,
  //在I区域 计算孔的位置
  xi=glassW*1-x0,yi=y0;
  if(area==1){
    //画缺 A位置
        //画应力孔缺 A位置   H W D  width=w-d/2      
        var myShgGapARect=new makerjs.models.Rectangle(w-dd+5, h); 
        myShgGapARect.origin=[xa-5,ya-h];     
        var myShgGapAHoles=new makerjs.models.Holes(r, [0, 0]);
        myShgGapAHoles.origin=[xa+w,ya-h/2];
        var myShgGapA=new makerjs.model.combineUnion(myShgGapARect, myShgGapAHoles);
     //画应力孔缺标记 A位置 
  var myShgGapAMaker = new makerjs.models.Square(0); //D的起始点       
  myShgGapAMaker.origin = [xa,ya+y0];
  var myShgGapAMakerW = new makerjs.models.Square(0); //W的起始点        
  myShgGapAMakerW.origin = [xa,ya+y0];
  var myShgGapAMakerH = new makerjs.models.Square(0); //H的起始点        
  myShgGapAMakerH.origin = [xa,ya+y0];
  var myShgGapAMakerY = new makerjs.models.Square(0); //Y的起始点        
  myShgGapAMakerY.origin = [xa,ya+y0];
  /*****************************************************************************/
  makerjs.model.addPath(myShgGapAMaker, new makerjs.paths.Line([0,0], [0, markShiftThree]));//Y轴加长标线
  makerjs.model.addPath(myShgGapAMaker, new makerjs.paths.Line([w,0], [w,0+markShiftThree]));//Y轴方向短线
  makerjs.model.addPath(myShgGapAMaker, new makerjs.paths.Line([0,0], [0-markShiftThree, 0]));//X轴加长标线
  makerjs.model.addPath(myShgGapAMaker, new makerjs.paths.Line([0,0-y0], [0-markShiftThree, 0-y0]));//X轴方向短线
  makerjs.model.addPath(myShgGapAMaker, new makerjs.paths.Line([0,0-y0-h], [0-markShiftThree, 0-y0-h]));//X轴方向短线
  
  makerjs.model.addPath(myShgGapAMaker, new makerjs.paths.Line([w,0-y0-h/2+r], [w+markShiftThree, 0-y0-h/2+r]));//D相关 X轴方向短线上
  makerjs.model.addPath(myShgGapAMaker, new makerjs.paths.Line([w,0-y0-h/2-r], [w+markShiftThree, 0-y0-h/2-r]));//D相关 X轴方向短线下
  
  makerjs.model.addPath(myShgGapAMaker, new makerjs.paths.Line([w,0-y0-h/2+r], [w, 0-y0-h/2-r]));//D相关 Y轴方向短线
  makerjs.model.addCaption(myShgGapAMaker,TxtD, [w+markShift, 0-y0-h/2-r], [w+markShift,0-y0-h/2+r]);//D文字标注
  makerjs.model.addPath(myShgGapAMakerY, new makerjs.paths.Line([0-markShiftTwo,0], [0-markShiftTwo, 0-y0]));//Y轴方向标注短线 
  makerjs.model.addCaption(myShgGapAMakerY,TxtY, [0-markShiftFour,0], [0-markShiftFour, 0-y0]);//Y文字标注
  /*****************************************************************************/
  makerjs.model.addPath(myShgGapAMakerH, new makerjs.paths.Line([0-markShiftTwo,0-y0], [0-markShiftTwo, 0-y0-h]));//H轴方向标注短线 
  makerjs.model.addCaption(myShgGapAMakerH,TxtH, [0-markShiftFour, 0-y0-h], [0-markShiftFour,0-y0]);//H文字标注
  
  /*****************************************************************************/
  makerjs.model.addPath(myShgGapAMakerW, new makerjs.paths.Line([0,markShift], [0+w, markShift]));//W短线标注
  makerjs.model.addCaption(myShgGapAMakerW, TxtW, [0,markShiftTwo], [0+w, markShiftTwo]);//W文字标注
  /*****************************************************************************/ 
  /*****************************************************************************/
  /*****************************************************************************/ 
  plate.models["myShgGapA"+myHolesNum]=myShgGapA;
  markers.models["myShgGapA"+myHolesNum]=myShgGapA;
  markers.models["myShgGapAMaker"+myHolesNum]=myShgGapAMaker;
  markers.models["myShgGapAMakerW"+myHolesNum]=myShgGapAMakerW;
  markers.models["myShgGapAMakerH"+myHolesNum]=myShgGapAMakerH;
  markers.models["myShgGapAMakerY"+myHolesNum]=myShgGapAMakerY;
  /*****************************************************************************/ 
  makerjs.model.combineSubtraction(markers.models.myRect, markers.models["myShgGapA"+myHolesNum]);
  console.log("markers.models=",markers.models); 
  /*****************************************************************************/
  /*****************************************************************************/ 
      
  }
  else if(area==3){
   //画缺 C位置
        //画应力孔缺 C位置   H W D  width=w-d/2 
        var xcdd=(w-dd)*1; 
        var myShgGapCRect=new makerjs.models.Rectangle(w-dd+5, h); 
        myShgGapCRect.origin=[xc-xcdd,yc-h];    
        var myShgGapCHoles=new makerjs.models.Holes(r, [0, 0]);
        myShgGapCHoles.origin=[xc-w,yc-h/2];      
        var myShgGapC=new makerjs.model.combineUnion(myShgGapCRect, myShgGapCHoles); 
  
     //画应力孔缺标记 C位置 
  var myShgGapCMaker = new makerjs.models.Square(0); //D的起始点       
  myShgGapCMaker.origin = [xc,yc+y0];
  var myShgGapCMakerW = new makerjs.models.Square(0); //W的起始点        
  myShgGapCMakerW.origin = [xc,yc+y0];
  var myShgGapCMakerH = new makerjs.models.Square(0); //H的起始点        
  myShgGapCMakerH.origin = [xc,yc+y0];
  var myShgGapCMakerY = new makerjs.models.Square(0); //Y的起始点        
  myShgGapCMakerY.origin = [xc,yc+y0];
  /*****************************************************************************/
  makerjs.model.addPath(myShgGapCMaker, new makerjs.paths.Line([0,0], [0, markShiftThree]));//Y轴加长标线
  makerjs.model.addPath(myShgGapCMaker, new makerjs.paths.Line([-w,0], [-w,0+markShiftThree]));//Y轴方向短线
  makerjs.model.addPath(myShgGapCMaker, new makerjs.paths.Line([0,0], [0+markShiftThree, 0]));//X轴加长标线
  makerjs.model.addPath(myShgGapCMaker, new makerjs.paths.Line([0,0-y0], [0+markShiftThree, 0-y0]));//X轴方向短线
  makerjs.model.addPath(myShgGapCMaker, new makerjs.paths.Line([0,0-y0-h], [0+markShiftThree, 0-y0-h]));//X轴方向短线
  
  makerjs.model.addPath(myShgGapCMaker, new makerjs.paths.Line([-w,0-y0-h/2+r], [-w-markShiftThree, 0-y0-h/2+r]));//D相关 X轴方向短线上
  makerjs.model.addPath(myShgGapCMaker, new makerjs.paths.Line([-w,0-y0-h/2-r], [-w-markShiftThree, 0-y0-h/2-r]));//D相关 X轴方向短线下
  
  makerjs.model.addPath(myShgGapCMaker, new makerjs.paths.Line([-w,0-y0-h/2+r], [-w, 0-y0-h/2-r]));//D相关 Y轴方向短线
  makerjs.model.addCaption(myShgGapCMaker,TxtD, [-w-markShift, 0-y0-h/2-r], [-w-markShift,0-y0-h/2+r]);//D文字标注
  makerjs.model.addPath(myShgGapCMakerY, new makerjs.paths.Line([0+markShift,0], [0+markShift, 0-y0]));//Y轴方向标注短线 
  makerjs.model.addCaption(myShgGapCMakerY,TxtY, [0+markShiftFour, 0-y0], [0+markShiftFour,0]);//Y文字标注
  // /*****************************************************************************/
  makerjs.model.addPath(myShgGapCMakerH, new makerjs.paths.Line([0+markShift,0-y0], [0+markShift, 0-y0-h]));//H轴方向标注短线 
  makerjs.model.addCaption(myShgGapCMakerH,TxtH, [0+markShiftFour, 0-y0-h], [0+markShiftFour,0-y0]);//H文字标注
  
  // /*****************************************************************************/
  makerjs.model.addPath(myShgGapCMakerW, new makerjs.paths.Line([0,markShift], [0-w, markShift]));//W短线标注
  makerjs.model.addCaption(myShgGapCMakerW, TxtW, [0-w, markShiftTwo], [0,markShiftTwo]);//W文字标注
  /*****************************************************************************/ 
  /*****************************************************************************/
  /*****************************************************************************/ 
  plate.models["myShgGapC"+myHolesNum]=myShgGapC;
  markers.models["myShgGapC"+myHolesNum]=myShgGapC;
  markers.models["myShgGapCMaker"+myHolesNum]=myShgGapCMaker;
  markers.models["myShgGapCMakerW"+myHolesNum]=myShgGapCMakerW;
  markers.models["myShgGapCMakerH"+myHolesNum]=myShgGapCMakerH;
  markers.models["myShgGapCMakerY"+myHolesNum]=myShgGapCMakerY;
  /*****************************************************************************/
  makerjs.model.combineSubtraction(markers.models.myRect, markers.models["myShgGapC"+myHolesNum]);
  console.log("markers.models=",markers.models);  
  /*****************************************************************************/
  /*****************************************************************************/ 
            
  }
  else if(area==4){
  //画缺 D位置
        //画应力孔缺 D位置   H W D  width=w-d/2      
        var myShgGapDRect=new makerjs.models.Rectangle(w-dd+5, h); 
        myShgGapDRect.origin=[xd-5,yd-h/2];     
        var myShgGapDHoles=new makerjs.models.Holes(r, [0, 0]);
        myShgGapDHoles.origin=[xd+w,yd];
        var myShgGapD=new makerjs.model.combineUnion(myShgGapDRect, myShgGapDHoles);
     //画应力孔缺标记 D位置 
  var myShgGapDMaker = new makerjs.models.Square(0); //D的起始点       
  myShgGapDMaker.origin = [xd,yd+y0];
  var myShgGapDMakerW = new makerjs.models.Square(0); //W的起始点        
  myShgGapDMakerW.origin = [xd,yd+y0];
  var myShgGapDMakerH = new makerjs.models.Square(0); //H的起始点        
  myShgGapDMakerH.origin = [xd,yd+y0];
  var myShgGapDMakerY = new makerjs.models.Square(0); //Y的起始点        
  myShgGapDMakerY.origin = [xd,yd+y0];
  /*****************************************************************************/
  makerjs.model.addPath(myShgGapDMaker, new makerjs.paths.Line([0,yd], [0, yd+markShiftFour]));//Y轴加长标线
  makerjs.model.addPath(myShgGapDMaker, new makerjs.paths.Line([w,yd], [w,yd+markShiftFour]));//Y轴方向短线
  
  makerjs.model.addPath(myShgGapDMaker, new makerjs.paths.Line([w,0+r], [w+markShiftFour, 0+r]));//D相关 X轴方向短线上
  makerjs.model.addPath(myShgGapDMaker, new makerjs.paths.Line([w,0-r], [w+markShiftFour, 0-r]));//D相关 X轴方向短线下
  
  makerjs.model.addPath(myShgGapDMaker, new makerjs.paths.Line([w,0+r], [w, 0-r]));//D相关 Y轴方向短线
  makerjs.model.addCaption(myShgGapDMaker,TxtD, [w+markShift,0-r], [w+markShift, 0+r]);//D文字标注
  /*****************************************************************************/
  makerjs.model.addPath(myShgGapDMakerH, new makerjs.paths.Line([0+markShiftTwo,h/2], [0+markShiftTwo, -h/2]));//H轴方向标注短线 
  makerjs.model.addCaption(myShgGapDMakerH,TxtH, [0+markShiftFour,-h/2], [0+markShiftFour, h/2]);//H文字标注
  /*****************************************************************************/
  makerjs.model.addPath(myShgGapDMakerW, new makerjs.paths.Line([0,yd+markShift], [0+w, yd+markShift]));//W短线标注
  makerjs.model.addCaption(myShgGapDMakerW, TxtW, [0,yd+markShiftFour], [0+w, yd+markShiftFour]);//W文字标注
  /*****************************************************************************/ 
  /*****************************************************************************/ 
  plate.models["myShgGapD"+myHolesNum]=myShgGapD;
  markers.models["myShgGapD"+myHolesNum]=myShgGapD;
  markers.models["myShgGapDMaker"+myHolesNum]=myShgGapDMaker;
  markers.models["myShgGapDMakerW"+myHolesNum]=myShgGapDMakerW;
  markers.models["myShgGapDMakerH"+myHolesNum]=myShgGapDMakerH;
  markers.models["myShgGapDMakerY"+myHolesNum]=myShgGapDMakerY;
  /*****************************************************************************/ 
  makerjs.model.combineSubtraction(markers.models.myRect, markers.models["myShgGapD"+myHolesNum]);
  console.log("markers.models=",markers.models);   
  /*****************************************************************************/
  /*****************************************************************************/ 
      
  }
  else if(area==6){
   //画缺 F位置
        //画应力孔缺 F位置   H W D  width=w-d/2 
        var xcdd=(w-dd)*1; 
        var myShgGapFRect=new makerjs.models.Rectangle(w-dd+5, h); 
        myShgGapFRect.origin=[xf-xcdd,yf-h/2];    
        var myShgGapFHoles=new makerjs.models.Holes(r, [0, 0]);
        myShgGapFHoles.origin=[xf-w,yf];      
        var myShgGapF=new makerjs.model.combineUnion(myShgGapFRect, myShgGapFHoles);
     //画应力孔缺标记 F位置 
  var myShgGapFMaker = new makerjs.models.Square(0); //D的起始点       
  myShgGapFMaker.origin = [xf,yf+y0];
  var myShgGapFMakerW = new makerjs.models.Square(0); //W的起始点        
  myShgGapFMakerW.origin = [xf,yf+y0];
  var myShgGapFMakerH = new makerjs.models.Square(0); //H的起始点        
  myShgGapFMakerH.origin = [xf,yf+y0];
  var myShgGapFMakerY = new makerjs.models.Square(0); //Y的起始点        
  myShgGapFMakerY.origin = [xf,yf+y0];
  /*****************************************************************************/
  makerjs.model.addPath(myShgGapFMaker, new makerjs.paths.Line([0,yd], [0, yd+markShiftThree]));//Y轴加长标线
  makerjs.model.addPath(myShgGapFMaker, new makerjs.paths.Line([-w,yd], [-w,yd+markShiftThree]));//Y轴方向短线
  makerjs.model.addPath(myShgGapFMaker, new makerjs.paths.Line([0,h/2], [0+markShiftThree, h/2]));//X轴方向短线
  makerjs.model.addPath(myShgGapFMaker, new makerjs.paths.Line([0,-h/2], [0+markShiftThree, -h/2]));//X轴方向短线
  
  makerjs.model.addPath(myShgGapFMaker, new makerjs.paths.Line([-w,r], [-w-markShiftThree, r]));//D相关 X轴方向短线上
  makerjs.model.addPath(myShgGapFMaker, new makerjs.paths.Line([-w,-r], [-w-markShiftThree, -r]));//D相关 X轴方向短线下
  
  makerjs.model.addPath(myShgGapFMaker, new makerjs.paths.Line([-w,r], [-w, -r]));//D相关 Y轴方向短线
  makerjs.model.addCaption(myShgGapFMaker,TxtD, [-w-markShift,-r], [-w-markShift, r]);//D文字标注
  // /*****************************************************************************/
  makerjs.model.addPath(myShgGapFMakerH, new makerjs.paths.Line([0+markShift,h/2], [0+markShift, -h/2]));//H轴方向标注短线 
  makerjs.model.addCaption(myShgGapFMakerH,TxtH, [0+markShiftFour,-h/2], [0+markShiftFour, h/2]);//H文字标注
  
  // /*****************************************************************************/
  makerjs.model.addPath(myShgGapFMakerW, new makerjs.paths.Line([0,yd+markShift], [0-w, yd+markShift]));//W短线标注
  makerjs.model.addCaption(myShgGapFMakerW, TxtW, [0-w, yd+markShiftTwo], [0,yd+markShiftTwo]);//W文字标注
  /*****************************************************************************/ 
  /*****************************************************************************/
  /*****************************************************************************/ 
  plate.models["myShgGapF"+myHolesNum]=myShgGapF;
  markers.models["myShgGapF"+myHolesNum]=myShgGapF;
  markers.models["myShgGapFMaker"+myHolesNum]=myShgGapFMaker;
  markers.models["myShgGapFMakerW"+myHolesNum]=myShgGapFMakerW;
  markers.models["myShgGapFMakerH"+myHolesNum]=myShgGapFMakerH;
  markers.models["myShgGapFMakerY"+myHolesNum]=myShgGapFMakerY;
  /*****************************************************************************/
  makerjs.model.combineSubtraction(markers.models.myRect, markers.models["myShgGapF"+myHolesNum]);
  console.log("markers.models=",markers.models); 
  /*****************************************************************************/
  /*****************************************************************************/ 
           
  }
  else if(area==7){
  //画缺 G位置
        //画应力孔缺 G位置   H W D  width=w-d/2      
        var myShgGapGRect=new makerjs.models.Rectangle(w-dd+5, h); 
        myShgGapGRect.origin=[xg-5,yg];     
        var myShgGapGHoles=new makerjs.models.Holes(r, [0, 0]);
        myShgGapGHoles.origin=[xg+w,yg+h/2];
        var myShgGapG=new makerjs.model.combineUnion(myShgGapGRect, myShgGapGHoles);      
        console.log("myShgGapG==",myShgGapG);      
     //画应力孔缺标记 G位置 
  var myShgGapGMaker = new makerjs.models.Square(0); //D的起始点       
  myShgGapGMaker.origin = [xg,yg];
  var myShgGapGMakerW = new makerjs.models.Square(0); //W的起始点        
  myShgGapGMakerW.origin = [xg,yg];
  var myShgGapGMakerH = new makerjs.models.Square(0); //H的起始点        
  myShgGapGMakerH.origin = [xg,yg];
  var myShgGapGMakerY = new makerjs.models.Square(0); //Y的起始点        
  myShgGapGMakerY.origin = [xg,yg];
  /*****************************************************************************/
  makerjs.model.addPath(myShgGapGMaker, new makerjs.paths.Line([0,-y0], [0, -y0-markShiftFour]));//Y轴加长标线
  makerjs.model.addPath(myShgGapGMaker, new makerjs.paths.Line([w,-y0], [w,-y0-markShiftFour]));//Y轴方向短线
  makerjs.model.addPath(myShgGapGMaker, new makerjs.paths.Line([0,-y0], [0-markShiftFour, -y0]));//X轴加长标线
  makerjs.model.addPath(myShgGapGMaker, new makerjs.paths.Line([0,0], [0-markShiftFour, 0]));//X轴方向短线
  makerjs.model.addPath(myShgGapGMaker, new makerjs.paths.Line([0,h], [0-markShiftFour, h]));//X轴方向短线
  
  makerjs.model.addPath(myShgGapGMaker, new makerjs.paths.Line([w,0+h/2+r], [w+markShiftFour, 0+h/2+r]));//D相关 X轴方向短线上
  makerjs.model.addPath(myShgGapGMaker, new makerjs.paths.Line([w,0+h/2-r], [w+markShiftFour, 0+h/2-r]));//D相关 X轴方向短线下
  
  makerjs.model.addPath(myShgGapGMaker, new makerjs.paths.Line([w,0+h/2+r], [w, 0+h/2-r]));//D相关 Y轴方向短线
  makerjs.model.addCaption(myShgGapGMaker,TxtD, [w+markShift,0+h/2-r], [w+markShift, 0+h/2+r]);//D文字标注
  makerjs.model.addPath(myShgGapGMakerY, new makerjs.paths.Line([0-markShiftTwo,0], [0-markShiftTwo, 0-y0]));//Y轴方向标注短线 
  makerjs.model.addCaption(myShgGapGMakerY,TxtY, [0-markShiftFour,0], [0-markShiftFour, 0-y0]);//Y文字标注
  // // /*****************************************************************************/
  makerjs.model.addPath(myShgGapGMakerH, new makerjs.paths.Line([0-markShiftTwo,0], [0-markShiftTwo, h]));//H轴方向标注短线 
  makerjs.model.addCaption(myShgGapGMakerH,TxtH, [0-markShiftFour, 0], [0-markShiftFour,h]);//H文字标注
  
  // // /*****************************************************************************/
  makerjs.model.addPath(myShgGapGMakerW, new makerjs.paths.Line([0,-y0-markShiftTwo], [0+w, -y0-markShiftTwo]));//W短线标注
  makerjs.model.addCaption(myShgGapGMakerW, TxtW, [0,-y0-markShiftFour], [0+w, -y0-markShiftFour]);//W文字标注
  /*****************************************************************************/ 
  /*****************************************************************************/
  /*****************************************************************************/ 
  plate.models["myShgGapG"+myHolesNum]=myShgGapG;
  markers.models["myShgGapG"+myHolesNum]=myShgGapG;
  markers.models["myShgGapGMaker"+myHolesNum]=myShgGapGMaker;
  markers.models["myShgGapGMakerW"+myHolesNum]=myShgGapGMakerW;
  markers.models["myShgGapGMakerH"+myHolesNum]=myShgGapGMakerH;
  markers.models["myShgGapGMakerY"+myHolesNum]=myShgGapGMakerY;
  /*****************************************************************************/ 
  makerjs.model.combineSubtraction(markers.models.myRect, markers.models["myShgGapG"+myHolesNum]);
  console.log("markers.models=",markers.models);   
  /*****************************************************************************/
  /*****************************************************************************/ 
      
  }
  else if(area==9){
  //画缺 I位置
        //画应力孔缺 I位置   H W D  width=w-d/2 
        var xcdd=(w-dd)*1; 
            console.log("w==",w);  
            console.log("dd==",dd);
        var myShgGapIRect=new makerjs.models.Rectangle(w-dd+5, h); 
        myShgGapIRect.origin=[xi-xcdd,yi];    
        var myShgGapIHoles=new makerjs.models.Holes(r, [0, 0]);
        myShgGapIHoles.origin=[xi-w,yi+h/2];      
        var myShgGapI=new makerjs.model.combineUnion(myShgGapIRect, myShgGapIHoles);
        console.log("myShgGapI==",myShgGapI);  
  //    //画应力孔缺标记 I位置 
  var myShgGapIMaker = new makerjs.models.Square(0); //D的起始点       
  myShgGapIMaker.origin = [xi,yi-y0];
  var myShgGapIMakerW = new makerjs.models.Square(0); //W的起始点        
  myShgGapIMakerW.origin = [xi,yi-y0];
  var myShgGapIMakerH = new makerjs.models.Square(0); //H的起始点        
  myShgGapIMakerH.origin = [xi,yi-y0];
  var myShgGapIMakerY = new makerjs.models.Square(0); //Y的起始点        
  myShgGapIMakerY.origin = [xi,yi-y0];
  // /*****************************************************************************/
  makerjs.model.addPath(myShgGapIMaker, new makerjs.paths.Line([0,0], [0, -markShiftThree]));//Y轴加长标线
  makerjs.model.addPath(myShgGapIMaker, new makerjs.paths.Line([-w,0], [-w,-markShiftThree]));//Y轴方向短线
  makerjs.model.addPath(myShgGapIMaker, new makerjs.paths.Line([0,0], [0+markShiftThree, 0]));//X轴加长标线
  makerjs.model.addPath(myShgGapIMaker, new makerjs.paths.Line([0,y0], [0+markShiftThree, y0]));//X轴方向短线
  makerjs.model.addPath(myShgGapIMaker, new makerjs.paths.Line([0,y0+h], [0+markShiftThree, y0+h]));//X轴方向短线
  
  makerjs.model.addPath(myShgGapIMaker, new makerjs.paths.Line([-w,y0+h/2-r], [-w-markShiftThree, y0+h/2-r]));//D相关 X轴方向短线上
  makerjs.model.addPath(myShgGapIMaker, new makerjs.paths.Line([-w,y0+h/2+r], [-w-markShiftThree, y0+h/2+r]));//D相关 X轴方向短线下
  
  makerjs.model.addPath(myShgGapIMaker, new makerjs.paths.Line([-w,y0+h/2-r], [-w, y0+h/2+r]));//D相关 Y轴方向短线
  makerjs.model.addCaption(myShgGapIMaker,TxtD, [-w-markShift,y0+h/2-r], [-w-markShift, y0+h/2+r]);//D文字标注
  makerjs.model.addPath(myShgGapIMakerY, new makerjs.paths.Line([0+markShift,0], [0+markShift, y0]));//Y轴方向标注短线 
  makerjs.model.addCaption(myShgGapIMakerY,TxtY, [0+markShiftFour, y0], [0+markShiftFour,0]);//Y文字标注
  // // /*****************************************************************************/
  makerjs.model.addPath(myShgGapIMakerH, new makerjs.paths.Line([0+markShift,0+y0], [0+markShift, 0+y0+h]));//H轴方向标注短线 
  makerjs.model.addCaption(myShgGapIMakerH,TxtH, [0+markShiftFour,0+y0], [0+markShiftFour, 0+y0+h]);//H文字标注
  
  // // /*****************************************************************************/
  makerjs.model.addPath(myShgGapIMakerW, new makerjs.paths.Line([0,-markShiftTwo], [0-w, -markShiftTwo]));//W短线标注
  makerjs.model.addCaption(myShgGapIMakerW, TxtW, [0-w, -markShiftFour], [0,-markShiftFour]);//W文字标注
  /*****************************************************************************/ 
  /*****************************************************************************/
  /*****************************************************************************/ 
  plate.models["myShgGapI"+myHolesNum]=myShgGapI;
  markers.models["myShgGapI"+myHolesNum]=myShgGapI;
  markers.models["myShgGapIMaker"+myHolesNum]=myShgGapIMaker;
  markers.models["myShgGapIMakerW"+myHolesNum]=myShgGapIMakerW;
  markers.models["myShgGapIMakerH"+myHolesNum]=myShgGapIMakerH;
  markers.models["myShgGapIMakerY"+myHolesNum]=myShgGapIMakerY;
  /*****************************************************************************/
  makerjs.model.combineSubtraction(markers.models.myRect, markers.models["myShgGapI"+myHolesNum]);
  console.log("markers.models=",markers.models); 
  /*****************************************************************************/
  /*****************************************************************************/ 
      
  }

myHolesNum++;
return{plate, markers,myHolesNum}
};


export default StressHoleGap;