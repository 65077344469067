//9、相对吊轮孔
import makerjs from "makerjs";
// inputParam,model,this.myHolesNum,outParam,fixedParam
// (D,X,Y,area,plate,markers,myHolesNum,glassW,glassH,markShift,markShiftTwo,markShiftThree,markShiftFour,markHolesOneD)
function RelSuWheelHole(inputParam,model,myHolesNum,outParam,fixedParam,zoom){
var D=inputParam.D,W=inputParam.W,X=inputParam.X,Y=inputParam.Y,area=inputParam.area,myZoom=zoom*1,
plate=model.plate,markers=model.markers,
glassW=outParam.glassW,glassH=outParam.glassH,
   markShift=fixedParam.markShift,markShiftTwo=fixedParam.markShiftTwo,
   markShiftThree=fixedParam.markShiftThree,markShiftFour=fixedParam.markShiftFour,markHolesOneD=fixedParam.markHolesOneD,
 
   d = D*1*myZoom,
      w= W*1*myZoom,
        x0 = X*1*myZoom,
        y0 = Y*1*myZoom;
        /*
        d = D*1,
           w= W*1,
             x0 = X*1,
             y0 = Y*1;
             */
        if(d!==0){
         var TxtD="∮"+D;
        }
        if(w!==0){
         var TxtW=W;
        }
        if(x0!==0){          
        var TxtX=X;
        }
        if(y0!==0){          
         var TxtY=Y;
        }
  //在A区域 计算孔的位置       
  var xa=x0,ya=glassH * 1-y0,
  //B区域//只有Y标注没有X标注 
  xb=glassW * 1/2,yb=glassH * 1-y0,
  //C区域
  xc=glassW * 1-x0,yc=glassH * 1-y0,
  //D区域
  //只有X标注没有Y标注 
  xd=x0,yd=glassH * 1/2,
  //E区域
  //只有X标注没有Y标注 
  xe=glassW * 1/2,ye=glassH * 1/2,
  //F区域
  //只有X标注没有Y标注 
  xf=glassW * 1-x0,yf=glassH * 1/2,
  //在G区域 计算孔的位置
  xg=x0,yg=y0,
  //在H区域 计算孔的位置
  //只标注Y
  xh=glassW*1/2,yh=y0,
  //在I区域 计算孔的位置
  xi=glassW*1-x0,yi=y0;
 //画孔 A位置孔
      //标记相对吊轮孔 A位置孔左
      var myRswhHolesALeft= new makerjs.models.Holes(d/2, [[xa, ya]]);
      var myRswhHolesMarkALeft = new makerjs.models.Square(0); //孔的原点       
      myRswhHolesMarkALeft.origin = [xa, ya];      
      var myRswhHolesXMarkALeft = new makerjs.models.Square(0);      
      myRswhHolesXMarkALeft.origin =[xa, ya];      
      var myRswhHolesYMarkALeft = new makerjs.models.Square(0);      
      myRswhHolesYMarkALeft.origin = [xa, ya];  
/*****************************************************************************/ 
makerjs.model.addPath(myRswhHolesMarkALeft, new makerjs.paths.Line([-x0,y0], [-x0, y0+markShiftTwo]));//Y轴加长标线
makerjs.model.addPath(myRswhHolesMarkALeft, new makerjs.paths.Line([0,0], [-x0-markShiftThree, 0]));//从孔圆心出发X方向横向标线
makerjs.model.addPath(myRswhHolesMarkALeft, new makerjs.paths.Line([-x0,y0], [-x0-markShiftThree, y0]));//X轴加长标线
makerjs.model.addPath(myRswhHolesMarkALeft, new makerjs.paths.Line([0,0], [0, y0+markShiftTwo]));//从孔圆心出发Y方向竖向标线
/*****************************************************************************/ 
makerjs.model.addPath(myRswhHolesMarkALeft, new makerjs.paths.Line([0,0], [d, -d]));//直径标线
makerjs.model.addCaption(myRswhHolesMarkALeft, TxtD, [0,0], [d+markHolesOneD, -d-markHolesOneD]);//直径文字标注
/*****************************************************************************/   
makerjs.model.addPath(myRswhHolesXMarkALeft, new makerjs.paths.Line([0,y0+markShift], [-x0, y0+markShift]));//X短标线
makerjs.model.addCaption(myRswhHolesXMarkALeft, TxtX, [-x0,y0+markShiftTwo], [0, y0+markShiftTwo]);//X文字标注
/*****************************************************************************/     
makerjs.model.addPath(myRswhHolesYMarkALeft, new makerjs.paths.Line([-x0-markShiftTwo,0], [-x0-markShiftTwo, y0]));//Y短标线
makerjs.model.addCaption(myRswhHolesYMarkALeft, TxtY, [-x0-markShiftThree,0], [-x0-markShiftThree, y0]);//Y文字标注
/*****************************************************************************/ 
 //画孔 A位置孔
      //标记相对吊轮孔 A位置孔中
      var myRswhHolesAMid= new makerjs.models.Holes(d/2, [[xa+w, ya]]);
      var myRswhHolesMarkAMid = new makerjs.models.Square(0); //孔的原点       
      myRswhHolesMarkAMid.origin = [xa+w, ya];      
      var myRswhHolesXMarkAMid = new makerjs.models.Square(0);      
      myRswhHolesXMarkAMid.origin =[xa+w, ya];   
/*****************************************************************************/ 
makerjs.model.addPath(myRswhHolesMarkAMid, new makerjs.paths.Line([0,0], [-x0-markShiftThree, 0]));//从孔圆心出发X方向横向标线
makerjs.model.addPath(myRswhHolesMarkAMid, new makerjs.paths.Line([0,0], [0, y0+markShiftTwo]));//从孔圆心出发Y方向竖向标线
/*****************************************************************************/ 
makerjs.model.addPath(myRswhHolesMarkAMid, new makerjs.paths.Line([0,0], [d, -d]));//直径标线
makerjs.model.addCaption(myRswhHolesMarkAMid, TxtD, [0,0], [d+markHolesOneD, -d-markHolesOneD]);//直径文字标注
/*****************************************************************************/   
makerjs.model.addPath(myRswhHolesXMarkAMid, new makerjs.paths.Line([0,y0+markShift], [-w, y0+markShift]));//X短标线
makerjs.model.addCaption(myRswhHolesXMarkAMid, TxtW, [-w,y0+markShiftTwo], [0, y0+markShiftTwo]);//X文字标注
/*****************************************************************************/     
/*****************************************************************************/ 
/*****************************************************************************/ 
 //画孔 A位置孔
      //标记双固定孔 A位置孔右
      var myRswhHolesARight= new makerjs.models.Holes(d/2, [[xa+w*2, ya]]);
      //标记孔
      var myRswhHolesMarkARight = new makerjs.models.Square(0); //孔的原点       
      myRswhHolesMarkARight.origin = [xa+w*2, ya];      
      var myRswhHolesXMarkARight = new makerjs.models.Square(0);      
      myRswhHolesXMarkARight.origin =[xa+w*2, ya]; 
/*****************************************************************************/ 
makerjs.model.addPath(myRswhHolesMarkARight, new makerjs.paths.Line([0,0], [-x0-markShiftThree, 0]));//从孔圆心出发X方向横向标线
makerjs.model.addPath(myRswhHolesMarkARight, new makerjs.paths.Line([0,0], [0, y0+markShiftTwo]));//从孔圆心出发Y方向竖向标线
/*****************************************************************************/ 
makerjs.model.addPath(myRswhHolesMarkARight, new makerjs.paths.Line([0,0], [d, -d]));//直径标线
makerjs.model.addCaption(myRswhHolesMarkARight, TxtD, [0,0], [d+markHolesOneD, -d-markHolesOneD]);//直径文字标注
/*****************************************************************************/   
makerjs.model.addPath(myRswhHolesXMarkARight, new makerjs.paths.Line([0,y0+markShift], [-w, y0+markShift]));//X短标线
makerjs.model.addCaption(myRswhHolesXMarkARight, TxtW, [-w,y0+markShiftTwo], [0, y0+markShiftTwo]);//X文字标注
/*****************************************************************************/
//C位置
//画孔 C位置孔
      //标记相对吊轮孔C位置孔右
      var myRswhHolesCRight= new makerjs.models.Holes(d/2, [[xc, yc]]);
      var myRswhHolesMarkCRight = new makerjs.models.Square(0); //孔的原点       
      myRswhHolesMarkCRight.origin = [xc, yc];      
      var myRswhHolesXMarkCRight = new makerjs.models.Square(0);      
      myRswhHolesXMarkCRight.origin =[xc, yc];      
      var myRswhHolesYMarkCRight = new makerjs.models.Square(0);      
      myRswhHolesYMarkCRight.origin = [xc, yc]; 
/*****************************************************************************/ 
makerjs.model.addPath(myRswhHolesMarkCRight, new makerjs.paths.Line([x0,y0], [x0, y0+markShiftTwo]));//Y轴加长标线
makerjs.model.addPath(myRswhHolesMarkCRight, new makerjs.paths.Line([0,0], [x0+markShiftThree, 0]));//从孔圆心出发X方向横向标线
makerjs.model.addPath(myRswhHolesMarkCRight, new makerjs.paths.Line([0,0], [-w, 0]));//从孔圆心出发X方向横向标线
makerjs.model.addPath(myRswhHolesMarkCRight, new makerjs.paths.Line([x0,y0], [x0+markShiftThree, y0]));//X轴加长标线
makerjs.model.addPath(myRswhHolesMarkCRight, new makerjs.paths.Line([0,0], [0, y0+markShiftTwo]));//从孔圆心出发Y方向竖向标线
/*****************************************************************************/ 
makerjs.model.addPath(myRswhHolesMarkCRight, new makerjs.paths.Line([0,0], [d, -d]));//直径标线
makerjs.model.addCaption(myRswhHolesMarkCRight, TxtD, [0,0], [d+markHolesOneD, -d-markHolesOneD]);//直径文字标注
/*****************************************************************************/   
makerjs.model.addPath(myRswhHolesXMarkCRight, new makerjs.paths.Line([0,y0+markShift], [x0, y0+markShift]));//X短标线
makerjs.model.addCaption(myRswhHolesXMarkCRight, TxtX, [0, y0+markShiftTwo], [x0,y0+markShiftTwo]);//X文字标注
/*****************************************************************************/     
makerjs.model.addPath(myRswhHolesYMarkCRight, new makerjs.paths.Line([x0+markShift,0], [x0+markShift, y0]));//Y短标线
makerjs.model.addCaption(myRswhHolesYMarkCRight, TxtY, [x0+markShiftFour,0], [x0+markShiftFour, y0]);//Y文字标注
/*****************************************************************************/ 
 //画孔 C位置孔
      //标记相对吊轮孔 C位置孔中
      var myRswhHolesCMid= new makerjs.models.Holes(d/2, [[xc-w, yc]]);
      var myRswhHolesMarkCMid = new makerjs.models.Square(0); //孔的原点       
      myRswhHolesMarkCMid.origin = [xc-w, yc];      
      var myRswhHolesXMarkCMid = new makerjs.models.Square(0);      
      myRswhHolesXMarkCMid.origin =[xc-w, yc];   
/*****************************************************************************/ 
makerjs.model.addPath(myRswhHolesMarkCMid, new makerjs.paths.Line([0,0], [-w, 0]));//从孔圆心出发X方向横向标线
makerjs.model.addPath(myRswhHolesMarkCMid, new makerjs.paths.Line([0,0], [0, y0+markShiftTwo]));//从孔圆心出发Y方向竖向标线
/*****************************************************************************/ 
makerjs.model.addPath(myRswhHolesMarkCMid, new makerjs.paths.Line([0,0], [d, -d]));//直径标线
makerjs.model.addCaption(myRswhHolesMarkCMid, TxtD, [0,0], [d+markHolesOneD, -d-markHolesOneD]);//直径文字标注
/*****************************************************************************/   
makerjs.model.addPath(myRswhHolesXMarkCMid, new makerjs.paths.Line([0,y0+markShift], [w, y0+markShift]));//X短标线
makerjs.model.addCaption(myRswhHolesXMarkCMid, TxtW, [0, y0+markShiftTwo], [w,y0+markShiftTwo]);//X文字标注
/*****************************************************************************/     
/*****************************************************************************/ 
/*****************************************************************************/ 
 //画孔 C位置孔
      //标记双固定孔 C位置孔左
      var myRswhHolesCLeft= new makerjs.models.Holes(d/2, [[xc-w*2, yc]]);
      //标记孔
      var myRswhHolesMarkCLeft = new makerjs.models.Square(0); //孔的原点       
      myRswhHolesMarkCLeft.origin = [xc-w*2, yc];      
      var myRswhHolesXMarkCLeft = new makerjs.models.Square(0);      
      myRswhHolesXMarkCLeft.origin =[xc-w*2, yc]; 
/*****************************************************************************/ 
makerjs.model.addPath(myRswhHolesMarkCLeft, new makerjs.paths.Line([0,0], [0, y0+markShiftTwo]));//从孔圆心出发Y方向竖向标线
/*****************************************************************************/ 
makerjs.model.addPath(myRswhHolesMarkCLeft, new makerjs.paths.Line([0,0], [d, -d]));//直径标线
makerjs.model.addCaption(myRswhHolesMarkCLeft, TxtD, [0,0], [d+markHolesOneD, -d-markHolesOneD]);//直径文字标注
/*****************************************************************************/   
makerjs.model.addPath(myRswhHolesXMarkCLeft, new makerjs.paths.Line([0,y0+markShift], [w, y0+markShift]));//X短标线
makerjs.model.addCaption(myRswhHolesXMarkCLeft, TxtW, [0, y0+markShiftTwo], [w,y0+markShiftTwo]);//X文字标注
/*****************************************************************************/ 
//*****************************A位置 */
plate.models["myRswhHolesALeft"+myHolesNum]=myRswhHolesALeft;
markers.models["myRswhHolesALeft"+myHolesNum]=myRswhHolesALeft;
markers.models["myRswhHolesMarkALeft"+myHolesNum]=myRswhHolesMarkALeft;
markers.models["myRswhHolesXMarkALeft"+myHolesNum]=myRswhHolesXMarkALeft;
markers.models["myRswhHolesYMarkALeft"+myHolesNum]=myRswhHolesYMarkALeft;
/*****************************************************************************/
plate.models["myRswhHolesAMid"+myHolesNum]=myRswhHolesAMid;
markers.models["myRswhHolesAMid"+myHolesNum]=myRswhHolesAMid;
markers.models["myRswhHolesMarkAMid"+myHolesNum]=myRswhHolesMarkAMid;
markers.models["myRswhHolesXMarkAMid"+myHolesNum]=myRswhHolesXMarkAMid;
/*****************************************************************************/ 
plate.models["myRswhHolesARight"+myHolesNum]=myRswhHolesARight;
markers.models["myRswhHolesARight"+myHolesNum]=myRswhHolesARight;
markers.models["myRswhHolesMarkARight"+myHolesNum]=myRswhHolesMarkARight;
markers.models["myRswhHolesXMarkARight"+myHolesNum]=myRswhHolesXMarkARight;
/*****************************************************************************/
//*****************************C位置 */
plate.models["myRswhHolesCRight"+myHolesNum]=myRswhHolesCRight;
markers.models["myRswhHolesCRight"+myHolesNum]=myRswhHolesCRight;
markers.models["myRswhHolesMarkCRight"+myHolesNum]=myRswhHolesMarkCRight;
markers.models["myRswhHolesXMarkCRight"+myHolesNum]=myRswhHolesXMarkCRight;
markers.models["myRswhHolesYMarkCRight"+myHolesNum]=myRswhHolesYMarkCRight;
/*****************************************************************************/
plate.models["myRswhHolesCMid"+myHolesNum]=myRswhHolesCMid;
markers.models["myRswhHolesCMid"+myHolesNum]=myRswhHolesCMid;
markers.models["myRswhHolesMarkCMid"+myHolesNum]=myRswhHolesMarkCMid;
markers.models["myRswhHolesXMarkCMid"+myHolesNum]=myRswhHolesXMarkCMid;
/*****************************************************************************/ 
plate.models["myRswhHolesCLeft"+myHolesNum]=myRswhHolesCLeft;
markers.models["myRswhHolesCLeft"+myHolesNum]=myRswhHolesCLeft;
markers.models["myRswhHolesMarkCLeft"+myHolesNum]=myRswhHolesMarkCLeft;
markers.models["myRswhHolesXMarkCLeft"+myHolesNum]=myRswhHolesXMarkCLeft;
/*****************************************************************************/ 

myHolesNum++;
return{plate, markers,myHolesNum}
};


export default RelSuWheelHole;