//7、拉手孔T字
import makerjs from "makerjs";
// inputParam,model,this.myHolesNum,outParam,fixedParam
// (D,X,Y,area,plate,markers,myHolesNum,glassW,glassH,markShift,markShiftTwo,markShiftThree,markShiftFour,markHolesOneD)
function HandleHoleTShaped(inputParam,model,myHolesNum,outParam,fixedParam,zoom){
var D=inputParam.D,W=inputParam.W,H1=inputParam.H1,H2=inputParam.H2,X=inputParam.X,Y=inputParam.Y,area=inputParam.area,myZoom=zoom*1,
plate=model.plate,markers=model.markers,
glassW=outParam.glassW,glassH=outParam.glassH,
   markShift=fixedParam.markShift,markShiftTwo=fixedParam.markShiftTwo,
   markShiftThree=fixedParam.markShiftThree,markShiftFour=fixedParam.markShiftFour,markHolesOneD=fixedParam.markHolesOneD,
   d = D*1*myZoom,
   w= W*1*myZoom,
   h1= H1*1*myZoom,
   h2= H2*1*myZoom,
   x0 = X*1*myZoom,
   y0 = Y*1*myZoom;
   /*
   d = D*1,
      w= W*1,
      h1= H1*1,
      h2= H2*1,
        x0 = X*1,
        y0 = Y*1;
        */
        if(d!==0){
         var TxtD="∮"+d;
        }
        if(w!==0){
         var TxtW=W;
        }
        if(h1!==0){
         var TxtH1=H1;
        }
        if(h2!==0){
         var TxtH2=H2;
        }
        if(x0!==0){          
        var TxtX=X;
        }
        if(y0!==0){          
         var TxtY=Y;
        }
  //在A区域 计算孔的位置       
  var xa=x0,ya=glassH * 1-y0,
  //B区域//只有Y标注没有X标注 
  xb=glassW * 1/2,yb=glassH * 1-y0,
  //C区域
  xc=glassW * 1-x0,yc=glassH * 1-y0,
  //D区域
  //只有X标注没有Y标注 
  xd=x0,yd=glassH * 1/2,
  //E区域
  //只有X标注没有Y标注 
  xe=glassW * 1/2,ye=glassH * 1/2,
  //F区域
  //只有X标注没有Y标注 
  xf=glassW * 1-x0,yf=glassH * 1/2,
  //在G区域 计算孔的位置
  xg=x0,yg=y0,
  //在H区域 计算孔的位置
  //只标注Y
  xh=glassW*1/2,yh=y0,
  //在I区域 计算孔的位置
  xi=glassW*1-x0,yi=y0;
  if(area==1){
    //画孔 A位置孔
          //标拉手孔T字 A位置孔左上
          var myHhtsHolesALeftup= new makerjs.models.Holes(d/2, [[xa, ya]]);
          var myHhtsHolesMarkALeftup = new makerjs.models.Square(0); //孔的原点       
          myHhtsHolesMarkALeftup.origin = [xa, ya];      
          var myHhtsHolesXMarkALeftup = new makerjs.models.Square(0);      
          myHhtsHolesXMarkALeftup.origin =[xa, ya];      
          var myHhtsHolesYMarkALeftup = new makerjs.models.Square(0);      
          myHhtsHolesYMarkALeftup.origin = [xa, ya];  
    
    /*****************************************************************************/ 
    makerjs.model.addPath(myHhtsHolesMarkALeftup, new makerjs.paths.Line([-x0,y0], [-x0, y0+markShiftTwo]));//Y轴加长标线
    makerjs.model.addPath(myHhtsHolesMarkALeftup, new makerjs.paths.Line([0,0], [-x0-markShiftThree, 0]));//从孔圆心出发X方向横向标线
    makerjs.model.addPath(myHhtsHolesMarkALeftup, new makerjs.paths.Line([-x0,y0], [-x0-markShiftThree, y0]));//X轴加长标线
    makerjs.model.addPath(myHhtsHolesMarkALeftup, new makerjs.paths.Line([0,0], [0, y0+markShiftTwo]));//从孔圆心出发Y方向竖向标线
    /*****************************************************************************/ 
    makerjs.model.addPath(myHhtsHolesMarkALeftup, new makerjs.paths.Line([0,0], [d, -d]));//直径标线
    makerjs.model.addCaption(myHhtsHolesMarkALeftup, TxtD, [0,0], [d+markHolesOneD, -d-markHolesOneD]);//直径文字标注
    /*****************************************************************************/   
    makerjs.model.addPath(myHhtsHolesXMarkALeftup, new makerjs.paths.Line([0,y0+markShift], [-x0, y0+markShift]));//X短标线
    makerjs.model.addCaption(myHhtsHolesXMarkALeftup, TxtX, [-x0,y0+markShiftTwo], [0, y0+markShiftTwo]);//X文字标注
    /*****************************************************************************/     
    makerjs.model.addPath(myHhtsHolesYMarkALeftup, new makerjs.paths.Line([-x0-markShiftTwo,0], [-x0-markShiftTwo, y0]));//Y短标线
    makerjs.model.addCaption(myHhtsHolesYMarkALeftup, TxtY, [-x0-markShiftThree,0], [-x0-markShiftThree, y0]);//Y文字标注
    /*****************************************************************************/ 
    
     //标记拉手孔T字 A位置孔左中
     var myHhtsHolesALeftmd= new makerjs.models.Holes(d/2, [[xa, ya-h1]]);
          var myHhtsHolesMarkALeftmd = new makerjs.models.Square(0); //孔的原点       
          myHhtsHolesMarkALeftmd.origin = [xa, ya-h1];     
          var myHhtsHolesYMarkALeftmd = new makerjs.models.Square(0);      
          myHhtsHolesYMarkALeftmd.origin = [xa, ya-h1];  
    
    /*****************************************************************************/ 
    makerjs.model.addPath(myHhtsHolesMarkALeftmd, new makerjs.paths.Line([0,0], [-x0-markShiftThree, 0]));//从孔圆心出发X方向横向标线
    makerjs.model.addPath(myHhtsHolesMarkALeftmd, new makerjs.paths.Line([0,0], [0, h1+markShiftTwo]));//从孔圆心出发Y方向竖向标线
    /*****************************************************************************/ 
    makerjs.model.addPath(myHhtsHolesMarkALeftmd, new makerjs.paths.Line([0,0], [d, -d]));//直径标线
    makerjs.model.addCaption(myHhtsHolesMarkALeftmd, TxtD, [0,0], [d+markHolesOneD, -d-markHolesOneD]);//直径文字标注
    /*****************************************************************************/     
    makerjs.model.addPath(myHhtsHolesYMarkALeftmd, new makerjs.paths.Line([-x0-markShiftTwo,0], [-x0-markShiftTwo, h1]));//Y短标线
    makerjs.model.addCaption(myHhtsHolesYMarkALeftmd, TxtH1, [-x0-markShiftThree,0], [-x0-markShiftThree, h1]);//Y文字标注
    /*****************************************************************************/ 
    
     //标记拉手孔T字 A位置孔右中
     var myHhtsHolesARightmd= new makerjs.models.Holes(d/2, [[xa+w, ya-h1]]);
          //标记孔
          var myHhtsHolesMarkARightmd = new makerjs.models.Square(0); //孔的原点       
          myHhtsHolesMarkARightmd.origin = [xa+w, ya-h1];      
          var myHhtsHolesXMarkARightmd = new makerjs.models.Square(0);      
          myHhtsHolesXMarkARightmd.origin =[xa+w, ya-h1];      
          var myHhtsHolesYMarkARightmd = new makerjs.models.Square(0);      
          myHhtsHolesYMarkARightmd.origin = [xa+w, ya-h1];  
    
    /*****************************************************************************/ 
    makerjs.model.addPath(myHhtsHolesMarkARightmd, new makerjs.paths.Line([0,0], [-w-markShiftThree, 0]));//从孔圆心出发X方向横向标线
    makerjs.model.addPath(myHhtsHolesMarkARightmd, new makerjs.paths.Line([0,0], [0, h1+y0+markShiftTwo]));//从孔圆心出发Y方向竖向标线          
    /*****************************************************************************/ 
    makerjs.model.addPath(myHhtsHolesMarkARightmd, new makerjs.paths.Line([0,0], [d, -d]));//直径标线
    makerjs.model.addCaption(myHhtsHolesMarkARightmd, TxtD, [0,0], [d+markHolesOneD, -d-markHolesOneD]);//直径文字标注
    /*****************************************************************************/   
    makerjs.model.addPath(myHhtsHolesXMarkARightmd, new makerjs.paths.Line([0,y0+h1+markShift], [-w, y0+h1+markShift]));//X短标线
    makerjs.model.addCaption(myHhtsHolesXMarkARightmd, TxtW, [-w,y0+h1+markShiftTwo],[0, y0+h1+markShiftTwo]);//X文字标注
    /*****************************************************************************/ 
    /*****************************************************************************/ 
    
     //标记拉手孔T字 A位置孔左下
     var myHhtsHolesALeftdn= new makerjs.models.Holes(d/2, [[xa, ya-h1-h2]]);
          //标记孔
          var myHhtsHolesMarkALeftdn = new makerjs.models.Square(0); //孔的原点       
          myHhtsHolesMarkALeftdn.origin = [xa, ya-h1-h2];   
          var myHhtsHolesYMarkALeftdn = new makerjs.models.Square(0);      
          myHhtsHolesYMarkALeftdn.origin = [xa, ya-h1-h2];  
    
    /*****************************************************************************/ 
    makerjs.model.addPath(myHhtsHolesMarkALeftdn, new makerjs.paths.Line([0,0], [-x0-markShiftThree, 0]));//从孔圆心出发X方向横向标线
    makerjs.model.addPath(myHhtsHolesMarkALeftdn, new makerjs.paths.Line([0,0], [0, h2+markShiftTwo]));//从孔圆心出发Y方向竖向标线
    /*****************************************************************************/ 
    makerjs.model.addPath(myHhtsHolesMarkALeftdn, new makerjs.paths.Line([0,0], [d, -d]));//直径标线
    makerjs.model.addCaption(myHhtsHolesMarkALeftdn, TxtD, [0,0], [d+markHolesOneD, -d-markHolesOneD]);//直径文字标注
    /*****************************************************************************/    
    makerjs.model.addPath(myHhtsHolesYMarkALeftdn, new makerjs.paths.Line([-x0-markShiftTwo,0], [-x0-markShiftTwo, h2]));//Y短标线
    makerjs.model.addCaption(myHhtsHolesYMarkALeftdn, TxtH2, [-x0-markShiftThree,0], [-x0-markShiftThree, h2]);//Y文字标注
    /*****************************************************************************/ 
    plate.models["myHhtsHolesALeftup"+myHolesNum]=myHhtsHolesALeftup;
    markers.models["myHhtsHolesALeftup"+myHolesNum]=myHhtsHolesALeftup;
    markers.models["myHhtsHolesMarkALeftup"+myHolesNum]=myHhtsHolesMarkALeftup;
    markers.models["myHhtsHolesXMarkALeftup"+myHolesNum]=myHhtsHolesXMarkALeftup;
    markers.models["myHhtsHolesYMarkALeftup"+myHolesNum]=myHhtsHolesYMarkALeftup;
    /*****************************************************************************/
    plate.models["myHhtsHolesALeftmd"+myHolesNum]=myHhtsHolesALeftmd;
    markers.models["myHhtsHolesALeftmd"+myHolesNum]=myHhtsHolesALeftmd;
    markers.models["myHhtsHolesMarkALeftmd"+myHolesNum]=myHhtsHolesMarkALeftmd;
    markers.models["myHhtsHolesYMarkALeftmd"+myHolesNum]=myHhtsHolesYMarkALeftmd;
    /*****************************************************************************/ 
    plate.models["myHhtsHolesALeftdn"+myHolesNum]=myHhtsHolesALeftdn;
    markers.models["myHhtsHolesALeftdn"+myHolesNum]=myHhtsHolesALeftdn;
    markers.models["myHhtsHolesMarkALeftdn"+myHolesNum]=myHhtsHolesMarkALeftdn;
    markers.models["myHhtsHolesYMarkALeftdn"+myHolesNum]=myHhtsHolesYMarkALeftdn;
    /*****************************************************************************/ 
    /*****************************************************************************/ 
    plate.models["myHhtsHolesARightmd"+myHolesNum]=myHhtsHolesARightmd;
    markers.models["myHhtsHolesARightmd"+myHolesNum]=myHhtsHolesARightmd;
    markers.models["myHhtsHolesMarkARightmd"+myHolesNum]=myHhtsHolesMarkARightmd;
    markers.models["myHhtsHolesXMarkARightmd"+myHolesNum]=myHhtsHolesXMarkARightmd;
    /*****************************************************************************/  
    
    }else if(area==3){
    //画孔 C位置孔
          //标拉手孔T字 C位置孔右上
          var myHhtsHolesCRightup= new makerjs.models.Holes(d/2, [[xc, yc]]);
          var myHhtsHolesMarkCRightup = new makerjs.models.Square(0); //孔的原点       
          myHhtsHolesMarkCRightup.origin = [xc, yc];      
          var myHhtsHolesXMarkCRightup = new makerjs.models.Square(0);      
          myHhtsHolesXMarkCRightup.origin =[xc, yc];      
          var myHhtsHolesYMarkCRightup = new makerjs.models.Square(0);      
          myHhtsHolesYMarkCRightup.origin = [xc, yc];  
    
    /*****************************************************************************/ 
    makerjs.model.addPath(myHhtsHolesMarkCRightup, new makerjs.paths.Line([x0,y0], [x0, y0+markShiftTwo]));//Y轴加长标线
    makerjs.model.addPath(myHhtsHolesMarkCRightup, new makerjs.paths.Line([0,0], [x0+markShiftThree, 0]));//从孔圆心出发X方向横向标线
    makerjs.model.addPath(myHhtsHolesMarkCRightup, new makerjs.paths.Line([x0,y0], [x0+markShiftThree, y0]));//X轴加长标线
    makerjs.model.addPath(myHhtsHolesMarkCRightup, new makerjs.paths.Line([0,0], [0, y0+markShiftTwo]));//从孔圆心出发Y方向竖向标线
    /*****************************************************************************/ 
    makerjs.model.addPath(myHhtsHolesMarkCRightup, new makerjs.paths.Line([0,0], [d, -d]));//直径标线
    makerjs.model.addCaption(myHhtsHolesMarkCRightup, TxtD, [0,0], [d+markHolesOneD, -d-markHolesOneD]);//直径文字标注
    /*****************************************************************************/   
    makerjs.model.addPath(myHhtsHolesXMarkCRightup, new makerjs.paths.Line([0,y0+markShift], [x0, y0+markShift]));//X短标线
    makerjs.model.addCaption(myHhtsHolesXMarkCRightup, TxtX, [0, y0+markShiftFour], [x0,y0+markShiftFour]);//X文字标注
    /*****************************************************************************/     
    makerjs.model.addPath(myHhtsHolesYMarkCRightup, new makerjs.paths.Line([x0+markShiftTwo,0], [x0+markShiftTwo, y0]));//Y短标线
    makerjs.model.addCaption(myHhtsHolesYMarkCRightup, TxtY, [x0+markShiftFour,0], [x0+markShiftFour, y0]);//Y文字标注
    /*****************************************************************************/ 
    
     //标记拉手孔T字 C位置孔右中
     var myHhtsHolesCRightmd= new makerjs.models.Holes(d/2, [[xc, yc-h1]]);
          var myHhtsHolesMarkCRightmd = new makerjs.models.Square(0); //孔的原点       
          myHhtsHolesMarkCRightmd.origin = [xc, yc-h1];     
          var myHhtsHolesYMarkCRightmd = new makerjs.models.Square(0);      
          myHhtsHolesYMarkCRightmd.origin = [xc, yc-h1];  
    
    /*****************************************************************************/ 
    makerjs.model.addPath(myHhtsHolesMarkCRightmd, new makerjs.paths.Line([0,0], [x0+markShiftThree, 0]));//从孔圆心出发X方向横向标线
    makerjs.model.addPath(myHhtsHolesMarkCRightmd, new makerjs.paths.Line([0,0], [0, h1+markShiftTwo]));//从孔圆心出发Y方向竖向标线
    /*****************************************************************************/ 
    makerjs.model.addPath(myHhtsHolesMarkCRightmd, new makerjs.paths.Line([0,0], [d, -d]));//直径标线
    makerjs.model.addCaption(myHhtsHolesMarkCRightmd, TxtD, [0,0], [d+markHolesOneD, -d-markHolesOneD]);//直径文字标注
    /*****************************************************************************/     
    makerjs.model.addPath(myHhtsHolesYMarkCRightmd, new makerjs.paths.Line([x0+markShiftTwo,0], [x0+markShiftTwo, h1]));//Y短标线
    makerjs.model.addCaption(myHhtsHolesYMarkCRightmd, TxtH1, [x0+markShiftFour,0], [x0+markShiftFour, h1]);//Y文字标注
    /*****************************************************************************/ 
    
     //标记拉手孔T字 C位置孔左中
     var myHhtsHolesCLeftmd= new makerjs.models.Holes(d/2, [[xc-w, yc-h1]]);
          //标记孔
          var myHhtsHolesMarkCLeftmd = new makerjs.models.Square(0); //孔的原点       
          myHhtsHolesMarkCLeftmd.origin = [xc-w, yc-h1];      
          var myHhtsHolesXMarkCLeftmd = new makerjs.models.Square(0);      
          myHhtsHolesXMarkCLeftmd.origin =[xc-w, yc-h1];     
    
    /*****************************************************************************/ 
    makerjs.model.addPath(myHhtsHolesMarkCLeftmd, new makerjs.paths.Line([0,0], [w, 0]));//从孔圆心出发X方向横向标线
    makerjs.model.addPath(myHhtsHolesMarkCLeftmd, new makerjs.paths.Line([0,0], [0, h1+y0+markShiftTwo]));//从孔圆心出发Y方向竖向标线          
    /*****************************************************************************/ 
    makerjs.model.addPath(myHhtsHolesMarkCLeftmd, new makerjs.paths.Line([0,0], [d, -d]));//直径标线
    makerjs.model.addCaption(myHhtsHolesMarkCLeftmd, TxtD, [0,0], [d+markHolesOneD, -d-markHolesOneD]);//直径文字标注
    /*****************************************************************************/   
    makerjs.model.addPath(myHhtsHolesXMarkCLeftmd, new makerjs.paths.Line([0,y0+h1+markShift], [w, y0+h1+markShift]));//X短标线
    makerjs.model.addCaption(myHhtsHolesXMarkCLeftmd, TxtW,[0, y0+h1+markShiftFour], [w,y0+h1+markShiftFour]);//X文字标注
    /*****************************************************************************/ 
    /*****************************************************************************/ 
    
     //标记拉手孔T字 C位置孔右下
     var myHhtsHolesCRightdn= new makerjs.models.Holes(d/2, [[xc, yc-h1-h2]]);
          //标记孔
          var myHhtsHolesMarkCRightdn = new makerjs.models.Square(0); //孔的原点       
          myHhtsHolesMarkCRightdn.origin = [xc, yc-h1-h2];   
          var myHhtsHolesYMarkCRightdn = new makerjs.models.Square(0);      
          myHhtsHolesYMarkCRightdn.origin = [xc, yc-h1-h2];  
    
    /*****************************************************************************/ 
    makerjs.model.addPath(myHhtsHolesMarkCRightdn, new makerjs.paths.Line([0,0], [x0+markShiftThree, 0]));//从孔圆心出发X方向横向标线
    makerjs.model.addPath(myHhtsHolesMarkCRightdn, new makerjs.paths.Line([0,0], [0, h2+markShiftTwo]));//从孔圆心出发Y方向竖向标线
    /*****************************************************************************/ 
    makerjs.model.addPath(myHhtsHolesMarkCRightdn, new makerjs.paths.Line([0,0], [d, -d]));//直径标线
    makerjs.model.addCaption(myHhtsHolesMarkCRightdn, TxtD, [0,0], [d+markHolesOneD, -d-markHolesOneD]);//直径文字标注
    /*****************************************************************************/    
    makerjs.model.addPath(myHhtsHolesYMarkCRightdn, new makerjs.paths.Line([x0+markShiftTwo,0], [x0+markShiftTwo, h2]));//Y短标线
    makerjs.model.addCaption(myHhtsHolesYMarkCRightdn, TxtH2, [x0+markShiftFour,0], [x0+markShiftFour, h2]);//Y文字标注
    /*****************************************************************************/ 
    plate.models["myHhtsHolesCRightup"+myHolesNum]=myHhtsHolesCRightup;
    markers.models["myHhtsHolesCRightup"+myHolesNum]=myHhtsHolesCRightup;
    markers.models["myHhtsHolesMarkCRightup"+myHolesNum]=myHhtsHolesMarkCRightup;
    markers.models["myHhtsHolesXMarkCRightup"+myHolesNum]=myHhtsHolesXMarkCRightup;
    markers.models["myHhtsHolesYMarkCRightup"+myHolesNum]=myHhtsHolesYMarkCRightup;
    /*****************************************************************************/
    plate.models["myHhtsHolesCRightmd"+myHolesNum]=myHhtsHolesCRightmd;
    markers.models["myHhtsHolesCRightmd"+myHolesNum]=myHhtsHolesCRightmd;
    markers.models["myHhtsHolesMarkCRightmd"+myHolesNum]=myHhtsHolesMarkCRightmd;
    markers.models["myHhtsHolesYMarkCRightmd"+myHolesNum]=myHhtsHolesYMarkCRightmd;
    /*****************************************************************************/ 
    plate.models["myHhtsHolesCRightdn"+myHolesNum]=myHhtsHolesCRightdn;
    markers.models["myHhtsHolesCRightdn"+myHolesNum]=myHhtsHolesCRightdn;
    markers.models["myHhtsHolesMarkCRightdn"+myHolesNum]=myHhtsHolesMarkCRightdn;
    markers.models["myHhtsHolesYMarkCRightdn"+myHolesNum]=myHhtsHolesYMarkCRightdn;
    /*****************************************************************************/ 
    /*****************************************************************************/ 
    plate.models["myHhtsHolesCLeftmd"+myHolesNum]=myHhtsHolesCLeftmd;
    markers.models["myHhtsHolesCLeftmd"+myHolesNum]=myHhtsHolesCLeftmd;
    markers.models["myHhtsHolesMarkCLeftmd"+myHolesNum]=myHhtsHolesMarkCLeftmd;
    markers.models["myHhtsHolesXMarkCLeftmd"+myHolesNum]=myHhtsHolesXMarkCLeftmd;
    /*****************************************************************************/  
   
    }else if(area==4){
    //画孔 D位置孔
          //标拉手孔T字 D位置孔左上
          var myHhtsHolesDLeftup= new makerjs.models.Holes(d/2, [[xd, yd+h1]]);
          var myHhtsHolesMarkDLeftup = new makerjs.models.Square(0); //孔的原点       
          myHhtsHolesMarkDLeftup.origin = [xd, yd+h1];      
          var myHhtsHolesXMarkDLeftup = new makerjs.models.Square(0);      
          myHhtsHolesXMarkDLeftup.origin =[xd, yd+h1];      
          var myHhtsHolesYMarkDLeftup = new makerjs.models.Square(0);      
          myHhtsHolesYMarkDLeftup.origin = [xd, yd+h1];  
    
    /*****************************************************************************/ 
    makerjs.model.addPath(myHhtsHolesMarkDLeftup, new makerjs.paths.Line([-x0,ye-h1], [-x0, ye-h1+markShiftTwo]));//Y轴加长标线
    makerjs.model.addPath(myHhtsHolesMarkDLeftup, new makerjs.paths.Line([0,0], [-x0, 0]));//从孔圆心出发X方向横向标线
    makerjs.model.addPath(myHhtsHolesMarkDLeftup, new makerjs.paths.Line([0,0], [w+d+markShiftFour, 0]));//从孔圆心出发X方向横向标线
    makerjs.model.addPath(myHhtsHolesMarkDLeftup, new makerjs.paths.Line([0,0], [0, ye-h1+markShiftTwo]));//从孔圆心出发Y方向竖向标线
    /*****************************************************************************/ 
    makerjs.model.addPath(myHhtsHolesMarkDLeftup, new makerjs.paths.Line([0,0], [d, -d]));//直径标线
    makerjs.model.addCaption(myHhtsHolesMarkDLeftup, TxtD, [0,0], [d+markHolesOneD, -d-markHolesOneD]);//直径文字标注
    /*****************************************************************************/   
    makerjs.model.addPath(myHhtsHolesXMarkDLeftup, new makerjs.paths.Line([0,ye-h1+markShift], [-x0, ye-h1+markShift]));//X短标线
    makerjs.model.addCaption(myHhtsHolesXMarkDLeftup, TxtX, [-x0,ye-h1+markShiftTwo], [0, ye-h1+markShiftTwo]);//X文字标注
    /*****************************************************************************/   
    /*****************************************************************************/ 
    
     //标记拉手孔T字 D位置孔左中
     var myHhtsHolesDLeftmd= new makerjs.models.Holes(d/2, [[xd, yd]]);
          var myHhtsHolesMarkDLeftmd = new makerjs.models.Square(0); //孔的原点       
          myHhtsHolesMarkDLeftmd.origin = [xd, yd];     
          var myHhtsHolesYMarkDLeftmd = new makerjs.models.Square(0);      
          myHhtsHolesYMarkDLeftmd.origin = [xd, yd];  
    
    /*****************************************************************************/ 
    makerjs.model.addPath(myHhtsHolesMarkDLeftmd, new makerjs.paths.Line([0,0], [-x0, 0]));//从孔圆心出发X方向横向标线 
    makerjs.model.addPath(myHhtsHolesMarkDLeftmd, new makerjs.paths.Line([0,0], [0, h1+markShiftTwo]));//从孔圆心出发Y方向竖向标线
    /*****************************************************************************/ 
    makerjs.model.addPath(myHhtsHolesMarkDLeftmd, new makerjs.paths.Line([0,0], [d, -d]));//直径标线
    makerjs.model.addCaption(myHhtsHolesMarkDLeftmd, TxtD, [0,0], [d+markHolesOneD, -d-markHolesOneD]);//直径文字标注
    /*****************************************************************************/     
    makerjs.model.addPath(myHhtsHolesYMarkDLeftmd, new makerjs.paths.Line([w+d+markShiftTwo,0], [w+d+markShiftTwo, h1]));//Y短标线
    makerjs.model.addCaption(myHhtsHolesYMarkDLeftmd, TxtH1, [w+d+markShiftFour,0], [w+d+markShiftFour, h1]);//Y文字标注
    /*****************************************************************************/ 
    
     //标记拉手孔T字 D位置孔右中
     var myHhtsHolesDRightmd= new makerjs.models.Holes(d/2, [[xd+w, yd]]);
          //标记孔
          var myHhtsHolesMarkDRightmd = new makerjs.models.Square(0); //孔的原点       
          myHhtsHolesMarkDRightmd.origin = [xd+w, yd];      
          var myHhtsHolesXMarkDRightmd = new makerjs.models.Square(0);      
          myHhtsHolesXMarkDRightmd.origin =[xd+w, yd];      
          var myHhtsHolesYMarkDRightmd = new makerjs.models.Square(0);      
          myHhtsHolesYMarkDRightmd.origin = [xd+w, yd];  
    
    /*****************************************************************************/ 
    makerjs.model.addPath(myHhtsHolesMarkDRightmd, new makerjs.paths.Line([0,0], [-w, 0]));//从孔圆心出发X方向横向标线
    makerjs.model.addPath(myHhtsHolesMarkDRightmd, new makerjs.paths.Line([0,0], [d+markShiftFour, 0]));//从孔圆心出发X方向横向标线
    makerjs.model.addPath(myHhtsHolesMarkDRightmd, new makerjs.paths.Line([0,0], [0, ye+markShiftTwo]));//从孔圆心出发Y方向竖向标线          
    /*****************************************************************************/ 
    makerjs.model.addPath(myHhtsHolesMarkDRightmd, new makerjs.paths.Line([0,0], [d, -d]));//直径标线
    makerjs.model.addCaption(myHhtsHolesMarkDRightmd, TxtD, [0,0], [d+markHolesOneD, -d-markHolesOneD]);//直径文字标注
    /*****************************************************************************/   
    makerjs.model.addPath(myHhtsHolesXMarkDRightmd, new makerjs.paths.Line([0,ye+markShift], [-w, ye+markShift]));//X短标线
    makerjs.model.addCaption(myHhtsHolesXMarkDRightmd, TxtW, [-w,ye+markShiftTwo],[0, ye+markShiftTwo]);//X文字标注
    /*****************************************************************************/ 
    /*****************************************************************************/ 
    
     //标记拉手孔T字 D位置孔左下
     var myHhtsHolesDLeftdn= new makerjs.models.Holes(d/2, [[xd, yd-h2]]);
          //标记孔
          var myHhtsHolesMarkDLeftdn = new makerjs.models.Square(0); //孔的原点       
          myHhtsHolesMarkDLeftdn.origin = [xd, yd-h2];   
          var myHhtsHolesYMarkDLeftdn = new makerjs.models.Square(0);      
          myHhtsHolesYMarkDLeftdn.origin = [xd, yd-h2];  
    
    /*****************************************************************************/ 
    makerjs.model.addPath(myHhtsHolesMarkDLeftdn, new makerjs.paths.Line([0,0], [w+d+markShiftFour, 0]));//从孔圆心出发X方向横向标线
    makerjs.model.addPath(myHhtsHolesMarkDLeftdn, new makerjs.paths.Line([0,0], [-x0, 0]));//从孔圆心出发X方向横向标线
    makerjs.model.addPath(myHhtsHolesMarkDLeftdn, new makerjs.paths.Line([0,0], [0, h2]));//从孔圆心出发Y方向竖向标线
    /*****************************************************************************/ 
    makerjs.model.addPath(myHhtsHolesMarkDLeftdn, new makerjs.paths.Line([0,0], [d, -d]));//直径标线
    makerjs.model.addCaption(myHhtsHolesMarkDLeftdn, TxtD, [0,0], [d+markHolesOneD, -d-markHolesOneD]);//直径文字标注
    /*****************************************************************************/    
    makerjs.model.addPath(myHhtsHolesYMarkDLeftdn, new makerjs.paths.Line([w+d+markShiftTwo,0], [w+d+markShiftTwo, h2]));//H2短标线
    makerjs.model.addCaption(myHhtsHolesYMarkDLeftdn, TxtH2, [w+d+markShiftFour,0], [w+d+markShiftFour, h2]);//H2文字标注
    /*****************************************************************************/ 
    plate.models["myHhtsHolesDLeftup"+myHolesNum]=myHhtsHolesDLeftup;
    markers.models["myHhtsHolesDLeftup"+myHolesNum]=myHhtsHolesDLeftup;
    markers.models["myHhtsHolesMarkDLeftup"+myHolesNum]=myHhtsHolesMarkDLeftup;
    markers.models["myHhtsHolesXMarkDLeftup"+myHolesNum]=myHhtsHolesXMarkDLeftup;
    markers.models["myHhtsHolesYMarkDLeftup"+myHolesNum]=myHhtsHolesYMarkDLeftup;
    /*****************************************************************************/
    plate.models["myHhtsHolesDLeftmd"+myHolesNum]=myHhtsHolesDLeftmd;
    markers.models["myHhtsHolesDLeftmd"+myHolesNum]=myHhtsHolesDLeftmd;
    markers.models["myHhtsHolesMarkDLeftmd"+myHolesNum]=myHhtsHolesMarkDLeftmd;
    markers.models["myHhtsHolesYMarkDLeftmd"+myHolesNum]=myHhtsHolesYMarkDLeftmd;
    /*****************************************************************************/ 
    plate.models["myHhtsHolesDLeftdn"+myHolesNum]=myHhtsHolesDLeftdn;
    markers.models["myHhtsHolesDLeftdn"+myHolesNum]=myHhtsHolesDLeftdn;
    markers.models["myHhtsHolesMarkDLeftdn"+myHolesNum]=myHhtsHolesMarkDLeftdn;
    markers.models["myHhtsHolesYMarkDLeftdn"+myHolesNum]=myHhtsHolesYMarkDLeftdn;
    /*****************************************************************************/ 
    /*****************************************************************************/ 
    plate.models["myHhtsHolesDRightmd"+myHolesNum]=myHhtsHolesDRightmd;
    markers.models["myHhtsHolesDRightmd"+myHolesNum]=myHhtsHolesDRightmd;
    markers.models["myHhtsHolesMarkDRightmd"+myHolesNum]=myHhtsHolesMarkDRightmd;
    markers.models["myHhtsHolesXMarkDRightmd"+myHolesNum]=myHhtsHolesXMarkDRightmd;
    /*****************************************************************************/  
    
    }else if(area==6){
    //画孔 F位置孔
          //标拉手孔T字 F位置孔右上
          var myHhtsHolesFRightup= new makerjs.models.Holes(d/2, [[xf, yf+h1]]);
          var myHhtsHolesMarkFRightup = new makerjs.models.Square(0); //孔的原点       
          myHhtsHolesMarkFRightup.origin = [xf, yf+h1];      
          var myHhtsHolesXMarkFRightup = new makerjs.models.Square(0);      
          myHhtsHolesXMarkFRightup.origin =[xf, yf+h1];      
          var myHhtsHolesYMarkFRightup = new makerjs.models.Square(0);      
          myHhtsHolesYMarkFRightup.origin = [xf, yf+h1];  
    
    /*****************************************************************************/ 
    makerjs.model.addPath(myHhtsHolesMarkFRightup, new makerjs.paths.Line([x0,ye-h1], [x0, ye-h1+markShiftTwo]));//Y轴加长标线
    makerjs.model.addPath(myHhtsHolesMarkFRightup, new makerjs.paths.Line([0,0], [x0, 0]));//从孔圆心出发X方向横向标线
    makerjs.model.addPath(myHhtsHolesMarkFRightup, new makerjs.paths.Line([0,0], [-w-d-markShiftFour, 0]));//从孔圆心出发X方向横向标线
    makerjs.model.addPath(myHhtsHolesMarkFRightup, new makerjs.paths.Line([0,0], [0, ye-h1+markShiftTwo]));//从孔圆心出发Y方向竖向标线
    /*****************************************************************************/ 
    makerjs.model.addPath(myHhtsHolesMarkFRightup, new makerjs.paths.Line([0,0], [d, -d]));//直径标线
    makerjs.model.addCaption(myHhtsHolesMarkFRightup, TxtD, [0,0], [d+markHolesOneD, -d-markHolesOneD]);//直径文字标注
    /*****************************************************************************/   
    makerjs.model.addPath(myHhtsHolesXMarkFRightup, new makerjs.paths.Line([0,ye-h1+markShift], [x0, ye-h1+markShift]));//X短标线
    makerjs.model.addCaption(myHhtsHolesXMarkFRightup, TxtX, [0, ye-h1+markShiftFour], [x0,ye-h1+markShiftFour]);//X文字标注
    /*****************************************************************************/   
    /*****************************************************************************/ 
    
     //标记拉手孔T字 F位置孔右中
     var myHhtsHolesFRightmd= new makerjs.models.Holes(d/2, [[xf, yf]]);
          var myHhtsHolesMarkFRightmd = new makerjs.models.Square(0); //孔的原点       
          myHhtsHolesMarkFRightmd.origin = [xf, yf];     
          var myHhtsHolesYMarkFRightmd = new makerjs.models.Square(0);      
          myHhtsHolesYMarkFRightmd.origin = [xf, yf];  
    
    /*****************************************************************************/ 
    makerjs.model.addPath(myHhtsHolesMarkFRightmd, new makerjs.paths.Line([0,0], [x0, 0]));//从孔圆心出发X方向横向标线 
    makerjs.model.addPath(myHhtsHolesMarkFRightmd, new makerjs.paths.Line([0,0], [0, h1+markShiftTwo]));//从孔圆心出发Y方向竖向标线
    /*****************************************************************************/ 
    makerjs.model.addPath(myHhtsHolesMarkFRightmd, new makerjs.paths.Line([0,0], [d, -d]));//直径标线
    makerjs.model.addCaption(myHhtsHolesMarkFRightmd, TxtD, [0,0], [d+markHolesOneD, -d-markHolesOneD]);//直径文字标注
    /*****************************************************************************/     
    makerjs.model.addPath(myHhtsHolesYMarkFRightmd, new makerjs.paths.Line([-w-d-markShiftTwo,0], [-w-d-markShiftTwo, h1]));//Y短标线
    makerjs.model.addCaption(myHhtsHolesYMarkFRightmd, TxtH1, [-w-d-markShiftFour,0], [-w-d-markShiftFour, h1]);//Y文字标注
    /*****************************************************************************/ 
    
     //标记拉手孔T字 F位置孔左中
     var myHhtsHoleFLeftmd= new makerjs.models.Holes(d/2, [[xf-w, yf]]);
          //标记孔
          var myHhtsHolesMarkFLeftmd = new makerjs.models.Square(0); //孔的原点       
          myHhtsHolesMarkFLeftmd.origin = [xf-w, yf];      
          var myHhtsHolesXMarkFLeftmd = new makerjs.models.Square(0);      
          myHhtsHolesXMarkFLeftmd.origin =[xf-w, yf];      
          var myHhtsHolesYMarkFLeftmd = new makerjs.models.Square(0);      
          myHhtsHolesYMarkFLeftmd.origin = [xf-w, yf];  
    
    /*****************************************************************************/ 
    makerjs.model.addPath(myHhtsHolesMarkFLeftmd, new makerjs.paths.Line([0,0], [w, 0]));//从孔圆心出发X方向横向标线
    makerjs.model.addPath(myHhtsHolesMarkFLeftmd, new makerjs.paths.Line([0,0], [-d-markShiftFour, 0]));//从孔圆心出发X方向横向标线
    makerjs.model.addPath(myHhtsHolesMarkFLeftmd, new makerjs.paths.Line([0,0], [0, ye+markShiftTwo]));//从孔圆心出发Y方向竖向标线          
    /*****************************************************************************/ 
    makerjs.model.addPath(myHhtsHolesMarkFLeftmd, new makerjs.paths.Line([0,0], [d, -d]));//直径标线
    makerjs.model.addCaption(myHhtsHolesMarkFLeftmd, TxtD, [0,0], [d+markHolesOneD, -d-markHolesOneD]);//直径文字标注
    /*****************************************************************************/   
    makerjs.model.addPath(myHhtsHolesXMarkFLeftmd, new makerjs.paths.Line([0,ye+markShift], [w, ye+markShift]));//X短标线
    makerjs.model.addCaption(myHhtsHolesXMarkFLeftmd, TxtW,[0, ye+markShiftFour], [w,ye+markShiftFour]);//X文字标注
    /*****************************************************************************/ 
    /*****************************************************************************/ 
    
     //标记拉手孔T字 F位置孔右下
     var myHhtsHolesFRightdn= new makerjs.models.Holes(d/2, [[xf, yf-h2]]);
          //标记孔
          var myHhtsHolesMarkFRightdn = new makerjs.models.Square(0); //孔的原点       
          myHhtsHolesMarkFRightdn.origin = [xf, yf-h2];   
          var myHhtsHolesYMarkFRightdn = new makerjs.models.Square(0);      
          myHhtsHolesYMarkFRightdn.origin = [xf, yf-h2];  
    
    /*****************************************************************************/ 
    makerjs.model.addPath(myHhtsHolesMarkFRightdn, new makerjs.paths.Line([0,0], [-w-d-markShiftFour, 0]));//从孔圆心出发X方向横向标线
    makerjs.model.addPath(myHhtsHolesMarkFRightdn, new makerjs.paths.Line([0,0], [x0, 0]));//从孔圆心出发X方向横向标线
    makerjs.model.addPath(myHhtsHolesMarkFRightdn, new makerjs.paths.Line([0,0], [0, h2]));//从孔圆心出发Y方向竖向标线
    /*****************************************************************************/ 
    makerjs.model.addPath(myHhtsHolesMarkFRightdn, new makerjs.paths.Line([0,0], [d, -d]));//直径标线
    makerjs.model.addCaption(myHhtsHolesMarkFRightdn, TxtD, [0,0], [d+markHolesOneD, -d-markHolesOneD]);//直径文字标注
    /*****************************************************************************/    
    makerjs.model.addPath(myHhtsHolesYMarkFRightdn, new makerjs.paths.Line([-w-d-markShiftTwo,0], [-w-d-markShiftTwo, h2]));//H2短标线
    makerjs.model.addCaption(myHhtsHolesYMarkFRightdn, TxtH2, [-w-d-markShiftFour,0], [-w-d-markShiftFour, h2]);//H2文字标注
    /*****************************************************************************/ 
    plate.models["myHhtsHolesFRightup"+myHolesNum]=myHhtsHolesFRightup;
    markers.models["myHhtsHolesFRightup"+myHolesNum]=myHhtsHolesFRightup;
    markers.models["myHhtsHolesMarkFRightup"+myHolesNum]=myHhtsHolesMarkFRightup;
    markers.models["myHhtsHolesXMarkFRightup"+myHolesNum]=myHhtsHolesXMarkFRightup;
    markers.models["myHhtsHolesYMarkFRightup"+myHolesNum]=myHhtsHolesYMarkFRightup;
    /*****************************************************************************/
    plate.models["myHhtsHolesFRightmd"+myHolesNum]=myHhtsHolesFRightmd;
    markers.models["myHhtsHolesFRightmd"+myHolesNum]=myHhtsHolesFRightmd;
    markers.models["myHhtsHolesMarkFRightmd"+myHolesNum]=myHhtsHolesMarkFRightmd;
    markers.models["myHhtsHolesYMarkFRightmd"+myHolesNum]=myHhtsHolesYMarkFRightmd;
    /*****************************************************************************/ 
    plate.models["myHhtsHoleFLeftmd"+myHolesNum]=myHhtsHoleFLeftmd;
    markers.models["myHhtsHoleFLeftmd"+myHolesNum]=myHhtsHoleFLeftmd;
    markers.models["myHhtsHolesMarkFLeftmd"+myHolesNum]=myHhtsHolesMarkFLeftmd;
    markers.models["myHhtsHolesXMarkFLeftmd"+myHolesNum]=myHhtsHolesXMarkFLeftmd;
    markers.models["myHhtsHolesYMarkFLeftmd"+myHolesNum]=myHhtsHolesYMarkFLeftmd;
    /*****************************************************************************/ 
    /*****************************************************************************/ 
    plate.models["myHhtsHolesFRightdn"+myHolesNum]=myHhtsHolesFRightdn;
    markers.models["myHhtsHolesFRightdn"+myHolesNum]=myHhtsHolesFRightdn;
    markers.models["myHhtsHolesMarkFRightdn"+myHolesNum]=myHhtsHolesMarkFRightdn;
    markers.models["myHhtsHolesYMarkFRightdn"+myHolesNum]=myHhtsHolesYMarkFRightdn;
    /*****************************************************************************/  

    }else if(area==7){
    //画孔 G位置孔
          //标拉手孔T字 G位置孔左下
          var myHhtsHolesGLeftdn= new makerjs.models.Holes(d/2, [[xg, yg]]);
          var myHhtsHolesMarkGLeftdn = new makerjs.models.Square(0); //孔的原点       
          myHhtsHolesMarkGLeftdn.origin = [xg, yg];      
          var myHhtsHolesXMarkGLeftdn = new makerjs.models.Square(0);      
          myHhtsHolesXMarkGLeftdn.origin =[xg, yg];      
          var myHhtsHolesYMarkGLeftdn = new makerjs.models.Square(0);      
          myHhtsHolesYMarkGLeftdn.origin = [xg, yg];  
    
    /*****************************************************************************/ 
    makerjs.model.addPath(myHhtsHolesMarkGLeftdn, new makerjs.paths.Line([-x0,y0], [-x0, -y0-markShiftFour]));//Y轴加长标线
    makerjs.model.addPath(myHhtsHolesMarkGLeftdn, new makerjs.paths.Line([0,0], [-x0-markShiftThree, 0]));//从孔圆心出发X方向横向标线
    makerjs.model.addPath(myHhtsHolesMarkGLeftdn, new makerjs.paths.Line([-x0,-y0], [-x0-markShiftThree, -y0]));//X轴加长标线
    makerjs.model.addPath(myHhtsHolesMarkGLeftdn, new makerjs.paths.Line([0,0], [0, -y0-markShiftFour]));//从孔圆心出发Y方向竖向标线
    /*****************************************************************************/ 
    makerjs.model.addPath(myHhtsHolesMarkGLeftdn, new makerjs.paths.Line([0,0], [d, -d]));//直径标线
    makerjs.model.addCaption(myHhtsHolesMarkGLeftdn, TxtD, [0,0], [d+markHolesOneD, -d-markHolesOneD]);//直径文字标注
    /*****************************************************************************/   
    makerjs.model.addPath(myHhtsHolesXMarkGLeftdn, new makerjs.paths.Line([0,-y0-markShiftTwo], [-x0, -y0-markShiftTwo]));//X短标线
    makerjs.model.addCaption(myHhtsHolesXMarkGLeftdn, TxtX, [-x0,-y0-markShiftFour], [0, -y0-markShiftFour]);//X文字标注
    /*****************************************************************************/     
    makerjs.model.addPath(myHhtsHolesYMarkGLeftdn, new makerjs.paths.Line([-x0-markShiftTwo,0], [-x0-markShiftTwo, -y0]));//Y短标线
    makerjs.model.addCaption(myHhtsHolesYMarkGLeftdn, TxtY, [-x0-markShiftFour, -y0], [-x0-markShiftFour,0]);//Y文字标注
    /*****************************************************************************/ 
    
     //标记拉手孔T字 G位置孔左中
     var myHhtsHolesGLeftmd= new makerjs.models.Holes(d/2, [[xg, yg+h1]]);
          var myHhtsHolesMarkGLeftmd = new makerjs.models.Square(0); //孔的原点       
          myHhtsHolesMarkGLeftmd.origin = [xg, yg+h1];     
          var myHhtsHolesYMarkGLeftmd = new makerjs.models.Square(0);      
          myHhtsHolesYMarkGLeftmd.origin = [xg, yg+h1];  
    
    /*****************************************************************************/ 
    makerjs.model.addPath(myHhtsHolesMarkGLeftmd, new makerjs.paths.Line([0,0], [-x0-markShiftThree, 0]));//从孔圆心出发X方向横向标线
    makerjs.model.addPath(myHhtsHolesMarkGLeftmd, new makerjs.paths.Line([0,0], [0, -h1-markShiftFour]));//从孔圆心出发Y方向竖向标线
    /*****************************************************************************/ 
    makerjs.model.addPath(myHhtsHolesMarkGLeftmd, new makerjs.paths.Line([0,0], [d, -d]));//直径标线
    makerjs.model.addCaption(myHhtsHolesMarkGLeftmd, TxtD, [0,0], [d+markHolesOneD, -d-markHolesOneD]);//直径文字标注
    /*****************************************************************************/     
    makerjs.model.addPath(myHhtsHolesYMarkGLeftmd, new makerjs.paths.Line([-x0-markShiftTwo,0], [-x0-markShiftTwo, -h1]));//Y短标线
    makerjs.model.addCaption(myHhtsHolesYMarkGLeftmd, TxtH1, [-x0-markShiftFour, -h1], [-x0-markShiftFour,0]);//Y文字标注
    /*****************************************************************************/ 
    
     //标记拉手孔T字 G位置孔右中
     var myHhtsHolesGRightmd= new makerjs.models.Holes(d/2, [[xg+w, yg+h1]]);
          //标记孔
          var myHhtsHolesMarkGRightmd = new makerjs.models.Square(0); //孔的原点       
          myHhtsHolesMarkGRightmd.origin = [xg+w, yg+h1];      
          var myHhtsHolesXMarkGRightmd = new makerjs.models.Square(0);      
          myHhtsHolesXMarkGRightmd.origin =[xg+w, yg+h1];  
    
    /*****************************************************************************/ 
    makerjs.model.addPath(myHhtsHolesMarkGRightmd, new makerjs.paths.Line([0,0], [-w-markShiftThree, 0]));//从孔圆心出发X方向横向标线
    makerjs.model.addPath(myHhtsHolesMarkGRightmd, new makerjs.paths.Line([0,0], [0, -h1-y0-markShiftFour]));//从孔圆心出发Y方向竖向标线          
    /*****************************************************************************/ 
    makerjs.model.addPath(myHhtsHolesMarkGRightmd, new makerjs.paths.Line([0,0], [d, -d]));//直径标线
    makerjs.model.addCaption(myHhtsHolesMarkGRightmd, TxtD, [0,0], [d+markHolesOneD, -d-markHolesOneD]);//直径文字标注
    /*****************************************************************************/   
    makerjs.model.addPath(myHhtsHolesXMarkGRightmd, new makerjs.paths.Line([0,-y0-h1-markShiftTwo], [-w, -y0-h1-markShiftTwo]));//X短标线
    makerjs.model.addCaption(myHhtsHolesXMarkGRightmd, TxtW, [-w,-y0-h1-markShiftFour],[0, -y0-h1-markShiftFour]);//X文字标注
    /*****************************************************************************/ 
    /*****************************************************************************/ 
    
     //标记拉手孔T字 G位置孔左上
     var myHhtsHolesGLeftup= new makerjs.models.Holes(d/2, [[xg, yg+h1+h2]]);
          //标记孔
          var myHhtsHolesMarkGLeftup = new makerjs.models.Square(0); //孔的原点       
          myHhtsHolesMarkGLeftup.origin = [xg, yg+h1+h2];   
          var myHhtsHolesYMarkGLeftup = new makerjs.models.Square(0);      
          myHhtsHolesYMarkGLeftup.origin = [xg, yg+h1+h2];  
    
    /*****************************************************************************/ 
    makerjs.model.addPath(myHhtsHolesMarkGLeftup, new makerjs.paths.Line([0,0], [-x0-markShiftThree, 0]));//从孔圆心出发X方向横向标线
    makerjs.model.addPath(myHhtsHolesMarkGLeftup, new makerjs.paths.Line([0,0], [0, -h2-markShiftFour]));//从孔圆心出发Y方向竖向标线
    /*****************************************************************************/ 
    makerjs.model.addPath(myHhtsHolesMarkGLeftup, new makerjs.paths.Line([0,0], [d, -d]));//直径标线
    makerjs.model.addCaption(myHhtsHolesMarkGLeftup, TxtD, [0,0], [d+markHolesOneD, -d-markHolesOneD]);//直径文字标注
    /*****************************************************************************/    
    makerjs.model.addPath(myHhtsHolesYMarkGLeftup, new makerjs.paths.Line([-x0-markShiftTwo,0], [-x0-markShiftTwo, -h2]));//Y短标线
    makerjs.model.addCaption(myHhtsHolesYMarkGLeftup, TxtH2, [-x0-markShiftFour, -h2], [-x0-markShiftFour,0]);//Y文字标注
    /*****************************************************************************/ 
    plate.models["myHhtsHolesGLeftdn"+myHolesNum]=myHhtsHolesGLeftdn;
    markers.models["myHhtsHolesGLeftdn"+myHolesNum]=myHhtsHolesGLeftdn;
    markers.models["myHhtsHolesMarkGLeftdn"+myHolesNum]=myHhtsHolesMarkGLeftdn;
    markers.models["myHhtsHolesXMarkGLeftdn"+myHolesNum]=myHhtsHolesXMarkGLeftdn;
    markers.models["myHhtsHolesYMarkGLeftdn"+myHolesNum]=myHhtsHolesYMarkGLeftdn;
    /*****************************************************************************/
    plate.models["myHhtsHolesGLeftmd"+myHolesNum]=myHhtsHolesGLeftmd;
    markers.models["myHhtsHolesGLeftmd"+myHolesNum]=myHhtsHolesGLeftmd;
    markers.models["myHhtsHolesMarkGLeftmd"+myHolesNum]=myHhtsHolesMarkGLeftmd;
    markers.models["myHhtsHolesYMarkGLeftmd"+myHolesNum]=myHhtsHolesYMarkGLeftmd;
    /*****************************************************************************/ 
    /*****************************************************************************/ 
    plate.models["myHhtsHolesGRightmd"+myHolesNum]=myHhtsHolesGRightmd;
    markers.models["myHhtsHolesGRightmd"+myHolesNum]=myHhtsHolesGRightmd;
    markers.models["myHhtsHolesMarkGRightmd"+myHolesNum]=myHhtsHolesMarkGRightmd;
    markers.models["myHhtsHolesXMarkGRightmd"+myHolesNum]=myHhtsHolesXMarkGRightmd;
    /*****************************************************************************/
    plate.models["myHhtsHolesGLeftup"+myHolesNum]=myHhtsHolesGLeftup;
    markers.models["myHhtsHolesGLeftup"+myHolesNum]=myHhtsHolesGLeftup;
    markers.models["myHhtsHolesMarkGLeftup"+myHolesNum]=myHhtsHolesMarkGLeftup;
    markers.models["myHhtsHolesYMarkGLeftup"+myHolesNum]=myHhtsHolesYMarkGLeftup;
    /*****************************************************************************/   
   
    }else if(area==9){
    //画孔 I位置孔
          //标拉手孔T字 I位置孔右下
          var myHhtsHolesIRightdn= new makerjs.models.Holes(d/2, [[xi, yi]]);
          var myHhtsHolesMarkIRightdn = new makerjs.models.Square(0); //孔的原点       
          myHhtsHolesMarkIRightdn.origin = [xi, yi];      
          var myHhtsHolesXMarkIRightdn = new makerjs.models.Square(0);      
          myHhtsHolesXMarkIRightdn.origin =[xi, yi];      
          var myHhtsHolesYMarkIRightdn = new makerjs.models.Square(0);      
          myHhtsHolesYMarkIRightdn.origin = [xi, yi];  
    
    /*****************************************************************************/ 
    makerjs.model.addPath(myHhtsHolesMarkIRightdn, new makerjs.paths.Line([x0,-y0], [x0, -y0-markShiftFour]));//Y轴加长标线
    makerjs.model.addPath(myHhtsHolesMarkIRightdn, new makerjs.paths.Line([0,0], [x0+markShiftThree, 0]));//从孔圆心出发X方向横向标线
    makerjs.model.addPath(myHhtsHolesMarkIRightdn, new makerjs.paths.Line([x0,-y0], [x0+markShiftThree, -y0]));//X轴加长标线
    makerjs.model.addPath(myHhtsHolesMarkIRightdn, new makerjs.paths.Line([0,0], [0, -y0-markShiftFour]));//从孔圆心出发Y方向竖向标线
    /*****************************************************************************/ 
    makerjs.model.addPath(myHhtsHolesMarkIRightdn, new makerjs.paths.Line([0,0], [d, -d]));//直径标线
    makerjs.model.addCaption(myHhtsHolesMarkIRightdn, TxtD, [0,0], [d+markHolesOneD, -d-markHolesOneD]);//直径文字标注
    /*****************************************************************************/   
    makerjs.model.addPath(myHhtsHolesXMarkIRightdn, new makerjs.paths.Line([0,-y0-markShiftTwo], [x0,-y0-markShiftTwo]));//X短标线
    makerjs.model.addCaption(myHhtsHolesXMarkIRightdn, TxtX, [0, -y0-markShiftFour], [x0,-y0-markShiftFour]);//X文字标注
    /*****************************************************************************/     
    makerjs.model.addPath(myHhtsHolesYMarkIRightdn, new makerjs.paths.Line([x0+markShift,0], [x0+markShift, -y0]));//Y短标线
    makerjs.model.addCaption(myHhtsHolesYMarkIRightdn, TxtY, [x0+markShiftFour, -y0], [x0+markShiftFour,0]);//Y文字标注
    /*****************************************************************************/ 
    
     //标记拉手孔T字 I位置孔右中
     var myHhtsHolesIRightmd= new makerjs.models.Holes(d/2, [[xi, yi+h1]]);
          var myHhtsHolesMarkIRightmd = new makerjs.models.Square(0); //孔的原点       
          myHhtsHolesMarkIRightmd.origin = [xi, yi+h1];     
          var myHhtsHolesYMarkIRightmd = new makerjs.models.Square(0);      
          myHhtsHolesYMarkIRightmd.origin = [xi, yi+h1];  
    
    /*****************************************************************************/ 
    makerjs.model.addPath(myHhtsHolesMarkIRightmd, new makerjs.paths.Line([0,0], [x0+markShiftThree, 0]));//从孔圆心出发X方向横向标线
    makerjs.model.addPath(myHhtsHolesMarkIRightmd, new makerjs.paths.Line([0,0], [0, -h1-markShiftFour]));//从孔圆心出发Y方向竖向标线
    /*****************************************************************************/ 
    makerjs.model.addPath(myHhtsHolesMarkIRightmd, new makerjs.paths.Line([0,0], [d, -d]));//直径标线
    makerjs.model.addCaption(myHhtsHolesMarkIRightmd, TxtD, [0,0], [d+markHolesOneD, -d-markHolesOneD]);//直径文字标注
    /*****************************************************************************/     
    makerjs.model.addPath(myHhtsHolesYMarkIRightmd, new makerjs.paths.Line([x0+markShift,0], [x0+markShift, -h1]));//Y短标线
    makerjs.model.addCaption(myHhtsHolesYMarkIRightmd, TxtH1, [x0+markShiftFour, -h1], [x0+markShiftFour,0]);//Y文字标注
    // /*****************************************************************************/ 
    
     //标记拉手孔T字 I位置孔左中
     var myHhtsHolesILeftmd= new makerjs.models.Holes(d/2, [[xi-w, yi+h1]]);
          //标记孔
          var myHhtsHolesMarkILeftmd = new makerjs.models.Square(0); //孔的原点       
          myHhtsHolesMarkILeftmd.origin = [xi-w, yi+h1];      
          var myHhtsHolesXMarkILeftmd = new makerjs.models.Square(0);      
          myHhtsHolesXMarkILeftmd.origin =[xi-w, yi+h1];     
    
    /*****************************************************************************/ 
    makerjs.model.addPath(myHhtsHolesMarkILeftmd, new makerjs.paths.Line([0,0], [w, 0]));//从孔圆心出发X方向横向标线
    makerjs.model.addPath(myHhtsHolesMarkILeftmd, new makerjs.paths.Line([0,0], [0, -h1-y0-markShiftFour]));//从孔圆心出发Y方向竖向标线          
    /*****************************************************************************/ 
    makerjs.model.addPath(myHhtsHolesMarkILeftmd, new makerjs.paths.Line([0,0], [d, -d]));//直径标线
    makerjs.model.addCaption(myHhtsHolesMarkILeftmd, TxtD, [0,0], [d+markHolesOneD, -d-markHolesOneD]);//直径文字标注
    /*****************************************************************************/   
    makerjs.model.addPath(myHhtsHolesXMarkILeftmd, new makerjs.paths.Line([0,-y0-h1-markShiftTwo], [w, -y0-h1-markShiftTwo]));//W短标线
    makerjs.model.addCaption(myHhtsHolesXMarkILeftmd, TxtW,[0, -y0-h1-markShiftFour], [w,-y0-h1-markShiftFour]);//W文字标注
    /*****************************************************************************/ 
    /*****************************************************************************/ 
    
     //标记拉手孔T字 I位置孔右上
     var myHhtsHolesIRightup= new makerjs.models.Holes(d/2, [[xi, yi+h1+h2]]);
          //标记孔
          var myHhtsHolesMarkIRightup = new makerjs.models.Square(0); //孔的原点       
          myHhtsHolesMarkIRightup.origin = [xi, yi+h1+h2];   
          var myHhtsHolesYMarkIRightup = new makerjs.models.Square(0);      
          myHhtsHolesYMarkIRightup.origin = [xi, yi+h1+h2];  
    
    /*****************************************************************************/ 
    makerjs.model.addPath(myHhtsHolesMarkIRightup, new makerjs.paths.Line([0,0], [x0+markShiftThree, 0]));//从孔圆心出发X方向横向标线
    makerjs.model.addPath(myHhtsHolesMarkIRightup, new makerjs.paths.Line([0,0], [0, -h2-markShiftTwo]));//从孔圆心出发Y方向竖向标线
    /*****************************************************************************/ 
    makerjs.model.addPath(myHhtsHolesMarkIRightup, new makerjs.paths.Line([0,0], [d, -d]));//直径标线
    makerjs.model.addCaption(myHhtsHolesMarkIRightup, TxtD, [0,0], [d+markHolesOneD, -d-markHolesOneD]);//直径文字标注
    /*****************************************************************************/    
    makerjs.model.addPath(myHhtsHolesYMarkIRightup, new makerjs.paths.Line([x0+markShift,0], [x0+markShift, -h2]));//H2短标线
    makerjs.model.addCaption(myHhtsHolesYMarkIRightup, TxtH2, [x0+markShiftFour,0], [x0+markShiftFour, -h2]);//H2文字标注
    /*****************************************************************************/ 
    plate.models["myHhtsHolesIRightdn"+myHolesNum]=myHhtsHolesIRightdn;
    markers.models["myHhtsHolesIRightdn"+myHolesNum]=myHhtsHolesIRightdn;
    markers.models["myHhtsHolesMarkIRightdn"+myHolesNum]=myHhtsHolesMarkIRightdn;
    markers.models["myHhtsHolesXMarkIRightdn"+myHolesNum]=myHhtsHolesXMarkIRightdn;
    markers.models["myHhtsHolesYMarkIRightdn"+myHolesNum]=myHhtsHolesYMarkIRightdn;
    /*****************************************************************************/
    plate.models["myHhtsHolesIRightmd"+myHolesNum]=myHhtsHolesIRightmd;
    markers.models["myHhtsHolesIRightmd"+myHolesNum]=myHhtsHolesIRightmd;
    markers.models["myHhtsHolesMarkIRightmd"+myHolesNum]=myHhtsHolesMarkIRightmd;
    markers.models["myHhtsHolesYMarkIRightmd"+myHolesNum]=myHhtsHolesYMarkIRightmd;
    // /*****************************************************************************/ 
    // /*****************************************************************************/ 
    plate.models["myHhtsHolesILeftmd"+myHolesNum]=myHhtsHolesILeftmd;
    markers.models["myHhtsHolesILeftmd"+myHolesNum]=myHhtsHolesILeftmd;
    markers.models["myHhtsHolesMarkILeftmd"+myHolesNum]=myHhtsHolesMarkILeftmd;
    markers.models["myHhtsHolesXMarkILeftmd"+myHolesNum]=myHhtsHolesXMarkILeftmd;
    // /*****************************************************************************/ 
    plate.models["myHhtsHolesIRightup"+myHolesNum]=myHhtsHolesIRightup;
    markers.models["myHhtsHolesIRightup"+myHolesNum]=myHhtsHolesIRightup;
    markers.models["myHhtsHolesMarkIRightup"+myHolesNum]=myHhtsHolesMarkIRightup;
    markers.models["myHhtsHolesYMarkIRightup"+myHolesNum]=myHhtsHolesYMarkIRightup;
    /*****************************************************************************/  
    } 

myHolesNum++;
return{plate, markers,myHolesNum}
};


export default HandleHoleTShaped;