//角6、地弹簧2
import makerjs from "makerjs";
// inputParam,model,myHolesNum,outParam,fixedParam
// (D,X,Y,area,plate,markers,myHolesNum,glassW,glassH,markShift,markShiftTwo,markShiftThree,markShiftFour,markHolesOneD)
function FloorSpring2(inputParam,model,myHolesNum,outParam,fixedParam,zoom){
var D=inputParam.D,R=inputParam.R,W=inputParam.W,W1=inputParam.W1,W2=inputParam.W2,H=inputParam.H,H1=inputParam.H1,X=inputParam.X,Y=inputParam.Y,area=inputParam.area,myZoom=zoom*1,
plate=model.plate,markers=model.markers,
glassW=outParam.glassW,glassH=outParam.glassH,
   markShift=fixedParam.markShift,markShiftTwo=fixedParam.markShiftTwo,
   markShiftThree=fixedParam.markShiftThree,markShiftFour=fixedParam.markShiftFour,markHolesOneD=fixedParam.markHolesOneD,
   
   w = W*1*myZoom,
   w1 = W1*1*myZoom,
   w2 = W2*1*myZoom,
   h = H*1*myZoom,
   h1 = H1*1*myZoom,
   r1 = R*1*myZoom,
   d = D*1*myZoom,
   r = d/2,
   a=0,//圆心坐标[a,b]
   b=0,//圆心坐标[a,b]  
     x0 = X*1*myZoom,
     y0 = Y*1*myZoom; 
     
    /* 
   w = W*1,
   w1 = W1*1,
   w2 = W2*1,
   h = H*1,
   h1 = H1*1,
   r1 = R*1,
   d = D*1,
   r = (D*1)/2,
   a=0,//圆心坐标[a,b]
   b=0,//圆心坐标[a,b]  
     x0 = X*1,
     y0 = Y*1; 
*/
     if(d!==0){
      var TxtD="∮"+D;
     }
     if(r1!==0){
      var TxtR1="R="+R;
     }
     if(w!==0){
      var TxtW=W;
     }
     if(w1!==0){
      var TxtW1=W1;
     }
     if(w2!==0){
      var TxtW2=W2;
     }
     if(h!==0){
      var TxtH=H;
     }
     if(h1!==0){
      var TxtH1=H1;
     }
     if(x0!==0){          
     var TxtX=X;
     }
     if(y0!==0){          
      var TxtY=Y;
     }
  //在A区域 计算孔的位置       
  var xa=x0,ya=glassH * 1-y0,
  //B区域//只有Y标注没有X标注 
  xb=glassW * 1/2,yb=glassH * 1-y0,
  //C区域
  xc=glassW * 1-x0,yc=glassH * 1-y0,
  //D区域
  //只有X标注没有Y标注 
  xd=x0,yd=glassH * 1/2,
  //E区域
  //只有X标注没有Y标注 
  xe=glassW * 1/2,ye=glassH * 1/2,
  //F区域
  //只有X标注没有Y标注 
  xf=glassW * 1-x0,yf=glassH * 1/2,
  //在G区域 计算孔的位置
  xg=x0,yg=y0,
  //在H区域 计算孔的位置
  //只标注Y
  xh=glassW*1/2,yh=y0,
  //在I区域 计算孔的位置
  xi=glassW*1-x0,yi=y0;
  if(area==1){  
 
    //画6、地弹簧2 A位置
var myFloorSpring2A = {
paths: {
"TopLine": new makerjs.paths.Line([xa-5, ya+5], [xa+w1, ya+5]),
"RightLine": new makerjs.paths.Line([xa+w1, ya+5], [xa+w1, ya]), 
"BottomRightArc": new makerjs.paths.Arc([xa+w1, ya], [xa+w, ya-h],r1,false,true),//是否大弧,是否顺时针
"BottomLine": new makerjs.paths.Line([xa+w, ya-h], [xa-5, ya-h]),
"LeftLine": new makerjs.paths.Line([xa-5, ya-h], [xa-5, ya+5]),
"RightHoles": new makerjs.paths.Circle([xa+w2, ya-h1], r),
}
};

//标记6、地弹簧 A位置
var myFs2AMakerD = new makerjs.models.Square(0); //孔的原点       
myFs2AMakerD.origin = [xa,ya];
var myFs2AMakerH = new makerjs.models.Square(0); //孔的原点       
myFs2AMakerH.origin = [xa,ya];
var myFs2AMakerH1 = new makerjs.models.Square(0); //孔的原点       
myFs2AMakerH1.origin = [xa,ya];
var myFs2AMakerR1 = new makerjs.models.Square(0); //孔的原点       
myFs2AMakerR1.origin = [xa,ya];
var myFs2AMakerW = new makerjs.models.Square(0); //孔的原点       
myFs2AMakerW.origin = [xa,ya];
var myFs2AMakerW1 = new makerjs.models.Square(0); //孔的原点       
myFs2AMakerW1.origin = [xa,ya];
var myFs2AMakerW2 = new makerjs.models.Square(0); //孔的原点       
myFs2AMakerW2.origin = [xa,ya];

// /*****************************************************************************/
// /*****************************************************************************/

makerjs.model.addPath(myFs2AMakerW, new makerjs.paths.Line([w,-h], [w,-h-markShiftThree]));//Y轴方向短线  W  right

makerjs.model.addPath(myFs2AMakerW, new makerjs.paths.Line([w1,0], [w1,-h1-d-markShiftThree]));//Y轴方向短线  W1  right

makerjs.model.addPath(myFs2AMakerW, new makerjs.paths.Line([w2,-r], [w2,-r-h1-h1-markShiftFour]));//Y轴方向短线  W2  right

makerjs.model.addPath(myFs2AMakerW, new makerjs.paths.Line([0,0], [0-markShiftFour, 0]));//X轴加长标线
makerjs.model.addPath(myFs2AMakerW, new makerjs.paths.Line([0,0-h], [0-markShiftFour, 0-h]));//X轴方向短线  H

makerjs.model.addPath(myFs2AMakerW, new makerjs.paths.Line([w1-r,-h1], [w2+r+markShiftFour, -h1]));//X轴方向短线   H1

// // /*****************************************************************************/
// // /*****************************************************************************/   
makerjs.model.addPath(myFs2AMakerW, new makerjs.paths.Line([0,0-h-markShift], [0+w,0-h-markShift]));//W短线标注
makerjs.model.addCaption(myFs2AMakerW, TxtW, [0,0-h-markShiftThree], [0+w,0-h-markShiftThree]);//W文字标注 

// // /*****************************************************************************/  
// // /*****************************************************************************/  

makerjs.model.addPath(myFs2AMakerW1, new makerjs.paths.Line([0,-h-d-markShift], [w1,-h-d-markShift]));//W1短线标注
makerjs.model.addCaption(myFs2AMakerW1, TxtW1, [0,-h-d-markShiftThree], [w1,-h-d-markShiftThree]);//W1文字标注 

// // // /*****************************************************************************/  
// // // /*****************************************************************************/  

makerjs.model.addPath(myFs2AMakerW2, new makerjs.paths.Line([0,-h-h-markShiftThree], [w2,-h-h-markShiftThree]));//W2短线标注
makerjs.model.addCaption(myFs2AMakerW2, TxtW2, [0,-h-h-r-markShiftFour], [w2,-h-h-r-markShiftFour]);//W2文字标注 

// // // /*****************************************************************************/  
// // // /*****************************************************************************/  

makerjs.model.addPath(myFs2AMakerH, new makerjs.paths.Line([0-markShiftTwo,0], [0-markShiftTwo,-h]));//H短线标注
makerjs.model.addCaption(myFs2AMakerH, TxtH, [0-markShiftFour,-h], [0-markShiftFour,0]);//H文字标注 

// // // /*****************************************************************************/  
// // // /*****************************************************************************/  

makerjs.model.addPath(myFs2AMakerH1, new makerjs.paths.Line([w2+markShiftTwo,0], [w2+markShiftTwo,-h1]));//H1短线标注
makerjs.model.addCaption(myFs2AMakerH1, TxtH1, [w2+markShiftFour,-h1], [w2+markShiftFour,0]);//H1文字标注  

// // // /*****************************************************************************/  
// // // /*****************************************************************************/  

makerjs.model.addPath(myFs2AMakerR1, new makerjs.paths.Line([w+(w/2),-h], [w+markShiftFour,-h+markShiftTwo]));//R1短线标注
makerjs.model.addCaption(myFs2AMakerR1, TxtR1, [w+(w/2),-h], [w+markShiftFour,-h+markShiftTwo]);//R1文字标注       
// // // /*****************************************************************************/  
// // // /*****************************************************************************/  

makerjs.model.addPath(myFs2AMakerD, new makerjs.paths.Line([w2,-h1], [w2+markShift+markHolesOneD,-h1-d-markShiftFour-markHolesOneD]));//D短线标注
makerjs.model.addCaption(myFs2AMakerD, TxtD, [w2+markShift+markHolesOneD,-h1-d-markShiftFour-markHolesOneD],[w2+markHolesOneD+markShiftFour,-h1-d-markShiftFour-markHolesOneD]);//D文字标注 

/*****************************************************************************/  
/*****************************************************************************/ 

plate.models["myFloorSpring2A"+myHolesNum]=myFloorSpring2A;
markers.models["myFloorSpring2A"+myHolesNum]=myFloorSpring2A;
markers.models["myFs2AMakerD"+myHolesNum]=myFs2AMakerD;
markers.models["myFs2AMakerH"+myHolesNum]=myFs2AMakerH;
markers.models["myFs2AMakerH1"+myHolesNum]=myFs2AMakerH1;
markers.models["myFs2AMakerR1"+myHolesNum]=myFs2AMakerR1;
markers.models["myFs2AMakerW"+myHolesNum]=myFs2AMakerW;
markers.models["myFs2AMakerW1"+myHolesNum]=myFs2AMakerW1;
markers.models["myFs2AMakerW2"+myHolesNum]=myFs2AMakerW2;
// /*****************************************************************************/
makerjs.model.combineSubtraction(markers.models.myRect, markers.models["myFloorSpring2A"+myHolesNum])
// makerjs.model.combineSubtraction(markers.models.myRect, markers.models["myFillet2AMakerR"+myHolesNum])

console.log("markers==",markers);
/*****************************************************************************/ 

/*****************************************************************************/ 
 
    }else if(area==2){
      
    }else if(area==3){
    
    //画6、地弹簧2 C位置
var myFloorSpring2C = {
paths: {
"TopLine": new makerjs.paths.Line([xc-w1, yc+5],[xc+5, yc+5]),
"RightLine": new makerjs.paths.Line([xc+5, yc+5], [xc+5, yc-h]),
"BottomLine": new makerjs.paths.Line( [xc+5, yc-h],[xc-w, yc-h]),
"BottomRightArc": new makerjs.paths.Arc([xc-w, yc-h], [xc-w1, yc],r1,false,true),//是否大弧,是否顺时针
"LeftLine": new makerjs.paths.Line([xc-w1, yc], [xc-w1, yc+5]),
"RightHoles": new makerjs.paths.Circle([xc-w2, yc-h1], r),
}
};

//标记6、地弹簧 C位置
var myFs2CMakerD = new makerjs.models.Square(0); //孔的原点       
myFs2CMakerD.origin = [xc,yc];
var myFs2CMakerH = new makerjs.models.Square(0); //孔的原点       
myFs2CMakerH.origin = [xc,yc];
var myFs2CMakerH1 = new makerjs.models.Square(0); //孔的原点       
myFs2CMakerH1.origin = [xc,yc];
var myFs2CMakerR1 = new makerjs.models.Square(0); //孔的原点       
myFs2CMakerR1.origin = [xc,yc];
var myFs2CMakerW = new makerjs.models.Square(0); //孔的原点       C 
myFs2CMakerW.origin = [xc,yc];
var myFs2CMakerW1 = new makerjs.models.Square(0); //孔的原点       
myFs2CMakerW1.origin = [xc,yc];
var myFs2CMakerW2 = new makerjs.models.Square(0); //孔的原点       
myFs2CMakerW2.origin = [xc,yc];

// /*****************************************************************************/
// /*****************************************************************************/

makerjs.model.addPath(myFs2CMakerW, new makerjs.paths.Line([-w,-h], [-w,-h-markShiftThree]));//Y轴方向短线  W  right

makerjs.model.addPath(myFs2CMakerW, new makerjs.paths.Line([-w1,0], [-w1,-h1-d-markShiftThree]));//Y轴方向短线  W1  right

makerjs.model.addPath(myFs2CMakerW, new makerjs.paths.Line([-w2,-r], [-w2,-r-h1-h1-markShiftThree]));//Y轴方向短线  W2  right

makerjs.model.addPath(myFs2CMakerW, new makerjs.paths.Line([0,0], [0+markShiftFour, 0]));//X轴加长标线
makerjs.model.addPath(myFs2CMakerW, new makerjs.paths.Line([0,0-h], [0+markShiftFour, 0-h]));//X轴方向短线  H

makerjs.model.addPath(myFs2CMakerW, new makerjs.paths.Line([-w1+r,-h1], [-w2-r-markShiftFour, -h1]));//X轴方向短线   H1

// /*****************************************************************************/
// /*****************************************************************************/   
makerjs.model.addPath(myFs2CMakerW, new makerjs.paths.Line([0,0-h-markShift], [0-w,0-h-markShift]));//W短线标注
makerjs.model.addCaption(myFs2CMakerW, TxtW, [0-w,0-h-markShiftThree], [0,0-h-markShiftThree]);//W文字标注 

// /*****************************************************************************/  
// /*****************************************************************************/  

makerjs.model.addPath(myFs2CMakerW1, new makerjs.paths.Line([0,-h-d-markShift], [-w1,-h-d-markShift]));//W1短线标注
makerjs.model.addCaption(myFs2CMakerW1, TxtW1, [-w1,-h-d-markShiftThree], [0,-h-d-markShiftThree]);//W1文字标注 

// // /*****************************************************************************/  
// // /*****************************************************************************/  

makerjs.model.addPath(myFs2CMakerW2, new makerjs.paths.Line([0,-h-h-markShiftThree], [-w2,-h-h-markShiftThree]));//W2短线标注
makerjs.model.addCaption(myFs2CMakerW2, TxtW2, [-w2,-h-h-r-markShiftFour], [0,-h-h-r-markShiftFour]);//W2文字标注 

// // /*****************************************************************************/  
// // /*****************************************************************************/  

makerjs.model.addPath(myFs2CMakerH, new makerjs.paths.Line([0+markShiftTwo,0], [0+markShiftTwo,-h]));//H短线标注
makerjs.model.addCaption(myFs2CMakerH, TxtH, [0+markShiftFour,-h], [0+markShiftFour,0]);//H文字标注 

// // /*****************************************************************************/  
// // /*****************************************************************************/  

makerjs.model.addPath(myFs2CMakerH1, new makerjs.paths.Line([-w2-markShiftTwo,0], [-w2-markShiftTwo,-h1]));//H1短线标注
makerjs.model.addCaption(myFs2CMakerH1, TxtH1, [-w2-markShiftFour,-h1], [-w2-markShiftFour,0]);//H1文字标注  

// // /*****************************************************************************/  
// // /*****************************************************************************/  

makerjs.model.addPath(myFs2CMakerR1, new makerjs.paths.Line([-w-(w/2),-h], [-w-markShiftFour,-h+markShiftTwo]));//R1短线标注
makerjs.model.addCaption(myFs2CMakerR1, TxtR1, [-w-(w/2),-h], [-w-markShiftFour,-h+markShiftTwo]);//R1文字标注    

// // /*****************************************************************************/  
// // /*****************************************************************************/  

makerjs.model.addPath(myFs2CMakerD, new makerjs.paths.Line([-w2,-h1], [-w2-markShift-markHolesOneD,-h1-d-markShiftFour-markHolesOneD]));//D短线标注
makerjs.model.addCaption(myFs2CMakerD, TxtD,[-w2-markHolesOneD-markShiftFour,-h1-d-markShiftFour-markHolesOneD], [-w2-markShift-markHolesOneD,-h1-d-markShiftFour-markHolesOneD]);//D文字标注 

/*****************************************************************************/  
/*****************************************************************************/ 

plate.models["myFloorSpring2C"+myHolesNum]=myFloorSpring2C;
markers.models["myFloorSpring2C"+myHolesNum]=myFloorSpring2C;
markers.models["myFs2CMakerD"+myHolesNum]=myFs2CMakerD;
markers.models["myFs2CMakerH"+myHolesNum]=myFs2CMakerH;
markers.models["myFs2CMakerH1"+myHolesNum]=myFs2CMakerH1;
markers.models["myFs2CMakerR1"+myHolesNum]=myFs2CMakerR1;
markers.models["myFs2CMakerW"+myHolesNum]=myFs2CMakerW;
markers.models["myFs2CMakerW1"+myHolesNum]=myFs2CMakerW1;
markers.models["myFs2CMakerW2"+myHolesNum]=myFs2CMakerW2;
// /*****************************************************************************/
makerjs.model.combineSubtraction(markers.models.myRect, markers.models["myFloorSpring2C"+myHolesNum])
// makerjs.model.combineSubtraction(markers.models.myRect, markers.models["myFillet2AMakerR"+myHolesNum])

console.log("markers==",markers);
/*****************************************************************************/ 

/*****************************************************************************/ 
      
    }else if(area==4){
      
    }else if(area==5){
      
    }else if(area==6){
      
    }else if(area==7){

  //画6、地弹簧2 G位置

var myFloorSpring2G = {
paths: {  
"LeftLine": new makerjs.paths.Line([xg-5, yg-5], [xg-5, yg+h]),
"TopLine": new makerjs.paths.Line([xg-5, yg+h], [xg+w, yg+h]),
"TopLeftArc": new makerjs.paths.Arc([xg+w, yg+h], [xg+w1, yg],r1,false,true),//是否大弧,是否顺时针
"RightLine": new makerjs.paths.Line([xg+w1, yg], [xg+w1, yg-5]),
"BottomLine": new makerjs.paths.Line([xg+w1, yg-5], [xg-5, yg-5]),
"RightHoles": new makerjs.paths.Circle([xg+w2, yg+h1], r),
}
};

//标记6、地弹簧 A位置
var myFs2GMakerD = new makerjs.models.Square(0); //孔的原点       
myFs2GMakerD.origin = [xg,yg];
var myFs2GMakerH = new makerjs.models.Square(0); //孔的原点       
myFs2GMakerH.origin = [xg,yg];
var myFs2GMakerH1 = new makerjs.models.Square(0); //孔的原点       
myFs2GMakerH1.origin = [xg,yg];
var myFs2GMakerR1 = new makerjs.models.Square(0); //孔的原点       
myFs2GMakerR1.origin = [xg,yg];
var myFs2GMakerW = new makerjs.models.Square(0); //孔的原点       
myFs2GMakerW.origin = [xg,yg];
var myFs2GMakerW1 = new makerjs.models.Square(0); //孔的原点       
myFs2GMakerW1.origin = [xg,yg];
var myFs2GMakerW2 = new makerjs.models.Square(0); //孔的原点       
myFs2GMakerW2.origin = [xg,yg];

// /*****************************************************************************/
// /*****************************************************************************/

makerjs.model.addPath(myFs2GMakerW, new makerjs.paths.Line([w,h], [w,h+markShiftThree]));//Y轴方向短线  W  right

makerjs.model.addPath(myFs2GMakerW, new makerjs.paths.Line([w1,h1-r], [w1,h1+d+markShiftThree]));//Y轴方向短线  W1  right

makerjs.model.addPath(myFs2GMakerW, new makerjs.paths.Line([w2,r], [w2,r+h1+h1+markShiftThree]));//Y轴方向短线  W2  right

makerjs.model.addPath(myFs2GMakerW, new makerjs.paths.Line([0,0], [0-markShiftFour, 0]));//X轴加长标线
makerjs.model.addPath(myFs2GMakerW, new makerjs.paths.Line([0,0+h], [0-markShiftFour, 0+h]));//X轴方向短线  H

makerjs.model.addPath(myFs2GMakerW, new makerjs.paths.Line([w1-r,h1], [w2+r+markShiftFour, h1]));//X轴方向短线   H1

// /*****************************************************************************/
// /*****************************************************************************/   
makerjs.model.addPath(myFs2GMakerW, new makerjs.paths.Line([0,0+h+markShift], [0+w,0+h+markShift]));//W短线标注
makerjs.model.addCaption(myFs2GMakerW, TxtW, [0,0+h+markShiftThree], [0+w,0+h+markShiftThree]);//W文字标注 

// /*****************************************************************************/  
// /*****************************************************************************/  

makerjs.model.addPath(myFs2GMakerW1, new makerjs.paths.Line([0,h+d+markShift], [w1,h+d+markShift]));//W1短线标注
makerjs.model.addCaption(myFs2GMakerW1, TxtW1, [0,h+d+markShiftThree], [w1,h+d+markShiftThree]);//W1文字标注 

// // /*****************************************************************************/  
// // /*****************************************************************************/  

makerjs.model.addPath(myFs2GMakerW2, new makerjs.paths.Line([0,h+h+markShiftThree], [w2,h+h+markShiftThree]));//W2短线标注
makerjs.model.addCaption(myFs2GMakerW2, TxtW2, [0,h+h+r+markShiftFour], [w2,h+h+r+markShiftFour]);//W2文字标注 

// // /*****************************************************************************/  
// // /*****************************************************************************/  

makerjs.model.addPath(myFs2GMakerH, new makerjs.paths.Line([0-markShiftTwo,0], [0-markShiftTwo,h]));//H短线标注
makerjs.model.addCaption(myFs2GMakerH, TxtH, [0-markShiftFour,0], [0-markShiftFour,h]);//H文字标注 

// // /*****************************************************************************/  
// // /*****************************************************************************/  

makerjs.model.addPath(myFs2GMakerH1, new makerjs.paths.Line([w2+markShift,0], [w2+markShift,h1]));//H1短线标注
makerjs.model.addCaption(myFs2GMakerH1, TxtH1, [w2+markShiftThree,0], [w2+markShiftThree,h1]);//H1文字标注  

// // /*****************************************************************************/  
// // /*****************************************************************************/   

makerjs.model.addPath(myFs2GMakerR1, new makerjs.paths.Line([w+(w/2),h], [w+(w/2)-markShiftFour,h-markShiftThree]));//R1短线标注
makerjs.model.addCaption(myFs2GMakerR1, TxtR1, [w+(w/2)-markShiftFour,h-markShiftThree], [w+(w/2),h]);//R1文字标注    

// // /*****************************************************************************/  
// // /*****************************************************************************/  

makerjs.model.addPath(myFs2GMakerD, new makerjs.paths.Line([w2,h1], [w2+markShift+markHolesOneD,h1+d+markShiftFour+markHolesOneD]));//D短线标注
makerjs.model.addCaption(myFs2GMakerD, TxtD, [w2+markShift+markHolesOneD,h1+d+markShiftFour+markHolesOneD],[w2+markHolesOneD+markShiftFour,h1+d+markShiftFour+markHolesOneD]);//D文字标注 

/*****************************************************************************/  
/*****************************************************************************/ 

plate.models["myFloorSpring2G"+myHolesNum]=myFloorSpring2G;
markers.models["myFloorSpring2G"+myHolesNum]=myFloorSpring2G;
markers.models["myFs2GMakerD"+myHolesNum]=myFs2GMakerD;
markers.models["myFs2GMakerH"+myHolesNum]=myFs2GMakerH;
markers.models["myFs2GMakerH1"+myHolesNum]=myFs2GMakerH1;
markers.models["myFs2GMakerR1"+myHolesNum]=myFs2GMakerR1;
markers.models["myFs2GMakerW"+myHolesNum]=myFs2GMakerW;
markers.models["myFs2GMakerW1"+myHolesNum]=myFs2GMakerW1;
markers.models["myFs2GMakerW2"+myHolesNum]=myFs2GMakerW2;
// /*****************************************************************************/
makerjs.model.combineSubtraction(markers.models.myRect, markers.models["myFloorSpring2G"+myHolesNum])
// makerjs.model.combineSubtraction(markers.models.myRect, markers.models["myFillet2AMakerR"+myHolesNum])

console.log("markers==",markers);
/*****************************************************************************/ 

/*****************************************************************************/ 

      
    }else if(area==8){
      
    }else if(area==9){
     
     //画6、地弹簧 I位置
var myFloorSpring2I = {
paths: {
"BottomLine": new makerjs.paths.Line([xi+5, yi-5], [xi-w1, yi-5]),
"LeftLine": new makerjs.paths.Line([xi-w1, yi-5], [xi-w1, yi]), 
"TopLeftArc": new makerjs.paths.Arc([xi-w1, yi], [xi-w, yi+h],r1,false,true),//是否大弧,是否顺时针
"TopLine": new makerjs.paths.Line([xi-w, yi+h], [xi+5, yi+h]),
"RightLine": new makerjs.paths.Line([xi+5, yi+h], [xi+5, yi-5]),
"RightHoles": new makerjs.paths.Circle([xi-w2, yi+h1], r),
}
};

//标记6、地弹簧 I位置
var myFs2IMakerD = new makerjs.models.Square(0); //孔的原点       
myFs2IMakerD.origin = [xi,yi];
var myFs2IMakerH = new makerjs.models.Square(0); //孔的原点       
myFs2IMakerH.origin = [xi,yi];
var myFs2IMakerH1 = new makerjs.models.Square(0); //孔的原点       
myFs2IMakerH1.origin = [xi,yi];
var myFs2IMakerR1 = new makerjs.models.Square(0); //孔的原点       
myFs2IMakerR1.origin = [xi,yi];
var myFs2IMakerW = new makerjs.models.Square(0); //孔的原点       C 
myFs2IMakerW.origin = [xi,yi];
var myFs2IMakerW1 = new makerjs.models.Square(0); //孔的原点       
myFs2IMakerW1.origin = [xi,yi];
var myFs2IMakerW2 = new makerjs.models.Square(0); //孔的原点       
myFs2IMakerW2.origin = [xi,yi];

// /*****************************************************************************/
// /*****************************************************************************/

makerjs.model.addPath(myFs2IMakerW, new makerjs.paths.Line([-w,h], [-w,h+markShiftThree]));//Y轴方向短线  W  right

makerjs.model.addPath(myFs2IMakerW, new makerjs.paths.Line([-w1,h1-r], [-w1,h1+d+markShiftThree]));//Y轴方向短线  W1  right

makerjs.model.addPath(myFs2IMakerW, new makerjs.paths.Line([-w2,r], [-w2,r+h1+h1+markShiftThree]));//Y轴方向短线  W2  right

makerjs.model.addPath(myFs2IMakerW, new makerjs.paths.Line([0,0], [0+markShiftFour, 0]));//X轴加长标线
makerjs.model.addPath(myFs2IMakerW, new makerjs.paths.Line([0,0+h], [0+markShiftFour, 0+h]));//X轴方向短线  H

makerjs.model.addPath(myFs2IMakerW, new makerjs.paths.Line([-w1+r,h1], [-w2-r-markShiftFour, h1]));//X轴方向短线   H1


// /*****************************************************************************/
// /*****************************************************************************/   
makerjs.model.addPath(myFs2IMakerW, new makerjs.paths.Line([0,0+h+markShift], [0-w,0+h+markShift]));//W短线标注
makerjs.model.addCaption(myFs2IMakerW, TxtW, [0-w,0+h+markShiftThree], [0,0+h+markShiftThree]);//W文字标注 

// /*****************************************************************************/  
// /*****************************************************************************/  

makerjs.model.addPath(myFs2IMakerW1, new makerjs.paths.Line([0,h+d+markShift], [-w1,h+d+markShift]));//W1短线标注
makerjs.model.addCaption(myFs2IMakerW1, TxtW1, [-w1,h+d+markShiftThree], [0,h+d+markShiftThree]);//W1文字标注 

// // /*****************************************************************************/  
// // /*****************************************************************************/  

makerjs.model.addPath(myFs2IMakerW2, new makerjs.paths.Line([0,h+h+markShiftThree], [-w2,h+h+markShiftThree]));//W2短线标注
makerjs.model.addCaption(myFs2IMakerW2, TxtW2, [-w2,h+h+r+markShiftFour], [0,h+h+r+markShiftFour]);//W2文字标注 

// // /*****************************************************************************/  
// // /*****************************************************************************/  

makerjs.model.addPath(myFs2IMakerH, new makerjs.paths.Line([0+markShiftTwo,0], [0+markShiftTwo,h]));//H短线标注
makerjs.model.addCaption(myFs2IMakerH, TxtH, [0+markShiftFour,0], [0+markShiftFour,h]);//H文字标注 

// // /*****************************************************************************/  
// // /*****************************************************************************/  

makerjs.model.addPath(myFs2IMakerH1, new makerjs.paths.Line([-w2-markShift,0], [-w2-markShift,h1]));//H1短线标注
makerjs.model.addCaption(myFs2IMakerH1, TxtH1, [-w2-markShiftThree,0], [-w2-markShiftThree,h1]);//H1文字标注  

// // /*****************************************************************************/  
// // /*****************************************************************************/ 

makerjs.model.addPath(myFs2IMakerR1, new makerjs.paths.Line([-w-(w/2),h], [-w-markShiftFour,h-markShiftTwo]));//R1短线标注
makerjs.model.addCaption(myFs2IMakerR1, TxtR1, [-w-(w/2),h], [-w-markShiftFour,h-markShiftTwo]);//R1文字标注    

// // /*****************************************************************************/  
// // /*****************************************************************************/  

makerjs.model.addPath(myFs2IMakerD, new makerjs.paths.Line([-w2,h1], [-w2-markShift-markHolesOneD,h1+d+markShiftFour+markHolesOneD]));//D短线标注
makerjs.model.addCaption(myFs2IMakerD, TxtD,[-w2-markHolesOneD-markShiftFour,h1+d+markShiftFour+markHolesOneD], [-w2-markShift-markHolesOneD,h1+d+markShiftFour+markHolesOneD]);//D文字标注 

/*****************************************************************************/  
/*****************************************************************************/ 

plate.models["myFloorSpring2I"+myHolesNum]=myFloorSpring2I;
markers.models["myFloorSpring2I"+myHolesNum]=myFloorSpring2I;
markers.models["myFs2IMakerD"+myHolesNum]=myFs2IMakerD;
markers.models["myFs2IMakerH"+myHolesNum]=myFs2IMakerH;
markers.models["myFs2IMakerH1"+myHolesNum]=myFs2IMakerH1;
markers.models["myFs2IMakerR1"+myHolesNum]=myFs2IMakerR1;
markers.models["myFs2IMakerW"+myHolesNum]=myFs2IMakerW;
markers.models["myFs2IMakerW1"+myHolesNum]=myFs2IMakerW1;
markers.models["myFs2IMakerW2"+myHolesNum]=myFs2IMakerW2;
// /*****************************************************************************/
makerjs.model.combineSubtraction(markers.models.myRect, markers.models["myFloorSpring2I"+myHolesNum])
// makerjs.model.combineSubtraction(markers.models.myRect, markers.models["myFillet2AMakerR"+myHolesNum])

console.log("markers==",markers);
/*****************************************************************************/ 

/*****************************************************************************/ 
    }

myHolesNum++;
return{plate, markers,myHolesNum}
};
export default FloorSpring2;