//缺6、铜夹
import makerjs from "makerjs";
// inputParam,model,myHolesNum,outParam,fixedParam
// (D,X,Y,area,plate,markers,myHolesNum,glassW,glassH,markShift,markShiftTwo,markShiftThree,markShiftFour,markHolesOneD)
function CopperClipGap(inputParam,model,myHolesNum,outParam,fixedParam,zoom){
var R=inputParam.R,W=inputParam.W,W1=inputParam.W1,H=inputParam.H,D=inputParam.D,X=inputParam.X,Y=inputParam.Y,area=inputParam.area,myZoom=zoom*1,
plate=model.plate,markers=model.markers,
glassW=outParam.glassW,glassH=outParam.glassH,
   markShift=fixedParam.markShift,markShiftTwo=fixedParam.markShiftTwo,
   markShiftThree=fixedParam.markShiftThree,markShiftFour=fixedParam.markShiftFour,markHolesOneD=fixedParam.markHolesOneD,
 
   w= W*1*myZoom,
      h= H*1*myZoom,
      d= D*1*myZoom,
      w1= W1*1*myZoom,
      r=R*1*myZoom,
      rd=r/Math.sqrt(2),
        x0 = X*1*myZoom,
        y0 = Y*1*myZoom;
        /*
        
   w= W*1,
      h= H*1,
      d= D*1,
      w1= W1*1,
      r=R*1,
      rd=r/Math.sqrt(2),
        x0 = X*1,
        y0 = Y*1;
        */
        if(d!==0){
         var TxtD="∮"+d;
        }
        if(r!==0){
         var TxtR="R="+R;
        }
        if(h!==0){
         var TxtH=H;
        }
        if(w!==0){
         var TxtW=W;
        }
        if(w1!==0){
         var TxtW1=W1;
        }
        if(x0!==0){          
        var TxtX=X;
        }
        if(y0!==0){          
         var TxtY=Y;
        }
  //在A区域 计算孔的位置       
  var xa=x0,ya=glassH * 1-y0,
  //B区域//只有Y标注没有X标注 
  xb=glassW * 1/2,yb=glassH * 1-y0,
  //C区域
  xc=glassW * 1-x0,yc=glassH * 1-y0,
  //D区域
  //只有X标注没有Y标注 
  xd=x0,yd=glassH * 1/2,
  //E区域
  //只有X标注没有Y标注 
  xe=glassW * 1/2,ye=glassH * 1/2,
  //F区域
  //只有X标注没有Y标注 
  xf=glassW * 1-x0,yf=glassH * 1/2,
  //在G区域 计算孔的位置
  xg=x0,yg=y0,
  //在H区域 计算孔的位置
  //只标注Y
  xh=glassW*1/2,yh=y0,
  //在I区域 计算孔的位置
  xi=glassW*1-x0,yi=y0;
  if(area==1){
    //画缺 A位置
        //6、铜夹 A位置        
        var myCcgGapARoundRectangle=new makerjs.models.RoundRectangle(w+r, h, r); 
        myCcgGapARoundRectangle.origin=[xa-r,ya-h];      
    var myCcgGapARoundHoles= new makerjs.models.Holes(d/2, [[xa, ya]]);  
    myCcgGapARoundHoles.origin=[w1,-h/2];   
    var myCcgGapA=makerjs.model.combineUnion(myCcgGapARoundRectangle, myCcgGapARoundHoles);      
  //    //画6、铜夹标记 A位置 
  var myCcgGapAMakerW = new makerjs.models.Square(0); //W的起始点       
  myCcgGapAMakerW.origin = [xa,ya+y0];
  var myCcgGapAMakerH = new makerjs.models.Square(0); //H的起始点        
  myCcgGapAMakerH.origin = [xa,ya+y0];
  var myCcgGapAMakerY = new makerjs.models.Square(0); //Y的起始点        
  myCcgGapAMakerY.origin = [xa,ya+y0];
  var myCcgGapAMakerD = new makerjs.models.Square(0); //Y的起始点        
  myCcgGapAMakerD.origin = [xa,ya+y0];
  var myCcgGapAMakerR = new makerjs.models.Square(0); //Y的起始点        
  myCcgGapAMakerR.origin = [xa,ya+y0];
  var myCcgGapAMakerW1 = new makerjs.models.Square(0); //Y的起始点        
  myCcgGapAMakerW1.origin = [xa,ya+y0];
  /*****************************************************************************/
  makerjs.model.addPath(myCcgGapAMakerW, new makerjs.paths.Line([0,0], [0, markShiftFour]));//Y轴加长标线
  makerjs.model.addPath(myCcgGapAMakerW, new makerjs.paths.Line([w,0], [w, markShiftFour]));//Y轴短标线
  makerjs.model.addPath(myCcgGapAMakerW, new makerjs.paths.Line([w1,0], [w1, -h/2-y0]));//Y轴短标线
  makerjs.model.addPath(myCcgGapAMakerW, new makerjs.paths.Line([0,0], [0-markShiftFour, 0]));//X轴加长标线
  makerjs.model.addPath(myCcgGapAMakerW, new makerjs.paths.Line([0,0-y0], [0-markShiftFour, 0-y0]));//X轴方向短线
  makerjs.model.addPath(myCcgGapAMakerW, new makerjs.paths.Line([0,0-y0-h], [0-markShiftFour, 0-y0-h]));//X轴方向短线
  
  makerjs.model.addPath(myCcgGapAMakerH, new makerjs.paths.Line([0-markShiftTwo,0-y0], [0-markShiftTwo, 0-y0-h]));//H相关 Y轴方向短线
  makerjs.model.addCaption(myCcgGapAMakerH,TxtH, [0-markShiftThree, 0-y0-h],[0-markShiftFour,0-y0] );//H文字标注
  
  makerjs.model.addPath(myCcgGapAMakerY, new makerjs.paths.Line([0-markShiftTwo,0], [0-markShiftTwo, 0-y0]));//Y轴方向标注短线 
  makerjs.model.addCaption(myCcgGapAMakerY,TxtY, [0-markShiftFour, 0-y0],[0-markShiftFour,0] );//Y文字标注
  /*****************************************************************************/
  /*****************************************************************************/
  makerjs.model.addPath(myCcgGapAMakerW, new makerjs.paths.Line([0,markShiftTwo], [0+w, markShiftTwo]));//W短线标注
  makerjs.model.addCaption(myCcgGapAMakerW, TxtW, [0,markShiftFour], [0+w, markShiftFour]);//W文字标注
  /*****************************************************************************/ 
  /*****************************************************************************/
  makerjs.model.addPath(myCcgGapAMakerW1, new makerjs.paths.Line([0,-markShiftTwo], [0+w1, -markShiftTwo]));//W1短线标注
  makerjs.model.addCaption(myCcgGapAMakerW1, TxtW1, [0,-markShiftFour], [0+w1, -markShiftFour]);//W1文字标注
  /*****************************************************************************/ 
  /*****************************************************************************/
  makerjs.model.addPath(myCcgGapAMakerD, new makerjs.paths.Line([w1,-h/2-y0], [w1+d, -h/2-d-y0]));////D短线标注直径标线
  makerjs.model.addCaption(myCcgGapAMakerD, TxtD, [w1,-h/2-y0], [w1+d+markHolesOneD, -h/2-d-y0-markHolesOneD]);///D文字标注直径文字标注
  /*****************************************************************************/ 
  makerjs.model.addPath(myCcgGapAMakerR, new makerjs.paths.Line([w-r,-y0-h+r], [w-r+rd,-y0-h+r-rd]));////R短线标注直径标线
  makerjs.model.addCaption(myCcgGapAMakerR, TxtR, [w-r+markHolesOneD,-y0-h+r+r-markHolesOneD], [w-r+rd+r+markHolesOneD, -y0-h+r-rd-r-markHolesOneD]);///R文字标注直径文字标注
  /*****************************************************************************/ 
  plate.models["myCcgGapA"+myHolesNum]=myCcgGapA;
  markers.models["myCcgGapA"+myHolesNum]=myCcgGapA;
  markers.models["myCcgGapAMakerW"+myHolesNum]=myCcgGapAMakerW;
  markers.models["myCcgGapAMakerH"+myHolesNum]=myCcgGapAMakerH;
  markers.models["myCcgGapAMakerY"+myHolesNum]=myCcgGapAMakerY;
  markers.models["myCcgGapAMakerD"+myHolesNum]=myCcgGapAMakerD;
  markers.models["myCcgGapAMakerR"+myHolesNum]=myCcgGapAMakerR;
  markers.models["myCcgGapAMakerW1"+myHolesNum]=myCcgGapAMakerW1;
  /*****************************************************************************/
  makerjs.model.combineSubtraction(markers.models.myRect, markers.models["myCcgGapA"+myHolesNum]);
  console.log("markers==",markers);
  /*****************************************************************************/ 
  /*****************************************************************************/ 
      
  }else if(area==2){
          
  }else if(area==3){
  //画缺 C位置
        //6、铜夹 C位置        
        var myCcgGapCRoundRectangle=new makerjs.models.RoundRectangle(w+r, h, r); 
        myCcgGapCRoundRectangle.origin=[xc-w,yc-h];      
    var myCcgGapCRoundHoles= new makerjs.models.Holes(d/2, [[xc, yc]]);  
    myCcgGapCRoundHoles.origin=[-w1,-h/2];   
    var myCcgGapC=makerjs.model.combineUnion(myCcgGapCRoundRectangle, myCcgGapCRoundHoles);      
  //    //画6、铜夹标记 C位置 
  var myCcgGapCMakerW = new makerjs.models.Square(0); //W的起始点       
  myCcgGapCMakerW.origin = [xc,yc+y0];
  var myCcgGapCMakerH = new makerjs.models.Square(0); //H的起始点        
  myCcgGapCMakerH.origin = [xc,yc+y0];
  var myCcgGapCMakerY = new makerjs.models.Square(0); //Y的起始点        
  myCcgGapCMakerY.origin = [xc,yc+y0];
  var myCcgGapCMakerD = new makerjs.models.Square(0); //Y的起始点        
  myCcgGapCMakerD.origin = [xc,yc+y0];
  var myCcgGapCMakerR = new makerjs.models.Square(0); //Y的起始点        
  myCcgGapCMakerR.origin = [xc,yc+y0];
  var myCcgGapCMakerW1 = new makerjs.models.Square(0); //Y的起始点        
  myCcgGapCMakerW1.origin = [xc,yc+y0];
  // /*****************************************************************************/
  makerjs.model.addPath(myCcgGapCMakerW, new makerjs.paths.Line([0,0], [0, markShiftFour]));//Y轴加长标线
  makerjs.model.addPath(myCcgGapCMakerW, new makerjs.paths.Line([-w,0], [-w, markShiftFour]));//Y轴短标线
  makerjs.model.addPath(myCcgGapCMakerW, new makerjs.paths.Line([-w1,0], [-w1, -h/2-y0]));//Y轴短标线
  makerjs.model.addPath(myCcgGapCMakerW, new makerjs.paths.Line([0,0], [0+markShiftFour, 0]));//X轴加长标线
  makerjs.model.addPath(myCcgGapCMakerW, new makerjs.paths.Line([0,0-y0], [0+markShiftFour, 0-y0]));//X轴方向短线
  makerjs.model.addPath(myCcgGapCMakerW, new makerjs.paths.Line([0,0-y0-h], [0+markShiftFour, 0-y0-h]));//X轴方向短线
  
  makerjs.model.addPath(myCcgGapCMakerH, new makerjs.paths.Line([0+markShiftTwo,0-y0], [0+markShiftTwo, 0-y0-h]));//H相关 Y轴方向短线
  makerjs.model.addCaption(myCcgGapCMakerH,TxtH, [0+markShiftThree, 0-y0-h],[0+markShiftFour,0-y0] );//H文字标注
  
  makerjs.model.addPath(myCcgGapCMakerY, new makerjs.paths.Line([0+markShiftTwo,0], [0+markShiftTwo, 0-y0]));//Y轴方向标注短线 
  makerjs.model.addCaption(myCcgGapCMakerY,TxtY, [0+markShiftFour, 0-y0],[0+markShiftFour,0] );//Y文字标注
  // /*****************************************************************************/
  // /*****************************************************************************/
  makerjs.model.addPath(myCcgGapCMakerW, new makerjs.paths.Line([0,markShiftTwo], [0-w, markShiftTwo]));//W短线标注
  makerjs.model.addCaption(myCcgGapCMakerW, TxtW, [0-w, markShiftFour], [0,markShiftFour]);//W文字标注
  // /*****************************************************************************/ 
  // /*****************************************************************************/
  makerjs.model.addPath(myCcgGapCMakerW1, new makerjs.paths.Line([0,-markShiftTwo], [0-w1, -markShiftTwo]));//W1短线标注
  makerjs.model.addCaption(myCcgGapCMakerW1, TxtW1, [0-w1, -markShiftFour], [0,-markShiftFour]);//W1文字标注
  // /*****************************************************************************/ 
  // /*****************************************************************************/
  makerjs.model.addPath(myCcgGapCMakerD, new makerjs.paths.Line([-w1,-h/2-y0], [-w1-d, -h/2-d-y0]));////D短线标注直径标线
  makerjs.model.addCaption(myCcgGapCMakerD, TxtD, [-w1-d-markHolesOneD, -h/2-d-y0-markHolesOneD], [-w1,-h/2-y0]);///D文字标注直径文字标注
  // /*****************************************************************************/ 
  makerjs.model.addPath(myCcgGapCMakerR, new makerjs.paths.Line([-w+r,-y0-h+r], [-w+r-rd,-y0-h+r-rd]));////R短线标注直径标线
  makerjs.model.addCaption(myCcgGapCMakerR, TxtR, [-w+r-rd-r-markHolesOneD, -y0-h+r-rd-r-markHolesOneD], [-w+r-markHolesOneD,-y0-h+r+r-markHolesOneD]);///R文字标注直径文字标注
  // /*****************************************************************************/ 
  plate.models["myCcgGapC"+myHolesNum]=myCcgGapC;
  markers.models["myCcgGapC"+myHolesNum]=myCcgGapC;
  markers.models["myCcgGapCMakerW"+myHolesNum]=myCcgGapCMakerW;
  markers.models["myCcgGapCMakerH"+myHolesNum]=myCcgGapCMakerH;
  markers.models["myCcgGapCMakerY"+myHolesNum]=myCcgGapCMakerY;
  markers.models["myCcgGapCMakerD"+myHolesNum]=myCcgGapCMakerD;
  markers.models["myCcgGapCMakerR"+myHolesNum]=myCcgGapCMakerR;
  markers.models["myCcgGapCMakerW1"+myHolesNum]=myCcgGapCMakerW1;
  /*****************************************************************************/
  makerjs.model.combineSubtraction(markers.models.myRect, markers.models["myCcgGapC"+myHolesNum]);
  console.log("markers==",markers);
  /*****************************************************************************/ 
  /*****************************************************************************/ 
    
  }else if(area==4){
  //画缺 D位置
        //6、铜夹 D位置        
        var myCcgGapDRoundRectangle=new makerjs.models.RoundRectangle(w+r, h, r); 
        myCcgGapDRoundRectangle.origin=[xd-r,yd-h/2];      
    var myCcgGapDRoundHoles= new makerjs.models.Holes(d/2, [[xd, yd]]);  
     myCcgGapDRoundHoles.origin=[w1,0];   
    var myCcgGapD=makerjs.model.combineUnion(myCcgGapDRoundRectangle, myCcgGapDRoundHoles);      
  //    //画6、铜夹标记 D位置 
  var myCcgGapDMakerW = new makerjs.models.Square(0); //W的起始点       
  myCcgGapDMakerW.origin = [xd,yd];
  var myCcgGapDMakerH = new makerjs.models.Square(0); //H的起始点        
  myCcgGapDMakerH.origin = [xd,yd];
  var myCcgGapDMakerY = new makerjs.models.Square(0); //Y的起始点        
  myCcgGapDMakerY.origin = [xd,yd];
  var myCcgGapDMakerD = new makerjs.models.Square(0); //Y的起始点        
  myCcgGapDMakerD.origin = [xd,yd];
  var myCcgGapDMakerR = new makerjs.models.Square(0); //Y的起始点        
  myCcgGapDMakerR.origin = [xd,yd];
  var myCcgGapDMakerW1 = new makerjs.models.Square(0); //Y的起始点        
  myCcgGapDMakerW1.origin = [xd,yd];
  /*****************************************************************************/
  makerjs.model.addPath(myCcgGapDMakerW, new makerjs.paths.Line([0,yd], [0, yd+markShiftFour]));//Y轴加长标线
  makerjs.model.addPath(myCcgGapDMakerW, new makerjs.paths.Line([w,yd], [w, yd+markShiftFour]));//Y轴短标线
  makerjs.model.addPath(myCcgGapDMakerW, new makerjs.paths.Line([w1,0], [w1, yd]));//Y轴短标线
  
  makerjs.model.addPath(myCcgGapDMakerH, new makerjs.paths.Line([0+markShiftTwo,h/2], [0+markShiftTwo, -h/2]));//H相关 Y轴方向短线
  makerjs.model.addCaption(myCcgGapDMakerH,TxtH,[0+markShiftFour,-h/2], [0+markShiftFour, h/2] );//H文字标注
  
  makerjs.model.addPath(myCcgGapDMakerY, new makerjs.paths.Line([0-markShiftTwo,0], [0-markShiftTwo, 0]));//Y轴方向标注短线 
  makerjs.model.addCaption(myCcgGapDMakerY,TxtY, [0-markShiftFour, 0],[0-markShiftFour,0] );//Y文字标注
  /*****************************************************************************/
  /*****************************************************************************/
  makerjs.model.addPath(myCcgGapDMakerW, new makerjs.paths.Line([0,yd+markShiftTwo], [0+w, yd+markShiftTwo]));//W短线标注
  makerjs.model.addCaption(myCcgGapDMakerW, TxtW, [0,yd+markShiftFour], [0+w, yd+markShiftFour]);//W文字标注
  /*****************************************************************************/ 
  /*****************************************************************************/
  makerjs.model.addPath(myCcgGapDMakerW1, new makerjs.paths.Line([0,yd-markShiftTwo], [0+w1, yd-markShiftTwo]));//W1短线标注
  makerjs.model.addCaption(myCcgGapDMakerW1, TxtW1, [0,yd-markShiftFour], [0+w1, yd-markShiftFour]);//W1文字标注
  /*****************************************************************************/ 
  /*****************************************************************************/
  makerjs.model.addPath(myCcgGapDMakerD, new makerjs.paths.Line([w1,0], [w1+d, -d]));////D短线标注直径标线
  makerjs.model.addCaption(myCcgGapDMakerD, TxtD, [w1,0], [w1+d+markHolesOneD, -d-markHolesOneD]);///D文字标注直径文字标注
  /*****************************************************************************/ 
  
  makerjs.model.addPath(myCcgGapDMakerR, new makerjs.paths.Line([w-r,-y0-h/2+r], [w-r+rd,-y0-h/2+r-rd]));////R短线标注直径标线
  makerjs.model.addCaption(myCcgGapDMakerR, TxtR, [w-r+markHolesOneD,-y0-h/2+r-markHolesOneD], [w-r+rd+r+markHolesOneD, -y0-h/2+r-rd-markHolesOneD]);///R文字标注直径文字标注
  /*****************************************************************************/ 
  plate.models["myCcgGapD"+myHolesNum]=myCcgGapD;
  markers.models["myCcgGapD"+myHolesNum]=myCcgGapD;
  markers.models["myCcgGapDMakerW"+myHolesNum]=myCcgGapDMakerW;
  markers.models["myCcgGapDMakerH"+myHolesNum]=myCcgGapDMakerH;
  markers.models["myCcgGapDMakerY"+myHolesNum]=myCcgGapDMakerY;
  markers.models["myCcgGapDMakerD"+myHolesNum]=myCcgGapDMakerD;
  markers.models["myCcgGapDMakerR"+myHolesNum]=myCcgGapDMakerR;
  markers.models["myCcgGapDMakerW1"+myHolesNum]=myCcgGapDMakerW1;
  /*****************************************************************************/
  makerjs.model.combineSubtraction(markers.models.myRect, markers.models["myCcgGapD"+myHolesNum]);
  console.log("markers==",markers);
  /*****************************************************************************/ 
  /*****************************************************************************/ 
          
  }else if(area==5){
          
  }else if(area==6){
   //画缺 F位置
        //6、铜夹 F位置        
        var myCcgGapFRoundRectangle=new makerjs.models.RoundRectangle(w+r, h, r); 
        myCcgGapFRoundRectangle.origin=[xf-w,yf-h/2];      
    var myCcgGapFRoundHoles= new makerjs.models.Holes(d/2, [[xf, yf]]);  
    myCcgGapFRoundHoles.origin=[-w1,0];   
    var myCcgGapF=makerjs.model.combineUnion(myCcgGapFRoundRectangle, myCcgGapFRoundHoles);      
  //    //画6、铜夹标记 C位置 
  var myCcgGapFMakerW = new makerjs.models.Square(0); //W的起始点       
  myCcgGapFMakerW.origin = [xf,yf];
  var myCcgGapFMakerH = new makerjs.models.Square(0); //H的起始点        
  myCcgGapFMakerH.origin = [xf,yf];
  var myCcgGapFMakerY = new makerjs.models.Square(0); //Y的起始点        
  myCcgGapFMakerY.origin = [xf,yf];
  var myCcgGapFMakerD = new makerjs.models.Square(0); //Y的起始点        
  myCcgGapFMakerD.origin = [xf,yf];
  var myCcgGapFMakerR = new makerjs.models.Square(0); //Y的起始点        
  myCcgGapFMakerR.origin = [xf,yf];
  var myCcgGapFMakerW1 = new makerjs.models.Square(0); //Y的起始点        
  myCcgGapFMakerW1.origin = [xf,yf];
  
  // /*****************************************************************************/
  makerjs.model.addPath(myCcgGapFMakerW, new makerjs.paths.Line([0,yf], [0, yf+markShiftFour]));//Y轴加长标线
  makerjs.model.addPath(myCcgGapFMakerW, new makerjs.paths.Line([-w,yf], [-w, yf+markShiftFour]));//Y轴短标线
  makerjs.model.addPath(myCcgGapFMakerW, new makerjs.paths.Line([-w1,0], [-w1, yf]));//Y轴短标线
  makerjs.model.addPath(myCcgGapFMakerW, new makerjs.paths.Line([0,h/2], [0+markShiftFour, h/2]));//X轴加长标线
  makerjs.model.addPath(myCcgGapFMakerW, new makerjs.paths.Line([0,0-h/2], [0+markShiftFour, 0-h/2]));//X轴方向短线
  
  makerjs.model.addPath(myCcgGapFMakerH, new makerjs.paths.Line([0+markShiftTwo,h/2], [0+markShiftTwo, -h/2]));//H相关 Y轴方向短线
  makerjs.model.addCaption(myCcgGapFMakerH,TxtH,[0+markShiftFour,-h/2], [0+markShiftThree, h/2] );//H文字标注
  
  // /*****************************************************************************/
  // /*****************************************************************************/
  makerjs.model.addPath(myCcgGapFMakerW, new makerjs.paths.Line([0,yf+markShiftTwo], [0-w, yf+markShiftTwo]));//W短线标注
  makerjs.model.addCaption(myCcgGapFMakerW, TxtW, [0-w, yf+markShiftFour], [0,yf+markShiftFour]);//W文字标注
  // /*****************************************************************************/ 
  // /*****************************************************************************/
  makerjs.model.addPath(myCcgGapFMakerW1, new makerjs.paths.Line([0,yf-markShiftTwo], [0-w1, yf-markShiftTwo]));//W1短线标注
  makerjs.model.addCaption(myCcgGapFMakerW1, TxtW1, [0-w1, yf-markShiftFour], [0,yf-markShiftFour]);//W1文字标注
  // /*****************************************************************************/ 
  // /*****************************************************************************/
  makerjs.model.addPath(myCcgGapFMakerD, new makerjs.paths.Line([-w1,0], [-w1-d, -d]));////D短线标注直径标线
  makerjs.model.addCaption(myCcgGapFMakerD, TxtD, [-w1-d-markHolesOneD, -d-markHolesOneD], [-w1,0]);///D文字标注直径文字标注
  // /*****************************************************************************/ 
  makerjs.model.addPath(myCcgGapFMakerR, new makerjs.paths.Line([-w+r,-h/2+r], [-w+r-rd,-h/2+r-rd]));////R短线标注直径标线
  makerjs.model.addCaption(myCcgGapFMakerR, TxtR, [-w+r-rd-r-markHolesOneD, -h/2+r-rd-r-markHolesOneD], [-w+r-markHolesOneD,-h/2+r+r-markHolesOneD]);///R文字标注直径文字标注
  // /*****************************************************************************/ 
  markers.models["myCcgGapF"+myHolesNum]=myCcgGapF;
  markers.models["myCcgGapFMakerW"+myHolesNum]=myCcgGapFMakerW;
  markers.models["myCcgGapFMakerH"+myHolesNum]=myCcgGapFMakerH;
  markers.models["myCcgGapFMakerY"+myHolesNum]=myCcgGapFMakerY;
  markers.models["myCcgGapFMakerD"+myHolesNum]=myCcgGapFMakerD;
  markers.models["myCcgGapFMakerR"+myHolesNum]=myCcgGapFMakerR;
  markers.models["myCcgGapFMakerW1"+myHolesNum]=myCcgGapFMakerW1;
  /*****************************************************************************/
  makerjs.model.combineSubtraction(markers.models.myRect, markers.models["myCcgGapF"+myHolesNum]);
  console.log("markers==",markers);
  /*****************************************************************************/ 
  /*****************************************************************************/ 
       
  }else if(area==7){
   //画缺 G位置
        //6、铜夹 G位置        
        var myCcgGapGRoundRectangle=new makerjs.models.RoundRectangle(w+r, h, r); 
        myCcgGapGRoundRectangle.origin=[xg-r,yg];      
    var myCcgGapGRoundHoles= new makerjs.models.Holes(d/2, [[xg, yg]]);  
    myCcgGapGRoundHoles.origin=[w1,h/2];   
    var myCcgGapG=makerjs.model.combineUnion(myCcgGapGRoundRectangle, myCcgGapGRoundHoles);      
  //    //画6、铜夹标记 G位置 
  var myCcgGapGMakerW = new makerjs.models.Square(0); //W的起始点       
  myCcgGapGMakerW.origin = [xg,yg-y0];
  var myCcgGapGMakerH = new makerjs.models.Square(0); //H的起始点        
  myCcgGapGMakerH.origin = [xg,yg-y0];
  var myCcgGapGMakerY = new makerjs.models.Square(0); //Y的起始点        
  myCcgGapGMakerY.origin = [xg,yg-y0];
  var myCcgGapGMakerD = new makerjs.models.Square(0); //Y的起始点        
  myCcgGapGMakerD.origin = [xg,yg-y0];
  var myCcgGapGMakerR = new makerjs.models.Square(0); //Y的起始点        
  myCcgGapGMakerR.origin = [xg,yg-y0];
  var myCcgGapGMakerW1 = new makerjs.models.Square(0); //Y的起始点        
  myCcgGapGMakerW1.origin = [xg,yg-y0];
  /*****************************************************************************/
  makerjs.model.addPath(myCcgGapGMakerW, new makerjs.paths.Line([0,0], [0, -markShiftFour]));//Y轴加长标线
  makerjs.model.addPath(myCcgGapGMakerW, new makerjs.paths.Line([w,0], [w, -markShiftFour]));//Y轴短标线
  makerjs.model.addPath(myCcgGapGMakerW, new makerjs.paths.Line([w1,0], [w1, h/2+y0]));//Y轴短标线
  makerjs.model.addPath(myCcgGapGMakerW, new makerjs.paths.Line([0,0], [0-markShiftFour, 0]));//X轴加长标线
  makerjs.model.addPath(myCcgGapGMakerW, new makerjs.paths.Line([0,0+y0], [0-markShiftFour, 0+y0]));//X轴方向短线
  makerjs.model.addPath(myCcgGapGMakerW, new makerjs.paths.Line([0,0+y0+h], [0-markShiftFour, 0+y0+h]));//X轴方向短线
  
  makerjs.model.addPath(myCcgGapGMakerH, new makerjs.paths.Line([0-markShiftTwo,0+y0], [0-markShiftTwo, 0+y0+h]));//H相关 Y轴方向短线
  makerjs.model.addCaption(myCcgGapGMakerH,TxtH, [0-markShiftThree, 0+y0+h],[0-markShiftFour,0+y0] );//H文字标注
  
  makerjs.model.addPath(myCcgGapGMakerY, new makerjs.paths.Line([0-markShiftTwo,0], [0-markShiftTwo, 0+y0]));//Y轴方向标注短线 
  makerjs.model.addCaption(myCcgGapGMakerY,TxtY, [0-markShiftFour, 0+y0],[0-markShiftFour,0] );//Y文字标注
  /*****************************************************************************/
  /*****************************************************************************/
  makerjs.model.addPath(myCcgGapGMakerW, new makerjs.paths.Line([0,-markShiftTwo], [0+w, -markShiftTwo]));//W短线标注
  makerjs.model.addCaption(myCcgGapGMakerW, TxtW, [0,-markShiftFour], [0+w, -markShiftFour]);//W文字标注
  /*****************************************************************************/ 
  /*****************************************************************************/
  makerjs.model.addPath(myCcgGapGMakerW1, new makerjs.paths.Line([0,markShiftTwo], [0+w1, markShiftTwo]));//W1短线标注
  makerjs.model.addCaption(myCcgGapGMakerW1, TxtW1, [0,markShiftFour], [0+w1, markShiftFour]);//W1文字标注
  /*****************************************************************************/ 
  /*****************************************************************************/
  makerjs.model.addPath(myCcgGapGMakerD, new makerjs.paths.Line([w1,h/2+y0], [w1+d, h/2+d+y0]));////D短线标注直径标线
  makerjs.model.addCaption(myCcgGapGMakerD, TxtD, [w1,h/2+y0], [w1+d+markHolesOneD, h/2+d+y0+markHolesOneD]);///D文字标注直径文字标注
  /*****************************************************************************/ 
  makerjs.model.addPath(myCcgGapGMakerR, new makerjs.paths.Line([w-r,y0+h-r], [w-r+rd,y0+hr+rd]));////R短线标注直径标线
  makerjs.model.addCaption(myCcgGapGMakerR, TxtR, [w-r+markHolesOneD,y0+h+r+r-markHolesOneD], [w-r+rd+r+markHolesOneD, -y0-h+r-rd-r-markHolesOneD]);///R文字标注直径文字标注
  /*****************************************************************************/ 
  plate.models["myCcgGapG"+myHolesNum]=myCcgGapG;
  markers.models["myCcgGapG"+myHolesNum]=myCcgGapG;
  markers.models["myCcgGapGMakerW"+myHolesNum]=myCcgGapGMakerW;
  markers.models["myCcgGapGMakerH"+myHolesNum]=myCcgGapGMakerH;
  markers.models["myCcgGapGMakerY"+myHolesNum]=myCcgGapGMakerY;
  markers.models["myCcgGapGMakerD"+myHolesNum]=myCcgGapGMakerD;
  markers.models["myCcgGapGMakerR"+myHolesNum]=myCcgGapGMakerR;
  markers.models["myCcgGapGMakerW1"+myHolesNum]=myCcgGapGMakerW1;
  /*****************************************************************************/
  makerjs.model.combineSubtraction(markers.models.myRect, markers.models["myCcgGapG"+myHolesNum]);
  console.log("markers==",markers);
  /*****************************************************************************/ 
  /*****************************************************************************/ 
             
  }else if(area==8){
          
  }else if(area==9){
  //画缺 I位置
        //6、铜夹 I位置        
        var myCcgGapIRoundRectangle=new makerjs.models.RoundRectangle(w+r, h, r); 
        myCcgGapIRoundRectangle.origin=[xi-w,yi];      
    var myCcgGapIRoundHoles= new makerjs.models.Holes(d/2, [[xi, yi]]);  
    myCcgGapIRoundHoles.origin=[-w1,h/2];   
    var myCcgGapI=makerjs.model.combineUnion(myCcgGapIRoundRectangle, myCcgGapIRoundHoles);      
  //    //画6、铜夹标记 I位置 
  var myCcgGapIMakerW = new makerjs.models.Square(0); //W的起始点       
  myCcgGapIMakerW.origin = [xi,yi-y0];
  var myCcgGapIMakerH = new makerjs.models.Square(0); //H的起始点        
  myCcgGapIMakerH.origin = [xi,yi-y0];
  var myCcgGapIMakerY = new makerjs.models.Square(0); //Y的起始点        
  myCcgGapIMakerY.origin = [xi,yi-y0];
  var myCcgGapIMakerD = new makerjs.models.Square(0); //Y的起始点        
  myCcgGapIMakerD.origin = [xi,yi-y0];
  var myCcgGapIMakerR = new makerjs.models.Square(0); //Y的起始点        
  myCcgGapIMakerR.origin = [xi,yi-y0];
  var myCcgGapIMakerW1 = new makerjs.models.Square(0); //Y的起始点        
  myCcgGapIMakerW1.origin = [xi,yi-y0];
  // /*****************************************************************************/
  makerjs.model.addPath(myCcgGapIMakerW, new makerjs.paths.Line([0,0], [0, -markShiftFour]));//Y轴加长标线
  makerjs.model.addPath(myCcgGapIMakerW, new makerjs.paths.Line([-w,0], [-w, -markShiftFour]));//Y轴短标线
  makerjs.model.addPath(myCcgGapIMakerW, new makerjs.paths.Line([-w1,0], [-w1, h/2+y0]));//Y轴短标线
  makerjs.model.addPath(myCcgGapIMakerW, new makerjs.paths.Line([0,0], [0+markShiftFour, 0]));//X轴加长标线
  makerjs.model.addPath(myCcgGapIMakerW, new makerjs.paths.Line([0,0+y0], [0+markShiftFour, 0+y0]));//X轴方向短线
  makerjs.model.addPath(myCcgGapIMakerW, new makerjs.paths.Line([0,0+y0+h], [0+markShiftFour, 0+y0+h]));//X轴方向短线
  
  makerjs.model.addPath(myCcgGapIMakerH, new makerjs.paths.Line([0+markShiftTwo,0+y0], [0+markShiftTwo, 0+y0+h]));//H相关 Y轴方向短线
  makerjs.model.addCaption(myCcgGapIMakerH,TxtH,[0+markShiftFour,0+y0] , [0+markShiftThree, 0+y0+h]);//H文字标注
  
  makerjs.model.addPath(myCcgGapIMakerY, new makerjs.paths.Line([0+markShiftTwo,0], [0+markShiftTwo, 0+y0]));//Y轴方向标注短线 
  makerjs.model.addCaption(myCcgGapIMakerY,TxtY,[0+markShiftFour,0], [0+markShiftFour, 0+y0] );//Y文字标注
  // /*****************************************************************************/
  // /*****************************************************************************/
  makerjs.model.addPath(myCcgGapIMakerW, new makerjs.paths.Line([0,-markShiftTwo], [0-w, -markShiftTwo]));//W短线标注
  makerjs.model.addCaption(myCcgGapIMakerW, TxtW, [0-w, -markShiftFour], [0,-markShiftFour]);//W文字标注
  // /*****************************************************************************/ 
  // /*****************************************************************************/
  makerjs.model.addPath(myCcgGapIMakerW1, new makerjs.paths.Line([0,markShiftTwo], [0-w1, markShiftTwo]));//W1短线标注
  makerjs.model.addCaption(myCcgGapIMakerW1, TxtW1, [0-w1, markShiftFour], [0,markShiftFour]);//W1文字标注
  // /*****************************************************************************/ 
  // /*****************************************************************************/
  makerjs.model.addPath(myCcgGapIMakerD, new makerjs.paths.Line([-w1,h/2+y0], [-w1-d, h/2+d+y0]));////D短线标注直径标线
  makerjs.model.addCaption(myCcgGapIMakerD, TxtD, [-w1-d-markHolesOneD, h/2+d+y0+markHolesOneD], [-w1,h/2+y0]);///D文字标注直径文字标注
  // /*****************************************************************************/ 
  makerjs.model.addPath(myCcgGapIMakerR, new makerjs.paths.Line([-w+r,y0+h-r], [-w+r-rd,y0+h-r+rd]));////R短线标注直径标线
  makerjs.model.addCaption(myCcgGapIMakerR, TxtR, [-w+r-rd-r-markHolesOneD, y0+h-r+rd+r+markHolesOneD], [-w+r-markHolesOneD,y0+h-r-r+markHolesOneD]);///R文字标注直径文字标注
  // /*****************************************************************************/ 
  plate.models["myCcgGapI"+myHolesNum]=myCcgGapI;
  markers.models["myCcgGapI"+myHolesNum]=myCcgGapI;
  markers.models["myCcgGapIMakerW"+myHolesNum]=myCcgGapIMakerW;
  markers.models["myCcgGapIMakerH"+myHolesNum]=myCcgGapIMakerH;
  markers.models["myCcgGapIMakerY"+myHolesNum]=myCcgGapIMakerY;
  markers.models["myCcgGapIMakerD"+myHolesNum]=myCcgGapIMakerD;
  markers.models["myCcgGapIMakerR"+myHolesNum]=myCcgGapIMakerR;
  markers.models["myCcgGapIMakerW1"+myHolesNum]=myCcgGapIMakerW1;
  /*****************************************************************************/
  makerjs.model.combineSubtraction(markers.models.myRect, markers.models["myCcgGapI"+myHolesNum]);
  console.log("markers==",markers);
  /*****************************************************************************/ 
  /*****************************************************************************/ 
      
  }

myHolesNum++;
return{plate, markers,myHolesNum}
};


export default CopperClipGap;