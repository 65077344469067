//缺7、长条夹  
import makerjs from "makerjs";
// inputParam,model,myHolesNum,outParam,fixedParam
// (D,X,Y,area,plate,markers,myHolesNum,glassW,glassH,markShift,markShiftTwo,markShiftThree,markShiftFour,markHolesOneD)
function LongClipGap(inputParam,model,myHolesNum,outParam,fixedParam,zoom){
var D=inputParam.D,W=inputParam.W,D1=inputParam.D1,W1=inputParam.W1,X=inputParam.X,Y=inputParam.Y,area=inputParam.area,myZoom=zoom*1,
plate=model.plate,markers=model.markers,
glassW=outParam.glassW,glassH=outParam.glassH,
   markShift=fixedParam.markShift,markShiftTwo=fixedParam.markShiftTwo,
   markShiftThree=fixedParam.markShiftThree,markShiftFour=fixedParam.markShiftFour,markHolesOneD=fixedParam.markHolesOneD,
 
   d = D*1*myZoom,
      d1 = D1*1*myZoom,
      w= W*1*myZoom,
      w1= W1*1*myZoom,
        x0 = X*1*myZoom,
        y0 = Y*1*myZoom;
        /*
        d = D*1,
        d1 = D1*1,
        w= W*1,
        w1= W1*1,
          x0 = X*1,
          y0 = Y*1;
          */
        if(d!==0){
         var TxtD="∮"+D;
        }
        if(d1!==0){
         var TxtD1="∮"+D1;
        }
        if(w!==0){
         var TxtW=W;
        }
        if(w1!==0){
         var TxtW1=W1;
        }
        if(x0!==0){          
        var TxtX=X;
        }
        if(y0!==0){          
         var TxtY=Y;
        }
  //在A区域 计算孔的位置       
  var xa=x0,ya=glassH * 1-y0,
  //B区域//只有Y标注没有X标注 
  xb=glassW * 1/2,yb=glassH * 1-y0,
  //C区域
  xc=glassW * 1-x0,yc=glassH * 1-y0,
  //D区域
  //只有X标注没有Y标注 
  xd=x0,yd=glassH * 1/2,
  //E区域
  //只有X标注没有Y标注 
  xe=glassW * 1/2,ye=glassH * 1/2,
  //F区域
  //只有X标注没有Y标注 
  xf=glassW * 1-x0,yf=glassH * 1/2,
  //在G区域 计算孔的位置
  xg=x0,yg=y0,
  //在H区域 计算孔的位置
  //只标注Y
  xh=glassW*1/2,yh=y0,
  //在I区域 计算孔的位置
  xi=glassW*1-x0,yi=y0;
  if(area==1){
    //画缺 A位置孔
        //画7、长条夹 A位置
   var myLcgGapARoundRectangle = new makerjs.models.RoundRectangle(w+d, d, d)
   myLcgGapARoundRectangle.origin=[xa-d/2,ya-d];
  var myLcgGapARoundHoles= new makerjs.models.Holes(d1/2, [[xa, ya]]);  
  myLcgGapARoundHoles.origin=[w1,-d/2]; 
  var   myLcgGapA=makerjs.model.combineUnion(myLcgGapARoundRectangle, myLcgGapARoundHoles);    
  ////标记U型缺 A位置
  var myLcgGapAMakerW = new makerjs.models.Square(0); //孔的原点       
  myLcgGapAMakerW.origin = [xa,ya];
  var myLcgGapAMakerD = new makerjs.models.Square(0); //孔的原点       
  myLcgGapAMakerD.origin = [xa,ya];
  var myLcgGapAMakerY = new makerjs.models.Square(0); //孔的原点       
  myLcgGapAMakerY.origin = [xa,ya]; 
  var myLcgGapAMakerD1 = new makerjs.models.Square(0); //孔的原点       
  myLcgGapAMakerD1.origin = [xa,ya]; 
  var myLcgGapAMakerW1 = new makerjs.models.Square(0); //孔的原点       
  myLcgGapAMakerW1.origin = [xa,ya]; 
  /*****************************************************************************/
  /*****************************************************************************/  
  makerjs.model.addPath(myLcgGapAMakerD, new makerjs.paths.Line([0,y0], [0, y0+markShiftTwo]));//Y轴加长标线
  makerjs.model.addPath(myLcgGapAMakerD, new makerjs.paths.Line([0+w,0], [0+w, y0+markShiftTwo]));//Y轴方向短线
  makerjs.model.addPath(myLcgGapAMakerD1, new makerjs.paths.Line([0+w1,-d/2], [0+w1, -d-markShiftFour]));//Y轴方向短线
  makerjs.model.addPath(myLcgGapAMakerD, new makerjs.paths.Line([0,y0], [-markShiftThree, y0]));//X轴加长标线
  makerjs.model.addPath(myLcgGapAMakerD, new makerjs.paths.Line([0,0], [-markShiftThree, 0]));//X轴方向短线
  makerjs.model.addPath(myLcgGapAMakerD, new makerjs.paths.Line([0,0-d], [-markShiftThree, 0-d]));//X轴方向短线
  
  makerjs.model.addPath(myLcgGapAMakerD, new makerjs.paths.Line([0-markShiftTwo,0-d], [0-markShiftTwo, 0]));//D轴方向标注短线 
  makerjs.model.addCaption(myLcgGapAMakerD,TxtD, [0-markShiftThree,0-d], [0-markShiftThree, 0]);//D文字标注
  /*****************************************************************************/
  makerjs.model.addPath(myLcgGapAMakerY, new makerjs.paths.Line([0-markShiftTwo,0], [0-markShiftTwo, 0+y0]));//Y轴方向标注短线 
  makerjs.model.addCaption(myLcgGapAMakerY,TxtY, [0-markShiftThree,0], [0-markShiftThree, 0+y0]);//Y文字标注
  /*****************************************************************************/
  makerjs.model.addPath(myLcgGapAMakerW, new makerjs.paths.Line([0,y0+markShift/2], [0+w, y0+markShift/2]));//W短线标注
  makerjs.model.addCaption(myLcgGapAMakerW, TxtW, [0,y0+markShift], [0+w, y0+markShift]);//W文字标注
  /*****************************************************************************/
  makerjs.model.addPath(myLcgGapAMakerW1, new makerjs.paths.Line([0,-markShift-d], [0+w1, -markShift-d]));//W1短线标注
  makerjs.model.addCaption(myLcgGapAMakerW1, TxtW1, [0,-markShiftThree-d], [0+w1, -markShiftThree-d]);//W1文字标注
  /*****************************************************************************/
  /*****************************************************************************/   
  makerjs.model.addPath(myLcgGapAMakerD1, new makerjs.paths.Line([w1,-d/2], [w1+d1, -d/2-d1]));//D1短线标注
  makerjs.model.addCaption(myLcgGapAMakerD1, TxtD1,[w1,-d/2], [w1+d1+markHolesOneD, -d/2-d1-markHolesOneD]);//D1文字标注
  /*****************************************************************************/ 
  plate.models["myLcgGapA"+myHolesNum]=myLcgGapA;
  markers.models["myLcgGapA"+myHolesNum]=myLcgGapA;
  markers.models["myLcgGapAMakerD"+myHolesNum]=myLcgGapAMakerD;
  markers.models["myLcgGapAMakerY"+myHolesNum]=myLcgGapAMakerY;
  markers.models["myLcgGapAMakerW"+myHolesNum]=myLcgGapAMakerW;
  markers.models["myLcgGapAMakerD1"+myHolesNum]=myLcgGapAMakerD1;
  markers.models["myLcgGapAMakerW1"+myHolesNum]=myLcgGapAMakerW1;
  /*****************************************************************************/
  makerjs.model.combineSubtraction(markers.models.myRect, markers.models["myLcgGapA"+myHolesNum]);
  console.log("markers==",markers);
  /*****************************************************************************/ 
  /*****************************************************************************/ 
    
        }else if(area==2){
          
        }else if(area==3){        
        //画7、长条夹 C位置
   var myLcgGapCRoundRectangle = new makerjs.models.RoundRectangle(w+d, d, d);
   myLcgGapCRoundRectangle.origin=[xc-w-d/2,yc-d];
  var myLcgGapCRoundHoles= new makerjs.models.Holes(d1/2, [[xc, yc]]);  
  myLcgGapCRoundHoles.origin=[-w1,-d/2]; 
  var   myLcgGapC=makerjs.model.combineUnion(myLcgGapCRoundRectangle, myLcgGapCRoundHoles); 
  ////标记7、长条夹 C位置
  var myLcgGapCMakerD = new makerjs.models.Square(0); //孔的原点       
  myLcgGapCMakerD.origin = [xc,yc];
  var myLcgGapCMakerY = new makerjs.models.Square(0); //孔的原点       
  myLcgGapCMakerY.origin = [xc,yc];
  var myLcgGapCMakerW = new makerjs.models.Square(0); //孔的原点       
  myLcgGapCMakerW.origin = [xc,yc];  
  var myLcgGapCMakerD1 = new makerjs.models.Square(0); //孔的原点       
  myLcgGapCMakerD1.origin = [xc,yc]; 
  var myLcgGapCMakerW1 = new makerjs.models.Square(0); //孔的原点       
  myLcgGapCMakerW1.origin = [xc,yc];   
  /*****************************************************************************/
  /*****************************************************************************/  
  
  makerjs.model.addPath(myLcgGapCMakerD, new makerjs.paths.Line([0,y0], [0, y0+markShiftTwo]));//Y轴加长标线
  makerjs.model.addPath(myLcgGapCMakerD, new makerjs.paths.Line([0-w,0], [0-w, y0+markShiftTwo]));//Y轴方向短线
  makerjs.model.addPath(myLcgGapCMakerD, new makerjs.paths.Line([0,y0], [0+markShiftThree, y0]));//X轴加长标线
  makerjs.model.addPath(myLcgGapCMakerD, new makerjs.paths.Line([0,0], [0+markShiftThree, 0]));//X轴方向短线
  makerjs.model.addPath(myLcgGapCMakerD, new makerjs.paths.Line([0,0-d], [0+markShiftThree, 0-d]));//X轴方向短线
  makerjs.model.addPath(myLcgGapCMakerD1, new makerjs.paths.Line([0-w1,-d/2], [0-w1, -d-markShiftFour]));//Y轴方向短线
  
  
  makerjs.model.addPath(myLcgGapCMakerD, new makerjs.paths.Line([0-markShiftTwo+d/2,0-d], [0-markShiftTwo+d/2, 0]));//Y轴方向标注短线 
  makerjs.model.addCaption(myLcgGapCMakerD,TxtD, [0+d/2,0-d], [0+d/2, 0]);//Y文字标注
  /*****************************************************************************/
  makerjs.model.addPath(myLcgGapCMakerY, new makerjs.paths.Line([0-markShiftTwo+d/2,0], [0-markShiftTwo+d/2, 0+y0]));//Y轴方向标注短线 
  makerjs.model.addCaption(myLcgGapCMakerY,TxtY, [0+d/2,0], [0+d/2, 0+y0]);//Y文字标注
  /*****************************************************************************/
  makerjs.model.addPath(myLcgGapCMakerW, new makerjs.paths.Line([0-w,y0+markShift/2], [0, y0+markShift/2]));//W短线标注
  makerjs.model.addCaption(myLcgGapCMakerW, TxtW, [0-w,y0+markShift], [0, y0+markShift]);//W文字标注
  /*****************************************************************************/ 
  makerjs.model.addPath(myLcgGapCMakerW1, new makerjs.paths.Line([0,-markShift-d], [0-w1, -markShift-d]));//W1短线标注
  makerjs.model.addCaption(myLcgGapCMakerW1, TxtW1, [0-w1, -markShiftThree-d], [0,-markShiftThree-d]);//W1文字标注
  /*****************************************************************************/
  /*****************************************************************************/   
  makerjs.model.addPath(myLcgGapCMakerD1, new makerjs.paths.Line([-w1,-d/2], [-w1-d1, -d/2-d1]));//D1短线标注
  makerjs.model.addCaption(myLcgGapCMakerD1, TxtD1, [-w1-d1-markHolesOneD, -d/2-d1-markHolesOneD],[-w1,-d/2]);//D1文字标注
  /*****************************************************************************/ 
  plate.models["myLcgGapC"+myHolesNum]=myLcgGapC;
  markers.models["myLcgGapC"+myHolesNum]=myLcgGapC;
  markers.models["myLcgGapCMakerD"+myHolesNum]=myLcgGapCMakerD;
  markers.models["myLcgGapCMakerY"+myHolesNum]=myLcgGapCMakerY;
  markers.models["myLcgGapCMakerW"+myHolesNum]=myLcgGapCMakerW;
  markers.models["myLcgGapCMakerW1"+myHolesNum]=myLcgGapCMakerW1;
  markers.models["myLcgGapCMakerD1"+myHolesNum]=myLcgGapCMakerD1;
  /*****************************************************************************/
  makerjs.model.combineSubtraction(markers.models.myRect, markers.models["myLcgGapC"+myHolesNum]);
  console.log("markers==",markers);
  /*****************************************************************************/ 
        }else if(area==4){
     //画缺 D位置孔
        //画7、长条夹 D位置
   var myLcgGapDRoundRectangle = new makerjs.models.RoundRectangle(w+d, d, d)
   myLcgGapDRoundRectangle.origin=[xd-d/2,yd-d/2];
  var myLcgGapDRoundHoles= new makerjs.models.Holes(d1/2, [[xd, yd]]);  
  myLcgGapDRoundHoles.origin=[w1,0]; 
  var   myLcgGapD=makerjs.model.combineUnion(myLcgGapDRoundRectangle, myLcgGapDRoundHoles);    
  ////标记7、长条夹 D位置
  var myLcgGapDMakerW = new makerjs.models.Square(0); //孔的原点       
  myLcgGapDMakerW.origin = [xd, yd];
  var myLcgGapDMakerD = new makerjs.models.Square(0); //孔的原点       
  myLcgGapDMakerD.origin = [xd, yd];
  var myLcgGapDMakerY = new makerjs.models.Square(0); //孔的原点       
  myLcgGapDMakerY.origin = [xd, yd]; 
  var myLcgGapDMakerD1 = new makerjs.models.Square(0); //孔的原点       
  myLcgGapDMakerD1.origin = [xd, yd]; 
  var myLcgGapDMakerW1 = new makerjs.models.Square(0); //孔的原点       
  myLcgGapDMakerW1.origin = [xd, yd]; 
  /*****************************************************************************/
  /*****************************************************************************/  
  makerjs.model.addPath(myLcgGapDMakerD, new makerjs.paths.Line([0,yd], [0, yd+markShiftTwo]));//Y轴加长标线
  makerjs.model.addPath(myLcgGapDMakerD, new makerjs.paths.Line([0+w,yd], [0+w, yd+markShiftTwo]));//Y轴方向短线
  makerjs.model.addPath(myLcgGapDMakerD, new makerjs.paths.Line([0+w1,0], [0+w1, -d-markShiftFour]));//Y轴方向短线
  makerjs.model.addPath(myLcgGapDMakerD, new makerjs.paths.Line([0,d/2], [markShiftThree, d/2]));//X轴方向短线
  makerjs.model.addPath(myLcgGapDMakerD, new makerjs.paths.Line([0,0-d/2], [markShiftThree, 0-d/2]));//X轴方向短线
  
  makerjs.model.addPath(myLcgGapDMakerD, new makerjs.paths.Line([0+markShiftTwo,d/2], [0+markShiftTwo, -d/2]));//D轴方向标注短线 
  makerjs.model.addCaption(myLcgGapDMakerD,TxtD, [0+markShiftThree, -d/2], [0+markShiftThree,d/2]);//D文字标注
  /*****************************************************************************/
  makerjs.model.addPath(myLcgGapDMakerY, new makerjs.paths.Line([0-markShiftTwo,0], [0-markShiftTwo, 0+y0]));//Y轴方向标注短线 
  makerjs.model.addCaption(myLcgGapDMakerY,TxtY, [0-markShiftThree,0], [0-markShiftThree, 0+y0]);//Y文字标注
  /*****************************************************************************/
  makerjs.model.addPath(myLcgGapDMakerW, new makerjs.paths.Line([0,yd+markShift/2], [0+w, yd+markShift/2]));//W短线标注
  makerjs.model.addCaption(myLcgGapDMakerW, TxtW, [0,yd+markShift], [0+w, yd+markShift]);//W文字标注
  /*****************************************************************************/
  makerjs.model.addPath(myLcgGapDMakerW1, new makerjs.paths.Line([0,-markShift-d], [0+w1, -markShift-d]));//W1短线标注
  makerjs.model.addCaption(myLcgGapDMakerW1, TxtW1, [0,-markShiftThree-d], [0+w1, -markShiftThree-d]);//W1文字标注
  /*****************************************************************************/
  /*****************************************************************************/   
  makerjs.model.addPath(myLcgGapDMakerD1, new makerjs.paths.Line([w1,0], [w1+d1, -d1]));//D1短线标注
  makerjs.model.addCaption(myLcgGapDMakerD1, TxtD1,[w1,0], [w1+d1+markHolesOneD, d1-markHolesOneD]);//D1文字标注
  /*****************************************************************************/ 
  plate.models["myLcgGapD"+myHolesNum]=myLcgGapD;
  markers.models["myLcgGapD"+myHolesNum]=myLcgGapD;
  markers.models["myLcgGapDMakerD"+myHolesNum]=myLcgGapDMakerD;
  markers.models["myLcgGapDMakerY"+myHolesNum]=myLcgGapDMakerY;
  markers.models["myLcgGapDMakerW"+myHolesNum]=myLcgGapDMakerW;
  markers.models["myLcgGapDMakerD1"+myHolesNum]=myLcgGapDMakerD1;
  markers.models["myLcgGapDMakerW1"+myHolesNum]=myLcgGapDMakerW1;
  /*****************************************************************************/
  makerjs.model.combineSubtraction(markers.models.myRect, markers.models["myLcgGapD"+myHolesNum]);
  console.log("markers==",markers);
  /*****************************************************************************/ 
  /*****************************************************************************/ 
       
        }else if(area==5){
          
        }else if(area==6){
     //画7、长条夹 F位置
   var myLcgGapFRoundRectangle = new makerjs.models.RoundRectangle(w+d, d, d);
   myLcgGapFRoundRectangle.origin=[xf-w-d/2,yf-d/2];
  var myLcgGapFRoundHoles= new makerjs.models.Holes(d1/2, [[xf, yf]]);  
  myLcgGapFRoundHoles.origin=[-w1,0]; 
  var   myLcgGapF=makerjs.model.combineUnion(myLcgGapFRoundRectangle, myLcgGapFRoundHoles); 
  ////标记7、长条夹 F位置
  var myLcgGapFMakerD = new makerjs.models.Square(0); //孔的原点       
  myLcgGapFMakerD.origin = [xf,yf];
  var myLcgGapFMakerY = new makerjs.models.Square(0); //孔的原点       
  myLcgGapFMakerY.origin = [xf,yf];
  var myLcgGapFMakerW = new makerjs.models.Square(0); //孔的原点       
  myLcgGapFMakerW.origin = [xf,yf];  
  var myLcgGapFMakerD1 = new makerjs.models.Square(0); //孔的原点       
  myLcgGapFMakerD1.origin = [xf,yf]; 
  var myLcgGapFMakerW1 = new makerjs.models.Square(0); //孔的原点       
  myLcgGapFMakerW1.origin = [xf,yf];   
  /*****************************************************************************/
  /*****************************************************************************/  
  makerjs.model.addPath(myLcgGapFMakerD, new makerjs.paths.Line([0,yf], [0, yf+markShiftTwo]));//Y轴加长标线
  makerjs.model.addPath(myLcgGapFMakerD, new makerjs.paths.Line([0-w,yf], [0-w, yf+markShiftTwo]));//Y轴方向短线
  makerjs.model.addPath(myLcgGapFMakerD, new makerjs.paths.Line([0,d/2], [0+markShiftThree, d/2]));//X轴方向短线
  makerjs.model.addPath(myLcgGapFMakerD, new makerjs.paths.Line([0,-d/2], [0+markShiftThree, -d/2]));//X轴方向短线
  makerjs.model.addPath(myLcgGapFMakerD, new makerjs.paths.Line([0-w1,0], [0-w1, -d-markShiftFour]));//Y轴方向短线
  
  makerjs.model.addPath(myLcgGapFMakerD, new makerjs.paths.Line([0-markShiftTwo+d/2,d/2], [0-markShiftTwo+d/2, -d/2]));//Y轴方向标注短线 
  makerjs.model.addCaption(myLcgGapFMakerD,TxtD, [0+d/2, -d/2], [0+d/2,d/2]);//Y文字标注
  /*****************************************************************************/
  makerjs.model.addPath(myLcgGapFMakerW, new makerjs.paths.Line([0-w,yf+markShift/2], [0, yf+markShift/2]));//W短线标注
  makerjs.model.addCaption(myLcgGapFMakerW, TxtW, [0-w,yf+markShift], [0, yf+markShift]);//W文字标注
  /*****************************************************************************/ 
  makerjs.model.addPath(myLcgGapFMakerW1, new makerjs.paths.Line([0,-markShift-d], [0-w1, -markShift-d]));//W1短线标注
  makerjs.model.addCaption(myLcgGapFMakerW1, TxtW1, [0-w1, -markShiftThree-d], [0,-markShiftThree-d]);//W1文字标注
  /*****************************************************************************/
  /*****************************************************************************/   
  makerjs.model.addPath(myLcgGapFMakerD1, new makerjs.paths.Line([-w1,0], [-w1-d1, -d1]));//D1短线标注
  makerjs.model.addCaption(myLcgGapFMakerD1, TxtD1, [-w1-d1-markHolesOneD, -d1-markHolesOneD],[-w1,0]);//D1文字标注
  /*****************************************************************************/ 
  plate.models["myLcgGapF"+myHolesNum]=myLcgGapF;
  markers.models["myLcgGapF"+myHolesNum]=myLcgGapF;
  markers.models["myLcgGapFMakerD"+myHolesNum]=myLcgGapFMakerD;
  markers.models["myLcgGapFMakerY"+myHolesNum]=myLcgGapFMakerY;
  markers.models["myLcgGapFMakerW"+myHolesNum]=myLcgGapFMakerW;
  markers.models["myLcgGapFMakerW1"+myHolesNum]=myLcgGapFMakerW1;
  markers.models["myLcgGapFMakerD1"+myHolesNum]=myLcgGapFMakerD1;
  /*****************************************************************************/
  makerjs.model.combineSubtraction(markers.models.myRect, markers.models["myLcgGapF"+myHolesNum]);
  console.log("markers==",markers);
  /*****************************************************************************/ 
  /*****************************************************************************/ 
          
        }else if(area==7){
  //画缺 G位置孔
        //画7、长条夹 G位置
        var myLcgGapGRoundRectangle = new makerjs.models.RoundRectangle(w+d, d, d)
   myLcgGapGRoundRectangle.origin=[xg-d/2,yg];
  var myLcgGapGRoundHoles= new makerjs.models.Holes(d1/2, [[xg, yg]]);  
  myLcgGapGRoundHoles.origin=[w1,d/2]; 
  var   myLcgGapG=makerjs.model.combineUnion(myLcgGapGRoundRectangle, myLcgGapGRoundHoles);    
  ////标记U型缺 G位置
  var myLcgGapGMakerW = new makerjs.models.Square(0); //孔的原点       
  myLcgGapGMakerW.origin = [xg,yg];
  var myLcgGapGMakerD = new makerjs.models.Square(0); //孔的原点       
  myLcgGapGMakerD.origin = [xg,yg];
  var myLcgGapGMakerY = new makerjs.models.Square(0); //孔的原点       
  myLcgGapGMakerY.origin = [xg,yg]; 
  var myLcgGapGMakerD1 = new makerjs.models.Square(0); //孔的原点       
  myLcgGapGMakerD1.origin = [xg,yg]; 
  var myLcgGapGMakerW1 = new makerjs.models.Square(0); //孔的原点       
  myLcgGapGMakerW1.origin = [xg,yg]; 
  /*****************************************************************************/
  /*****************************************************************************/  
  makerjs.model.addPath(myLcgGapGMakerD, new makerjs.paths.Line([0,-y0], [0, -y0-markShiftFour]));//Y轴加长标线
  makerjs.model.addPath(myLcgGapGMakerD, new makerjs.paths.Line([0+w,0], [0+w, -y0-markShiftFour]));//Y轴方向短线
  makerjs.model.addPath(myLcgGapGMakerD1, new makerjs.paths.Line([0+w1,d/2], [0+w1, d+markShiftFour]));//Y轴方向短线
  makerjs.model.addPath(myLcgGapGMakerD, new makerjs.paths.Line([0,-y0], [-markShiftFour, -y0]));//X轴加长标线
  makerjs.model.addPath(myLcgGapGMakerD, new makerjs.paths.Line([0,0], [-markShiftFour, 0]));//X轴方向短线
  makerjs.model.addPath(myLcgGapGMakerD, new makerjs.paths.Line([0,0+d], [-markShiftFour, 0+d]));//X轴方向短线
  
  makerjs.model.addPath(myLcgGapGMakerD, new makerjs.paths.Line([0-markShiftTwo,0+d], [0-markShiftTwo, 0]));//D轴方向标注短线 
  makerjs.model.addCaption(myLcgGapGMakerD,TxtD, [0-markShiftFour, 0], [0-markShiftFour,0+d]);//D文字标注
  /*****************************************************************************/
  makerjs.model.addPath(myLcgGapGMakerY, new makerjs.paths.Line([0-markShiftTwo,0], [0-markShiftTwo, 0-y0]));//Y轴方向标注短线 
  makerjs.model.addCaption(myLcgGapGMakerY,TxtY, [0-markShiftFour, 0-y0], [0-markShiftFour,0]);//Y文字标注
  /*****************************************************************************/
  makerjs.model.addPath(myLcgGapGMakerW, new makerjs.paths.Line([0,-y0-markShiftTwo], [0+w, -y0-markShiftTwo]));//W短线标注
  makerjs.model.addCaption(myLcgGapGMakerW, TxtW, [0,-y0-markShiftFour], [0+w, -y0-markShiftFour]);//W文字标注
  /*****************************************************************************/
  makerjs.model.addPath(myLcgGapGMakerW1, new makerjs.paths.Line([0,markShift+d], [0+w1, markShift+d]));//W1短线标注
  makerjs.model.addCaption(myLcgGapGMakerW1, TxtW1, [0,markShiftThree+d], [0+w1, markShiftThree+d]);//W1文字标注
  /*****************************************************************************/
  /*****************************************************************************/   
  makerjs.model.addPath(myLcgGapGMakerD1, new makerjs.paths.Line([w1,d/2], [w1+d1, d/2+d1]));//D1短线标注
  makerjs.model.addCaption(myLcgGapGMakerD1, TxtD1,[w1,d/2], [w1+d1+markHolesOneD, d/2+d1+markHolesOneD]);//D1文字标注
  /*****************************************************************************/ 
  plate.models["myLcgGapG"+myHolesNum]=myLcgGapG;
  markers.models["myLcgGapG"+myHolesNum]=myLcgGapG;
  markers.models["myLcgGapGMakerD"+myHolesNum]=myLcgGapGMakerD;
  markers.models["myLcgGapGMakerY"+myHolesNum]=myLcgGapGMakerY;
  markers.models["myLcgGapGMakerW"+myHolesNum]=myLcgGapGMakerW;
  markers.models["myLcgGapGMakerD1"+myHolesNum]=myLcgGapGMakerD1;
  markers.models["myLcgGapGMakerW1"+myHolesNum]=myLcgGapGMakerW1;
  /*****************************************************************************/
  makerjs.model.combineSubtraction(markers.models.myRect, markers.models["myLcgGapG"+myHolesNum]);
  console.log("markers==",markers);
  /*****************************************************************************/ 
  /*****************************************************************************/ 
           
        }else if(area==8){
          
        }else if(area==9){
     //画7、长条夹 I位置
   var myLcgGapIRoundRectangle = new makerjs.models.RoundRectangle(w+d, d, d);
   myLcgGapIRoundRectangle.origin=[xi-w-d/2,yi];
  var myLcgGapIRoundHoles= new makerjs.models.Holes(d1/2, [[xi, yi]]);  
  myLcgGapIRoundHoles.origin=[-w1,d/2]; 
  var   myLcgGapI=makerjs.model.combineUnion(myLcgGapIRoundRectangle, myLcgGapIRoundHoles); 
  ////标记7、长条夹 I位置
  var myLcgGapIMakerD = new makerjs.models.Square(0); //孔的原点       
  myLcgGapIMakerD.origin = [xi, yi];
  var myLcgGapIMakerY = new makerjs.models.Square(0); //孔的原点       
  myLcgGapIMakerY.origin = [xi, yi];
  var myLcgGapIMakerW = new makerjs.models.Square(0); //孔的原点       
  myLcgGapIMakerW.origin = [xi, yi];  
  var myLcgGapIMakerD1 = new makerjs.models.Square(0); //孔的原点       
  myLcgGapIMakerD1.origin = [xi, yi]; 
  var myLcgGapIMakerW1 = new makerjs.models.Square(0); //孔的原点       
  myLcgGapIMakerW1.origin = [xi, yi];   
  /*****************************************************************************/
  /*****************************************************************************/  
  
  makerjs.model.addPath(myLcgGapIMakerD, new makerjs.paths.Line([0,-y0], [0, -y0-markShiftFour]));//Y轴加长标线
  makerjs.model.addPath(myLcgGapIMakerD, new makerjs.paths.Line([0-w,0], [0-w, -y0-markShiftFour]));//Y轴方向短线
  makerjs.model.addPath(myLcgGapIMakerD, new makerjs.paths.Line([0,-y0], [0+markShiftFour, -y0]));//X轴加长标线
  makerjs.model.addPath(myLcgGapIMakerD, new makerjs.paths.Line([0,0], [0+markShiftFour, 0]));//X轴方向短线
  makerjs.model.addPath(myLcgGapIMakerD, new makerjs.paths.Line([0,0+d], [0+markShiftFour, 0+d]));//X轴方向短线
  makerjs.model.addPath(myLcgGapIMakerD1, new makerjs.paths.Line([0-w1,d/2], [0-w1, d+markShiftFour]));//Y轴方向短线
  
  
  makerjs.model.addPath(myLcgGapIMakerD, new makerjs.paths.Line([markShiftTwo,0+d], [markShiftTwo, 0]));//Y轴方向标注短线 
  makerjs.model.addCaption(myLcgGapIMakerD,TxtD, [markShiftFour, 0], [markShiftFour,0+d]);//Y文字标注
  /*****************************************************************************/
  makerjs.model.addPath(myLcgGapIMakerY, new makerjs.paths.Line([0+markShiftTwo,0], [0+markShiftTwo, 0-y0]));//Y轴方向标注短线 
  makerjs.model.addCaption(myLcgGapIMakerY,TxtY, [0+markShiftFour, 0-y0], [0+markShiftFour,0]);//Y文字标注
  /*****************************************************************************/
  makerjs.model.addPath(myLcgGapIMakerW, new makerjs.paths.Line([0-w,-y0-markShiftTwo], [0, -y0-markShiftTwo]));//W短线标注
  makerjs.model.addCaption(myLcgGapIMakerW, TxtW, [0-w,-y0-markShiftFour], [0, -y0-markShiftFour]);//W文字标注
  /*****************************************************************************/ 
  
  makerjs.model.addPath(myLcgGapIMakerW1, new makerjs.paths.Line([0,markShift+d], [0-w1, markShift+d]));//W1短线标注
  makerjs.model.addCaption(myLcgGapIMakerW1, TxtW1, [0-w1, markShiftThree+d], [0,markShiftThree+d]);//W1文字标注
  /*****************************************************************************/   
  makerjs.model.addPath(myLcgGapIMakerD1, new makerjs.paths.Line([-w1,d/2], [-w1-d1, d/2+d1]));//D1短线标注
  makerjs.model.addCaption(myLcgGapIMakerD1, TxtD1, [-w1-d1-markHolesOneD, d/2+d1+markHolesOneD],[-w1,d/2]);//D1文字标注
  /*****************************************************************************/ 
  plate.models["myLcgGapI"+myHolesNum]=myLcgGapI;
  markers.models["myLcgGapI"+myHolesNum]=myLcgGapI;
  markers.models["myLcgGapIMakerD"+myHolesNum]=myLcgGapIMakerD;
  markers.models["myLcgGapIMakerY"+myHolesNum]=myLcgGapIMakerY;
  markers.models["myLcgGapIMakerW"+myHolesNum]=myLcgGapIMakerW;
  markers.models["myLcgGapIMakerW1"+myHolesNum]=myLcgGapIMakerW1;
  markers.models["myLcgGapIMakerD1"+myHolesNum]=myLcgGapIMakerD1;
  /*****************************************************************************/
  makerjs.model.combineSubtraction(markers.models.myRect, markers.models["myLcgGapI"+myHolesNum]);
  console.log("markers==",markers);
  /*****************************************************************************/       
        }

myHolesNum++;
return{plate, markers,myHolesNum}
};


export default LongClipGap;