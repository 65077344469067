//角4、圆角2
import makerjs from "makerjs";
// inputParam,model,myHolesNum,outParam,fixedParam
// (D,X,Y,area,plate,markers,myHolesNum,glassW,glassH,markShift,markShiftTwo,markShiftThree,markShiftFour,markHolesOneD)
 //已知圆上两个点坐标及圆的半径，求圆心坐标函数
 function Circle_Center(x1,y1,x2,y2,r){
  var a=0,b=0,b2=0;
  console.log("Circle_Center计算圆心坐标函数")
  //Math.pow((x1-a),2)+Math.pow((y1-b),2) == Math.pow(r,2)
  //Math.pow((x2-a),2)+Math.pow((y2-b),2) == Math.pow(r,2)
var c1=(x2*x2-x1*x1+y2*y2-y1*y1)/(2*(x2-x1));
var c2=(y2-y1)/(x2-x1);//
var A=(c2*c2+1);
var B=(2*x1*c2-2*c1*c2-2*y1);
var C=x1*x1-2*x1*c1+c1*c1+y1*y1-r*r;
b=(-B+Math.sqrt(B*B-4*A*C))/(2*A);
b2=(-B-Math.sqrt(B*B-4*A*C))/(2*A);
a=c1-c2*b;
var arr = [a,b,b2]; 
console.log("arr==",arr);
return arr;

};
function Fillet2(inputParam,model,myHolesNum,outParam,fixedParam,zoom){
var R=inputParam.R,W=inputParam.W,H=inputParam.H,X=inputParam.X,Y=inputParam.Y,area=inputParam.area,myZoom=zoom*1,
plate=model.plate,markers=model.markers,
glassW=outParam.glassW,glassH=outParam.glassH,
   markShift=fixedParam.markShift,markShiftTwo=fixedParam.markShiftTwo,
   markShiftThree=fixedParam.markShiftThree,markShiftFour=fixedParam.markShiftFour,markHolesOneD=fixedParam.markHolesOneD,
 
   w = W*1*myZoom,
   h = H*1*myZoom,
   r = R*1*myZoom,
   rd=r/Math.sqrt(2),
   a=0,//圆心坐标[a,b]
   b=0,//圆心坐标[a,b]
   b2=0,//圆心坐标[a,b]
     x0 = X*1*myZoom,
     y0 = Y*1*myZoom;

     /*
     w = W*1,
     h = H*1,
     r = R*1,
     rd=r/Math.sqrt(2),
     a=0,//圆心坐标[a,b]
     b=0,//圆心坐标[a,b]
     b2=0,//圆心坐标[a,b]
       x0 = X*1,
       y0 = Y*1;
       */
     if(r!==0){
      var TxtR="R="+R;
     }
     if(w!==0){
      var TxtW=W;
     }
     if(h!==0){
      var TxtH=H;
     }
     if(x0!==0){          
     var TxtX=X;
     }
     if(y0!==0){          
      var TxtY=Y;
     }
  //在A区域 计算孔的位置       
  var xa=x0,ya=glassH * 1-y0,
  //B区域//只有Y标注没有X标注 
  xb=glassW * 1/2,yb=glassH * 1-y0,
  //C区域
  xc=glassW * 1-x0,yc=glassH * 1-y0,
  //D区域
  //只有X标注没有Y标注 
  xd=x0,yd=glassH * 1/2,
  //E区域
  //只有X标注没有Y标注 
  xe=glassW * 1/2,ye=glassH * 1/2,
  //F区域
  //只有X标注没有Y标注 
  xf=glassW * 1-x0,yf=glassH * 1/2,
  //在G区域 计算孔的位置
  xg=x0,yg=y0,
  //在H区域 计算孔的位置
  //只标注Y
  xh=glassW*1/2,yh=y0,
  //在I区域 计算孔的位置
  xi=glassW*1-x0,yi=y0;
  if(area==1){
    //画4、圆角2 A位置
/*
w-r:圆圆心X方向上偏移量
h-r:圆圆心Y方向上偏移量
*/
var myFillet2A = {
paths: {
"TopLine": new makerjs.paths.Line([xa-5, ya+5], [xa-5+w+5, ya+5]),
"RightLine": new makerjs.paths.Line([xa-5+w+5, ya+5], [xa-5+w+5, ya]),
"BottomRightArc": new makerjs.paths.Arc([xa-5+w+5, ya], [xa, ya-h],r,false,false),
"BottomLine": new makerjs.paths.Line([xa, ya-h], [xa-5, ya-h]),
"LeftLine": new makerjs.paths.Line([xa-5, ya-h], [xa-5, ya+5])
}
};
console.log("测试坐标=");
//Circle_Center(0,-h,w,0,r)
console.log("测试坐标=",Circle_Center(0,-h,w,0,r));
var arr=[];
arr=Circle_Center(0,-h,w,0,r);
a=arr[0].toFixed()*1;
b=arr[1].toFixed()*1;
b2=arr[2].toFixed()*1;
console.log("测试坐标a=",a);
console.log("测试坐标b=",b);
console.log("测试坐标b=",b2);
console.log("测试坐标a=isNaN(a)",isNaN(a));
console.log("测试坐标b=isNaN(b)",isNaN(b));
console.log("测试坐标b=isNaN(b2)",isNaN(b2));
console.log("测试坐标b=",typeof(b2));
//var rLine=new makerjs.paths.Line([0,-h], [a, b2]);
//var rAngleInDegrees=makerjs.angle.ofPointInDegrees([xa-5+w+5, ya], [xa, ya-h]);//角度
//var rRotat=new makerjs.path.rotate(rLine, -rAngleInDegrees, [w, -r]);//角度
// console.log( "rAngleInDegrees=",360-rAngleInDegrees/2);

  //  var myFillet2A= new makerjs.models.Holes(r, [[xa, ya]]);
    // myFillet2A.origin=[a+r,b2];
////标记4、圆角2 A位置
var myFillet2AMakerW = new makerjs.models.Square(0); //孔的原点       
myFillet2AMakerW.origin = [xa,ya];
var myFillet2AMakerH = new makerjs.models.Square(0); //孔的原点       
myFillet2AMakerH.origin = [xa,ya];
var myFillet2AMakerR = new makerjs.models.Square(0); //孔的原点       
myFillet2AMakerR.origin = [xa,ya];

/*****************************************************************************/
/*****************************************************************************/
makerjs.model.addPath(myFillet2AMakerW, new makerjs.paths.Line([0,0], [0, markShiftThree]));//Y轴加长标线
makerjs.model.addPath(myFillet2AMakerW, new makerjs.paths.Line([w,0], [w,0+markShiftFour]));//Y轴方向短线
makerjs.model.addPath(myFillet2AMakerW, new makerjs.paths.Line([0,0], [0-markShiftThree, 0]));//X轴加长标线
makerjs.model.addPath(myFillet2AMakerW, new makerjs.paths.Line([0,0-h], [0-markShiftThree, 0-h]));//X轴方向短线

/*****************************************************************************/
/*****************************************************************************/   
makerjs.model.addPath(myFillet2AMakerW, new makerjs.paths.Line([0,0+markShift], [0+w, 0+markShift]));//W短线标注
makerjs.model.addCaption(myFillet2AMakerW, TxtW, [0,0+markShiftThree], [0+w, 0+markShiftThree]);//W文字标注 

/*****************************************************************************/  
/*****************************************************************************/   
makerjs.model.addPath(myFillet2AMakerH, new makerjs.paths.Line([0-markShiftTwo,0-h], [0-markShiftTwo, 0]));//H短线标注
makerjs.model.addCaption(myFillet2AMakerH, TxtH, [0-markShiftFour,0-h], [0-markShiftFour, 0]);//H文字标注 

/*****************************************************************************/  
/*****************************************************************************/ 
var rLine=new makerjs.paths.Line([a+r,b2], [0, -h]);
var rAngleInDegrees=makerjs.angle.ofPointInDegrees([xa, ya-h],[xa+w, ya]);//角度
// var rRotat=new makerjs.path.rotate(rLine, rAngleInDegrees/2, [a+r,b2]);//角度
console.log( "rAngleInDegrees=",rAngleInDegrees);
makerjs.model.addPath(myFillet2AMakerR, new makerjs.path.rotate(rLine, -rAngleInDegrees,[a+r,b2]));//R短线标注
makerjs.model.addCaption(myFillet2AMakerR, TxtR,  [a+r+markShift,b2-markShift],[a+r+markShiftFour,b2-markShift]);//R文字标注 

/*****************************************************************************/  
/*****************************************************************************/ 

plate.models["myFillet2A"+myHolesNum]=myFillet2A;
markers.models["myFillet2A"+myHolesNum]=myFillet2A;
markers.models["myFillet2AMakerW"+myHolesNum]=myFillet2AMakerW;
markers.models["myFillet2AMakerH"+myHolesNum]=myFillet2AMakerH;
markers.models["myFillet2AMakerR"+myHolesNum]=myFillet2AMakerR;
/*****************************************************************************/
makerjs.model.combineSubtraction(markers.models.myRect, markers.models["myFillet2A"+myHolesNum])
// makerjs.model.combineSubtraction(markers.models.myRect, markers.models["myFillet2AMakerR"+myHolesNum])

console.log("markers==",markers);
/*****************************************************************************/ 

/*****************************************************************************/ 
 
    }else if(area==2){
      
    }else if(area==3){
        //画4、圆角2 C位置
/*
w-r:圆圆心X方向上偏移量
h-r:圆圆心Y方向上偏移量
*/

var myFillet2C = {

paths: {
"RightLine": new makerjs.paths.Line([xc+5, yc+5], [xc+5, yc-h]),
"BottomLine": new makerjs.paths.Line([xc+5, yc-h], [xc, yc-h]),
"BottomRightArc": new makerjs.paths.Arc([xc, yc-h], [xc-w, yc],r,false,false),
"LeftLine": new makerjs.paths.Line([xc-w, yc], [xc-w, yc+5]),
"TopLine": new makerjs.paths.Line([xc-w, yc+5], [xc+5, yc+5])
}

};

console.log("测试坐标=");
//Circle_Center(0,-h,w,0,r)

console.log("测试坐标=",Circle_Center(w,0,0,-h,r));
var arr=[];
arr=Circle_Center(w,0,0,-h,r);
a=arr[0].toFixed()*1;
b=arr[1].toFixed()*1;
b2=arr[2].toFixed()*1;
console.log("测试坐标a=",a);
console.log("测试坐标b=",b);
console.log("测试坐标b=",b2);
console.log("测试坐标b=",typeof(b2));
//var rLine=new makerjs.paths.Line([0,-h], [a, b2]);
//var rAngleInDegrees=makerjs.angle.ofPointInDegrees([xa-5+w+5, ya], [xa, ya-h]);//角度
//var rRotat=new makerjs.path.rotate(rLine, -rAngleInDegrees, [w, -r]);//角度
// console.log( "rAngleInDegrees=",360-rAngleInDegrees/2);

    // var myFillet2C= new makerjs.models.Holes(r, [[xc, yc]]);
    // myFillet2C.origin=[-(r+a), b2];
////标记4、圆角2 A位置
var myFillet2CMakerW = new makerjs.models.Square(0); //孔的原点       
myFillet2CMakerW.origin = [xc,yc];
var myFillet2CMakerH = new makerjs.models.Square(0); //孔的原点       
myFillet2CMakerH.origin = [xc,yc];
var myFillet2CMakerR = new makerjs.models.Square(0); //孔的原点       
myFillet2CMakerR.origin = [xc,yc];

/*****************************************************************************/
/*****************************************************************************/
makerjs.model.addPath(myFillet2CMakerW, new makerjs.paths.Line([0,0], [0, markShiftThree]));//Y轴加长标线
makerjs.model.addPath(myFillet2CMakerW, new makerjs.paths.Line([-w,0], [-w,0+markShiftFour]));//Y轴方向短线
makerjs.model.addPath(myFillet2CMakerW, new makerjs.paths.Line([0,0], [0+markShiftThree, 0]));//X轴加长标线
makerjs.model.addPath(myFillet2CMakerW, new makerjs.paths.Line([0,0-h], [0+markShiftThree, 0-h]));//X轴方向短线

/*****************************************************************************/
/*****************************************************************************/   
makerjs.model.addPath(myFillet2CMakerW, new makerjs.paths.Line([0-w,0+markShift], [0, 0+markShift]));//W短线标注
makerjs.model.addCaption(myFillet2CMakerW, TxtW, [0-w,0+markShiftThree], [0, 0+markShiftThree]);//W文字标注 

/*****************************************************************************/  
/*****************************************************************************/   
makerjs.model.addPath(myFillet2CMakerH, new makerjs.paths.Line([0+markShiftTwo,0], [0+markShiftTwo, 0-h]));//H短线标注
makerjs.model.addCaption(myFillet2CMakerH, TxtH, [0+markShiftFour,0-h], [0+markShiftFour, 0]);//H文字标注 

/*****************************************************************************/  
/*****************************************************************************/ 
//console.log();
var rLine=new makerjs.paths.Line([-(r+a), b2], [-w, 0]);
var rAngleInDegrees=makerjs.angle.ofPointInDegrees([xc, yc-h],[xc-w, yc]);//角度
console.log( "rAngleInDegrees=",rAngleInDegrees);
makerjs.model.addPath(myFillet2CMakerR, new makerjs.path.rotate(rLine, -90+rAngleInDegrees/2,[-(r+a), b2]));//R短线标注
makerjs.model.addCaption(myFillet2CMakerR, TxtR,  [a-r+markShift,b2-markShift],[a-r+markShiftFour,b2-markShift]);//R文字标注 

/*****************************************************************************/  
/*****************************************************************************/ 

plate.models["myFillet2C"+myHolesNum]=myFillet2C;
markers.models["myFillet2C"+myHolesNum]=myFillet2C;
markers.models["myFillet2CMakerW"+myHolesNum]=myFillet2CMakerW;
markers.models["myFillet2CMakerH"+myHolesNum]=myFillet2CMakerH;
markers.models["myFillet2CMakerR"+myHolesNum]=myFillet2CMakerR;
/*****************************************************************************/
makerjs.model.combineSubtraction(markers.models.myRect, markers.models["myFillet2C"+myHolesNum])

console.log("markers==",markers);
/*****************************************************************************/ 

/*****************************************************************************/ 
 

    
    }else if(area==4){
      
    }else if(area==5){
      
    }else if(area==6){
      
    }else if(area==7){

  //画4、圆角2 G位置
/*
w-r:圆圆心X方向上偏移量
h-r:圆圆心Y方向上偏移量
*/

var myFillet2G = {

paths: {
"LeftLine": new makerjs.paths.Line([xg-5, yg-5], [xg-5, yg+h]),
"TopLine": new makerjs.paths.Line([xg-5, yg+h], [xg, yg+h]),
"TopRightArc": new makerjs.paths.Arc([xg, yg+h], [xg+w, yg],r,false,false),
"RightLine": new makerjs.paths.Line([xg+w, yg], [xg+w, yg-5]),
"BottomLine": new makerjs.paths.Line([xg+w, yg-5], [xg-5, yg-5])
}

};

console.log("测试坐标=");
//Circle_Center(0,-h,w,0,r)
console.log("测试坐标=",Circle_Center(w,0,0,h,r));
var arr=[];
arr=Circle_Center(w,0,0,-h,r);
a=arr[0].toFixed()*1;
b=arr[1].toFixed()*1;
b2=arr[2].toFixed()*1;
console.log("测试坐标a=",a);
console.log("测试坐标b=",b);
console.log("测试坐标b=",b2);
console.log("测试坐标b=",typeof(b2));
////标记4、圆角2 G位置
var myFillet2GMakerW = new makerjs.models.Square(0); //孔的原点       
myFillet2GMakerW.origin = [xg,yg];
var myFillet2GMakerH = new makerjs.models.Square(0); //孔的原点       
myFillet2GMakerH.origin = [xg,yg];
var myFillet2GMakerR = new makerjs.models.Square(0); //孔的原点       
myFillet2GMakerR.origin = [xg,yg];

/*****************************************************************************/
/*****************************************************************************/
makerjs.model.addPath(myFillet2GMakerW, new makerjs.paths.Line([0,0], [0, -markShiftThree]));//Y轴加长标线
makerjs.model.addPath(myFillet2GMakerW, new makerjs.paths.Line([w,0], [w,0-markShiftFour]));//Y轴方向短线
makerjs.model.addPath(myFillet2GMakerW, new makerjs.paths.Line([0,0], [0-markShiftThree, 0]));//X轴加长标线
makerjs.model.addPath(myFillet2GMakerW, new makerjs.paths.Line([0,0+h], [0-markShiftThree, 0+h]));//X轴方向短线

/*****************************************************************************/
/*****************************************************************************/   
makerjs.model.addPath(myFillet2GMakerW, new makerjs.paths.Line([0,0-markShiftTwo], [0+w, 0-markShiftTwo]));//W短线标注
makerjs.model.addCaption(myFillet2GMakerW, TxtW, [0,0-markShiftFour], [0+w, 0-markShiftFour]);//W文字标注 

/*****************************************************************************/  
/*****************************************************************************/   
makerjs.model.addPath(myFillet2GMakerH, new makerjs.paths.Line([0-markShiftTwo,0], [0-markShiftTwo, 0+h]));//H短线标注
makerjs.model.addCaption(myFillet2GMakerH, TxtH, [0-markShiftFour,0], [0-markShiftFour, 0+h]);//H文字标注 

/*****************************************************************************/  
/*****************************************************************************/ 
var rLine=new makerjs.paths.Line([a+r, -b2], [w, 0]);
var rAngleInDegrees=makerjs.angle.ofPointInDegrees([xg+w, yg],[xg, yg+h]);//角度
console.log( "rAngleInDegrees=",rAngleInDegrees);
makerjs.model.addPath(myFillet2GMakerR, new makerjs.path.rotate(rLine, -90+rAngleInDegrees/2,[a+r, -b2]));//R短线标注
makerjs.model.addCaption(myFillet2GMakerR, TxtR,  [a+r+markShift,-b2+markShift],[a+r+markShiftFour,-b2+markShift]);//R文字标注 

/*****************************************************************************/  
/*****************************************************************************/ 

plate.models["myFillet2G"+myHolesNum]=myFillet2G;
markers.models["myFillet2G"+myHolesNum]=myFillet2G;
markers.models["myFillet2GMakerW"+myHolesNum]=myFillet2GMakerW;
markers.models["myFillet2GMakerH"+myHolesNum]=myFillet2GMakerH;
markers.models["myFillet2GMakerR"+myHolesNum]=myFillet2GMakerR;
/*****************************************************************************/
makerjs.model.combineSubtraction(markers.models.myRect, markers.models["myFillet2G"+myHolesNum])

console.log("markers==",markers);
/*****************************************************************************/ 

/*****************************************************************************/ 
  
      
    }else if(area==8){
      
    }else if(area==9){
     
     //画4、圆角2 I位置
/*
w-r:圆圆心X方向上偏移量
h-r:圆圆心Y方向上偏移量
*/

var myFillet2I = {

paths: {
"BottomLine": new makerjs.paths.Line([xi+5, yi-5], [xi-w, yi-5]),
"LeftLine": new makerjs.paths.Line([xi-w, yi-5], [xi-w, yi]),
"BottomLeftArc": new makerjs.paths.Arc([xi-w, yi], [xi, yi+h],r,false,false),
"TopLine": new makerjs.paths.Line([xi, yi+h], [xi+5, yi+h]),
"RightLine": new makerjs.paths.Line([xi+5, yi+h], [xi+5, yi-5])
}

};

console.log("测试坐标=");
//Circle_Center(0,-h,w,0,r)

console.log("测试坐标=",Circle_Center(0,h,-w,0,r));
var arr=[];
arr=Circle_Center(0,h,-w,0,r);
a=arr[0].toFixed()*1;
b=arr[1].toFixed()*1;
b2=arr[2].toFixed()*1;
console.log("测试坐标a=",a);
console.log("测试坐标b=",b);
console.log("测试坐标b=",b2);
console.log("测试坐标b=",typeof(b2));

    // var myFillet2I= new makerjs.models.Holes(r, [[xi, yi]]);
    // myFillet2I.origin=[a, b];
////标记4、圆角2 I位置
var myFillet2IMakerW = new makerjs.models.Square(0); //孔的原点       
myFillet2IMakerW.origin = [xi,yi];
var myFillet2IMakerH = new makerjs.models.Square(0); //孔的原点       
myFillet2IMakerH.origin = [xi,yi];
var myFillet2IMakerR = new makerjs.models.Square(0); //孔的原点       
myFillet2IMakerR.origin = [xi,yi];

/*****************************************************************************/
/*****************************************************************************/
makerjs.model.addPath(myFillet2IMakerW, new makerjs.paths.Line([0,0], [0, -markShiftThree]));//Y轴加长标线
makerjs.model.addPath(myFillet2IMakerW, new makerjs.paths.Line([-w,0], [-w,0-markShiftFour]));//Y轴方向短线
makerjs.model.addPath(myFillet2IMakerW, new makerjs.paths.Line([0,0], [0+markShiftThree, 0]));//X轴加长标线
makerjs.model.addPath(myFillet2IMakerW, new makerjs.paths.Line([0,0+h], [0+markShiftThree, 0+h]));//X轴方向短线

/*****************************************************************************/
/*****************************************************************************/   
makerjs.model.addPath(myFillet2IMakerW, new makerjs.paths.Line([0-w,0-markShiftTwo], [0, 0-markShiftTwo]));//W短线标注
makerjs.model.addCaption(myFillet2IMakerW, TxtW, [0-w,0-markShiftFour], [0, 0-markShiftFour]);//W文字标注 

/*****************************************************************************/  
/*****************************************************************************/   
makerjs.model.addPath(myFillet2IMakerH, new makerjs.paths.Line([0+markShiftTwo,0], [0+markShiftTwo, 0+h]));//H短线标注
makerjs.model.addCaption(myFillet2IMakerH, TxtH, [0+markShiftFour,0], [0+markShiftFour, 0+h]);//H文字标注 

/*****************************************************************************/  
/*****************************************************************************/ 
//console.log();

var rLine=new makerjs.paths.Line([a, b], [0, h]);
var rAngleInDegrees=makerjs.angle.ofPointInDegrees([xi, yi+h],[xi-w, yi]);//角度
// var rRotat=new makerjs.path.rotate(rLine, rAngleInDegrees/2, [a-r,b2]);//角度
console.log( "rAngleInDegrees=",rAngleInDegrees);


// makerjs.model.addPath(myFillet2IMakerR, new makerjs.paths.Line([a, b], [0, 0+h]));//R短线标注
// makerjs.model.addPath(myFillet2IMakerR, new makerjs.paths.Line([-w,0], [-w, r]));//R短线标注

// makerjs.model.addPath(myFillet2IMakerR, new makerjs.paths.Line([a, b], [-w, 0]));//R短线标注
makerjs.model.addPath(myFillet2IMakerR, new makerjs.path.rotate(rLine, 90-rAngleInDegrees/2,[a, b]));//R短线标注
makerjs.model.addCaption(myFillet2IMakerR, TxtR,  [a+markShift,b+markShift],[a+markShiftFour,b+markShift]);//R文字标注 

/*****************************************************************************/  
/*****************************************************************************/ 

plate.models["myFillet2I"+myHolesNum]=myFillet2I;
markers.models["myFillet2I"+myHolesNum]=myFillet2I;
markers.models["myFillet2IMakerW"+myHolesNum]=myFillet2IMakerW;
markers.models["myFillet2IMakerH"+myHolesNum]=myFillet2IMakerH;
markers.models["myFillet2IMakerR"+myHolesNum]=myFillet2IMakerR;
/*****************************************************************************/
makerjs.model.combineSubtraction(markers.models.myRect, markers.models["myFillet2I"+myHolesNum])

console.log("markers==",markers);
/*****************************************************************************/ 

/*****************************************************************************/ 
  
    }

myHolesNum++;
return{plate, markers,myHolesNum}
};
export default Fillet2;