//6、晾物孔
import makerjs from "makerjs";
// inputParam,model,this.myHolesNum,outParam,fixedParam
// (D,X,Y,area,plate,markers,myHolesNum,glassW,glassH,markShift,markShiftTwo,markShiftThree,markShiftFour,markHolesOneD)
function HangHole(inputParam,model,myHolesNum,outParam,fixedParam,zoom){
var D=inputParam.D,W=inputParam.W,H1=inputParam.H1,H2=inputParam.H2,X=inputParam.X,Y=inputParam.Y,area=inputParam.area,myZoom=zoom*1,
plate=model.plate,markers=model.markers,
glassW=outParam.glassW,glassH=outParam.glassH,
   markShift=fixedParam.markShift,markShiftTwo=fixedParam.markShiftTwo,
   markShiftThree=fixedParam.markShiftThree,markShiftFour=fixedParam.markShiftFour,markHolesOneD=fixedParam.markHolesOneD,
   d = D*1*myZoom,
   w= W*1*myZoom,
   h1= H1*1*myZoom,
   h2= H2*1*myZoom,
   x0 = X*1*myZoom,
   y0 = Y*1*myZoom;

/*
   d = D*1,
 w= W*1,
      h1= H1*1,
      h2= H2*1,
        x0 = X*1,
        y0 = Y*1;
        */
        if(d!==0){
         var TxtD="∮"+D;
        }
        if(w!==0){
         var TxtW=W;
        }
        if(h1!==0){
         var TxtH1=H1;
        }
        if(h2!==0){
         var TxtH2=H2;
        }
        if(x0!==0){          
        var TxtX=X;
        }
        if(y0!==0){          
         var TxtY=Y;
        }
  //在A区域 计算孔的位置       
  var xa=x0,ya=glassH * 1-y0,
  //B区域//只有Y标注没有X标注 
  xb=glassW * 1/2,yb=glassH * 1-y0,
  //C区域
  xc=glassW * 1-x0,yc=glassH * 1-y0,
  //D区域
  //只有X标注没有Y标注 
  xd=x0,yd=glassH * 1/2,
  //E区域
  //只有X标注没有Y标注 
  xe=glassW * 1/2,ye=glassH * 1/2,
  //F区域
  //只有X标注没有Y标注 
  xf=glassW * 1-x0,yf=glassH * 1/2,
  //在G区域 计算孔的位置
  xg=x0,yg=y0,
  //在H区域 计算孔的位置
  //只标注Y
  xh=glassW*1/2,yh=y0,
  //在I区域 计算孔的位置
  xi=glassW*1-x0,yi=y0;
  if(area==1){
    //画孔 A位置孔
         //标记晾物孔 A位置孔左上
         var myHhHolesALeftup= new makerjs.models.Holes(d/2, [[xa, ya]]);
         //标记孔
         var myHhHolesMarkALeftup = new makerjs.models.Square(0); //孔的原点       
         myHhHolesMarkALeftup.origin = [xa, ya];      
         var myKhHolesXMarkALeftup = new makerjs.models.Square(0);      
         myKhHolesXMarkALeftup.origin =[xa, ya];      
         var myKhHolesYMarkALeftup = new makerjs.models.Square(0);      
         myKhHolesYMarkALeftup.origin = [xa, ya]; 
   /*****************************************************************************/ 
   makerjs.model.addPath(myHhHolesMarkALeftup, new makerjs.paths.Line([-x0,y0], [-x0, y0+markShiftTwo]));//Y轴加长标线
   makerjs.model.addPath(myHhHolesMarkALeftup, new makerjs.paths.Line([0,0], [-x0-markShiftThree, 0]));//从孔圆心出发X方向横向标线
   makerjs.model.addPath(myHhHolesMarkALeftup, new makerjs.paths.Line([-x0,y0], [-x0-markShiftThree, y0]));//X轴加长标线
   makerjs.model.addPath(myHhHolesMarkALeftup, new makerjs.paths.Line([0,0], [0, y0+markShiftTwo]));//从孔圆心出发Y方向竖向标线
   /*****************************************************************************/ 
   makerjs.model.addPath(myHhHolesMarkALeftup, new makerjs.paths.Line([0,0], [d, -d]));//直径标线
   makerjs.model.addCaption(myHhHolesMarkALeftup, TxtD, [0,0], [d+markHolesOneD, -d-markHolesOneD]);//直径文字标注
   /*****************************************************************************/   
   makerjs.model.addPath(myKhHolesXMarkALeftup, new makerjs.paths.Line([0,y0+markShift], [-x0, y0+markShift]));//X短标线
   makerjs.model.addCaption(myKhHolesXMarkALeftup, TxtX, [-x0,y0+markShiftTwo], [0, y0+markShiftTwo]);//X文字标注
   /*****************************************************************************/     
   makerjs.model.addPath(myKhHolesYMarkALeftup, new makerjs.paths.Line([-x0-markShiftTwo,0], [-x0-markShiftTwo, y0]));//Y短标线
   makerjs.model.addCaption(myKhHolesYMarkALeftup, TxtY, [-x0-markShiftThree,0], [-x0-markShiftThree, y0]);//Y文字标注
   /*****************************************************************************/ 
   /*****************************************************************************/ 
   //画孔 A位置孔
         //标记晾物孔 A位置孔右上
         var myHhHolesARightup= new makerjs.models.Holes(d/2, [[xa+w, ya]]);
         var myHhHolesMarkARightup = new makerjs.models.Square(0); //孔的原点       
         myHhHolesMarkARightup.origin = [xa+w, ya];      
         var myHhHolesXMarkARightup = new makerjs.models.Square(0);      
         myHhHolesXMarkARightup.origin =[xa+w, ya];  
   /*****************************************************************************/ 
   makerjs.model.addPath(myHhHolesMarkARightup, new makerjs.paths.Line([0,0], [-w-markShiftThree, 0]));//从孔圆心出发X方向横向标线
   makerjs.model.addPath(myHhHolesMarkARightup, new makerjs.paths.Line([0,0], [0, y0+markShiftTwo]));//从孔圆心出发Y方向竖向标线
             
   /*****************************************************************************/ 
   makerjs.model.addPath(myHhHolesMarkARightup, new makerjs.paths.Line([0,0], [d, -d]));//直径标线
   makerjs.model.addCaption(myHhHolesMarkARightup, TxtD, [0,0], [d+markHolesOneD, -d-markHolesOneD]);//直径文字标注
   /*****************************************************************************/   
   makerjs.model.addPath(myHhHolesXMarkARightup, new makerjs.paths.Line([0,y0+markShift], [-w, y0+markShift]));//X短标线
   makerjs.model.addCaption(myHhHolesXMarkARightup, TxtW, [-w,y0+markShiftTwo], [0, y0+markShiftTwo]);//X文字标注
   /*****************************************************************************/ 
    //标记晾物孔 A位置孔左中
    var myHhHolesALeftmd= new makerjs.models.Holes(d/2, [[xa, ya-h1]]);
         //标记孔
         var myHhHolesMarkALeftmd = new makerjs.models.Square(0); //孔的原点       
         myHhHolesMarkALeftmd.origin = [xa, ya-h1];     
         var myHhHolesYMarkALeftmd = new makerjs.models.Square(0);      
         myHhHolesYMarkALeftmd.origin = [xa, ya-h1];  
   /*****************************************************************************/ 
   makerjs.model.addPath(myHhHolesMarkALeftmd, new makerjs.paths.Line([0,0], [-x0-markShiftThree, 0]));//从孔圆心出发X方向横向标线
   makerjs.model.addPath(myHhHolesMarkALeftmd, new makerjs.paths.Line([0,0], [0, h1+markShiftTwo]));//从孔圆心出发Y方向竖向标线
   /*****************************************************************************/ 
   makerjs.model.addPath(myHhHolesMarkALeftmd, new makerjs.paths.Line([0,0], [d, -d]));//直径标线
   makerjs.model.addCaption(myHhHolesMarkALeftmd, TxtD, [0,0], [d+markHolesOneD, -d-markHolesOneD]);//直径文字标注
   /*****************************************************************************/   
   /*****************************************************************************/     
   makerjs.model.addPath(myHhHolesYMarkALeftmd, new makerjs.paths.Line([-x0-markShiftTwo,0], [-x0-markShiftTwo, h1]));//Y短标线
   makerjs.model.addCaption(myHhHolesYMarkALeftmd, TxtH1, [-x0-markShiftThree,0], [-x0-markShiftThree, h1]);//Y文字标注
   /*****************************************************************************/ 
   /*****************************************************************************/ 
    //标记晾物孔 A位置孔右中
    var myKhHolesARightmd= new makerjs.models.Holes(d/2, [[xa+w, ya-h1]]);
         //标记孔
         var myKhHolesMarkARightmd = new makerjs.models.Square(0); //孔的原点       
         myKhHolesMarkARightmd.origin = [xa+w, ya-h1];      
         var myKhHolesXMarkARightmd = new makerjs.models.Square(0);      
         myKhHolesXMarkARightmd.origin =[xa+w, ya-h1];      
         var myKhHolesYMarkARightmd = new makerjs.models.Square(0);      
         myKhHolesYMarkARightmd.origin = [xa+w, ya-h1];  
   /*****************************************************************************/ 
   makerjs.model.addPath(myKhHolesMarkARightmd, new makerjs.paths.Line([0,0], [-w-markShiftThree, 0]));//从孔圆心出发X方向横向标线
   makerjs.model.addPath(myKhHolesMarkARightmd, new makerjs.paths.Line([0,0], [0, h1+markShiftTwo]));//从孔圆心出发Y方向竖向标线
   /*****************************************************************************/ 
   makerjs.model.addPath(myKhHolesMarkARightmd, new makerjs.paths.Line([0,0], [d, -d]));//直径标线
   makerjs.model.addCaption(myKhHolesMarkARightmd, TxtD, [0,0], [d+markHolesOneD, -d-markHolesOneD]);//直径文字标注
   /*****************************************************************************/ 
    //标记晾物孔 A位置孔左下
    var myKhHolesALeftdn= new makerjs.models.Holes(d/2, [[xa, ya-h1-h2]]);
         //标记孔
         var myKhHolesMarkALeftdn = new makerjs.models.Square(0); //孔的原点       
         myKhHolesMarkALeftdn.origin = [xa, ya-h1-h2];     
         var myKhHolesYMarkALeftdn = new makerjs.models.Square(0);      
         myKhHolesYMarkALeftdn.origin = [xa, ya-h1-h2];  
   /*****************************************************************************/ 
   makerjs.model.addPath(myKhHolesMarkALeftdn, new makerjs.paths.Line([0,0], [-x0-markShiftThree, 0]));//从孔圆心出发X方向横向标线
   makerjs.model.addPath(myKhHolesMarkALeftdn, new makerjs.paths.Line([0,0], [0, h2+markShiftTwo]));//从孔圆心出发Y方向竖向标线
   /*****************************************************************************/ 
   makerjs.model.addPath(myKhHolesMarkALeftdn, new makerjs.paths.Line([0,0], [d, -d]));//直径标线
   makerjs.model.addCaption(myKhHolesMarkALeftdn, TxtD, [0,0], [d+markHolesOneD, -d-markHolesOneD]);//直径文字标注
   /*****************************************************************************/ 
   makerjs.model.addPath(myKhHolesYMarkALeftdn, new makerjs.paths.Line([-x0-markShiftTwo,0], [-x0-markShiftTwo, h2]));//Y短标线
   makerjs.model.addCaption(myKhHolesYMarkALeftdn, TxtH2, [-x0-markShiftThree,0], [-x0-markShiftThree, h2]);//Y文字标注
   /*****************************************************************************/ 
   /*****************************************************************************/ 
    //标记晾物孔 A位置孔右下
    var myHhHolesARightdn= new makerjs.models.Holes(d/2, [[xa+w, ya-h1-h2]]);
         //标记孔
         var myHhHolesMarkARightdn = new makerjs.models.Square(0); //孔的原点       
         myHhHolesMarkARightdn.origin = [xa+w, ya-h1-h2];  
   /*****************************************************************************/ 
   makerjs.model.addPath(myHhHolesMarkARightdn, new makerjs.paths.Line([0,0], [-w-markShiftThree, 0]));//从孔圆心出发X方向横向标线
   makerjs.model.addPath(myHhHolesMarkARightdn, new makerjs.paths.Line([0,0], [0, h2+markShiftTwo]));//从孔圆心出发Y方向竖向标线          
   /*****************************************************************************/ 
   makerjs.model.addPath(myHhHolesMarkARightdn, new makerjs.paths.Line([0,0], [d, -d]));//直径标线
   makerjs.model.addCaption(myHhHolesMarkARightdn, TxtD, [0,0], [d+markHolesOneD, -d-markHolesOneD]);//直径文字标注
   /*****************************************************************************/   
   /*****************************************************************************/ 
   plate.models["myHhHolesALeftup"+myHolesNum]=myHhHolesALeftup;
   markers.models["myHhHolesALeftup"+myHolesNum]=myHhHolesALeftup;
   markers.models["myHhHolesMarkALeftup"+myHolesNum]=myHhHolesMarkALeftup;
   markers.models["myKhHolesXMarkALeftup"+myHolesNum]=myKhHolesXMarkALeftup;
   markers.models["myKhHolesYMarkALeftup"+myHolesNum]=myKhHolesYMarkALeftup;
   /*****************************************************************************/
   plate.models["myHhHolesALeftmd"+myHolesNum]=myHhHolesALeftmd;
   markers.models["myHhHolesALeftmd"+myHolesNum]=myHhHolesALeftmd;
   markers.models["myHhHolesMarkALeftmd"+myHolesNum]=myHhHolesMarkALeftmd;
   markers.models["myHhHolesYMarkALeftmd"+myHolesNum]=myHhHolesYMarkALeftmd;
   /*****************************************************************************/ 
   plate.models["myKhHolesALeftdn"+myHolesNum]=myKhHolesALeftdn;
   markers.models["myKhHolesALeftdn"+myHolesNum]=myKhHolesALeftdn;
   markers.models["myKhHolesMarkALeftdn"+myHolesNum]=myKhHolesMarkALeftdn;
   markers.models["myKhHolesYMarkALeftdn"+myHolesNum]=myKhHolesYMarkALeftdn;
   /*****************************************************************************/ 
   plate.models["myHhHolesARightup"+myHolesNum]=myHhHolesARightup;
   markers.models["myHhHolesARightup"+myHolesNum]=myHhHolesARightup;
   markers.models["myHhHolesMarkARightup"+myHolesNum]=myHhHolesMarkARightup;
   markers.models["myHhHolesXMarkARightup"+myHolesNum]=myHhHolesXMarkARightup;
   /*****************************************************************************/ 
   /*****************************************************************************/ 
   plate.models["myKhHolesARightmd"+myHolesNum]=myKhHolesARightmd;
   markers.models["myKhHolesARightmd"+myHolesNum]=myKhHolesARightmd;
   markers.models["myKhHolesMarkARightmd"+myHolesNum]=myKhHolesMarkARightmd;
   /*****************************************************************************/ 
   plate.models["myHhHolesARightdn"+myHolesNum]=myHhHolesARightdn;
   markers.models["myHhHolesARightdn"+myHolesNum]=myHhHolesARightdn;
   markers.models["myHhHolesMarkARightdn"+myHolesNum]=myHhHolesMarkARightdn;
   /*****************************************************************************/ 
  
   }
   else if(area==3){
     //画孔 C位置孔
         //标记晾物孔 C位置孔右上
         var myHhHolesCRightup= new makerjs.models.Holes(d/2, [[xc, yc]]);
         //标记孔
         var myHhHolesMarkCRightup = new makerjs.models.Square(0); //孔的原点       
         myHhHolesMarkCRightup.origin = [xc, yc];      
         var myKhHolesXMarkCRightup = new makerjs.models.Square(0);      
         myKhHolesXMarkCRightup.origin =[xc, yc];      
         var myKhHolesYMarkCRightup = new makerjs.models.Square(0);      
         myKhHolesYMarkCRightup.origin = [xc, yc];  
   
   // /*****************************************************************************/ 
   makerjs.model.addPath(myHhHolesMarkCRightup, new makerjs.paths.Line([x0,y0], [x0, y0+markShiftTwo]));//Y轴加长标线
   makerjs.model.addPath(myHhHolesMarkCRightup, new makerjs.paths.Line([0,0], [x0+markShiftThree, 0]));//从孔圆心出发X方向横向标线
   makerjs.model.addPath(myHhHolesMarkCRightup, new makerjs.paths.Line([x0,y0], [x0+markShiftThree, y0]));//X轴加长标线
   makerjs.model.addPath(myHhHolesMarkCRightup, new makerjs.paths.Line([0,0], [0, y0+markShiftTwo]));//从孔圆心出发Y方向竖向标线
   // // /*****************************************************************************/ 
   makerjs.model.addPath(myHhHolesMarkCRightup, new makerjs.paths.Line([0,0], [d, -d]));//直径标线
   makerjs.model.addCaption(myHhHolesMarkCRightup, TxtD, [0,0], [d+markHolesOneD, -d-markHolesOneD]);//直径文字标注
   // /*****************************************************************************/   
   makerjs.model.addPath(myKhHolesXMarkCRightup, new makerjs.paths.Line([0,y0+markShift], [x0, y0+markShift]));//X短标线
   makerjs.model.addCaption(myKhHolesXMarkCRightup, TxtX, [0, y0+markShiftTwo], [x0,y0+markShiftTwo]);//X文字标注
   // /*****************************************************************************/     
   makerjs.model.addPath(myKhHolesYMarkCRightup, new makerjs.paths.Line([x0+markShift,0], [x0+markShift, y0]));//Y短标线
   makerjs.model.addCaption(myKhHolesYMarkCRightup, TxtY, [x0+markShiftThree,0], [x0+markShiftThree, y0]);//Y文字标注
   // /*****************************************************************************/ 
   // /*****************************************************************************/ 
   //画孔 C位置孔
         //标记晾物孔 C位置孔左上
         var myHhHolesCLeftup= new makerjs.models.Holes(d/2, [[xc-w, yc]]);
         //标记孔
         var myHhHolesMarkCLeftup = new makerjs.models.Square(0); //孔的原点       
         myHhHolesMarkCLeftup.origin = [xc-w, yc];      
         var myHhHolesXMarkCLeftup = new makerjs.models.Square(0);      
         myHhHolesXMarkCLeftup.origin =[xc-w, yc];   
   
   // /*****************************************************************************/ 
   makerjs.model.addPath(myHhHolesMarkCLeftup, new makerjs.paths.Line([0,0], [x0+w+markShiftThree, 0]));//从孔圆心出发X方向横向标线
   makerjs.model.addPath(myHhHolesMarkCLeftup, new makerjs.paths.Line([0,0], [0, y0+markShiftTwo]));//从孔圆心出发Y方向竖向标线
   // /*****************************************************************************/ 
   makerjs.model.addPath(myHhHolesMarkCLeftup, new makerjs.paths.Line([0,0], [d, -d]));//直径标线
   makerjs.model.addCaption(myHhHolesMarkCLeftup, TxtD, [0,0], [d+markHolesOneD, -d-markHolesOneD]);//直径文字标注
   /*****************************************************************************/   
   makerjs.model.addPath(myHhHolesXMarkCLeftup, new makerjs.paths.Line([0,y0+markShift], [w, y0+markShift]));//X短标线
   makerjs.model.addCaption(myHhHolesXMarkCLeftup, TxtW, [0, y0+markShiftTwo], [w,y0+markShiftTwo]);//X文字标注
   // /*****************************************************************************/  
   // /*****************************************************************************/  
   
    //标记晾物孔 C位置孔右中
    var myHhHolesCRightmd= new makerjs.models.Holes(d/2, [[xc, yc-h1]]);
         //标记孔
         var myHhHolesMarkCRightmd = new makerjs.models.Square(0); //孔的原点       
         myHhHolesMarkCRightmd.origin = [xc, yc-h1];     
         var myHhHolesYMarkCRightmd = new makerjs.models.Square(0);      
         myHhHolesYMarkCRightmd.origin = [xc, yc-h1];  
   
   // /*****************************************************************************/ 
   makerjs.model.addPath(myHhHolesMarkCRightmd, new makerjs.paths.Line([0,0], [x0+markShiftThree, 0]));//从孔圆心出发X方向横向标线
   makerjs.model.addPath(myHhHolesMarkCRightmd, new makerjs.paths.Line([0,0], [0, h1+markShiftTwo]));//从孔圆心出发Y方向竖向标线
   /*****************************************************************************/ 
   makerjs.model.addPath(myHhHolesMarkCRightmd, new makerjs.paths.Line([0,0], [d, -d]));//直径标线
   makerjs.model.addCaption(myHhHolesMarkCRightmd, TxtD, [0,0], [d+markHolesOneD, -d-markHolesOneD]);//直径文字标注
   /*****************************************************************************/      
   makerjs.model.addPath(myHhHolesYMarkCRightmd, new makerjs.paths.Line([x0+markShift,0], [x0+markShift, h1]));//Y短标线
   makerjs.model.addCaption(myHhHolesYMarkCRightmd, TxtH1, [x0+markShiftThree,0], [x0+markShiftThree, h1]);//Y文字标注
   // /*****************************************************************************/ 
   
    //标记晾物孔 C位置孔左中
    var myKhHolesCLeftmd= new makerjs.models.Holes(d/2, [[xc-w, yc-h1]]);
         //标记孔
         var myKhHolesMarkCLeftmd = new makerjs.models.Square(0); //孔的原点       
         myKhHolesMarkCLeftmd.origin = [xc-w, yc-h1];      
         var myKhHolesXMarkCLeftmd = new makerjs.models.Square(0);      
         myKhHolesXMarkCLeftmd.origin =[xc-w, yc-h1];      
         var myKhHolesYMarkCLeftmd = new makerjs.models.Square(0);      
         myKhHolesYMarkCLeftmd.origin = [xc-w, yc-h1];  
   
   // /*****************************************************************************/ 
   makerjs.model.addPath(myKhHolesMarkCLeftmd, new makerjs.paths.Line([0,0], [x0+w+markShiftThree, 0]));//从孔圆心出发X方向横向标线
   makerjs.model.addPath(myKhHolesMarkCLeftmd, new makerjs.paths.Line([0,0], [0, h1+markShiftTwo]));//从孔圆心出发Y方向竖向标线
             
   /*****************************************************************************/ 
   makerjs.model.addPath(myKhHolesMarkCLeftmd, new makerjs.paths.Line([0,0], [d, -d]));//直径标线
   makerjs.model.addCaption(myKhHolesMarkCLeftmd, TxtD, [0,0], [d+markHolesOneD, -d-markHolesOneD]);//直径文字标注
   
   /*****************************************************************************/ 
   
    //标记晾物孔C位置孔右下
    var myKhHolesCRightdn= new makerjs.models.Holes(d/2, [[xc, yc-h1-h2]]);
         //标记孔
         var myKhHolesMarkCRightdn = new makerjs.models.Square(0); //孔的原点       
         myKhHolesMarkCRightdn.origin = [xc, yc-h1-h2];        
         var myKhHolesYMarkCRightdn = new makerjs.models.Square(0);      
         myKhHolesYMarkCRightdn.origin = [xc, yc-h1-h2];  
   
   /*****************************************************************************/ 
   makerjs.model.addPath(myKhHolesMarkCRightdn, new makerjs.paths.Line([0,0], [x0+markShiftThree, 0]));//从孔圆心出发X方向横向标线
   makerjs.model.addPath(myKhHolesMarkCRightdn, new makerjs.paths.Line([0,0], [0, h2+markShiftTwo]));//从孔圆心出发Y方向竖向标线
   /*****************************************************************************/ 
   makerjs.model.addPath(myKhHolesMarkCRightdn, new makerjs.paths.Line([0,0], [d, -d]));//直径标线
   makerjs.model.addCaption(myKhHolesMarkCRightdn, TxtD, [0,0], [d+markHolesOneD, -d-markHolesOneD]);//直径文字标注
   /*****************************************************************************/       
   makerjs.model.addPath(myKhHolesYMarkCRightdn, new makerjs.paths.Line([x0+markShift,0], [x0+markShift, h2]));//Y短标线
   makerjs.model.addCaption(myKhHolesYMarkCRightdn, TxtH2, [x0+markShiftThree,0], [x0+markShiftThree, h2]);//Y文字标注
   /*****************************************************************************/ 
   /*****************************************************************************/ 
   
    //标记晾物孔C位置孔左下
    var myHhHolesCLeftdn= new makerjs.models.Holes(d/2, [[xc-w, yc-h1-h2]]);
         //标记孔
         var myHhHolesMarkCLeftdn = new makerjs.models.Square(0); //孔的原点       
         myHhHolesMarkCLeftdn.origin = [xc-w, yc-h1-h2];   
   
   // /*****************************************************************************/ 
   makerjs.model.addPath(myHhHolesMarkCLeftdn, new makerjs.paths.Line([0,0], [x0+w+markShiftThree, 0]));//从孔圆心出发X方向横向标线
   makerjs.model.addPath(myHhHolesMarkCLeftdn, new makerjs.paths.Line([0,0], [0, h2+markShiftTwo]));//从孔圆心出发Y方向竖向标线
             
   /*****************************************************************************/ 
   makerjs.model.addPath(myHhHolesMarkCLeftdn, new makerjs.paths.Line([0,0], [d, -d]));//直径标线
   makerjs.model.addCaption(myHhHolesMarkCLeftdn, TxtD, [0,0], [d+markHolesOneD, -d-markHolesOneD]);//直径文字标注
   /*****************************************************************************/   
   plate.models["myHhHolesCRightup"+myHolesNum]=myHhHolesCRightup;
   markers.models["myHhHolesCRightup"+myHolesNum]=myHhHolesCRightup;
   markers.models["myHhHolesMarkCRightup"+myHolesNum]=myHhHolesMarkCRightup;
   markers.models["myKhHolesXMarkCRightup"+myHolesNum]=myKhHolesXMarkCRightup;
   markers.models["myKhHolesYMarkCRightup"+myHolesNum]=myKhHolesYMarkCRightup;
   // /*****************************************************************************/
   plate.models["myHhHolesCRightmd"+myHolesNum]=myHhHolesCRightmd;
   markers.models["myHhHolesCRightmd"+myHolesNum]=myHhHolesCRightmd;
   markers.models["myHhHolesMarkCRightmd"+myHolesNum]=myHhHolesMarkCRightmd;
   markers.models["myHhHolesYMarkCRightmd"+myHolesNum]=myHhHolesYMarkCRightmd;
   
   /*****************************************************************************/ 
   plate.models["myKhHolesCRightdn"+myHolesNum]=myKhHolesCRightdn;
   markers.models["myKhHolesCRightdn"+myHolesNum]=myKhHolesCRightdn;
   markers.models["myKhHolesMarkCRighttdn"+myHolesNum]=myKhHolesMarkCRightdn;
   markers.models["myKhHolesYMarkCRightdn"+myHolesNum]=myKhHolesYMarkCRightdn;
   
   /*****************************************************************************/ 
   plate.models["myHhHolesCLeftup"+myHolesNum]=myHhHolesCLeftup;
   markers.models["myHhHolesCLeftup"+myHolesNum]=myHhHolesCLeftup;
   markers.models["myHhHolesMarkCLeftup"+myHolesNum]=myHhHolesMarkCLeftup;
   markers.models["myHhHolesXMarkCLeftup"+myHolesNum]=myHhHolesXMarkCLeftup;
   /*****************************************************************************/ 
   
   /*****************************************************************************/ 
   
   
   plate.models["myKhHolesCLeftmd"+myHolesNum]=myKhHolesCLeftmd;
   markers.models["myKhHolesCLeftmd"+myHolesNum]=myKhHolesCLeftmd;
   markers.models["myKhHolesMarkCLeftmd"+myHolesNum]=myKhHolesMarkCLeftmd;
   
   /*****************************************************************************/ 
   plate.models["myHhHolesCLeftdn"+myHolesNum]=myHhHolesCLeftdn;
   markers.models["myHhHolesCLeftdn"+myHolesNum]=myHhHolesCLeftdn;
   markers.models["myHhHolesMarkCLeftdn"+myHolesNum]=myHhHolesMarkCLeftdn;
   /*****************************************************************************/ 
   }
   else if(area==4){
   //画孔 D位置孔
         //标记晾物孔 D位置孔左上
         var myHhHolesDLeftup= new makerjs.models.Holes(d/2, [[xd, yd+h1]]);
         //标记孔
         var myHhHolesMarkDLeftup = new makerjs.models.Square(0); //孔的原点       
         myHhHolesMarkDLeftup.origin = [xd, yd+h1];      
         var myKhHolesXMarkDLeftup = new makerjs.models.Square(0);      
         myKhHolesXMarkDLeftup.origin =[xd, yd+h1];      
         var myKhHolesYMarkDLeftup = new makerjs.models.Square(0);      
         myKhHolesYMarkDLeftup.origin = [xd, yd+h1];  
   
   /*****************************************************************************/ 
   makerjs.model.addPath(myHhHolesMarkDLeftup, new makerjs.paths.Line([-x0,ye-h1], [-x0, ye-h1+markShiftTwo]));//Y轴加长标线
   makerjs.model.addPath(myHhHolesMarkDLeftup, new makerjs.paths.Line([0,0], [-x0, 0]));//从孔圆心出发X方向横向标线
   makerjs.model.addPath(myHhHolesMarkDLeftup, new makerjs.paths.Line([0,0], [0, ye-h1+markShiftTwo]));//从孔圆心出发Y方向竖向标线
   /*****************************************************************************/ 
   makerjs.model.addPath(myHhHolesMarkDLeftup, new makerjs.paths.Line([0,0], [d, -d]));//直径标线
   makerjs.model.addCaption(myHhHolesMarkDLeftup, TxtD, [0,0], [d+markHolesOneD, -d-markHolesOneD]);//直径文字标注
   /*****************************************************************************/   
   makerjs.model.addPath(myKhHolesXMarkDLeftup, new makerjs.paths.Line([0,ye-h1+markShift], [-x0, ye-h1+markShift]));//X短标线
   makerjs.model.addCaption(myKhHolesXMarkDLeftup, TxtX, [-x0,ye-h1+markShiftTwo], [0, ye-h1+markShiftTwo]);//X文字标注
   /*****************************************************************************/  
   /*****************************************************************************/ 
   /*****************************************************************************/ 
   //画孔 D位置孔
         //标记晾物孔 D位置孔右上
         var myHhHolesDRightup= new makerjs.models.Holes(d/2, [[xd+w, yd+h1]]);
         //标记孔
         var myHhHolesMarkDRightup = new makerjs.models.Square(0); //孔的原点       
         myHhHolesMarkDRightup.origin = [xd+w, yd+h1];      
         var myHhHolesXMarkDRightup = new makerjs.models.Square(0);      
         myHhHolesXMarkDRightup.origin =[xd+w, yd+h1];  
   
   /*****************************************************************************/ 
   makerjs.model.addPath(myHhHolesMarkDRightup, new makerjs.paths.Line([0,0], [-w, 0]));//从孔圆心出发X方向横向标线
   makerjs.model.addPath(myHhHolesMarkDRightup, new makerjs.paths.Line([0,0], [d+markShiftThree, 0]));//从孔圆心出发X方向横向标线 向右
   makerjs.model.addPath(myHhHolesMarkDRightup, new makerjs.paths.Line([0,0], [0, ye-h1+markShiftTwo]));//从孔圆心出发Y方向竖向标线
             
   /*****************************************************************************/ 
   makerjs.model.addPath(myHhHolesMarkDRightup, new makerjs.paths.Line([0,0], [d, -d]));//直径标线
   makerjs.model.addCaption(myHhHolesMarkDRightup, TxtD, [0,0], [d+markHolesOneD, -d-markHolesOneD]);//直径文字标注
   /*****************************************************************************/   
   makerjs.model.addPath(myHhHolesXMarkDRightup, new makerjs.paths.Line([0,ye-h1+markShift], [-w, ye-h1+markShift]));//X短标线
   makerjs.model.addCaption(myHhHolesXMarkDRightup, TxtW, [-w,ye-h1+markShiftTwo], [0, ye-h1+markShiftTwo]);//X文字标注
   /*****************************************************************************/  
   
    //标记晾物孔 D位置孔左中
    var myHhHolesDLeftmd= new makerjs.models.Holes(d/2, [[xd, yd]]);
         //标记孔
         var myHhHolesMarkDLeftmd = new makerjs.models.Square(0); //孔的原点       
         myHhHolesMarkDLeftmd.origin = [xd, yd];     
         var myHhHolesYMarkDLeftmd = new makerjs.models.Square(0);      
         myHhHolesYMarkDLeftmd.origin = [xd, yd];  
   
   /*****************************************************************************/ 
   makerjs.model.addPath(myHhHolesMarkDLeftmd, new makerjs.paths.Line([0,0], [-x0, 0]));//从孔圆心出发X方向横向标线
   makerjs.model.addPath(myHhHolesMarkDLeftmd, new makerjs.paths.Line([0,0], [0, h1+markShiftTwo]));//从孔圆心出发Y方向竖向标线
   /*****************************************************************************/ 
   makerjs.model.addPath(myHhHolesMarkDLeftmd, new makerjs.paths.Line([0,0], [d, -d]));//直径标线
   makerjs.model.addCaption(myHhHolesMarkDLeftmd, TxtD, [0,0], [d+markHolesOneD, -d-markHolesOneD]);//直径文字标注
   /*****************************************************************************/   
   /*****************************************************************************/     
   makerjs.model.addPath(myHhHolesYMarkDLeftmd, new makerjs.paths.Line([w+d+markShiftTwo,0], [w+d+markShiftTwo, h1]));//Y短标线
   makerjs.model.addCaption(myHhHolesYMarkDLeftmd, TxtH1, [w+d+markShiftFour,0], [w+d+markShiftFour, h1]);//Y文字标注
   /*****************************************************************************/ 
   /*****************************************************************************/ 
   
    //标记晾物孔D位置孔右中
    var myKhHolesDRightmd= new makerjs.models.Holes(d/2, [[xd+w, yd]]);
         //标记孔
         var myKhHolesMarkDRightmd = new makerjs.models.Square(0); //孔的原点       
         myKhHolesMarkDRightmd.origin = [xd+w, yd];      
         var myKhHolesXMarkDRightmd = new makerjs.models.Square(0);      
         myKhHolesXMarkDRightmd.origin =[xd+w, yd];      
         var myKhHolesYMarkDRightmd = new makerjs.models.Square(0);      
         myKhHolesYMarkDRightmd.origin = [xd+w, yd];  
   
   /*****************************************************************************/ 
   makerjs.model.addPath(myKhHolesMarkDRightmd, new makerjs.paths.Line([0,0], [-w, 0]));//从孔圆心出发X方向横向标线
   makerjs.model.addPath(myKhHolesMarkDRightmd, new makerjs.paths.Line([0,0], [d+markShiftThree, 0]));//从孔圆心出发X方向横向标线
   makerjs.model.addPath(myKhHolesMarkDRightmd, new makerjs.paths.Line([0,0], [0, h1+markShiftTwo]));//从孔圆心出发Y方向竖向标线
             
   /*****************************************************************************/ 
   makerjs.model.addPath(myKhHolesMarkDRightmd, new makerjs.paths.Line([0,0], [d, -d]));//直径标线
   makerjs.model.addCaption(myKhHolesMarkDRightmd, TxtD, [0,0], [d+markHolesOneD, -d-markHolesOneD]);//直径文字标注
   
   /*****************************************************************************/ 
   
    //标记晾物孔 D位置孔左下
    var myKhHolesDLeftdn= new makerjs.models.Holes(d/2, [[xd, yd-h2]]);
         //标记孔
         var myKhHolesMarkDLeftdn = new makerjs.models.Square(0); //孔的原点       
         myKhHolesMarkDLeftdn.origin = [xd, yd-h2];     
         var myKhHolesYMarkDLeftdn = new makerjs.models.Square(0);      
         myKhHolesYMarkDLeftdn.origin = [xd, yd-h2];  
   
   /*****************************************************************************/ 
   makerjs.model.addPath(myKhHolesMarkDLeftdn, new makerjs.paths.Line([0,0], [-x0, 0]));//从孔圆心出发X方向横向标线
   makerjs.model.addPath(myKhHolesMarkDLeftdn, new makerjs.paths.Line([0,0], [0, h2+markShiftTwo]));//从孔圆心出发Y方向竖向标线
   /*****************************************************************************/ 
   makerjs.model.addPath(myKhHolesMarkDLeftdn, new makerjs.paths.Line([0,0], [d, -d]));//直径标线
   makerjs.model.addCaption(myKhHolesMarkDLeftdn, TxtD, [0,0], [d+markHolesOneD, -d-markHolesOneD]);//直径文字标注
   /*****************************************************************************/ 
   makerjs.model.addPath(myKhHolesYMarkDLeftdn, new makerjs.paths.Line([w+d+markShiftTwo,0], [w+d+markShiftTwo, h2]));//Y短标线
   makerjs.model.addCaption(myKhHolesYMarkDLeftdn, TxtH2, [w+d+markShiftFour,0], [w+d+markShiftFour, h2]);//Y文字标注
   /*****************************************************************************/ 
   /*****************************************************************************/ 
   
    //标记晾物孔 D位置孔右下
    var myHhHolesDRightdn= new makerjs.models.Holes(d/2, [[xd+w, yd-h2]]);
         //标记孔
         var myHhHolesMarkDRightdn = new makerjs.models.Square(0); //孔的原点       
         myHhHolesMarkDRightdn.origin = [xd+w, yd-h2];  
   /*****************************************************************************/ 
   makerjs.model.addPath(myHhHolesMarkDRightdn, new makerjs.paths.Line([0,0], [-w, 0]));//从孔圆心出发X方向横向标线
   makerjs.model.addPath(myHhHolesMarkDRightdn, new makerjs.paths.Line([0,0], [d+markShiftThree, 0]));//从孔圆心出发X方向横向标线
   makerjs.model.addPath(myHhHolesMarkDRightdn, new makerjs.paths.Line([0,0], [0, h2+markShiftTwo]));//从孔圆心出发Y方向竖向标线          
   /*****************************************************************************/ 
   makerjs.model.addPath(myHhHolesMarkDRightdn, new makerjs.paths.Line([0,0], [d, -d]));//直径标线
   makerjs.model.addCaption(myHhHolesMarkDRightdn, TxtD, [0,0], [d+markHolesOneD, -d-markHolesOneD]);//直径文字标注
   /*****************************************************************************/   
   /*****************************************************************************/ 
   plate.models["myHhHolesDLeftup"+myHolesNum]=myHhHolesDLeftup;
   markers.models["myHhHolesDLeftup"+myHolesNum]=myHhHolesDLeftup;
   markers.models["myHhHolesMarkDLeftup"+myHolesNum]=myHhHolesMarkDLeftup;
   markers.models["myKhHolesXMarkDLeftup"+myHolesNum]=myKhHolesXMarkDLeftup;
   markers.models["myKhHolesYMarkDLeftup"+myHolesNum]=myKhHolesYMarkDLeftup;
   /*****************************************************************************/
   plate.models["myHhHolesDLeftmd"+myHolesNum]=myHhHolesDLeftmd;
   markers.models["myHhHolesDLeftmd"+myHolesNum]=myHhHolesDLeftmd;
   markers.models["myHhHolesMarkDLeftmd"+myHolesNum]=myHhHolesMarkDLeftmd;
   markers.models["myHhHolesYMarkDLeftmd"+myHolesNum]=myHhHolesYMarkDLeftmd;
   /*****************************************************************************/ 
   plate.models["myKhHolesDLeftdn"+myHolesNum]=myKhHolesDLeftdn;
   markers.models["myKhHolesDLeftdn"+myHolesNum]=myKhHolesDLeftdn;
   markers.models["myKhHolesMarkDLeftdn"+myHolesNum]=myKhHolesMarkDLeftdn;
   markers.models["myKhHolesYMarkDLeftdn"+myHolesNum]=myKhHolesYMarkDLeftdn;
   /*****************************************************************************/ 
   plate.models["myHhHolesDRightup"+myHolesNum]=myHhHolesDRightup;
   markers.models["myHhHolesDRightup"+myHolesNum]=myHhHolesDRightup;
   markers.models["myHhHolesMarkDRightup"+myHolesNum]=myHhHolesMarkDRightup;
   markers.models["myHhHolesXMarkDRightup"+myHolesNum]=myHhHolesXMarkDRightup;
   /*****************************************************************************/ 
   /*****************************************************************************/ 
   plate.models["myKhHolesDRightmd"+myHolesNum]=myKhHolesDRightmd;
   markers.models["myKhHolesDRightmd"+myHolesNum]=myKhHolesDRightmd;
   markers.models["myKhHolesMarkDRightmd"+myHolesNum]=myKhHolesMarkDRightmd;
   /*****************************************************************************/ 
   plate.models["myHhHolesDRightdn"+myHolesNum]=myHhHolesDRightdn;
   markers.models["myHhHolesDRightdn"+myHolesNum]=myHhHolesDRightdn;
   markers.models["myHhHolesMarkDRightdn"+myHolesNum]=myHhHolesMarkDRightdn;
   /*****************************************************************************/ 
   }
   else if(area==6){
    //画孔 F位置孔
         //标记晾物孔 F位置孔右上
         var myHhHolesFRightup= new makerjs.models.Holes(d/2, [[xf, yf+h1]]);
         //标记孔
         var myHhHolesMarkFRightup = new makerjs.models.Square(0); //孔的原点       
         myHhHolesMarkFRightup.origin = [xf, yf+h1];      
         var myKhHolesXMarkFRightup = new makerjs.models.Square(0);      
         myKhHolesXMarkFRightup.origin =[xf, yf+h1];      
         var myKhHolesYMarkFRightup = new makerjs.models.Square(0);      
         myKhHolesYMarkFRightup.origin = [xf, yf+h1];  
   
   // /*****************************************************************************/ 
   makerjs.model.addPath(myHhHolesMarkFRightup, new makerjs.paths.Line([x0,y0], [x0, ye-h1+markShiftTwo]));//Y轴加长标线
   makerjs.model.addPath(myHhHolesMarkFRightup, new makerjs.paths.Line([0,0], [x0+markShiftThree, 0]));//从孔圆心出发X方向横向标线
   makerjs.model.addPath(myHhHolesMarkFRightup, new makerjs.paths.Line([0,0], [0, ye-h1+markShiftTwo]));//从孔圆心出发Y方向竖向标线
   // // /*****************************************************************************/ 
   makerjs.model.addPath(myHhHolesMarkFRightup, new makerjs.paths.Line([0,0], [d, -d]));//直径标线
   makerjs.model.addCaption(myHhHolesMarkFRightup, TxtD, [0,0], [d+markHolesOneD, -d-markHolesOneD]);//直径文字标注
   // /*****************************************************************************/   
   makerjs.model.addPath(myKhHolesXMarkFRightup, new makerjs.paths.Line([0,ye-h1+markShift], [x0, ye-h1+markShift]));//X短标线
   makerjs.model.addCaption(myKhHolesXMarkFRightup, TxtX, [0, ye-h1+markShiftTwo], [x0,ye-h1+markShiftTwo]);//X文字标注
   // /*****************************************************************************/    
   // /*****************************************************************************/ 
   // /*****************************************************************************/ 
   //画孔 F位置孔
         //标记晾物孔 F位置孔左上
         var myHhHolesFLeftup= new makerjs.models.Holes(d/2, [[xf-w, yf+h1]]);
         //标记孔
         var myHhHolesMarkFLeftup = new makerjs.models.Square(0); //孔的原点       
         myHhHolesMarkFLeftup.origin = [xf-w, yf+h1];      
         var myHhHolesXMarkFLeftup = new makerjs.models.Square(0);      
         myHhHolesXMarkFLeftup.origin =[xf-w, yf+h1];   
   
   // /*****************************************************************************/ 
   makerjs.model.addPath(myHhHolesMarkFLeftup, new makerjs.paths.Line([0,0], [w, 0]));//从孔圆心出发X方向横向标线
   makerjs.model.addPath(myHhHolesMarkFLeftup, new makerjs.paths.Line([0,0], [0, ye-h1+markShiftTwo]));//从孔圆心出发Y方向竖向标线
   // /*****************************************************************************/ 
   makerjs.model.addPath(myHhHolesMarkFLeftup, new makerjs.paths.Line([0,0], [d, -d]));//直径标线
   makerjs.model.addCaption(myHhHolesMarkFLeftup, TxtD, [0,0], [d+markHolesOneD, -d-markHolesOneD]);//直径文字标注
   /*****************************************************************************/   
   makerjs.model.addPath(myHhHolesXMarkFLeftup, new makerjs.paths.Line([0,ye-h1+markShift], [w, ye-h1+markShift]));//X短标线
   makerjs.model.addCaption(myHhHolesXMarkFLeftup, TxtW, [0, ye-h1+markShiftTwo], [w,ye-h1+markShiftTwo]);//X文字标注
   // /*****************************************************************************/  
   // /*****************************************************************************/  
   
    //标记晾物孔 F位置孔右中
    var myHhHolesFRightmd= new makerjs.models.Holes(d/2, [[xf, yf]]);
         //标记孔
         var myHhHolesMarkFRightmd = new makerjs.models.Square(0); //孔的原点       
         myHhHolesMarkFRightmd.origin = [xf, yf];     
         var myHhHolesYMarkFRightmd = new makerjs.models.Square(0);      
         myHhHolesYMarkFRightmd.origin = [xf, yf];  
   
   // /*****************************************************************************/ 
   makerjs.model.addPath(myHhHolesMarkFRightmd, new makerjs.paths.Line([0,0], [x0+markShiftThree, 0]));//从孔圆心出发X方向横向标线
   makerjs.model.addPath(myHhHolesMarkFRightmd, new makerjs.paths.Line([0,0], [0, h1+markShiftTwo]));//从孔圆心出发Y方向竖向标线
   /*****************************************************************************/ 
   makerjs.model.addPath(myHhHolesMarkFRightmd, new makerjs.paths.Line([0,0], [d, -d]));//直径标线
   makerjs.model.addCaption(myHhHolesMarkFRightmd, TxtD, [0,0], [d+markHolesOneD, -d-markHolesOneD]);//直径文字标注
   /*****************************************************************************/      
   makerjs.model.addPath(myHhHolesYMarkFRightmd, new makerjs.paths.Line([x0+markShift,0], [x0+markShift, h1]));//H短标线
   makerjs.model.addCaption(myHhHolesYMarkFRightmd, TxtH1, [x0+markShiftThree,0], [x0+markShiftThree, h1]);//H文字标注
   // /*****************************************************************************/ 
   
    //标记晾物孔 F位置孔左中
    var myKhHolesFLeftmd= new makerjs.models.Holes(d/2, [[xf-w, yf]]);
         //标记孔
         var myKhHolesMarkFLeftmd = new makerjs.models.Square(0); //孔的原点       
         myKhHolesMarkFLeftmd.origin = [xf-w, yf];      
         var myKhHolesXMarkFLeftmd = new makerjs.models.Square(0);      
         myKhHolesXMarkFLeftmd.origin =[xf-w, yf];      
         var myKhHolesYMarkFLeftmd = new makerjs.models.Square(0);      
         myKhHolesYMarkFLeftmd.origin = [xf-w, yf];  
   
   // /*****************************************************************************/ 
   makerjs.model.addPath(myKhHolesMarkFLeftmd, new makerjs.paths.Line([0,0], [w, 0]));//从孔圆心出发X方向横向标线
   makerjs.model.addPath(myKhHolesMarkFLeftmd, new makerjs.paths.Line([0,0], [0, h1+markShiftTwo]));//从孔圆心出发Y方向竖向标线
             
   /*****************************************************************************/ 
   makerjs.model.addPath(myKhHolesMarkFLeftmd, new makerjs.paths.Line([0,0], [d, -d]));//直径标线
   makerjs.model.addCaption(myKhHolesMarkFLeftmd, TxtD, [0,0], [d+markHolesOneD, -d-markHolesOneD]);//直径文字标注
   
   /*****************************************************************************/ 
   
    //标记晾物孔F位置孔右下
    var myKhHolesFRightdn= new makerjs.models.Holes(d/2, [[xf, yf-h2]]);
         //标记孔
         var myKhHolesMarkFRightdn = new makerjs.models.Square(0); //孔的原点       
         myKhHolesMarkFRightdn.origin = [xf, yf-h2];        
         var myKhHolesYMarkFRightdn = new makerjs.models.Square(0);      
         myKhHolesYMarkFRightdn.origin = [xf, yf-h2];  
   
   /*****************************************************************************/ 
   makerjs.model.addPath(myKhHolesMarkFRightdn, new makerjs.paths.Line([0,0], [x0+markShiftThree, 0]));//从孔圆心出发X方向横向标线
   makerjs.model.addPath(myKhHolesMarkFRightdn, new makerjs.paths.Line([0,0], [0, h2+markShiftTwo]));//从孔圆心出发Y方向竖向标线
   /*****************************************************************************/ 
   makerjs.model.addPath(myKhHolesMarkFRightdn, new makerjs.paths.Line([0,0], [d, -d]));//直径标线
   makerjs.model.addCaption(myKhHolesMarkFRightdn, TxtD, [0,0], [d+markHolesOneD, -d-markHolesOneD]);//直径文字标注
   /*****************************************************************************/       
   makerjs.model.addPath(myKhHolesYMarkFRightdn, new makerjs.paths.Line([x0+markShift,0], [x0+markShift, h2]));//Y短标线
   makerjs.model.addCaption(myKhHolesYMarkFRightdn, TxtH2, [x0+markShiftThree,0], [x0+markShiftThree, h2]);//Y文字标注
   /*****************************************************************************/ 
   /*****************************************************************************/ 
   
    //标记晾物孔F位置孔左下
    var myHhHolesFLeftdn= new makerjs.models.Holes(d/2, [[xf-w, yf-h2]]);
         //标记孔
         var myHhHolesMarkFLeftdn = new makerjs.models.Square(0); //孔的原点       
         myHhHolesMarkFLeftdn.origin = [xf-w, yf-h2];   
   
   // /*****************************************************************************/ 
   makerjs.model.addPath(myHhHolesMarkFLeftdn, new makerjs.paths.Line([0,0], [w, 0]));//从孔圆心出发X方向横向标线
   makerjs.model.addPath(myHhHolesMarkFLeftdn, new makerjs.paths.Line([0,0], [0, h2+markShiftTwo]));//从孔圆心出发Y方向竖向标线
             
   /*****************************************************************************/ 
   makerjs.model.addPath(myHhHolesMarkFLeftdn, new makerjs.paths.Line([0,0], [d, -d]));//直径标线
   makerjs.model.addCaption(myHhHolesMarkFLeftdn, TxtD, [0,0], [d+markHolesOneD, -d-markHolesOneD]);//直径文字标注
   /*****************************************************************************/   
   plate.models["myHhHolesFRightup"+myHolesNum]=myHhHolesFRightup;
   markers.models["myHhHolesFRightup"+myHolesNum]=myHhHolesFRightup;
   markers.models["myHhHolesMarkFRightup"+myHolesNum]=myHhHolesMarkFRightup;
   markers.models["myKhHolesXMarkFRightup"+myHolesNum]=myKhHolesXMarkFRightup;
   markers.models["myKhHolesYMarkFRightup"+myHolesNum]=myKhHolesYMarkFRightup;
   // /*****************************************************************************/
   plate.models["myHhHolesFRightmd"+myHolesNum]=myHhHolesFRightmd;
   markers.models["myHhHolesFRightmd"+myHolesNum]=myHhHolesFRightmd;
   markers.models["myHhHolesMarkFRightmd"+myHolesNum]=myHhHolesMarkFRightmd;
   markers.models["myHhHolesYMarkFRightmd"+myHolesNum]=myHhHolesYMarkFRightmd;
   
   /*****************************************************************************/ 
   plate.models["myKhHolesFRightdn"+myHolesNum]=myKhHolesFRightdn;
   markers.models["myKhHolesFRightdn"+myHolesNum]=myKhHolesFRightdn;
   markers.models["myKhHolesMarkFRighttdn"+myHolesNum]=myKhHolesMarkFRightdn;
   markers.models["myKhHolesYMarkFRightdn"+myHolesNum]=myKhHolesYMarkFRightdn;
   
   /*****************************************************************************/ 
   plate.models["myHhHolesFLeftup"+myHolesNum]=myHhHolesFLeftup;
   markers.models["myHhHolesFLeftup"+myHolesNum]=myHhHolesFLeftup;
   markers.models["myHhHolesMarkFLeftup"+myHolesNum]=myHhHolesMarkFLeftup;
   markers.models["myHhHolesXMarkFLeftup"+myHolesNum]=myHhHolesXMarkFLeftup;
   /*****************************************************************************/ 
   
   /*****************************************************************************/ 
   
   
   plate.models["myKhHolesFLeftmd"+myHolesNum]=myKhHolesFLeftmd;
   markers.models["myKhHolesFLeftmd"+myHolesNum]=myKhHolesFLeftmd;
   markers.models["myKhHolesMarkFLeftmd"+myHolesNum]=myKhHolesMarkFLeftmd;
   
   /*****************************************************************************/ 
   plate.models["myHhHolesFLeftdn"+myHolesNum]=myHhHolesFLeftdn;
   markers.models["myHhHolesFLeftdn"+myHolesNum]=myHhHolesFLeftdn;
   markers.models["myHhHolesMarkFLeftdn"+myHolesNum]=myHhHolesMarkFLeftdn;
   /*****************************************************************************/   
   }
   else if(area==7){
    //画孔 G位置孔
         //标记晾物孔 G位置孔左下
         var myHhHolesGLeftdn= new makerjs.models.Holes(d/2, [[xg, yg]]);
         //标记孔
         var myHhHolesMarkGLeftdn = new makerjs.models.Square(0); //孔的原点       
         myHhHolesMarkGLeftdn.origin = [xg, yg];      
         var myKhHolesXMarkGLeftdn = new makerjs.models.Square(0);      
         myKhHolesXMarkGLeftdn.origin =[xg, yg];      
         var myKhHolesYMarkGLeftdn = new makerjs.models.Square(0);      
         myKhHolesYMarkGLeftdn.origin = [xg, yg];  
   /*****************************************************************************/ 
   makerjs.model.addPath(myHhHolesMarkGLeftdn, new makerjs.paths.Line([-x0,-y0], [-x0, -y0-markShiftFour]));//Y轴加长标线
   makerjs.model.addPath(myHhHolesMarkGLeftdn, new makerjs.paths.Line([0,0], [-x0-markShiftFour, 0]));//从孔圆心出发X方向横向标线
   makerjs.model.addPath(myHhHolesMarkGLeftdn, new makerjs.paths.Line([-x0,-y0], [-x0-markShiftFour, -y0]));//X轴加长标线
   makerjs.model.addPath(myHhHolesMarkGLeftdn, new makerjs.paths.Line([0,0], [0, -y0-markShiftFour]));//从孔圆心出发Y方向竖向标线
   /*****************************************************************************/ 
   makerjs.model.addPath(myHhHolesMarkGLeftdn, new makerjs.paths.Line([0,0], [d, -d]));//直径标线
   makerjs.model.addCaption(myHhHolesMarkGLeftdn, TxtD, [0,0], [d+markHolesOneD, -d-markHolesOneD]);//直径文字标注
   /*****************************************************************************/   
   makerjs.model.addPath(myKhHolesXMarkGLeftdn, new makerjs.paths.Line([0,-y0-markShiftTwo], [-x0, -y0-markShiftTwo]));//X短标线
   makerjs.model.addCaption(myKhHolesXMarkGLeftdn, TxtX, [-x0,-y0-markShiftFour], [0, -y0-markShiftFour]);//X文字标注
   /*****************************************************************************/     
   makerjs.model.addPath(myKhHolesYMarkGLeftdn, new makerjs.paths.Line([-x0-markShiftTwo,0], [-x0-markShiftTwo, -y0]));//Y短标线
   makerjs.model.addCaption(myKhHolesYMarkGLeftdn, TxtY, [-x0-markShiftFour, -y0], [-x0-markShiftFour,0]);//Y文字标注
   // /*****************************************************************************/ 
   // /*****************************************************************************/ 
   //画孔 G位置孔
         //标记晾物孔 G位置孔右下
         var myHhHolesGRightdn= new makerjs.models.Holes(d/2, [[xg+w, yg]]);
         //标记孔
         var myHhHolesMarkGRightdn = new makerjs.models.Square(0); //孔的原点       
         myHhHolesMarkGRightdn.origin = [xg+w, yg];      
         var myHhHolesXMarkGRightdn = new makerjs.models.Square(0);      
         myHhHolesXMarkGRightdn.origin =[xg+w, yg]; 
   
   /*****************************************************************************/ 
   makerjs.model.addPath(myHhHolesMarkGRightdn, new makerjs.paths.Line([0,0], [-x0-w-markShiftFour, 0]));//从孔圆心出发X方向横向标线
   makerjs.model.addPath(myHhHolesMarkGRightdn, new makerjs.paths.Line([0,0], [0, -y0-markShiftFour]));//从孔圆心出发Y方向竖向标线
   /*****************************************************************************/ 
   makerjs.model.addPath(myHhHolesMarkGRightdn, new makerjs.paths.Line([0,0], [d, -d]));//直径标线
   makerjs.model.addCaption(myHhHolesMarkGRightdn, TxtD, [0,0], [d+markHolesOneD, -d-markHolesOneD]);//直径文字标注
   /*****************************************************************************/   
   makerjs.model.addPath(myHhHolesXMarkGRightdn, new makerjs.paths.Line([0,-y0-markShiftTwo], [-w, -y0-markShiftTwo]));//X短标线
   makerjs.model.addCaption(myHhHolesXMarkGRightdn, TxtW, [-w,-y0-markShiftFour], [0, -y0-markShiftFour]);//X文字标注
   /*****************************************************************************/  
   /*****************************************************************************/ 
   
    //标记晾物孔 G位置孔左中
    var myHhHolesGLeftmd= new makerjs.models.Holes(d/2, [[xg, yg+h1]]);
         //标记孔
         var myHhHolesMarkGLeftmd = new makerjs.models.Square(0); //孔的原点       
         myHhHolesMarkGLeftmd.origin = [xg, yg+h1];     
         var myHhHolesYMarkGLeftmd = new makerjs.models.Square(0);      
         myHhHolesYMarkGLeftmd.origin = [xg, yg+h1];  
   
   /*****************************************************************************/ 
   makerjs.model.addPath(myHhHolesMarkGLeftmd, new makerjs.paths.Line([0,0], [-x0-markShiftFour, 0]));//从孔圆心出发X方向横向标线
   makerjs.model.addPath(myHhHolesMarkGLeftmd, new makerjs.paths.Line([0,0], [0, -h1-markShiftTwo]));//从孔圆心出发Y方向竖向标线
   /*****************************************************************************/ 
   makerjs.model.addPath(myHhHolesMarkGLeftmd, new makerjs.paths.Line([0,0], [d, -d]));//直径标线
   makerjs.model.addCaption(myHhHolesMarkGLeftmd, TxtD, [0,0], [d+markHolesOneD, -d-markHolesOneD]);//直径文字标注
   /*****************************************************************************/  
   /*****************************************************************************/     
   makerjs.model.addPath(myHhHolesYMarkGLeftmd, new makerjs.paths.Line([-x0-markShiftTwo,0], [-x0-markShiftTwo, -h1]));//Y短标线
   makerjs.model.addCaption(myHhHolesYMarkGLeftmd, TxtH1, [-x0-markShiftFour, -h1], [-x0-markShiftFour,0]);//Y文字标注
   /*****************************************************************************/ 
   /*****************************************************************************/ 
   
    //标记晾物孔 G位置孔右中
    var myKhHolesGRightmd= new makerjs.models.Holes(d/2, [[xg+w, yg+h1]]);
         //标记孔
         var myKhHolesMarkGRightmd = new makerjs.models.Square(0); //孔的原点       
         myKhHolesMarkGRightmd.origin = [xg+w, yg+h1];      
         var myKhHolesXMarkGRightmd = new makerjs.models.Square(0);      
         myKhHolesXMarkGRightmd.origin =[xg+w, yg+h1];      
         var myKhHolesYMarkGRightmd = new makerjs.models.Square(0);      
         myKhHolesYMarkGRightmd.origin = [xg+w, yg+h1];  
   // /*****************************************************************************/ 
   makerjs.model.addPath(myKhHolesMarkGRightmd, new makerjs.paths.Line([0,0], [-x0-w-markShiftThree, 0]));//从孔圆心出发X方向横向标线
   makerjs.model.addPath(myKhHolesMarkGRightmd, new makerjs.paths.Line([0,0], [0, -h1-markShiftTwo]));//从孔圆心出发Y方向竖向标线          
   // /*****************************************************************************/ 
   makerjs.model.addPath(myKhHolesMarkGRightmd, new makerjs.paths.Line([0,0], [d, -d]));//直径标线
   makerjs.model.addCaption(myKhHolesMarkGRightmd, TxtD, [0,0], [d+markHolesOneD, -d-markHolesOneD]);//直径文字标注
   // /*****************************************************************************/ 
   
    //标记晾物孔 G位置孔左上
    var myKhHolesGLeftup= new makerjs.models.Holes(d/2, [[xg, yg+h1+h2]]);
         //标记孔
         var myKhHolesMarkGLeftup = new makerjs.models.Square(0); //孔的原点       
         myKhHolesMarkGLeftup.origin = [xg, yg+h1+h2]; 
         var myKhHolesYMarkGLeftup = new makerjs.models.Square(0);      
         myKhHolesYMarkGLeftup.origin = [xg, yg+h1+h2];  
   // /*****************************************************************************/ 
   makerjs.model.addPath(myKhHolesMarkGLeftup, new makerjs.paths.Line([0,0], [-x0-markShiftFour, 0]));//从孔圆心出发X方向横向标线
   makerjs.model.addPath(myKhHolesMarkGLeftup, new makerjs.paths.Line([0,0], [0, -h2-markShiftTwo]));//从孔圆心出发Y方向竖向标线
   /*****************************************************************************/ 
   makerjs.model.addPath(myKhHolesMarkGLeftup, new makerjs.paths.Line([0,0], [d, -d]));//直径标线
   makerjs.model.addCaption(myKhHolesMarkGLeftup, TxtD, [0,0], [d+markHolesOneD, -d-markHolesOneD]);//直径文字标注
   /*****************************************************************************/   
   /*****************************************************************************/     
   makerjs.model.addPath(myKhHolesYMarkGLeftup, new makerjs.paths.Line([-x0-markShiftTwo,0], [-x0-markShiftTwo, -h2]));//Y短标线
   makerjs.model.addCaption(myKhHolesYMarkGLeftup, TxtH2, [-x0-markShiftFour, -h2], [-x0-markShiftFour,0]);//Y文字标注
   // /*****************************************************************************/ 
    //标记晾物孔 G位置孔右上
    var myHhHolesGRightup= new makerjs.models.Holes(d/2, [[xg+w, yg+h1+h2]]);
         //标记孔
         var myHhHolesMarkGRightup = new makerjs.models.Square(0); //孔的原点       
         myHhHolesMarkGRightup.origin = [xg+w, yg+h1+h2]; 
   // /*****************************************************************************/ 
   makerjs.model.addPath(myHhHolesMarkGRightup, new makerjs.paths.Line([0,0], [-x0-w-markShiftThree, 0]));//从孔圆心出发X方向横向标线
   makerjs.model.addPath(myHhHolesMarkGRightup, new makerjs.paths.Line([0,0], [0, -h2-markShiftTwo]));//从孔圆心出发Y方向竖向标线
   /*****************************************************************************/ 
   makerjs.model.addPath(myHhHolesMarkGRightup, new makerjs.paths.Line([0,0], [d, -d]));//直径标线
   makerjs.model.addCaption(myHhHolesMarkGRightup, TxtD, [0,0], [d+markHolesOneD, -d-markHolesOneD]);//直径文字标注
   // /*****************************************************************************/  
   
   plate.models["myHhHolesGLeftdn"+myHolesNum]=myHhHolesGLeftdn;
   markers.models["myHhHolesGLeftdn"+myHolesNum]=myHhHolesGLeftdn;
   markers.models["myHhHolesMarkGLeftdn"+myHolesNum]=myHhHolesMarkGLeftdn;
   markers.models["myKhHolesXMarkGLeftdn"+myHolesNum]=myKhHolesXMarkGLeftdn;
   markers.models["myKhHolesYMarkGLeftdn"+myHolesNum]=myKhHolesYMarkGLeftdn;
   /*****************************************************************************/
   plate.models["myHhHolesGLeftmd"+myHolesNum]=myHhHolesGLeftmd;
   markers.models["myHhHolesGLeftmd"+myHolesNum]=myHhHolesGLeftmd;
   markers.models["myHhHolesMarkGLeftmd"+myHolesNum]=myHhHolesMarkGLeftmd;
   markers.models["myHhHolesYMarkGLeftmd"+myHolesNum]=myHhHolesYMarkGLeftmd;
   // /*****************************************************************************/ 
   plate.models["myKhHolesGLeftup"+myHolesNum]=myKhHolesGLeftup;
   markers.models["myKhHolesGLeftup"+myHolesNum]=myKhHolesGLeftup;
   markers.models["myKhHolesMarkGLeftup"+myHolesNum]=myKhHolesMarkGLeftup;
   markers.models["myKhHolesYMarkGLeftup"+myHolesNum]=myKhHolesYMarkGLeftup;
   // /*****************************************************************************/ 
   plate.models["myHhHolesGRightdn"+myHolesNum]=myHhHolesGRightdn;
   markers.models["myHhHolesGRightdn"+myHolesNum]=myHhHolesGRightdn;
   markers.models["myHhHolesMarkGRightdn"+myHolesNum]=myHhHolesMarkGRightdn;
   markers.models["myHhHolesXMarkGRightdn"+myHolesNum]=myHhHolesXMarkGRightdn;
   // /*****************************************************************************/ 
   // /*****************************************************************************/ 
   plate.models["myKhHolesGRightmd"+myHolesNum]=myKhHolesGRightmd;
   markers.models["myKhHolesGRightmd"+myHolesNum]=myKhHolesGRightmd;
   markers.models["myKhHolesMarkGRightmd"+myHolesNum]=myKhHolesMarkGRightmd;
   // /*****************************************************************************/ 
   plate.models["myHhHolesGRightup"+myHolesNum]=myHhHolesGRightup;
   markers.models["myHhHolesGRightup"+myHolesNum]=myHhHolesGRightup;
   markers.models["myHhHolesMarkGRightup"+myHolesNum]=myHhHolesMarkGRightup;
   /*****************************************************************************/  
   }
   else if(area==9){
     //画孔 I位置孔
         //标记晾物孔 I位置孔右上
         var myHhHolesIRightup= new makerjs.models.Holes(d/2, [[xi, yi]]);
         //标记孔
         var myHhHolesMarkIRightup = new makerjs.models.Square(0); //孔的原点       
         myHhHolesMarkIRightup.origin = [xi, yi];      
         var myKhHolesXMarkIRightup = new makerjs.models.Square(0);      
         myKhHolesXMarkIRightup.origin =[xi, yi];      
         var myKhHolesYMarkIRightup = new makerjs.models.Square(0);      
         myKhHolesYMarkIRightup.origin = [xi, yi];  
   
   // /*****************************************************************************/ 
   makerjs.model.addPath(myHhHolesMarkIRightup, new makerjs.paths.Line([x0,-y0], [x0, -y0-markShiftFour]));//Y轴加长标线
   makerjs.model.addPath(myHhHolesMarkIRightup, new makerjs.paths.Line([0,0], [x0+markShiftThree, 0]));//从孔圆心出发X方向横向标线
   makerjs.model.addPath(myHhHolesMarkIRightup, new makerjs.paths.Line([x0,-y0], [x0+markShiftThree, -y0]));//X轴加长标线
   makerjs.model.addPath(myHhHolesMarkIRightup, new makerjs.paths.Line([0,0], [0, -y0-markShiftFour]));//从孔圆心出发Y方向竖向标线
   // // /*****************************************************************************/ 
   makerjs.model.addPath(myHhHolesMarkIRightup, new makerjs.paths.Line([0,0], [d, -d]));//直径标线
   makerjs.model.addCaption(myHhHolesMarkIRightup, TxtD, [0,0], [d+markHolesOneD, -d-markHolesOneD]);//直径文字标注
   // /*****************************************************************************/   
   makerjs.model.addPath(myKhHolesXMarkIRightup, new makerjs.paths.Line([0,-y0-markShiftTwo], [x0, -y0-markShiftTwo]));//X短标线
   makerjs.model.addCaption(myKhHolesXMarkIRightup, TxtX, [0, -y0-markShiftFour], [x0,-y0-markShiftFour]);//X文字标注
   // /*****************************************************************************/     
   makerjs.model.addPath(myKhHolesYMarkIRightup, new makerjs.paths.Line([x0+markShift,0], [x0+markShift, -y0]));//Y短标线
   makerjs.model.addCaption(myKhHolesYMarkIRightup, TxtY, [x0+markShiftThree, -y0], [x0+markShiftThree,0]);//Y文字标注
   // /*****************************************************************************/ 
   // /*****************************************************************************/ 
   //画孔I位置孔
         //标记晾物孔 I位置孔左上
         var myHhHolesILeftup= new makerjs.models.Holes(d/2, [[xi-w, yi]]);
         //标记孔
         var myHhHolesMarkILeftup = new makerjs.models.Square(0); //孔的原点       
         myHhHolesMarkILeftup.origin = [xi-w, yi];      
         var myHhHolesXMarkILeftup = new makerjs.models.Square(0);      
         myHhHolesXMarkILeftup.origin =[xi-w, yi]; 
   // /*****************************************************************************/ 
   makerjs.model.addPath(myHhHolesMarkILeftup, new makerjs.paths.Line([0,0], [x0+w+markShiftThree, 0]));//从孔圆心出发X方向横向标线
   makerjs.model.addPath(myHhHolesMarkILeftup, new makerjs.paths.Line([0,0], [0, -y0-markShiftFour]));//从孔圆心出发Y方向竖向标线
   // /*****************************************************************************/ 
   makerjs.model.addPath(myHhHolesMarkILeftup, new makerjs.paths.Line([0,0], [d, -d]));//直径标线
   makerjs.model.addCaption(myHhHolesMarkILeftup, TxtD, [0,0], [d+markHolesOneD, -d-markHolesOneD]);//直径文字标注
   /*****************************************************************************/   
   makerjs.model.addPath(myHhHolesXMarkILeftup, new makerjs.paths.Line([0,-y0-markShiftTwo], [w, -y0-markShiftTwo]));//X短标线
   makerjs.model.addCaption(myHhHolesXMarkILeftup, TxtW, [0, -y0-markShiftFour], [w,-y0-markShiftFour]);//X文字标注
   // /*****************************************************************************/  
   // /*****************************************************************************/ 
    //标记晾物孔 I位置孔右中
    var myHhHolesIRightmd= new makerjs.models.Holes(d/2, [[xi, yi+h1]]);
         //标记孔
         var myHhHolesMarkIRightmd = new makerjs.models.Square(0); //孔的原点       
         myHhHolesMarkIRightmd.origin = [xi, yi+h1];     
         var myHhHolesYMarkIRightmd = new makerjs.models.Square(0);      
         myHhHolesYMarkIRightmd.origin = [xi, yi+h1];  
   // /*****************************************************************************/ 
   makerjs.model.addPath(myHhHolesMarkIRightmd, new makerjs.paths.Line([0,0], [x0+markShiftThree, 0]));//从孔圆心出发X方向横向标线
   makerjs.model.addPath(myHhHolesMarkIRightmd, new makerjs.paths.Line([0,0], [0, -h1-markShiftFour]));//从孔圆心出发Y方向竖向标线
   /*****************************************************************************/ 
   makerjs.model.addPath(myHhHolesMarkIRightmd, new makerjs.paths.Line([0,0], [d, -d]));//直径标线
   makerjs.model.addCaption(myHhHolesMarkIRightmd, TxtD, [0,0], [d+markHolesOneD, -d-markHolesOneD]);//直径文字标注
   /*****************************************************************************/      
   makerjs.model.addPath(myHhHolesYMarkIRightmd, new makerjs.paths.Line([x0+markShift,0], [x0+markShift, -h1]));//Y短标线
   makerjs.model.addCaption(myHhHolesYMarkIRightmd, TxtH1, [x0+markShiftThree, -h1], [x0+markShiftThree,0]);//Y文字标注
   // /*****************************************************************************/ 
    //标记晾物孔 I位置孔左中
    var myKhHolesILeftmd= new makerjs.models.Holes(d/2, [[xi-w, yi+h1]]);
         //标记孔
         var myKhHolesMarkILeftmd = new makerjs.models.Square(0); //孔的原点       
         myKhHolesMarkILeftmd.origin = [xi-w, yi+h1];      
         var myKhHolesXMarkILeftmd = new makerjs.models.Square(0);      
         myKhHolesXMarkILeftmd.origin =[xi-w, yi+h1];      
         var myKhHolesYMarkILeftmd = new makerjs.models.Square(0);      
         myKhHolesYMarkILeftmd.origin = [xi-w, yi+h1];  
   // /*****************************************************************************/ 
   makerjs.model.addPath(myKhHolesMarkILeftmd, new makerjs.paths.Line([0,0], [x0+w+markShiftThree, 0]));//从孔圆心出发X方向横向标线
   makerjs.model.addPath(myKhHolesMarkILeftmd, new makerjs.paths.Line([0,0], [0, -h1-markShiftFour]));//从孔圆心出发Y方向竖向标线
   /*****************************************************************************/ 
   makerjs.model.addPath(myKhHolesMarkILeftmd, new makerjs.paths.Line([0,0], [d, -d]));//直径标线
   makerjs.model.addCaption(myKhHolesMarkILeftmd, TxtD, [0,0], [d+markHolesOneD, -d-markHolesOneD]);//直径文字标注
   /*****************************************************************************/
    //标记晾物孔I位置孔右下
    var myKhHolesIRightdn= new makerjs.models.Holes(d/2, [[xi, yi+h1+h2]]);
         //标记孔
         var myKhHolesMarkIRightdn = new makerjs.models.Square(0); //孔的原点       
         myKhHolesMarkIRightdn.origin = [xi, yi+h1+h2];        
         var myKhHolesYMarkIRightdn = new makerjs.models.Square(0);      
         myKhHolesYMarkIRightdn.origin = [xi, yi+h1+h2];  
   /*****************************************************************************/ 
   makerjs.model.addPath(myKhHolesMarkIRightdn, new makerjs.paths.Line([0,0], [x0+markShiftThree, 0]));//从孔圆心出发X方向横向标线
   makerjs.model.addPath(myKhHolesMarkIRightdn, new makerjs.paths.Line([0,0], [0, -h2-markShiftFour]));//从孔圆心出发Y方向竖向标线
   /*****************************************************************************/ 
   makerjs.model.addPath(myKhHolesMarkIRightdn, new makerjs.paths.Line([0,0], [d, -d]));//直径标线
   makerjs.model.addCaption(myKhHolesMarkIRightdn, TxtD, [0,0], [d+markHolesOneD, -d-markHolesOneD]);//直径文字标注
   /*****************************************************************************/       
   makerjs.model.addPath(myKhHolesYMarkIRightdn, new makerjs.paths.Line([x0+markShift,0], [x0+markShift, -h2]));//Y短标线
   makerjs.model.addCaption(myKhHolesYMarkIRightdn, TxtH2, [x0+markShiftThree, -h2], [x0+markShiftThree,0]);//Y文字标注
   /*****************************************************************************/ 
   /*****************************************************************************/
    //标记晾物孔I位置孔左下
    var myHhHolesILeftdn= new makerjs.models.Holes(d/2, [[xi-w, yi+h1+h2]]);
         //标记孔
         var myHhHolesMarkILeftdn = new makerjs.models.Square(0); //孔的原点       
         myHhHolesMarkILeftdn.origin = [xi-w, yi+h1+h2];  
   // /*****************************************************************************/ 
   makerjs.model.addPath(myHhHolesMarkILeftdn, new makerjs.paths.Line([0,0], [x0+w+markShiftThree, 0]));//从孔圆心出发X方向横向标线
   makerjs.model.addPath(myHhHolesMarkILeftdn, new makerjs.paths.Line([0,0], [0, -h2-markShiftFour]));//从孔圆心出发Y方向竖向标线
   /*****************************************************************************/ 
   makerjs.model.addPath(myHhHolesMarkILeftdn, new makerjs.paths.Line([0,0], [d, -d]));//直径标线
   makerjs.model.addCaption(myHhHolesMarkILeftdn, TxtD, [0,0], [d+markHolesOneD, -d-markHolesOneD]);//直径文字标注
   /*****************************************************************************/   
   plate.models["myHhHolesIRightup"+myHolesNum]=myHhHolesIRightup;
   markers.models["myHhHolesIRightup"+myHolesNum]=myHhHolesIRightup;
   markers.models["myHhHolesMarkIRightup"+myHolesNum]=myHhHolesMarkIRightup;
   markers.models["myKhHolesXMarkIRightup"+myHolesNum]=myKhHolesXMarkIRightup;
   markers.models["myKhHolesYMarkIRightup"+myHolesNum]=myKhHolesYMarkIRightup;
   // /*****************************************************************************/
   plate.models["myHhHolesIRightmd"+myHolesNum]=myHhHolesIRightmd;
   markers.models["myHhHolesIRightmd"+myHolesNum]=myHhHolesIRightmd;
   markers.models["myHhHolesMarkIRightmd"+myHolesNum]=myHhHolesMarkIRightmd;
   markers.models["myHhHolesYMarkIRightmd"+myHolesNum]=myHhHolesYMarkIRightmd;
   /*****************************************************************************/ 
   plate.models["myKhHolesIRightdn"+myHolesNum]=myKhHolesIRightdn;
   markers.models["myKhHolesIRightdn"+myHolesNum]=myKhHolesIRightdn;
   markers.models["myKhHolesMarkIRightdn"+myHolesNum]=myKhHolesMarkIRightdn;
   markers.models["myKhHolesYMarkIRightdn"+myHolesNum]=myKhHolesYMarkIRightdn;
   /*****************************************************************************/ 
   plate.models["myHhHolesILeftup"+myHolesNum]=myHhHolesILeftup;
   markers.models["myHhHolesILeftup"+myHolesNum]=myHhHolesILeftup;
   markers.models["myHhHolesMarkILeftup"+myHolesNum]=myHhHolesMarkILeftup;
   markers.models["myHhHolesXMarkILeftup"+myHolesNum]=myHhHolesXMarkILeftup;
   /*****************************************************************************/ 
   /*****************************************************************************/ 
   plate.models["myKhHolesILeftmd"+myHolesNum]=myKhHolesILeftmd;
   markers.models["myKhHolesILeftmd"+myHolesNum]=myKhHolesILeftmd;
   markers.models["myKhHolesMarkILeftmd"+myHolesNum]=myKhHolesMarkILeftmd;
   /*****************************************************************************/ 
   plate.models["myHhHolesILeftdn"+myHolesNum]=myHhHolesILeftdn;
   markers.models["myHhHolesILeftdn"+myHolesNum]=myHhHolesILeftdn;
   markers.models["myHhHolesMarkILeftdn"+myHolesNum]=myHhHolesMarkILeftdn;
   /*****************************************************************************/  
   }

myHolesNum++;
return{plate, markers,myHolesNum}
};


export default HangHole;