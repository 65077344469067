//5、双吊轮孔排
import makerjs from "makerjs";
// inputParam,model,this.myHolesNum,outParam,fixedParam
// (D,X,Y,area,plate,markers,myHolesNum,glassW,glassH,markShift,markShiftTwo,markShiftThree,markShiftFour,markHolesOneD)
function HangWheelHoleTwoRow(inputParam,model,myHolesNum,outParam,fixedParam,zoom){
var D=inputParam.D,X=inputParam.X,W=inputParam.W,Y=inputParam.Y,area=inputParam.area,myZoom=zoom*1,
plate=model.plate,markers=model.markers,
glassW=outParam.glassW,glassH=outParam.glassH,
   markShift=fixedParam.markShift,markShiftTwo=fixedParam.markShiftTwo,
   markShiftThree=fixedParam.markShiftThree,markShiftFour=fixedParam.markShiftFour,markHolesOneD=fixedParam.markHolesOneD,
   d = D*1*myZoom,
   w = W*1*myZoom,
    x0 = X*1*myZoom,
    y0 = Y*1*myZoom;
   
   //d = D*1,
   //x0 = X*1,
  // y0 = Y*1;
   if(d!==0){
      var TxtD="∮"+D;
     }
   if(w!==0){
    var TxtW=W;
   }
   if(x0!==0){          
   var TxtX=X;
   }
   if(y0!==0){          
    var TxtY=Y;
   }
  //在A区域 计算孔的位置       
  var xa=x0,ya=glassH * 1-y0,
  //B区域//只有Y标注没有X标注 
  xb=glassW * 1/2,yb=glassH * 1-y0,
  //C区域
  xc=glassW * 1-x0,yc=glassH * 1-y0,
  //D区域
  //只有X标注没有Y标注 
  xd=x0,yd=glassH * 1/2,
  //E区域
  //只有X标注没有Y标注 
  xe=glassW * 1/2,ye=glassH * 1/2,
  //F区域
  //只有X标注没有Y标注 
  xf=glassW * 1-x0,yf=glassH * 1/2,
  //在G区域 计算孔的位置
  xg=x0,yg=y0,
  //在H区域 计算孔的位置
  //只标注Y
  xh=glassW*1/2,yh=y0,
  //在I区域 计算孔的位置
  xi=glassW*1-x0,yi=y0;
//双吊轮孔排 A位置孔
      //标记双吊轮孔排 A位置孔排左
      var myHwhtrHolesALeft= new makerjs.models.Holes(d/2, [[xa, ya]]);
      var myHwhtrHolesMarkALeft = new makerjs.models.Square(0); //孔的原点       
      myHwhtrHolesMarkALeft.origin = [xa, ya];      
      var myHwhtrXMarkALeft = new makerjs.models.Square(0);      
      myHwhtrXMarkALeft.origin =[xa, ya];      
      var myHwhtrYMarkALeft = new makerjs.models.Square(0);      
      myHwhtrYMarkALeft.origin = [xa, ya]; 
/*****************************************************************************/ 
makerjs.model.addPath(myHwhtrHolesMarkALeft, new makerjs.paths.Line([-x0,y0], [-x0, y0+markShiftTwo]));//Y轴加长标线
makerjs.model.addPath(myHwhtrHolesMarkALeft, new makerjs.paths.Line([0,0], [-x0-markShiftThree, 0]));//从孔圆心出发X方向横向标线
makerjs.model.addPath(myHwhtrHolesMarkALeft, new makerjs.paths.Line([-x0,y0], [-x0-markShiftThree, y0]));//X轴加长标线
makerjs.model.addPath(myHwhtrHolesMarkALeft, new makerjs.paths.Line([0,0], [0, y0+markShiftTwo]));//从孔圆心出发Y方向竖向标线
/*****************************************************************************/ 
makerjs.model.addPath(myHwhtrHolesMarkALeft, new makerjs.paths.Line([0,0], [d, -d]));//直径标线
makerjs.model.addCaption(myHwhtrHolesMarkALeft, TxtD, [0,0], [d+markHolesOneD, -d-markHolesOneD]);//直径文字标注
/*****************************************************************************/   
makerjs.model.addPath(myHwhtrXMarkALeft, new makerjs.paths.Line([0,y0+markShift], [-x0, y0+markShift]));//X短标线
makerjs.model.addCaption(myHwhtrXMarkALeft, TxtX, [-x0,y0+markShiftTwo], [0, y0+markShiftTwo]);//X文字标注
/*****************************************************************************/     
makerjs.model.addPath(myHwhtrYMarkALeft, new makerjs.paths.Line([-x0-markShiftTwo,0], [-x0-markShiftTwo, y0]));//Y短标线
makerjs.model.addCaption(myHwhtrYMarkALeft, TxtY, [-x0-markShiftThree,0], [-x0-markShiftThree, y0]);//Y文字标注
/*****************************************************************************/ 
      //标记双吊轮孔排 A位置孔排右
      var myHwhtrHolesARight= new makerjs.models.Holes(d/2, [[xa+w, ya]]);
      var myHwhtrHolesMarkARight = new makerjs.models.Square(0); //孔的原点       
      myHwhtrHolesMarkARight.origin = [xa+w, ya];      
      var myHwhtrXMarkARight = new makerjs.models.Square(0);      
      myHwhtrXMarkARight.origin =[xa+w, ya];      
      var myHwhtrYMarkARight = new makerjs.models.Square(0);      
      myHwhtrYMarkARight.origin = [xa+w, ya]; 
/*****************************************************************************/ 
makerjs.model.addPath(myHwhtrHolesMarkARight, new makerjs.paths.Line([0,0], [-x0-markShiftThree, 0]));//从孔圆心出发X方向横向标线
makerjs.model.addPath(myHwhtrHolesMarkARight, new makerjs.paths.Line([0,0], [0, y0+markShiftTwo]));//从孔圆心出发Y方向竖向标线
/*****************************************************************************/ 
makerjs.model.addPath(myHwhtrHolesMarkARight, new makerjs.paths.Line([0,0], [d, -d]));//直径标线
makerjs.model.addCaption(myHwhtrHolesMarkARight, TxtD, [0,0], [d+markHolesOneD, -d-markHolesOneD]);//直径文字标注
/*****************************************************************************/   
makerjs.model.addPath(myHwhtrXMarkARight, new makerjs.paths.Line([0,y0+markShift], [-w, y0+markShift]));//X短标线
makerjs.model.addCaption(myHwhtrXMarkARight, TxtW, [-w,y0+markShiftTwo], [0, y0+markShiftTwo]);//X文字标注
/*****************************************************************************/
 //标记双吊轮孔排 C位置孔排右
  //画孔
     var myHwhtrHolesCRight= new makerjs.models.Holes(d/2, [[xc, yc]]);
      var myHwhtrHolesMarkCRight = new makerjs.models.Square(0); //孔的原点       
      myHwhtrHolesMarkCRight.origin = [xc, yc];      
      var myHwhtrXMarkCRight = new makerjs.models.Square(0);      
      myHwhtrXMarkCRight.origin = [xc, yc];      
      var myHwhtrYMarkCRight = new makerjs.models.Square(0);      
      myHwhtrYMarkCRight.origin = [xc, yc];  
/*****************************************************************************/  
makerjs.model.addPath(myHwhtrHolesMarkCRight, new makerjs.paths.Line([x0,y0], [x0+markShiftTwo, y0]));//Y轴加长标线
makerjs.model.addPath(myHwhtrHolesMarkCRight, new makerjs.paths.Line([x0,y0], [x0, y0+markShiftTwo]));//X轴加长标线
makerjs.model.addPath(myHwhtrXMarkCRight, new makerjs.paths.Line([0,0], [0, y0+markShiftTwo]));////从孔圆心出发Y方向横向标线
makerjs.model.addPath(myHwhtrXMarkCRight, new makerjs.paths.Line([0,0], [x0+markShiftTwo, 0]));////从孔圆心出发X方向竖向标线
/*****************************************************************************/ 
makerjs.model.addPath(myHwhtrHolesMarkCRight, new makerjs.paths.Line([0,0], [-d, -d]));//直径标线
makerjs.model.addCaption(myHwhtrHolesMarkCRight, TxtD, [-d-markHolesOneD,-d-markHolesOneD], [0, 0]);//直径文字标注
/*****************************************************************************/ 
makerjs.model.addPath(myHwhtrXMarkCRight, new makerjs.paths.Line([0,y0+markShift], [x0, y0+markShift]));//X短标线
makerjs.model.addCaption(myHwhtrXMarkCRight, TxtX, [0,y0+markShiftThree], [x0, y0+markShiftThree]);//X文字标注
/*****************************************************************************/    
makerjs.model.addPath(myHwhtrYMarkCRight, new makerjs.paths.Line([x0+markShift,0], [x0+markShift, y0]));//Y短标线
makerjs.model.addCaption(myHwhtrYMarkCRight, TxtY, [x0+markShiftThree,0], [x0+markShiftThree, y0]);//Y文字标注
/*****************************************************************************/ 
 //标记双吊轮孔排 C位置孔排左
  //画孔
  var myHwhtrHolesCLeft= new makerjs.models.Holes(d/2, [[xc-w, yc]]);
      var myHwhtrHolesMarkCLeft = new makerjs.models.Square(0); //孔的原点       
      myHwhtrHolesMarkCLeft.origin = [xc-w, yc];      
      var myHwhtrXMarkCLeft = new makerjs.models.Square(0);      
      myHwhtrXMarkCLeft.origin = [xc-w, yc];      
      var myHwhtrYMarkCLeft = new makerjs.models.Square(0);      
      myHwhtrYMarkCLeft.origin = [xc-w, yc];  
/*****************************************************************************/  
makerjs.model.addPath(myHwhtrXMarkCLeft, new makerjs.paths.Line([0,0], [0, y0+markShiftTwo]));////从孔圆心出发Y方向横向标线
makerjs.model.addPath(myHwhtrXMarkCLeft, new makerjs.paths.Line([0,0], [x0+markShiftTwo, 0]));////从孔圆心出发X方向竖向标线
/*****************************************************************************/ 
makerjs.model.addPath(myHwhtrHolesMarkCLeft, new makerjs.paths.Line([0,0], [-d, -d]));//直径标线
makerjs.model.addCaption(myHwhtrHolesMarkCLeft, TxtD, [-d-markHolesOneD,-d-markHolesOneD], [0, 0]);//直径文字标注
/*****************************************************************************/ 
makerjs.model.addPath(myHwhtrXMarkCLeft, new makerjs.paths.Line([0,y0+markShift], [w, y0+markShift]));//X短标线
makerjs.model.addCaption(myHwhtrXMarkCLeft, TxtW, [0,y0+markShiftThree], [w, y0+markShiftThree]);//X文字标注
/*****************************************************************************/ 
plate.models["myHwhtrHolesALeft"+myHolesNum]=myHwhtrHolesALeft;
markers.models["myHwhtrHolesALeft"+myHolesNum]=myHwhtrHolesALeft;
markers.models["myHwhtrHolesMarkALeft"+myHolesNum]=myHwhtrHolesMarkALeft;
markers.models["myHwhtrXMarkALeft"+myHolesNum]=myHwhtrXMarkALeft;
markers.models["myHwhtrYMarkALeft"+myHolesNum]=myHwhtrYMarkALeft;
/*****************************************************************************/
plate.models["myHwhtrHolesARight"+myHolesNum]=myHwhtrHolesARight;
markers.models["myHwhtrHolesARight"+myHolesNum]=myHwhtrHolesARight;
markers.models["myHwhtrHolesMarkARight"+myHolesNum]=myHwhtrHolesMarkARight;
markers.models["myHwhtrXMarkARight"+myHolesNum]=myHwhtrXMarkARight;
markers.models["myHwhtrYMarkARight"+myHolesNum]=myHwhtrYMarkARight;
/*****************************************************************************/ 
plate.models["myHwhtrHolesCRight"+myHolesNum]=myHwhtrHolesCRight;
markers.models["myHwhtrHolesCRight"+myHolesNum]=myHwhtrHolesCRight;
markers.models["myHwhtrHolesMarkCRight"+myHolesNum]=myHwhtrHolesMarkCRight;
markers.models["myHwhtrXMarkCRight"+myHolesNum]=myHwhtrXMarkCRight;
markers.models["myHwhtrYMarkCRight"+myHolesNum]=myHwhtrYMarkCRight;
/*****************************************************************************/ 
plate.models["myHwhtrHolesCLeft"+myHolesNum]=myHwhtrHolesCLeft;
markers.models["myHwhtrHolesCLeft"+myHolesNum]=myHwhtrHolesCLeft;
markers.models["myHwhtrHolesMarkCLeft"+myHolesNum]=myHwhtrHolesMarkCLeft;
markers.models["myHwhtrXMarkCLeft"+myHolesNum]=myHwhtrXMarkCLeft;
markers.models["myHwhtrYMarkCLeft"+myHolesNum]=myHwhtrYMarkCLeft;
/*****************************************************************************/  

myHolesNum++;
return{plate, markers,myHolesNum}
};


export default HangWheelHoleTwoRow;