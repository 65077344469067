//11、旋转锁孔
import makerjs from "makerjs";
// inputParam,model,myHolesNum,outParam,fixedParam
// (D,X,Y,area,plate,markers,myHolesNum,glassW,glassH,markShift,markShiftTwo,markShiftThree,markShiftFour,markHolesOneD)
function RotatLockHole(inputParam,model,myHolesNum,outParam,fixedParam,zoom){
var D=inputParam.D,W=inputParam.W,H=inputParam.H,X=inputParam.X,Y=inputParam.Y,area=inputParam.area,myZoom=zoom*1,
plate=model.plate,markers=model.markers,
glassW=outParam.glassW,glassH=outParam.glassH,
   markShift=fixedParam.markShift,markShiftTwo=fixedParam.markShiftTwo,
   markShiftThree=fixedParam.markShiftThree,markShiftFour=fixedParam.markShiftFour,markHolesOneD=fixedParam.markHolesOneD,
 
   d = D*1*myZoom,
      w= W*1*myZoom,
      h= H*1*myZoom,
        x0 = X*1*myZoom,
        y0 = Y*1*myZoom;
        /*
        d = D*1,
           w= W*1,
           h= H*1,
             x0 = X*1,
             y0 = Y*1;
             */
        if(d!==0){
         var TxtD="∮"+D;
        }
        if(h!==0){
         var TxtH=H;
        }
        if(w!==0){
         var TxtW=W;
        }
        if(x0!==0){          
        var TxtX=X;
        }
        if(y0!==0){          
         var TxtY=Y;
        }
  //在A区域 计算孔的位置       
  var xa=x0,ya=glassH * 1-y0,
  //B区域//只有Y标注没有X标注 
  xb=glassW * 1/2,yb=glassH * 1-y0,
  //C区域
  xc=glassW * 1-x0,yc=glassH * 1-y0,
  //D区域
  //只有X标注没有Y标注 
  xd=x0,yd=glassH * 1/2,
  //E区域
  //只有X标注没有Y标注 
  xe=glassW * 1/2,ye=glassH * 1/2,
  //F区域
  //只有X标注没有Y标注 
  xf=glassW * 1-x0,yf=glassH * 1/2,
  //在G区域 计算孔的位置
  xg=x0,yg=y0,
  //在H区域 计算孔的位置
  //只标注Y
  xh=glassW*1/2,yh=y0,
  //在I区域 计算孔的位置
  xi=glassW*1-x0,yi=y0;
  if(area==1){
    //画孔 A位置孔
        //标记旋转锁孔 A位置孔左上
        var myRlhHolesALeftup= new makerjs.models.Holes(d/2, [[xa, ya]]);
        //标记孔
        var myRlhHolesMarkALeftup = new makerjs.models.Square(0); //孔的原点       
        myRlhHolesMarkALeftup.origin = [xa, ya];      
        var myRlhHolesXMarkALeftup = new makerjs.models.Square(0);      
        myRlhHolesXMarkALeftup.origin =[xa, ya];      
        var myRlhHolesYMarkALeftup = new makerjs.models.Square(0);      
        myRlhHolesYMarkALeftup.origin = [xa, ya];  
  
  /*****************************************************************************/ 
  makerjs.model.addPath(myRlhHolesMarkALeftup, new makerjs.paths.Line([-x0,y0], [-x0, y0+markShiftTwo]));//Y轴加长标线
  makerjs.model.addPath(myRlhHolesMarkALeftup, new makerjs.paths.Line([0,0], [-x0-markShiftThree, 0]));//从孔圆心出发X方向横向标线
  makerjs.model.addPath(myRlhHolesMarkALeftup, new makerjs.paths.Line([-x0,y0], [-x0-markShiftThree, y0]));//X轴加长标线
  makerjs.model.addPath(myRlhHolesMarkALeftup, new makerjs.paths.Line([0,0], [0, y0+markShiftTwo]));//从孔圆心出发Y方向竖向标线
  /*****************************************************************************/ 
  makerjs.model.addPath(myRlhHolesMarkALeftup, new makerjs.paths.Line([0,0], [d, -d]));//直径标线
  makerjs.model.addCaption(myRlhHolesMarkALeftup, TxtD, [0,0], [d+markHolesOneD, -d-markHolesOneD]);//直径文字标注
  /*****************************************************************************/   
  makerjs.model.addPath(myRlhHolesXMarkALeftup, new makerjs.paths.Line([0,y0+markShift], [-x0, y0+markShift]));//X短标线
  makerjs.model.addCaption(myRlhHolesXMarkALeftup, TxtX, [-x0,y0+markShiftTwo], [0, y0+markShiftTwo]);//X文字标注
  /*****************************************************************************/     
  makerjs.model.addPath(myRlhHolesYMarkALeftup, new makerjs.paths.Line([-x0-markShiftTwo,0], [-x0-markShiftTwo, y0]));//Y短标线
  makerjs.model.addCaption(myRlhHolesYMarkALeftup, TxtY, [-x0-markShiftThree,0], [-x0-markShiftThree, y0]);//Y文字标注
  /*****************************************************************************/ 
  /*****************************************************************************/ 
  //画孔 A位置孔
        //标记旋转锁孔 A位置孔右上
        var myRlhHolesARightup= new makerjs.models.Holes(d/2, [[xa+w, ya]]);
        var myRlhHolesMarkARightup = new makerjs.models.Square(0); //孔的原点       
        myRlhHolesMarkARightup.origin = [xa+w, ya];      
        var myRlhHolesXMarkARightup = new makerjs.models.Square(0);      
        myRlhHolesXMarkARightup.origin =[xa+w, ya]; 
  /*****************************************************************************/ 
  makerjs.model.addPath(myRlhHolesMarkARightup, new makerjs.paths.Line([0,0], [-w, 0]));//从孔圆心出发X方向横向标线
  makerjs.model.addPath(myRlhHolesMarkARightup, new makerjs.paths.Line([0,0], [0, y0+markShiftTwo]));//从孔圆心出发Y方向竖向标线
  /*****************************************************************************/ 
  makerjs.model.addPath(myRlhHolesMarkARightup, new makerjs.paths.Line([0,0], [d, -d]));//直径标线
  makerjs.model.addCaption(myRlhHolesMarkARightup, TxtD, [0,0], [d+markHolesOneD, -d-markHolesOneD]);//直径文字标注
  /*****************************************************************************/   
  makerjs.model.addPath(myRlhHolesXMarkARightup, new makerjs.paths.Line([0,y0+markShift], [-w, y0+markShift]));//X短标线
  makerjs.model.addCaption(myRlhHolesXMarkARightup, TxtW, [-w,y0+markShiftTwo], [0, y0+markShiftTwo]);//X文字标注
  /*****************************************************************************/  
  /*****************************************************************************/ 
   //标记旋转锁孔 A位置孔左下
   var myRlhHolesALeftdn= new makerjs.models.Holes(d/2, [[xa, ya-h]]);
        var myRlhHolesMarkALeftdn = new makerjs.models.Square(0); //孔的原点       
        myRlhHolesMarkALeftdn.origin = [xa, ya-h];   
        var myRlhHolesYMarkALeftdn = new makerjs.models.Square(0);      
        myRlhHolesYMarkALeftdn.origin = [xa, ya-h]; 
  /*****************************************************************************/ 
  makerjs.model.addPath(myRlhHolesMarkALeftdn, new makerjs.paths.Line([0,0], [-x0-markShiftThree, 0]));//从孔圆心出发X方向横向标线
  makerjs.model.addPath(myRlhHolesMarkALeftdn, new makerjs.paths.Line([0,0], [0, h+markShiftTwo]));//从孔圆心出发Y方向竖向标线
  /*****************************************************************************/ 
  makerjs.model.addPath(myRlhHolesMarkALeftdn, new makerjs.paths.Line([0,0], [d, -d]));//直径标线
  makerjs.model.addCaption(myRlhHolesMarkALeftdn, TxtD, [0,0], [d+markHolesOneD, -d-markHolesOneD]);//直径文字标注
  /*****************************************************************************/  
  /*****************************************************************************/     
  makerjs.model.addPath(myRlhHolesYMarkALeftdn, new makerjs.paths.Line([-x0-markShiftTwo,0], [-x0-markShiftTwo, h]));//Y短标线
  makerjs.model.addCaption(myRlhHolesYMarkALeftdn, TxtH, [-x0-markShiftThree,0], [-x0-markShiftThree, h]);//Y文字标注
  /*****************************************************************************/ 
  /*****************************************************************************/ 
  plate.models["myRlhHolesALeftup"+myHolesNum]=myRlhHolesALeftup;
  markers.models["myRlhHolesALeftup"+myHolesNum]=myRlhHolesALeftup;
  markers.models["myRlhHolesMarkALeftup"+myHolesNum]=myRlhHolesMarkALeftup;
  markers.models["myRlhHolesXMarkALeftup"+myHolesNum]=myRlhHolesXMarkALeftup;
  markers.models["myRlhHolesYMarkALeftup"+myHolesNum]=myRlhHolesYMarkALeftup;
  /*****************************************************************************/
  plate.models["myRlhHolesALeftdn"+myHolesNum]=myRlhHolesALeftdn;
  markers.models["myRlhHolesALeftdn"+myHolesNum]=myRlhHolesALeftdn;
  markers.models["myRlhHolesMarkALeftdn"+myHolesNum]=myRlhHolesMarkALeftdn;
  markers.models["myRlhHolesYMarkALeftdn"+myHolesNum]=myRlhHolesYMarkALeftdn;
  /*****************************************************************************/ 
  plate.models["myRlhHolesARightup"+myHolesNum]=myRlhHolesARightup;
  markers.models["myRlhHolesARightup"+myHolesNum]=myRlhHolesARightup;
  markers.models["myRlhHolesMarkARightup"+myHolesNum]=myRlhHolesMarkARightup;
  markers.models["myRlhHolesXMarkARightup"+myHolesNum]=myRlhHolesXMarkARightup;
  /*****************************************************************************/ 
  
  }
  else if(area==3){
  //画孔 C位置孔
        //标记旋转锁孔 C位置孔右上
        var myRlhHolesCRightup= new makerjs.models.Holes(d/2, [[xc, yc]]);
        //标记孔
        var myRlhHolesMarkCRightup = new makerjs.models.Square(0); //孔的原点       
        myRlhHolesMarkCRightup.origin = [xc, yc];      
        var myRlhHolesXMarkCRightup = new makerjs.models.Square(0);      
        myRlhHolesXMarkCRightup.origin =[xc, yc];      
        var myRlhHolesYMarkCRightup = new makerjs.models.Square(0);      
        myRlhHolesYMarkCRightup.origin = [xc, yc];  
  /*****************************************************************************/ 
  makerjs.model.addPath(myRlhHolesMarkCRightup, new makerjs.paths.Line([x0,y0], [x0, y0+markShiftFour]));//Y轴加长标线
  makerjs.model.addPath(myRlhHolesMarkCRightup, new makerjs.paths.Line([0,0], [x0+markShiftThree, 0]));//从孔圆心出发X方向横向标线
  makerjs.model.addPath(myRlhHolesMarkCRightup, new makerjs.paths.Line([x0,y0], [x0+markShiftThree, y0]));//X轴加长标线
  makerjs.model.addPath(myRlhHolesMarkCRightup, new makerjs.paths.Line([0,0], [0, y0+markShiftFour]));//从孔圆心出发Y方向竖向标线
  /*****************************************************************************/ 
  makerjs.model.addPath(myRlhHolesMarkCRightup, new makerjs.paths.Line([0,0], [d, -d]));//直径标线
  makerjs.model.addCaption(myRlhHolesMarkCRightup, TxtD, [0,0], [d+markHolesOneD, -d-markHolesOneD]);//直径文字标注
  /*****************************************************************************/   
  makerjs.model.addPath(myRlhHolesXMarkCRightup, new makerjs.paths.Line([0,y0+markShift], [x0, y0+markShift]));//X短标线
  makerjs.model.addCaption(myRlhHolesXMarkCRightup, TxtX, [0, y0+markShiftTwo], [x0,y0+markShiftTwo]);//X文字标注
  /*****************************************************************************/     
  makerjs.model.addPath(myRlhHolesYMarkCRightup, new makerjs.paths.Line([x0+markShift,0], [x0+markShift, y0]));//Y短标线
  makerjs.model.addCaption(myRlhHolesYMarkCRightup, TxtY, [x0+markShiftFour,0], [x0+markShiftFour, y0]);//Y文字标注
  /*****************************************************************************/ 
  /*****************************************************************************/ 
  //画孔 C位置孔
        //标记旋转锁孔 C位置孔左上
        var myRlhHolesCLeftup= new makerjs.models.Holes(d/2, [[xc-w, yc]]);
        var myRlhHolesMarkCLeftup = new makerjs.models.Square(0); //孔的原点       
        myRlhHolesMarkCLeftup.origin = [xc-w, yc];      
        var myRlhHolesXMarkCLeftup = new makerjs.models.Square(0);      
        myRlhHolesXMarkCLeftup.origin =[xc-w, yc]; 
  /*****************************************************************************/ 
  makerjs.model.addPath(myRlhHolesMarkCLeftup, new makerjs.paths.Line([0,0], [w, 0]));//从孔圆心出发X方向横向标线
  makerjs.model.addPath(myRlhHolesMarkCLeftup, new makerjs.paths.Line([0,0], [0, y0+markShiftFour]));//从孔圆心出发Y方向竖向标线
  /*****************************************************************************/ 
  makerjs.model.addPath(myRlhHolesMarkCLeftup, new makerjs.paths.Line([0,0], [d, -d]));//直径标线
  makerjs.model.addCaption(myRlhHolesMarkCLeftup, TxtD, [0,0], [d+markHolesOneD, -d-markHolesOneD]);//直径文字标注
  /*****************************************************************************/   
  makerjs.model.addPath(myRlhHolesXMarkCLeftup, new makerjs.paths.Line([0,y0+markShift], [w, y0+markShift]));//X短标线
  makerjs.model.addCaption(myRlhHolesXMarkCLeftup, TxtW, [0, y0+markShiftTwo], [w,y0+markShiftTwo]);//X文字标注
  /*****************************************************************************/  
  /*****************************************************************************/ 
   //标记旋转锁孔 C位置孔右下
   var myRlhHolesCRightdn= new makerjs.models.Holes(d/2, [[xc, yc-h]]);
        var myRlhHolesMarkCRightdn = new makerjs.models.Square(0); //孔的原点       
        myRlhHolesMarkCRightdn.origin = [xc, yc-h];   
        var myRlhHolesYMarkCRightdn = new makerjs.models.Square(0);      
        myRlhHolesYMarkCRightdn.origin = [xc, yc-h]; 
  /*****************************************************************************/ 
  makerjs.model.addPath(myRlhHolesMarkCRightdn, new makerjs.paths.Line([0,0], [x0+markShiftThree, 0]));//从孔圆心出发X方向横向标线
  makerjs.model.addPath(myRlhHolesMarkCRightdn, new makerjs.paths.Line([0,0], [0, h+markShiftTwo]));//从孔圆心出发Y方向竖向标线
  /*****************************************************************************/ 
  makerjs.model.addPath(myRlhHolesMarkCRightdn, new makerjs.paths.Line([0,0], [d, -d]));//直径标线
  makerjs.model.addCaption(myRlhHolesMarkCRightdn, TxtD, [0,0], [d+markHolesOneD, -d-markHolesOneD]);//直径文字标注
  /*****************************************************************************/  
  /*****************************************************************************/     
  makerjs.model.addPath(myRlhHolesYMarkCRightdn, new makerjs.paths.Line([x0+markShift,0], [x0+markShift, h]));//Y短标线
  makerjs.model.addCaption(myRlhHolesYMarkCRightdn, TxtH, [x0+markShiftFour,0], [x0+markShiftFour, h]);//Y文字标注
  /*****************************************************************************/ 
  /*****************************************************************************/ 
  plate.models["myRlhHolesCRightup"+myHolesNum]=myRlhHolesCRightup;
  markers.models["myRlhHolesCRightup"+myHolesNum]=myRlhHolesCRightup;
  markers.models["myRlhHolesMarkCRightup"+myHolesNum]=myRlhHolesMarkCRightup;
  markers.models["myRlhHolesXMarkCRightup"+myHolesNum]=myRlhHolesXMarkCRightup;
  markers.models["myRlhHolesYMarkCRightup"+myHolesNum]=myRlhHolesYMarkCRightup;
  /*****************************************************************************/
  plate.models["myRlhHolesCRightdn"+myHolesNum]=myRlhHolesCRightdn;
  markers.models["myRlhHolesCRightdn"+myHolesNum]=myRlhHolesCRightdn;
  markers.models["myRlhHolesMarkCRightdn"+myHolesNum]=myRlhHolesMarkCRightdn;
  markers.models["myRlhHolesYMarkCRightdn"+myHolesNum]=myRlhHolesYMarkCRightdn;
  /*****************************************************************************/ 
  plate.models["myRlhHolesCLeftup"+myHolesNum]=myRlhHolesCLeftup;
  markers.models["myRlhHolesCLeftup"+myHolesNum]=myRlhHolesCLeftup;
  markers.models["myRlhHolesMarkCLeftup"+myHolesNum]=myRlhHolesMarkCLeftup;
  markers.models["myRlhHolesXMarkCLeftup"+myHolesNum]=myRlhHolesXMarkCLeftup;
  /*****************************************************************************/ 
     
  }
  else if(area==4){
   //画孔 D位置孔
        //标记旋转锁孔 D位置孔左上
        var myRlhHolesDLeftup= new makerjs.models.Holes(d/2, [[xd, yd]]);
        //标记孔
        var myRlhHolesMarkDLeftup = new makerjs.models.Square(0); //孔的原点       
        myRlhHolesMarkDLeftup.origin = [xd, yd];      
        var myRlhHolesXMarkDLeftup = new makerjs.models.Square(0);      
        myRlhHolesXMarkDLeftup.origin =[xd, yd];      
        var myRlhHolesYMarkDLeftup = new makerjs.models.Square(0);      
        myRlhHolesYMarkDLeftup.origin = [xd, yd];  
  
  /*****************************************************************************/ 
  makerjs.model.addPath(myRlhHolesMarkDLeftup, new makerjs.paths.Line([-x0,yd], [-x0, yd+markShiftTwo]));//Y轴加长标线
  makerjs.model.addPath(myRlhHolesMarkDLeftup, new makerjs.paths.Line([0,y0], [-x0, y0]));//X轴加长标线
  makerjs.model.addPath(myRlhHolesMarkDLeftup, new makerjs.paths.Line([0,0], [0, yd+markShiftTwo]));//从孔圆心出发Y方向竖向标线
  /*****************************************************************************/ 
  makerjs.model.addPath(myRlhHolesMarkDLeftup, new makerjs.paths.Line([0,0], [d, -d]));//直径标线
  makerjs.model.addCaption(myRlhHolesMarkDLeftup, TxtD, [0,0], [d+markHolesOneD, -d-markHolesOneD]);//直径文字标注
  /*****************************************************************************/   
  makerjs.model.addPath(myRlhHolesXMarkDLeftup, new makerjs.paths.Line([0,yd+markShift], [-x0, yd+markShift]));//X短标线
  makerjs.model.addCaption(myRlhHolesXMarkDLeftup, TxtX, [-x0,yd+markShiftTwo], [0, yd+markShiftTwo]);//X文字标注
  /*****************************************************************************/     
  makerjs.model.addPath(myRlhHolesYMarkDLeftup, new makerjs.paths.Line([markShiftTwo,0], [markShiftTwo, -y0]));//Y短标线
  makerjs.model.addCaption(myRlhHolesYMarkDLeftup, TxtY, [markShiftThree,0], [markShiftThree, -y0]);//Y文字标注
  /*****************************************************************************/ 
  /*****************************************************************************/ 
  //画孔 D位置孔
        //标记旋转锁孔 D位置孔右上
        var myRlhHolesDRightup= new makerjs.models.Holes(d/2, [[xd+w, yd]]);
        var myRlhHolesMarkDRightup = new makerjs.models.Square(0); //孔的原点       
        myRlhHolesMarkDRightup.origin = [xd+w, yd];      
        var myRlhHolesXMarkDRightup = new makerjs.models.Square(0);      
        myRlhHolesXMarkDRightup.origin =[xd+w, yd]; 
  /*****************************************************************************/ 
  makerjs.model.addPath(myRlhHolesMarkDRightup, new makerjs.paths.Line([0,0], [d+markShiftFour, 0]));//从孔圆心出发X方向横向标线
  makerjs.model.addPath(myRlhHolesMarkDRightup, new makerjs.paths.Line([0,0], [-w, 0]));//从孔圆心出发X方向横向标线
  makerjs.model.addPath(myRlhHolesMarkDRightup, new makerjs.paths.Line([0,0], [0, yd+markShiftTwo]));//从孔圆心出发Y方向竖向标线
  /*****************************************************************************/ 
  makerjs.model.addPath(myRlhHolesMarkDRightup, new makerjs.paths.Line([0,0], [d, -d]));//直径标线
  makerjs.model.addCaption(myRlhHolesMarkDRightup, TxtD, [0,0], [d+markHolesOneD, -d-markHolesOneD]);//直径文字标注
  /*****************************************************************************/   
  makerjs.model.addPath(myRlhHolesXMarkDRightup, new makerjs.paths.Line([0,yd+markShift], [-w, yd+markShift]));//X短标线
  makerjs.model.addCaption(myRlhHolesXMarkDRightup, TxtW, [-w,yd+markShiftTwo], [0, yd+markShiftTwo]);//X文字标注
  /*****************************************************************************/  
  /*****************************************************************************/ 
   //标记旋转锁孔 D位置孔左下
   var myRlhHolesDLeftdn= new makerjs.models.Holes(d/2, [[xd, yd-h]]);
        var myRlhHolesMarkDLeftdn = new makerjs.models.Square(0); //孔的原点       
        myRlhHolesMarkDLeftdn.origin = [xd, yd-h];   
        var myRlhHolesYMarkDLeftdn = new makerjs.models.Square(0);      
        myRlhHolesYMarkDLeftdn.origin = [xd, yd-h]; 
  /*****************************************************************************/ 
  makerjs.model.addPath(myRlhHolesMarkDLeftdn, new makerjs.paths.Line([0,0], [d+w+markShiftFour, 0]));//从孔圆心出发X方向横向标线
  makerjs.model.addPath(myRlhHolesMarkDLeftdn, new makerjs.paths.Line([0,0], [0, h+markShiftTwo]));//从孔圆心出发Y方向竖向标线
  /*****************************************************************************/ 
  makerjs.model.addPath(myRlhHolesMarkDLeftdn, new makerjs.paths.Line([0,0], [d, -d]));//直径标线
  makerjs.model.addCaption(myRlhHolesMarkDLeftdn, TxtD, [0,0], [d+markHolesOneD, -d-markHolesOneD]);//直径文字标注
  /*****************************************************************************/  
  /*****************************************************************************/     
  makerjs.model.addPath(myRlhHolesYMarkDLeftdn, new makerjs.paths.Line([d+w+markShiftTwo,0], [d+w+markShiftTwo, h]));//Y短标线
  makerjs.model.addCaption(myRlhHolesYMarkDLeftdn, TxtH, [d+w+markShiftFour,0], [d+w+markShiftFour, h]);//Y文字标注
  /*****************************************************************************/ 
  /*****************************************************************************/ 
  plate.models["myRlhHolesDLeftup"+myHolesNum]=myRlhHolesDLeftup;
  markers.models["myRlhHolesDLeftup"+myHolesNum]=myRlhHolesDLeftup;
  markers.models["myRlhHolesMarkDLeftup"+myHolesNum]=myRlhHolesMarkDLeftup;
  markers.models["myRlhHolesXMarkDLeftup"+myHolesNum]=myRlhHolesXMarkDLeftup;
  markers.models["myRlhHolesYMarkDLeftup"+myHolesNum]=myRlhHolesYMarkDLeftup;
  /*****************************************************************************/
  plate.models["myRlhHolesDLeftdn"+myHolesNum]=myRlhHolesDLeftdn;
  markers.models["myRlhHolesDLeftdn"+myHolesNum]=myRlhHolesDLeftdn;
  markers.models["myRlhHolesMarkDLeftdn"+myHolesNum]=myRlhHolesMarkDLeftdn;
  markers.models["myRlhHolesYMarkDLeftdn"+myHolesNum]=myRlhHolesYMarkDLeftdn;
  /*****************************************************************************/ 
  plate.models["myRlhHolesDRightup"+myHolesNum]=myRlhHolesDRightup;
  markers.models["myRlhHolesDRightup"+myHolesNum]=myRlhHolesDRightup;
  markers.models["myRlhHolesMarkDRightup"+myHolesNum]=myRlhHolesMarkDRightup;
  markers.models["myRlhHolesXMarkDRightup"+myHolesNum]=myRlhHolesXMarkDRightup;
  /*****************************************************************************/ 
     
  }
  else if(area==6){
    //画孔 F位置孔
        //标记旋转锁孔 F位置孔右上
        var myRlhHolesFRightup= new makerjs.models.Holes(d/2, [[xf, yf]]);
        //标记孔
        var myRlhHolesMarkFRightup = new makerjs.models.Square(0); //孔的原点       
        myRlhHolesMarkFRightup.origin = [xf, yf];      
        var myRlhHolesXMarkFRightup = new makerjs.models.Square(0);      
        myRlhHolesXMarkFRightup.origin =[xf, yf];      
        var myRlhHolesYMarkFRightup = new makerjs.models.Square(0);      
        myRlhHolesYMarkFRightup.origin = [xf, yf];  
  /*****************************************************************************/ 
  makerjs.model.addPath(myRlhHolesMarkFRightup, new makerjs.paths.Line([x0,yd], [x0, yd+markShiftFour]));//Y轴加长标线
  makerjs.model.addPath(myRlhHolesMarkFRightup, new makerjs.paths.Line([0,0], [x0, 0]));//从孔圆心出发X方向横向标线
  makerjs.model.addPath(myRlhHolesMarkFRightup, new makerjs.paths.Line([x0,y0], [x0+markShiftThree, y0]));//X轴加长标线
  makerjs.model.addPath(myRlhHolesMarkFRightup, new makerjs.paths.Line([0,0], [0, yd+markShiftFour]));//从孔圆心出发Y方向竖向标线
  /*****************************************************************************/ 
  makerjs.model.addPath(myRlhHolesMarkFRightup, new makerjs.paths.Line([0,0], [d, -d]));//直径标线
  makerjs.model.addCaption(myRlhHolesMarkFRightup, TxtD, [0,0], [d+markHolesOneD, -d-markHolesOneD]);//直径文字标注
  /*****************************************************************************/   
  makerjs.model.addPath(myRlhHolesXMarkFRightup, new makerjs.paths.Line([0,yd+markShift], [x0, yd+markShift]));//X短标线
  makerjs.model.addCaption(myRlhHolesXMarkFRightup, TxtX, [0, yd+markShiftTwo], [x0,yd+markShiftTwo]);//X文字标注
  /*****************************************************************************/     
  makerjs.model.addPath(myRlhHolesYMarkFRightup, new makerjs.paths.Line([x0+markShift,0], [x0+markShift, y0]));//Y短标线
  makerjs.model.addCaption(myRlhHolesYMarkFRightup, TxtY, [x0+markShiftFour,0], [x0+markShiftFour, y0]);//Y文字标注
  /*****************************************************************************/ 
  /*****************************************************************************/ 
  //画孔 F位置孔
        //标记旋转锁孔 F位置孔左上
        var myRlhHolesFLeftup= new makerjs.models.Holes(d/2, [[xf-w, yf]]);
        var myRlhHolesMarkFLeftup = new makerjs.models.Square(0); //孔的原点       
        myRlhHolesMarkFLeftup.origin = [xf-w, yf];      
        var myRlhHolesXMarkFLeftup = new makerjs.models.Square(0);      
        myRlhHolesXMarkFLeftup.origin =[xf-w, yf]; 
  /*****************************************************************************/ 
  makerjs.model.addPath(myRlhHolesMarkFLeftup, new makerjs.paths.Line([0,0], [w, 0]));//从孔圆心出发X方向横向标线
  makerjs.model.addPath(myRlhHolesMarkFLeftup, new makerjs.paths.Line([0,0], [0, yd+markShiftFour]));//从孔圆心出发Y方向竖向标线
  /*****************************************************************************/ 
  makerjs.model.addPath(myRlhHolesMarkFLeftup, new makerjs.paths.Line([0,0], [d, -d]));//直径标线
  makerjs.model.addCaption(myRlhHolesMarkFLeftup, TxtD, [0,0], [d+markHolesOneD, -d-markHolesOneD]);//直径文字标注
  /*****************************************************************************/   
  makerjs.model.addPath(myRlhHolesXMarkFLeftup, new makerjs.paths.Line([0,yd+markShift], [w, yd+markShift]));//X短标线
  makerjs.model.addCaption(myRlhHolesXMarkFLeftup, TxtW, [0, yd+markShiftTwo], [w,yd+markShiftTwo]);//X文字标注
  /*****************************************************************************/  
  /*****************************************************************************/ 
   //标记旋转锁孔 F位置孔右下
   var myRlhHolesFRightdn= new makerjs.models.Holes(d/2, [[xf, yf-h]]);
        var myRlhHolesMarkFRightdn = new makerjs.models.Square(0); //孔的原点       
        myRlhHolesMarkFRightdn.origin = [xf, yf-h];   
        var myRlhHolesYMarkFRightdn = new makerjs.models.Square(0);      
        myRlhHolesYMarkFRightdn.origin = [xf, yf-h]; 
  /*****************************************************************************/ 
  makerjs.model.addPath(myRlhHolesMarkFRightdn, new makerjs.paths.Line([0,0], [x0+markShiftThree, 0]));//从孔圆心出发X方向横向标线
  makerjs.model.addPath(myRlhHolesMarkFRightdn, new makerjs.paths.Line([0,0], [0, h+markShiftTwo]));//从孔圆心出发Y方向竖向标线
  /*****************************************************************************/ 
  makerjs.model.addPath(myRlhHolesMarkFRightdn, new makerjs.paths.Line([0,0], [d, -d]));//直径标线
  makerjs.model.addCaption(myRlhHolesMarkFRightdn, TxtD, [0,0], [d+markHolesOneD, -d-markHolesOneD]);//直径文字标注
  /*****************************************************************************/  
  /*****************************************************************************/     
  makerjs.model.addPath(myRlhHolesYMarkFRightdn, new makerjs.paths.Line([x0+markShift,0], [x0+markShift, h]));//Y短标线
  makerjs.model.addCaption(myRlhHolesYMarkFRightdn, TxtH, [x0+markShiftThree,0], [x0+markShiftThree, h]);//Y文字标注
  /*****************************************************************************/ 
  /*****************************************************************************/ 
  plate.models["myRlhHolesFRightup"+myHolesNum]=myRlhHolesFRightup;
  markers.models["myRlhHolesFRightup"+myHolesNum]=myRlhHolesFRightup;
  markers.models["myRlhHolesMarkFRightup"+myHolesNum]=myRlhHolesMarkFRightup;
  markers.models["myRlhHolesXMarkFRightup"+myHolesNum]=myRlhHolesXMarkFRightup;
  markers.models["myRlhHolesYMarkFRightup"+myHolesNum]=myRlhHolesYMarkFRightup;
  /*****************************************************************************/
  plate.models["myRlhHolesFRightdn"+myHolesNum]=myRlhHolesFRightdn;
  markers.models["myRlhHolesFRightdn"+myHolesNum]=myRlhHolesFRightdn;
  markers.models["myRlhHolesMarkFRightdn"+myHolesNum]=myRlhHolesMarkFRightdn;
  markers.models["myRlhHolesYMarkFRightdn"+myHolesNum]=myRlhHolesYMarkFRightdn;
  /*****************************************************************************/ 
  plate.models["myRlhHolesFLeftup"+myHolesNum]=myRlhHolesFLeftup;
  markers.models["myRlhHolesFLeftup"+myHolesNum]=myRlhHolesFLeftup;
  markers.models["myRlhHolesMarkFLeftup"+myHolesNum]=myRlhHolesMarkFLeftup;
  markers.models["myRlhHolesXMarkFLeftup"+myHolesNum]=myRlhHolesXMarkFLeftup;
  /*****************************************************************************/ 
      
  }

myHolesNum++;
return{plate, markers,myHolesNum}
};


export default RotatLockHole;