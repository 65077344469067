//1、 单孔
import makerjs from "makerjs";
// inputParam,model,this.myHolesNum,outParam,fixedParam
// (D,X,Y,area,plate,markers,myHolesNum,glassW,glassH,markShift,markShiftTwo,markShiftThree,markShiftFour,markHolesOneD)
function SingleHole(inputParam,model,myHolesNum,outParam,fixedParam,zoom){
var D=inputParam.D,X=inputParam.X,Y=inputParam.Y,area=inputParam.area,myZoom=zoom*1,
plate=model.plate,markers=model.markers,
glassW=outParam.glassW,glassH=outParam.glassH,
   markShift=fixedParam.markShift,markShiftTwo=fixedParam.markShiftTwo,
   markShiftThree=fixedParam.markShiftThree,markShiftFour=fixedParam.markShiftFour,markHolesOneD=fixedParam.markHolesOneD,
 d = D*1*myZoom,
  x0 = X*1*myZoom,
  y0 = Y*1*myZoom;
  if(d!==0){
   var TxtD="∮"+D;
  }
  if(x0!==0){          
  var TxtX=X;
  }
  if(y0!==0){          
   var TxtY=Y;
  }
  //在A区域 计算孔的位置       
  var xa=x0,ya=glassH * 1-y0,
  //B区域//只有Y标注没有X标注 
  xb=glassW * 1/2,yb=glassH * 1-y0,
  //C区域
  xc=glassW * 1-x0,yc=glassH * 1-y0,
  //D区域
  //只有X标注没有Y标注 
  xd=x0,yd=glassH * 1/2,
  //E区域
  //只有X标注没有Y标注 
  xe=glassW * 1/2,ye=glassH * 1/2,
  //F区域
  //只有X标注没有Y标注 
  xf=glassW * 1-x0,yf=glassH * 1/2,
  //在G区域 计算孔的位置
  xg=x0,yg=y0,
  //在H区域 计算孔的位置
  //只标注Y
  xh=glassW*1/2,yh=y0,
  //在I区域 计算孔的位置
  xi=glassW*1-x0,yi=y0;
if(area==1){
    var myHolesA= new makerjs.models.Holes(d/2, [[xa, ya]]);
    //标记孔
    var myHolesMarkA = new makerjs.models.Square(0); //孔的原点       
        myHolesMarkA.origin = [xa, ya];      
    var myXMarkA = new makerjs.models.Square(0);      
        myXMarkA.origin = [xa, ya];      
    var myYMarkA = new makerjs.models.Square(0);      
        myYMarkA.origin = [xa, ya];
        
/*****************************************************************************/ 
makerjs.model.addPath(myHolesMarkA, new makerjs.paths.Line([-x0,y0], [-x0, y0+markShiftTwo]));//Y轴加长标线
makerjs.model.addPath(myHolesMarkA, new makerjs.paths.Line([0,0], [-x0-markShiftThree, 0]));//从孔圆心出发X方向横向标线
makerjs.model.addPath(myHolesMarkA, new makerjs.paths.Line([-x0,y0], [-x0-markShiftThree, y0]));//X轴加长标线
makerjs.model.addPath(myHolesMarkA, new makerjs.paths.Line([0,0], [0, y0+markShiftTwo]));//从孔圆心出发Y方向竖向标线
      
/*****************************************************************************/ 
makerjs.model.addPath(myHolesMarkA, new makerjs.paths.Line([0,0], [d, -d]));//直径标线
makerjs.model.addCaption(myHolesMarkA, TxtD, [0,0], [d+markHolesOneD, -d-markHolesOneD]);//直径文字标注
/*****************************************************************************/   
makerjs.model.addPath(myXMarkA, new makerjs.paths.Line([0,y0+markShift], [-x0, y0+markShift]));//X短标线
makerjs.model.addCaption(myXMarkA, TxtX, [-x0,y0+markShiftTwo], [0, y0+markShiftTwo]);//X文字标注
/*****************************************************************************/     
makerjs.model.addPath(myYMarkA, new makerjs.paths.Line([-x0-markShiftTwo,0], [-x0-markShiftTwo, y0]));//Y短标线
makerjs.model.addCaption(myYMarkA, TxtY, [-x0-markShiftThree,0], [-x0-markShiftThree, y0]);//Y文字标注
/*****************************************************************************/ 
plate.models["myHolesA"+myHolesNum]=myHolesA;
markers.models["myHolesA"+myHolesNum]=myHolesA;
markers.models["myHolesMarkA"+myHolesNum]=myHolesMarkA;
markers.models["myXMarkA"+myHolesNum]=myXMarkA;
markers.models["myYMarkA"+myHolesNum]=myYMarkA;

}
else if(area==2){
     //B区域
  //只有Y标注没有X标注 
  //画孔
  var myHolesB= new makerjs.models.Holes(d/2, [[xb, yb]]);
  //标记孔
  var myHolesMarkB = new makerjs.models.Square(0); //孔的原点       
      myHolesMarkB.origin = [xb, yb];          
  var myYMarkB = new makerjs.models.Square(0);      
      myYMarkB.origin = [xb, yb];  
/*****************************************************************************/
makerjs.model.addPath(myHolesMarkB, new makerjs.paths.Line([-markShift/2,0], [markShift/2, 0]));//短线标注
/*****************************************************************************/ 
makerjs.model.addPath(myHolesMarkB, new makerjs.paths.Line([0,0], [d, -d]));//直径标线
makerjs.model.addCaption(myHolesMarkB, TxtD, [0,0], [d+markHolesOneD, -d-markHolesOneD]);//直径文字标注
/*****************************************************************************/     
makerjs.model.addPath(myYMarkB, new makerjs.paths.Line([0,0], [0, y0]));//Y标线
makerjs.model.addCaption(myYMarkB, TxtY, [0-markShift/2,0], [0-markShift/2, y0]);//Y文字标注
/*****************************************************************************/ 
plate.models["myHolesB"+myHolesNum]=myHolesB;
markers.models["myHolesB"+myHolesNum]=myHolesB;
markers.models["myHolesMark"+myHolesNum]=myHolesMarkB;
markers.models["myYMarkB"+myHolesNum]=myYMarkB;
}
else if(area==3){
//画孔
var myHolesC= new makerjs.models.Holes(d/2, [[xc, yc]]);
var myHolesMarkC = new makerjs.models.Square(0); //孔的原点       
    myHolesMarkC.origin = [xc,yc];      
var myXMarkC = new makerjs.models.Square(0);      
    myXMarkC.origin = [xc,yc];      
var myYMarkC = new makerjs.models.Square(0);      
    myYMarkC.origin = [xc,yc];  
/*****************************************************************************/  
makerjs.model.addPath(myHolesMarkC, new makerjs.paths.Line([x0,y0], [x0+markShiftTwo, y0]));//Y轴加长标线
makerjs.model.addPath(myHolesMarkC, new makerjs.paths.Line([x0,y0], [x0, y0+markShiftTwo]));//X轴加长标线
makerjs.model.addPath(myYMarkC, new makerjs.paths.Line([0,0], [0, y0+markShiftTwo]));////从孔圆心出发Y方向横向标线
makerjs.model.addPath(myXMarkC, new makerjs.paths.Line([0,0], [x0+markShiftTwo, 0]));////从孔圆心出发X方向竖向标线
/*****************************************************************************/ 
makerjs.model.addPath(myHolesMarkC, new makerjs.paths.Line([0,0], [-d, -d]));//直径标线
makerjs.model.addCaption(myHolesMarkC, TxtD, [-d-markHolesOneD,-d-markHolesOneD], [0, 0]);//直径文字标注
/*****************************************************************************/ 
makerjs.model.addPath(myXMarkC, new makerjs.paths.Line([0,y0+markShift], [x0, y0+markShift]));//X短标线
makerjs.model.addCaption(myXMarkC, TxtX, [0,y0+markShiftThree], [x0, y0+markShiftThree]);//X文字标注
/*****************************************************************************/    
makerjs.model.addPath(myYMarkC, new makerjs.paths.Line([x0+markShift,0], [x0+markShift, y0]));//Y短标线
makerjs.model.addCaption(myYMarkC, TxtY, [x0+markShiftThree,0], [x0+markShiftThree, y0]);//Y文字标注
/*****************************************************************************/ 
plate.models["myHolesC"+myHolesNum]=myHolesC;
markers.models["myHolesC"+myHolesNum]=myHolesC;
markers.models["myHolesMark"+myHolesNum]=myHolesMarkC;
markers.models["myXMarkC"+myHolesNum]=myXMarkC;
markers.models["myYMarkC"+myHolesNum]=myYMarkC;
}
else if(area==4){
//画孔 D位置
var myHolesD= new makerjs.models.Holes(d/2, [[xd, yd]]);
//标记孔 D位置
var myHolesMarkD = new makerjs.models.Square(0); //孔的原点       
    myHolesMarkD.origin = [xd,yd];      
var myXMarkD = new makerjs.models.Square(0);      
    myXMarkD.origin = [xd,yd];   
    
/*****************************************************************************/
makerjs.model.addPath(myHolesMarkD, new makerjs.paths.Line([0,-markShift/2], [0,markShift/2]));//短线标注
/*****************************************************************************/ 
makerjs.model.addPath(myHolesMarkD, new makerjs.paths.Line([0,0], [d, -d]));//直径标线
makerjs.model.addCaption(myHolesMarkD, TxtD, [0,0], [d+markHolesOneD, -d-markHolesOneD]);//直径文字标注
/*****************************************************************************/   
makerjs.model.addPath(myXMarkD, new makerjs.paths.Line([0,0], [-x0, 0]));//X标线
makerjs.model.addCaption(myXMarkD, TxtX, [-x0,0+markShift], [0, 0+markShift]);//X文字标注
/*****************************************************************************/ 
plate.models["myHolesD"+myHolesNum]=myHolesD;
markers.models["myHolesD"+myHolesNum]=myHolesD;
markers.models["myHolesMarkD"+myHolesNum]=myHolesMarkD;
markers.models["myXMarkD"+myHolesNum]=myXMarkD;
}
else if(area==5){
//画孔 E位置
var myHolesE= new makerjs.models.Holes(d/2, [[xe, ye]]);
var myHolesMarkE = new makerjs.models.Square(0); //孔的原点       
    myHolesMarkE.origin = [xe,ye];      
var myXMarkE = new makerjs.models.Square(0);      
    myXMarkE.origin = [xe,ye]; 
/*****************************************************************************/ 
makerjs.model.addPath(myHolesMarkE, new makerjs.paths.Line([0,0], [d+markHolesOneD, 0]));//直径标线
makerjs.model.addCaption(myHolesMarkE, TxtD, [0,0], [d+markHolesOneD, 0]);//直径文字标注 
/*****************************************************************************/ 
plate.models["myHolesE"+myHolesNum]=myHolesE;
markers.models["myHolesE"+myHolesNum]=myHolesE;
markers.models["myHolesMarkE"+myHolesNum]=myHolesMarkE;
markers.models["myXMarkE"+myHolesNum]=myXMarkE;
}
else if(area==6){
//画孔 F位置
var myHolesF= new makerjs.models.Holes(d/2, [[xf, yf]]);
var myHolesMarkF = new makerjs.models.Square(0); //孔的原点       
    myHolesMarkF.origin = [xf,yf];      
var myXMarkF = new makerjs.models.Square(0);      
    myXMarkF.origin = [xf,yf];      
/*****************************************************************************/
makerjs.model.addPath(myHolesMarkF, new makerjs.paths.Line([0,-markShift/2], [0,markShift/2]));//短线标注
/*****************************************************************************/ 
makerjs.model.addPath(myHolesMarkF, new makerjs.paths.Line([0,0], [-d, -d]));//直径标线
makerjs.model.addCaption(myHolesMarkF, TxtD, [-d-markHolesOneD, -d-markHolesOneD],[0,0] );//直径文字标注
/*****************************************************************************/   
makerjs.model.addPath(myXMarkF, new makerjs.paths.Line([0,0], [x0, 0]));//X标线
makerjs.model.addCaption(myXMarkF, TxtX, [0,0+markShift], [x0, 0+markShift]);//X文字标注
/*****************************************************************************/  
plate.models["myHolesF"+myHolesNum]=myHolesF;
markers.models["myHolesF"+myHolesNum]=myHolesF;
markers.models["myHolesMarkF"+myHolesNum]=myHolesMarkF;
markers.models["myXMarkF"+myHolesNum]=myXMarkF;
}
else if(area==7){
//画孔 G
var myHolesG= new makerjs.models.Holes(d/2, [[xg, yg]]);
//标记孔 G
var myHolesMarkG = new makerjs.models.Square(0); //孔的原点       
    myHolesMarkG.origin = [xg,yg];      
var myXMarkG = new makerjs.models.Square(0);      
    myXMarkG.origin = [xg,yg];      
var myYMarkG = new makerjs.models.Square(0);      
    myYMarkG.origin = [xg,yg];  

/*****************************************************************************/ 
makerjs.model.addPath(myHolesMarkG, new makerjs.paths.Line([-x0,-y0], [-x0,-y0-markShiftFour]));//Y轴加长标线
makerjs.model.addPath(myHolesMarkG, new makerjs.paths.Line([0,0], [-x0-markShiftFour, 0]));//从孔圆心出发X方向横向标线
makerjs.model.addPath(myHolesMarkG, new makerjs.paths.Line([-x0,-y0], [-x0-markShiftFour,-y0]));//X轴加长标线
makerjs.model.addPath(myHolesMarkG, new makerjs.paths.Line([0,0], [0, -y0-markShiftFour]));//从孔圆心出发Y方向竖向标线                   
/*****************************************************************************/ 
makerjs.model.addPath(myHolesMarkG, new makerjs.paths.Line([0,0], [d, d]));//直径标线
makerjs.model.addCaption(myHolesMarkG, TxtD, [0,0], [d+markHolesOneD, d+markHolesOneD]);//直径文字标注
/*****************************************************************************/    
makerjs.model.addPath(myXMarkG, new makerjs.paths.Line([0,-y0-markShiftTwo], [-x0, -y0-markShiftTwo]));//X短标线
makerjs.model.addCaption(myXMarkG, TxtX, [-x0,-y0-markShiftFour], [0, -y0-markShiftFour]);//X文字标注
/*****************************************************************************/     
makerjs.model.addPath(myYMarkG, new makerjs.paths.Line([-x0-markShiftTwo,0], [-x0-markShiftTwo, -y0]));//Y短标线
makerjs.model.addCaption(myYMarkG, TxtY, [-x0-markShiftThree, -y0], [-x0-markShiftThree,0]);//Y文字标注
/*****************************************************************************/ 
plate.models["myHolesG"+myHolesNum]=myHolesG;
markers.models["myHolesG"+myHolesNum]=myHolesG;
markers.models["myHolesMark"+myHolesNum]=myHolesMarkG;
markers.models["myXMarkG"+myHolesNum]=myXMarkG;
markers.models["myYMarkG"+myHolesNum]=myYMarkG;
}
else if(area==8){
//画孔 H
var myHolesH= new makerjs.models.Holes(d/2, [[xh, yh]]);
//标记孔
var myHolesMarkH = new makerjs.models.Square(0); //孔的原点       
    myHolesMarkH.origin = [xh,yh];      
var myXMarkH = new makerjs.models.Square(0);      
    myXMarkH.origin = [xh,yh];      
var myYMarkH = new makerjs.models.Square(0);      
    myYMarkH.origin = [xh,yh];  
    
/*****************************************************************************/
makerjs.model.addPath(myHolesMarkH, new makerjs.paths.Line([-markShift/2,0], [markShift/2, 0]));//短线标注
/*****************************************************************************/ 
makerjs.model.addPath(myHolesMarkH, new makerjs.paths.Line([0,0], [d, d]));//直径标线
makerjs.model.addCaption(myHolesMarkH, TxtD, [0,0], [d+markHolesOneD, d+markHolesOneD]);//直径文字标注
/*****************************************************************************/    
makerjs.model.addPath(myYMarkH, new makerjs.paths.Line([0,0], [0, -y0]));//Y标线
makerjs.model.addCaption(myYMarkH, TxtY, [0-markShift/2, -y0],[0-markShift/2,0]);//Y文字标注
/*****************************************************************************/ 
plate.models["myHolesH"+myHolesNum]=myHolesH;
markers.models["myHolesH"+myHolesNum]=myHolesH;
markers.models["myHolesMarkH"+myHolesNum]=myHolesMarkH;
markers.models["myXMarkH"+myHolesNum]=myXMarkH;
markers.models["myYMarkH"+myHolesNum]=myYMarkH;
}
else if(area==9){
 //画孔I
 var myHolesI= new makerjs.models.Holes(d/2, [[xi, yi]]);
 //标记孔I
 var myHolesMarkI = new makerjs.models.Square(0); //孔的原点       
     myHolesMarkI.origin = [xi,yi];      
 var myXMarkI = new makerjs.models.Square(0);      
     myXMarkI.origin = [xi,yi];      
 var myYMarkI = new makerjs.models.Square(0);      
     myYMarkI.origin = [xi,yi];  
      
/*****************************************************************************/  
makerjs.model.addPath(myHolesMarkI, new makerjs.paths.Line([x0,-y0], [x0+markShiftTwo, -y0]));//X轴加长标线
makerjs.model.addPath(myHolesMarkI, new makerjs.paths.Line([x0,y0], [x0, -y0-markShiftThree]));//Y轴加长标线
makerjs.model.addPath(myYMarkI, new makerjs.paths.Line([0,0], [0, -y0-markShiftThree]));////从孔圆心出发Y方向横向标线
makerjs.model.addPath(myXMarkI, new makerjs.paths.Line([0,0], [x0+markShiftTwo, 0]));////从孔圆心出发X方向竖向标线
/*****************************************************************************/ 
makerjs.model.addPath(myHolesMarkI, new makerjs.paths.Line([0,0], [-d, d]));//直径标线
makerjs.model.addCaption(myHolesMarkI, TxtD, [-d-markHolesOneD, d+markHolesOneD],[0,0] );//直径文字标注
/*****************************************************************************/
makerjs.model.addPath(myYMarkI, new makerjs.paths.Line([x0+markShift,0], [x0+markShift, -y0]));//Y短标线
makerjs.model.addCaption(myYMarkI, TxtY, [x0+markShiftTwo,0], [x0+markShiftTwo, -y0]);//Y文字标注
/*****************************************************************************/    
makerjs.model.addPath(myXMarkI, new makerjs.paths.Line([0,-y0-markShiftTwo], [x0, -y0-markShiftTwo]));//X短标线
makerjs.model.addCaption(myXMarkI, TxtX, [0,-y0-markShiftThree], [x0, -y0-markShiftThree]);//X文字标注
/*****************************************************************************/ 
/*****************************************************************************/ 
plate.models["myHolesI"+myHolesNum]=myHolesI;
markers.models["myHolesI"+myHolesNum]=myHolesI;
markers.models["myHolesMarkI"+myHolesNum]=myHolesMarkI;
markers.models["myXMarkI"+myHolesNum]=myXMarkI;
markers.models["myYMarkI"+myHolesNum]=myYMarkI;
}
myHolesNum++;
return{plate, markers,myHolesNum}
};


export default SingleHole ;

