//4、双吊轮孔列
import makerjs from "makerjs";
// inputParam,model,this.myHolesNum,outParam,fixedParam
// (D,X,Y,area,plate,markers,myHolesNum,glassW,glassH,markShift,markShiftTwo,markShiftThree,markShiftFour,markHolesOneD)
function HangWheelHoleTwoCol(inputParam,model,myHolesNum,outParam,fixedParam,zoom){
var D=inputParam.D,X=inputParam.X,H=inputParam.H,Y=inputParam.Y,area=inputParam.area,myZoom=zoom*1,
plate=model.plate,markers=model.markers,
glassW=outParam.glassW,glassH=outParam.glassH,
   markShift=fixedParam.markShift,markShiftTwo=fixedParam.markShiftTwo,
   markShiftThree=fixedParam.markShiftThree,markShiftFour=fixedParam.markShiftFour,markHolesOneD=fixedParam.markHolesOneD,
   d = D*1*myZoom,
   h = H*1*myZoom,
    x0 = X*1*myZoom,
    y0 = Y*1*myZoom;
  
   //d = D*1,
  // h = H*1,
   if(d!==0){
      var TxtD="∮"+D;
     }
   if(h!==0){
    var TxtH=H;
   }
   if(x0!==0){          
   var TxtX=X;
   }
   if(y0!==0){          
    var TxtY=Y;
   }
  //在A区域 计算孔的位置       
  var xa=x0,ya=glassH * 1-y0,
  //B区域//只有Y标注没有X标注 
  xb=glassW * 1/2,yb=glassH * 1-y0,
  //C区域
  xc=glassW * 1-x0,yc=glassH * 1-y0,
  //D区域
  //只有X标注没有Y标注 
  xd=x0,yd=glassH * 1/2,
  //E区域
  //只有X标注没有Y标注 
  xe=glassW * 1/2,ye=glassH * 1/2,
  //F区域
  //只有X标注没有Y标注 
  xf=glassW * 1-x0,yf=glassH * 1/2,
  //在G区域 计算孔的位置
  xg=x0,yg=y0,
  //在H区域 计算孔的位置
  //只标注Y
  xh=glassW*1/2,yh=y0,
  //在I区域 计算孔的位置
  xi=glassW*1-x0,yi=y0;
//画孔 A位置孔
      //标记双吊轮孔列 A位置孔上
      var myHwhtcHolesAup= new makerjs.models.Holes(d/2, [[xa, ya]]);
      var myHwhtcHolesMarkAup = new makerjs.models.Square(0); //孔的原点       
      myHwhtcHolesMarkAup.origin = [xa, ya];      
      var myHwhtcXMarkAup = new makerjs.models.Square(0);      
      myHwhtcXMarkAup.origin =[xa, ya];      
      var myHwhtcYMarkAup = new makerjs.models.Square(0);      
      myHwhtcYMarkAup.origin = [xa, ya];
/*****************************************************************************/ 
makerjs.model.addPath(myHwhtcHolesMarkAup, new makerjs.paths.Line([-x0,y0], [-x0, y0+markShiftTwo]));//Y轴加长标线
makerjs.model.addPath(myHwhtcHolesMarkAup, new makerjs.paths.Line([0,0], [-x0-markShiftThree, 0]));//从孔圆心出发X方向横向标线
makerjs.model.addPath(myHwhtcHolesMarkAup, new makerjs.paths.Line([-x0,y0], [-x0-markShiftThree, y0]));//X轴加长标线
makerjs.model.addPath(myHwhtcHolesMarkAup, new makerjs.paths.Line([0,0], [0, y0+markShiftTwo]));//从孔圆心出发Y方向竖向标线
/*****************************************************************************/ 
makerjs.model.addPath(myHwhtcHolesMarkAup, new makerjs.paths.Line([0,0], [d, -d]));//直径标线
makerjs.model.addCaption(myHwhtcHolesMarkAup, TxtD, [0,0], [d+markHolesOneD, -d-markHolesOneD]);//直径文字标注
/*****************************************************************************/   
makerjs.model.addPath(myHwhtcXMarkAup, new makerjs.paths.Line([0,y0+markShift], [-x0, y0+markShift]));//X短标线
makerjs.model.addCaption(myHwhtcXMarkAup, TxtX, [-x0,y0+markShiftTwo], [0, y0+markShiftTwo]);//X文字标注
/*****************************************************************************/     
makerjs.model.addPath(myHwhtcYMarkAup, new makerjs.paths.Line([-x0-markShiftTwo,0], [-x0-markShiftTwo, y0]));//Y短标线
makerjs.model.addCaption(myHwhtcYMarkAup, TxtY, [-x0-markShiftThree,0], [-x0-markShiftThree, y0]);//Y文字标注
/*****************************************************************************/ 
      //标记双吊轮孔列 A位置孔下
      var myHwhtcHolesAdn= new makerjs.models.Holes(d/2, [[xa, ya-h]]);
      var myHwhtcHolesMarkAdn = new makerjs.models.Square(0); //孔的原点       
      myHwhtcHolesMarkAdn.origin = [xa, ya-h];      
      var myHwhtcXMarkAdn = new makerjs.models.Square(0);      
     myHwhtcXMarkAdn.origin =[xa, ya-h];      
      var myHwhtcYMarkAdn = new makerjs.models.Square(0);      
      myHwhtcYMarkAdn.origin = [xa, ya-h];  
/*****************************************************************************/ 
makerjs.model.addPath(myHwhtcHolesMarkAdn, new makerjs.paths.Line([0,0], [-x0-markShiftThree, 0]));//从孔圆心出发X方向横向标线
/*****************************************************************************/ 
makerjs.model.addPath(myHwhtcHolesMarkAdn, new makerjs.paths.Line([0,0], [d, -d]));//直径标线
makerjs.model.addCaption(myHwhtcHolesMarkAdn, TxtD, [0,0], [d+markHolesOneD, -d-markHolesOneD]);//直径文字标注
/*****************************************************************************/   
makerjs.model.addPath(myHwhtcXMarkAdn, new makerjs.paths.Line([0,y0+markShift], [-x0, y0+markShift]));//X短标线
/*****************************************************************************/ 
makerjs.model.addPath(myHwhtcYMarkAdn, new makerjs.paths.Line([-x0-markShiftTwo,0], [-x0-markShiftTwo, y0]));//Y短标线    
makerjs.model.addPath(myHwhtcYMarkAdn, new makerjs.paths.Line([0,0], [0, h]));//H短标线
makerjs.model.addCaption(myHwhtcYMarkAdn, TxtH, [-x0-markShiftThree,0], [-x0-markShiftThree,h]);//H文字标注
/*****************************************************************************/
 //标记双吊轮孔列 C位置孔上
     var myHwhtcHolesCup= new makerjs.models.Holes(d/2, [[xc, yc]]);
      var myHwhtcHolesMarkCup = new makerjs.models.Square(0); //孔的原点       
      myHwhtcHolesMarkCup.origin = [xc, yc];      
      var myHwhtcXMarkCup = new makerjs.models.Square(0);      
      myHwhtcXMarkCup.origin = [xc, yc];      
      var myHwhtcYMarkCup = new makerjs.models.Square(0);      
      myHwhtcYMarkCup.origin = [xc, yc];  
/*****************************************************************************/  
makerjs.model.addPath(myHwhtcHolesMarkCup, new makerjs.paths.Line([x0,y0], [x0+markShiftTwo, y0]));//Y轴加长标线
makerjs.model.addPath(myHwhtcHolesMarkCup, new makerjs.paths.Line([x0,y0], [x0, y0+markShiftTwo]));//X轴加长标线
makerjs.model.addPath(myHwhtcXMarkCup, new makerjs.paths.Line([0,0], [0, y0+markShiftTwo]));////从孔圆心出发Y方向横向标线
makerjs.model.addPath(myHwhtcXMarkCup, new makerjs.paths.Line([0,0], [x0+markShiftTwo, 0]));////从孔圆心出发X方向竖向标线
/*****************************************************************************/ 
makerjs.model.addPath(myHwhtcHolesMarkCup, new makerjs.paths.Line([0,0], [-d, -d]));//直径标线
makerjs.model.addCaption(myHwhtcHolesMarkCup, TxtD, [-d-markHolesOneD,-d-markHolesOneD], [0, 0]);//直径文字标注
/*****************************************************************************/ 
makerjs.model.addPath(myHwhtcXMarkCup, new makerjs.paths.Line([0,y0+markShift], [x0, y0+markShift]));//X短标线
makerjs.model.addCaption(myHwhtcXMarkCup, TxtX, [0,y0+markShiftThree], [x0, y0+markShiftThree]);//X文字标注
/*****************************************************************************/    
makerjs.model.addPath(myHwhtcYMarkCup, new makerjs.paths.Line([x0+markShift,0], [x0+markShift, y0]));//Y短标线
makerjs.model.addCaption(myHwhtcYMarkCup, TxtY, [x0+markShiftThree,0], [x0+markShiftThree, y0]);//Y文字标注
/*****************************************************************************/ 
//标记双吊轮孔列 C位置孔下
  var myHwhtcHolesCdn= new makerjs.models.Holes(d/2, [[xc, yc-h]]);
      var myHwhtcHolesMarkCdn = new makerjs.models.Square(0); //孔的原点       
      myHwhtcHolesMarkCdn.origin = [xc, yc-h];  
      var myHwhtcYMarkCdn = new makerjs.models.Square(0);      
      myHwhtcYMarkCdn.origin = [xc, yc-h];  
/*****************************************************************************/  
makerjs.model.addPath(myHwhtcYMarkCdn, new makerjs.paths.Line([0,0], [x0+markShiftTwo, 0]));////从孔圆心出发X方向竖向标线
/*****************************************************************************/ 
makerjs.model.addPath(myHwhtcHolesMarkCdn, new makerjs.paths.Line([0,0], [-d, -d]));//直径标线
makerjs.model.addCaption(myHwhtcHolesMarkCdn, TxtD, [-d-markHolesOneD,-d-markHolesOneD], [0, 0]);//直径文字标注
/*****************************************************************************/ 
makerjs.model.addPath(myHwhtcYMarkCdn, new makerjs.paths.Line([x0+markShift,0], [x0+markShift, y0]));//Y短标线
/*****************************************************************************/    
makerjs.model.addPath(myHwhtcYMarkCdn, new makerjs.paths.Line([0,0], [0, h]));//H短标线
makerjs.model.addCaption(myHwhtcYMarkCdn, TxtH, [x0+markShiftThree,0], [x0+markShiftThree, h]);//H文字标注
/*****************************************************************************/
/*****************************************************************************/ 
plate.models["myHwhtcHolesAup"+myHolesNum]=myHwhtcHolesAup;
markers.models["myHwhtcHolesAup"+myHolesNum]=myHwhtcHolesAup;
markers.models["myHwhtcHolesMarkAup"+myHolesNum]=myHwhtcHolesMarkAup;
markers.models["myHwhtcXMarkAup"+myHolesNum]=myHwhtcXMarkAup;
markers.models["myHwhtcYMarkAup"+myHolesNum]=myHwhtcYMarkAup;
/*****************************************************************************/
plate.models["myHwhtcHolesAdn"+myHolesNum]=myHwhtcHolesAdn;
markers.models["myHwhtcHolesAdn"+myHolesNum]=myHwhtcHolesAdn;
markers.models["myHwhtcHolesMarkAdn"+myHolesNum]=myHwhtcHolesMarkAdn;
markers.models["myHwhtcYMarkAdn"+myHolesNum]=myHwhtcYMarkAdn;
/*****************************************************************************/
plate.models["myHwhtcHolesCup"+myHolesNum]=myHwhtcHolesCup;
markers.models["myHwhtcHolesCup"+myHolesNum]=myHwhtcHolesCup;
markers.models["myHwhtcHolesMarkCup"+myHolesNum]=myHwhtcHolesMarkCup;
markers.models["myHwhtcXMarkCup"+myHolesNum]=myHwhtcXMarkCup;
markers.models["myHwhtcYMarkCup"+myHolesNum]=myHwhtcYMarkCup; 
/*****************************************************************************/ 
/*****************************************************************************/
plate.models["myHwhtcHolesCdn"+myHolesNum]=myHwhtcHolesCdn;
markers.models["myHwhtcHolesCdn"+myHolesNum]=myHwhtcHolesCdn;
markers.models["myHwhtcHolesMarkCdn"+myHolesNum]=myHwhtcHolesMarkCdn;
markers.models["myHwhtcYMarkCdn"+myHolesNum]=myHwhtcYMarkCdn; 
/*****************************************************************************/ 

myHolesNum++;
return{plate, markers,myHolesNum}
};


export default HangWheelHoleTwoCol;