//14、7字拉手孔
import makerjs from "makerjs";
// inputParam,model,myHolesNum,outParam,fixedParam
// (D,X,Y,area,plate,markers,myHolesNum,glassW,glassH,markShift,markShiftTwo,markShiftThree,markShiftFour,markHolesOneD)
function Shaped7HandleHole(inputParam,model,myHolesNum,outParam,fixedParam,zoom){
var D=inputParam.D,W=inputParam.W,H=inputParam.H,X=inputParam.X,Y=inputParam.Y,area=inputParam.area,myZoom=zoom*1,
plate=model.plate,markers=model.markers,
glassW=outParam.glassW,glassH=outParam.glassH,
   markShift=fixedParam.markShift,markShiftTwo=fixedParam.markShiftTwo,
   markShiftThree=fixedParam.markShiftThree,markShiftFour=fixedParam.markShiftFour,markHolesOneD=fixedParam.markHolesOneD,
 
   d = D*1*myZoom,
      w= W*1*myZoom,
      h= H*1*myZoom,
        x0 = X*1*myZoom,
        y0 = Y*1*myZoom;
        /*
        d = D*1,
           w= W*1,
           h= H*1,
             x0 = X*1,
             y0 = Y*1;
             */
        if(d!==0){
         var TxtD="∮"+D;
        }
        if(h!==0){
         var TxtH=H;
        }
        if(w!==0){
         var TxtW=W;
        }
        if(x0!==0){          
        var TxtX=X;
        }
        if(y0!==0){          
         var TxtY=Y;
        }
  //在A区域 计算孔的位置       
  var xa=x0,ya=glassH * 1-y0,
  //B区域//只有Y标注没有X标注 
  xb=glassW * 1/2,yb=glassH * 1-y0,
  //C区域
  xc=glassW * 1-x0,yc=glassH * 1-y0,
  //D区域
  //只有X标注没有Y标注 
  xd=x0,yd=glassH * 1/2,
  //E区域
  //只有X标注没有Y标注 
  xe=glassW * 1/2,ye=glassH * 1/2,
  //F区域
  //只有X标注没有Y标注 
  xf=glassW * 1-x0,yf=glassH * 1/2,
  //在G区域 计算孔的位置
  xg=x0,yg=y0,
  //在H区域 计算孔的位置
  //只标注Y
  xh=glassW*1/2,yh=y0,
  //在I区域 计算孔的位置
  xi=glassW*1-x0,yi=y0;
  if(area==1){
    //画孔 A位置孔
          //标记7字拉手孔 A位置孔左上
          var my7ShhHolesALeftup= new makerjs.models.Holes(d/2, [[xa, ya]]);
          var my7ShhHolesMarkALeftup = new makerjs.models.Square(0); //孔的原点       
          my7ShhHolesMarkALeftup.origin = [xa, ya];      
          var my7ShhHolesXMarkALeftup = new makerjs.models.Square(0);      
          my7ShhHolesXMarkALeftup.origin =[xa, ya];      
          var my7ShhHolesYMarkALeftup = new makerjs.models.Square(0);      
          my7ShhHolesYMarkALeftup.origin = [xa, ya];  
    /*****************************************************************************/ 
    makerjs.model.addPath(my7ShhHolesMarkALeftup, new makerjs.paths.Line([-x0,y0], [-x0, y0+markShiftTwo]));//Y轴加长标线
    makerjs.model.addPath(my7ShhHolesMarkALeftup, new makerjs.paths.Line([0,0], [-x0-markShiftThree, 0]));//从孔圆心出发X方向横向标线
    makerjs.model.addPath(my7ShhHolesMarkALeftup, new makerjs.paths.Line([-x0,y0], [-x0-markShiftThree, y0]));//X轴加长标线
    makerjs.model.addPath(my7ShhHolesMarkALeftup, new makerjs.paths.Line([0,0], [0, y0+markShiftTwo]));//从孔圆心出发Y方向竖向标线
    /*****************************************************************************/ 
    makerjs.model.addPath(my7ShhHolesMarkALeftup, new makerjs.paths.Line([0,0], [d, -d]));//直径标线
    makerjs.model.addCaption(my7ShhHolesMarkALeftup, TxtD, [0,0], [d+markHolesOneD, -d-markHolesOneD]);//直径文字标注
    /*****************************************************************************/   
    makerjs.model.addPath(my7ShhHolesXMarkALeftup, new makerjs.paths.Line([0,y0+markShift], [-x0, y0+markShift]));//X短标线
    makerjs.model.addCaption(my7ShhHolesXMarkALeftup, TxtX, [-x0,y0+markShiftTwo], [0, y0+markShiftTwo]);//X文字标注
    /*****************************************************************************/     
    makerjs.model.addPath(my7ShhHolesYMarkALeftup, new makerjs.paths.Line([-x0-markShiftTwo,0], [-x0-markShiftTwo, y0]));//Y短标线
    makerjs.model.addCaption(my7ShhHolesYMarkALeftup, TxtY, [-x0-markShiftThree,0], [-x0-markShiftThree, y0]);//Y文字标注
    /*****************************************************************************/ 
    /*****************************************************************************/ 
     //标记7字拉手孔 A位置孔左中
     var my7ShhHolesALeftmd= new makerjs.models.Holes(d/2, [[xa, ya-h]]);
          var my7ShhHolesMarkALeftmd = new makerjs.models.Square(0); //孔的原点       
          my7ShhHolesMarkALeftmd.origin = [xa, ya-h];       
          var my7ShhHolesYMarkALeftmd = new makerjs.models.Square(0);      
          my7ShhHolesYMarkALeftmd.origin = [xa, ya-h];  
    /*****************************************************************************/ 
    makerjs.model.addPath(my7ShhHolesMarkALeftmd, new makerjs.paths.Line([0,0], [-x0-markShiftThree, 0]));//从孔圆心出发X方向横向标线
    makerjs.model.addPath(my7ShhHolesMarkALeftmd, new makerjs.paths.Line([0,0], [0, h+markShiftTwo]));//从孔圆心出发Y方向竖向标线
    /*****************************************************************************/ 
    makerjs.model.addPath(my7ShhHolesMarkALeftmd, new makerjs.paths.Line([0,0], [d, -d]));//直径标线
    makerjs.model.addCaption(my7ShhHolesMarkALeftmd, TxtD, [0,0], [d+markHolesOneD, -d-markHolesOneD]);//直径文字标注
    /*****************************************************************************/   
    makerjs.model.addPath(my7ShhHolesYMarkALeftmd, new makerjs.paths.Line([-x0-markShiftTwo,0], [-x0-markShiftTwo, h]));//Y短标线
    makerjs.model.addCaption(my7ShhHolesYMarkALeftmd, TxtH, [-x0-markShiftThree,0], [-x0-markShiftThree, h]);//Y文字标注
    /*****************************************************************************/ 
    /*****************************************************************************/
     //标记7字拉手孔 A位置孔右中
     var my7ShhHolesARightmd= new makerjs.models.Holes(d/2, [[xa+w, ya-h]]);
          var my7ShhHolesMarkARightmd = new makerjs.models.Square(0); //孔的原点       
          my7ShhHolesMarkARightmd.origin = [xa+w, ya-h];      
          var my7ShhHolesXMarkARightmd = new makerjs.models.Square(0);      
          my7ShhHolesXMarkARightmd.origin =[xa+w, ya-h];      
          var my7ShhHolesYMarkARightmd = new makerjs.models.Square(0);      
          my7ShhHolesYMarkARightmd.origin = [xa+w, ya-h];  
    /*****************************************************************************/ 
    makerjs.model.addPath(my7ShhHolesMarkARightmd, new makerjs.paths.Line([0,0], [-w, 0]));//从孔圆心出发X方向横向标线
    makerjs.model.addPath(my7ShhHolesMarkARightmd, new makerjs.paths.Line([0,0], [0, h+y0+markShiftTwo]));//从孔圆心出发Y方向竖向标线
    /*****************************************************************************/ 
    makerjs.model.addPath(my7ShhHolesMarkARightmd, new makerjs.paths.Line([0,0], [d, -d]));//直径标线
    makerjs.model.addCaption(my7ShhHolesMarkARightmd, TxtD, [0,0], [d+markHolesOneD, -d-markHolesOneD]);//直径文字标注
    /*****************************************************************************/   
    makerjs.model.addPath(my7ShhHolesXMarkARightmd, new makerjs.paths.Line([0,y0+h+markShift], [-w, y0+h+markShift]));//X短标线
    makerjs.model.addCaption(my7ShhHolesXMarkARightmd, TxtW, [-w,y0+h+markShiftTwo], [0, y0+h+markShiftTwo]);//X文字标注
    /*****************************************************************************/  
    plate.models["my7ShhHolesALeftup"+myHolesNum]=my7ShhHolesALeftup;
    markers.models["my7ShhHolesALeftup"+myHolesNum]=my7ShhHolesALeftup;
    markers.models["my7ShhHolesMarkALeftup"+myHolesNum]=my7ShhHolesMarkALeftup;
    markers.models["my7ShhHolesXMarkALeftup"+myHolesNum]=my7ShhHolesXMarkALeftup;
    markers.models["my7ShhHolesYMarkALeftup"+myHolesNum]=my7ShhHolesYMarkALeftup;
    /*****************************************************************************/
    plate.models["my7ShhHolesALeftmd"+myHolesNum]=my7ShhHolesALeftmd;
    markers.models["my7ShhHolesALeftmd"+myHolesNum]=my7ShhHolesALeftmd;
    markers.models["my7ShhHolesMarkALeftmd"+myHolesNum]=my7ShhHolesMarkALeftmd;
    markers.models["my7ShhHolesYMarkALeftmd"+myHolesNum]=my7ShhHolesYMarkALeftmd;
    /*****************************************************************************/ 
    plate.models["my7ShhHolesARightmd"+myHolesNum]=my7ShhHolesARightmd;
    markers.models["my7ShhHolesARightmd"+myHolesNum]=my7ShhHolesARightmd;
    markers.models["my7ShhHolesMarkARightmd"+myHolesNum]=my7ShhHolesMarkARightmd;
    markers.models["my7ShhHolesXMarkARightmd"+myHolesNum]=my7ShhHolesXMarkARightmd;
    /*****************************************************************************/ 
    
    } else if(area==3){
    //画孔 C位置孔
          //标记7字拉手孔C位置孔右上
          var my7ShhHolesCRightup= new makerjs.models.Holes(d/2, [[xc, yc]]);
          var my7ShhHolesMarkCRightup = new makerjs.models.Square(0); //孔的原点       
          my7ShhHolesMarkCRightup.origin = [xc, yc];      
          var my7ShhHolesXMarkCRightup = new makerjs.models.Square(0);      
          my7ShhHolesXMarkCRightup.origin =[xc, yc];      
          var my7ShhHolesYMarkCRightup = new makerjs.models.Square(0);      
          my7ShhHolesYMarkCRightup.origin = [xc, yc];  
    /*****************************************************************************/ 
    makerjs.model.addPath(my7ShhHolesMarkCRightup, new makerjs.paths.Line([x0,y0], [x0, y0+markShiftFour]));//Y轴加长标线
    makerjs.model.addPath(my7ShhHolesMarkCRightup, new makerjs.paths.Line([0,0], [x0+markShiftThree, 0]));//从孔圆心出发X方向横向标线
    makerjs.model.addPath(my7ShhHolesMarkCRightup, new makerjs.paths.Line([x0,y0], [x0+markShiftThree, y0]));//X轴加长标线
    makerjs.model.addPath(my7ShhHolesMarkCRightup, new makerjs.paths.Line([0,0], [0, y0+markShiftFour]));//从孔圆心出发Y方向竖向标线
    /*****************************************************************************/ 
    makerjs.model.addPath(my7ShhHolesMarkCRightup, new makerjs.paths.Line([0,0], [d, -d]));//直径标线
    makerjs.model.addCaption(my7ShhHolesMarkCRightup, TxtD, [0,0], [d+markHolesOneD, -d-markHolesOneD]);//直径文字标注
    /*****************************************************************************/   
    makerjs.model.addPath(my7ShhHolesXMarkCRightup, new makerjs.paths.Line([0,y0+markShift], [x0, y0+markShift]));//X短标线
    makerjs.model.addCaption(my7ShhHolesXMarkCRightup, TxtX, [0, y0+markShiftThree], [x0,y0+markShiftThree]);//X文字标注
    /*****************************************************************************/     
    makerjs.model.addPath(my7ShhHolesYMarkCRightup, new makerjs.paths.Line([x0+markShift,0], [x0+markShift, y0]));//Y短标线
    makerjs.model.addCaption(my7ShhHolesYMarkCRightup, TxtY, [x0+markShiftThree,0], [x0+markShiftThree, y0]);//Y文字标注
    /*****************************************************************************/ 
    /*****************************************************************************/ 
     //标记7字拉手孔C位置孔右中
     var my7ShhHolesCRightmd= new makerjs.models.Holes(d/2, [[xc, yc-h]]);
          var my7ShhHolesMarkCRightmd = new makerjs.models.Square(0); //孔的原点       
          my7ShhHolesMarkCRightmd.origin = [xc, yc-h];       
          var my7ShhHolesYMarkCRightmd = new makerjs.models.Square(0);      
          my7ShhHolesYMarkCRightmd.origin = [xc, yc-h];  
    /*****************************************************************************/ 
    makerjs.model.addPath(my7ShhHolesMarkCRightmd, new makerjs.paths.Line([0,0], [x0+markShiftThree, 0]));//从孔圆心出发X方向横向标线
    makerjs.model.addPath(my7ShhHolesMarkCRightmd, new makerjs.paths.Line([0,0], [0, h+markShiftFour]));//从孔圆心出发Y方向竖向标线
    /*****************************************************************************/ 
    makerjs.model.addPath(my7ShhHolesMarkCRightmd, new makerjs.paths.Line([0,0], [d, -d]));//直径标线
    makerjs.model.addCaption(my7ShhHolesMarkCRightmd, TxtD, [0,0], [d+markHolesOneD, -d-markHolesOneD]);//直径文字标注
    /*****************************************************************************/   
    makerjs.model.addPath(my7ShhHolesYMarkCRightmd, new makerjs.paths.Line([x0+markShift,0], [x0+markShift, h]));//Y短标线
    makerjs.model.addCaption(my7ShhHolesYMarkCRightmd, TxtH, [x0+markShiftThree,0], [x0+markShiftThree, h]);//Y文字标注
    /*****************************************************************************/ 
    /*****************************************************************************/
     //标记7字拉手孔C位置孔左中
     var my7ShhHolesCLeftmd= new makerjs.models.Holes(d/2, [[xc-w, yc-h]]);
          var my7ShhHolesMarkCLeftmd = new makerjs.models.Square(0); //孔的原点       
          my7ShhHolesMarkCLeftmd.origin = [xc-w, yc-h];      
          var my7ShhHolesXMarkCLeftmd = new makerjs.models.Square(0);      
          my7ShhHolesXMarkCLeftmd.origin =[xc-w, yc-h];      
          var my7ShhHolesYMarkCLeftmd = new makerjs.models.Square(0);      
          my7ShhHolesYMarkCLeftmd.origin = [xc-w, yc-h];  
    /*****************************************************************************/ 
    makerjs.model.addPath(my7ShhHolesMarkCLeftmd, new makerjs.paths.Line([0,0], [x0+markShiftThree, 0]));//从孔圆心出发X方向横向标线
    makerjs.model.addPath(my7ShhHolesMarkCLeftmd, new makerjs.paths.Line([0,0], [0, h+y0+markShiftFour]));//从孔圆心出发Y方向竖向标线
    /*****************************************************************************/ 
    makerjs.model.addPath(my7ShhHolesMarkCLeftmd, new makerjs.paths.Line([0,0], [d, -d]));//直径标线
    makerjs.model.addCaption(my7ShhHolesMarkCLeftmd, TxtD, [0,0], [d+markHolesOneD, -d-markHolesOneD]);//直径文字标注
    /*****************************************************************************/   
    makerjs.model.addPath(my7ShhHolesXMarkCLeftmd, new makerjs.paths.Line([0,y0+h+markShift], [w, y0+h+markShift]));//X短标线
    makerjs.model.addCaption(my7ShhHolesXMarkCLeftmd, TxtW, [0, y0+h+markShiftThree], [w,y0+h+markShiftThree]);//X文字标注
    /*****************************************************************************/  
    plate.models["my7ShhHolesCRightup"+myHolesNum]=my7ShhHolesCRightup;
    markers.models["my7ShhHolesCRightup"+myHolesNum]=my7ShhHolesCRightup;
    markers.models["my7ShhHolesMarkCRightup"+myHolesNum]=my7ShhHolesMarkCRightup;
    markers.models["my7ShhHolesXMarkCRightup"+myHolesNum]=my7ShhHolesXMarkCRightup;
    markers.models["my7ShhHolesYMarkCRightup"+myHolesNum]=my7ShhHolesYMarkCRightup;
    /*****************************************************************************/
    plate.models["my7ShhHolesCRightmd"+myHolesNum]=my7ShhHolesCRightmd;
    markers.models["my7ShhHolesCRightmd"+myHolesNum]=my7ShhHolesCRightmd;
    markers.models["my7ShhHolesMarkCRightmd"+myHolesNum]=my7ShhHolesMarkCRightmd;
    markers.models["my7ShhHolesYMarkCRightmd"+myHolesNum]=my7ShhHolesYMarkCRightmd;
    /*****************************************************************************/ 
    plate.models["my7ShhHolesCLeftmd"+myHolesNum]=my7ShhHolesCLeftmd;
    markers.models["my7ShhHolesCLeftmd"+myHolesNum]=my7ShhHolesCLeftmd;
    markers.models["my7ShhHolesMarkCLeftmd"+myHolesNum]=my7ShhHolesMarkCLeftmd;
    markers.models["my7ShhHolesXMarkCLeftmd"+myHolesNum]=my7ShhHolesXMarkCLeftmd;
    /*****************************************************************************/ 
      
    }else if(area==4){
    //画孔 D位置孔
          //标记7字拉手孔 D位置孔左上
          var my7ShhHolesDLeftup= new makerjs.models.Holes(d/2, [[xd, yd+h/2]]);
          var my7ShhHolesMarkDLeftup = new makerjs.models.Square(0); //孔的原点       
          my7ShhHolesMarkDLeftup.origin = [xd, yd+h/2];      
          var my7ShhHolesXMarkDLeftup = new makerjs.models.Square(0);      
          my7ShhHolesXMarkDLeftup.origin =[xd, yd+h/2];      
          var my7ShhHolesYMarkDLeftup = new makerjs.models.Square(0);      
          my7ShhHolesYMarkDLeftup.origin = [xd, yd+h/2];  
    /*****************************************************************************/ 
    makerjs.model.addPath(my7ShhHolesMarkDLeftup, new makerjs.paths.Line([-x0,yd-h/2], [-x0, yd-h/2+markShiftFour]));//Y轴加长标线
    makerjs.model.addPath(my7ShhHolesMarkDLeftup, new makerjs.paths.Line([0,0], [d+w+markShiftFour, 0]));//从孔圆心出发X方向横向标线
    makerjs.model.addPath(my7ShhHolesMarkDLeftup, new makerjs.paths.Line([0,0], [0, yd-h/2+markShiftFour]));//从孔圆心出发Y方向竖向标线
    /*****************************************************************************/ 
    makerjs.model.addPath(my7ShhHolesMarkDLeftup, new makerjs.paths.Line([0,0], [d, -d]));//直径标线
    makerjs.model.addCaption(my7ShhHolesMarkDLeftup, TxtD, [0,0], [d+markHolesOneD, -d-markHolesOneD]);//直径文字标注
    /*****************************************************************************/   
    makerjs.model.addPath(my7ShhHolesXMarkDLeftup, new makerjs.paths.Line([0,yd-h/2+markShift], [-x0, yd-h/2+markShift]));//X短标线
    makerjs.model.addCaption(my7ShhHolesXMarkDLeftup, TxtX, [-x0,yd-h/2+markShiftTwo], [0, yd-h/2+markShiftTwo]);//X文字标注
    /*****************************************************************************/   
    /*****************************************************************************/ 
     //标记7字拉手孔 D位置孔左中
     var my7ShhHolesDLeftmd= new makerjs.models.Holes(d/2, [[xd, yd-h/2]]);
          var my7ShhHolesMarkDLeftmd = new makerjs.models.Square(0); //孔的原点       
          my7ShhHolesMarkDLeftmd.origin = [xd, yd-h/2];       
          var my7ShhHolesYMarkDLeftmd = new makerjs.models.Square(0);      
          my7ShhHolesYMarkDLeftmd.origin = [xd, yd-h/2];  
    /*****************************************************************************/ 
    makerjs.model.addPath(my7ShhHolesMarkDLeftmd, new makerjs.paths.Line([0,0], [w, 0]));//从孔圆心出发X方向横向标线
    makerjs.model.addPath(my7ShhHolesMarkDLeftmd, new makerjs.paths.Line([0,0], [0, h]));//从孔圆心出发Y方向竖向标线
    /*****************************************************************************/ 
    makerjs.model.addPath(my7ShhHolesMarkDLeftmd, new makerjs.paths.Line([0,0], [d, -d]));//直径标线
    makerjs.model.addCaption(my7ShhHolesMarkDLeftmd, TxtD, [0,0], [d+markHolesOneD, -d-markHolesOneD]);//直径文字标注
    /*****************************************************************************/   
    makerjs.model.addPath(my7ShhHolesYMarkDLeftmd, new makerjs.paths.Line([w+d+markShiftTwo,0], [w+d+markShiftTwo, h]));//Y短标线
    makerjs.model.addCaption(my7ShhHolesYMarkDLeftmd, TxtH, [w+d+markShiftFour,0], [w+d+markShiftFour, h]);//Y文字标注
    /*****************************************************************************/ 
    /*****************************************************************************/
     //标记7字拉手孔D位置孔右中
     var my7ShhHolesDRightmd= new makerjs.models.Holes(d/2, [[xd+w, yd-h/2]]);
          var my7ShhHolesMarkDRightmd = new makerjs.models.Square(0); //孔的原点       
          my7ShhHolesMarkDRightmd.origin = [xd+w, yd-h/2];      
          var my7ShhHolesXMarkDRightmd = new makerjs.models.Square(0);      
          my7ShhHolesXMarkDRightmd.origin =[xd+w, yd-h/2];      
          var my7ShhHolesYMarkDRightmd = new makerjs.models.Square(0);      
          my7ShhHolesYMarkDRightmd.origin = [xd+w, yd-h/2];  
    /*****************************************************************************/ 
    makerjs.model.addPath(my7ShhHolesMarkDRightmd, new makerjs.paths.Line([0,0], [d+markShiftFour, 0]));//从孔圆心出发X方向横向标线
    makerjs.model.addPath(my7ShhHolesMarkDRightmd, new makerjs.paths.Line([0,0], [0, h+yd-h/2+markShiftFour]));//从孔圆心出发Y方向竖向标线
    /*****************************************************************************/ 
    makerjs.model.addPath(my7ShhHolesMarkDRightmd, new makerjs.paths.Line([0,0], [d, -d]));//直径标线
    makerjs.model.addCaption(my7ShhHolesMarkDRightmd, TxtD, [0,0], [d+markHolesOneD, -d-markHolesOneD]);//直径文字标注
    /*****************************************************************************/   
    makerjs.model.addPath(my7ShhHolesXMarkDRightmd, new makerjs.paths.Line([0,yd-h/2+h+markShift], [-w, yd-h/2+h+markShift]));//X短标线
    makerjs.model.addCaption(my7ShhHolesXMarkDRightmd, TxtW, [-w,yd-h/2+h+markShiftTwo], [0, yd-h/2+h+markShiftTwo]);//X文字标注
    /*****************************************************************************/  
    plate.models["my7ShhHolesDLeftup"+myHolesNum]=my7ShhHolesDLeftup;
    markers.models["my7ShhHolesDLeftup"+myHolesNum]=my7ShhHolesDLeftup;
    markers.models["my7ShhHolesMarkDLeftup"+myHolesNum]=my7ShhHolesMarkDLeftup;
    markers.models["my7ShhHolesXMarkDLeftup"+myHolesNum]=my7ShhHolesXMarkDLeftup;
    markers.models["my7ShhHolesYMarkDLeftup"+myHolesNum]=my7ShhHolesYMarkDLeftup;
    /*****************************************************************************/
    plate.models["my7ShhHolesDLeftmd"+myHolesNum]=my7ShhHolesDLeftmd;
    markers.models["my7ShhHolesDLeftmd"+myHolesNum]=my7ShhHolesDLeftmd;
    markers.models["my7ShhHolesMarkDLeftmd"+myHolesNum]=my7ShhHolesMarkDLeftmd;
    markers.models["my7ShhHolesYMarkDLeftmd"+myHolesNum]=my7ShhHolesYMarkDLeftmd;
    /*****************************************************************************/ 
    plate.models["my7ShhHolesDRightmd"+myHolesNum]=my7ShhHolesDRightmd;
    markers.models["my7ShhHolesDRightmd"+myHolesNum]=my7ShhHolesDRightmd;
    markers.models["my7ShhHolesMarkDRightmd"+myHolesNum]=my7ShhHolesMarkDRightmd;
    markers.models["my7ShhHolesXMarkDRightmd"+myHolesNum]=my7ShhHolesXMarkDRightmd;
    /*****************************************************************************/ 
    
    }else if(area==6){
    //画孔 F位置孔
          //标记7字拉手孔F位置孔右上
          var my7ShhHolesFRightup= new makerjs.models.Holes(d/2, [[xf, yf+h/2]]);
          var my7ShhHolesMarkFRightup = new makerjs.models.Square(0); //孔的原点       
          my7ShhHolesMarkFRightup.origin = [xf, yf+h/2];      
          var my7ShhHolesXMarkFRightup = new makerjs.models.Square(0);      
          my7ShhHolesXMarkFRightup.origin =[xf, yf+h/2];      
          var my7ShhHolesYMarkFRightup = new makerjs.models.Square(0);      
          my7ShhHolesYMarkFRightup.origin = [xf, yf+h/2];  
    /*****************************************************************************/ 
    makerjs.model.addPath(my7ShhHolesMarkFRightup, new makerjs.paths.Line([x0,y0], [x0, yd-h/2+markShiftFour]));//Y轴加长标线
    makerjs.model.addPath(my7ShhHolesMarkFRightup, new makerjs.paths.Line([0,0], [x0+markShiftThree, 0]));//从孔圆心出发X方向横向标线
    makerjs.model.addPath(my7ShhHolesMarkFRightup, new makerjs.paths.Line([0,0], [0, yd-h/2+markShiftFour]));//从孔圆心出发Y方向竖向标线
    /*****************************************************************************/ 
    makerjs.model.addPath(my7ShhHolesMarkFRightup, new makerjs.paths.Line([0,0], [d, -d]));//直径标线
    makerjs.model.addCaption(my7ShhHolesMarkFRightup, TxtD, [0,0], [d+markHolesOneD, -d-markHolesOneD]);//直径文字标注
    /*****************************************************************************/   
    makerjs.model.addPath(my7ShhHolesXMarkFRightup, new makerjs.paths.Line([0,yd-h/2+markShift], [x0, yd-h/2+markShift]));//X短标线
    makerjs.model.addCaption(my7ShhHolesXMarkFRightup, TxtX, [0, yd-h/2+markShiftThree], [x0,yd-h/2+markShiftThree]);//X文字标注
    /*****************************************************************************/   
    /*****************************************************************************/ 
    /*****************************************************************************/ 
     //标记7字拉手孔F位置孔右中
     var my7ShhHolesFRightmd= new makerjs.models.Holes(d/2, [[xf, yf-h/2]]);
          var my7ShhHolesMarkFRightmd = new makerjs.models.Square(0); //孔的原点       
          my7ShhHolesMarkFRightmd.origin = [xf, yf-h/2];       
          var my7ShhHolesYMarkFRightmd = new makerjs.models.Square(0);      
          my7ShhHolesYMarkFRightmd.origin = [xf, yf-h/2];  
    /*****************************************************************************/ 
    makerjs.model.addPath(my7ShhHolesMarkFRightmd, new makerjs.paths.Line([0,0], [x0+markShiftThree, 0]));//从孔圆心出发X方向横向标线
    makerjs.model.addPath(my7ShhHolesMarkFRightmd, new makerjs.paths.Line([0,0], [0, h+markShiftFour]));//从孔圆心出发Y方向竖向标线
    /*****************************************************************************/ 
    makerjs.model.addPath(my7ShhHolesMarkFRightmd, new makerjs.paths.Line([0,0], [d, -d]));//直径标线
    makerjs.model.addCaption(my7ShhHolesMarkFRightmd, TxtD, [0,0], [d+markHolesOneD, -d-markHolesOneD]);//直径文字标注
    /*****************************************************************************/   
    makerjs.model.addPath(my7ShhHolesYMarkFRightmd, new makerjs.paths.Line([x0+markShift,0], [x0+markShift, h]));//Y短标线
    makerjs.model.addCaption(my7ShhHolesYMarkFRightmd, TxtH, [x0+markShiftThree,0], [x0+markShiftThree, h]);//Y文字标注
    /*****************************************************************************/ 
    /*****************************************************************************/
     //标记7字拉手孔F位置孔左中
     var my7ShhHolesFLeftmd= new makerjs.models.Holes(d/2, [[xf-w, yf-h/2]]);
          var my7ShhHolesMarkFLeftmd = new makerjs.models.Square(0); //孔的原点       
          my7ShhHolesMarkFLeftmd.origin = [xf-w, yf-h/2];      
          var my7ShhHolesXMarkFLeftmd = new makerjs.models.Square(0);      
          my7ShhHolesXMarkFLeftmd.origin =[xf-w, yf-h/2];      
          var my7ShhHolesYMarkFLeftmd = new makerjs.models.Square(0);      
          my7ShhHolesYMarkFLeftmd.origin = [xf-w, yf-h/2];  
    /*****************************************************************************/ 
    makerjs.model.addPath(my7ShhHolesMarkFLeftmd, new makerjs.paths.Line([0,0], [w, 0]));//从孔圆心出发X方向横向标线
    makerjs.model.addPath(my7ShhHolesMarkFLeftmd, new makerjs.paths.Line([0,0], [0, h+yd-h/2+markShiftFour]));//从孔圆心出发Y方向竖向标线
    /*****************************************************************************/ 
    makerjs.model.addPath(my7ShhHolesMarkFLeftmd, new makerjs.paths.Line([0,0], [d, -d]));//直径标线
    makerjs.model.addCaption(my7ShhHolesMarkFLeftmd, TxtD, [0,0], [d+markHolesOneD, -d-markHolesOneD]);//直径文字标注
    /*****************************************************************************/   
    makerjs.model.addPath(my7ShhHolesXMarkFLeftmd, new makerjs.paths.Line([0,yd-h/2+h+markShift], [w, yd-h/2+h+markShift]));//X短标线
    makerjs.model.addCaption(my7ShhHolesXMarkFLeftmd, TxtW, [0, yd-h/2+h+markShiftThree], [w,yd-h/2+h+markShiftThree]);//X文字标注
    /*****************************************************************************/  
    plate.models["my7ShhHolesFRightup"+myHolesNum]=my7ShhHolesFRightup;
    markers.models["my7ShhHolesFRightup"+myHolesNum]=my7ShhHolesFRightup;
    markers.models["my7ShhHolesMarkFRightup"+myHolesNum]=my7ShhHolesMarkFRightup;
    markers.models["my7ShhHolesXMarkFRightup"+myHolesNum]=my7ShhHolesXMarkFRightup;
    markers.models["my7ShhHolesYMarkFRightup"+myHolesNum]=my7ShhHolesYMarkFRightup;
    /*****************************************************************************/
    plate.models["my7ShhHolesFRightmd"+myHolesNum]=my7ShhHolesFRightmd;
    markers.models["my7ShhHolesFRightmd"+myHolesNum]=my7ShhHolesFRightmd;
    markers.models["my7ShhHolesMarkFRightmd"+myHolesNum]=my7ShhHolesMarkFRightmd;
    markers.models["my7ShhHolesYMarkFRightmd"+myHolesNum]=my7ShhHolesYMarkFRightmd;
    /*****************************************************************************/ 
    plate.models["my7ShhHolesFLeftmd"+myHolesNum]=my7ShhHolesFLeftmd;
    markers.models["my7ShhHolesFLeftmd"+myHolesNum]=my7ShhHolesFLeftmd;
    markers.models["my7ShhHolesMarkFLeftmd"+myHolesNum]=my7ShhHolesMarkFLeftmd;
    markers.models["my7ShhHolesXMarkFLeftmd"+myHolesNum]=my7ShhHolesXMarkFLeftmd;
    /*****************************************************************************/ 
     
    }else if(area==7){
    //画孔 G位置孔
          //标记7字拉手孔 G位置孔左上
          var my7ShhHolesGLeftup= new makerjs.models.Holes(d/2, [[xg, yg+h]]);
          var my7ShhHolesMarkGLeftup = new makerjs.models.Square(0); //孔的原点       
          my7ShhHolesMarkGLeftup.origin = [xg, yg+h];     
          var my7ShhHolesYMarkGLeftup = new makerjs.models.Square(0);      
          my7ShhHolesYMarkGLeftup.origin = [xg, yg+h];  
    /*****************************************************************************/ 
    makerjs.model.addPath(my7ShhHolesMarkGLeftup, new makerjs.paths.Line([-x0,-y0-h], [-x0, -y0-h-markShiftFour]));//Y轴加长标线
    makerjs.model.addPath(my7ShhHolesMarkGLeftup, new makerjs.paths.Line([0,0], [w+d+markShiftFour, 0]));//从孔圆心出发X方向横向标线
    makerjs.model.addPath(my7ShhHolesMarkGLeftup, new makerjs.paths.Line([-x0,-y0-h], [-x0-markShiftThree, -y0-h]));//X轴加长标线
    makerjs.model.addPath(my7ShhHolesMarkGLeftup, new makerjs.paths.Line([0,0], [0, -y0-h-markShiftFour]));//从孔圆心出发Y方向竖向标线
    /*****************************************************************************/ 
    makerjs.model.addPath(my7ShhHolesMarkGLeftup, new makerjs.paths.Line([0,0], [d, -d]));//直径标线
    makerjs.model.addCaption(my7ShhHolesMarkGLeftup, TxtD, [0,0], [d+markHolesOneD, -d-markHolesOneD]);//直径文字标注
    /*****************************************************************************/ 
    makerjs.model.addPath(my7ShhHolesYMarkGLeftup, new makerjs.paths.Line([w+d+markShiftTwo,0], [w+d+markShiftTwo, -h]));//Y短标线
    makerjs.model.addCaption(my7ShhHolesYMarkGLeftup, TxtH, [w+d+markShiftFour, -h], [w+d+markShiftFour,0]);//Y文字标注
    // /*****************************************************************************/   
    /*****************************************************************************/ 
    //  //标记7字拉手孔 G位置孔左中
     var my7ShhHolesGLeftmd= new makerjs.models.Holes(d/2, [[xg, yg]]);
          var my7ShhHolesMarkGLeftmd = new makerjs.models.Square(0); //孔的原点       
          my7ShhHolesMarkGLeftmd.origin = [xg, yg];       
          var my7ShhHolesXMarkGLeftmd = new makerjs.models.Square(0);      
          my7ShhHolesXMarkGLeftmd.origin = [xg, yg];       
          var my7ShhHolesYMarkGLeftmd = new makerjs.models.Square(0);      
          my7ShhHolesYMarkGLeftmd.origin = [xg, yg];  
    /*****************************************************************************/ 
    makerjs.model.addPath(my7ShhHolesMarkGLeftmd, new makerjs.paths.Line([0,0], [-x0, 0]));//从孔圆心出发X方向横向标线
    makerjs.model.addPath(my7ShhHolesMarkGLeftmd, new makerjs.paths.Line([0,0], [w+d+markShiftFour, 0]));//从孔圆心出发X方向横向标线
    makerjs.model.addPath(my7ShhHolesMarkGLeftmd, new makerjs.paths.Line([0,0], [0, -y0-markShiftFour]));//从孔圆心出发Y方向竖向标线
    /*****************************************************************************/ 
    makerjs.model.addPath(my7ShhHolesMarkGLeftmd, new makerjs.paths.Line([0,0], [d, -d]));//直径标线
    makerjs.model.addCaption(my7ShhHolesMarkGLeftmd, TxtD, [0,0], [d+markHolesOneD, -d-markHolesOneD]);//直径文字标注
    /*****************************************************************************/ 
    makerjs.model.addPath(my7ShhHolesXMarkGLeftmd, new makerjs.paths.Line([0,-y0-markShiftTwo], [-x0, -y0-markShiftTwo]));//X短标线
    makerjs.model.addCaption(my7ShhHolesXMarkGLeftmd, TxtX, [-x0,-y0-markShiftFour], [0, -y0-markShiftFour]);//X文字标注
    /*****************************************************************************/     
    makerjs.model.addPath(my7ShhHolesYMarkGLeftmd, new makerjs.paths.Line([w+d+markShiftTwo,0], [w+d+markShiftTwo, -y0]));//Y短标线
    makerjs.model.addCaption(my7ShhHolesYMarkGLeftmd, TxtY, [w+d+markShiftFour, -y0], [w+d+markShiftFour,0]);//Y文字标注
    // /*****************************************************************************/ 
    // /*****************************************************************************/
     //标记7字拉手孔 G位置孔右中
     var my7ShhHolesGRightmd= new makerjs.models.Holes(d/2, [[xg+w, yg]]);
          var my7ShhHolesMarkGRightmd = new makerjs.models.Square(0); //孔的原点       
          my7ShhHolesMarkGRightmd.origin = [xg+w, yg];      
          var my7ShhHolesXMarkGRightmd = new makerjs.models.Square(0);      
          my7ShhHolesXMarkGRightmd.origin =[xg+w, yg];      
          var my7ShhHolesYMarkGRightmd = new makerjs.models.Square(0);      
          my7ShhHolesYMarkGRightmd.origin = [xg+w, yg];  
    /*****************************************************************************/ 
    makerjs.model.addPath(my7ShhHolesMarkGRightmd, new makerjs.paths.Line([0,0], [-w, 0]));//从孔圆心出发X方向横向标线
    makerjs.model.addPath(my7ShhHolesMarkGRightmd, new makerjs.paths.Line([0,0], [0, -y0-markShiftFour]));//从孔圆心出发Y方向竖向标线
    /*****************************************************************************/ 
    makerjs.model.addPath(my7ShhHolesMarkGRightmd, new makerjs.paths.Line([0,0], [d, -d]));//直径标线
    makerjs.model.addCaption(my7ShhHolesMarkGRightmd, TxtD, [0,0], [d+markHolesOneD, -d-markHolesOneD]);//直径文字标注
    /*****************************************************************************/   
    makerjs.model.addPath(my7ShhHolesXMarkGRightmd, new makerjs.paths.Line([0,-y0-markShiftTwo], [-w, -y0-markShiftTwo]));//X短标线
    makerjs.model.addCaption(my7ShhHolesXMarkGRightmd, TxtW, [-w,-y0-markShiftFour], [0, -y0-markShiftFour]);//X文字标注
    /*****************************************************************************/  
    plate.models["my7ShhHolesGLeftup"+myHolesNum]=my7ShhHolesGLeftup;
    markers.models["my7ShhHolesGLeftup"+myHolesNum]=my7ShhHolesGLeftup;
    markers.models["my7ShhHolesMarkGLeftup"+myHolesNum]=my7ShhHolesMarkGLeftup;
    markers.models["my7ShhHolesYMarkGLeftup"+myHolesNum]=my7ShhHolesYMarkGLeftup;
    /*****************************************************************************/
    plate.models["my7ShhHolesGLeftmd"+myHolesNum]=my7ShhHolesGLeftmd;
    markers.models["my7ShhHolesGLeftmd"+myHolesNum]=my7ShhHolesGLeftmd;
    markers.models["my7ShhHolesMarkGLeftmd"+myHolesNum]=my7ShhHolesMarkGLeftmd;
    markers.models["my7ShhHolesXMarkGLeftmd"+myHolesNum]=my7ShhHolesXMarkGLeftmd;
    markers.models["my7ShhHolesYMarkGLeftmd"+myHolesNum]=my7ShhHolesYMarkGLeftmd;
    // /*****************************************************************************/ 
    plate.models["my7ShhHolesGRightmd"+myHolesNum]=my7ShhHolesGRightmd;
    markers.models["my7ShhHolesGRightmd"+myHolesNum]=my7ShhHolesGRightmd;
    markers.models["my7ShhHolesMarkGRightmd"+myHolesNum]=my7ShhHolesMarkGRightmd;
    markers.models["my7ShhHolesXMarkGRightmd"+myHolesNum]=my7ShhHolesXMarkGRightmd;
    /*****************************************************************************/ 
     
    }else if(area==9){
    //画孔 I位置孔
          //标记7字拉手孔 I位置孔右上
          var my7ShhHolesIRightup= new makerjs.models.Holes(d/2, [[xi, yi+h]]);
          var my7ShhHolesMarkIRightup = new makerjs.models.Square(0); //孔的原点       
          my7ShhHolesMarkIRightup.origin = [xi, yi+h];     
          var my7ShhHolesYMarkIRightup = new makerjs.models.Square(0);      
          my7ShhHolesYMarkIRightup.origin = [xi, yi+h];  
    /*****************************************************************************/ 
    makerjs.model.addPath(my7ShhHolesMarkIRightup, new makerjs.paths.Line([x0,-y0-h], [x0, -y0-h-markShiftFour]));//Y轴加长标线
    makerjs.model.addPath(my7ShhHolesMarkIRightup, new makerjs.paths.Line([0,0], [-w-d-markShiftFour, 0]));//从孔圆心出发X方向横向标线
    makerjs.model.addPath(my7ShhHolesMarkIRightup, new makerjs.paths.Line([x0,-y0-h], [x0-markShiftThree, -y0-h]));//X轴加长标线
    makerjs.model.addPath(my7ShhHolesMarkIRightup, new makerjs.paths.Line([0,0], [0, -y0-h-markShiftFour]));//从孔圆心出发Y方向竖向标线
    /*****************************************************************************/ 
    makerjs.model.addPath(my7ShhHolesMarkIRightup, new makerjs.paths.Line([0,0], [d, -d]));//直径标线
    makerjs.model.addCaption(my7ShhHolesMarkIRightup, TxtD, [0,0], [d+markHolesOneD, -d-markHolesOneD]);//直径文字标注
    /*****************************************************************************/ 
    makerjs.model.addPath(my7ShhHolesYMarkIRightup, new makerjs.paths.Line([-w-d-markShiftTwo,0], [-w-d-markShiftTwo, -h]));//Y短标线
    makerjs.model.addCaption(my7ShhHolesYMarkIRightup, TxtH, [-w-d-markShiftFour, -h], [-w-d-markShiftFour,0]);//Y文字标注
    // /*****************************************************************************/   
    /*****************************************************************************/ 
    //  //标记7字拉手孔 I位置孔右下
     var my7ShhHolesIRightdn= new makerjs.models.Holes(d/2, [[xi, yi]]);
          var my7ShhHolesMarkIRightdn = new makerjs.models.Square(0); //孔的原点       
          my7ShhHolesMarkIRightdn.origin = [xi, yi];       
          var my7ShhHolesXMarkIRightdn = new makerjs.models.Square(0);      
          my7ShhHolesXMarkIRightdn.origin = [xi, yi];       
          var my7ShhHolesYMarkIRightdn = new makerjs.models.Square(0);      
          my7ShhHolesYMarkIRightdn.origin = [xi, yi];  
    /*****************************************************************************/ 
    makerjs.model.addPath(my7ShhHolesMarkIRightdn, new makerjs.paths.Line([0,0], [x0, 0]));//从孔圆心出发X方向横向标线
    makerjs.model.addPath(my7ShhHolesMarkIRightdn, new makerjs.paths.Line([0,0], [-w-d-markShiftFour, 0]));//从孔圆心出发X方向横向标线
    makerjs.model.addPath(my7ShhHolesMarkIRightdn, new makerjs.paths.Line([0,0], [0, -y0-markShiftFour]));//从孔圆心出发Y方向竖向标线
    /*****************************************************************************/ 
    makerjs.model.addPath(my7ShhHolesMarkIRightdn, new makerjs.paths.Line([0,0], [d, -d]));//直径标线
    makerjs.model.addCaption(my7ShhHolesMarkIRightdn, TxtD, [0,0], [d+markHolesOneD, -d-markHolesOneD]);//直径文字标注
    /*****************************************************************************/ 
    makerjs.model.addPath(my7ShhHolesXMarkIRightdn, new makerjs.paths.Line([0,-y0-markShiftTwo], [x0, -y0-markShiftTwo]));//X短标线
    makerjs.model.addCaption(my7ShhHolesXMarkIRightdn, TxtX, [0, -y0-markShiftFour], [x0,-y0-markShiftFour]);//X文字标注
    /*****************************************************************************/     
    makerjs.model.addPath(my7ShhHolesYMarkIRightdn, new makerjs.paths.Line([-w-d-markShiftTwo,0], [-w-d-markShiftTwo, -y0]));//Y短标线
    makerjs.model.addCaption(my7ShhHolesYMarkIRightdn, TxtY, [-w-d-markShiftFour, -y0], [-w-d-markShiftFour,0]);//Y文字标注
    // /*****************************************************************************/ 
    // /*****************************************************************************/
     //标记7字拉手孔 I位置孔左下
     var my7ShhHolesILeftdn= new makerjs.models.Holes(d/2, [[xi-w, yi]]);
          var my7ShhHolesMarkILeftdn = new makerjs.models.Square(0); //孔的原点       
          my7ShhHolesMarkILeftdn.origin = [xi-w, yi];      
          var my7ShhHolesXMarkILeftdn = new makerjs.models.Square(0);      
          my7ShhHolesXMarkILeftdn.origin =[xi-w, yi];  
    /*****************************************************************************/ 
    makerjs.model.addPath(my7ShhHolesMarkILeftdn, new makerjs.paths.Line([0,0], [w, 0]));//从孔圆心出发X方向横向标线
    makerjs.model.addPath(my7ShhHolesMarkILeftdn, new makerjs.paths.Line([0,0], [0, -y0-markShiftFour]));//从孔圆心出发Y方向竖向标线
    /*****************************************************************************/ 
    makerjs.model.addPath(my7ShhHolesMarkILeftdn, new makerjs.paths.Line([0,0], [d, -d]));//直径标线
    makerjs.model.addCaption(my7ShhHolesMarkILeftdn, TxtD, [0,0], [d+markHolesOneD, -d-markHolesOneD]);//直径文字标注
    /*****************************************************************************/   
    makerjs.model.addPath(my7ShhHolesXMarkILeftdn, new makerjs.paths.Line([0,-y0-markShiftTwo], [w, -y0-markShiftTwo]));//X短标线
    makerjs.model.addCaption(my7ShhHolesXMarkILeftdn, TxtW, [0, -y0-markShiftFour], [w,-y0-markShiftFour]);//X文字标注
    /*****************************************************************************/  
    plate.models["my7ShhHolesIRightup"+myHolesNum]=my7ShhHolesIRightup;
    markers.models["my7ShhHolesIRightup"+myHolesNum]=my7ShhHolesIRightup;
    markers.models["my7ShhHolesMarkIRightup"+myHolesNum]=my7ShhHolesMarkIRightup;
    markers.models["my7ShhHolesYMarkIRightup"+myHolesNum]=my7ShhHolesYMarkIRightup;
    /*****************************************************************************/
    plate.models["my7ShhHolesIRightdn"+myHolesNum]=my7ShhHolesIRightdn;
    markers.models["my7ShhHolesIRightdn"+myHolesNum]=my7ShhHolesIRightdn;
    markers.models["my7ShhHolesMarkIRightdn"+myHolesNum]=my7ShhHolesMarkIRightdn;
    markers.models["my7ShhHolesXMarkIRightdn"+myHolesNum]=my7ShhHolesXMarkIRightdn;
    markers.models["my7ShhHolesYMarkIRightdn"+myHolesNum]=my7ShhHolesYMarkIRightdn;
    // /*****************************************************************************/ 
    plate.models["my7ShhHolesILeftdn"+myHolesNum]=my7ShhHolesILeftdn;
    markers.models["my7ShhHolesILeftdn"+myHolesNum]=my7ShhHolesILeftdn;
    markers.models["my7ShhHolesMarkILeftdn"+myHolesNum]=my7ShhHolesMarkILeftdn;
    markers.models["my7ShhHolesXMarkILeftdn"+myHolesNum]=my7ShhHolesXMarkILeftdn;
    /*****************************************************************************/ 
    
    }

myHolesNum++;
return{plate, markers,myHolesNum}
};


export default Shaped7HandleHole;