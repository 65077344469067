//缺2、U型
import makerjs from "makerjs";
// inputParam,model,myHolesNum,outParam,fixedParam
// (D,X,Y,area,plate,markers,myHolesNum,glassW,glassH,markShift,markShiftTwo,markShiftThree,markShiftFour,markHolesOneD)
function UShapeGap(inputParam,model,myHolesNum,outParam,fixedParam,zoom){
var D=inputParam.D,W=inputParam.W,X=inputParam.X,Y=inputParam.Y,area=inputParam.area,myZoom=zoom*1,
plate=model.plate,markers=model.markers,
glassW=outParam.glassW,glassH=outParam.glassH,
markShift=fixedParam.markShift,markShiftF=fixedParam.markShiftF,markShiftTwo=fixedParam.markShiftTwo,
   markShiftThree=fixedParam.markShiftThree,markShiftFour=fixedParam.markShiftFour,markHolesOneD=fixedParam.markHolesOneD,
 
   d = D*1*myZoom,
   w= W*1*myZoom-d/2,
     x0 = X*1*myZoom,
     y0 = Y*1*myZoom;
     if(d!==0){
      var TxtD=TxtD="∮"+D;
     }
     if(w!==0){
      var TxtW=W;
     }
     if(y0!==0){          
      var TxtY=Y;
     }
  //在A区域 计算孔的位置       
  var xa=x0,ya=glassH * 1-y0,
  //B区域//只有Y标注没有X标注 
  xb=glassW * 1/2,yb=glassH * 1-y0,
  //C区域
  xc=glassW * 1-x0,yc=glassH * 1-y0,
  //D区域
  //只有X标注没有Y标注 
  xd=x0,yd=glassH * 1/2,
  //E区域
  //只有X标注没有Y标注 
  xe=glassW * 1/2,ye=glassH * 1/2,
  //F区域
  //只有X标注没有Y标注 
  xf=glassW * 1-x0,yf=glassH * 1/2,
  //在G区域 计算孔的位置
  xg=x0,yg=y0,
  //在H区域 计算孔的位置
  //只标注Y
  xh=glassW*1/2,yh=y0,
  //在I区域 计算孔的位置
  xi=glassW*1-x0,yi=y0;
  if(area==1){
    //画缺 A位置
        //画U型缺 A位置
   var myUsgGapA = new makerjs.models.RoundRectangle(w+d, d, d)
//   delete myUsgGapA.paths.TopLeft;
//   delete myUsgGapA.paths.BottomLeft;
  myUsgGapA.origin=[xa-d/2,ya-d];
  ////标记U型缺 A位置
  var myUsgGapAMaker = new makerjs.models.Square(0); //孔的原点       
  myUsgGapAMaker.origin = [xa-d/2,ya-d];
  var myUsgGapAMakerY = new makerjs.models.Square(0); //孔的原点       
  myUsgGapAMakerY.origin = [xa-d/2,ya-d];
  var myUsgGapAMakerW = new makerjs.models.Square(0); //孔的原点       
  myUsgGapAMakerW.origin = [xa-d/2,ya-d]; 
  /*****************************************************************************/
  /*****************************************************************************/ 
  //makerjs.model.addPath(myUsgGapAMaker, new makerjs.paths.Line([0+d/2,y0+d], [0+d/2, y0+d+markShiftTwo]));//Y轴加长标线
  makerjs.model.addPath(myUsgGapAMaker, new makerjs.paths.Line([0+d+w,d], [0+d+w, d+markShiftTwo]));//Y轴方向短线
  makerjs.model.addPath(myUsgGapAMaker, new makerjs.paths.Line([0+d/2,y0+d], [d/2-markShiftFour, y0+d]));//X轴加长标线
  makerjs.model.addPath(myUsgGapAMaker, new makerjs.paths.Line([0+d/2,0+d], [d/2-markShiftFour, 0+d]));//X轴方向短线
  //makerjs.model.addPath(myUsgGapAMaker, new makerjs.paths.Line([0+d/2,0], [d/2-markShiftFour, 0]));//X轴方向短线
  /*****************************************************************************/  
  makerjs.model.addPath(myUsgGapAMaker, new makerjs.paths.Line([0+markShiftTwo+d/2,0], [0+markShiftTwo+d/2, 0+d]));//D轴方向标注短线 
  makerjs.model.addCaption(myUsgGapAMaker,TxtD, [0+markShiftFour+d/2,0], [0+markShiftFour+d/2, 0+d]);//D文字标注  
  /*****************************************************************************/
  makerjs.model.addPath(myUsgGapAMakerY, new makerjs.paths.Line([0-markShiftTwo+d/2,d], [0-markShiftTwo+d/2, 0+d+y0]));//Y轴方向标注短线 
  makerjs.model.addCaption(myUsgGapAMakerY,TxtY, [0-markShiftThree+d/2,d], [0-markShiftThree+d/2, 0+d+y0]);//Y文字标注
  /*****************************************************************************/
  makerjs.model.addPath(myUsgGapAMakerW, new makerjs.paths.Line([0+d/2,markShift/2+d], [0+d+w, markShift/2+d]));//W短线标注
  makerjs.model.addCaption(myUsgGapAMakerW, TxtW, [0+d/2,markShift+d], [0+d/2+w, markShift+d]);//W文字标注
  /*****************************************************************************/ 
  plate.models["myUsgGapA"+myHolesNum]=myUsgGapA;
  markers.models["myUsgGapA"+myHolesNum]=myUsgGapA;
  markers.models["myUsgGapAMaker"+myHolesNum]=myUsgGapAMaker;
  markers.models["myUsgGapAMakerY"+myHolesNum]=myUsgGapAMakerY;
  markers.models["myUsgGapAMakerW"+myHolesNum]=myUsgGapAMakerW;
  /*****************************************************************************/
  makerjs.model.combineSubtraction(markers.models.myRect, markers.models["myUsgGapA"+myHolesNum])
  console.log("markers==",markers);
  /*****************************************************************************/
  /*****************************************************************************/ 
     
        }else if(area==3){        
        //画U型缺 C位置
   var myUsgGapC = new makerjs.models.RoundRectangle(w+d, d, d)
//   delete myUsgGapC.paths.TopRight;
//   delete myUsgGapC.paths.BottomRight;
  myUsgGapC.origin=[xc-w-d/2,yc-d];
  ////标记U型缺 C位置
  var myUsgGapCMaker = new makerjs.models.Square(0); //孔的原点       
  myUsgGapCMaker.origin = [xc-w-d/2,yc-d];
  var myUsgGapCMakerY = new makerjs.models.Square(0); //孔的原点       
  myUsgGapCMakerY.origin = [xc-w-d/2,yc-d];
  var myUsgGapCMakerW = new makerjs.models.Square(0); //孔的原点       
  myUsgGapCMakerW.origin = [xc-w-d/2,yc-d];  
  /*****************************************************************************/
  /*****************************************************************************/  
  //makerjs.model.addPath(myUsgGapCMaker, new makerjs.paths.Line([0+w+d/2,y0+d], [0+w+d/2, y0+d+markShiftTwo]));//Y轴加长标线
  makerjs.model.addPath(myUsgGapCMaker, new makerjs.paths.Line([0,d], [0, d+markShiftTwo]));//Y轴方向短线
  makerjs.model.addPath(myUsgGapCMaker, new makerjs.paths.Line([0+d/2+w,y0+d], [0+d/2+w+markShiftThree, y0+d]));//X轴加长标线
  makerjs.model.addPath(myUsgGapCMaker, new makerjs.paths.Line([0+d/2+w,0+d], [0+d/2+w+markShiftThree, 0+d]));//X轴方向短线
  makerjs.model.addPath(myUsgGapCMaker, new makerjs.paths.Line([0+d/2+w,0], [0+d/2+w+markShiftThree, 0]));//X轴方向短线
  
  makerjs.model.addPath(myUsgGapCMaker, new makerjs.paths.Line([0-markShift+d+w,0], [0-markShift+d+w, 0+d]));//Y轴方向标注短线 
  makerjs.model.addCaption(myUsgGapCMaker,TxtD, [0+d+w,0], [0+d+w, 0+d]);//Y文字标注
  /*****************************************************************************/
  makerjs.model.addPath(myUsgGapCMakerY, new makerjs.paths.Line([0+d/2+markShift+w,d], [0+d/2+markShift+w, 0+d+y0]));//Y轴方向标注短线 
  makerjs.model.addCaption(myUsgGapCMakerY,TxtY, [0+d+markShift+w,d], [0+d+markShift+w, 0+d+y0]);//Y文字标注
  
  /*****************************************************************************/
  makerjs.model.addPath(myUsgGapCMakerW, new makerjs.paths.Line([0,markShift/2+d], [0+d/2+w, markShift/2+d]));//W短线标注
  makerjs.model.addCaption(myUsgGapCMakerW, TxtW, [0,markShift+d], [0+d/2+w, markShift+d]);//W文字标注
  /*****************************************************************************/
  plate.models["myUsgGapC"+myHolesNum]=myUsgGapC;
  markers.models["myUsgGapC"+myHolesNum]=myUsgGapC;
  markers.models["myUsgGapCMaker"+myHolesNum]=myUsgGapCMaker;
  markers.models["myUsgGapCMakerY"+myHolesNum]=myUsgGapCMakerY;
  markers.models["myUsgGapCMakerW"+myHolesNum]=myUsgGapCMakerW;
  /*****************************************************************************/
  makerjs.model.combineSubtraction(markers.models.myRect, markers.models["myUsgGapC"+myHolesNum])
  /*****************************************************************************/ 
      
        }else if(area==4){
//画缺 D位置
        //画U型缺 D位置
        var myUsgGapD = new makerjs.models.RoundRectangle(w+d, d, d)
      //   delete myUsgGapD.paths.TopLeft;
      //   delete myUsgGapD.paths.BottomLeft;
        myUsgGapD.origin=[xd-d/2,yd-d/2];
        ////标记U型缺 D位置
        var myUsgGapDMaker = new makerjs.models.Square(0); //孔的原点       
        myUsgGapDMaker.origin = [xd-d/2,yd-d/2];
        var myUsgGapDMakerY = new makerjs.models.Square(0); //孔的原点       
        myUsgGapDMakerY.origin = [xd-d/2,yd-d/2];
        var myUsgGapDMakerW = new makerjs.models.Square(0); //孔的原点       
        myUsgGapDMakerW.origin = [xd-d/2,yd-d/2]; 
        /*****************************************************************************/
        /*****************************************************************************/ 
      //   makerjs.model.addPath(myUsgGapDMaker, new makerjs.paths.Line([0+d/2,y0+d], [0+d/2, y0+d+markShiftTwo]));//Y轴加长标线
        makerjs.model.addPath(myUsgGapDMaker, new makerjs.paths.Line([0+d+w,d], [0+d+w, y0+d+markShiftTwo]));//Y轴方向短线
      //   makerjs.model.addPath(myUsgGapDMaker, new makerjs.paths.Line([0+d/2,y0+d], [d/2-markShiftFour, y0+d]));//X轴加长标线
      //   makerjs.model.addPath(myUsgGapDMaker, new makerjs.paths.Line([0+d/2,0+d], [d/2-markShiftFour, 0+d]));//X轴方向短线
      //   makerjs.model.addPath(myUsgGapDMaker, new makerjs.paths.Line([0+d/2,0], [d/2-markShiftFour, 0]));//X轴方向短线
        
        makerjs.model.addPath(myUsgGapDMaker, new makerjs.paths.Line([0+markShiftTwo+d/2,0], [0+markShiftTwo+d/2, 0+d]));//D轴方向标注短线 
        makerjs.model.addCaption(myUsgGapDMaker,TxtD, [0+markShiftFour+d/2,0], [0+markShiftFour+d/2, 0+d]);//D文字标注
        
        /*****************************************************************************/
        makerjs.model.addPath(myUsgGapDMakerY, new makerjs.paths.Line([0-markShiftTwo+d/2,d], [0-markShiftTwo+d/2, 0+d+y0]));//Y轴方向标注短线 
        makerjs.model.addCaption(myUsgGapDMakerY,TxtY, [0-markShiftThree+d/2,d], [0-markShiftThree+d/2, 0+d+y0]);//Y文字标注
        /*****************************************************************************/
        makerjs.model.addPath(myUsgGapDMakerW, new makerjs.paths.Line([0+d/2,y0+markShift/2+d], [0+d+w, y0+markShift/2+d]));//W短线标注
        makerjs.model.addCaption(myUsgGapDMakerW, TxtW, [0+d/2,y0+markShift+d], [0+d+w, y0+markShift+d]);//W文字标注
        /*****************************************************************************/ 
        plate.models["myUsgGapD"+myHolesNum]=myUsgGapD;
        markers.models["myUsgGapD"+myHolesNum]=myUsgGapD;
        markers.models["myUsgGapDMaker"+myHolesNum]=myUsgGapDMaker;
        markers.models["myUsgGapDMakerY"+myHolesNum]=myUsgGapDMakerY;
        markers.models["myUsgGapDMakerW"+myHolesNum]=myUsgGapDMakerW;
        /*****************************************************************************/        
  makerjs.model.combineSubtraction(markers.models.myRect, markers.models["myUsgGapD"+myHolesNum])
        console.log("markers==",markers);
        /*****************************************************************************/
        /*****************************************************************************/ 
        }else if(area==6){
//画U型缺 F位置
var myUsgGapF = new makerjs.models.RoundRectangle(w+d, d, d)
// delete myUsgGapF.paths.TopRight;
// delete myUsgGapF.paths.BottomRight;
myUsgGapF.origin=[xf-w-d/2,yf-d/2];
////标记U型缺 F位置
var myUsgGapFMaker = new makerjs.models.Square(0); //孔的原点       
myUsgGapFMaker.origin = [xf-w-d/2,yf-d/2];
var myUsgGapFMakerY = new makerjs.models.Square(0); //孔的原点       
myUsgGapFMakerY.origin = [xf-w-d/2,yf-d/2];
var myUsgGapFMakerW = new makerjs.models.Square(0); //孔的原点       
myUsgGapFMakerW.origin = [xf-w-d/2,yf-d/2];  
/*****************************************************************************/
/*****************************************************************************/  
// makerjs.model.addPath(myUsgGapFMaker, new makerjs.paths.Line([0+w+d/2,y0+d], [0+w+d/2, y0+d+markShiftTwo]));//Y轴加长标线
makerjs.model.addPath(myUsgGapFMaker, new makerjs.paths.Line([0,d], [0, y0+d+markShiftTwo]));//Y轴方向短线
makerjs.model.addPath(myUsgGapFMaker, new makerjs.paths.Line([0+d/2+w,y0+d], [0+d/2+w+markShiftThree, y0+d]));//X轴加长标线
makerjs.model.addPath(myUsgGapFMaker, new makerjs.paths.Line([0+d/2+w,0+d], [0+d/2+w+markShiftThree, 0+d]));//X轴方向短线
makerjs.model.addPath(myUsgGapFMaker, new makerjs.paths.Line([0+d/2+w,0], [0+d/2+w+markShiftThree, 0]));//X轴方向短线

makerjs.model.addPath(myUsgGapFMaker, new makerjs.paths.Line([0+markShift+d/2+w,0], [0+markShift+d/2+w, 0+d]));//Y轴方向标注短线 
makerjs.model.addCaption(myUsgGapFMaker,TxtD, [0+markShiftThree+d/2+w,0], [0+markShiftThree+d/2+w, 0+d]);//Y文字标注
/*****************************************************************************/
makerjs.model.addPath(myUsgGapFMakerY, new makerjs.paths.Line([0-markShiftTwo+d/2+w,d], [0-markShiftTwo+d/2+w, 0+d+y0]));//Y轴方向标注短线 
makerjs.model.addCaption(myUsgGapFMakerY,TxtY, [0+d/2+w,d], [0+d/2+w, 0+d+y0]);//Y文字标注

/*****************************************************************************/
makerjs.model.addPath(myUsgGapFMakerW, new makerjs.paths.Line([0,y0+markShift/2+d], [0+w+d/2, y0+markShift/2+d]));//W短线标注
makerjs.model.addCaption(myUsgGapFMakerW, TxtW, [0,y0+markShift+d], [0+w+d/2, y0+markShift+d]);//W文字标注
/*****************************************************************************/
plate.models["myUsgGapF"+myHolesNum]=myUsgGapF;
markers.models["myUsgGapF"+myHolesNum]=myUsgGapF;
markers.models["myUsgGapFMaker"+myHolesNum]=myUsgGapFMaker;
markers.models["myUsgGapFMakerY"+myHolesNum]=myUsgGapFMakerY;
markers.models["myUsgGapFMakerW"+myHolesNum]=myUsgGapFMakerW;
/*****************************************************************************/       
  makerjs.model.combineSubtraction(markers.models.myRect, markers.models["myUsgGapF"+myHolesNum])
/*****************************************************************************/
      }
        else if(area==7){
  //画缺 G位置
        //画U型缺 G位置
        var myUsgGapG = new makerjs.models.RoundRectangle(w+d, d, d)
//   delete myUsgGapG.paths.TopLeft;
//   delete myUsgGapG.paths.BottomLeft;
  myUsgGapG.origin=[xg-d/2,yg];
  ////标记U型缺 G位置
  var myUsgGapGMaker = new makerjs.models.Square(0); //孔的原点       
  myUsgGapGMaker.origin = [xg-d/2,yg];
  var myUsgGapGMakerY = new makerjs.models.Square(0); //孔的原点       
  myUsgGapGMakerY.origin = [xg-d/2,yg];
  var myUsgGapGMakerW = new makerjs.models.Square(0); //孔的原点       
  myUsgGapGMakerW.origin = [xg-d/2,yg]; 
  /*****************************************************************************/
  /*****************************************************************************/ 
  //makerjs.model.addPath(myUsgGapGMaker, new makerjs.paths.Line([0+d/2,-y0], [0+d/2, -y0-markShiftFour]));//Y轴加长标线
  makerjs.model.addPath(myUsgGapGMaker, new makerjs.paths.Line([0+d+w,0], [0+d+w, -markShiftFour]));//Y轴方向短线
  makerjs.model.addPath(myUsgGapGMaker, new makerjs.paths.Line([0+d/2,-y0], [d/2-markShiftFour, -y0]));//X轴加长标线
  makerjs.model.addPath(myUsgGapGMaker, new makerjs.paths.Line([0+d/2,0], [d/2-markShiftFour, 0]));//X轴方向短线
  //makerjs.model.addPath(myUsgGapGMaker, new makerjs.paths.Line([0+d/2,d], [d/2-markShiftFour, d]));//X轴方向短线
  /*****************************************************************************/   
  makerjs.model.addPath(myUsgGapGMaker, new makerjs.paths.Line([0+markShiftTwo+d/2,0], [0+markShiftTwo+d/2, 0+d]));//D轴方向标注短线 
  makerjs.model.addCaption(myUsgGapGMaker,TxtD, [0+markShiftFour+d/2,0], [0+markShiftFour+d/2, 0+d]);//D文字标注  
  // /*****************************************************************************/
  makerjs.model.addPath(myUsgGapGMakerY, new makerjs.paths.Line([0-markShiftTwo+d/2,0], [0-markShiftTwo+d/2, -y0]));//Y轴方向标注短线 
  makerjs.model.addCaption(myUsgGapGMakerY,TxtY, [0-markShiftFour+d/2, -y0], [0-markShiftFour+d/2,0]);//Y文字标注
  // /*****************************************************************************/
  makerjs.model.addPath(myUsgGapGMakerW, new makerjs.paths.Line([0+d/2,-markShiftTwo], [0+d+w, -markShiftTwo]));//W短线标注
  makerjs.model.addCaption(myUsgGapGMakerW, TxtW, [0+d/2,-markShiftFour], [0+d+w, -markShiftFour]);//W文字标注
  /*****************************************************************************/ 
  plate.models["myUsgGapG"+myHolesNum]=myUsgGapG;
  markers.models["myUsgGapG"+myHolesNum]=myUsgGapG;
  markers.models["myUsgGapGMaker"+myHolesNum]=myUsgGapGMaker;
  markers.models["myUsgGapGMakerY"+myHolesNum]=myUsgGapGMakerY;
  markers.models["myUsgGapGMakerW"+myHolesNum]=myUsgGapGMakerW;
  /*****************************************************************************/     
  makerjs.model.combineSubtraction(markers.models.myRect, markers.models["myUsgGapG"+myHolesNum])
  console.log("markers==",markers);
  /*****************************************************************************/
  /*****************************************************************************/ 
    
        }else if(area==9){
  //画U型缺 I位置
  var myUsgGapI = new makerjs.models.RoundRectangle(w+d, d, d)
//   delete myUsgGapI.paths.TopRight;
//   delete myUsgGapI.paths.BottomRight;
  myUsgGapI.origin=[xi-w-d/2,yi];
  ////标记U型缺 I位置
  var myUsgGapIMaker = new makerjs.models.Square(0); //孔的原点       
  myUsgGapIMaker.origin = [xi-w-d/2,yi];
  var myUsgGapIMakerY = new makerjs.models.Square(0); //孔的原点       
  myUsgGapIMakerY.origin = [xi-w-d/2,yi];
  var myUsgGapIMakerW = new makerjs.models.Square(0); //孔的原点       
  myUsgGapIMakerW.origin = [xi-w-d/2,yi];  
  /*****************************************************************************/
  /*****************************************************************************/  
  makerjs.model.addPath(myUsgGapIMaker, new makerjs.paths.Line([0,0], [0, -markShiftFour]));//Y轴方向短线
  makerjs.model.addPath(myUsgGapIMaker, new makerjs.paths.Line([0+d/2+w,-y0], [0+d/2+w+markShiftThree, -y0]));//X轴加长标线
  makerjs.model.addPath(myUsgGapIMaker, new makerjs.paths.Line([0+d/2+w,d], [0+d/2+w+markShiftThree, d]));//X轴方向短线
  makerjs.model.addPath(myUsgGapIMaker, new makerjs.paths.Line([0+d/2+w,0], [0+d/2+w+markShiftThree, 0]));//X轴方向短线
  
  makerjs.model.addPath(myUsgGapIMaker, new makerjs.paths.Line([0+w+d/2+markShift,0], [0+w+d/2+markShift, 0+d]));//Y轴方向标注短线 
  makerjs.model.addCaption(myUsgGapIMaker,TxtD, [0+d+w+markShift,0], [0+d+w+markShift, 0+d]);//Y文字标注
  /*****************************************************************************/
  makerjs.model.addPath(myUsgGapIMakerY, new makerjs.paths.Line([0+w+d/2+markShiftTwo,0], [0+w+d/2+markShiftTwo, 0-y0]));//Y轴方向标注短线 
  makerjs.model.addCaption(myUsgGapIMakerY,TxtY, [0+d+w+markShiftTwo, 0-y0], [0+d+w+markShiftTwo,0]);//Y文字标注
  
  /*****************************************************************************/
  makerjs.model.addPath(myUsgGapIMakerW, new makerjs.paths.Line([0,-markShiftTwo], [0+d/2+w, -markShiftTwo]));//W短线标注
  makerjs.model.addCaption(myUsgGapIMakerW, TxtW, [0,-markShiftFour], [0+d/2+w, -markShiftFour]);//W文字标注
  /*****************************************************************************/
  plate.models["myUsgGapI"+myHolesNum]=myUsgGapI;
  markers.models["myUsgGapI"+myHolesNum]=myUsgGapI;
  markers.models["myUsgGapIMaker"+myHolesNum]=myUsgGapIMaker;
  markers.models["myUsgGapIMakerY"+myHolesNum]=myUsgGapIMakerY;
  markers.models["myUsgGapIMakerW"+myHolesNum]=myUsgGapIMakerW;
  /*****************************************************************************/  
  makerjs.model.combineSubtraction(markers.models.myRect, markers.models["myUsgGapI"+myHolesNum])
  /*****************************************************************************/ 
       
        }

myHolesNum++;
return{plate, markers,myHolesNum}
};


export default UShapeGap;