//19、三孔4
import makerjs from "makerjs";
// inputParam,model,myHolesNum,outParam,fixedParam
// (D,X,Y,area,plate,markers,myHolesNum,glassW,glassH,markShift,markShiftTwo,markShiftThree,markShiftFour,markHolesOneD)
function ThreeHoles4(inputParam,model,myHolesNum,outParam,fixedParam,zoom){
var D=inputParam.D,W=inputParam.W,H=inputParam.H,X=inputParam.X,Y=inputParam.Y,area=inputParam.area,myZoom=zoom*1,
plate=model.plate,markers=model.markers,
glassW=outParam.glassW,glassH=outParam.glassH,
   markShift=fixedParam.markShift,markShiftTwo=fixedParam.markShiftTwo,
   markShiftThree=fixedParam.markShiftThree,markShiftFour=fixedParam.markShiftFour,markHolesOneD=fixedParam.markHolesOneD,
 
   d = D*1*myZoom,
      w= W*1*myZoom,
      h= H*1*myZoom,
        x0 = X*1*myZoom,
        y0 = Y*1*myZoom;
        /*
        d = D*1,
           w= W*1,
           h= H*1,
             x0 = X*1,
             y0 = Y*1;
             */
       if(d!==0){
         var TxtD="∮"+D;
        }
        if(h!==0){
         var TxtH=H;
        }
        if(w!==0){
         var TxtW=W;
        }
        if(x0!==0){          
        var TxtX=X;
        }
        if(y0!==0){          
         var TxtY=Y;
        }
  //在A区域 计算孔的位置       
  var xa=x0,ya=glassH * 1-y0,
  //B区域//只有Y标注没有X标注 
  xb=glassW * 1/2,yb=glassH * 1-y0,
  //C区域
  xc=glassW * 1-x0,yc=glassH * 1-y0,
  //D区域
  //只有X标注没有Y标注 
  xd=x0,yd=glassH * 1/2,
  //E区域
  //只有X标注没有Y标注 
  xe=glassW * 1/2,ye=glassH * 1/2,
  //F区域
  //只有X标注没有Y标注 
  xf=glassW * 1-x0,yf=glassH * 1/2,
  //在G区域 计算孔的位置
  xg=x0,yg=y0,
  //在H区域 计算孔的位置
  //只标注Y
  xh=glassW*1/2,yh=y0,
  //在I区域 计算孔的位置
  xi=glassW*1-x0,yi=y0;
  if(area==1){
    //画孔 A位置孔
          //标记三孔4 A位置孔右上
          var myThHoles4ARightup= new makerjs.models.Holes(d/2, [[xa+w, ya]]);
          var myThHoles4MarkARightup = new makerjs.models.Square(0); //孔的原点       
          myThHoles4MarkARightup.origin = [xa+w, ya];      
          var myThHoles4XMarkARightup = new makerjs.models.Square(0);      
          myThHoles4XMarkARightup.origin =[xa+w, ya];   
    /*****************************************************************************/ 
    makerjs.model.addPath(myThHoles4MarkARightup, new makerjs.paths.Line([0,0], [d+markShiftFour, 0]));//从孔圆心出发X方向横向标线
    makerjs.model.addPath(myThHoles4MarkARightup, new makerjs.paths.Line([0,0], [0, y0+markShiftFour]));//从孔圆心出发Y方向竖向标线
    /*****************************************************************************/ 
    makerjs.model.addPath(myThHoles4MarkARightup, new makerjs.paths.Line([0,0], [d, -d]));//直径标线
    makerjs.model.addCaption(myThHoles4MarkARightup, TxtD, [0,0], [d+markHolesOneD, -d-markHolesOneD]);//直径文字标注
    /*****************************************************************************/   
    makerjs.model.addPath(myThHoles4XMarkARightup, new makerjs.paths.Line([0,y0+markShiftTwo], [-w, y0+markShiftTwo]));//X短标线
    makerjs.model.addCaption(myThHoles4XMarkARightup, TxtW, [-w,y0+markShiftFour], [0, y0+markShiftFour]);//X文字标注
    /*****************************************************************************/  
    /*****************************************************************************/ 
     //标记三孔 A位置孔左下
     var myThHoles4ALeftdn= new makerjs.models.Holes(d/2, [[xa, ya-h]]);
          var myThHoles4MarkALeftdn = new makerjs.models.Square(0); //孔的原点       
          myThHoles4MarkALeftdn.origin = [xa, ya-h];       
          var myThHoles4XMarkALeftdn = new makerjs.models.Square(0);      
          myThHoles4XMarkALeftdn.origin = [xa, ya-h];       
          var myThHoles4YMarkALeftdn = new makerjs.models.Square(0);      
          myThHoles4YMarkALeftdn.origin = [xa, ya-h];  
    /*****************************************************************************/ 
    makerjs.model.addPath(myThHoles4MarkALeftdn, new makerjs.paths.Line([-x0,y0+h], [-x0, y0+h+markShiftFour]));//Y轴方向标注加长线
    makerjs.model.addPath(myThHoles4MarkALeftdn, new makerjs.paths.Line([0,0], [w, 0]));//从孔圆心出发X方向横向标线
    makerjs.model.addPath(myThHoles4MarkALeftdn, new makerjs.paths.Line([0,0], [-x0, 0]));//从孔圆心出发X方向横向标线
    makerjs.model.addPath(myThHoles4MarkALeftdn, new makerjs.paths.Line([0,0], [0, y0+h+markShiftFour]));//从孔圆心出发Y方向竖向标线
    /*****************************************************************************/ 
    makerjs.model.addPath(myThHoles4MarkALeftdn, new makerjs.paths.Line([0,0], [d, -d]));//直径标线
    makerjs.model.addCaption(myThHoles4MarkALeftdn, TxtD, [0,0], [d+markHolesOneD, -d-markHolesOneD]);//直径文字标注
    /*****************************************************************************/  
    makerjs.model.addPath(myThHoles4XMarkALeftdn, new makerjs.paths.Line([-x0,y0+h+markShiftTwo], [0, y0+h+markShiftTwo]));//X短标线
    makerjs.model.addCaption(myThHoles4XMarkALeftdn, TxtX, [-x0,y0+h+markShiftFour], [0, y0+h+markShiftFour]);//X文字标注
    /*****************************************************************************/ 
    makerjs.model.addPath(myThHoles4YMarkALeftdn, new makerjs.paths.Line([w+d+markShiftTwo,0], [w+d+markShiftTwo, h]));//Y短标线
    makerjs.model.addCaption(myThHoles4YMarkALeftdn, TxtH, [w+d+markShiftFour,0], [w+d+markShiftFour, h]);//Y文字标注
    /*****************************************************************************/ 
    /*****************************************************************************/ 
     //标记三孔 A位置孔右下
     var myThHoles4ARightdn= new makerjs.models.Holes(d/2, [[xa+w, ya-h]]);
          var myThHoles4MarkARightdn = new makerjs.models.Square(0); //孔的原点       
          myThHoles4MarkARightdn.origin = [xa+w, ya-h];      
          var myThHoles4XMarkARightdn = new makerjs.models.Square(0);      
          myThHoles4XMarkARightdn.origin =[xa+w, ya-h];      
          var myThHoles4YMarkARightdn = new makerjs.models.Square(0);      
          myThHoles4YMarkARightdn.origin = [xa+w, ya-h]; 
    /*****************************************************************************/ 
    makerjs.model.addPath(myThHoles4MarkARightdn, new makerjs.paths.Line([0,0], [d+markShiftFour, 0]));//从孔圆心出发X方向横向标线
    /*****************************************************************************/ 
    makerjs.model.addPath(myThHoles4MarkARightdn, new makerjs.paths.Line([0,0], [d, -d]));//直径标线
    makerjs.model.addCaption(myThHoles4MarkARightdn, TxtD, [0,0], [d+markHolesOneD, -d-markHolesOneD]);//直径文字标注
    /*****************************************************************************/  
    /*****************************************************************************/
    plate.models["myThHoles4ALeftdn"+myHolesNum]=myThHoles4ALeftdn;
    markers.models["myThHoles4ALeftdn"+myHolesNum]=myThHoles4ALeftdn;
    markers.models["myThHoles4MarkALeftdn"+myHolesNum]=myThHoles4MarkALeftdn;
    markers.models["myThHoles4XMarkALeftdn"+myHolesNum]=myThHoles4XMarkALeftdn;
    markers.models["myThHoles4YMarkALeftdn"+myHolesNum]=myThHoles4YMarkALeftdn;
    /*****************************************************************************/ 
    plate.models["myThHoles4ARightup"+myHolesNum]=myThHoles4ARightup;
    markers.models["myThHoles4ARightup"+myHolesNum]=myThHoles4ARightup;
    markers.models["myThHoles4MarkARightup"+myHolesNum]=myThHoles4MarkARightup;
    markers.models["myThHoles4XMarkARightup"+myHolesNum]=myThHoles4XMarkARightup;
    /*****************************************************************************/ 
    plate.models["myThHoles4ARightdn"+myHolesNum]=myThHoles4ARightdn;
    markers.models["myThHoles4ARightdn"+myHolesNum]=myThHoles4ARightdn;
    markers.models["myThHoles4MarkARightdn"+myHolesNum]=myThHoles4MarkARightdn;
    /*****************************************************************************/ 
    /*****************************************************************************/ 
        
    }else if(area==4){
    //画孔D位置孔
          //标记三孔4 D位置孔右上
          var myThHoles4DRightup= new makerjs.models.Holes(d/2, [[xd+w, yd+h/2]]);
          var myThHoles4MarkDRightup = new makerjs.models.Square(0); //孔的原点       
          myThHoles4MarkDRightup.origin = [xd+w, yd+h/2];      
          var myThHoles4XMarkDRightup = new makerjs.models.Square(0);      
          myThHoles4XMarkDRightup.origin =[xd+w, yd+h/2];   
    /*****************************************************************************/ 
    makerjs.model.addPath(myThHoles4MarkDRightup, new makerjs.paths.Line([0,0], [d+markShiftFour, 0]));//从孔圆心出发X方向横向标线
    makerjs.model.addPath(myThHoles4MarkDRightup, new makerjs.paths.Line([0,0], [0, yd-h/2+markShiftFour]));//从孔圆心出发Y方向竖向标线
    /*****************************************************************************/ 
    makerjs.model.addPath(myThHoles4MarkDRightup, new makerjs.paths.Line([0,0], [d, -d]));//直径标线
    makerjs.model.addCaption(myThHoles4MarkDRightup, TxtD, [0,0], [d+markHolesOneD, -d-markHolesOneD]);//直径文字标注
    /*****************************************************************************/   
    makerjs.model.addPath(myThHoles4XMarkDRightup, new makerjs.paths.Line([0,yd-h/2+markShiftTwo], [-w, yd-h/2+markShiftTwo]));//X短标线
    makerjs.model.addCaption(myThHoles4XMarkDRightup, TxtW, [-w,yd-h/2+markShiftFour], [0, yd-h/2+markShiftFour]);//X文字标注
    /*****************************************************************************/  
    /*****************************************************************************/ 
     //标记三孔4D位置孔左下
     var myThHoles4DLeftdn= new makerjs.models.Holes(d/2, [[xd, yd-h/2]]);
          var myThHoles4MarkDLeftdn = new makerjs.models.Square(0); //孔的原点       
          myThHoles4MarkDLeftdn.origin = [xd, yd-h/2];       
          var myThHoles4XMarkDLeftdn = new makerjs.models.Square(0);      
          myThHoles4XMarkDLeftdn.origin = [xd, yd-h/2];       
          var myThHoles4YMarkDLeftdn = new makerjs.models.Square(0);      
          myThHoles4YMarkDLeftdn.origin = [xd, yd-h/2];  
    /*****************************************************************************/ 
    makerjs.model.addPath(myThHoles4MarkDLeftdn, new makerjs.paths.Line([-x0,yd+h/2], [-x0, yd+h/2+markShiftFour]));//Y轴方向标注加长线
    makerjs.model.addPath(myThHoles4MarkDLeftdn, new makerjs.paths.Line([0,0], [w+d+markShiftFour, 0]));//从孔圆心出发X方向横向标线
    makerjs.model.addPath(myThHoles4MarkDLeftdn, new makerjs.paths.Line([0,0], [-x0, 0]));//从孔圆心出发X方向横向标线
    makerjs.model.addPath(myThHoles4MarkDLeftdn, new makerjs.paths.Line([0,0], [0, yd+h/2+markShiftFour]));//从孔圆心出发Y方向竖向标线
    /*****************************************************************************/ 
    makerjs.model.addPath(myThHoles4MarkDLeftdn, new makerjs.paths.Line([0,0], [d, -d]));//直径标线
    makerjs.model.addCaption(myThHoles4MarkDLeftdn, TxtD, [0,0], [d+markHolesOneD, -d-markHolesOneD]);//直径文字标注
    /*****************************************************************************/  
    makerjs.model.addPath(myThHoles4XMarkDLeftdn, new makerjs.paths.Line([-x0,yd+h/2+markShiftTwo], [0, yd+h/2+markShiftTwo]));//X短标线
    makerjs.model.addCaption(myThHoles4XMarkDLeftdn, TxtX, [-x0,yd+h/2+markShiftFour], [0, yd+h/2+markShiftFour]);//X文字标注
    /*****************************************************************************/ 
    makerjs.model.addPath(myThHoles4YMarkDLeftdn, new makerjs.paths.Line([w+d+markShiftTwo,0], [w+d+markShiftTwo, h]));//Y短标线
    makerjs.model.addCaption(myThHoles4YMarkDLeftdn, TxtH, [w+d+markShiftFour,0], [w+d+markShiftFour, h]);//Y文字标注
    /*****************************************************************************/ 
    /*****************************************************************************/ 
     //标记三孔4 D位置孔左上
     var myThHoles4DLeftup= new makerjs.models.Holes(d/2, [[xd, yd+h/2]]);
          var myThHoles4MarkDLeftup = new makerjs.models.Square(0); //孔的原点       
          myThHoles4MarkDLeftup.origin = [xd, yd+h/2]; 
    /*****************************************************************************/ 
    makerjs.model.addPath(myThHoles4MarkDLeftup, new makerjs.paths.Line([0,0], [w, 0]));//从孔圆心出发X方向横向标线
    /*****************************************************************************/ 
    makerjs.model.addPath(myThHoles4MarkDLeftup, new makerjs.paths.Line([0,0], [d, -d]));//直径标线
    makerjs.model.addCaption(myThHoles4MarkDLeftup, TxtD, [0,0], [d+markHolesOneD, -d-markHolesOneD]);//直径文字标注
    /*****************************************************************************/  
    /*****************************************************************************/
    plate.models["myThHoles4DLeftdn"+myHolesNum]=myThHoles4DLeftdn;
    markers.models["myThHoles4DLeftdn"+myHolesNum]=myThHoles4DLeftdn;
    markers.models["myThHoles4MarkDLeftdn"+myHolesNum]=myThHoles4MarkDLeftdn;
    markers.models["myThHoles4XMarkDLeftdn"+myHolesNum]=myThHoles4XMarkDLeftdn;
    markers.models["myThHoles4YMarkDLeftdn"+myHolesNum]=myThHoles4YMarkDLeftdn;
    /*****************************************************************************/ 
    plate.models["myThHoles4DRightup"+myHolesNum]=myThHoles4DRightup;
    markers.models["myThHoles4DRightup"+myHolesNum]=myThHoles4DRightup;
    markers.models["myThHoles4MarkDRightup"+myHolesNum]=myThHoles4MarkDRightup;
    markers.models["myThHoles4XMarkDRightup"+myHolesNum]=myThHoles4XMarkDRightup;
    /*****************************************************************************/ 
    plate.models["myThHoles4DLeftup"+myHolesNum]=myThHoles4DLeftup;
    markers.models["myThHoles4DLeftup"+myHolesNum]=myThHoles4DLeftup;
    markers.models["myThHoles4MarkDLeftup"+myHolesNum]=myThHoles4MarkDLeftup;
    /*****************************************************************************/ 
    /*****************************************************************************/ 
       
    }else if(area==6){
    //画孔F位置孔
          //标记三孔 F位置孔左上
          var myThHoles4FLeftup= new makerjs.models.Holes(d/2, [[xf-w, yf+h/2]]);
          var myThHoles4MarkFLeftup = new makerjs.models.Square(0); //孔的原点       
          myThHoles4MarkFLeftup.origin = [xf-w, yf+h/2];      
          var myThHoles4XMarkFLeftup = new makerjs.models.Square(0);      
          myThHoles4XMarkFLeftup.origin =[xf-w, yf+h/2];   
    /*****************************************************************************/ 
    makerjs.model.addPath(myThHoles4MarkFLeftup, new makerjs.paths.Line([0,0], [-d-markShiftFour, 0]));//从孔圆心出发X方向横向标线
    makerjs.model.addPath(myThHoles4MarkFLeftup, new makerjs.paths.Line([0,0], [0, yd-h/2+markShiftFour]));//从孔圆心出发Y方向竖向标线
    /*****************************************************************************/ 
    makerjs.model.addPath(myThHoles4MarkFLeftup, new makerjs.paths.Line([0,0], [d, -d]));//直径标线
    makerjs.model.addCaption(myThHoles4MarkFLeftup, TxtD, [0,0], [d+markHolesOneD, -d-markHolesOneD]);//直径文字标注
    /*****************************************************************************/   
    makerjs.model.addPath(myThHoles4XMarkFLeftup, new makerjs.paths.Line([0,yd-h/2+markShiftTwo], [w, yd-h/2+markShiftTwo]));//X短标线
    makerjs.model.addCaption(myThHoles4XMarkFLeftup, TxtW, [0, yd-h/2+markShiftFour], [w,yd-h/2+markShiftFour]);//X文字标注
    /*****************************************************************************/  
    /*****************************************************************************/ 
     //标记三孔F位置孔右下
     var myThHoles4FRightdn= new makerjs.models.Holes(d/2, [[xf, yf-h/2]]);
          var myThHoles4MarkFRightdn = new makerjs.models.Square(0); //孔的原点       
          myThHoles4MarkFRightdn.origin = [xf, yf-h/2];       
          var myThHoles4XMarkFRightdn = new makerjs.models.Square(0);      
          myThHoles4XMarkFRightdn.origin = [xf, yf-h/2];       
          var myThHoles4YMarkFRightdn = new makerjs.models.Square(0);      
          myThHoles4YMarkFRightdn.origin = [xf, yf-h/2];  
    /*****************************************************************************/ 
    makerjs.model.addPath(myThHoles4MarkFRightdn, new makerjs.paths.Line([x0,yd+h/2], [x0, yd+h/2+markShiftFour]));//Y轴方向标注加长线
    makerjs.model.addPath(myThHoles4MarkFRightdn, new makerjs.paths.Line([0,0], [-w-d-markShiftFour, 0]));//从孔圆心出发X方向横向标线
    makerjs.model.addPath(myThHoles4MarkFRightdn, new makerjs.paths.Line([0,0], [x0, 0]));//从孔圆心出发X方向横向标线
    makerjs.model.addPath(myThHoles4MarkFRightdn, new makerjs.paths.Line([0,0], [0, yd+h/2+markShiftFour]));//从孔圆心出发Y方向竖向标线
    /*****************************************************************************/ 
    makerjs.model.addPath(myThHoles4MarkFRightdn, new makerjs.paths.Line([0,0], [d, -d]));//直径标线
    makerjs.model.addCaption(myThHoles4MarkFRightdn, TxtD, [0,0], [d+markHolesOneD, -d-markHolesOneD]);//直径文字标注
    /*****************************************************************************/  
    makerjs.model.addPath(myThHoles4XMarkFRightdn, new makerjs.paths.Line([x0,yd+h/2+markShiftTwo], [0, yd+h/2+markShiftTwo]));//X短标线
    makerjs.model.addCaption(myThHoles4XMarkFRightdn, TxtX, [0, yd+h/2+markShiftFour], [x0,yd+h/2+markShiftFour]);//X文字标注
    /*****************************************************************************/ 
    makerjs.model.addPath(myThHoles4YMarkFRightdn, new makerjs.paths.Line([-w-d-markShiftTwo,0], [-w-d-markShiftTwo, h]));//Y短标线
    makerjs.model.addCaption(myThHoles4YMarkFRightdn, TxtH, [-w-d-markShiftFour,0], [-w-d-markShiftFour, h]);//Y文字标注
    /*****************************************************************************/ 
    /*****************************************************************************/ 
     //标记三孔 F位置孔右上
     var myThHoles4FRightup= new makerjs.models.Holes(d/2, [[xf, yf+h/2]]);
          var myThHoles4MarkFRightup = new makerjs.models.Square(0); //孔的原点       
          myThHoles4MarkFRightup.origin = [xf, yf+h/2];
    /*****************************************************************************/ 
    makerjs.model.addPath(myThHoles4MarkFRightup, new makerjs.paths.Line([0,0], [-w, 0]));//从孔圆心出发X方向横向标线
    /*****************************************************************************/ 
    makerjs.model.addPath(myThHoles4MarkFRightup, new makerjs.paths.Line([0,0], [d, -d]));//直径标线
    makerjs.model.addCaption(myThHoles4MarkFRightup, TxtD, [0,0], [d+markHolesOneD, -d-markHolesOneD]);//直径文字标注
    /*****************************************************************************/  
    /*****************************************************************************/
    plate.models["myThHoles4FRightdn"+myHolesNum]=myThHoles4FRightdn;
    markers.models["myThHoles4FRightdn"+myHolesNum]=myThHoles4FRightdn;
    markers.models["myThHoles4MarkFRightdn"+myHolesNum]=myThHoles4MarkFRightdn;
    markers.models["myThHoles4XMarkFRightdn"+myHolesNum]=myThHoles4XMarkFRightdn;
    markers.models["myThHoles4YMarkFRightdn"+myHolesNum]=myThHoles4YMarkFRightdn;
    /*****************************************************************************/ 
    plate.models["myThHoles4FLeftup"+myHolesNum]=myThHoles4FLeftup;
    markers.models["myThHoles4FLeftup"+myHolesNum]=myThHoles4FLeftup;
    markers.models["myThHoles4MarkFLeftup"+myHolesNum]=myThHoles4MarkFLeftup;
    markers.models["myThHoles4XMarkFLeftup"+myHolesNum]=myThHoles4XMarkFLeftup;
    /*****************************************************************************/ 
    plate.models["myThHoles4FRightup"+myHolesNum]=myThHoles4FRightup;
    markers.models["myThHoles4FRightup"+myHolesNum]=myThHoles4FRightup;
    markers.models["myThHoles4MarkFRightup"+myHolesNum]=myThHoles4MarkFRightup;
    /*****************************************************************************/ 
    /*****************************************************************************/ 
         
    }
/*****************************************************************************/ 

myHolesNum++;
return{plate, markers,myHolesNum}
};


export default ThreeHoles4;