//缺5、方缺
import makerjs from "makerjs";
// inputParam,model,myHolesNum,outParam,fixedParam
// (D,X,Y,area,plate,markers,myHolesNum,glassW,glassH,markShift,markShiftTwo,markShiftThree,markShiftFour,markHolesOneD)
function SquareGap(inputParam,model,myHolesNum,outParam,fixedParam,zoom){
var H=inputParam.H,R=inputParam.R,W=inputParam.W,X=inputParam.X,Y=inputParam.Y,area=inputParam.area,myZoom=zoom*1,
plate=model.plate,markers=model.markers,
glassW=outParam.glassW,glassH=outParam.glassH,
markShift=fixedParam.markShift,markShiftF=fixedParam.markShiftF,markShiftTwo=fixedParam.markShiftTwo,
   markShiftThree=fixedParam.markShiftThree,markShiftFour=fixedParam.markShiftFour,markHolesOneD=fixedParam.markHolesOneD,
 
   w= W*1*myZoom,
   h= H*1*myZoom,
   r=R*1*myZoom,
   rd=r/Math.sqrt(2), 
     x0 = X*1*myZoom,
     y0 = Y*1*myZoom;
     /*
     w= W*1,
     h= H*1,
     r=R*1,
     rd=r/Math.sqrt(2), 
       x0 = X*1,
       y0 = Y*1;
       */
     if(r!==0){
      var TxtR="R="+R;
     }
     if(h!==0){
      var TxtH=H;
     }
     if(w!==0){
      var TxtW=W;
     }
     if(x0!==0){          
     var TxtX=X;
     }
     if(y0!==0){          
      var TxtY=Y;
     }
  //在A区域 计算孔的位置       
  var xa=x0,ya=glassH * 1-y0,
  //B区域//只有Y标注没有X标注 
  xb=glassW * 1/2,yb=glassH * 1-y0,
  //C区域
  xc=glassW * 1-x0,yc=glassH * 1-y0,
  //D区域
  //只有X标注没有Y标注 
  xd=x0,yd=glassH * 1/2,
  //E区域
  //只有X标注没有Y标注 
  xe=glassW * 1/2,ye=glassH * 1/2,
  //F区域
  //只有X标注没有Y标注 
  xf=glassW * 1-x0,yf=glassH * 1/2,
  //在G区域 计算孔的位置
  xg=x0,yg=y0,
  //在H区域 计算孔的位置
  //只标注Y
  xh=glassW*1/2,yh=y0,
  //在I区域 计算孔的位置
  xi=glassW*1-x0,yi=y0;
  if(area==1){
    //画缺 A位置
        //5、方缺 A位置 
        var mySgGapARoundRectangle=new makerjs.models.RoundRectangle(w+r, h, r); 
        mySgGapARoundRectangle.origin=[xa-r,ya-h]; 
     //画5、方缺标记 A位置    
  var mySgGapAMakerW = new makerjs.models.Square(0); //W的起始点       
  mySgGapAMakerW.origin = [xa,ya+y0];
  var mySgGapAMakerH = new makerjs.models.Square(0); //H的起始点        
  mySgGapAMakerH.origin = [xa,ya+y0];
  var mySgGapAMakerY = new makerjs.models.Square(0); //Y的起始点        
  mySgGapAMakerY.origin = [xa,ya+y0];
  var mySgGapAMakerR = new makerjs.models.Square(0); //Y的起始点        
  mySgGapAMakerR.origin = [xa,ya+y0];
  /*****************************************************************************/
  makerjs.model.addPath(mySgGapAMakerW, new makerjs.paths.Line([0,0], [0, markShiftFour]));//Y轴加长标线
  makerjs.model.addPath(mySgGapAMakerW, new makerjs.paths.Line([w,0], [w, markShiftFour]));//Y轴短标线
  makerjs.model.addPath(mySgGapAMakerW, new makerjs.paths.Line([0,0], [0-markShiftFour, 0]));//X轴加长标线
  makerjs.model.addPath(mySgGapAMakerW, new makerjs.paths.Line([0,0-y0], [0-markShiftFour, 0-y0]));//X轴方向短线
  makerjs.model.addPath(mySgGapAMakerW, new makerjs.paths.Line([0,0-y0-h], [0-markShiftFour, 0-y0-h]));//X轴方向短线
  
  makerjs.model.addPath(mySgGapAMakerH, new makerjs.paths.Line([0-markShiftTwo,0-y0], [0-markShiftTwo, 0-y0-h]));//H相关 Y轴方向短线
  makerjs.model.addCaption(mySgGapAMakerH,TxtH, [0-markShiftFour, 0-y0-h],[0-markShiftFour,0-y0] );//H文字标注
  
  makerjs.model.addPath(mySgGapAMakerY, new makerjs.paths.Line([0-markShiftTwo,0], [0-markShiftTwo, 0-y0]));//Y轴方向标注短线 
  makerjs.model.addCaption(mySgGapAMakerY,TxtY, [0-markShiftFour, 0-y0],[0-markShiftFour,0] );//Y文字标注
  /*****************************************************************************/
  /*****************************************************************************/
  makerjs.model.addPath(mySgGapAMakerW, new makerjs.paths.Line([0,markShiftTwo], [0+w, markShiftTwo]));//W短线标注
  makerjs.model.addCaption(mySgGapAMakerW, TxtW, [0,markShiftFour], [0+w, markShiftFour]);//W文字标注
  /*****************************************************************************/ 
  /*****************************************************************************/
  makerjs.model.addPath(mySgGapAMakerR, new makerjs.paths.Line([w-r,-y0-h+r], [w-r+rd,-y0-h+r-rd]));////R短线标注直径标线
  makerjs.model.addCaption(mySgGapAMakerR, TxtR, [w-r+markHolesOneD,-y0-h+r+r-markHolesOneD], [w-r+rd+r+markHolesOneD, -y0-h+r-rd-r-markHolesOneD]);///R文字标注直径文字标注
  /*****************************************************************************/    
  plate.models["mySgGapARoundRectangle"+myHolesNum]=mySgGapARoundRectangle;
  markers.models["mySgGapARoundRectangle"+myHolesNum]=mySgGapARoundRectangle;
  markers.models["mySgGapAMakerW"+myHolesNum]=mySgGapAMakerW;
  markers.models["mySgGapAMakerH"+myHolesNum]=mySgGapAMakerH;
  markers.models["mySgGapAMakerY"+myHolesNum]=mySgGapAMakerY;
  markers.models["mySgGapAMakerR"+myHolesNum]=mySgGapAMakerR;
  /*****************************************************************************/
  makerjs.model.combineSubtraction(markers.models.myRect, markers.models["mySgGapARoundRectangle"+myHolesNum]);
  console.log("markers==",markers);
  /*****************************************************************************/ 
  /*****************************************************************************/ 
      
  }else if(area==2){       
  }else if(area==3){
  //画缺 C位置
        //5、方缺 C位置 
        var mySgGapCRoundRectangle=new makerjs.models.RoundRectangle(w+r, h, r); 
        mySgGapCRoundRectangle.origin=[xc-w,yc-h]; 
     //画5、方缺标记 C位置    
  var mySgGapCMakerW = new makerjs.models.Square(0); //W的起始点       
  mySgGapCMakerW.origin = [xc,yc+y0];
  var mySgGapCMakerH = new makerjs.models.Square(0); //H的起始点        
  mySgGapCMakerH.origin = [xc,yc+y0];
  var mySgGapCMakerY = new makerjs.models.Square(0); //Y的起始点        
  mySgGapCMakerY.origin = [xc,yc+y0];
  var mySgGapCMakerR = new makerjs.models.Square(0); //Y的起始点        
  mySgGapCMakerR.origin = [xc,yc+y0];
  // /*****************************************************************************/
  makerjs.model.addPath(mySgGapCMakerW, new makerjs.paths.Line([0,0], [0, markShiftFour]));//Y轴加长标线
  makerjs.model.addPath(mySgGapCMakerW, new makerjs.paths.Line([-w,0], [-w, markShiftFour]));//Y轴短标线
  makerjs.model.addPath(mySgGapCMakerW, new makerjs.paths.Line([0,0], [0+markShiftFour, 0]));//X轴加长标线
  makerjs.model.addPath(mySgGapCMakerW, new makerjs.paths.Line([0,0-y0], [0+markShiftFour, 0-y0]));//X轴方向短线
  makerjs.model.addPath(mySgGapCMakerW, new makerjs.paths.Line([0,0-y0-h], [0+markShiftFour, 0-y0-h]));//X轴方向短线
  
  makerjs.model.addPath(mySgGapCMakerH, new makerjs.paths.Line([0+markShiftTwo,0-y0], [0+markShiftTwo, 0-y0-h]));//H相关 Y轴方向短线
  makerjs.model.addCaption(mySgGapCMakerH,TxtH, [0+markShiftFour, 0-y0-h],[0+markShiftFour,0-y0] );//H文字标注
  
  makerjs.model.addPath(mySgGapCMakerY, new makerjs.paths.Line([0+markShiftTwo,0], [0+markShiftTwo, 0-y0]));//Y轴方向标注短线 
  makerjs.model.addCaption(mySgGapCMakerY,TxtY, [0+markShiftFour, 0-y0],[0+markShiftFour,0] );//Y文字标注
  /*****************************************************************************/
  /*****************************************************************************/
  makerjs.model.addPath(mySgGapCMakerW, new makerjs.paths.Line([0,markShiftTwo], [0-w, markShiftTwo]));//W短线标注
  makerjs.model.addCaption(mySgGapCMakerW, TxtW, [0-w, markShiftFour], [0,markShiftFour]);//W文字标注
  /*****************************************************************************/ 
  /*****************************************************************************/
  makerjs.model.addPath(mySgGapCMakerR, new makerjs.paths.Line([-w+r,-y0-h+r], [-w+r-rd,-y0-h+r-rd]));////R短线标注直径标线
  makerjs.model.addCaption(mySgGapCMakerR, TxtR, [-w+r-rd-r-markHolesOneD, -y0-h+r-rd-r-markHolesOneD], [-w+r-markHolesOneD,-y0-h+r+r-markHolesOneD]);///R文字标注直径文字标注
  /*****************************************************************************/    
  plate.models["mySgGapCRoundRectangle"+myHolesNum]=mySgGapCRoundRectangle;
  markers.models["mySgGapCRoundRectangle"+myHolesNum]=mySgGapCRoundRectangle;
  markers.models["mySgGapCMakerW"+myHolesNum]=mySgGapCMakerW;
  markers.models["mySgGapCMakerH"+myHolesNum]=mySgGapCMakerH;
  markers.models["mySgGapCMakerY"+myHolesNum]=mySgGapCMakerY;
  markers.models["mySgGapCMakerR"+myHolesNum]=mySgGapCMakerR;
  /*****************************************************************************/
  makerjs.model.combineSubtraction(markers.models.myRect, markers.models["mySgGapCRoundRectangle"+myHolesNum]);
  console.log("markers==",markers);
  /*****************************************************************************/ 
  /*****************************************************************************/ 
     
  }else if(area==4){
   //画缺 D位置
        //5、方缺 D位置 
        var mySgGapDRoundRectangle=new makerjs.models.RoundRectangle(w+r, h, r); 
        mySgGapDRoundRectangle.origin=[xd-r,yd-h/2]; 
     //画5、方缺标记 D位置    
  var mySgGapDMakerW = new makerjs.models.Square(0); //W的起始点       
  mySgGapDMakerW.origin = [xd,yd];
  var mySgGapDMakerH = new makerjs.models.Square(0); //H的起始点        
  mySgGapDMakerH.origin = [xd,yd];
  var mySgGapDMakerR = new makerjs.models.Square(0); //Y的起始点        
  mySgGapDMakerR.origin = [xd,yd];
  // /*****************************************************************************/
  makerjs.model.addPath(mySgGapDMakerW, new makerjs.paths.Line([0,yd], [0, yd+markShiftFour]));//Y轴加长标线
  makerjs.model.addPath(mySgGapDMakerW, new makerjs.paths.Line([w,yd], [w, yd+markShiftFour]));//Y轴短标线
  
  makerjs.model.addPath(mySgGapDMakerH, new makerjs.paths.Line([0+markShiftTwo,h/2], [0+markShiftTwo, -h/2]));//H相关 Y轴方向短线
  makerjs.model.addCaption(mySgGapDMakerH,TxtH,[0+markShiftFour,-h/2], [0+markShiftFour, h/2] );//H文字标注
  // /*****************************************************************************/
  // /*****************************************************************************/
  makerjs.model.addPath(mySgGapDMakerW, new makerjs.paths.Line([0,yd+markShiftTwo], [0+w, yd+markShiftTwo]));//W短线标注
  makerjs.model.addCaption(mySgGapDMakerW, TxtW, [0,yd+markShiftFour], [0+w, yd+markShiftFour]);//W文字标注
  // /*****************************************************************************/ 
  // /*****************************************************************************/
  makerjs.model.addPath(mySgGapDMakerR, new makerjs.paths.Line([w-r,h/2-r], [w-r+rd,h/2-r+rd]));////R短线标注直径标线
  makerjs.model.addCaption(mySgGapDMakerR, TxtR, [w-r+markHolesOneD,h/2-r-r+markHolesOneD], [w-r+rd+r+markHolesOneD, h/2-r+rd+r+markHolesOneD]);///R文字标注直径文字标注
  /*****************************************************************************/    
  plate.models["mySgGapDRoundRectangle"+myHolesNum]=mySgGapDRoundRectangle;
  markers.models["mySgGapDRoundRectangle"+myHolesNum]=mySgGapDRoundRectangle;
  markers.models["mySgGapDMakerW"+myHolesNum]=mySgGapDMakerW;
  markers.models["mySgGapDMakerH"+myHolesNum]=mySgGapDMakerH;
  markers.models["mySgGapDMakerR"+myHolesNum]=mySgGapDMakerR;
  // /*****************************************************************************/
  makerjs.model.combineSubtraction(markers.models.myRect, markers.models["mySgGapDRoundRectangle"+myHolesNum]);
  console.log("markers==",markers);
  /*****************************************************************************/ 
  /*****************************************************************************/ 
            
  }else if(area==5){       
  }else if(area==6){
   //画缺 F位置
        //5、方缺 F位置 
        var mySgGapFRoundRectangle=new makerjs.models.RoundRectangle(w+r, h, r); 
        mySgGapFRoundRectangle.origin=[xf-w,yf-h/2]; 
     //画5、方缺标记 F位置    
  var mySgGapFMakerW = new makerjs.models.Square(0); //W的起始点       
  mySgGapFMakerW.origin = [xf,yf];
  var mySgGapFMakerH = new makerjs.models.Square(0); //H的起始点        
  mySgGapFMakerH.origin = [xf,yf];
  var mySgGapFMakerR = new makerjs.models.Square(0); //Y的起始点        
  mySgGapFMakerR.origin = [xf,yf]; 
  // /*****************************************************************************/
  makerjs.model.addPath(mySgGapFMakerW, new makerjs.paths.Line([0,yd], [0, yd+markShiftFour]));//Y轴加长标线
  makerjs.model.addPath(mySgGapFMakerW, new makerjs.paths.Line([-w,yd], [-w, yd+markShiftFour]));//Y轴短标线
  makerjs.model.addPath(mySgGapFMakerW, new makerjs.paths.Line([0,h/2], [0+markShiftFour, h/2]));//X轴加长标线
  makerjs.model.addPath(mySgGapFMakerW, new makerjs.paths.Line([0,0-h/2], [0+markShiftFour, 0-h/2]));//X轴方向短线
  
  makerjs.model.addPath(mySgGapFMakerH, new makerjs.paths.Line([0+markShiftTwo,h/2], [0+markShiftTwo, -h/2]));//H相关 Y轴方向短线
  makerjs.model.addCaption(mySgGapFMakerH,TxtH, [0+markShiftFour, -h/2],[0+markShiftFour,h/2] );//H文字标注
  /*****************************************************************************/
  /*****************************************************************************/
  makerjs.model.addPath(mySgGapFMakerW, new makerjs.paths.Line([0,yd+markShiftTwo], [0-w, yd+markShiftTwo]));//W短线标注
  makerjs.model.addCaption(mySgGapFMakerW, TxtW, [0-w, yd+markShiftFour], [0,yd+markShiftFour]);//W文字标注
  /*****************************************************************************/ 
  /*****************************************************************************/
  makerjs.model.addPath(mySgGapFMakerR, new makerjs.paths.Line([-w+r,h/2-r], [-w+r-rd,h/2-r+rd]));////R短线标注直径标线
  makerjs.model.addCaption(mySgGapFMakerR, TxtR, [-w+r-rd-r-markHolesOneD, h/2-r+rd+r+markHolesOneD], [-w+r-markHolesOneD,h/2-r-r+markHolesOneD]);///R文字标注直径文字标注
  /*****************************************************************************/    
  plate.models["mySgGapFRoundRectangle"+myHolesNum]=mySgGapFRoundRectangle;
  markers.models["mySgGapFRoundRectangle"+myHolesNum]=mySgGapFRoundRectangle;
  markers.models["mySgGapFMakerW"+myHolesNum]=mySgGapFMakerW;
  markers.models["mySgGapFMakerH"+myHolesNum]=mySgGapFMakerH;
  markers.models["mySgGapFMakerR"+myHolesNum]=mySgGapFMakerR;
  /*****************************************************************************/
  makerjs.model.combineSubtraction(markers.models.myRect, markers.models["mySgGapFRoundRectangle"+myHolesNum]);
  console.log("markers==",markers);
  /*****************************************************************************/ 
  /*****************************************************************************/ 
            
  }else if(area==7){
  //画缺 G位置
        //5、方缺G位置 
        var mySgGapGRoundRectangle=new makerjs.models.RoundRectangle(w+r, h, r); 
        mySgGapGRoundRectangle.origin=[xg-r,yg]; 
     //画5、方缺标记 G位置    
  var mySgGapGMakerW = new makerjs.models.Square(0); //W的起始点       
  mySgGapGMakerW.origin = [xg,yg-y0];
  var mySgGapGMakerH = new makerjs.models.Square(0); //H的起始点        
  mySgGapGMakerH.origin = [xg,yg-y0];
  var mySgGapGMakerY = new makerjs.models.Square(0); //Y的起始点        
  mySgGapGMakerY.origin = [xg,yg-y0];
  var mySgGapGMakerR = new makerjs.models.Square(0); //Y的起始点        
  mySgGapGMakerR.origin = [xg,yg-y0];
  /*****************************************************************************/
  makerjs.model.addPath(mySgGapGMakerW, new makerjs.paths.Line([0,0], [0, -markShiftFour]));//Y轴加长标线
  makerjs.model.addPath(mySgGapGMakerW, new makerjs.paths.Line([w,0], [w, -markShiftFour]));//Y轴短标线
  makerjs.model.addPath(mySgGapGMakerW, new makerjs.paths.Line([0,0], [0-markShiftFour, 0]));//X轴加长标线
  makerjs.model.addPath(mySgGapGMakerW, new makerjs.paths.Line([0,0+y0], [0-markShiftFour, 0+y0]));//X轴方向短线
  makerjs.model.addPath(mySgGapGMakerW, new makerjs.paths.Line([0,0+y0+h], [0-markShiftFour, 0+y0+h]));//X轴方向短线
  
  makerjs.model.addPath(mySgGapGMakerH, new makerjs.paths.Line([0-markShiftTwo,0+y0], [0-markShiftTwo, 0+y0+h]));//H相关 Y轴方向短线
  makerjs.model.addCaption(mySgGapGMakerH,TxtH,[0-markShiftFour,0+y0], [0-markShiftFour, 0+y0+h] );//H文字标注
  
  makerjs.model.addPath(mySgGapGMakerY, new makerjs.paths.Line([0-markShiftTwo,0], [0-markShiftTwo, 0+y0]));//Y轴方向标注短线 
  makerjs.model.addCaption(mySgGapGMakerY,TxtY,[0-markShiftFour,0], [0-markShiftFour, 0+y0] );//Y文字标注
  /*****************************************************************************/
  /*****************************************************************************/
  makerjs.model.addPath(mySgGapGMakerW, new makerjs.paths.Line([0,-markShiftTwo], [0+w, -markShiftTwo]));//W短线标注
  makerjs.model.addCaption(mySgGapGMakerW, TxtW, [0,-markShiftFour], [0+w, -markShiftFour]);//W文字标注
  /*****************************************************************************/ 
  /*****************************************************************************/
  makerjs.model.addPath(mySgGapGMakerR, new makerjs.paths.Line([w-r,y0+h-r], [w-r+rd,y0+h-r+rd]));////R短线标注直径标线
  makerjs.model.addCaption(mySgGapGMakerR, TxtR, [w-r+markHolesOneD,y0+h-r-r+markHolesOneD], [w-r+rd+r+markHolesOneD, y0+h-r+rd+r+markHolesOneD]);///R文字标注直径文字标注
  /*****************************************************************************/    
  plate.models["mySgGapGRoundRectangle"+myHolesNum]=mySgGapGRoundRectangle;
  markers.models["mySgGapGRoundRectangle"+myHolesNum]=mySgGapGRoundRectangle;
  markers.models["mySgGapGMakerW"+myHolesNum]=mySgGapGMakerW;
  markers.models["mySgGapGMakerH"+myHolesNum]=mySgGapGMakerH;
  markers.models["mySgGapGMakerY"+myHolesNum]=mySgGapGMakerY;
  markers.models["mySgGapGMakerR"+myHolesNum]=mySgGapGMakerR;
  /*****************************************************************************/
  makerjs.model.combineSubtraction(markers.models.myRect, markers.models["mySgGapGRoundRectangle"+myHolesNum]);
  console.log("markers==",markers);
  /*****************************************************************************/ 
  /*****************************************************************************/ 
          
  }else if(area==8){       
  }else if(area==9){
  //画缺 I位置
        //5、方缺 I位置 
        var mySgGapIRoundRectangle=new makerjs.models.RoundRectangle(w+r, h, r); 
        mySgGapIRoundRectangle.origin=[xi-w,yi]; 
     //画5、方缺标记 I位置    
  var mySgGapIMakerW = new makerjs.models.Square(0); //W的起始点       
  mySgGapIMakerW.origin = [xi,yi-y0];
  var mySgGapIMakerH = new makerjs.models.Square(0); //H的起始点        
  mySgGapIMakerH.origin = [xi,yi-y0];
  var mySgGapIMakerY = new makerjs.models.Square(0); //Y的起始点        
  mySgGapIMakerY.origin = [xi,yi-y0];
  var mySgGapIMakerR = new makerjs.models.Square(0); //Y的起始点        
  mySgGapIMakerR.origin = [xi,yi-y0];
  // /*****************************************************************************/
  makerjs.model.addPath(mySgGapIMakerW, new makerjs.paths.Line([0,0], [0, -markShiftFour]));//Y轴加长标线
  makerjs.model.addPath(mySgGapIMakerW, new makerjs.paths.Line([-w,0], [-w, -markShiftFour]));//Y轴短标线
  makerjs.model.addPath(mySgGapIMakerW, new makerjs.paths.Line([0,0], [0+markShiftFour, 0]));//X轴加长标线
  makerjs.model.addPath(mySgGapIMakerW, new makerjs.paths.Line([0,0+y0], [0+markShiftFour, 0+y0]));//X轴方向短线
  makerjs.model.addPath(mySgGapIMakerW, new makerjs.paths.Line([0,0+y0+h], [0+markShiftFour, 0+y0+h]));//X轴方向短线
  
  makerjs.model.addPath(mySgGapIMakerH, new makerjs.paths.Line([0+markShiftTwo,0+y0], [0+markShiftTwo, 0+y0+h]));//H相关 Y轴方向短线
  makerjs.model.addCaption(mySgGapIMakerH,TxtH,[0+markShiftFour,0+y0], [0+markShiftFour, 0+y0+h] );//H文字标注
  
  makerjs.model.addPath(mySgGapIMakerY, new makerjs.paths.Line([0+markShiftTwo,0], [0+markShiftTwo, 0+y0]));//Y轴方向标注短线 
  makerjs.model.addCaption(mySgGapIMakerY,TxtY,[0+markShiftFour,0], [0+markShiftFour, 0+y0] );//Y文字标注
  /*****************************************************************************/
  /*****************************************************************************/
  makerjs.model.addPath(mySgGapIMakerW, new makerjs.paths.Line([0,-markShiftTwo], [0-w, -markShiftTwo]));//W短线标注
  makerjs.model.addCaption(mySgGapIMakerW, TxtW, [0-w, -markShiftFour], [0,-markShiftFour]);//W文字标注
  /*****************************************************************************/ 
  /*****************************************************************************/
  makerjs.model.addPath(mySgGapIMakerR, new makerjs.paths.Line([-w+r,y0+h-r], [-w+r-rd,y0+h-r+rd]));////R短线标注直径标线
  makerjs.model.addCaption(mySgGapIMakerR, TxtR, [-w+r-rd-r-markHolesOneD, y0+h-r+rd+r+markHolesOneD], [-w+r-markHolesOneD,y0+h-r-r+markHolesOneD]);///R文字标注直径文字标注
  /*****************************************************************************/    
  plate.models["mySgGapIRoundRectangle"+myHolesNum]=mySgGapIRoundRectangle;
  markers.models["mySgGapIRoundRectangle"+myHolesNum]=mySgGapIRoundRectangle;
  markers.models["mySgGapIMakerW"+myHolesNum]=mySgGapIMakerW;
  markers.models["mySgGapIMakerH"+myHolesNum]=mySgGapIMakerH;
  markers.models["mySgGapIMakerY"+myHolesNum]=mySgGapIMakerY;
  markers.models["mySgGapIMakerR"+myHolesNum]=mySgGapIMakerR;
  /*****************************************************************************/
  makerjs.model.combineSubtraction(markers.models.myRect, markers.models["mySgGapIRoundRectangle"+myHolesNum]);
  console.log("markers==",markers);
  /*****************************************************************************/ 
  /*****************************************************************************/ 
            
  }

myHolesNum++;
return{plate, markers,myHolesNum}
};


export default SquareGap;