//15、锁孔
import makerjs from "makerjs";
// inputParam,model,myHolesNum,outParam,fixedParam
// (D,X,Y,area,plate,markers,myHolesNum,glassW,glassH,markShift,markShiftTwo,markShiftThree,markShiftFour,markHolesOneD)
function KeyHole(inputParam,model,myHolesNum,outParam,fixedParam,zoom){
var D1=inputParam.D1,D2=inputParam.D2,W=inputParam.W,H=inputParam.H,X=inputParam.X,Y=inputParam.Y,area=inputParam.area,myZoom=zoom*1,
plate=model.plate,markers=model.markers,
glassW=outParam.glassW,glassH=outParam.glassH,
   markShift=fixedParam.markShift,markShiftTwo=fixedParam.markShiftTwo,
   markShiftThree=fixedParam.markShiftThree,markShiftFour=fixedParam.markShiftFour,markHolesOneD=fixedParam.markHolesOneD,
 
   d1 = D1*1*myZoom,
      d2 = D2*1*myZoom,
      w= W*1*myZoom,
      h= H*1*myZoom,
        x0 = X*1*myZoom,
        y0 = Y*1*myZoom;
        /*
        d1 = D1*1,
           d2 = D2*1,
           w= W*1,
           h= H*1,
             x0 = X*1,
             y0 = Y*1;
             */
        if(d1!==0){
         var TxtD1="∮"+D1;
        }
        if(d2!==0){
         var TxtD2="∮"+D2;
        }
        if(h!==0){
         var TxtH=H;
        }
        if(w!==0){
         var TxtW=W;
        }
        if(x0!==0){          
        var TxtX=X;
        }
        if(y0!==0){          
         var TxtY=Y;
        }
  //在A区域 计算孔的位置       
  var xa=x0,ya=glassH * 1-y0,
  //B区域//只有Y标注没有X标注 
  xb=glassW * 1/2,yb=glassH * 1-y0,
  //C区域
  xc=glassW * 1-x0,yc=glassH * 1-y0,
  //D区域
  //只有X标注没有Y标注 
  xd=x0,yd=glassH * 1/2,
  //E区域
  //只有X标注没有Y标注 
  xe=glassW * 1/2,ye=glassH * 1/2,
  //F区域
  //只有X标注没有Y标注 
  xf=glassW * 1-x0,yf=glassH * 1/2,
  //在G区域 计算孔的位置
  xg=x0,yg=y0,
  //在H区域 计算孔的位置
  //只标注Y
  xh=glassW*1/2,yh=y0,
  //在I区域 计算孔的位置
  xi=glassW*1-x0,yi=y0;
  if(area==1){
    //画孔 A位置孔
          //标记锁孔 A位置孔左上
          var myKhHolesALeftup= new makerjs.models.Holes(d1/2, [[xa, ya]]);
          var myKhHolesMarkALeftup = new makerjs.models.Square(0); //孔的原点       
          myKhHolesMarkALeftup.origin = [xa, ya];      
          var myKhHolesXMarkALeftup = new makerjs.models.Square(0);      
          myKhHolesXMarkALeftup.origin =[xa, ya];      
          var myKhHolesYMarkALeftup = new makerjs.models.Square(0);      
          myKhHolesYMarkALeftup.origin = [xa, ya]; 
    /*****************************************************************************/ 
    makerjs.model.addPath(myKhHolesMarkALeftup, new makerjs.paths.Line([-x0,y0], [-x0, y0+markShiftTwo]));//Y轴加长标线
    makerjs.model.addPath(myKhHolesMarkALeftup, new makerjs.paths.Line([0,0], [-x0-markShiftThree, 0]));//从孔圆心出发X方向横向标线
    makerjs.model.addPath(myKhHolesMarkALeftup, new makerjs.paths.Line([-x0,y0], [-x0-markShiftThree, y0]));//X轴加长标线
    makerjs.model.addPath(myKhHolesMarkALeftup, new makerjs.paths.Line([0,0], [0, y0+markShiftTwo]));//从孔圆心出发Y方向竖向标线
    /*****************************************************************************/ 
    makerjs.model.addPath(myKhHolesMarkALeftup, new makerjs.paths.Line([0,0], [d1, -d1]));//直径标线
    makerjs.model.addCaption(myKhHolesMarkALeftup, TxtD1, [0,0], [d1+markHolesOneD, -d1-markHolesOneD]);//直径文字标注
    /*****************************************************************************/   
    makerjs.model.addPath(myKhHolesXMarkALeftup, new makerjs.paths.Line([0,y0+markShift], [-x0, y0+markShift]));//X短标线
    makerjs.model.addCaption(myKhHolesXMarkALeftup, TxtX, [-x0,y0+markShiftTwo], [0, y0+markShiftTwo]);//X文字标注
    /*****************************************************************************/     
    makerjs.model.addPath(myKhHolesYMarkALeftup, new makerjs.paths.Line([-x0-markShiftTwo,0], [-x0-markShiftTwo, y0]));//Y短标线
    makerjs.model.addCaption(myKhHolesYMarkALeftup, TxtY, [-x0-markShiftThree,0], [-x0-markShiftThree, y0]);//Y文字标注
    /*****************************************************************************/ 
    /*****************************************************************************/ 
     //标记锁孔 A位置孔右中
     var myKhHolesARightmd= new makerjs.models.Holes(d2/2, [[xa+w, ya-h]]);
          var myKhHolesMarkARightmd = new makerjs.models.Square(0); //孔的原点       
          myKhHolesMarkARightmd.origin = [xa+w, ya-h];      
          var myKhHolesXMarkARightmd = new makerjs.models.Square(0);      
          myKhHolesXMarkARightmd.origin =[xa+w, ya-h];      
          var myKhHolesYMarkARightmd = new makerjs.models.Square(0);      
          myKhHolesYMarkARightmd.origin = [xa+w, ya-h];  
    /*****************************************************************************/ 
    makerjs.model.addPath(myKhHolesMarkARightmd, new makerjs.paths.Line([0,0], [-x0-w-markShiftThree, 0]));//从孔圆心出发X方向横向标线
    makerjs.model.addPath(myKhHolesMarkARightmd, new makerjs.paths.Line([0,0], [0, h+y0+markShiftTwo]));//从孔圆心出发Y方向竖向标线          
    /*****************************************************************************/ 
    makerjs.model.addPath(myKhHolesMarkARightmd, new makerjs.paths.Line([0,0], [d2, -d2]));//直径标线
    makerjs.model.addCaption(myKhHolesMarkARightmd, TxtD2, [0,0], [d2+markHolesOneD, -d2-markHolesOneD]);//直径文字标注
    /*****************************************************************************/   
    makerjs.model.addPath(myKhHolesXMarkARightmd, new makerjs.paths.Line([0,y0+h+markShift], [-w, y0+h+markShift]));//X短标线
    makerjs.model.addCaption(myKhHolesXMarkARightmd, TxtW, [-w,y0+h+markShiftTwo], [0, y0+h+markShiftTwo]);//X文字标注
    /*****************************************************************************/     
    makerjs.model.addPath(myKhHolesYMarkARightmd, new makerjs.paths.Line([-x0-w-markShiftTwo,0], [-x0-w-markShiftTwo, h]));//Y短标线
    makerjs.model.addCaption(myKhHolesYMarkARightmd, TxtH, [-x0-w-markShiftThree,0], [-x0-w-markShiftThree, h]);//Y文字标注
    /*****************************************************************************/ 
    /*****************************************************************************/ 
     //标记锁孔 A位置孔左下
     var myKhHolesALeftdn= new makerjs.models.Holes(d1/2, [[xa, ya-h-h]]);
          var myKhHolesMarkALeftdn = new makerjs.models.Square(0); //孔的原点       
          myKhHolesMarkALeftdn.origin = [xa, ya-h-h];        
          var myKhHolesYMarkALeftdn = new makerjs.models.Square(0);      
          myKhHolesYMarkALeftdn.origin = [xa, ya-h-h]; 
    /*****************************************************************************/ 
    makerjs.model.addPath(myKhHolesMarkALeftdn, new makerjs.paths.Line([0,0], [-x0-markShiftThree, 0]));//从孔圆心出发X方向横向标线
    makerjs.model.addPath(myKhHolesMarkALeftdn, new makerjs.paths.Line([0,0], [0, h+h+markShiftTwo]));//从孔圆心出发Y方向竖向标线
    /*****************************************************************************/ 
    makerjs.model.addPath(myKhHolesMarkALeftdn, new makerjs.paths.Line([0,0], [d1, -d1]));//直径标线
    makerjs.model.addCaption(myKhHolesMarkALeftdn, TxtD1, [0,0], [d1+markHolesOneD, -d1-markHolesOneD]);//直径文字标注
    /*****************************************************************************/   
    makerjs.model.addPath(myKhHolesYMarkALeftdn, new makerjs.paths.Line([-x0-markShiftTwo,0], [-x0-markShiftTwo, h]));//Y短标线
    makerjs.model.addCaption(myKhHolesYMarkALeftdn, TxtH, [-x0-markShiftThree,0], [-x0-markShiftThree, h]);//Y文字标注
    /*****************************************************************************/ 
    plate.models["myKhHolesALeftup"+myHolesNum]=myKhHolesALeftup;
    markers.models["myKhHolesALeftup"+myHolesNum]=myKhHolesALeftup;
    markers.models["myKhHolesMarkALeftup"+myHolesNum]=myKhHolesMarkALeftup;
    markers.models["myKhHolesXMarkALeftup"+myHolesNum]=myKhHolesXMarkALeftup;
    markers.models["myKhHolesYMarkALeftup"+myHolesNum]=myKhHolesYMarkALeftup;
    /*****************************************************************************/
    plate.models["myKhHolesALeftdn"+myHolesNum]=myKhHolesALeftdn;
    markers.models["myKhHolesALeftdn"+myHolesNum]=myKhHolesALeftdn;
    markers.models["myKhHolesMarkALeftdn"+myHolesNum]=myKhHolesMarkALeftdn;
    markers.models["myKhHolesYMarkALeftdn"+myHolesNum]=myKhHolesYMarkALeftdn;
    /*****************************************************************************/ 
    plate.models["myKhHolesARightmd"+myHolesNum]=myKhHolesARightmd;
    markers.models["myKhHolesARightmd"+myHolesNum]=myKhHolesARightmd;
    markers.models["myKhHolesMarkARightmd"+myHolesNum]=myKhHolesMarkARightmd;
    markers.models["myKhHolesXMarkARightmd"+myHolesNum]=myKhHolesXMarkARightmd;
    markers.models["myKhHolesYMarkARightmd"+myHolesNum]=myKhHolesYMarkARightmd;
    /*****************************************************************************/ 
    /*****************************************************************************/ 
   
    }else if(area==3){
      
    }else if(area==4){
     //画孔 D位置孔
          //标记锁孔D位置孔左上
          var myKhHolesDLeftup= new makerjs.models.Holes(d1/2, [[xd, yd+h]]);
          var myKhHolesMarkDLeftup = new makerjs.models.Square(0); //孔的原点       
          myKhHolesMarkDLeftup.origin = [xd, yd+h];      
          var myKhHolesXMarkDLeftup = new makerjs.models.Square(0);      
          myKhHolesXMarkDLeftup.origin =[xd, yd+h];      
          var myKhHolesYMarkDLeftup = new makerjs.models.Square(0);      
          myKhHolesYMarkDLeftup.origin = [xd, yd+h]; 
    /*****************************************************************************/ 
    makerjs.model.addPath(myKhHolesMarkDLeftup, new makerjs.paths.Line([-x0,yd-h], [-x0, yd-h+markShiftFour]));//Y轴加长标线
    makerjs.model.addPath(myKhHolesMarkDLeftup, new makerjs.paths.Line([0,0], [w+d2+markShiftFour, 0]));//从孔圆心出发X方向横向标线
    makerjs.model.addPath(myKhHolesMarkDLeftup, new makerjs.paths.Line([-x0,yd-h], [-x0-markShiftThree, yd-h]));//X轴加长标线
    makerjs.model.addPath(myKhHolesMarkDLeftup, new makerjs.paths.Line([0,0], [0, yd-h+markShiftFour]));//从孔圆心出发Y方向竖向标线
    /*****************************************************************************/ 
    makerjs.model.addPath(myKhHolesMarkDLeftup, new makerjs.paths.Line([0,0], [d1, -d1]));//直径标线
    makerjs.model.addCaption(myKhHolesMarkDLeftup, TxtD1, [0,0], [d1+markHolesOneD, -d1-markHolesOneD]);//直径文字标注
    /*****************************************************************************/   
    makerjs.model.addPath(myKhHolesXMarkDLeftup, new makerjs.paths.Line([0,yd-h+markShift], [-x0, yd-h+markShift]));//X短标线
    makerjs.model.addCaption(myKhHolesXMarkDLeftup, TxtX, [-x0,yd-h+markShiftTwo], [0, yd-h+markShiftTwo]);//X文字标注
    /*****************************************************************************/ 
    /*****************************************************************************/ 
    //  //标记锁孔D位置孔右中
     var myKhHolesDRightmd= new makerjs.models.Holes(d2/2, [[xd+w, yd]]);
          var myKhHolesMarkDRightmd = new makerjs.models.Square(0); //孔的原点       
          myKhHolesMarkDRightmd.origin = [xd+w, yd];      
          var myKhHolesXMarkDRightmd = new makerjs.models.Square(0);      
          myKhHolesXMarkDRightmd.origin =[xd+w, yd];      
          var myKhHolesYMarkDRightmd = new makerjs.models.Square(0);      
          myKhHolesYMarkDRightmd.origin = [xd+w, yd];  
    /*****************************************************************************/ 
    makerjs.model.addPath(myKhHolesMarkDRightmd, new makerjs.paths.Line([0,0], [d2+markShiftFour, 0]));//从孔圆心出发X方向横向标线
    makerjs.model.addPath(myKhHolesMarkDRightmd, new makerjs.paths.Line([0,0], [0, yd+markShiftFour]));//从孔圆心出发Y方向竖向标线          
    /*****************************************************************************/ 
    makerjs.model.addPath(myKhHolesMarkDRightmd, new makerjs.paths.Line([0,0], [d2, -d2]));//直径标线
    makerjs.model.addCaption(myKhHolesMarkDRightmd, TxtD2, [0,0], [d2+markHolesOneD, -d2-markHolesOneD]);//直径文字标注
    /*****************************************************************************/   
    makerjs.model.addPath(myKhHolesXMarkDRightmd, new makerjs.paths.Line([0,yd+markShift], [-w, yd+markShift]));//X短标线
    makerjs.model.addCaption(myKhHolesXMarkDRightmd, TxtW, [-w,yd+markShiftTwo], [0, yd+markShiftTwo]);//X文字标注
    /*****************************************************************************/     
    makerjs.model.addPath(myKhHolesYMarkDRightmd, new makerjs.paths.Line([d2+markShiftTwo,0], [d2+markShiftTwo, h]));//H短标线
    makerjs.model.addCaption(myKhHolesYMarkDRightmd, TxtH, [d2+markShiftFour,0], [d2+markShiftFour, h]);//H文字标注
    /*****************************************************************************/ 
    /*****************************************************************************/ 
     //标记锁孔D位置孔左下
     var myKhHolesDLeftdn= new makerjs.models.Holes(d1/2, [[xd, yd-h]]);
          var myKhHolesMarkDLeftdn = new makerjs.models.Square(0); //孔的原点       
          myKhHolesMarkDLeftdn.origin = [xd, yd-h];        
          var myKhHolesYMarkDLeftdn = new makerjs.models.Square(0);      
          myKhHolesYMarkDLeftdn.origin = [xd, yd-h]; 
    /*****************************************************************************/ 
    makerjs.model.addPath(myKhHolesMarkDLeftdn, new makerjs.paths.Line([0,0], [w+d2+markShiftFour, 0]));//从孔圆心出发X方向横向标线
    makerjs.model.addPath(myKhHolesMarkDLeftdn, new makerjs.paths.Line([0,0], [0, h+h+markShiftTwo]));//从孔圆心出发Y方向竖向标线
    /*****************************************************************************/ 
    makerjs.model.addPath(myKhHolesMarkDLeftdn, new makerjs.paths.Line([0,0], [d1, -d1]));//直径标线
    makerjs.model.addCaption(myKhHolesMarkDLeftdn, TxtD1, [0,0], [d1+markHolesOneD, -d1-markHolesOneD]);//直径文字标注
    /*****************************************************************************/   
    makerjs.model.addPath(myKhHolesYMarkDLeftdn, new makerjs.paths.Line([w+d2+markShiftTwo,0], [w+d2+markShiftTwo, h]));//Y短标线
    makerjs.model.addCaption(myKhHolesYMarkDLeftdn, TxtH, [w+d2+markShiftFour,0], [w+d2+markShiftFour, h]);//Y文字标注
    /*****************************************************************************/ 
    plate.models["myKhHolesDLeftup"+myHolesNum]=myKhHolesDLeftup;
    markers.models["myKhHolesDLeftup"+myHolesNum]=myKhHolesDLeftup;
    markers.models["myKhHolesMarkDLeftup"+myHolesNum]=myKhHolesMarkDLeftup;
    markers.models["myKhHolesXMarkDLeftup"+myHolesNum]=myKhHolesXMarkDLeftup;
    markers.models["myKhHolesYMarkDLeftup"+myHolesNum]=myKhHolesYMarkDLeftup;
    /*****************************************************************************/
    plate.models["myKhHolesDLeftdn"+myHolesNum]=myKhHolesDLeftdn;
    markers.models["myKhHolesDLeftdn"+myHolesNum]=myKhHolesDLeftdn;
    markers.models["myKhHolesMarkDLeftdn"+myHolesNum]=myKhHolesMarkDLeftdn;
    markers.models["myKhHolesYMarkDLeftdn"+myHolesNum]=myKhHolesYMarkDLeftdn;
    /*****************************************************************************/ 
    plate.models["myKhHolesDRightmd"+myHolesNum]=myKhHolesDRightmd;
    markers.models["myKhHolesDRightmd"+myHolesNum]=myKhHolesDRightmd;
    markers.models["myKhHolesMarkDRightmd"+myHolesNum]=myKhHolesMarkDRightmd;
    markers.models["myKhHolesXMarkDRightmd"+myHolesNum]=myKhHolesXMarkDRightmd;
    markers.models["myKhHolesYMarkDRightmd"+myHolesNum]=myKhHolesYMarkDRightmd;
    /*****************************************************************************/ 
    /*****************************************************************************/ 
   
    }else if(area==6){
    //画孔F位置孔
          //标记锁孔F位置孔右上
          var myKhHolesFRightup= new makerjs.models.Holes(d1/2, [[xf, yf+h]]);
          var myKhHolesMarkFRightup = new makerjs.models.Square(0); //孔的原点       
          myKhHolesMarkFRightup.origin = [xf, yf+h];      
          var myKhHolesXMarkFRightup = new makerjs.models.Square(0);      
          myKhHolesXMarkFRightup.origin =[xf, yf+h]; 
    /*****************************************************************************/ 
    makerjs.model.addPath(myKhHolesMarkFRightup, new makerjs.paths.Line([x0,yd-h], [x0, yd-h+markShiftFour]));//Y轴加长标线
    makerjs.model.addPath(myKhHolesMarkFRightup, new makerjs.paths.Line([0,0], [-w-d2-markShiftFour, 0]));//从孔圆心出发X方向横向标线
    makerjs.model.addPath(myKhHolesMarkFRightup, new makerjs.paths.Line([x0,yd-h], [x0+markShiftThree, yd-h]));//X轴加长标线
    makerjs.model.addPath(myKhHolesMarkFRightup, new makerjs.paths.Line([0,0], [0, yd-h+markShiftFour]));//从孔圆心出发Y方向竖向标线
    /*****************************************************************************/ 
    makerjs.model.addPath(myKhHolesMarkFRightup, new makerjs.paths.Line([0,0], [d1, -d1]));//直径标线
    makerjs.model.addCaption(myKhHolesMarkFRightup, TxtD1, [0,0], [d1+markHolesOneD, -d1-markHolesOneD]);//直径文字标注
    /*****************************************************************************/   
    makerjs.model.addPath(myKhHolesXMarkFRightup, new makerjs.paths.Line([0,yd-h+markShift], [x0, yd-h+markShift]));//X短标线
    makerjs.model.addCaption(myKhHolesXMarkFRightup, TxtX, [0, yd-h+markShiftTwo], [x0,yd-h+markShiftTwo]);//X文字标注
    /*****************************************************************************/ 
    /*****************************************************************************/ 
    //  //标记锁孔F位置孔左中
     var myKhHolesFLeftmd= new makerjs.models.Holes(d2/2, [[xf-w, yf]]);
          var myKhHolesMarkFLeftmd = new makerjs.models.Square(0); //孔的原点       
          myKhHolesMarkFLeftmd.origin = [xf-w, yf];      
          var myKhHolesXMarkFLeftmd = new makerjs.models.Square(0);      
          myKhHolesXMarkFLeftmd.origin =[xf-w, yf];      
          var myKhHolesYMarkFLeftmd = new makerjs.models.Square(0);      
          myKhHolesYMarkFLeftmd.origin = [xf-w, yf];  
    /*****************************************************************************/ 
    makerjs.model.addPath(myKhHolesMarkFLeftmd, new makerjs.paths.Line([0,0], [-d2-markShiftFour, 0]));//从孔圆心出发X方向横向标线
    makerjs.model.addPath(myKhHolesMarkFLeftmd, new makerjs.paths.Line([0,0], [0, yd+markShiftFour]));//从孔圆心出发Y方向竖向标线          
    /*****************************************************************************/ 
    makerjs.model.addPath(myKhHolesMarkFLeftmd, new makerjs.paths.Line([0,0], [d2, -d2]));//直径标线
    makerjs.model.addCaption(myKhHolesMarkFLeftmd, TxtD2, [0,0], [d2+markHolesOneD, -d2-markHolesOneD]);//直径文字标注
    /*****************************************************************************/   
    makerjs.model.addPath(myKhHolesXMarkFLeftmd, new makerjs.paths.Line([0,yd+markShift], [w, yd+markShift]));//X短标线
    makerjs.model.addCaption(myKhHolesXMarkFLeftmd, TxtW, [0, yd+markShiftTwo], [w,yd+markShiftTwo]);//X文字标注
    /*****************************************************************************/     
    makerjs.model.addPath(myKhHolesYMarkFLeftmd, new makerjs.paths.Line([-d2-markShiftTwo,0], [-d2-markShiftTwo, h]));//H短标线
    makerjs.model.addCaption(myKhHolesYMarkFLeftmd, TxtH, [-d2-markShiftFour,0], [-d2-markShiftFour, h]);//H文字标注
    /*****************************************************************************/ 
    /*****************************************************************************/ 
     //标记锁孔F位置孔右下
     var myKhHolesFRightdn= new makerjs.models.Holes(d1/2, [[xf, yf-h]]);
          var myKhHolesMarkFRightdn = new makerjs.models.Square(0); //孔的原点       
          myKhHolesMarkFRightdn.origin = [xf, yf-h];        
          var myKhHolesYMarkFRightdn = new makerjs.models.Square(0);      
          myKhHolesYMarkFRightdn.origin = [xf, yf-h]; 
    /*****************************************************************************/ 
    makerjs.model.addPath(myKhHolesMarkFRightdn, new makerjs.paths.Line([0,0], [-w-d2-markShiftFour, 0]));//从孔圆心出发X方向横向标线
    makerjs.model.addPath(myKhHolesMarkFRightdn, new makerjs.paths.Line([0,0], [0, h+h+markShiftTwo]));//从孔圆心出发Y方向竖向标线
    /*****************************************************************************/ 
    makerjs.model.addPath(myKhHolesMarkFRightdn, new makerjs.paths.Line([0,0], [d1, -d1]));//直径标线
    makerjs.model.addCaption(myKhHolesMarkFRightdn, TxtD1, [0,0], [d1+markHolesOneD, -d1-markHolesOneD]);//直径文字标注
    /*****************************************************************************/   
    makerjs.model.addPath(myKhHolesYMarkFRightdn, new makerjs.paths.Line([-w-d2-markShiftTwo,0], [-w-d2-markShiftTwo, h]));//Y短标线
    makerjs.model.addCaption(myKhHolesYMarkFRightdn, TxtH, [-w-d2-markShiftFour,0], [-w-d2-markShiftFour, h]);//Y文字标注
    /*****************************************************************************/ 
    plate.models["myKhHolesFRightup"+myHolesNum]=myKhHolesFRightup;
    markers.models["myKhHolesFRightup"+myHolesNum]=myKhHolesFRightup;
    markers.models["myKhHolesMarkFRightup"+myHolesNum]=myKhHolesMarkFRightup;
    markers.models["myKhHolesXMarkFRightup"+myHolesNum]=myKhHolesXMarkFRightup;
    /*****************************************************************************/
    plate.models["myKhHolesFRightdn"+myHolesNum]=myKhHolesFRightdn;
    markers.models["myKhHolesFRightdn"+myHolesNum]=myKhHolesFRightdn;
    markers.models["myKhHolesMarkFRightdn"+myHolesNum]=myKhHolesMarkFRightdn;
    markers.models["myKhHolesYMarkFRightdn"+myHolesNum]=myKhHolesYMarkFRightdn;
    /*****************************************************************************/ 
    plate.models["myKhHolesFLeftmd"+myHolesNum]=myKhHolesFLeftmd;
    markers.models["myKhHolesFLeftmd"+myHolesNum]=myKhHolesFLeftmd;
    markers.models["myKhHolesMarkFLeftmd"+myHolesNum]=myKhHolesMarkFLeftmd;
    markers.models["myKhHolesXMarkFLeftmd"+myHolesNum]=myKhHolesXMarkFLeftmd;
    markers.models["myKhHolesYMarkFLeftmd"+myHolesNum]=myKhHolesYMarkFLeftmd;
    /*****************************************************************************/ 
    /*****************************************************************************/ 
    }
/*****************************************************************************/ 

myHolesNum++;
return{plate, markers,myHolesNum}
};


export default KeyHole;