//18、三孔3
import makerjs from "makerjs";
// inputParam,model,myHolesNum,outParam,fixedParam
// (D,X,Y,area,plate,markers,myHolesNum,glassW,glassH,markShift,markShiftTwo,markShiftThree,markShiftFour,markHolesOneD)
function ThreeHoles3(inputParam,model,myHolesNum,outParam,fixedParam,zoom){
var D=inputParam.D,W=inputParam.W,H=inputParam.H,X=inputParam.X,Y=inputParam.Y,area=inputParam.area,myZoom=zoom*1,
plate=model.plate,markers=model.markers,
glassW=outParam.glassW,glassH=outParam.glassH,
   markShift=fixedParam.markShift,markShiftTwo=fixedParam.markShiftTwo,
   markShiftThree=fixedParam.markShiftThree,markShiftFour=fixedParam.markShiftFour,markHolesOneD=fixedParam.markHolesOneD,
 
   d = D*1*myZoom,
      w= W*1*myZoom,
      h= H*1*myZoom,
        x0 = X*1*myZoom,
        y0 = Y*1*myZoom;
        /**
         
   d = D*1,
      w= W*1,
      h= H*1,
        x0 = X*1,
        y0 = Y*1;
         */
       if(d!==0){
         var TxtD="∮"+D;
        }
        if(h!==0){
         var TxtH=H;
        }
        if(w!==0){
         var TxtW=W;
        }
        if(x0!==0){          
        var TxtX=X;
        }
        if(y0!==0){          
         var TxtY=Y;
        }
  //在A区域 计算孔的位置       
  var xa=x0,ya=glassH * 1-y0,
  //B区域//只有Y标注没有X标注 
  xb=glassW * 1/2,yb=glassH * 1-y0,
  //C区域
  xc=glassW * 1-x0,yc=glassH * 1-y0,
  //D区域
  //只有X标注没有Y标注 
  xd=x0,yd=glassH * 1/2,
  //E区域
  //只有X标注没有Y标注 
  xe=glassW * 1/2,ye=glassH * 1/2,
  //F区域
  //只有X标注没有Y标注 
  xf=glassW * 1-x0,yf=glassH * 1/2,
  //在G区域 计算孔的位置
  xg=x0,yg=y0,
  //在H区域 计算孔的位置
  //只标注Y
  xh=glassW*1/2,yh=y0,
  //在I区域 计算孔的位置
  xi=glassW*1-x0,yi=y0;
  if(area==1){
    //画孔 A位置孔
          //标记三孔3 A位置孔右上
          var myThHoles3ARightup= new makerjs.models.Holes(d/2, [[xa+w, ya]]);
          var myThHoles3MarkARightup = new makerjs.models.Square(0); //孔的原点       
          myThHoles3MarkARightup.origin = [xa+w, ya];      
          var myThHoles3XMarkARightup = new makerjs.models.Square(0);      
          myThHoles3XMarkARightup.origin =[xa+w, ya];   
    /*****************************************************************************/ 
    makerjs.model.addPath(myThHoles3MarkARightup, new makerjs.paths.Line([0,0], [d+markShiftFour, 0]));//从孔圆心出发X方向横向标线
    makerjs.model.addPath(myThHoles3MarkARightup, new makerjs.paths.Line([0,0], [0, y0+markShiftFour]));//从孔圆心出发Y方向竖向标线
    /*****************************************************************************/ 
    makerjs.model.addPath(myThHoles3MarkARightup, new makerjs.paths.Line([0,0], [d, -d]));//直径标线
    makerjs.model.addCaption(myThHoles3MarkARightup, TxtD, [0,0], [d+markHolesOneD, -d-markHolesOneD]);//直径文字标注
    /*****************************************************************************/   
    makerjs.model.addPath(myThHoles3XMarkARightup, new makerjs.paths.Line([0,y0+markShiftTwo], [-w, y0+markShiftTwo]));//X短标线
    makerjs.model.addCaption(myThHoles3XMarkARightup, TxtW, [-w,y0+markShiftFour], [0, y0+markShiftFour]);//X文字标注
    /*****************************************************************************/  
    /*****************************************************************************/ 
     //标记三孔3 A位置孔左下
     var myThHoles3ALeftdn= new makerjs.models.Holes(d/2, [[xa, ya-h]]);
          var myThHoles3MarkALeftdn = new makerjs.models.Square(0); //孔的原点       
          myThHoles3MarkALeftdn.origin = [xa, ya-h];       
          var myThHoles3XMarkALeftdn = new makerjs.models.Square(0);      
          myThHoles3XMarkALeftdn.origin = [xa, ya-h];       
          var myThHoles3YMarkALeftdn = new makerjs.models.Square(0);      
          myThHoles3YMarkALeftdn.origin = [xa, ya-h];  
    /*****************************************************************************/ 
    makerjs.model.addPath(myThHoles3MarkALeftdn, new makerjs.paths.Line([-x0,y0+h], [-x0, y0+h+markShiftFour]));//Y轴方向标注加长线
    makerjs.model.addPath(myThHoles3MarkALeftdn, new makerjs.paths.Line([0,0], [w, 0]));//从孔圆心出发X方向横向标线
    makerjs.model.addPath(myThHoles3MarkALeftdn, new makerjs.paths.Line([0,0], [-x0, 0]));//从孔圆心出发X方向横向标线
    makerjs.model.addPath(myThHoles3MarkALeftdn, new makerjs.paths.Line([0,0], [0, y0+h+markShiftFour]));//从孔圆心出发Y方向竖向标线
    /*****************************************************************************/ 
    makerjs.model.addPath(myThHoles3MarkALeftdn, new makerjs.paths.Line([0,0], [d, -d]));//直径标线
    makerjs.model.addCaption(myThHoles3MarkALeftdn, TxtD, [0,0], [d+markHolesOneD, -d-markHolesOneD]);//直径文字标注
    /*****************************************************************************/  
    makerjs.model.addPath(myThHoles3XMarkALeftdn, new makerjs.paths.Line([-x0,y0+h+markShiftTwo], [0, y0+h+markShiftTwo]));//X短标线
    makerjs.model.addCaption(myThHoles3XMarkALeftdn, TxtX, [-x0,y0+h+markShiftFour], [0, y0+h+markShiftFour]);//X文字标注
    /*****************************************************************************/ 
    makerjs.model.addPath(myThHoles3YMarkALeftdn, new makerjs.paths.Line([w+d+markShiftTwo,0], [w+d+markShiftTwo, h]));//Y短标线
    makerjs.model.addCaption(myThHoles3YMarkALeftdn, TxtH, [w+d+markShiftFour,0], [w+d+markShiftFour, h]);//Y文字标注
    /*****************************************************************************/ 
    /*****************************************************************************/ 
     //标记三孔3 A位置孔右下
     var myThHoles3ARightdn= new makerjs.models.Holes(d/2, [[xa+w, ya-h]]);
          var myThHoles3MarkARightdn = new makerjs.models.Square(0); //孔的原点       
          myThHoles3MarkARightdn.origin = [xa+w, ya-h];      
          var myThHoles3XMarkARightdn = new makerjs.models.Square(0);      
          myThHoles3XMarkARightdn.origin =[xa+w, ya-h];      
          var myThHoles3YMarkARightdn = new makerjs.models.Square(0);      
          myThHoles3YMarkARightdn.origin = [xa+w, ya-h]; 
    /*****************************************************************************/ 
    makerjs.model.addPath(myThHoles3MarkARightdn, new makerjs.paths.Line([0,0], [d+markShiftFour, 0]));//从孔圆心出发X方向横向标线
    /*****************************************************************************/ 
    makerjs.model.addPath(myThHoles3MarkARightdn, new makerjs.paths.Line([0,0], [d, -d]));//直径标线
    makerjs.model.addCaption(myThHoles3MarkARightdn, TxtD, [0,0], [d+markHolesOneD, -d-markHolesOneD]);//直径文字标注
    /*****************************************************************************/  
    /*****************************************************************************/
    plate.models["myThHoles3ALeftdn"+myHolesNum]=myThHoles3ALeftdn;
    markers.models["myThHoles3ALeftdn"+myHolesNum]=myThHoles3ALeftdn;
    markers.models["myThHoles3MarkALeftdn"+myHolesNum]=myThHoles3MarkALeftdn;
    markers.models["myThHoles3XMarkALeftdn"+myHolesNum]=myThHoles3XMarkALeftdn;
    markers.models["myThHoles3YMarkALeftdn"+myHolesNum]=myThHoles3YMarkALeftdn;
    /*****************************************************************************/ 
    plate.models["myThHoles3ARightup"+myHolesNum]=myThHoles3ARightup;
    markers.models["myThHoles3ARightup"+myHolesNum]=myThHoles3ARightup;
    markers.models["myThHoles3MarkARightup"+myHolesNum]=myThHoles3MarkARightup;
    markers.models["myThHoles3XMarkARightup"+myHolesNum]=myThHoles3XMarkARightup;
    /*****************************************************************************/ 
    plate.models["myThHoles3ARightdn"+myHolesNum]=myThHoles3ARightdn;
    markers.models["myThHoles3ARightdn"+myHolesNum]=myThHoles3ARightdn;
    markers.models["myThHoles3MarkARightdn"+myHolesNum]=myThHoles3MarkARightdn;
    /*****************************************************************************/ 
    /*****************************************************************************/ 
       
    }else if(area==4){
    //画孔D位置孔
          //标记三孔3 D位置孔右上
          var myThHoles3DRightup= new makerjs.models.Holes(d/2, [[xd+w, yd+h/2]]);
          var myThHoles3MarkDRightup = new makerjs.models.Square(0); //孔的原点       
          myThHoles3MarkDRightup.origin = [xd+w, yd+h/2];      
          var myThHoles3XMarkDRightup = new makerjs.models.Square(0);      
          myThHoles3XMarkDRightup.origin =[xd+w, yd+h/2];   
    /*****************************************************************************/ 
    makerjs.model.addPath(myThHoles3MarkDRightup, new makerjs.paths.Line([0,0], [d+markShiftFour, 0]));//从孔圆心出发X方向横向标线
    makerjs.model.addPath(myThHoles3MarkDRightup, new makerjs.paths.Line([0,0], [0, yd-h/2+markShiftFour]));//从孔圆心出发Y方向竖向标线
    /*****************************************************************************/ 
    makerjs.model.addPath(myThHoles3MarkDRightup, new makerjs.paths.Line([0,0], [d, -d]));//直径标线
    makerjs.model.addCaption(myThHoles3MarkDRightup, TxtD, [0,0], [d+markHolesOneD, -d-markHolesOneD]);//直径文字标注
    /*****************************************************************************/   
    makerjs.model.addPath(myThHoles3XMarkDRightup, new makerjs.paths.Line([0,yd-h/2+markShiftTwo], [-w, yd-h/2+markShiftTwo]));//X短标线
    makerjs.model.addCaption(myThHoles3XMarkDRightup, TxtW, [-w,yd-h/2+markShiftFour], [0, yd-h/2+markShiftFour]);//X文字标注
    /*****************************************************************************/  
    /*****************************************************************************/ 
     //标记三孔3D位置孔左上
     var myThHoles3DLeftup= new makerjs.models.Holes(d/2, [[xd, yd+h/2]]);
          var myThHoles3MarkDLeftup = new makerjs.models.Square(0); //孔的原点       
          myThHoles3MarkDLeftup.origin = [xd, yd+h/2];       
          var myThHoles3XMarkDLeftup = new makerjs.models.Square(0);      
          myThHoles3XMarkDLeftup.origin = [xd, yd+h/2];       
          var myThHoles3YMarkDLeftup = new makerjs.models.Square(0);      
          myThHoles3YMarkDLeftup.origin = [xd, yd+h/2];  
    /*****************************************************************************/ 
    makerjs.model.addPath(myThHoles3MarkDLeftup, new makerjs.paths.Line([-x0,yd-h/2], [-x0, yd-h/2+markShiftFour]));//Y轴方向标注加长线
    makerjs.model.addPath(myThHoles3MarkDLeftup, new makerjs.paths.Line([0,0], [w, 0]));//从孔圆心出发X方向横向标线
    makerjs.model.addPath(myThHoles3MarkDLeftup, new makerjs.paths.Line([0,0], [-x0, 0]));//从孔圆心出发X方向横向标线
    makerjs.model.addPath(myThHoles3MarkDLeftup, new makerjs.paths.Line([0,0], [0, yd-h/2+markShiftFour]));//从孔圆心出发Y方向竖向标线
    /*****************************************************************************/ 
    makerjs.model.addPath(myThHoles3MarkDLeftup, new makerjs.paths.Line([0,0], [d, -d]));//直径标线
    makerjs.model.addCaption(myThHoles3MarkDLeftup, TxtD, [0,0], [d+markHolesOneD, -d-markHolesOneD]);//直径文字标注
    /*****************************************************************************/  
    makerjs.model.addPath(myThHoles3XMarkDLeftup, new makerjs.paths.Line([-x0,yd-h/2+markShiftTwo], [0, yd-h/2+markShiftTwo]));//X短标线
    makerjs.model.addCaption(myThHoles3XMarkDLeftup, TxtX, [-x0,yd-h/2+markShiftFour], [0, yd-h/2+markShiftFour]);//X文字标注
    /*****************************************************************************/ 
    makerjs.model.addPath(myThHoles3YMarkDLeftup, new makerjs.paths.Line([w+d+markShiftTwo,0], [w+d+markShiftTwo, -h]));//Y短标线
    makerjs.model.addCaption(myThHoles3YMarkDLeftup, TxtH, [w+d+markShiftFour, -h], [w+d+markShiftFour,0]);//Y文字标注
    /*****************************************************************************/ 
    /*****************************************************************************/ 
     //标记三孔3 D位置孔右下
     var myThHoles3DRightdn= new makerjs.models.Holes(d/2, [[xd+w, yd-h/2]]);
          var myThHoles3MarkDRightdn = new makerjs.models.Square(0); //孔的原点       
          myThHoles3MarkDRightdn.origin = [xd+w, yd-h/2];      
          var myThHoles3XMarkDRightdn = new makerjs.models.Square(0);      
          myThHoles3XMarkDRightdn.origin =[xd+w, yd-h/2];      
          var myThHoles3YMarkDRightdn = new makerjs.models.Square(0);      
          myThHoles3YMarkDRightdn.origin = [xd+w, yd-h/2]; 
    /*****************************************************************************/ 
    makerjs.model.addPath(myThHoles3MarkDRightdn, new makerjs.paths.Line([0,0], [d+markShiftFour, 0]));//从孔圆心出发X方向横向标线
    /*****************************************************************************/ 
    makerjs.model.addPath(myThHoles3MarkDRightdn, new makerjs.paths.Line([0,0], [d, -d]));//直径标线
    makerjs.model.addCaption(myThHoles3MarkDRightdn, TxtD, [0,0], [d+markHolesOneD, -d-markHolesOneD]);//直径文字标注
    /*****************************************************************************/  
    /*****************************************************************************/
    plate.models["myThHoles3DLeftup"+myHolesNum]=myThHoles3DLeftup;
    markers.models["myThHoles3DLeftup"+myHolesNum]=myThHoles3DLeftup;
    markers.models["myThHoles3MarkDLeftup"+myHolesNum]=myThHoles3MarkDLeftup;
    markers.models["myThHoles3XMarkDLeftup"+myHolesNum]=myThHoles3XMarkDLeftup;
    markers.models["myThHoles3YMarkDLeftup"+myHolesNum]=myThHoles3YMarkDLeftup;
    /*****************************************************************************/ 
    plate.models["myThHoles3DRightup"+myHolesNum]=myThHoles3DRightup;
    markers.models["myThHoles3DRightup"+myHolesNum]=myThHoles3DRightup;
    markers.models["myThHoles3MarkDRightup"+myHolesNum]=myThHoles3MarkDRightup;
    markers.models["myThHoles3XMarkDRightup"+myHolesNum]=myThHoles3XMarkDRightup;
    /*****************************************************************************/ 
    plate.models["myThHoles3DRightdn"+myHolesNum]=myThHoles3DRightdn;
    markers.models["myThHoles3DRightdn"+myHolesNum]=myThHoles3DRightdn;
    markers.models["myThHoles3MarkDRightdn"+myHolesNum]=myThHoles3MarkDRightdn;
    /*****************************************************************************/ 
    /*****************************************************************************/ 
     
    }else if(area==6){
    //画孔F位置孔
          //标记三孔3 F位置孔左上
          var myThHoles3FLeftup= new makerjs.models.Holes(d/2, [[xf-w, yf+h/2]]);
          var myThHoles3MarkFLeftup = new makerjs.models.Square(0); //孔的原点       
          myThHoles3MarkFLeftup.origin = [xf-w, yf+h/2];      
          var myThHoles3XMarkFLeftup = new makerjs.models.Square(0);      
          myThHoles3XMarkFLeftup.origin =[xf-w, yf+h/2];   
    /*****************************************************************************/ 
    makerjs.model.addPath(myThHoles3MarkFLeftup, new makerjs.paths.Line([0,0], [-d-markShiftFour, 0]));//从孔圆心出发X方向横向标线
    makerjs.model.addPath(myThHoles3MarkFLeftup, new makerjs.paths.Line([0,0], [0, yd-h/2+markShiftFour]));//从孔圆心出发Y方向竖向标线
    /*****************************************************************************/ 
    makerjs.model.addPath(myThHoles3MarkFLeftup, new makerjs.paths.Line([0,0], [d, -d]));//直径标线
    makerjs.model.addCaption(myThHoles3MarkFLeftup, TxtD, [0,0], [d+markHolesOneD, -d-markHolesOneD]);//直径文字标注
    /*****************************************************************************/   
    makerjs.model.addPath(myThHoles3XMarkFLeftup, new makerjs.paths.Line([0,yd-h/2+markShiftTwo], [w, yd-h/2+markShiftTwo]));//X短标线
    makerjs.model.addCaption(myThHoles3XMarkFLeftup, TxtW, [0, yd-h/2+markShiftFour], [w,yd-h/2+markShiftFour]);//X文字标注
    /*****************************************************************************/  
    /*****************************************************************************/ 
     //标记三孔3F位置孔右上
     var myThHoles3FRightup= new makerjs.models.Holes(d/2, [[xf, yf+h/2]]);
          var myThHoles3MarkFRightup = new makerjs.models.Square(0); //孔的原点       
          myThHoles3MarkFRightup.origin = [xf, yf+h/2];       
          var myThHoles3XMarkFRightup = new makerjs.models.Square(0);      
          myThHoles3XMarkFRightup.origin = [xf, yf+h/2];       
          var myThHoles3YMarkFRightup = new makerjs.models.Square(0);      
          myThHoles3YMarkFRightup.origin = [xf, yf+h/2];  
    /*****************************************************************************/ 
    makerjs.model.addPath(myThHoles3MarkFRightup, new makerjs.paths.Line([x0,yd-h/2], [x0, yd-h/2+markShiftFour]));//Y轴方向标注加长线
    makerjs.model.addPath(myThHoles3MarkFRightup, new makerjs.paths.Line([0,0], [-w, 0]));//从孔圆心出发X方向横向标线
    makerjs.model.addPath(myThHoles3MarkFRightup, new makerjs.paths.Line([0,0], [x0, 0]));//从孔圆心出发X方向横向标线
    makerjs.model.addPath(myThHoles3MarkFRightup, new makerjs.paths.Line([0,0], [0, yd-h/2+markShiftFour]));//从孔圆心出发Y方向竖向标线
    /*****************************************************************************/ 
    makerjs.model.addPath(myThHoles3MarkFRightup, new makerjs.paths.Line([0,0], [d, -d]));//直径标线
    makerjs.model.addCaption(myThHoles3MarkFRightup, TxtD, [0,0], [d+markHolesOneD, -d-markHolesOneD]);//直径文字标注
    /*****************************************************************************/  
    makerjs.model.addPath(myThHoles3XMarkFRightup, new makerjs.paths.Line([x0,yd-h/2+markShiftTwo], [0, yd-h/2+markShiftTwo]));//X短标线
    makerjs.model.addCaption(myThHoles3XMarkFRightup, TxtX, [0, yd-h/2+markShiftFour], [x0,yd-h/2+markShiftFour]);//X文字标注
    /*****************************************************************************/ 
    makerjs.model.addPath(myThHoles3YMarkFRightup, new makerjs.paths.Line([-w-d-markShiftTwo,0], [-w-d-markShiftTwo, -h]));//Y短标线
    makerjs.model.addCaption(myThHoles3YMarkFRightup, TxtH, [-w-d-markShiftFour, -h], [-w-d-markShiftFour,0]);//Y文字标注
    /*****************************************************************************/ 
    /*****************************************************************************/ 
     //标记三孔3 F位置孔左下
     var myThHoles3FLeftdn= new makerjs.models.Holes(d/2, [[xf-w, yf-h/2]]);
          var myThHoles3MarkFLeftdn = new makerjs.models.Square(0); //孔的原点       
          myThHoles3MarkFLeftdn.origin = [xf-w, yf-h/2];      
          var myThHoles3XMarkFLeftdn = new makerjs.models.Square(0);      
          myThHoles3XMarkFLeftdn.origin =[xf-w, yf-h/2];      
          var myThHoles3YMarkFLeftdn = new makerjs.models.Square(0);      
          myThHoles3YMarkFLeftdn.origin = [xf-w, yf-h/2]; 
    /*****************************************************************************/ 
    makerjs.model.addPath(myThHoles3MarkFLeftdn, new makerjs.paths.Line([0,0], [-d-markShiftFour, 0]));//从孔圆心出发X方向横向标线
    /*****************************************************************************/ 
    makerjs.model.addPath(myThHoles3MarkFLeftdn, new makerjs.paths.Line([0,0], [d, -d]));//直径标线
    makerjs.model.addCaption(myThHoles3MarkFLeftdn, TxtD, [0,0], [d+markHolesOneD, -d-markHolesOneD]);//直径文字标注
    /*****************************************************************************/  
    /*****************************************************************************/
    plate.models["myThHoles3FRightup"+myHolesNum]=myThHoles3FRightup;
    markers.models["myThHoles3FRightup"+myHolesNum]=myThHoles3FRightup;
    markers.models["myThHoles3MarkFRightup"+myHolesNum]=myThHoles3MarkFRightup;
    markers.models["myThHoles3XMarkFRightup"+myHolesNum]=myThHoles3XMarkFRightup;
    markers.models["myThHoles3YMarkFRightup"+myHolesNum]=myThHoles3YMarkFRightup;
    /*****************************************************************************/ 
    plate.models["myThHoles3FLeftup"+myHolesNum]=myThHoles3FLeftup;
    markers.models["myThHoles3FLeftup"+myHolesNum]=myThHoles3FLeftup;
    markers.models["myThHoles3MarkFLeftup"+myHolesNum]=myThHoles3MarkFLeftup;
    markers.models["myThHoles3XMarkFLeftup"+myHolesNum]=myThHoles3XMarkFLeftup;
    /*****************************************************************************/ 
    plate.models["myThHoles3FLeftdn"+myHolesNum]=myThHoles3FLeftdn;
    markers.models["myThHoles3FLeftdn"+myHolesNum]=myThHoles3FLeftdn;
    markers.models["myThHoles3MarkFLeftdn"+myHolesNum]=myThHoles3MarkFLeftdn;
    /*****************************************************************************/ 
    /*****************************************************************************/ 
   
    }
/*****************************************************************************/ 

myHolesNum++;
return{plate, markers,myHolesNum}
};


export default ThreeHoles3;