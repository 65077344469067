//10、拉手孔 
import makerjs from "makerjs";
// inputParam,model,this.myHolesNum,outParam,fixedParam
// (D,X,Y,area,plate,markers,myHolesNum,glassW,glassH,markShift,markShiftTwo,markShiftThree,markShiftFour,markHolesOneD)
function HandleHole(inputParam,model,myHolesNum,outParam,fixedParam,zoom){
var D=inputParam.D,W=inputParam.W,H1=inputParam.H1,H2=inputParam.H2,X=inputParam.X,Y=inputParam.Y,area=inputParam.area,myZoom=zoom*1,
plate=model.plate,markers=model.markers,
glassW=outParam.glassW,glassH=outParam.glassH,
   markShift=fixedParam.markShift,markShiftTwo=fixedParam.markShiftTwo,
   markShiftThree=fixedParam.markShiftThree,markShiftFour=fixedParam.markShiftFour,markHolesOneD=fixedParam.markHolesOneD,
 
   d = D*1*myZoom,
        w = W*1*myZoom,
        y0 = Y*1*myZoom,
        x0=X*1*myZoom,        
  xdis=(glassW * 1/2-w/2),
  ydis=(glassH * 1/2-w/2); 
        /*  
        d = D*1,
       xdis=(glassW * 1/2-W*1/2),
       ydis=(glassH * 1/2-W*1/2),
             w = W*1,
             y0 = Y*1,
             x0=X*1; 
             */    
        if(d!==0){
         var TxtD="∮"+D;
        }
        if(w!==0){          
        var TxtW=W;
        }
        if(x0!==0){          
        var TxtX=X;
        }
        if(y0!==0){          
         var TxtY=Y;
        }
  //在A区域 计算孔的位置       
  var xa=x0,ya=glassH * 1-y0,
  //B区域//只有Y标注没有X标注 
  xb=glassW * 1/2,yb=glassH * 1-y0,
  //C区域
  xc=glassW * 1-x0,yc=glassH * 1-y0,
  //D区域
  //只有X标注没有Y标注 
  xd=x0,yd=glassH * 1/2,
  //E区域
  //只有X标注没有Y标注 
  xe=glassW * 1/2,ye=glassH * 1/2,
  //F区域
  //只有X标注没有Y标注 
  xf=glassW * 1-x0,yf=glassH * 1/2,
  //在G区域 计算孔的位置
  xg=x0,yg=y0,
  //在H区域 计算孔的位置
  //只标注Y
  xh=glassW*1/2,yh=y0,
  //在I区域 计算孔的位置
  xi=glassW*1-x0,yi=y0;
  if(area==2){
    //画孔 B位置孔
        //标记拉手孔 B位置孔左
        var myHhHolesBLeft= new makerjs.models.Holes(d/2, [[xb-w/2, yb]]);
        //标记孔
        var myHhHolesMarkBLeft = new makerjs.models.Square(0); //孔的原点       
        myHhHolesMarkBLeft.origin = [xb-w/2, yb];      
        var myHhHolesXMarkBLeft = new makerjs.models.Square(0);      
        myHhHolesXMarkBLeft.origin =[xb-w/2, yb];      
        var myHhHolesYMarkBLeft = new makerjs.models.Square(0);      
        myHhHolesYMarkBLeft.origin = [xb-w/2, yb];  
  /*****************************************************************************/ 
  makerjs.model.addPath(myHhHolesMarkBLeft, new makerjs.paths.Line([0,0], [-xdis-markShiftThree, 0]));//从孔圆心出发X方向横向标线
  makerjs.model.addPath(myHhHolesMarkBLeft, new makerjs.paths.Line([0-xdis,y0], [0-xdis-markShiftThree, y0]));//X轴加长标线
  makerjs.model.addPath(myHhHolesMarkBLeft, new makerjs.paths.Line([0,0], [0, y0+markShiftTwo]));//从孔圆心出发Y方向竖向标线
  /*****************************************************************************/ 
  makerjs.model.addPath(myHhHolesMarkBLeft, new makerjs.paths.Line([0,0], [d, -d]));//直径标线
  makerjs.model.addCaption(myHhHolesMarkBLeft, TxtD, [0,0], [d+markHolesOneD, -d-markHolesOneD]);//直径文字标注
  /*****************************************************************************/  
  makerjs.model.addPath(myHhHolesYMarkBLeft, new makerjs.paths.Line([-xdis-markShiftTwo,0], [-xdis-markShiftTwo, y0]));//Y短标线
  makerjs.model.addCaption(myHhHolesYMarkBLeft, TxtY, [-xdis-markShiftThree,0], [-xdis-markShiftThree, y0]);//Y文字标注
  /*****************************************************************************/ 
  /*****************************************************************************/ 
   //画孔 B位置孔
        //标记拉手孔 B位置孔右
        var myHhHolesBRight= new makerjs.models.Holes(d/2, [[xb+w/2, yb]]);
        var myHhHolesMarkBRight = new makerjs.models.Square(0); //孔的原点       
        myHhHolesMarkBRight.origin = [xb+w/2, yb];      
        var myHhHolesXMarkBRight = new makerjs.models.Square(0);      
        myHhHolesXMarkBRight.origin =[xb+w/2, yb]; 
  /*****************************************************************************/ 
  makerjs.model.addPath(myHhHolesMarkBRight, new makerjs.paths.Line([0,0], [-w-markShiftThree, 0]));//从孔圆心出发X方向横向标线
  makerjs.model.addPath(myHhHolesMarkBRight, new makerjs.paths.Line([0,0], [0, y0+markShiftTwo]));//从孔圆心出发Y方向竖向标线
  /*****************************************************************************/ 
  makerjs.model.addPath(myHhHolesMarkBRight, new makerjs.paths.Line([0,0], [d, -d]));//直径标线
  makerjs.model.addCaption(myHhHolesMarkBRight, TxtD, [0,0], [d+markHolesOneD, -d-markHolesOneD]);//直径文字标注
  /*****************************************************************************/   
  makerjs.model.addPath(myHhHolesXMarkBRight, new makerjs.paths.Line([0,y0+markShift], [-w, y0+markShift]));//W短标线
  makerjs.model.addCaption(myHhHolesXMarkBRight, TxtW, [-w,y0+markShiftTwo], [0, y0+markShiftTwo]);//W文字标注
  /*****************************************************************************/  
  /*****************************************************************************/ /*****************************************************************************/ 
  plate.models["myHhHolesBLeft"+myHolesNum]=myHhHolesBLeft;
  markers.models["myHhHolesBLeft"+myHolesNum]=myHhHolesBLeft;
  markers.models["myHhHolesMarkBLeft"+myHolesNum]=myHhHolesMarkBLeft;
  markers.models["myHhHolesXMarkBLeft"+myHolesNum]=myHhHolesXMarkBLeft;
  markers.models["myHhHolesYMarkBLeft"+myHolesNum]=myHhHolesYMarkBLeft;
  /*****************************************************************************/
  plate.models["myHhHolesBRight"+myHolesNum]=myHhHolesBRight;
  markers.models["myHhHolesBRight"+myHolesNum]=myHhHolesBRight;
  markers.models["myHhHolesMarkBRight"+myHolesNum]=myHhHolesMarkBRight;
  markers.models["myHhHolesXMarkBRight"+myHolesNum]=myHhHolesXMarkBRight;
  /*****************************************************************************/ 
  
  }
  else if(area==4){
    //画孔 D位置孔
        //标记拉手孔 D位置孔上
        var myHhHolesDup= new makerjs.models.Holes(d/2, [[xd, yd+w/2]]);
        //标记孔
        var myHhHolesMarkDup = new makerjs.models.Square(0); //孔的原点       
        myHhHolesMarkDup.origin = [xd, yd+w/2];      
        var myHhHolesXMarkDup = new makerjs.models.Square(0);      
        myHhHolesXMarkDup.origin =[xd, yd+w/2];      
        var myHhHolesYMarkDup = new makerjs.models.Square(0);      
        myHhHolesYMarkDup.origin = [xd, yd+w/2];  
  /*****************************************************************************/ 
  makerjs.model.addPath(myHhHolesMarkDup, new makerjs.paths.Line([0,0], [0+d+markShiftFour, 0]));//从孔圆心出发X方向横向标线
  makerjs.model.addPath(myHhHolesMarkDup, new makerjs.paths.Line([0-x0,ydis], [0-x0, ydis+markShiftThree]));//Y轴加长标线
  makerjs.model.addPath(myHhHolesMarkDup, new makerjs.paths.Line([0,0], [0, ydis+markShiftTwo]));//从孔圆心出发Y方向竖向标线
  /*****************************************************************************/ 
  makerjs.model.addPath(myHhHolesMarkDup, new makerjs.paths.Line([0,0], [d, -d]));//直径标线
  makerjs.model.addCaption(myHhHolesMarkDup, TxtD, [0,0], [d+markHolesOneD, -d-markHolesOneD]);//直径文字标注
  /*****************************************************************************/  
  makerjs.model.addPath(myHhHolesYMarkDup, new makerjs.paths.Line([0-x0,ydis+markShift], [0,ydis+markShift]));//X短标线
  makerjs.model.addCaption(myHhHolesYMarkDup, TxtX, [0-x0,ydis+markShiftFour], [0,ydis+markShiftFour]);//X文字标注
  /*****************************************************************************/ 
  /*****************************************************************************/ 
   //画孔 D位置孔
        //标记拉手孔 D位置孔下
        var myHhHolesDdn= new makerjs.models.Holes(d/2, [[xd, yd-w/2]]);
        var myHhHolesMarkDdn = new makerjs.models.Square(0); //孔的原点       
        myHhHolesMarkDdn.origin = [xd, yd-w/2];      
        var myHhHolesXMarkDdn = new makerjs.models.Square(0);      
        myHhHolesXMarkDdn.origin =[xd, yd-w/2]; 
  /*****************************************************************************/ 
  makerjs.model.addPath(myHhHolesMarkDdn, new makerjs.paths.Line([0,0], [d+markShiftFour, 0]));//从孔圆心出发X方向横向标线
  /*****************************************************************************/ 
  makerjs.model.addPath(myHhHolesMarkDdn, new makerjs.paths.Line([0,0], [d, -d]));//直径标线
  makerjs.model.addCaption(myHhHolesMarkDdn, TxtD, [0,0], [d+markHolesOneD, -d-markHolesOneD]);//直径文字标注
  /*****************************************************************************/   
  makerjs.model.addPath(myHhHolesXMarkDdn, new makerjs.paths.Line([0+d+markShiftTwo,w], [0+d+markShiftTwo, 0]));//W短标线
  makerjs.model.addCaption(myHhHolesXMarkDdn, TxtW, [0+d+markShiftFour, 0], [0+d+markShiftFour,w]);//W文字标注
  /*****************************************************************************/  
  /*****************************************************************************/ /*****************************************************************************/ 
  plate.models["myHhHolesDup"+myHolesNum]=myHhHolesDup;
  markers.models["myHhHolesDup"+myHolesNum]=myHhHolesDup;
  markers.models["myHhHolesMarkDup"+myHolesNum]=myHhHolesMarkDup;
  markers.models["myHhHolesXMarkDup"+myHolesNum]=myHhHolesXMarkDup;
  markers.models["myHhHolesYMarkDup"+myHolesNum]=myHhHolesYMarkDup;
  /*****************************************************************************/
  plate.models["myHhHolesDdn"+myHolesNum]=myHhHolesDdn;
  markers.models["myHhHolesDdn"+myHolesNum]=myHhHolesDdn;
  markers.models["myHhHolesMarkDdn"+myHolesNum]=myHhHolesMarkDdn;
  markers.models["myHhHolesXMarkDdn"+myHolesNum]=myHhHolesXMarkDdn;
  /*****************************************************************************/ 
 
  }
  else if(area==6){
    //画孔 F位置孔
        //标记拉手孔 F位置孔上
        var myHhHolesFup= new makerjs.models.Holes(d/2, [[xf, yf+w/2]]);
        //标记孔
        var myHhHolesMarkFup = new makerjs.models.Square(0); //孔的原点       
        myHhHolesMarkFup.origin = [xf, yf+w/2];      
        var myHhHolesXMarkFup = new makerjs.models.Square(0);      
        myHhHolesXMarkFup.origin =[xf, yf+w/2];      
        var myHhHolesYMarkFup = new makerjs.models.Square(0);      
        myHhHolesYMarkFup.origin = [xf, yf+w/2];  
  /*****************************************************************************/ 
  makerjs.model.addPath(myHhHolesMarkFup, new makerjs.paths.Line([0,0], [0+x0+markShiftFour, 0]));//从孔圆心出发X方向横向标线
  makerjs.model.addPath(myHhHolesMarkFup, new makerjs.paths.Line([0+x0,ydis], [0+x0, ydis+markShiftThree]));//Y轴加长标线
  makerjs.model.addPath(myHhHolesMarkFup, new makerjs.paths.Line([0,0], [0, ydis+markShiftTwo]));//从孔圆心出发Y方向竖向标线
  /*****************************************************************************/ 
  makerjs.model.addPath(myHhHolesMarkFup, new makerjs.paths.Line([0,0], [d, -d]));//直径标线
  makerjs.model.addCaption(myHhHolesMarkFup, TxtD, [0,0], [d+markHolesOneD, -d-markHolesOneD]);//直径文字标注
  /*****************************************************************************/  
  makerjs.model.addPath(myHhHolesYMarkFup, new makerjs.paths.Line([0+x0,ydis+markShift], [0,ydis+markShift]));//X短标线
  makerjs.model.addCaption(myHhHolesYMarkFup, TxtX, [0,ydis+markShiftFour], [0+x0,ydis+markShiftFour]);//X文字标注
  /*****************************************************************************/ 
  /*****************************************************************************/ 
   //画孔 F位置孔
        //标记拉手孔 F位置孔下
        var myHhHolesFdn= new makerjs.models.Holes(d/2, [[xf, yf-w/2]]);
        var myHhHolesMarkFdn = new makerjs.models.Square(0); //孔的原点       
        myHhHolesMarkFdn.origin = [xf, yf-w/2];      
        var myHhHolesXMarkFdn = new makerjs.models.Square(0);      
        myHhHolesXMarkFdn.origin =[xf, yf-w/2]; 
  /*****************************************************************************/ 
  makerjs.model.addPath(myHhHolesMarkFdn, new makerjs.paths.Line([0,0], [x0+markShiftFour, 0]));//从孔圆心出发X方向横向标线
  /*****************************************************************************/ 
  makerjs.model.addPath(myHhHolesMarkFdn, new makerjs.paths.Line([0,0], [d, -d]));//直径标线
  makerjs.model.addCaption(myHhHolesMarkFdn, TxtD, [0,0], [d+markHolesOneD, -d-markHolesOneD]);//直径文字标注
  /*****************************************************************************/   
  makerjs.model.addPath(myHhHolesXMarkFdn, new makerjs.paths.Line([0+x0+markShiftTwo,w], [0+x0+markShiftTwo, 0]));//W短标线
  makerjs.model.addCaption(myHhHolesXMarkFdn, TxtW, [0+x0+markShiftFour, 0], [0+x0+markShiftFour,w]);//W文字标注
  /*****************************************************************************/  
  /*****************************************************************************/ 
  /*****************************************************************************/ 
  plate.models["myHhHolesFup"+myHolesNum]=myHhHolesFup;
  markers.models["myHhHolesFup"+myHolesNum]=myHhHolesFup;
  markers.models["myHhHolesMarkFup"+myHolesNum]=myHhHolesMarkFup;
  markers.models["myHhHolesXMarkFup"+myHolesNum]=myHhHolesXMarkFup;
  markers.models["myHhHolesYMarkFup"+myHolesNum]=myHhHolesYMarkFup;
  /*****************************************************************************/
  plate.models["myHhHolesFdn"+myHolesNum]=myHhHolesFdn;
  markers.models["myHhHolesFdn"+myHolesNum]=myHhHolesFdn;
  markers.models["myHhHolesMarkFdn"+myHolesNum]=myHhHolesMarkFdn;
  markers.models["myHhHolesXMarkFdn"+myHolesNum]=myHhHolesXMarkFdn;
  /*****************************************************************************/ 
  
  }
  else if(area==8){
   //画孔 H位置孔
        //标记拉手孔 H位置孔左
        var myHhHolesHLeft= new makerjs.models.Holes(d/2, [[xh-w/2, yh]]);
        //标记孔
        var myHhHolesMarkHLeft = new makerjs.models.Square(0); //孔的原点       
        myHhHolesMarkHLeft.origin = [xh-w/2, yh];      
        var myHhHolesXMarkHLeft = new makerjs.models.Square(0);      
        myHhHolesXMarkHLeft.origin =[xh-w/2, yh];      
        var myHhHolesYMarkHLeft = new makerjs.models.Square(0);      
        myHhHolesYMarkHLeft.origin = [xh-w/2, yh];  
  /*****************************************************************************/ 
  makerjs.model.addPath(myHhHolesMarkHLeft, new makerjs.paths.Line([0,0], [w+xdis+markShiftThree, 0]));//从孔圆心出发X方向横向标线
  makerjs.model.addPath(myHhHolesMarkHLeft, new makerjs.paths.Line([0+w+xdis,-y0], [0+w+xdis+markShiftThree, -y0]));//X轴加长标线
  makerjs.model.addPath(myHhHolesMarkHLeft, new makerjs.paths.Line([0,0], [0, d+markShiftFour]));//从孔圆心出发Y方向竖向标线
  /*****************************************************************************/ 
  makerjs.model.addPath(myHhHolesMarkHLeft, new makerjs.paths.Line([0,0], [d, -d]));//直径标线
  makerjs.model.addCaption(myHhHolesMarkHLeft, TxtD, [0,0], [d+markHolesOneD, -d-markHolesOneD]);//直径文字标注
  /*****************************************************************************/  
  makerjs.model.addPath(myHhHolesYMarkHLeft, new makerjs.paths.Line([w+xdis+markShiftTwo,0], [w+xdis+markShiftTwo, -y0]));//Y短标线
  makerjs.model.addCaption(myHhHolesYMarkHLeft, TxtY, [w+xdis+markShiftThree,0], [w+xdis+markShiftThree, -y0]);//Y文字标注
  /*****************************************************************************/ 
  /*****************************************************************************/ 
   //画孔 H位置孔
        //标记拉手孔 H位置孔右
        var myHhHolesHRight= new makerjs.models.Holes(d/2, [[xh+w/2, yh]]);
        var myHhHolesMarkHRight = new makerjs.models.Square(0); //孔的原点       
        myHhHolesMarkHRight.origin = [xh+w/2, yh];      
        var myHhHolesXMarkHRight = new makerjs.models.Square(0);      
        myHhHolesXMarkHRight.origin =[xh+w/2, yh]; 
  /*****************************************************************************/ 
  makerjs.model.addPath(myHhHolesMarkHRight, new makerjs.paths.Line([0,0], [0, d+markShiftFour]));//从孔圆心出发Y方向竖向标线
  /*****************************************************************************/ 
  makerjs.model.addPath(myHhHolesMarkHRight, new makerjs.paths.Line([0,0], [d, -d]));//直径标线
  makerjs.model.addCaption(myHhHolesMarkHRight, TxtD, [0,0], [d+markHolesOneD, -d-markHolesOneD]);//直径文字标注
  /*****************************************************************************/   
  makerjs.model.addPath(myHhHolesXMarkHRight, new makerjs.paths.Line([0,d+markShift], [-w, d+markShift]));//W短标线
  makerjs.model.addCaption(myHhHolesXMarkHRight, TxtW, [-w,d+markShiftTwo], [0, d+markShiftTwo]);//W文字标注
  /*****************************************************************************/  
  /*****************************************************************************/ /*****************************************************************************/ 
  plate.models["myHhHolesHLeft"+myHolesNum]=myHhHolesHLeft;
  markers.models["myHhHolesHLeft"+myHolesNum]=myHhHolesHLeft;
  markers.models["myHhHolesMarkHLeft"+myHolesNum]=myHhHolesMarkHLeft;
  markers.models["myHhHolesXMarkHLeft"+myHolesNum]=myHhHolesXMarkHLeft;
  markers.models["myHhHolesYMarkHLeft"+myHolesNum]=myHhHolesYMarkHLeft;
  /*****************************************************************************/
  plate.models["myHhHolesHRight"+myHolesNum]=myHhHolesHRight;
  markers.models["myHhHolesHRight"+myHolesNum]=myHhHolesHRight;
  markers.models["myHhHolesMarkHRight"+myHolesNum]=myHhHolesMarkHRight;
  markers.models["myHhHolesXMarkHRight"+myHolesNum]=myHhHolesXMarkHRight;
  /*****************************************************************************/ 
  /*****************************************************************************/ 
 
  } 

myHolesNum++;
return{plate, markers,myHolesNum}
};


export default HandleHole;