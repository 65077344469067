//12、拉手孔3 
import makerjs from "makerjs";
// inputParam,model,this.myHolesNum,outParam,fixedParam
// (D,X,Y,area,plate,markers,myHolesNum,glassW,glassH,markShift,markShiftTwo,markShiftThree,markShiftFour,markHolesOneD)
function HandleHole3(inputParam,model,myHolesNum,outParam,fixedParam,zoom){
var D=inputParam.D,W=inputParam.W,H=inputParam.H,H2=inputParam.H2,X=inputParam.X,Y=inputParam.Y,area=inputParam.area,myZoom=zoom*1,
plate=model.plate,markers=model.markers,
glassW=outParam.glassW,glassH=outParam.glassH,
   markShift=fixedParam.markShift,markShiftTwo=fixedParam.markShiftTwo,
   markShiftThree=fixedParam.markShiftThree,markShiftFour=fixedParam.markShiftFour,markHolesOneD=fixedParam.markHolesOneD,
 
   d = D*1*myZoom,
      h= H*1*myZoom,
        x0 = X*1*myZoom,
        y0 = Y*1*myZoom;
        /*
        d = D*1,
           h= H*1,
             x0 = X*1,
             y0 = Y*1;
             */
        if(d!==0){
         var TxtD="∮"+D;
        }
        if(h!==0){
         var TxtH=H;
        }
        if(x0!==0){          
        var TxtX=X;
        }
        if(y0!==0){          
         var TxtY=Y;
        }
  //在A区域 计算孔的位置       
  var xa=x0,ya=glassH * 1-y0,
  //B区域//只有Y标注没有X标注 
  xb=glassW * 1/2,yb=glassH * 1-y0,
  //C区域
  xc=glassW * 1-x0,yc=glassH * 1-y0,
  //D区域
  //只有X标注没有Y标注 
  xd=x0,yd=glassH * 1/2,
  //E区域
  //只有X标注没有Y标注 
  xe=glassW * 1/2,ye=glassH * 1/2,
  //F区域
  //只有X标注没有Y标注 
  xf=glassW * 1-x0,yf=glassH * 1/2,
  //在G区域 计算孔的位置
  xg=x0,yg=y0,
  //在H区域 计算孔的位置
  //只标注Y
  xh=glassW*1/2,yh=y0,
  //在I区域 计算孔的位置
  xi=glassW*1-x0,yi=y0;
  if(area==1){
    //画孔 A位置孔
      //标记拉手孔3 A位置孔上
      var myHh3HolesAup= new makerjs.models.Holes(d/2, [[xa, ya]]);
      var myHh3HolesMarkAup = new makerjs.models.Square(0); //孔的原点       
      myHh3HolesMarkAup.origin = [xa, ya];      
      var myHh3HolesXMarkAup = new makerjs.models.Square(0);      
      myHh3HolesXMarkAup.origin =[xa, ya];      
      var myHh3HolesYMarkAup = new makerjs.models.Square(0);      
      myHh3HolesYMarkAup.origin = [xa, ya];  

/*****************************************************************************/ 
makerjs.model.addPath(myHh3HolesMarkAup, new makerjs.paths.Line([-x0,y0], [-x0, y0+markShiftTwo]));//Y轴加长标线
makerjs.model.addPath(myHh3HolesMarkAup, new makerjs.paths.Line([0,0], [-x0-markShiftThree, 0]));//从孔圆心出发X方向横向标线
makerjs.model.addPath(myHh3HolesMarkAup, new makerjs.paths.Line([-x0,y0], [-x0-markShiftThree, y0]));//X轴加长标线
makerjs.model.addPath(myHh3HolesMarkAup, new makerjs.paths.Line([0,0], [0, y0+markShiftTwo]));//从孔圆心出发Y方向竖向标线
/*****************************************************************************/ 
makerjs.model.addPath(myHh3HolesMarkAup, new makerjs.paths.Line([0,0], [d, -d]));//直径标线
makerjs.model.addCaption(myHh3HolesMarkAup, TxtD, [0,0], [d+markHolesOneD, -d-markHolesOneD]);//直径文字标注
/*****************************************************************************/   
makerjs.model.addPath(myHh3HolesXMarkAup, new makerjs.paths.Line([0,y0+markShift], [-x0, y0+markShift]));//X短标线
makerjs.model.addCaption(myHh3HolesXMarkAup, TxtX, [-x0,y0+markShiftTwo], [0, y0+markShiftTwo]);//X文字标注
/*****************************************************************************/     
makerjs.model.addPath(myHh3HolesYMarkAup, new makerjs.paths.Line([-x0-markShiftTwo,0], [-x0-markShiftTwo, y0]));//Y短标线
makerjs.model.addCaption(myHh3HolesYMarkAup, TxtY, [-x0-markShiftThree,0], [-x0-markShiftThree, y0]);//Y文字标注
/*****************************************************************************/ 
 //标记拉手孔3 A位置孔中
 var myHh3HolesAmd= new makerjs.models.Holes(d/2, [[xa, ya-h]]);
      var myHh3HolesMarkAmd = new makerjs.models.Square(0); //孔的原点       
      myHh3HolesMarkAmd.origin = [xa, ya-h];      
      var myHh3HolesYMarkAmd = new makerjs.models.Square(0);      
      myHh3HolesYMarkAmd.origin = [xa, ya-h];  
/*****************************************************************************/ 
makerjs.model.addPath(myHh3HolesMarkAmd, new makerjs.paths.Line([0,0], [-x0-markShiftThree, 0]));//从孔圆心出发X方向横向标线
makerjs.model.addPath(myHh3HolesMarkAmd, new makerjs.paths.Line([0,0], [0, h+markShiftTwo]));//从孔圆心出发Y方向竖向标线
/*****************************************************************************/ 
makerjs.model.addPath(myHh3HolesMarkAmd, new makerjs.paths.Line([0,0], [d, -d]));//直径标线
makerjs.model.addCaption(myHh3HolesMarkAmd, TxtD, [0,0], [d+markHolesOneD, -d-markHolesOneD]);//直径文字标注
/*****************************************************************************/   
makerjs.model.addPath(myHh3HolesYMarkAmd, new makerjs.paths.Line([-x0-markShiftTwo,0], [-x0-markShiftTwo, h]));//Y短标线
makerjs.model.addCaption(myHh3HolesYMarkAmd, TxtH, [-x0-markShiftThree,0], [-x0-markShiftThree, h]);//Y文字标注
/*****************************************************************************/ 
/*****************************************************************************/ 

 //标记拉手孔3 A位置孔下
 var myHh3HolesAdn= new makerjs.models.Holes(d/2, [[xa, ya-h-h]]);
      var myHh3HolesMarkAdn = new makerjs.models.Square(0); //孔的原点       
      myHh3HolesMarkAdn.origin = [xa, ya-h-h];       
      var myHh3HolesYMarkAdn = new makerjs.models.Square(0);      
      myHh3HolesYMarkAdn.origin = [xa, ya-h-h];  
/*****************************************************************************/ 
makerjs.model.addPath(myHh3HolesMarkAdn, new makerjs.paths.Line([0,0], [-x0-markShiftThree, 0]));//从孔圆心出发X方向横向标线
makerjs.model.addPath(myHh3HolesMarkAdn, new makerjs.paths.Line([0,0], [0, h+markShiftTwo]));//从孔圆心出发Y方向竖向标线
/*****************************************************************************/ 
makerjs.model.addPath(myHh3HolesMarkAdn, new makerjs.paths.Line([0,0], [d, -d]));//直径标线
makerjs.model.addCaption(myHh3HolesMarkAdn, TxtD, [0,0], [d+markHolesOneD, -d-markHolesOneD]);//直径文字标注
/*****************************************************************************/    
makerjs.model.addPath(myHh3HolesYMarkAdn, new makerjs.paths.Line([-x0-markShiftTwo,0], [-x0-markShiftTwo, h]));//Y短标线
makerjs.model.addCaption(myHh3HolesYMarkAdn, TxtH, [-x0-markShiftThree,0], [-x0-markShiftThree, h]);//Y文字标注
/*****************************************************************************/ 
/*****************************************************************************/
plate.models["myHh3HolesAup"+myHolesNum]=myHh3HolesAup;
markers.models["myHh3HolesAup"+myHolesNum]=myHh3HolesAup;
markers.models["myHh3HolesMarkAup"+myHolesNum]=myHh3HolesMarkAup;
markers.models["myHh3HolesXMarkAup"+myHolesNum]=myHh3HolesXMarkAup;
markers.models["myHh3HolesYMarkAup"+myHolesNum]=myHh3HolesYMarkAup;
/*****************************************************************************/
plate.models["myHh3HolesAmd"+myHolesNum]=myHh3HolesAmd;
markers.models["myHh3HolesAmd"+myHolesNum]=myHh3HolesAmd;
markers.models["myHh3HolesMarkAmd"+myHolesNum]=myHh3HolesMarkAmd;
markers.models["myHh3HolesYMarkAmd"+myHolesNum]=myHh3HolesYMarkAmd;
/*****************************************************************************/ 
plate.models["myHh3HolesAdn"+myHolesNum]=myHh3HolesAdn;
markers.models["myHh3HolesAdn"+myHolesNum]=myHh3HolesAdn;
markers.models["myHh3HolesMarkAdn"+myHolesNum]=myHh3HolesMarkAdn;
markers.models["myHh3HolesYMarkAdn"+myHolesNum]=myHh3HolesYMarkAdn;
/*****************************************************************************/ 

}
else if(area==3){
 //画孔 C位置孔
      //标记拉手孔3 C位置孔上
      var myHh3HolesCup= new makerjs.models.Holes(d/2, [[xc, yc]]);
      var myHh3HolesMarkCup = new makerjs.models.Square(0); //孔的原点       
      myHh3HolesMarkCup.origin = [xc, yc];      
      var myHh3HolesXMarkCup = new makerjs.models.Square(0);      
      myHh3HolesXMarkCup.origin =[xc, yc];      
      var myHh3HolesYMarkCup = new makerjs.models.Square(0);      
      myHh3HolesYMarkCup.origin = [xc, yc];  

/*****************************************************************************/ 
makerjs.model.addPath(myHh3HolesMarkCup, new makerjs.paths.Line([x0,y0], [x0, y0+markShiftTwo]));//Y轴加长标线
makerjs.model.addPath(myHh3HolesMarkCup, new makerjs.paths.Line([0,0], [x0+markShiftThree, 0]));//从孔圆心出发X方向横向标线
makerjs.model.addPath(myHh3HolesMarkCup, new makerjs.paths.Line([x0,y0], [x0+markShiftThree, y0]));//X轴加长标线
makerjs.model.addPath(myHh3HolesMarkCup, new makerjs.paths.Line([0,0], [0, y0+markShiftTwo]));//从孔圆心出发Y方向竖向标线
/*****************************************************************************/ 
makerjs.model.addPath(myHh3HolesMarkCup, new makerjs.paths.Line([0,0], [d, -d]));//直径标线
makerjs.model.addCaption(myHh3HolesMarkCup, TxtD, [0,0], [d+markHolesOneD, -d-markHolesOneD]);//直径文字标注
/*****************************************************************************/   
makerjs.model.addPath(myHh3HolesXMarkCup, new makerjs.paths.Line([0,y0+markShift], [x0, y0+markShift]));//X短标线
makerjs.model.addCaption(myHh3HolesXMarkCup, TxtX, [0, y0+markShiftThree], [x0,y0+markShiftThree]);//X文字标注
/*****************************************************************************/     
makerjs.model.addPath(myHh3HolesYMarkCup, new makerjs.paths.Line([x0+markShift,0], [x0+markShift, y0]));//Y短标线
makerjs.model.addCaption(myHh3HolesYMarkCup, TxtY, [x0+markShiftThree,0], [x0+markShiftThree, y0]);//Y文字标注
/*****************************************************************************/ 
 //标记拉手孔3 C位置孔中
 var myHh3HolesCmd= new makerjs.models.Holes(d/2, [[xc, yc-h]]);
      var myHh3HolesMarkCmd = new makerjs.models.Square(0); //孔的原点       
      myHh3HolesMarkCmd.origin = [xc, yc-h];      
      var myHh3HolesYMarkCmd = new makerjs.models.Square(0);      
      myHh3HolesYMarkCmd.origin = [xc, yc-h];  
/*****************************************************************************/ 
makerjs.model.addPath(myHh3HolesMarkCmd, new makerjs.paths.Line([0,0], [x0+markShiftThree, 0]));//从孔圆心出发X方向横向标线
makerjs.model.addPath(myHh3HolesMarkCmd, new makerjs.paths.Line([0,0], [0, h+markShiftTwo]));//从孔圆心出发Y方向竖向标线
/*****************************************************************************/ 
makerjs.model.addPath(myHh3HolesMarkCmd, new makerjs.paths.Line([0,0], [d, -d]));//直径标线
makerjs.model.addCaption(myHh3HolesMarkCmd, TxtD, [0,0], [d+markHolesOneD, -d-markHolesOneD]);//直径文字标注
/*****************************************************************************/   
makerjs.model.addPath(myHh3HolesYMarkCmd, new makerjs.paths.Line([x0+markShift,0], [x0+markShift, h]));//Y短标线
makerjs.model.addCaption(myHh3HolesYMarkCmd, TxtH, [x0+markShiftThree,0], [x0+markShiftThree, h]);//Y文字标注
/*****************************************************************************/ 
/*****************************************************************************/ 

 //标记拉手孔3 C位置孔下
 var myHh3HolesCdn= new makerjs.models.Holes(d/2, [[xc, yc-h-h]]);
      var myHh3HolesMarkCdn = new makerjs.models.Square(0); //孔的原点       
      myHh3HolesMarkCdn.origin = [xc, yc-h-h];       
      var myHh3HolesYMarkCdn = new makerjs.models.Square(0);      
      myHh3HolesYMarkCdn.origin = [xc, yc-h-h];  
/*****************************************************************************/ 
makerjs.model.addPath(myHh3HolesMarkCdn, new makerjs.paths.Line([0,0], [x0+markShiftThree, 0]));//从孔圆心出发X方向横向标线
makerjs.model.addPath(myHh3HolesMarkCdn, new makerjs.paths.Line([0,0], [0, h+markShiftTwo]));//从孔圆心出发Y方向竖向标线
/*****************************************************************************/ 
makerjs.model.addPath(myHh3HolesMarkCdn, new makerjs.paths.Line([0,0], [d, -d]));//直径标线
makerjs.model.addCaption(myHh3HolesMarkCdn, TxtD, [0,0], [d+markHolesOneD, -d-markHolesOneD]);//直径文字标注
/*****************************************************************************/    
makerjs.model.addPath(myHh3HolesYMarkCdn, new makerjs.paths.Line([x0+markShift,0], [x0+markShift, h]));//Y短标线
makerjs.model.addCaption(myHh3HolesYMarkCdn, TxtH, [x0+markShiftThree,0], [x0+markShiftThree, h]);//Y文字标注
/*****************************************************************************/ 
/*****************************************************************************/
plate.models["myHh3HolesCup"+myHolesNum]=myHh3HolesCup;
markers.models["myHh3HolesCup"+myHolesNum]=myHh3HolesCup;
markers.models["myHh3HolesMarkCup"+myHolesNum]=myHh3HolesMarkCup;
markers.models["myHh3HolesXMarkCup"+myHolesNum]=myHh3HolesXMarkCup;
markers.models["myHh3HolesYMarkCup"+myHolesNum]=myHh3HolesYMarkCup;
/*****************************************************************************/
plate.models["myHh3HolesCmd"+myHolesNum]=myHh3HolesCmd;
markers.models["myHh3HolesCmd"+myHolesNum]=myHh3HolesCmd;
markers.models["myHh3HolesMarkCmd"+myHolesNum]=myHh3HolesMarkCmd;
markers.models["myHh3HolesYMarkCmd"+myHolesNum]=myHh3HolesYMarkCmd;
/*****************************************************************************/ 
plate.models["myHh3HolesCdn"+myHolesNum]=myHh3HolesCdn;
markers.models["myHh3HolesCdn"+myHolesNum]=myHh3HolesCdn;
markers.models["myHh3HolesMarkCdn"+myHolesNum]=myHh3HolesMarkCdn;
markers.models["myHh3HolesYMarkCdn"+myHolesNum]=myHh3HolesYMarkCdn;
/*****************************************************************************/ 
 
}
else if(area==4){
 //画孔 D位置孔
      //标记拉手孔3 D位置孔上
      var myHh3HolesDup= new makerjs.models.Holes(d/2, [[xd, yd+h]]);
      var myHh3HolesMarkDup = new makerjs.models.Square(0); //孔的原点       
      myHh3HolesMarkDup.origin = [xd, yd+h];      
      var myHh3HolesXMarkDup = new makerjs.models.Square(0);      
      myHh3HolesXMarkDup.origin =[xd, yd+h];      
      var myHh3HolesYMarkDup = new makerjs.models.Square(0);      
      myHh3HolesYMarkDup.origin = [xd, yd+h];  

/*****************************************************************************/ 
makerjs.model.addPath(myHh3HolesMarkDup, new makerjs.paths.Line([-x0,yd-h], [-x0, yd-h+markShiftFour]));//Y轴加长标线
makerjs.model.addPath(myHh3HolesMarkDup, new makerjs.paths.Line([0,0], [-x0, 0]));//从孔圆心出发X方向横向标线
makerjs.model.addPath(myHh3HolesMarkDup, new makerjs.paths.Line([0,0], [d+markShiftFour, 0]));//从孔圆心出发X方向横向标线
// makerjs.model.addPath(myHh3HolesMarkDup, new makerjs.paths.Line([-x0,yd-h], [-x0-markShiftThree, yd-h]));//X轴加长标线
makerjs.model.addPath(myHh3HolesMarkDup, new makerjs.paths.Line([0,0], [0, yd-h+markShiftFour]));//从孔圆心出发Y方向竖向标线
/*****************************************************************************/ 
makerjs.model.addPath(myHh3HolesMarkDup, new makerjs.paths.Line([0,0], [d, -d]));//直径标线
makerjs.model.addCaption(myHh3HolesMarkDup, TxtD, [0,0], [d+markHolesOneD, -d-markHolesOneD]);//直径文字标注
/*****************************************************************************/   
makerjs.model.addPath(myHh3HolesXMarkDup, new makerjs.paths.Line([0,yd-h+markShift], [-x0, yd-h+markShift]));//X短标线
makerjs.model.addCaption(myHh3HolesXMarkDup, TxtX, [-x0,yd-h+markShiftThree], [0, yd-h+markShiftThree]);//X文字标注
/*****************************************************************************/  
 //标记拉手孔3 D位置孔中
 var myHh3HolesDmd= new makerjs.models.Holes(d/2, [[xd, yd]]);
      var myHh3HolesMarkDmd = new makerjs.models.Square(0); //孔的原点       
      myHh3HolesMarkDmd.origin = [xd, yd];      
      var myHh3HolesYMarkDmd = new makerjs.models.Square(0);      
      myHh3HolesYMarkDmd.origin = [xd, yd];  
/*****************************************************************************/ 
makerjs.model.addPath(myHh3HolesMarkDmd, new makerjs.paths.Line([0,0], [d+markShiftFour, 0]));//从孔圆心出发X方向横向标线
makerjs.model.addPath(myHh3HolesMarkDmd, new makerjs.paths.Line([0,0], [0, h+markShiftTwo]));//从孔圆心出发Y方向竖向标线
/*****************************************************************************/ 
makerjs.model.addPath(myHh3HolesMarkDmd, new makerjs.paths.Line([0,0], [d, -d]));//直径标线
makerjs.model.addCaption(myHh3HolesMarkDmd, TxtD, [0,0], [d+markHolesOneD, -d-markHolesOneD]);//直径文字标注
/*****************************************************************************/   
makerjs.model.addPath(myHh3HolesYMarkDmd, new makerjs.paths.Line([d+markShiftTwo,0], [d+markShiftTwo, h]));//Y短标线
makerjs.model.addCaption(myHh3HolesYMarkDmd, TxtH, [d+markShiftFour,0], [d+markShiftFour, h]);//Y文字标注
/*****************************************************************************/ 
/*****************************************************************************/ 

 //标记拉手孔3 D位置孔下
 var myHh3HolesDdn= new makerjs.models.Holes(d/2, [[xd, yd-h]]);
      var myHh3HolesMarkDdn = new makerjs.models.Square(0); //孔的原点       
      myHh3HolesMarkDdn.origin = [xd, yd-h];       
      var myHh3HolesYMarkDdn = new makerjs.models.Square(0);      
      myHh3HolesYMarkDdn.origin = [xd, yd-h];  
/*****************************************************************************/ 
makerjs.model.addPath(myHh3HolesMarkDdn, new makerjs.paths.Line([0,0], [d+markShiftFour, 0]));//从孔圆心出发X方向横向标线
makerjs.model.addPath(myHh3HolesMarkDdn, new makerjs.paths.Line([0,0], [0, h+markShiftTwo]));//从孔圆心出发Y方向竖向标线
/*****************************************************************************/ 
makerjs.model.addPath(myHh3HolesMarkDdn, new makerjs.paths.Line([0,0], [d, -d]));//直径标线
makerjs.model.addCaption(myHh3HolesMarkDdn, TxtD, [0,0], [d+markHolesOneD, -d-markHolesOneD]);//直径文字标注
/*****************************************************************************/    
makerjs.model.addPath(myHh3HolesYMarkDdn, new makerjs.paths.Line([d+markShiftTwo,0], [d+markShiftTwo, h]));//Y短标线
makerjs.model.addCaption(myHh3HolesYMarkDdn, TxtH, [d+markShiftFour,0], [d+markShiftFour, h]);//Y文字标注
/*****************************************************************************/ 
/*****************************************************************************/
plate.models["myHh3HolesDup"+myHolesNum]=myHh3HolesDup;
markers.models["myHh3HolesDup"+myHolesNum]=myHh3HolesDup;
markers.models["myHh3HolesMarkDup"+myHolesNum]=myHh3HolesMarkDup;
markers.models["myHh3HolesXMarkDup"+myHolesNum]=myHh3HolesXMarkDup;
markers.models["myHh3HolesYMarkDup"+myHolesNum]=myHh3HolesYMarkDup;
/*****************************************************************************/
plate.models["myHh3HolesDmd"+myHolesNum]=myHh3HolesDmd;
markers.models["myHh3HolesDmd"+myHolesNum]=myHh3HolesDmd;
markers.models["myHh3HolesMarkDmd"+myHolesNum]=myHh3HolesMarkDmd;
markers.models["myHh3HolesYMarkDmd"+myHolesNum]=myHh3HolesYMarkDmd;
/*****************************************************************************/ 
plate.models["myHh3HolesDdn"+myHolesNum]=myHh3HolesDdn;
markers.models["myHh3HolesDdn"+myHolesNum]=myHh3HolesDdn;
markers.models["myHh3HolesMarkDdn"+myHolesNum]=myHh3HolesMarkDdn;
markers.models["myHh3HolesYMarkDdn"+myHolesNum]=myHh3HolesYMarkDdn;
/*****************************************************************************/ 
/*****************************************************************************/ 

}
else if(area==6){
//画孔 F位置孔
      //标记拉手孔3 F位置孔上
      var myHh3HolesFup= new makerjs.models.Holes(d/2, [[xf, yf+h]]);
      var myHh3HolesMarkFup = new makerjs.models.Square(0); //孔的原点       
      myHh3HolesMarkFup.origin = [xf, yf+h];      
      var myHh3HolesXMarkFup = new makerjs.models.Square(0);      
      myHh3HolesXMarkFup.origin =[xf, yf+h];      
      var myHh3HolesYMarkFup = new makerjs.models.Square(0);      
      myHh3HolesYMarkFup.origin = [xf, yf+h];  
/*****************************************************************************/ 
makerjs.model.addPath(myHh3HolesMarkFup, new makerjs.paths.Line([x0,yd-h], [x0, yd-h+markShiftFour]));//Y轴加长标线
makerjs.model.addPath(myHh3HolesMarkFup, new makerjs.paths.Line([0,0], [x0+markShiftFour, 0]));//从孔圆心出发X方向横向标线
makerjs.model.addPath(myHh3HolesMarkFup, new makerjs.paths.Line([0,0], [0, yd-h+markShiftFour]));//从孔圆心出发Y方向竖向标线
/*****************************************************************************/ 
makerjs.model.addPath(myHh3HolesMarkFup, new makerjs.paths.Line([0,0], [d, -d]));//直径标线
makerjs.model.addCaption(myHh3HolesMarkFup, TxtD, [0,0], [d+markHolesOneD, -d-markHolesOneD]);//直径文字标注
/*****************************************************************************/   
makerjs.model.addPath(myHh3HolesXMarkFup, new makerjs.paths.Line([0,yd-h+markShift], [x0, yd-h+markShift]));//X短标线
makerjs.model.addCaption(myHh3HolesXMarkFup, TxtX, [0, yd-h+markShiftThree], [x0,yd-h+markShiftThree]);//X文字标注
/*****************************************************************************/  
 //标记拉手孔3 F位置孔中
 var myHh3HolesFmd= new makerjs.models.Holes(d/2, [[xf, yf]]);
      var myHh3HolesMarkFmd = new makerjs.models.Square(0); //孔的原点       
      myHh3HolesMarkFmd.origin = [xf, yf];      
      var myHh3HolesYMarkFmd = new makerjs.models.Square(0);      
      myHh3HolesYMarkFmd.origin = [xf, yf];  
/*****************************************************************************/ 
makerjs.model.addPath(myHh3HolesMarkFmd, new makerjs.paths.Line([0,0], [x0+markShiftFour, 0]));//从孔圆心出发X方向横向标线
makerjs.model.addPath(myHh3HolesMarkFmd, new makerjs.paths.Line([0,0], [0, h+markShiftTwo]));//从孔圆心出发Y方向竖向标线
/*****************************************************************************/ 
makerjs.model.addPath(myHh3HolesMarkFmd, new makerjs.paths.Line([0,0], [d, -d]));//直径标线
makerjs.model.addCaption(myHh3HolesMarkFmd, TxtD, [0,0], [d+markHolesOneD, -d-markHolesOneD]);//直径文字标注
/*****************************************************************************/   
makerjs.model.addPath(myHh3HolesYMarkFmd, new makerjs.paths.Line([x0+markShiftTwo,0], [x0+markShiftTwo, h]));//Y短标线
makerjs.model.addCaption(myHh3HolesYMarkFmd, TxtH, [x0+markShiftFour,0], [x0+markShiftFour, h]);//Y文字标注
/*****************************************************************************/ 
/*****************************************************************************/ 
 //标记拉手孔3 F位置孔下
 var myHh3HolesFdn= new makerjs.models.Holes(d/2, [[xf, yf-h]]);
      var myHh3HolesMarkFdn = new makerjs.models.Square(0); //孔的原点       
      myHh3HolesMarkFdn.origin = [xf, yf-h];       
      var myHh3HolesYMarkFdn = new makerjs.models.Square(0);      
      myHh3HolesYMarkFdn.origin = [xf, yf-h];  
/*****************************************************************************/ 
makerjs.model.addPath(myHh3HolesMarkFdn, new makerjs.paths.Line([0,0], [x0+markShiftFour, 0]));//从孔圆心出发X方向横向标线
makerjs.model.addPath(myHh3HolesMarkFdn, new makerjs.paths.Line([0,0], [0, h+markShiftTwo]));//从孔圆心出发Y方向竖向标线
/*****************************************************************************/ 
makerjs.model.addPath(myHh3HolesMarkFdn, new makerjs.paths.Line([0,0], [d, -d]));//直径标线
makerjs.model.addCaption(myHh3HolesMarkFdn, TxtD, [0,0], [d+markHolesOneD, -d-markHolesOneD]);//直径文字标注
/*****************************************************************************/    
makerjs.model.addPath(myHh3HolesYMarkFdn, new makerjs.paths.Line([x0+markShiftTwo,0], [x0+markShiftTwo, h]));//Y短标线
makerjs.model.addCaption(myHh3HolesYMarkFdn, TxtH, [x0+markShiftFour,0], [x0+markShiftFour, h]);//Y文字标注
/*****************************************************************************/ 
/*****************************************************************************/
plate.models["myHh3HolesFup"+myHolesNum]=myHh3HolesFup;
markers.models["myHh3HolesFup"+myHolesNum]=myHh3HolesFup;
markers.models["myHh3HolesMarkFup"+myHolesNum]=myHh3HolesMarkFup;
markers.models["myHh3HolesXMarkFup"+myHolesNum]=myHh3HolesXMarkFup;
markers.models["myHh3HolesYMarkFup"+myHolesNum]=myHh3HolesYMarkFup;
/*****************************************************************************/
plate.models["myHh3HolesFmd"+myHolesNum]=myHh3HolesFmd;
markers.models["myHh3HolesFmd"+myHolesNum]=myHh3HolesFmd;
markers.models["myHh3HolesMarkFmd"+myHolesNum]=myHh3HolesMarkFmd;
markers.models["myHh3HolesYMarkFmd"+myHolesNum]=myHh3HolesYMarkFmd;
/*****************************************************************************/ 
plate.models["myHh3HolesFdn"+myHolesNum]=myHh3HolesFdn;
markers.models["myHh3HolesFdn"+myHolesNum]=myHh3HolesFdn;
markers.models["myHh3HolesMarkFdn"+myHolesNum]=myHh3HolesMarkFdn;
markers.models["myHh3HolesYMarkFdn"+myHolesNum]=myHh3HolesYMarkFdn;
/*****************************************************************************/ 
/*****************************************************************************/ 
 
}
else if(area==7){
 //画孔 G位置孔
      //标记拉手孔3 G位置孔下
      var myHh3HolesGdn= new makerjs.models.Holes(d/2, [[xg, yg]]);
      var myHh3HolesMarkGdn = new makerjs.models.Square(0); //孔的原点       
      myHh3HolesMarkGdn.origin = [xg, yg];      
      var myHh3HolesXMarkGdn = new makerjs.models.Square(0);      
      myHh3HolesXMarkGdn.origin =[xg, yg];      
      var myHh3HolesYMarkGdn = new makerjs.models.Square(0);      
      myHh3HolesYMarkGdn.origin = [xg, yg];  

/*****************************************************************************/ 
makerjs.model.addPath(myHh3HolesMarkGdn, new makerjs.paths.Line([-x0,y0], [-x0, -y0-markShiftFour]));//Y轴加长标线
makerjs.model.addPath(myHh3HolesMarkGdn, new makerjs.paths.Line([0,0], [-x0-markShiftFour, 0]));//从孔圆心出发X方向横向标线
makerjs.model.addPath(myHh3HolesMarkGdn, new makerjs.paths.Line([-x0,-y0], [-x0-markShiftFour, -y0]));//X轴加长标线
makerjs.model.addPath(myHh3HolesMarkGdn, new makerjs.paths.Line([0,0], [0, -y0-markShiftFour]));//从孔圆心出发Y方向竖向标线
/*****************************************************************************/ 
makerjs.model.addPath(myHh3HolesMarkGdn, new makerjs.paths.Line([0,0], [d, -d]));//直径标线
makerjs.model.addCaption(myHh3HolesMarkGdn, TxtD, [0,0], [d+markHolesOneD, -d-markHolesOneD]);//直径文字标注
/*****************************************************************************/   
makerjs.model.addPath(myHh3HolesXMarkGdn, new makerjs.paths.Line([0,-y0-markShiftTwo], [-x0, -y0-markShiftTwo]));//X短标线
makerjs.model.addCaption(myHh3HolesXMarkGdn, TxtX, [-x0,-y0-markShiftFour], [0, -y0-markShiftFour]);//X文字标注
/*****************************************************************************/     
makerjs.model.addPath(myHh3HolesYMarkGdn, new makerjs.paths.Line([-x0-markShiftTwo,0], [-x0-markShiftTwo, -y0]));//Y短标线
makerjs.model.addCaption(myHh3HolesYMarkGdn, TxtY, [-x0-markShiftFour, -y0], [-x0-markShiftFour,0]);//Y文字标注
/*****************************************************************************/ 
 //标记拉手孔3 G位置孔左中
 var myHh3HolesGmd= new makerjs.models.Holes(d/2, [[xg, yg+h]]);
      var myHh3HolesMarkGmd = new makerjs.models.Square(0); //孔的原点       
      myHh3HolesMarkGmd.origin = [xg, yg+h];      
      var myHh3HolesYMarkGmd = new makerjs.models.Square(0);      
      myHh3HolesYMarkGmd.origin = [xg, yg+h];  
/*****************************************************************************/ 
makerjs.model.addPath(myHh3HolesMarkGmd, new makerjs.paths.Line([0,0], [-x0-markShiftFour, 0]));//从孔圆心出发X方向横向标线
makerjs.model.addPath(myHh3HolesMarkGmd, new makerjs.paths.Line([0,0], [0, -h-markShiftFour]));//从孔圆心出发Y方向竖向标线
/*****************************************************************************/ 
makerjs.model.addPath(myHh3HolesMarkGmd, new makerjs.paths.Line([0,0], [d, -d]));//直径标线
makerjs.model.addCaption(myHh3HolesMarkGmd, TxtD, [0,0], [d+markHolesOneD, -d-markHolesOneD]);//直径文字标注
/*****************************************************************************/   
makerjs.model.addPath(myHh3HolesYMarkGmd, new makerjs.paths.Line([-x0-markShiftTwo,0], [-x0-markShiftTwo, -h]));//Y短标线
makerjs.model.addCaption(myHh3HolesYMarkGmd, TxtH, [-x0-markShiftFour, -h], [-x0-markShiftFour,0]);//Y文字标注
/*****************************************************************************/ 
/*****************************************************************************/ 
 //标记拉手孔3 G位置孔上
 var myHh3HolesGup= new makerjs.models.Holes(d/2, [[xg, yg+h+h]]);
      var myHh3HolesMarkGup = new makerjs.models.Square(0); //孔的原点       
      myHh3HolesMarkGup.origin = [xg, yg+h+h];       
      var myHh3HolesYMarkGup = new makerjs.models.Square(0);      
      myHh3HolesYMarkGup.origin = [xg, yg+h+h];  
/*****************************************************************************/ 
makerjs.model.addPath(myHh3HolesMarkGup, new makerjs.paths.Line([0,0], [-x0-markShiftFour, 0]));//从孔圆心出发X方向横向标线
makerjs.model.addPath(myHh3HolesMarkGup, new makerjs.paths.Line([0,0], [0, -h-markShiftFour]));//从孔圆心出发Y方向竖向标线
/*****************************************************************************/ 
makerjs.model.addPath(myHh3HolesMarkGup, new makerjs.paths.Line([0,0], [d, -d]));//直径标线
makerjs.model.addCaption(myHh3HolesMarkGup, TxtD, [0,0], [d+markHolesOneD, -d-markHolesOneD]);//直径文字标注
/*****************************************************************************/    
makerjs.model.addPath(myHh3HolesYMarkGup, new makerjs.paths.Line([-x0-markShiftTwo,0], [-x0-markShiftTwo, -h]));//Y短标线
makerjs.model.addCaption(myHh3HolesYMarkGup, TxtH, [-x0-markShiftFour, -h], [-x0-markShiftFour,0]);//Y文字标注
/*****************************************************************************/ 
/*****************************************************************************/
plate.models["myHh3HolesGdn"+myHolesNum]=myHh3HolesGdn;
markers.models["myHh3HolesGdn"+myHolesNum]=myHh3HolesGdn;
markers.models["myHh3HolesMarkGdn"+myHolesNum]=myHh3HolesMarkGdn;
markers.models["myHh3HolesXMarkGdn"+myHolesNum]=myHh3HolesXMarkGdn;
markers.models["myHh3HolesYMarkGdn"+myHolesNum]=myHh3HolesYMarkGdn;
/*****************************************************************************/
plate.models["myHh3HolesGmd"+myHolesNum]=myHh3HolesGmd;
markers.models["myHh3HolesGmd"+myHolesNum]=myHh3HolesGmd;
markers.models["myHh3HolesMarkGmd"+myHolesNum]=myHh3HolesMarkGmd;
markers.models["myHh3HolesYMarkGmd"+myHolesNum]=myHh3HolesYMarkGmd;
/*****************************************************************************/ 
this.plate.models["myHh3HolesGup"+this.myHolesNum]=myHh3HolesGup;
this.markers.models["myHh3HolesGup"+this.myHolesNum]=myHh3HolesGup;
this.markers.models["myHh3HolesMarkGup"+this.myHolesNum]=myHh3HolesMarkGup;
this.markers.models["myHh3HolesYMarkGup"+this.myHolesNum]=myHh3HolesYMarkGup;
/*****************************************************************************/ 
/*****************************************************************************/ 

}
else if(area==9){
//画孔 I位置孔
      //标记拉手孔3 I位置孔下
      var myHh3HolesIdn= new makerjs.models.Holes(d/2, [[xi, yi]]);
      var myHh3HolesMarkIdn = new makerjs.models.Square(0); //孔的原点       
      myHh3HolesMarkIdn.origin = [xi, yi];      
      var myHh3HolesXMarkIdn = new makerjs.models.Square(0);      
      myHh3HolesXMarkIdn.origin =[xi, yi];      
      var myHh3HolesYMarkIdn = new makerjs.models.Square(0);      
      myHh3HolesYMarkIdn.origin = [xi, yi];  

/*****************************************************************************/ 
makerjs.model.addPath(myHh3HolesMarkIdn, new makerjs.paths.Line([x0,y0], [x0, -y0-markShiftFour]));//Y轴加长标线
makerjs.model.addPath(myHh3HolesMarkIdn, new makerjs.paths.Line([0,0], [x0+markShiftFour, 0]));//从孔圆心出发X方向横向标线
makerjs.model.addPath(myHh3HolesMarkIdn, new makerjs.paths.Line([x0,-y0], [x0+markShiftFour, -y0]));//X轴加长标线
makerjs.model.addPath(myHh3HolesMarkIdn, new makerjs.paths.Line([0,0], [0, -y0-markShiftFour]));//从孔圆心出发Y方向竖向标线
/*****************************************************************************/ 
makerjs.model.addPath(myHh3HolesMarkIdn, new makerjs.paths.Line([0,0], [d, -d]));//直径标线
makerjs.model.addCaption(myHh3HolesMarkIdn, TxtD, [0,0], [d+markHolesOneD, -d-markHolesOneD]);//直径文字标注
/*****************************************************************************/   
makerjs.model.addPath(myHh3HolesXMarkIdn, new makerjs.paths.Line([0,-y0-markShiftTwo], [x0, -y0-markShiftTwo]));//X短标线
makerjs.model.addCaption(myHh3HolesXMarkIdn, TxtX, [0, -y0-markShiftFour], [x0,-y0-markShiftFour]);//X文字标注
/*****************************************************************************/     
makerjs.model.addPath(myHh3HolesYMarkIdn, new makerjs.paths.Line([x0+markShiftTwo,0], [x0+markShiftTwo, -y0]));//Y短标线
makerjs.model.addCaption(myHh3HolesYMarkIdn, TxtY, [x0+markShiftFour, -y0], [x0+markShiftFour,0]);//Y文字标注
/*****************************************************************************/ 
 //标记拉手孔3 I位置孔左中
 var myHh3HolesImd= new makerjs.models.Holes(d/2, [[xi, yi+h]]);
      var myHh3HolesMarkImd = new makerjs.models.Square(0); //孔的原点       
      myHh3HolesMarkImd.origin = [xi, yi+h];      
      var myHh3HolesYMarkImd = new makerjs.models.Square(0);      
      myHh3HolesYMarkImd.origin = [xi, yi+h];  
/*****************************************************************************/ 
makerjs.model.addPath(myHh3HolesMarkImd, new makerjs.paths.Line([0,0], [x0+markShiftFour, 0]));//从孔圆心出发X方向横向标线
makerjs.model.addPath(myHh3HolesMarkImd, new makerjs.paths.Line([0,0], [0, -h-markShiftFour]));//从孔圆心出发Y方向竖向标线
/*****************************************************************************/ 
makerjs.model.addPath(myHh3HolesMarkImd, new makerjs.paths.Line([0,0], [d, -d]));//直径标线
makerjs.model.addCaption(myHh3HolesMarkImd, TxtD, [0,0], [d+markHolesOneD, -d-markHolesOneD]);//直径文字标注
/*****************************************************************************/   
makerjs.model.addPath(myHh3HolesYMarkImd, new makerjs.paths.Line([x0+markShiftTwo,0], [x0+markShiftTwo, -h]));//Y短标线
makerjs.model.addCaption(myHh3HolesYMarkImd, TxtH, [x0+markShiftFour, -h], [x0+markShiftFour,0]);//Y文字标注
/*****************************************************************************/ 
/*****************************************************************************/ 
 //标记拉手孔3 I位置孔上
 var myHh3HolesIup= new makerjs.models.Holes(d/2, [[xi, yi+h+h]]);
      var myHh3HolesMarkIup = new makerjs.models.Square(0); //孔的原点       
      myHh3HolesMarkIup.origin = [xi, yi+h+h];       
      var myHh3HolesYMarkIup = new makerjs.models.Square(0);      
      myHh3HolesYMarkIup.origin = [xi, yi+h+h];  
/*****************************************************************************/ 
makerjs.model.addPath(myHh3HolesMarkIup, new makerjs.paths.Line([0,0], [x0+markShiftFour, 0]));//从孔圆心出发X方向横向标线
makerjs.model.addPath(myHh3HolesMarkIup, new makerjs.paths.Line([0,0], [0, -h-markShiftFour]));//从孔圆心出发Y方向竖向标线
/*****************************************************************************/ 
makerjs.model.addPath(myHh3HolesMarkIup, new makerjs.paths.Line([0,0], [d, -d]));//直径标线
makerjs.model.addCaption(myHh3HolesMarkIup, TxtD, [0,0], [d+markHolesOneD, -d-markHolesOneD]);//直径文字标注
/*****************************************************************************/    
makerjs.model.addPath(myHh3HolesYMarkIup, new makerjs.paths.Line([x0+markShiftTwo,0], [x0+markShiftTwo, -h]));//Y短标线
makerjs.model.addCaption(myHh3HolesYMarkIup, TxtH, [x0+markShiftFour, -h], [x0+markShiftFour,0]);//Y文字标注
/*****************************************************************************/ 
/*****************************************************************************/
plate.models["myHh3HolesIdn"+myHolesNum]=myHh3HolesIdn;
markers.models["myHh3HolesIdn"+myHolesNum]=myHh3HolesIdn;
markers.models["myHh3HolesMarkIdn"+myHolesNum]=myHh3HolesMarkIdn;
markers.models["myHh3HolesXMarkIdn"+myHolesNum]=myHh3HolesXMarkIdn;
markers.models["myHh3HolesYMarkIdn"+myHolesNum]=myHh3HolesYMarkIdn;
/*****************************************************************************/
plate.models["myHh3HolesImd"+myHolesNum]=myHh3HolesImd;
markers.models["myHh3HolesImd"+myHolesNum]=myHh3HolesImd;
markers.models["myHh3HolesMarkImd"+myHolesNum]=myHh3HolesMarkImd;
markers.models["myHh3HolesYMarkImd"+myHolesNum]=myHh3HolesYMarkImd;
/*****************************************************************************/ 
plate.models["myHh3HolesIup"+myHolesNum]=myHh3HolesIup;
markers.models["myHh3HolesIup"+myHolesNum]=myHh3HolesIup;
markers.models["myHh3HolesMarkIup"+myHolesNum]=myHh3HolesMarkIup;
markers.models["myHh3HolesYMarkIup"+myHolesNum]=myHh3HolesYMarkIup;
/*****************************************************************************/ 
/*****************************************************************************/ 
  
}

myHolesNum++;
return{plate, markers,myHolesNum}
};


export default HandleHole3;