//缺1.2、蝴蝶
import makerjs from "makerjs";
// inputParam,model,myHolesNum,outParam,fixedParam
// (D,X,Y,area,plate,markers,myHolesNum,glassW,glassH,markShift,markShiftTwo,markShiftThree,markShiftFour,markHolesOneD)
function ButterflyGap2(inputParam,model,myHolesNum,outParam,fixedParam,zoom){
var R=inputParam.R,W=inputParam.W,W1=inputParam.W1,H=inputParam.H,H1=inputParam.H1,X=inputParam.X,Y=inputParam.Y,area=inputParam.area,myZoom=zoom*1,
plate=model.plate,markers=model.markers,
glassW=outParam.glassW,glassH=outParam.glassH,
   markShift=fixedParam.markShift,markShiftTwo=fixedParam.markShiftTwo,
   markShiftThree=fixedParam.markShiftThree,markShiftFour=fixedParam.markShiftFour,markHolesOneD=fixedParam.markHolesOneD,
 
   h = H*1*myZoom,
   w= W*1*myZoom,
   h1 = H1*1*myZoom,
   w1= W1*1*myZoom,
   r= R*1*myZoom,
   dd=r/Math.sqrt(2),
   wd=w1-w,
   hd=(h1-h)/2,
     y0 = Y*1*myZoom,
     x0 = X*1*myZoom;
     /*
     h = H*1,
     w= W*1,
     h1 = H1*1,
     w1= W1*1,
     r= R*1,
     dd=r/Math.sqrt(2),
     wd=w1-w,
     hd=(h1-h)/2,
       y0 = Y*1,
       x0 = X*1;
       */
     if(r!==0){
      var TxtR="R="+R;
     }
     if(h!==0){
      var TxtH=H;
     }
     if(w!==0){
      var TxtW=W;
     }
     if(h1!==0){
      var TxtH1=H1;
     }
     if(w1!==0){
      var TxtW1=W1;
     }
     if(y0!==0){          
      var TxtY=Y;
     }
  //在A区域 计算孔的位置       
  var xa=x0,ya=glassH * 1-y0,
  //B区域//只有Y标注没有X标注 
  xb=glassW * 1/2,yb=glassH * 1-y0,
  //C区域
  xc=glassW * 1-x0,yc=glassH * 1-y0,
  //D区域
  //只有X标注没有Y标注 
  xd=x0,yd=glassH * 1/2,
  //E区域
  //只有X标注没有Y标注 
  xe=glassW * 1/2,ye=glassH * 1/2,
  //F区域
  //只有X标注没有Y标注 
  xf=glassW * 1-x0,yf=glassH * 1/2,
  //在G区域 计算孔的位置
  xg=x0,yg=y0,
  //在H区域 计算孔的位置
  //只标注Y
  xh=glassW*1/2,yh=y0,
  //在I区域 计算孔的位置
  xi=glassW*1-x0,yi=y0;
  if(area==1){
    //画缺 A位置
        //1、2蝴蝶2 A位置   H W D  width=w-d/2        
        var myBfg2GapARect=new makerjs.models.Rectangle(w+r+wd, h1); 
        myBfg2GapARect.origin=[xa-r,ya-h1];     
        var myBfg2GapABoltRectangle=new makerjs.models.BoltRectangle(w+r,h1,r);
        myBfg2GapABoltRectangle.origin=[xa-r,ya-h1-hd];
  var myBfg2GapA=makerjs.model.combineUnion(myBfg2GapARect, myBfg2GapABoltRectangle);  
     //画1、2蝴蝶2标记 A位置 
  var myBfg2GapAMakerW = new makerjs.models.Square(0); //D的起始点       
  myBfg2GapAMakerW.origin = [xa,ya+y0];
  var myBfg2GapAMakerH = new makerjs.models.Square(0); //W的起始点        
  myBfg2GapAMakerH.origin = [xa,ya+y0];   
  var myBfg2GapAMakerW1 = new makerjs.models.Square(0); //D的起始点       
  myBfg2GapAMakerW1.origin = [xa,ya+y0];
  var myBfg2GapAMakerH1 = new makerjs.models.Square(0); //W的起始点        
  myBfg2GapAMakerH1.origin = [xa,ya+y0];
  var myBfg2GapAMakerR = new makerjs.models.Square(0); //H的起始点        
  myBfg2GapAMakerR.origin = [xa,ya+y0];
  var myBfg2GapAMakerY = new makerjs.models.Square(0); //H的起始点        
  myBfg2GapAMakerY.origin = [xa,ya+y0];
  /*****************************************************************************/
  makerjs.model.addPath(myBfg2GapAMakerW, new makerjs.paths.Line([0,0], [0, markShiftThree]));//Y轴加长标线
  makerjs.model.addPath(myBfg2GapAMakerW, new makerjs.paths.Line([w,0], [w,0-markShiftThree]));//Y轴方向短线 w
  makerjs.model.addPath(myBfg2GapAMakerW, new makerjs.paths.Line([w1,0], [w1,0+markShiftFour]));//Y轴方向短线 w1
  makerjs.model.addPath(myBfg2GapAMakerW, new makerjs.paths.Line([0,0], [0-markShiftThree, 0]));//X轴加长标线
  makerjs.model.addPath(myBfg2GapAMakerW, new makerjs.paths.Line([0,0-y0], [0-markShiftThree, 0-y0]));//X轴方向短线
  makerjs.model.addPath(myBfg2GapAMakerW, new makerjs.paths.Line([w,0-y0-hd], [w+markShiftFour+wd, 0-y0-hd]));//X轴方向短线 H  up
  makerjs.model.addPath(myBfg2GapAMakerW, new makerjs.paths.Line([w,0-y0-hd-h], [w+markShiftFour+wd, 0-y0-hd-h]));//X轴方向短线 H  dn
  
  makerjs.model.addPath(myBfg2GapAMakerR, new makerjs.paths.Line([w,0-y0-hd], [w+dd+markHolesOneD,0-y0-hd+dd+markHolesOneD]));//R相关 X轴方向短线上
  makerjs.model.addCaption(myBfg2GapAMakerR,TxtR,[w+markShiftFour,0-y0+hd], [w+markShiftFour+dd+markHolesOneD,0-y0+hd+dd+markHolesOneD]);//R相关 X轴方向短线下
  
  makerjs.model.addPath(myBfg2GapAMakerH1, new makerjs.paths.Line([0+markShift,0-y0], [0+markShift, 0-y0-h1]));//H相关 Y轴方向短线
  makerjs.model.addCaption(myBfg2GapAMakerH1,TxtH1, [0+markShiftThree,0-y0-h1], [0+markShiftThree, 0-y0]);//H文字标注
  
  makerjs.model.addPath(myBfg2GapAMakerH, new makerjs.paths.Line([w1+markShift,0-y0-hd], [w1+markShift, 0-y0-hd-h]));//H相关 Y轴方向短线
  makerjs.model.addCaption(myBfg2GapAMakerH,TxtH, [w1+markShiftThree, 0-y0-hd-h], [w1+markShiftThree,0-y0-hd]);//H文字标注
  
  makerjs.model.addPath(myBfg2GapAMakerY, new makerjs.paths.Line([0-markShiftTwo,0], [0-markShiftTwo, 0-y0]));//Y轴方向标注短线 
  makerjs.model.addCaption(myBfg2GapAMakerY,TxtY,  [0-markShiftFour, 0-y0],[0-markShiftFour,0]);//Y文字标注
  /*****************************************************************************/
  makerjs.model.addPath(myBfg2GapAMakerW, new makerjs.paths.Line([0,0-markShiftTwo], [w, 0-markShiftTwo]));//W轴方向标注短线 
  makerjs.model.addCaption(myBfg2GapAMakerW,TxtW, [0,0-markShiftFour], [w, 0-markShiftFour]);//W文字标注
  /*****************************************************************************/
  makerjs.model.addPath(myBfg2GapAMakerW1, new makerjs.paths.Line([0,0+markShiftTwo], [w1, 0+markShiftTwo]));//W轴方向标注短线 
  makerjs.model.addCaption(myBfg2GapAMakerW1,TxtW1, [0,0+markShiftFour], [w1, 0+markShiftFour]);//W文字标注
  // /*****************************************************************************/
  plate.models["myBfg2GapA"+myHolesNum]=myBfg2GapA;
  markers.models["myBfg2GapA"+myHolesNum]=myBfg2GapA;
  markers.models["myBfg2GapAMakerW1"+myHolesNum]=myBfg2GapAMakerW1;
  markers.models["myBfg2GapAMakerH1"+myHolesNum]=myBfg2GapAMakerH1;
  markers.models["myBfg2GapAMakerW"+myHolesNum]=myBfg2GapAMakerW;
  markers.models["myBfg2GapAMakerH"+myHolesNum]=myBfg2GapAMakerH;
  markers.models["myBfg2GapAMakerR"+myHolesNum]=myBfg2GapAMakerR;
  markers.models["myBfg2GapAMakerY"+myHolesNum]=myBfg2GapAMakerY;
  /*****************************************************************************/
  makerjs.model.combineSubtraction(markers.models.myRect, markers.models["myBfg2GapA"+myHolesNum]);
  console.log("markers==",markers);
  /*****************************************************************************/ 
        }else if(area==2){
          
        }else if(area==3){
          //画缺 C位置
        //1、2蝴蝶2 C位置   H W D  width=w-d/2
        var myBfg2GapCRect=new makerjs.models.Rectangle(w+r+wd, h1); 
        myBfg2GapCRect.origin=[xc-w-wd,yc-h1];     
        var myBfg2GapCBoltRectangle=new makerjs.models.BoltRectangle(w+r,h1,r);
        myBfg2GapCBoltRectangle.origin=[xc-w,yc-h1-hd]; 
  var myBfg2GapC=makerjs.model.combineUnion(myBfg2GapCRect, myBfg2GapCBoltRectangle); 
     //画1、2蝴蝶2标记 C位置 
  var myBfg2GapCMakerW1 = new makerjs.models.Square(0); //D的起始点       
  myBfg2GapCMakerW1.origin = [xc,yc+y0];
  var myBfg2GapCMakerH1 = new makerjs.models.Square(0); //W的起始点        
  myBfg2GapCMakerH1.origin = [xc,yc+y0]; 
  var myBfg2GapCMakerW = new makerjs.models.Square(0); //D的起始点       
  myBfg2GapCMakerW.origin = [xc,yc+y0];
  var myBfg2GapCMakerH = new makerjs.models.Square(0); //W的起始点        
  myBfg2GapCMakerH.origin = [xc,yc+y0];
  var myBfg2GapCMakerR = new makerjs.models.Square(0); //H的起始点        
  myBfg2GapCMakerR.origin = [xc,yc+y0];
  var myBfg2GapCMakerY = new makerjs.models.Square(0); //H的起始点        
  myBfg2GapCMakerY.origin = [xc,yc+y0];
  /*****************************************************************************/
  makerjs.model.addPath(myBfg2GapCMakerW, new makerjs.paths.Line([0,0], [0, markShiftThree]));//Y轴加长标线
  makerjs.model.addPath(myBfg2GapCMakerW, new makerjs.paths.Line([-w1,0], [-w1,0+markShiftFour]));//Y轴方向短线 W1
  makerjs.model.addPath(myBfg2GapCMakerW, new makerjs.paths.Line([-w,0], [-w,0-markShiftFour]));//Y轴方向短线 W
  makerjs.model.addPath(myBfg2GapCMakerW, new makerjs.paths.Line([0,0], [0+markShiftThree, 0]));//X轴加长标线
  makerjs.model.addPath(myBfg2GapCMakerW, new makerjs.paths.Line([0,-y0], [0+markShiftThree, -y0]));//X轴方向短线 y0
  makerjs.model.addPath(myBfg2GapCMakerW, new makerjs.paths.Line([-w,0-y0-hd], [-w-markShiftFour-wd, 0-y0-hd]));//X轴方向短线  H  up
  makerjs.model.addPath(myBfg2GapCMakerW, new makerjs.paths.Line([-w,0-y0-hd-h], [-w-markShiftFour-wd, 0-y0-hd-h]));//X轴方向短线 H  dn 
  
  makerjs.model.addPath(myBfg2GapCMakerR, new makerjs.paths.Line([-w,0-y0-hd], [-w-dd-markHolesOneD,0-y0-hd+dd+markHolesOneD]));//D相关 X轴方向短线上
  makerjs.model.addCaption(myBfg2GapCMakerR,TxtR, [-w-markShiftFour-dd-markHolesOneD,0-y0+hd+dd+markHolesOneD],[-w-markShiftFour,0-y0+hd]);//D相关 X轴方向短线下
  
  
  makerjs.model.addPath(myBfg2GapCMakerH, new makerjs.paths.Line([-w1-markShift,0-y0-hd], [-w1-markShift, 0-y0-hd-h]));//H相关 Y轴方向短线
  makerjs.model.addCaption(myBfg2GapCMakerH,TxtH, [-w1-markShiftFour,0-y0-hd], [-w1-markShiftFour, 0-y0-hd-h]);//H文字标注
  
  
  
  makerjs.model.addPath(myBfg2GapCMakerH1, new makerjs.paths.Line([0-markShift,0-y0], [0-markShift, 0-y0-h1]));//H相关 Y轴方向短线
  makerjs.model.addCaption(myBfg2GapCMakerH1,TxtH1,  [0-markShiftThree, 0-y0-h1],[0-markShiftThree,0-y0]);//H文字标注
  
  
  makerjs.model.addPath(myBfg2GapCMakerY, new makerjs.paths.Line([0+markShift,0], [0+markShift, 0-y0]));//Y轴方向标注短线 
  makerjs.model.addCaption(myBfg2GapCMakerY,TxtY,  [0+markShiftThree, 0-y0],[0+markShiftThree,0]);//Y文字标注
  /*****************************************************************************/
  makerjs.model.addPath(myBfg2GapCMakerW1, new makerjs.paths.Line([-w1,0+markShiftTwo], [0, 0+markShiftTwo]));//W1轴方向标注短线 
  makerjs.model.addCaption(myBfg2GapCMakerW1,TxtW1, [-w1,0+markShiftFour], [0, 0+markShiftFour]);//W1文字标注
  
  makerjs.model.addPath(myBfg2GapCMakerW, new makerjs.paths.Line([-w,0-markShiftTwo], [0, 0-markShiftTwo]));//W轴方向标注短线 
  makerjs.model.addCaption(myBfg2GapCMakerW,TxtW, [-w,0-markShiftFour], [0, 0-markShiftFour]);//W文字标注
  // /*****************************************************************************/
  plate.models["myBfg2GapC"+myHolesNum]=myBfg2GapC;
  markers.models["myBfg2GapC"+myHolesNum]=myBfg2GapC;
  markers.models["myBfg2GapCMakerW1"+myHolesNum]=myBfg2GapCMakerW1;
  markers.models["myBfg2GapCMakerH1"+myHolesNum]=myBfg2GapCMakerH1;
  markers.models["myBfg2GapCMakerW"+myHolesNum]=myBfg2GapCMakerW;
  markers.models["myBfg2GapCMakerH"+myHolesNum]=myBfg2GapCMakerH;
  markers.models["myBfg2GapCMakerR"+myHolesNum]=myBfg2GapCMakerR;
  markers.models["myBfg2GapCMakerY"+myHolesNum]=myBfg2GapCMakerY;
  /*****************************************************************************/
  makerjs.model.combineSubtraction(markers.models.myRect, markers.models["myBfg2GapC"+myHolesNum]);
  console.log("markers==",markers);
  /*****************************************************************************/ 
        }else if(area==4){
     //1、2蝴蝶2 D位置   H W D  width=w-d/2
     var myBfg2GapDRect=new makerjs.models.Rectangle(w+r+wd, h1); 
        myBfg2GapDRect.origin=[xd-r,yd-h1/2];     
        var myBfg2GapDBoltRectangle=new makerjs.models.BoltRectangle(w+r,h1,r);
        myBfg2GapDBoltRectangle.origin=[xd-r,yd-h1/2-hd/2];
  var myBfg2GapD=makerjs.model.combineUnion(myBfg2GapDRect, myBfg2GapDBoltRectangle);  
     //画1、2蝴蝶2标记 D位置 
  var myBfg2GapDMakerW = new makerjs.models.Square(0); //D的起始点       
  myBfg2GapDMakerW.origin = [xd,yd];
  var myBfg2GapDMakerH = new makerjs.models.Square(0); //W的起始点        
  myBfg2GapDMakerH.origin = [xd,yd];   
  var myBfg2GapDMakerW1 = new makerjs.models.Square(0); //D的起始点       
  myBfg2GapDMakerW1.origin = [xd,yd];
  var myBfg2GapDMakerH1 = new makerjs.models.Square(0); //W的起始点        
  myBfg2GapDMakerH1.origin = [xd,yd];
  var myBfg2GapDMakerR = new makerjs.models.Square(0); //H的起始点        
  myBfg2GapDMakerR.origin = [xd,yd];
  /*****************************************************************************/
  makerjs.model.addPath(myBfg2GapDMakerW, new makerjs.paths.Line([w,h/2], [w,h+markShiftTwo]));//Y轴方向短线 w
  makerjs.model.addPath(myBfg2GapDMakerW, new makerjs.paths.Line([w1,-h/2], [w1,-h-markShiftFour]));//Y轴方向短线 w1
  makerjs.model.addPath(myBfg2GapDMakerW, new makerjs.paths.Line([w,0+h/2-hd/2], [w+markShiftFour+wd, 0+h/2-hd/2]));//X轴方向短线 H  up
  makerjs.model.addPath(myBfg2GapDMakerW, new makerjs.paths.Line([w,0+h/2+hd-h], [w+markShiftFour+wd, 0+h/2+hd-h]));//X轴方向短线 H  dn
  
  makerjs.model.addPath(myBfg2GapDMakerR, new makerjs.paths.Line([w,0+h1/2-hd], [w+dd+markHolesOneD,0+h1/2-hd+dd+markHolesOneD]));//R相关 X轴方向短线上
  makerjs.model.addCaption(myBfg2GapDMakerR,TxtR,[w+markShiftFour,0+h1/2+hd], [w+markShiftFour+dd+markHolesOneD,0+h1/2+hd+dd+markHolesOneD]);//R相关 X轴方向短线下
 
  makerjs.model.addPath(myBfg2GapDMakerH1, new makerjs.paths.Line([0+markShift,h1/2], [0+markShift, -h1/2]));//H相关 Y轴方向短线
  makerjs.model.addCaption(myBfg2GapDMakerH1,TxtH1, [0+markShiftThree, -h1/2], [0+markShiftThree,h1/2]);//H文字标注
  
  makerjs.model.addPath(myBfg2GapDMakerH, new makerjs.paths.Line([w1+markShift,h/2-hd/2], [w1+markShift, -h/2+hd]));//H相关 Y轴方向短线
  makerjs.model.addCaption(myBfg2GapDMakerH,TxtH, [w1+markShiftThree, -h/2+hd], [w1+markShiftThree,h/2-hd/2]);//H文字标注
  /*****************************************************************************/
  makerjs.model.addPath(myBfg2GapDMakerW, new makerjs.paths.Line([0,h+markShift], [w, h+markShift]));//W轴方向标注短线 
  makerjs.model.addCaption(myBfg2GapDMakerW,TxtW, [0,h+markShiftTwo], [w, h+markShiftTwo]);//W文字标注
  /*****************************************************************************/
  makerjs.model.addPath(myBfg2GapDMakerW1, new makerjs.paths.Line([0,0-h-markShift], [w1, 0-h-markShift]));//W轴方向标注短线 
  makerjs.model.addCaption(myBfg2GapDMakerW1,TxtW1, [0,0-h-markShiftThree], [w1, 0-h-markShiftThree]);//W文字标注
  // /*****************************************************************************/
  plate.models["myBfg2GapD"+myHolesNum]=myBfg2GapD;
  markers.models["myBfg2GapD"+myHolesNum]=myBfg2GapD;
  markers.models["myBfg2GapDMakerW1"+myHolesNum]=myBfg2GapDMakerW1;
  markers.models["myBfg2GapDMakerH1"+myHolesNum]=myBfg2GapDMakerH1;
  markers.models["myBfg2GapDMakerW"+myHolesNum]=myBfg2GapDMakerW;
  markers.models["myBfg2GapDMakerH"+myHolesNum]=myBfg2GapDMakerH;
  markers.models["myBfg2GapDMakerR"+myHolesNum]=myBfg2GapDMakerR;
  /*****************************************************************************/
  makerjs.model.combineSubtraction(markers.models.myRect, markers.models["myBfg2GapD"+myHolesNum]);
  console.log("markers==",markers);
  /*****************************************************************************/    
        }else if(area==5){
          
        }else if(area==6){
     //画缺 F位置
        //1、2蝴蝶2 F位置   H W D  width=w-d/2
        var myBfg2GapFRect=new makerjs.models.Rectangle(w+r+wd, h1); 
        myBfg2GapFRect.origin=[xf-w-wd,yf-h1/2];     
        var myBfg2GapFBoltRectangle=new makerjs.models.BoltRectangle(w+r,h1,r);
        myBfg2GapFBoltRectangle.origin=[xf-w,yf-h1/2-hd/2]; 
  var myBfg2GapF=makerjs.model.combineUnion(myBfg2GapFRect, myBfg2GapFBoltRectangle); 
     //画1、2蝴蝶2标记 F位置 
  var myBfg2GapFMakerW1 = new makerjs.models.Square(0); //D的起始点       
  myBfg2GapFMakerW1.origin = [xf,yf];
  var myBfg2GapFMakerH1 = new makerjs.models.Square(0); //W的起始点        
  myBfg2GapFMakerH1.origin = [xf,yf]; 
  var myBfg2GapFMakerW = new makerjs.models.Square(0); //D的起始点       
  myBfg2GapFMakerW.origin = [xf,yf];
  var myBfg2GapFMakerH = new makerjs.models.Square(0); //W的起始点        
  myBfg2GapFMakerH.origin = [xf,yf];
  var myBfg2GapFMakerR = new makerjs.models.Square(0); //H的起始点        
  myBfg2GapFMakerR.origin = [xf,yf];
  /*****************************************************************************/
  makerjs.model.addPath(myBfg2GapFMakerW, new makerjs.paths.Line([-w1,-h/2], [-w1,-h-markShiftFour]));//Y轴方向短线 W1
  makerjs.model.addPath(myBfg2GapFMakerW, new makerjs.paths.Line([-w,h/2], [-w,h+markShiftFour]));//Y轴方向短线 W
  makerjs.model.addPath(myBfg2GapFMakerW, new makerjs.paths.Line([0,h1/2], [0+markShiftThree, h1/2]));//X轴加长标线
  makerjs.model.addPath(myBfg2GapFMakerW, new makerjs.paths.Line([0,-h1/2], [0+markShiftThree, -h1/2]));//X轴方向短线 y0
  makerjs.model.addPath(myBfg2GapFMakerW, new makerjs.paths.Line([-w,0-h/2-hd/2], [-w-markShiftFour-wd, 0-h/2-hd/2]));//X轴方向短线  H  up
  makerjs.model.addPath(myBfg2GapFMakerW, new makerjs.paths.Line([-w,h/2-hd/2], [-w-markShiftFour-wd, h/2-hd/2]));//X轴方向短线 H  dn 
  
  makerjs.model.addPath(myBfg2GapFMakerR, new makerjs.paths.Line([-w,0-h/2-hd], [-w-dd-markHolesOneD,0-h/2-hd-dd-markHolesOneD]));//D相关 X轴方向短线上
  makerjs.model.addCaption(myBfg2GapFMakerR,TxtR, [-w-markShiftFour-dd-markHolesOneD,0-h/2-hd-dd-markHolesOneD],[-w-markShiftFour,0-h/2-hd]);//D相关 X轴方向短线下
  
  makerjs.model.addPath(myBfg2GapFMakerH, new makerjs.paths.Line([-w1-markShift,0-h/2+hd/2], [-w1-markShift, h/2-hd/2]));//H相关 Y轴方向短线
  makerjs.model.addCaption(myBfg2GapFMakerH,TxtH, [-w1-markShiftFour, 0-h/2+hd/2], [-w1-markShiftFour,h/2-hd/2]);//H文字标注
  
  makerjs.model.addPath(myBfg2GapFMakerH1, new makerjs.paths.Line([0+markShift,h1/2], [0+markShift, -h1/2]));//H1相关 Y轴方向短线
  makerjs.model.addCaption(myBfg2GapFMakerH1,TxtH1,[0+markShiftThree,0-h1/2],  [0+markShiftThree, h1/2]);//H1文字标注
  
  /*****************************************************************************/
  makerjs.model.addPath(myBfg2GapFMakerW1, new makerjs.paths.Line([-w1,-h-markShiftTwo], [0, -h-markShiftTwo]));//W1轴方向标注短线 
  makerjs.model.addCaption(myBfg2GapFMakerW1,TxtW1, [-w1,-h-markShiftFour], [0, -h-markShiftFour]);//W1文字标注
  
  makerjs.model.addPath(myBfg2GapFMakerW, new makerjs.paths.Line([-w,h+markShiftTwo], [0, h+markShiftTwo]));//W轴方向标注短线 
  makerjs.model.addCaption(myBfg2GapFMakerW,TxtW, [-w,h+markShiftFour], [0, h+markShiftFour]);//W文字标注
  
  // /*****************************************************************************/
  plate.models["myBfg2GapF"+myHolesNum]=myBfg2GapF;
  markers.models["myBfg2GapF"+myHolesNum]=myBfg2GapF;
  markers.models["myBfg2GapFMakerW1"+myHolesNum]=myBfg2GapFMakerW1;
  markers.models["myBfg2GapFMakerH1"+myHolesNum]=myBfg2GapFMakerH1;
  markers.models["myBfg2GapFMakerW"+myHolesNum]=myBfg2GapFMakerW;
  markers.models["myBfg2GapFMakerH"+myHolesNum]=myBfg2GapFMakerH;
  markers.models["myBfg2GapFMakerR"+myHolesNum]=myBfg2GapFMakerR;
  /*****************************************************************************/
  makerjs.model.combineSubtraction(markers.models.myRect, markers.models["myBfg2GapF"+myHolesNum]);
  console.log("markers==",markers);
  /*****************************************************************************/ 
  /*****************************************************************************/    
        }else if(area==7){
           //画缺 G位置
  console.log("dd",dd)
        //1、2蝴蝶2 G位置   H W D  width=w-d/2
        
        var myBfg2GapGRect=new makerjs.models.Rectangle(w+r+wd, h1); 
        myBfg2GapGRect.origin=[xg-r,yg];     
        var myBfg2GapGBoltRectangle=new makerjs.models.BoltRectangle(w+r,h1,r);
        myBfg2GapGBoltRectangle.origin=[xg-r,yg+hd];
  var myBfg2GapG=makerjs.model.combineUnion(myBfg2GapGRect, myBfg2GapGBoltRectangle); 
     //画1、2蝴蝶2 G位置 
     
  var myBfg2GapGMakerW1 = new makerjs.models.Square(0); //D的起始点       
  myBfg2GapGMakerW1.origin = [xg,yg-y0];
  var myBfg2GapGMakerH1 = new makerjs.models.Square(0); //W的起始点        
  myBfg2GapGMakerH1.origin = [xg,yg-y0];
  var myBfg2GapGMakerW = new makerjs.models.Square(0); //D的起始点       
  myBfg2GapGMakerW.origin = [xg,yg-y0];
  var myBfg2GapGMakerH = new makerjs.models.Square(0); //W的起始点        
  myBfg2GapGMakerH.origin = [xg,yg-y0];
  var myBfg2GapGMakerR = new makerjs.models.Square(0); //H的起始点        
  myBfg2GapGMakerR.origin = [xg,yg-y0];
  var myBfg2GapGMakerY = new makerjs.models.Square(0); //H的起始点        
  myBfg2GapGMakerY.origin = [xg,yg-y0];
  
  // /*****************************************************************************/
  
  makerjs.model.addPath(myBfg2GapGMakerW, new makerjs.paths.Line([0,0], [0, -markShiftFour]));//Y轴加长标线
  makerjs.model.addPath(myBfg2GapGMakerW, new makerjs.paths.Line([w,0], [w,0+markShiftFour]));//Y轴方向短线 w
  makerjs.model.addPath(myBfg2GapGMakerW, new makerjs.paths.Line([w1,0], [w1,0-markShiftFour]));//Y轴方向短线 w1
  makerjs.model.addPath(myBfg2GapGMakerW, new makerjs.paths.Line([0,0], [0-markShiftFour, 0]));//X轴加长标线
  makerjs.model.addPath(myBfg2GapGMakerW, new makerjs.paths.Line([0,0+y0], [0-markShiftFour, 0+y0]));//X轴方向短线
  makerjs.model.addPath(myBfg2GapGMakerW, new makerjs.paths.Line([w,0+y0+hd], [w+markShiftFour+wd, 0+y0+hd]));//X轴方向短线 H  up
  makerjs.model.addPath(myBfg2GapGMakerW, new makerjs.paths.Line([w,0+y0+hd+h], [w+markShiftFour+wd, 0+y0+hd+h]));//X轴方向短线 H  dn
  
  
  makerjs.model.addPath(myBfg2GapGMakerR, new makerjs.paths.Line([w,0+y0+hd], [w+dd+markHolesOneD,0+y0-hd-dd-markHolesOneD]));//D相关 X轴方向短线上
  makerjs.model.addCaption(myBfg2GapGMakerR,TxtR,[w+markShiftTwo,0+y0], [w+markShiftTwo+hd+dd+markHolesOneD,0+y0-dd-hd-markHolesOneD]);//D相关 X轴方向短线下
  
  makerjs.model.addPath(myBfg2GapGMakerH1, new makerjs.paths.Line([0+markShiftTwo,0+y0], [0+markShiftTwo, 0+y0+h1]));//H相关 Y轴方向短线
  makerjs.model.addCaption(myBfg2GapGMakerH1,TxtH1, [0+markShiftFour,0+y0], [0+markShiftFour, 0+y0+h1]);//H文字标注
  
  makerjs.model.addPath(myBfg2GapGMakerH, new makerjs.paths.Line([w1+markShift,0+y0+hd], [w1+markShift, 0+y0+hd+h]));//H相关 Y轴方向短线
  makerjs.model.addCaption(myBfg2GapGMakerH,TxtH, [w1+markShiftThree,0+y0+hd], [w1+markShiftThree, 0+y0+hd+h]);//H文字标注
  
  
  makerjs.model.addPath(myBfg2GapGMakerY, new makerjs.paths.Line([0-markShiftTwo,0], [0-markShiftTwo, 0+y0]));//Y轴方向标注短线 
  makerjs.model.addCaption(myBfg2GapGMakerY,TxtY,  [0-markShiftFour, 0],[0-markShiftFour,0+y0]);//Y文字标注
  
  // /*****************************************************************************/
  
  
  makerjs.model.addPath(myBfg2GapGMakerW1, new makerjs.paths.Line([0,0-markShiftTwo], [w1, 0-markShiftTwo]));//W1轴方向标注短线 
  makerjs.model.addCaption(myBfg2GapGMakerW1,TxtW1, [0,0-markShiftFour], [w1, 0-markShiftFour]);//W1文字标注
  
  
  makerjs.model.addPath(myBfg2GapGMakerW, new makerjs.paths.Line([0,0+markShiftTwo], [w, 0+markShiftTwo]));//W轴方向标注短线 
  makerjs.model.addCaption(myBfg2GapGMakerW,TxtW, [0,0+markShiftFour], [w, 0+markShiftFour]);//W文字标注
  
  // /*****************************************************************************/
  /*****************************************************************************/ 
  
  plate.models["myBfg2GapG"+myHolesNum]=myBfg2GapG;
  markers.models["myBfg2GapG"+myHolesNum]=myBfg2GapG;
  markers.models["myBfg2GapGMakerW1"+myHolesNum]=myBfg2GapGMakerW1;
  markers.models["myBfg2GapGMakerH1"+myHolesNum]=myBfg2GapGMakerH1;
  markers.models["myBfg2GapGMakerW"+myHolesNum]=myBfg2GapGMakerW;
  markers.models["myBfg2GapGMakerH"+myHolesNum]=myBfg2GapGMakerH;
  markers.models["myBfg2GapGMakerR"+myHolesNum]=myBfg2GapGMakerR;
  markers.models["myBfg2GapGMakerY"+myHolesNum]=myBfg2GapGMakerY;
  /*****************************************************************************/
  makerjs.model.combineSubtraction(markers.models.myRect, markers.models["myBfg2GapG"+myHolesNum]);
  console.log("markers==",markers);
  /*****************************************************************************/           
        }else if(area==8){
          
        }else if(area==9){
       //画缺 G位置
  console.log("dd",dd)
        //1、2蝴蝶2 I位置   H W D  width=w-d/2
        var myBfg2GapIRect=new makerjs.models.Rectangle(w+r+wd, h1); 
        myBfg2GapIRect.origin=[xi-w-wd,yi];     
        var myBfg2GapIBoltRectangle=new makerjs.models.BoltRectangle(w+r,h1,r);
        myBfg2GapIBoltRectangle.origin=[xi-w,yi+hd]; 
  var myBfg2GapI=makerjs.model.combineUnion(myBfg2GapIRect, myBfg2GapIBoltRectangle);
     //画1、2蝴蝶2 I位置 
     
  var myBfg2GapIMakerW1 = new makerjs.models.Square(0); //D的起始点       
  myBfg2GapIMakerW1.origin = [xi,yi-y0];
  var myBfg2GapIMakerH1 = new makerjs.models.Square(0); //W的起始点        
  myBfg2GapIMakerH1.origin = [xi,yi-y0];
  var myBfg2GapIMakerW = new makerjs.models.Square(0); //D的起始点       
  myBfg2GapIMakerW.origin = [xi,yi-y0];
  var myBfg2GapIMakerH = new makerjs.models.Square(0); //W的起始点        
  myBfg2GapIMakerH.origin = [xi,yi-y0];
  var myBfg2GapIMakerR = new makerjs.models.Square(0); //H的起始点        
  myBfg2GapIMakerR.origin = [xi,yi-y0];
  var myBfg2GapIMakerY = new makerjs.models.Square(0); //H的起始点        
  myBfg2GapIMakerY.origin = [xi,yi-y0];
  // /*****************************************************************************/
  makerjs.model.addPath(myBfg2GapIMakerW, new makerjs.paths.Line([0,0], [0, -markShiftFour]));//Y轴加长标线
  makerjs.model.addPath(myBfg2GapIMakerW, new makerjs.paths.Line([-w,0], [-w,0+markShiftFour]));//Y轴方向短线
  makerjs.model.addPath(myBfg2GapIMakerW, new makerjs.paths.Line([-w1,0], [-w1,0-markShiftFour]));//Y轴方向短线
  makerjs.model.addPath(myBfg2GapIMakerW, new makerjs.paths.Line([0,0], [0+markShiftFour, 0]));//X轴加长标线
  makerjs.model.addPath(myBfg2GapIMakerW, new makerjs.paths.Line([0,0+y0], [0+markShiftFour, 0+y0]));//X轴方向短线
  makerjs.model.addPath(myBfg2GapIMakerW, new makerjs.paths.Line([-w,0+y0+hd], [-w-markShiftFour-wd, 0+y0+hd]));//X轴方向短线  H  up
  makerjs.model.addPath(myBfg2GapIMakerW, new makerjs.paths.Line([-w,0+y0+hd+h], [-w-markShiftFour-wd, 0+y0+hd+h]));//X轴方向短线 H  
  
  makerjs.model.addPath(myBfg2GapIMakerR, new makerjs.paths.Line([-w,0+y0+hd], [-w-dd-markHolesOneD,0+y0-dd-markHolesOneD]));//R相关 X轴方向短线上
  makerjs.model.addCaption(myBfg2GapIMakerR,TxtR,[-w-markShiftTwo-dd-markHolesOneD,0+y0-hd-dd-markHolesOneD],[-w-markShiftTwo,0+y0-hd] );//R相关 X轴方向短线下
 
  makerjs.model.addPath(myBfg2GapIMakerH1, new makerjs.paths.Line([0-markShiftTwo,0+y0], [0-markShiftTwo, 0+y0+h1]));//H相关 Y轴方向短线
  makerjs.model.addCaption(myBfg2GapIMakerH1,TxtH1, [0-markShiftFour,0+y0], [0-markShiftFour, 0+y0+h1]);//H文字标注
  
  makerjs.model.addPath(myBfg2GapIMakerH, new makerjs.paths.Line([-w-markShiftTwo-wd,0+y0+hd], [-w-markShiftTwo-wd, 0+y0+hd+h]));//H1相关 Y轴方向短线
  makerjs.model.addCaption(myBfg2GapIMakerH,TxtH, [-w-wd-markShiftFour,0+y0+hd], [-w-wd-markShiftFour, 0+y0+hd+h]);//H1文字标注
  
  
  makerjs.model.addPath(myBfg2GapIMakerY, new makerjs.paths.Line([0+markShiftTwo,0], [0+markShiftTwo, 0+y0]));//Y轴方向标注短线 
  makerjs.model.addCaption(myBfg2GapIMakerY,TxtY,  [0+markShiftFour, 0],[0+markShiftFour,0+y0]);//Y文字标注
  // /*****************************************************************************/
  makerjs.model.addPath(myBfg2GapIMakerW, new makerjs.paths.Line([-w,0+markShiftTwo], [0, 0+markShiftTwo]));//W轴方向标注短线 
  makerjs.model.addCaption(myBfg2GapIMakerW,TxtW, [-w,0+markShiftFour], [0, 0+markShiftFour]);//W文字标注
  
  
  makerjs.model.addPath(myBfg2GapIMakerW1, new makerjs.paths.Line([-w1,0-markShiftTwo], [0, 0-markShiftTwo]));//W轴方向标注短线 
  makerjs.model.addCaption(myBfg2GapIMakerW1,TxtW1, [-w1,0-markShiftFour], [0, 0-markShiftFour]);//W文字标注
  // /*****************************************************************************/
  plate.models["myBfg2GapI"+myHolesNum]=myBfg2GapI;
  markers.models["myBfg2GapI"+myHolesNum]=myBfg2GapI;
  markers.models["myBfg2GapIMakerW1"+myHolesNum]=myBfg2GapIMakerW1;
  markers.models["myBfg2GapIMakerH1"+myHolesNum]=myBfg2GapIMakerH1;
  markers.models["myBfg2GapIMakerW"+myHolesNum]=myBfg2GapIMakerW;
  markers.models["myBfg2GapIMakerH"+myHolesNum]=myBfg2GapIMakerH;
  markers.models["myBfg2GapIMakerR"+myHolesNum]=myBfg2GapIMakerR;
  markers.models["myBfg2GapIMakerY"+myHolesNum]=myBfg2GapIMakerY;
  /*****************************************************************************/
  makerjs.model.combineSubtraction(markers.models.myRect, markers.models["myBfg2GapI"+myHolesNum]); 
  console.log("markers==",markers);
  /*****************************************************************************/ 
    
        }

myHolesNum++;
return{plate, markers,myHolesNum}
};


export default ButterflyGap2;