//16、三孔
import makerjs from "makerjs";
// inputParam,model,myHolesNum,outParam,fixedParam
// (D,X,Y,area,plate,markers,myHolesNum,glassW,glassH,markShift,markShiftTwo,markShiftThree,markShiftFour,markHolesOneD)
function ThreeHoles(inputParam,model,myHolesNum,outParam,fixedParam,zoom){
var D=inputParam.D,W=inputParam.W,H=inputParam.H,X=inputParam.X,Y=inputParam.Y,area=inputParam.area,myZoom=zoom*1,
plate=model.plate,markers=model.markers,
glassW=outParam.glassW,glassH=outParam.glassH,
   markShift=fixedParam.markShift,markShiftTwo=fixedParam.markShiftTwo,
   markShiftThree=fixedParam.markShiftThree,markShiftFour=fixedParam.markShiftFour,markHolesOneD=fixedParam.markHolesOneD,
 
   d = D*1*myZoom,
      w= W*1*myZoom,
      h= H*1*myZoom,
        x0 = X*1*myZoom,
        y0 = Y*1*myZoom;
        /*
        d = D*1,
           w= W*1,
           h= H*1,
             x0 = X*1,
             y0 = Y*1;
             */
       if(d!==0){
         var TxtD="∮"+D;
        }
        if(h!==0){
         var TxtH=H;
        }
        if(w!==0){
         var TxtW=W;
        }
        if(x0!==0){          
        var TxtX=X;
        }
        if(y0!==0){          
         var TxtY=Y;
        }
  //在A区域 计算孔的位置       
  var xa=x0,ya=glassH * 1-y0,
  //B区域//只有Y标注没有X标注 
  xb=glassW * 1/2,yb=glassH * 1-y0,
  //C区域
  xc=glassW * 1-x0,yc=glassH * 1-y0,
  //D区域
  //只有X标注没有Y标注 
  xd=x0,yd=glassH * 1/2,
  //E区域
  //只有X标注没有Y标注 
  xe=glassW * 1/2,ye=glassH * 1/2,
  //F区域
  //只有X标注没有Y标注 
  xf=glassW * 1-x0,yf=glassH * 1/2,
  //在G区域 计算孔的位置
  xg=x0,yg=y0,
  //在H区域 计算孔的位置
  //只标注Y
  xh=glassW*1/2,yh=y0,
  //在I区域 计算孔的位置
  xi=glassW*1-x0,yi=y0;
  if(area==1){
    //画孔 A位置孔
          //标记三孔 A位置孔右上
          var myThHolesARightup= new makerjs.models.Holes(d/2, [[xa+w, ya]]);
          var myThHolesMarkARightup = new makerjs.models.Square(0); //孔的原点       
          myThHolesMarkARightup.origin = [xa+w, ya];      
          var myThHolesXMarkARightup = new makerjs.models.Square(0);      
          myThHolesXMarkARightup.origin =[xa+w, ya];   
    /*****************************************************************************/ 
    makerjs.model.addPath(myThHolesMarkARightup, new makerjs.paths.Line([0,0], [d+markShiftFour, 0]));//从孔圆心出发X方向横向标线
    makerjs.model.addPath(myThHolesMarkARightup, new makerjs.paths.Line([0,0], [0, y0+markShiftFour]));//从孔圆心出发Y方向竖向标线
    /*****************************************************************************/ 
    makerjs.model.addPath(myThHolesMarkARightup, new makerjs.paths.Line([0,0], [d, -d]));//直径标线
    makerjs.model.addCaption(myThHolesMarkARightup, TxtD, [0,0], [d+markHolesOneD, -d-markHolesOneD]);//直径文字标注
    /*****************************************************************************/   
    makerjs.model.addPath(myThHolesXMarkARightup, new makerjs.paths.Line([0,y0+markShiftTwo], [-w, y0+markShiftTwo]));//X短标线
    makerjs.model.addCaption(myThHolesXMarkARightup, TxtW, [-w,y0+markShiftFour], [0, y0+markShiftFour]);//X文字标注
    /*****************************************************************************/  
    /*****************************************************************************/ 
     //标记三孔 A位置孔左下
     var myThHolesALeftdn= new makerjs.models.Holes(d/2, [[xa, ya-h]]);
          var myThHolesMarkALeftdn = new makerjs.models.Square(0); //孔的原点       
          myThHolesMarkALeftdn.origin = [xa, ya-h];       
          var myThHolesXMarkALeftdn = new makerjs.models.Square(0);      
          myThHolesXMarkALeftdn.origin = [xa, ya-h];       
          var myThHolesYMarkALeftdn = new makerjs.models.Square(0);      
          myThHolesYMarkALeftdn.origin = [xa, ya-h];  
    /*****************************************************************************/ 
    makerjs.model.addPath(myThHolesMarkALeftdn, new makerjs.paths.Line([-x0,y0+h], [-x0, y0+h+markShiftFour]));//Y轴方向标注加长线
    makerjs.model.addPath(myThHolesMarkALeftdn, new makerjs.paths.Line([0,0], [w, 0]));//从孔圆心出发X方向横向标线
    makerjs.model.addPath(myThHolesMarkALeftdn, new makerjs.paths.Line([0,0], [-x0, 0]));//从孔圆心出发X方向横向标线
    makerjs.model.addPath(myThHolesMarkALeftdn, new makerjs.paths.Line([0,0], [0, y0+h+markShiftFour]));//从孔圆心出发Y方向竖向标线
    /*****************************************************************************/ 
    makerjs.model.addPath(myThHolesMarkALeftdn, new makerjs.paths.Line([0,0], [d, -d]));//直径标线
    makerjs.model.addCaption(myThHolesMarkALeftdn, TxtD, [0,0], [d+markHolesOneD, -d-markHolesOneD]);//直径文字标注
    /*****************************************************************************/  
    makerjs.model.addPath(myThHolesXMarkALeftdn, new makerjs.paths.Line([-x0,y0+h+markShiftTwo], [0, y0+h+markShiftTwo]));//X短标线
    makerjs.model.addCaption(myThHolesXMarkALeftdn, TxtX, [-x0,y0+h+markShiftFour], [0, y0+h+markShiftFour]);//X文字标注
    /*****************************************************************************/ 
    makerjs.model.addPath(myThHolesYMarkALeftdn, new makerjs.paths.Line([w+d+markShiftTwo,0], [w+d+markShiftTwo, h]));//Y短标线
    makerjs.model.addCaption(myThHolesYMarkALeftdn, TxtH, [w+d+markShiftFour,0], [w+d+markShiftFour, h]);//Y文字标注
    /*****************************************************************************/ 
    /*****************************************************************************/ 
     //标记三孔 A位置孔右下
     var myThHolesARightdn= new makerjs.models.Holes(d/2, [[xa+w, ya-h]]);
          var myThHolesMarkARightdn = new makerjs.models.Square(0); //孔的原点       
          myThHolesMarkARightdn.origin = [xa+w, ya-h];      
          var myThHolesXMarkARightdn = new makerjs.models.Square(0);      
          myThHolesXMarkARightdn.origin =[xa+w, ya-h];      
          var myThHolesYMarkARightdn = new makerjs.models.Square(0);      
          myThHolesYMarkARightdn.origin = [xa+w, ya-h]; 
    /*****************************************************************************/ 
    makerjs.model.addPath(myThHolesMarkARightdn, new makerjs.paths.Line([0,0], [d+markShiftFour, 0]));//从孔圆心出发X方向横向标线
    /*****************************************************************************/ 
    makerjs.model.addPath(myThHolesMarkARightdn, new makerjs.paths.Line([0,0], [d, -d]));//直径标线
    makerjs.model.addCaption(myThHolesMarkARightdn, TxtD, [0,0], [d+markHolesOneD, -d-markHolesOneD]);//直径文字标注
    /*****************************************************************************/  
    /*****************************************************************************/
    plate.models["myThHolesALeftdn"+myHolesNum]=myThHolesALeftdn;
    markers.models["myThHolesALeftdn"+myHolesNum]=myThHolesALeftdn;
    markers.models["myThHolesMarkALeftdn"+myHolesNum]=myThHolesMarkALeftdn;
    markers.models["myThHolesXMarkALeftdn"+myHolesNum]=myThHolesXMarkALeftdn;
    markers.models["myThHolesYMarkALeftdn"+myHolesNum]=myThHolesYMarkALeftdn;
    /*****************************************************************************/ 
    plate.models["myThHolesARightup"+myHolesNum]=myThHolesARightup;
    markers.models["myThHolesARightup"+myHolesNum]=myThHolesARightup;
    markers.models["myThHolesMarkARightup"+myHolesNum]=myThHolesMarkARightup;
    markers.models["myThHolesXMarkARightup"+myHolesNum]=myThHolesXMarkARightup;
    /*****************************************************************************/ 
    plate.models["myThHolesARightdn"+myHolesNum]=myThHolesARightdn;
    markers.models["myThHolesARightdn"+myHolesNum]=myThHolesARightdn;
    markers.models["myThHolesMarkARightdn"+myHolesNum]=myThHolesMarkARightdn;
    /*****************************************************************************/ 
    /*****************************************************************************/ 
    
    }else if(area==4){
    //画孔D位置孔
          //标记三孔 D位置孔右上
          var myThHolesDRightup= new makerjs.models.Holes(d/2, [[xd+w, yd+h/2]]);
          var myThHolesMarkDRightup = new makerjs.models.Square(0); //孔的原点       
          myThHolesMarkDRightup.origin = [xd+w, yd+h/2];      
          var myThHolesXMarkDRightup = new makerjs.models.Square(0);      
          myThHolesXMarkDRightup.origin =[xd+w, yd+h/2];   
    /*****************************************************************************/ 
    makerjs.model.addPath(myThHolesMarkDRightup, new makerjs.paths.Line([0,0], [d+markShiftFour, 0]));//从孔圆心出发X方向横向标线
    makerjs.model.addPath(myThHolesMarkDRightup, new makerjs.paths.Line([0,0], [0, yd-h/2+markShiftFour]));//从孔圆心出发Y方向竖向标线
    /*****************************************************************************/ 
    makerjs.model.addPath(myThHolesMarkDRightup, new makerjs.paths.Line([0,0], [d, -d]));//直径标线
    makerjs.model.addCaption(myThHolesMarkDRightup, TxtD, [0,0], [d+markHolesOneD, -d-markHolesOneD]);//直径文字标注
    /*****************************************************************************/   
    makerjs.model.addPath(myThHolesXMarkDRightup, new makerjs.paths.Line([0,yd-h/2+markShiftTwo], [-w, yd-h/2+markShiftTwo]));//X短标线
    makerjs.model.addCaption(myThHolesXMarkDRightup, TxtW, [-w,yd-h/2+markShiftFour], [0, yd-h/2+markShiftFour]);//X文字标注
    /*****************************************************************************/  
    /*****************************************************************************/ 
     //标记三孔D位置孔左下
     var myThHolesDLeftdn= new makerjs.models.Holes(d/2, [[xd, yd-h/2]]);
          var myThHolesMarkDLeftdn = new makerjs.models.Square(0); //孔的原点       
          myThHolesMarkDLeftdn.origin = [xd, yd-h/2];       
          var myThHolesXMarkDLeftdn = new makerjs.models.Square(0);      
          myThHolesXMarkDLeftdn.origin = [xd, yd-h/2];       
          var myThHolesYMarkDLeftdn = new makerjs.models.Square(0);      
          myThHolesYMarkDLeftdn.origin = [xd, yd-h/2];  
    /*****************************************************************************/ 
    makerjs.model.addPath(myThHolesMarkDLeftdn, new makerjs.paths.Line([-x0,yd+h/2], [-x0, yd+h/2+markShiftFour]));//Y轴方向标注加长线
    makerjs.model.addPath(myThHolesMarkDLeftdn, new makerjs.paths.Line([0,0], [w, 0]));//从孔圆心出发X方向横向标线
    makerjs.model.addPath(myThHolesMarkDLeftdn, new makerjs.paths.Line([0,0], [-x0, 0]));//从孔圆心出发X方向横向标线
    makerjs.model.addPath(myThHolesMarkDLeftdn, new makerjs.paths.Line([0,0], [0, yd+h/2+markShiftFour]));//从孔圆心出发Y方向竖向标线
    /*****************************************************************************/ 
    makerjs.model.addPath(myThHolesMarkDLeftdn, new makerjs.paths.Line([0,0], [d, -d]));//直径标线
    makerjs.model.addCaption(myThHolesMarkDLeftdn, TxtD, [0,0], [d+markHolesOneD, -d-markHolesOneD]);//直径文字标注
    /*****************************************************************************/  
    makerjs.model.addPath(myThHolesXMarkDLeftdn, new makerjs.paths.Line([-x0,yd+h/2+markShiftTwo], [0, yd+h/2+markShiftTwo]));//X短标线
    makerjs.model.addCaption(myThHolesXMarkDLeftdn, TxtX, [-x0,yd+h/2+markShiftFour], [0, yd+h/2+markShiftFour]);//X文字标注
    /*****************************************************************************/ 
    makerjs.model.addPath(myThHolesYMarkDLeftdn, new makerjs.paths.Line([w+d+markShiftTwo,0], [w+d+markShiftTwo, h]));//Y短标线
    makerjs.model.addCaption(myThHolesYMarkDLeftdn, TxtH, [w+d+markShiftFour,0], [w+d+markShiftFour, h]);//Y文字标注
    /*****************************************************************************/ 
    /*****************************************************************************/ 
     //标记三孔 D位置孔右下
     var myThHolesDRightdn= new makerjs.models.Holes(d/2, [[xd+w, yd-h/2]]);
          var myThHolesMarkDRightdn = new makerjs.models.Square(0); //孔的原点       
          myThHolesMarkDRightdn.origin = [xd+w, yd-h/2];      
          var myThHolesXMarkDRightdn = new makerjs.models.Square(0);      
          myThHolesXMarkDRightdn.origin =[xd+w, yd-h/2];      
          var myThHolesYMarkDRightdn = new makerjs.models.Square(0);      
          myThHolesYMarkDRightdn.origin = [xd+w, yd-h/2]; 
    /*****************************************************************************/ 
    makerjs.model.addPath(myThHolesMarkDRightdn, new makerjs.paths.Line([0,0], [d+markShiftFour, 0]));//从孔圆心出发X方向横向标线
    /*****************************************************************************/ 
    makerjs.model.addPath(myThHolesMarkDRightdn, new makerjs.paths.Line([0,0], [d, -d]));//直径标线
    makerjs.model.addCaption(myThHolesMarkDRightdn, TxtD, [0,0], [d+markHolesOneD, -d-markHolesOneD]);//直径文字标注
    /*****************************************************************************/  
    /*****************************************************************************/
    plate.models["myThHolesDLeftdn"+myHolesNum]=myThHolesDLeftdn;
    markers.models["myThHolesDLeftdn"+myHolesNum]=myThHolesDLeftdn;
    markers.models["myThHolesMarkDLeftdn"+myHolesNum]=myThHolesMarkDLeftdn;
    markers.models["myThHolesXMarkDLeftdn"+myHolesNum]=myThHolesXMarkDLeftdn;
    markers.models["myThHolesYMarkDLeftdn"+myHolesNum]=myThHolesYMarkDLeftdn;
    /*****************************************************************************/ 
    plate.models["myThHolesDRightup"+myHolesNum]=myThHolesDRightup;
    markers.models["myThHolesDRightup"+myHolesNum]=myThHolesDRightup;
    markers.models["myThHolesMarkDRightup"+myHolesNum]=myThHolesMarkDRightup;
    markers.models["myThHolesXMarkDRightup"+myHolesNum]=myThHolesXMarkDRightup;
    /*****************************************************************************/ 
    plate.models["myThHolesDRightdn"+myHolesNum]=myThHolesDRightdn;
    markers.models["myThHolesDRightdn"+myHolesNum]=myThHolesDRightdn;
    markers.models["myThHolesMarkDRightdn"+myHolesNum]=myThHolesMarkDRightdn;
    /*****************************************************************************/ 
    /*****************************************************************************/ 
  
    }else if(area==6){
    //画孔F位置孔
          //标记三孔 F位置孔左上
          var myThHolesFLeftup= new makerjs.models.Holes(d/2, [[xf-w, yf+h/2]]);
          var myThHolesMarkFLeftup = new makerjs.models.Square(0); //孔的原点       
          myThHolesMarkFLeftup.origin = [xf-w, yf+h/2];      
          var myThHolesXMarkFLeftup = new makerjs.models.Square(0);      
          myThHolesXMarkFLeftup.origin =[xf-w, yf+h/2];   
    /*****************************************************************************/ 
    makerjs.model.addPath(myThHolesMarkFLeftup, new makerjs.paths.Line([0,0], [-d-markShiftFour, 0]));//从孔圆心出发X方向横向标线
    makerjs.model.addPath(myThHolesMarkFLeftup, new makerjs.paths.Line([0,0], [0, yd-h/2+markShiftFour]));//从孔圆心出发Y方向竖向标线
    /*****************************************************************************/ 
    makerjs.model.addPath(myThHolesMarkFLeftup, new makerjs.paths.Line([0,0], [d, -d]));//直径标线
    makerjs.model.addCaption(myThHolesMarkFLeftup, TxtD, [0,0], [d+markHolesOneD, -d-markHolesOneD]);//直径文字标注
    /*****************************************************************************/   
    makerjs.model.addPath(myThHolesXMarkFLeftup, new makerjs.paths.Line([0,yd-h/2+markShiftTwo], [w, yd-h/2+markShiftTwo]));//X短标线
    makerjs.model.addCaption(myThHolesXMarkFLeftup, TxtW, [0, yd-h/2+markShiftFour], [w,yd-h/2+markShiftFour]);//X文字标注
    /*****************************************************************************/  
    /*****************************************************************************/ 
     //标记三孔F位置孔右下
     var myThHolesFRightdn= new makerjs.models.Holes(d/2, [[xf, yf-h/2]]);
          var myThHolesMarkFRightdn = new makerjs.models.Square(0); //孔的原点       
          myThHolesMarkFRightdn.origin = [xf, yf-h/2];       
          var myThHolesXMarkFRightdn = new makerjs.models.Square(0);      
          myThHolesXMarkFRightdn.origin = [xf, yf-h/2];       
          var myThHolesYMarkFRightdn = new makerjs.models.Square(0);      
          myThHolesYMarkFRightdn.origin = [xf, yf-h/2];  
    /*****************************************************************************/ 
    makerjs.model.addPath(myThHolesMarkFRightdn, new makerjs.paths.Line([x0,yd+h/2], [x0, yd+h/2+markShiftFour]));//Y轴方向标注加长线
    makerjs.model.addPath(myThHolesMarkFRightdn, new makerjs.paths.Line([0,0], [-w, 0]));//从孔圆心出发X方向横向标线
    makerjs.model.addPath(myThHolesMarkFRightdn, new makerjs.paths.Line([0,0], [x0, 0]));//从孔圆心出发X方向横向标线
    makerjs.model.addPath(myThHolesMarkFRightdn, new makerjs.paths.Line([0,0], [0, yd+h/2+markShiftFour]));//从孔圆心出发Y方向竖向标线
    /*****************************************************************************/ 
    makerjs.model.addPath(myThHolesMarkFRightdn, new makerjs.paths.Line([0,0], [d, -d]));//直径标线
    makerjs.model.addCaption(myThHolesMarkFRightdn, TxtD, [0,0], [d+markHolesOneD, -d-markHolesOneD]);//直径文字标注
    /*****************************************************************************/  
    makerjs.model.addPath(myThHolesXMarkFRightdn, new makerjs.paths.Line([x0,yd+h/2+markShiftTwo], [0, yd+h/2+markShiftTwo]));//X短标线
    makerjs.model.addCaption(myThHolesXMarkFRightdn, TxtX, [0, yd+h/2+markShiftFour], [x0,yd+h/2+markShiftFour]);//X文字标注
    /*****************************************************************************/ 
    makerjs.model.addPath(myThHolesYMarkFRightdn, new makerjs.paths.Line([-w-d-markShiftTwo,0], [-w-d-markShiftTwo, h]));//Y短标线
    makerjs.model.addCaption(myThHolesYMarkFRightdn, TxtH, [-w-d-markShiftFour,0], [-w-d-markShiftFour, h]);//Y文字标注
    /*****************************************************************************/ 
    /*****************************************************************************/ 
     //标记三孔 F位置孔左下
     var myThHolesFLeftdn= new makerjs.models.Holes(d/2, [[xf-w, yf-h/2]]);
          var myThHolesMarkFLeftdn = new makerjs.models.Square(0); //孔的原点       
          myThHolesMarkFLeftdn.origin = [xf-w, yf-h/2];      
          var myThHolesXMarkFLeftdn = new makerjs.models.Square(0);      
          myThHolesXMarkFLeftdn.origin =[xf-w, yf-h/2];      
          var myThHolesYMarkFLeftdn = new makerjs.models.Square(0);      
          myThHolesYMarkFLeftdn.origin = [xf-w, yf-h/2]; 
    /*****************************************************************************/ 
    makerjs.model.addPath(myThHolesMarkFLeftdn, new makerjs.paths.Line([0,0], [-d-markShiftFour, 0]));//从孔圆心出发X方向横向标线
    /*****************************************************************************/ 
    makerjs.model.addPath(myThHolesMarkFLeftdn, new makerjs.paths.Line([0,0], [d, -d]));//直径标线
    makerjs.model.addCaption(myThHolesMarkFLeftdn, TxtD, [0,0], [d+markHolesOneD, -d-markHolesOneD]);//直径文字标注
    /*****************************************************************************/  
    /*****************************************************************************/
    plate.models["myThHolesFRightdn"+myHolesNum]=myThHolesFRightdn;
    markers.models["myThHolesFRightdn"+myHolesNum]=myThHolesFRightdn;
    markers.models["myThHolesMarkFRightdn"+myHolesNum]=myThHolesMarkFRightdn;
    markers.models["myThHolesXMarkFRightdn"+myHolesNum]=myThHolesXMarkFRightdn;
    markers.models["myThHolesYMarkFRightdn"+myHolesNum]=myThHolesYMarkFRightdn;
    /*****************************************************************************/ 
    plate.models["myThHolesFLeftup"+myHolesNum]=myThHolesFLeftup;
    markers.models["myThHolesFLeftup"+myHolesNum]=myThHolesFLeftup;
    markers.models["myThHolesMarkFLeftup"+myHolesNum]=myThHolesMarkFLeftup;
    markers.models["myThHolesXMarkFLeftup"+myHolesNum]=myThHolesXMarkFLeftup;
    /*****************************************************************************/ 
    plate.models["myThHolesFLeftdn"+myHolesNum]=myThHolesFLeftdn;
    markers.models["myThHolesFLeftdn"+myHolesNum]=myThHolesFLeftdn;
    markers.models["myThHolesMarkFLeftdn"+myHolesNum]=myThHolesMarkFLeftdn;
    /*****************************************************************************/ 
    /*****************************************************************************/ 
  
    }
/*****************************************************************************/ 

myHolesNum++;
return{plate, markers,myHolesNum}
};


export default ThreeHoles;