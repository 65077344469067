//17、三孔2
import makerjs from "makerjs";
// inputParam,model,myHolesNum,outParam,fixedParam
// (D,X,Y,area,plate,markers,myHolesNum,glassW,glassH,markShift,markShiftTwo,markShiftThree,markShiftFour,markHolesOneD)
function ThreeHoles2(inputParam,model,myHolesNum,outParam,fixedParam,zoom){
var D=inputParam.D,W=inputParam.W,H=inputParam.H,X=inputParam.X,Y=inputParam.Y,area=inputParam.area,myZoom=zoom*1,
plate=model.plate,markers=model.markers,
glassW=outParam.glassW,glassH=outParam.glassH,
   markShift=fixedParam.markShift,markShiftTwo=fixedParam.markShiftTwo,
   markShiftThree=fixedParam.markShiftThree,markShiftFour=fixedParam.markShiftFour,markHolesOneD=fixedParam.markHolesOneD,
 
   d = D*1*myZoom,
      w= W*1*myZoom,
      h= H*1*myZoom,
        x0 = X*1*myZoom,
        y0 = Y*1*myZoom; 
        /*
        d = D*1,
        w= W*1,
        h= H*1,
          x0 = X*1,
          y0 = Y*1;
          */
       if(d!==0){
         var TxtD="∮"+D;
        }
        if(h!==0){
         var TxtH=H;
        }
        if(w!==0){
         var TxtW=W;
        }
        if(x0!==0){          
        var TxtX=X;
        }
        if(y0!==0){          
         var TxtY=Y;
        }
  //在A区域 计算孔的位置       
  var xa=x0,ya=glassH * 1-y0,
  //B区域//只有Y标注没有X标注 
  xb=glassW * 1/2,yb=glassH * 1-y0,
  //C区域
  xc=glassW * 1-x0,yc=glassH * 1-y0,
  //D区域
  //只有X标注没有Y标注 
  xd=x0,yd=glassH * 1/2,
  //E区域
  //只有X标注没有Y标注 
  xe=glassW * 1/2,ye=glassH * 1/2,
  //F区域
  //只有X标注没有Y标注 
  xf=glassW * 1-x0,yf=glassH * 1/2,
  //在G区域 计算孔的位置
  xg=x0,yg=y0,
  //在H区域 计算孔的位置
  //只标注Y
  xh=glassW*1/2,yh=y0,
  //在I区域 计算孔的位置
  xi=glassW*1-x0,yi=y0;
  if(area==1){
    //画孔 A位置孔
          //标记三孔 A位置孔左上
          var myThHoles2ALeftup= new makerjs.models.Holes(d/2, [[xa, ya]]);
          var myThHoles2MarkALeftup = new makerjs.models.Square(0); //孔的原点       
          myThHoles2MarkALeftup.origin = [xa, ya];      
          var myThHoles2XMarkALeftup = new makerjs.models.Square(0);      
          myThHoles2XMarkALeftup.origin =[xa, ya];   
    /*****************************************************************************/ 
    makerjs.model.addPath(myThHoles2MarkALeftup, new makerjs.paths.Line([0,0], [d+markShiftFour, 0]));//从孔圆心出发X方向横向标线
    makerjs.model.addPath(myThHoles2MarkALeftup, new makerjs.paths.Line([0,0], [0, y0+markShiftFour]));//从孔圆心出发Y方向竖向标线
    /*****************************************************************************/ 
    makerjs.model.addPath(myThHoles2MarkALeftup, new makerjs.paths.Line([0,0], [d, -d]));//直径标线
    makerjs.model.addCaption(myThHoles2MarkALeftup, TxtD, [0,0], [d+markHolesOneD, -d-markHolesOneD]);//直径文字标注
    /*****************************************************************************/   
    makerjs.model.addPath(myThHoles2XMarkALeftup, new makerjs.paths.Line([0,y0+markShiftTwo], [-w, y0+markShiftTwo]));//X短标线
    makerjs.model.addCaption(myThHoles2XMarkALeftup, TxtW, [-w,y0+markShiftFour], [0, y0+markShiftFour]);//X文字标注
    /*****************************************************************************/  
    /*****************************************************************************/ 
     //标记三孔 A位置孔左下
     var myThHoles2ALeftdn= new makerjs.models.Holes(d/2, [[xa, ya-h]]);
          var myThHoles2MarkALeftdn = new makerjs.models.Square(0); //孔的原点       
          myThHoles2MarkALeftdn.origin = [xa, ya-h];       
          var myThHoles2XMarkALeftdn = new makerjs.models.Square(0);      
          myThHoles2XMarkALeftdn.origin = [xa, ya-h];       
          var myThHoles2YMarkALeftdn = new makerjs.models.Square(0);      
          myThHoles2YMarkALeftdn.origin = [xa, ya-h];  
    /*****************************************************************************/ 
    makerjs.model.addPath(myThHoles2MarkALeftdn, new makerjs.paths.Line([-x0,y0+h], [-x0, y0+h+markShiftFour]));//Y轴方向标注加长线
    makerjs.model.addPath(myThHoles2MarkALeftdn, new makerjs.paths.Line([0,0], [w, 0]));//从孔圆心出发X方向横向标线
    makerjs.model.addPath(myThHoles2MarkALeftdn, new makerjs.paths.Line([0,0], [-x0, 0]));//从孔圆心出发X方向横向标线
    makerjs.model.addPath(myThHoles2MarkALeftdn, new makerjs.paths.Line([0,0], [0, y0+h+markShiftFour]));//从孔圆心出发Y方向竖向标线
    /*****************************************************************************/ 
    makerjs.model.addPath(myThHoles2MarkALeftdn, new makerjs.paths.Line([0,0], [d, -d]));//直径标线
    makerjs.model.addCaption(myThHoles2MarkALeftdn, TxtD, [0,0], [d+markHolesOneD, -d-markHolesOneD]);//直径文字标注
    /*****************************************************************************/  
    makerjs.model.addPath(myThHoles2XMarkALeftdn, new makerjs.paths.Line([-x0,y0+h+markShiftTwo], [0, y0+h+markShiftTwo]));//X短标线
    makerjs.model.addCaption(myThHoles2XMarkALeftdn, TxtX, [-x0,y0+h+markShiftFour], [0, y0+h+markShiftFour]);//X文字标注
    /*****************************************************************************/ 
    makerjs.model.addPath(myThHoles2YMarkALeftdn, new makerjs.paths.Line([w+d+markShiftTwo,0], [w+d+markShiftTwo, h]));//Y短标线
    makerjs.model.addCaption(myThHoles2YMarkALeftdn, TxtH, [w+d+markShiftFour,0], [w+d+markShiftFour, h]);//Y文字标注
    /*****************************************************************************/ 
    /*****************************************************************************/ 
     //标记三孔 A位置孔右下
     var myThHoles2ARightdn= new makerjs.models.Holes(d/2, [[xa+w, ya-h]]);
          var myThHoles2MarkARightdn = new makerjs.models.Square(0); //孔的原点       
          myThHoles2MarkARightdn.origin = [xa+w, ya-h];      
          var myThHoles2XMarkARightdn = new makerjs.models.Square(0);      
          myThHoles2XMarkARightdn.origin =[xa+w, ya-h];      
          var myThHoles2YMarkARightdn = new makerjs.models.Square(0);      
          myThHoles2YMarkARightdn.origin = [xa+w, ya-h]; 
    /*****************************************************************************/ 
    makerjs.model.addPath(myThHoles2MarkARightdn, new makerjs.paths.Line([0,0], [d+markShiftFour, 0]));//从孔圆心出发X方向横向标线
    /*****************************************************************************/ 
    makerjs.model.addPath(myThHoles2MarkARightdn, new makerjs.paths.Line([0,0], [d, -d]));//直径标线
    makerjs.model.addCaption(myThHoles2MarkARightdn, TxtD, [0,0], [d+markHolesOneD, -d-markHolesOneD]);//直径文字标注
    /*****************************************************************************/  
    /*****************************************************************************/
    plate.models["myThHoles2ALeftdn"+myHolesNum]=myThHoles2ALeftdn;
    markers.models["myThHoles2ALeftdn"+myHolesNum]=myThHoles2ALeftdn;
    markers.models["myThHoles2MarkALeftdn"+myHolesNum]=myThHoles2MarkALeftdn;
    markers.models["myThHoles2XMarkALeftdn"+myHolesNum]=myThHoles2XMarkALeftdn;
    markers.models["myThHoles2YMarkALeftdn"+myHolesNum]=myThHoles2YMarkALeftdn;
    /*****************************************************************************/ 
    plate.models["myThHoles2ALeftup"+myHolesNum]=myThHoles2ALeftup;
    markers.models["myThHoles2ALeftup"+myHolesNum]=myThHoles2ALeftup;
    markers.models["myThHoles2MarkALeftup"+myHolesNum]=myThHoles2MarkALeftup;
    markers.models["myThHoles2XMarkALeftup"+myHolesNum]=myThHoles2XMarkALeftup;
    /*****************************************************************************/ 
    plate.models["myThHoles2ARightdn"+myHolesNum]=myThHoles2ARightdn;
    markers.models["myThHoles2ARightdn"+myHolesNum]=myThHoles2ARightdn;
    markers.models["myThHoles2MarkARightdn"+myHolesNum]=myThHoles2MarkARightdn;
    /*****************************************************************************/ 
    /*****************************************************************************/ 
        
    }else if(area==4){
    //画孔D位置孔
          //标记三孔 D位置孔右上
          var myThHoles2DRightup= new makerjs.models.Holes(d/2, [[xd, yd+h/2]]);
          var myThHoles2MarkDRightup = new makerjs.models.Square(0); //孔的原点       
          myThHoles2MarkDRightup.origin = [xd, yd+h/2];      
          var myThHoles2XMarkDRightup = new makerjs.models.Square(0);      
          myThHoles2XMarkDRightup.origin =[xd, yd+h/2];   
    /*****************************************************************************/ 
    makerjs.model.addPath(myThHoles2MarkDRightup, new makerjs.paths.Line([0,0], [w+d+markShiftFour, 0]));//从孔圆心出发X方向横向标线
    makerjs.model.addPath(myThHoles2MarkDRightup, new makerjs.paths.Line([0,0], [0, yd-h/2+markShiftFour]));//从孔圆心出发Y方向竖向标线
    /*****************************************************************************/ 
    makerjs.model.addPath(myThHoles2MarkDRightup, new makerjs.paths.Line([0,0], [d, -d]));//直径标线
    makerjs.model.addCaption(myThHoles2MarkDRightup, TxtD, [0,0], [d+markHolesOneD, -d-markHolesOneD]);//直径文字标注
    /*****************************************************************************/   
    makerjs.model.addPath(myThHoles2XMarkDRightup, new makerjs.paths.Line([0,yd-h/2+markShiftTwo], [w, yd-h/2+markShiftTwo]));//X短标线
    makerjs.model.addCaption(myThHoles2XMarkDRightup, TxtW, [0, yd-h/2+markShiftFour], [w,yd-h/2+markShiftFour]);//X文字标注
    /*****************************************************************************/  
    /*****************************************************************************/ 
     //标记三孔D位置孔左下
     var myThHoles2DLeftdn= new makerjs.models.Holes(d/2, [[xd, yd-h/2]]);
          var myThHoles2MarkDLeftdn = new makerjs.models.Square(0); //孔的原点       
          myThHoles2MarkDLeftdn.origin = [xd, yd-h/2];       
          var myThHoles2XMarkDLeftdn = new makerjs.models.Square(0);      
          myThHoles2XMarkDLeftdn.origin = [xd, yd-h/2];       
          var myThHoles2YMarkDLeftdn = new makerjs.models.Square(0);      
          myThHoles2YMarkDLeftdn.origin = [xd, yd-h/2];  
    /*****************************************************************************/ 
    makerjs.model.addPath(myThHoles2MarkDLeftdn, new makerjs.paths.Line([-x0,yd+h/2], [-x0, yd+h/2+markShiftFour]));//Y轴方向标注加长线
    makerjs.model.addPath(myThHoles2MarkDLeftdn, new makerjs.paths.Line([0,0], [w, 0]));//从孔圆心出发X方向横向标线
    makerjs.model.addPath(myThHoles2MarkDLeftdn, new makerjs.paths.Line([0,0], [-x0, 0]));//从孔圆心出发X方向横向标线
    /*****************************************************************************/ 
    makerjs.model.addPath(myThHoles2MarkDLeftdn, new makerjs.paths.Line([0,0], [d, -d]));//直径标线
    makerjs.model.addCaption(myThHoles2MarkDLeftdn, TxtD, [0,0], [d+markHolesOneD, -d-markHolesOneD]);//直径文字标注
    /*****************************************************************************/  
    makerjs.model.addPath(myThHoles2XMarkDLeftdn, new makerjs.paths.Line([-x0,yd+h/2+markShiftTwo], [0, yd+h/2+markShiftTwo]));//X短标线
    makerjs.model.addCaption(myThHoles2XMarkDLeftdn, TxtX, [-x0,yd+h/2+markShiftFour], [0, yd+h/2+markShiftFour]);//X文字标注
    /*****************************************************************************/ 
    makerjs.model.addPath(myThHoles2YMarkDLeftdn, new makerjs.paths.Line([w+d+markShiftTwo,0], [w+d+markShiftTwo, h]));//Y短标线
    makerjs.model.addCaption(myThHoles2YMarkDLeftdn, TxtH, [w+d+markShiftFour,0], [w+d+markShiftFour, h]);//Y文字标注
    /*****************************************************************************/ 
    /*****************************************************************************/ 
     //标记三孔 D位置孔右下
     var myThHoles2DRightdn= new makerjs.models.Holes(d/2, [[xd+w, yd-h/2]]);
          var myThHoles2MarkDRightdn = new makerjs.models.Square(0); //孔的原点       
          myThHoles2MarkDRightdn.origin = [xd+w, yd-h/2];      
          var myThHoles2XMarkDRightdn = new makerjs.models.Square(0);      
          myThHoles2XMarkDRightdn.origin =[xd+w, yd-h/2];      
          var myThHoles2YMarkDRightdn = new makerjs.models.Square(0);      
          myThHoles2YMarkDRightdn.origin = [xd+w, yd-h/2]; 
    /*****************************************************************************/ 
    makerjs.model.addPath(myThHoles2MarkDRightdn, new makerjs.paths.Line([0,0], [d+markShiftFour, 0]));//从孔圆心出发X方向横向标线
    makerjs.model.addPath(myThHoles2MarkDRightdn, new makerjs.paths.Line([0,0], [0, yd+h/2+markShiftFour]));//从孔圆心出发Y方向竖向标线
    /*****************************************************************************/ 
    makerjs.model.addPath(myThHoles2MarkDRightdn, new makerjs.paths.Line([0,0], [d, -d]));//直径标线
    makerjs.model.addCaption(myThHoles2MarkDRightdn, TxtD, [0,0], [d+markHolesOneD, -d-markHolesOneD]);//直径文字标注
    /*****************************************************************************/  
    /*****************************************************************************/
    plate.models["myThHoles2DLeftdn"+myHolesNum]=myThHoles2DLeftdn;
    markers.models["myThHoles2DLeftdn"+myHolesNum]=myThHoles2DLeftdn;
    markers.models["myThHoles2MarkDLeftdn"+myHolesNum]=myThHoles2MarkDLeftdn;
    markers.models["myThHoles2XMarkDLeftdn"+myHolesNum]=myThHoles2XMarkDLeftdn;
    markers.models["myThHoles2YMarkDLeftdn"+myHolesNum]=myThHoles2YMarkDLeftdn;
    /*****************************************************************************/ 
    plate.models["myThHoles2DRightup"+myHolesNum]=myThHoles2DRightup;
    markers.models["myThHoles2DRightup"+myHolesNum]=myThHoles2DRightup;
    markers.models["myThHoles2MarkDRightup"+myHolesNum]=myThHoles2MarkDRightup;
    markers.models["myThHoles2XMarkDRightup"+myHolesNum]=myThHoles2XMarkDRightup;
    /*****************************************************************************/ 
    plate.models["myThHoles2DRightdn"+myHolesNum]=myThHoles2DRightdn;
    markers.models["myThHoles2DRightdn"+myHolesNum]=myThHoles2DRightdn;
    markers.models["myThHoles2MarkDRightdn"+myHolesNum]=myThHoles2MarkDRightdn;
    /*****************************************************************************/ 
    /*****************************************************************************/ 
        
    }else if(area==6){
    //画孔F位置孔
          //标记三孔 F位置孔右上
          var myThHoles2FRightup= new makerjs.models.Holes(d/2, [[xf, yf+h/2]]);
          var myThHoles2MarkFRightup = new makerjs.models.Square(0); //孔的原点       
          myThHoles2MarkFRightup.origin = [xf, yf+h/2];      
          var myThHoles2XMarkFRightup = new makerjs.models.Square(0);      
          myThHoles2XMarkFRightup.origin =[xf, yf+h/2];   
    /*****************************************************************************/ 
    makerjs.model.addPath(myThHoles2MarkFRightup, new makerjs.paths.Line([0,0], [-d-w-markShiftFour, 0]));//从孔圆心出发X方向横向标线
    makerjs.model.addPath(myThHoles2MarkFRightup, new makerjs.paths.Line([0,0], [0, yd-h/2+markShiftFour]));//从孔圆心出发Y方向竖向标线
    /*****************************************************************************/ 
    makerjs.model.addPath(myThHoles2MarkFRightup, new makerjs.paths.Line([0,0], [d, -d]));//直径标线
    makerjs.model.addCaption(myThHoles2MarkFRightup, TxtD, [0,0], [d+markHolesOneD, -d-markHolesOneD]);//直径文字标注
    /*****************************************************************************/   
    makerjs.model.addPath(myThHoles2XMarkFRightup, new makerjs.paths.Line([0,yd-h/2+markShiftTwo], [-w, yd-h/2+markShiftTwo]));//X短标线
    makerjs.model.addCaption(myThHoles2XMarkFRightup, TxtW, [-w,yd-h/2+markShiftFour], [0, yd-h/2+markShiftFour]);//X文字标注
    /*****************************************************************************/  
    /*****************************************************************************/ 
     //标记三孔F位置孔右下
     var myThHoles2FRightdn= new makerjs.models.Holes(d/2, [[xf, yf-h/2]]);
          var myThHoles2MarkFRightdn = new makerjs.models.Square(0); //孔的原点       
          myThHoles2MarkFRightdn.origin = [xf, yf-h/2];       
          var myThHoles2XMarkFRightdn = new makerjs.models.Square(0);      
          myThHoles2XMarkFRightdn.origin = [xf, yf-h/2];       
          var myThHoles2YMarkFRightdn = new makerjs.models.Square(0);      
          myThHoles2YMarkFRightdn.origin = [xf, yf-h/2];  
    /*****************************************************************************/ 
    makerjs.model.addPath(myThHoles2MarkFRightdn, new makerjs.paths.Line([x0,yd+h/2], [x0, yd+h/2+markShiftFour]));//Y轴方向标注加长线
    makerjs.model.addPath(myThHoles2MarkFRightdn, new makerjs.paths.Line([0,0], [-w, 0]));//从孔圆心出发X方向横向标线
    makerjs.model.addPath(myThHoles2MarkFRightdn, new makerjs.paths.Line([0,0], [x0, 0]));//从孔圆心出发X方向横向标线
    /*****************************************************************************/ 
    makerjs.model.addPath(myThHoles2MarkFRightdn, new makerjs.paths.Line([0,0], [d, -d]));//直径标线
    makerjs.model.addCaption(myThHoles2MarkFRightdn, TxtD, [0,0], [d+markHolesOneD, -d-markHolesOneD]);//直径文字标注
    /*****************************************************************************/  
    makerjs.model.addPath(myThHoles2XMarkFRightdn, new makerjs.paths.Line([x0,yd+h/2+markShiftTwo], [0, yd+h/2+markShiftTwo]));//X短标线
    makerjs.model.addCaption(myThHoles2XMarkFRightdn, TxtX, [0, yd+h/2+markShiftFour], [x0,yd+h/2+markShiftFour]);//X文字标注
    /*****************************************************************************/ 
    makerjs.model.addPath(myThHoles2YMarkFRightdn, new makerjs.paths.Line([-w-d-markShiftTwo,0], [-w-d-markShiftTwo, h]));//Y短标线
    makerjs.model.addCaption(myThHoles2YMarkFRightdn, TxtH, [-w-d-markShiftFour,0], [-w-d-markShiftFour, h]);//Y文字标注
    /*****************************************************************************/ 
    /*****************************************************************************/ 
     //标记三孔 F位置孔左下
     var myThHoles2FLeftdn= new makerjs.models.Holes(d/2, [[xf-w, yf-h/2]]);
          var myThHoles2MarkFLeftdn = new makerjs.models.Square(0); //孔的原点       
          myThHoles2MarkFLeftdn.origin = [xf-w, yf-h/2];      
          var myThHoles2XMarkFLeftdn = new makerjs.models.Square(0);      
          myThHoles2XMarkFLeftdn.origin =[xf-w, yf-h/2];      
          var myThHoles2YMarkFLeftdn = new makerjs.models.Square(0);      
          myThHoles2YMarkFLeftdn.origin = [xf-w, yf-h/2]; 
    /*****************************************************************************/ 
    makerjs.model.addPath(myThHoles2MarkFLeftdn, new makerjs.paths.Line([0,0], [0, yd+h/2+markShiftFour]));//从孔圆心出发Y方向竖向标线
    makerjs.model.addPath(myThHoles2MarkFLeftdn, new makerjs.paths.Line([0,0], [-d-markShiftFour, 0]));//从孔圆心出发X方向横向标线
    /*****************************************************************************/ 
    makerjs.model.addPath(myThHoles2MarkFLeftdn, new makerjs.paths.Line([0,0], [d, -d]));//直径标线
    makerjs.model.addCaption(myThHoles2MarkFLeftdn, TxtD, [0,0], [d+markHolesOneD, -d-markHolesOneD]);//直径文字标注
    /*****************************************************************************/  
    /*****************************************************************************/
    plate.models["myThHoles2FRightdn"+myHolesNum]=myThHoles2FRightdn;
    markers.models["myThHoles2FRightdn"+myHolesNum]=myThHoles2FRightdn;
    markers.models["myThHoles2MarkFRightdn"+myHolesNum]=myThHoles2MarkFRightdn;
    markers.models["myThHoles2XMarkFRightdn"+myHolesNum]=myThHoles2XMarkFRightdn;
    markers.models["myThHoles2YMarkFRightdn"+myHolesNum]=myThHoles2YMarkFRightdn;
    /*****************************************************************************/ 
    plate.models["myThHoles2FRightup"+myHolesNum]=myThHoles2FRightup;
    markers.models["myThHoles2FRightup"+myHolesNum]=myThHoles2FRightup;
    markers.models["myThHoles2MarkFRightup"+myHolesNum]=myThHoles2MarkFRightup;
    markers.models["myThHoles2XMarkFRightup"+myHolesNum]=myThHoles2XMarkFRightup;
    /*****************************************************************************/ 
    plate.models["myThHoles2FLeftdn"+myHolesNum]=myThHoles2FLeftdn;
    markers.models["myThHoles2FLeftdn"+myHolesNum]=myThHoles2FLeftdn;
    markers.models["myThHoles2MarkFLeftdn"+myHolesNum]=myThHoles2MarkFLeftdn;
    /*****************************************************************************/ 
   
    }
/*****************************************************************************/ 

myHolesNum++;
return{plate, markers,myHolesNum}
};


export default ThreeHoles2;